import { putJson, postJson, getJson, deleteJson } from './baseApi'

export const fetchAllListings = search => {
	return getJson('/api/listings/all')
}

export const fetchListingNames = () => {
	return getJson('/api/listings/names')
}

export const searchListings = search => {
	let query = ''
	// paging
	query += 'pageSize=' + search.paging.pageSize
	// listingStatuses
	search.filter.listingStatuses.statuses.filter(m => m.selected).forEach((m, i) => {
		query += '&listingStatuses.statuses[' + i +']=' + m.value
	})

	return getJson(`/api/listings?${query}`)
}

export const fetchListing = listingId => {
	return getJson(`/api/listings/${listingId}`)
}

export const fetchVerificationListingByPartnerListingId = partnerListingId => {
	return getJson(`/api/listing?partnerListingId=${partnerListingId}`)
}

export const createListing = listing => {
	return postJson(`/api/listings`, listing)
}

export const updateListing = listing => {
	return putJson(`/api/listings/${listing.id}`, listing)
		.then(response => {
			// This is required b/c the update method doesn't handle settings
			response.settings = listing.settings
			return response
		})
		.catch(err => {
			throw err
		})
}

export const updateListingSettings = (listingId, listingSettings) => {
	return putJson(`/api/listings/${listingId}/settings`, { listingId: listingId, listingSettings: listingSettings })
}

export const getListingTemplateSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/template`)
}

export const updateListingTemplateSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/template`, settings)
}

export const getListingMessagingSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/messaging`)
}

export const updateListingMessagingSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/messaging`, settings)
}

export const getListingDataSharingSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/data-sharing`)
}

export const getListingAdvertisementSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/advertisement`)
}

export const updateListingAdvertisementSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/advertisement`, settings)
}

export const updateListingDataSharingSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/data-sharing`, settings)
}

export const getListingOfferPageSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/offer-page`)
}

export const updateListingOfferPageSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/offer-page`, settings)
}

export const fetchListingEmailSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/email`)
}

export const updateListingEmailSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/email`, settings)
}

export const getListingLoginsSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/logins`)
}

export const updateListingLoginsSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/logins`, settings)
}

export const getListingWizardSettings = listingId => {
	return getJson(`/api/listings/${listingId}/settings/wizard`)
}

export const updateListingWizardSettings = (listingId, settings) => {
	return putJson(`/api/listings/${listingId}/settings/wizard`, settings)
}

export const getListingOccupations = listingId => {
	return getJson(`/api/listings/${listingId}/occupations`)
}

export const setListingOccupations = (listingId, occupationMappings) => {
	return putJson(`/api/listings/${listingId}/occupations`, { listingId: listingId, occupationMappings: occupationMappings })
}

export const fetchListingVerificationLinks = listingId => {
	return getJson(`/api/listings/${listingId}/verification-links`)
}

export const createListingVerificationLink = link => {
	return postJson(`/api/listings/${link.listingId}/verification-links`, link)
}

export const updateListingVerificationLink = link => {
	return putJson(`/api/listings/${link.listingId}/verification-links/${link.id}`, link)
}

export const deleteListingVerificationLink = link => {
	return deleteJson(`/api/listings/${link.listingId}/verification-links/${link.id}`)
}

export const publishListing = id => {
	return postJson(`/api/listings/${id}/publish`)
}

export const unpublishListing = id => {
	return postJson(`/api/listings/${id}/unpublish`)
}

export const generateClientSecret = listingId => {
	return postJson(`/api/listings/${listingId}/generate-client-secret`)
}

export const generateBearerToken = listingId => {
	return postJson(`/api/listings/${listingId}/generate-bearer-token`)
}

export const fetchTicketSettings = (listingId, settings) => {
	return getJson(`/api/listings/${listingId}/settings/tickets`)
}

export const saveTicketSettings = (listingId, settings) => {
	return postJson(`/api/listings/${listingId}/settings/tickets`, settings)
}

export const fetchListingRedirectUrls = listingId => {
	return getJson(`/api/listings/${listingId}/redirect-urls`)
}

export const createListingRedirectUrl = (listingId, redirectUrl) => {
	return postJson(`/api/listings/${listingId}/create-redirect-url`, redirectUrl)
}

export const deleteListingRedirectUrl = (listingId, redirectUrl) => {
	return postJson(`/api/listings/${listingId}/delete-redirect-url`, redirectUrl)
}

export const fetchListingSpecialOffers = listingId => {
	return getJson(`/api/listings/${listingId}/special-offers`)
}

export const createListingSpecialOffer = (listingId, specialOffer) => {
	return postJson(`/api/listings/${listingId}/special-offers`, specialOffer)
}

export const deleteListingSpecialOffer = (listingId, specialOffer) => {
	return deleteJson(`/api/listings/${listingId}/special-offers/${specialOffer.id}`)
}

export const fetchListingSpecialOfferUserSubscriptionReport = search => {
	let { expanded, ...timeFrame } = search.filter.timeFrame

	let listingId =  search.filter.listing.id
	let query = ''

	// timeframe
	query += 'timeFrame.type=' + timeFrame.type
	if (timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(timeFrame.from.format())
	if (timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(timeFrame.to.format())

	// paging
	query += '&pageSize=' + search.paging.pageSize
	// Listing ID
	query += '&listingId=' + listingId

	return getJson(`/api/listings/${listingId}/special-offers-user-subscription-report?${query}`)
}

export const saveGovXLogin = login => {
	return postJson(`/api/listings/${login.listingId}/govx-login`, login)
}