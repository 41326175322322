import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, deleteWhitelistedEmailDomain, searchWhitelistEmailDomains } from '../../../redux/actions'

export function DeleteWhitelistedDomainModal({ domain }) {
  const dispatch = useDispatch()

  const onSave = () => {
    dispatch(deleteWhitelistedEmailDomain(domain.id))
      .then(() => {
        dispatch(searchWhitelistEmailDomains('whitelist-queue'))
        dispatch(hideModal())
      })
  }
  const onCancel = () => dispatch(hideModal())

  return (
    <ModalContainer
      title="Delete Domain"
      modal
      maxWidth="sm"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: () => onSave(domain),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p>
        Are you sure you want to delete the domain
        {' '}
        <strong>{ domain.name }</strong>
        ?
      </p>
      <p>
        Note: this will not change the verification status of
        members who have already been approved. However, any new
        registrants using this email domain will have to be
        manually verified.
      </p>
    </ModalContainer>
  )
}

DeleteWhitelistedDomainModal.propTypes = {
  domain: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
}

export default DeleteWhitelistedDomainModal
