import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../redux/actions'
import ModalContainer from '../ModalContainer'

// eslint-disable-next-line import/prefer-default-export
export function DeleteOfferModal({ offer, onDelete }) {
  const dispatch = useDispatch()
  const onCancel = useCallback(() => dispatch(hideModal()), [dispatch])
  const [processing, setProcessing] = useState(false)
  return (
    <ModalContainer
      title="Delete Offer"
      modal
      maxWidth="md"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: async () => {
            setProcessing(true)
            try {
              await onDelete(offer)
            } finally {
              setProcessing(false)
            }
          },
        },
        secondaryAction: { label: 'Cancel', onClick: onCancel },
      }}
    >
      <div>Are you sure you want to delete the offer? This action cannot be undone.</div>
    </ModalContainer>
  )
}

DeleteOfferModal.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
}
