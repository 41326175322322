import React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

// TODO: make generic component for empty states and use it in other places
function NoRows({ importOptions, message }) {
  return (
    <div style={{
      textAlign: 'center', padding: '58px',
    }}
    >
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="18.5039"
          y="19.0469"
          width="45.1701"
          height="40.2721"
          fill="#0E5777"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.9048 0C20.8762 0 3.80952 17.0667 3.80952 38.0952C3.80952 43.9619 5.18095 49.4476 7.50476 54.4381L0 80L25.5619 72.4952C30.5524 74.8191 36.0381 76.1905 41.9048 76.1905C62.9333 76.1905 80 59.1238 80 38.0952C80 17.0667 62.9333 0 41.9048 0ZM57.1429 41.9048H45.7143V53.3333H38.0952V41.9048H26.6667V34.2857H38.0952V22.8571H45.7143V34.2857H57.1429V41.9048Z"
          fill="#F1F1F1"
        />
      </svg>
      <p style={{ fontWeight: 'bold' }}>{message}</p>
      {importOptions
        && (
        <Button
          sx={{
            fontWeight: 'bold', color: '#0E5777', textTransform: 'none', fontSize: '16px',
          }}
          variant="text"
          onClick={importOptions.onClick}
          data-gxid="btnImport"
        >
          {importOptions.buttonText}
        </Button>
        )}
    </div>
  )
}

NoRows.propTypes = {
  importOptions: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  message: PropTypes.string.isRequired,
}

export default NoRows
