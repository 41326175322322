import React from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import LoginPage from './LoginPage'
import useLogin from './useLogin'
import { reactPlugin } from '../../applicationInsights'

function Login() {
  return (
    <LoginPage {...useLogin()} />
  )
}

export default withAITracking(reactPlugin, Login)
