import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
	logo: {
		height: '25px',
	},
	logoPath: {
		fill: '#fff',
	},
}

export const GovXLogo = ({ classes }) => (
	<div className={classes.logoWrapper}>
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 325.77 54.69" className={classes.logo}>
			<g>
				<path className={classes.logoPath} d="M275.12,1.58C274.16,0.57,272.82,0,271.43,0h-22.64l50.83,53.25c0.85,0.89,2.02,1.39,3.24,1.39l3.05,0
		l-28.56-29.92l2.48-2.6l29.77,31.1c0.87,0.9,2.06,1.42,3.31,1.42h2.92l-33.52-35.12l2.48-2.6l34.45,36.09
		c0.99,1.04,2.37,1.63,3.81,1.63h2.71L275.12,1.58z M267.7,4.38h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08
		c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18c0.06,0,0.09,0.08,0.04,0.12l-1.77,1.28
		c-0.02,0.02-0.03,0.05-0.02,0.07l0.68,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0L268.87,8
		c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28C267.61,4.46,267.63,4.38,267.7,4.38z
		 M264.75,4.49l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.68,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28
		c-0.02-0.02-0.05-0.02-0.07,0L259.93,8c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28
		c-0.05-0.04-0.02-0.12,0.04-0.12h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08
		c0.01,0.03,0.03,0.04,0.06,0.04h2.18C264.78,4.38,264.8,4.46,264.75,4.49z M271.57,11.49l-1.77,1.28
		c-0.02,0.02-0.03,0.04-0.02,0.07l0.67,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0L266.75,15
		c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18
		c0.03,0,0.05-0.02,0.06-0.04l0.68-2.08c0.02-0.06,0.1-0.06,0.12,0l0.67,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18
		C271.6,11.38,271.62,11.46,271.57,11.49z M278,18.43l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.67,2.08
		c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.08,0l-1.77,1.28c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08
		c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08
		c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18C278.02,18.32,278.05,18.39,278,18.43z M280.58,11.36
		l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.67,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0
		l-1.77,1.28c-0.05,0.04-0.12-0.01-0.1-0.07l0.67-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18
		c0.03,0,0.05-0.02,0.06-0.04l0.68-2.08c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18
		C280.61,11.24,280.63,11.32,280.58,11.36z"/>
				<g>
					<path className={classes.logoPath} d="M272.98,29.34l-24.19,25.34h22.64c1.39,0,2.73-0.57,3.69-1.58l9.01-9.44c0.71-0.74,0.71-1.91,0-2.66
			L272.98,29.34z"/>
					<path className={classes.logoPath} d="M302.93,23.96l22.83-23.92h-22.64c-1.39,0-2.73,0.57-3.69,1.58l-10.31,10.8l11.02,11.54
			C300.91,24.76,302.18,24.76,302.93,23.96z"/>
				</g>
			</g>
			<g>
				<path className={classes.logoPath} d="M0,7.61V47c0,4.2,3.41,7.61,7.61,7.61H68.9c4.2,0,7.61-3.41,7.61-7.61V27.1c0-1.79-1.45-3.25-3.25-3.25H50.61
		v8.2c0,1.85,1.5,3.35,3.35,3.35h6.7c0.61,0,1.11,0.5,1.11,1.11v4.98c0,0.61-0.5,1.11-1.11,1.11l-44.69-0.06
		c-0.61,0-1.11-0.5-1.11-1.11V13.35c0-0.61,0.5-1.11,1.11-1.11h44.69c0.61,0,1.11,0.5,1.11,1.11v4.67h14.75V7.61
		C76.51,3.41,73.1,0,68.9,0H7.61C3.41,0,0,3.41,0,7.61z"/>
				<path className={classes.logoPath} d="M155.02,0H93.15c-4.2,0-7.61,3.41-7.61,7.61v39.46c0,4.2,3.41,7.61,7.61,7.61h61.87c4.2,0,7.61-3.41,7.61-7.61
		V7.61C162.63,3.41,159.23,0,155.02,0z M147.78,13.65v27.46c0,0.52-0.14,0.88-0.42,1.09c-0.27,0.21-0.85,0.31-1.71,0.31h-43.13
		c-0.79,0-1.34-0.1-1.65-0.31c-0.31-0.21-0.47-0.57-0.47-1.09V13.65c0-0.52,0.16-0.88,0.47-1.09c0.31-0.21,0.87-0.31,1.65-0.31
		h43.13c0.86,0,1.44,0.11,1.71,0.31C147.64,12.76,147.78,13.13,147.78,13.65z"/>
				<path className={classes.logoPath} d="M229.92,0c-2.37,0-4.54,1.31-5.62,3.4l-18.54,35.95l-0.07,0.12l-0.07-0.12L187.1,3.4
		c-1.08-2.09-3.25-3.4-5.62-3.4h-14.33l26.74,51.95c0.84,1.64,2.54,2.66,4.4,2.66h0.02h14.8h0.02c1.86,0,3.55-1.03,4.4-2.66
		L244.25,0H229.92z"/>
			</g>
		</svg>
	</div>
)

GovXLogo.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(GovXLogo)