import React, { useState, Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material'

export function SelectField({
  name,
  label,
  disabled = false,
  items,
  value = null,
  onChange,
  invalid = false,
  gxid,
}) {
  const [selectedItem, setSelectedItem] = useState()
  useEffect(() => {
    setSelectedItem(() => {
      if (typeof value === 'object') {
        return items.find((item) => item.value === value.value)
      }
      return items.find((item) => item.value == value) // eslint-disable-line
    })
  }, [value])

  return (
    <Listbox
      name={name}
      disabled={disabled}
      value={selectedItem}
      onChange={(v) => {
        onChange(v)
      }}
      data-gxid={gxid}
    >
      {({ open }) => (
        <div className="relative">
          <Listbox.Label
            className={classNames(
              invalid ? 'text-red' : '',
              open || selectedItem ? 'top-2 text-xs' : 'top-4 text-base',
              open ? 'text-blue-dark' : 'text-gray-500',
              'transition-all block absolute left-3 z-10  text-base pointer-events-none select-none',
            )}
          >
            {label}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button
              className={classNames(
                invalid ? 'border-b-red-600' : 'focus:border-b-blue-dark',
                disabled ? 'bg-gray-100' : '',
                open
                  ? 'border-b-blue-dark border-b-2 cursor-default'
                  : 'border-b cursor-pointer',
                'relative w-full text-base rounded-t bg-white pt-3 pb-2 pl-3 pr-10 text-left text-gray-500 border-x border-x-gray-200 border-t border-t-gray-200 focus:outline-none border-gray-600 focus:border-b-2',
              )}
            >
              <span className="block truncate min-h-[24px] pointer-events-none select-none mt-3 text-black">
                {selectedItem?.label}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  className="h-6 w-6 fill-gray-800"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-gxid="ArrowDropDownIcon"
                >
                  <path d="m7 10 5 5 5-5z" />
                </svg>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map((item) => ( // eslint-disable-line
                  <Listbox.Option
                    key={item.key}
                    className={({ active }) => classNames(
                      active ? 'bg-blue-dark text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9 min-h-[36px]',
                    )}
                    value={item.value}
                  >
                    {({ selected, active }) => ( // eslint-disable-line
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {item.label}
                        </span>

                        {/* {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-blue-dark',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null} */}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  gxid: PropTypes.string,
}

export function MUISelectInput({
  label,
  name,
  disabled = false,
  items,
  value = null,
  onChange,
  invalid = false,
  gxid,
}) {
  return (
    <FormControl variant="filled" sx={{ m: 1, width: '100%', margin: '0px' }}>
      <InputLabel id={`inputLabelSelect-${name}`}>{label}</InputLabel>
      <Select
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        error={invalid}
        inputProps={{ 'data-gxid': gxid }}
        labelId={`inputLabelSelect-${name}`}
      >
        {items.map((item) => (<MenuItem sx={{ height: '2.25em' }} key={item.key ? item.key : item.value} value={item.value} disabled={item.disabled}>{item.label}</MenuItem>))}
      </Select>
    </FormControl>
  )
}

MUISelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  gxid: PropTypes.string,
}
