import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ModalContainer from '../ModalContainer'
import StyledLink from '../../StyledLink'
import {
  hideModal, createUsersSearch, searchUsersByDuplicateUserId,
} from '../../../redux/actions'
import { createFilteredUsersSelector } from '../../../redux/selectors'
import { formatDateWithoutTimezone } from '../../../formatting'
import { getUserPath } from '../../../setup/routeConstants'

export const DEFAULT_SEARCH = (duplicateUserId) => ({
  id: 'duplicate-users',
  filter: {
    duplicateUserId,
  },
})

const SEARCH_ID = 'duplicate-users'

export function DuplicateUserMappingsModal({
  userId,
}) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)

  // const duplicateVerificationRequests = useSelector(selectDuplicateUserVerificationRequests(userId)) || []
  const duplicatedUsers = useSelector(createFilteredUsersSelector(SEARCH_ID))

  // const getDuplicateUserVerificationRequests = () => dispatch(fetchDuplicateUserVerificationRequests(userId))
  const createSearch = () => dispatch(createUsersSearch(DEFAULT_SEARCH(userId)))
  const onSearchUsersByDuplicateUserId = () => dispatch(searchUsersByDuplicateUserId(userId, SEARCH_ID))
  const onCancel = () => dispatch(hideModal())

  useEffect(() => {
    const fetchData = async () => {
      createSearch(DEFAULT_SEARCH(userId))
      onSearchUsersByDuplicateUserId(userId, SEARCH_ID)
      setProcessing(false)
    }
    fetchData()
  }, [])

  return (
    <ModalContainer
      title="Potential Duplicate Accounts"
      processing={processing}
      modal
      maxWidth="md"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <div style={{ marginTop: '20px' }}>
        <Table>
          <TableHead style={{ backgroundColor: '#f1f1f1' }}>
            <TableRow>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Name
              </TableCell>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                ID
              </TableCell>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Created
              </TableCell>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Source Listing
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(duplicatedUsers || []).map((r) => (
              <TableRow key={r.id}>
                <TableCell>
                  {r.firstName}
                  {' '}
                  {r.lastName}
                </TableCell>
                <TableCell>{r.id}</TableCell>
                <TableCell>{r.dateOfBirth ? formatDateWithoutTimezone(r.dateOfBirth) : ''}</TableCell>
                <TableCell>
                  <StyledLink
                    contents="View duplicate"
                    to={getUserPath(r.id)}
                    onClick={onCancel}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </ModalContainer>
  )
}

DuplicateUserMappingsModal.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default DuplicateUserMappingsModal
