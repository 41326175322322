import { occupationInfoModel } from '../../../api/schema'
import * as occupationsApi from '../../../api/occupationsApi'
import { normalize, denormalize } from 'normalizr'
import { createSelector } from 'reselect'
import { UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS } from './occupations'

export const FETCH_OCCUPATION_INFOMODEL_REQUEST = 'FETCH_OCCUPATION_INFOMODEL_REQUEST'
export const FETCH_OCCUPATION_INFOMODEL_SUCCESS = 'FETCH_OCCUPATION_INFOMODEL_SUCCESS'
export const FETCH_OCCUPATION_INFOMODEL_FAILURE = 'FETCH_OCCUPATION_INFOMODEL_FAILURE'

export const fetchOccupationInfoModel = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_OCCUPATION_INFOMODEL_REQUEST })
	return occupationsApi.fetchOccupationInfoModel(id)
		.then(response => {
			const data = normalize(response, schema.occupationInfoModel)
			dispatch({ type: FETCH_OCCUPATION_INFOMODEL_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_OCCUPATION_INFOMODEL_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'occupationInfoModels'

export const DEFAULT_STATE = {
	db: {}
}

export const mergeOccupationInfoModels = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.occupationInfoModels }}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type){
		case FETCH_OCCUPATION_INFOMODEL_SUCCESS:
		case UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS:
			return mergeOccupationInfoModels(state, action)
		default:
			return { ...state }
	}
}

export default reducer

export const selectOccupationInfoModelsHashMap = state => state.verification.occupationInfoModels.db

export const selectOccupationInfoModels = createSelector(
	selectOccupationInfoModelsHashMap,
	occupationInfoModels => Object.keys(occupationInfoModels).map(k => occupationInfoModels[k])
)

export const selectOccupationInfoModel = id => state => state.verification.occupationInfoModels.db[id]