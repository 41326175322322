import React, { useEffect } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { fetchPolicies, requireUser } from '../redux/actions'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PageOverlay from '../components/PageOverlay'
import { hasPolicy } from '../redux/selectors'

export function ProtectedRoute({ requiredPolicies = [], children }) {
    const dispatch = useDispatch()
    const location = useLocation()
    const { getState } = useStore()
    const [auth, setAuth] = useState({})

    useEffect(() => {
        const userPromise = dispatch(requireUser())
        const policyPromise = requiredPolicies.length > 0 ? dispatch(fetchPolicies(requiredPolicies)) : null
        
        const authorizePromise = () => Promise.all([userPromise, policyPromise]).then(([a, b]) => {
            const loggedIn = !!a

            const hasAccess = requiredPolicies.reduce((acc, policy) => {
                acc = acc && hasPolicy(policy)(getState())
                return acc
            }, true)
            if (!loggedIn) {
                return { authorized: false }
            }
            if (requiredPolicies.length > 0 && !hasAccess) {
                console.error('Unauthorized access attempt', requiredPolicies)
                return { authorized: false, redirect: a.adminHomepage }
            }
            return { authorized: true }
        })

        authorizePromise()
        .then((res) => setAuth(res))
        .catch((err) => {
            setAuth({ authorized: false })
            console.error(err)
        })
    }, [requiredPolicies])

    if(auth?.authorized === false) {
        const returnUrlQueryString = `?returnUrl=${encodeURIComponent(location.pathname+location.search)}`
        return <Navigate to={auth.redirect || `/login${!window.location.href.includes('/login') ? returnUrlQueryString : ''}`} replace />
    }

    return children
}

export const LazyProtectedRoute = ({requiredPolicies = [], component, fallback = <PageOverlay />}) => (
	<React.Suspense fallback={fallback}>
		<ProtectedRoute requiredPolicies={requiredPolicies}>
			{component}
		</ProtectedRoute>
	</React.Suspense>
)

export default LazyProtectedRoute
