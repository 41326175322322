import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@mui/material/Switch'
import {
    useGetQuickResponseCodeSettingsQuery,
    useSetQuickResponseCodeSettingsMutation
} from "../../../redux/slices/shopifySlice"
import {
    showSuccessNotification,
    showErrorNotification
} from "../../../redux/actions"
import { selectPartner } from '../../../redux/modules/partners/partners'

const getMessage = (enabled) => enabled
    ? 'QR code discounts are enabled for this partner.'
    : 'QR code discounts are not enabled for this partner.'

const showNotification = (success) => success
    ? showSuccessNotification('Enable QR codes updated successfully')
    : showErrorNotification('Failed to update partner listing enable QR codes')

const Content = ({ appId, settings, isFetching }) => {
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(false)
    const [setQuickResponseCodeSettingsAsync] = useSetQuickResponseCodeSettingsMutation()

    useEffect(() => setChecked(settings?.enable == true), [isFetching])

    const update = async (enable) => {
        try {
            const success = await setQuickResponseCodeSettingsAsync({ appId, enable }).unwrap()
            if (success) setChecked(enable)
            dispatch(showNotification(success))
        }
        catch (error) {
            console.error(error)
            dispatch(showNotification(false))
        }
    }

    return (
        <div style={{ margin: '0px' }}>
            <div>
                <strong>Enable QR Codes</strong>
                <Switch
                    value={checked}
                    checked={checked}
                    onChange={e => update(e.target.checked)}
                />
            </div>
            <div>{getMessage(checked)}</div>
        </div>
    )
}

export const EnableQuickResponseCodeSection = ({ show, partnerId }) => {
    if (!show) return null
    const partner = useSelector(selectPartner(partnerId))
    const appId = partner.sourceKey
    const { data, isFetching } = useGetQuickResponseCodeSettingsQuery(appId)
    return <Content
        appId={appId}
        settings={data}
        isFetching={isFetching}
    />
}

export default EnableQuickResponseCodeSection
