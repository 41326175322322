/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { createUseStyles } from 'react-jss'
import { createSelector } from '@reduxjs/toolkit'
import { useMatch, useNavigate } from 'react-router-dom'
import StyledLink from '../../../../../StyledLink'
import BillingTermsStatusBadge from './BillingTermsStatusBadge'
import {
  fetchBillingTermsByListingId,
  showModal,
} from '../../../../../../redux/actions'
import { selectAllBillingTermsByListingId } from '../../../../../../redux/selectors'
import {
  formatDate, pluralize, numberWithCommas, capitalizeFirstLetter,
} from '../../../../../../formatting'

const useStyles = createUseStyles({
  root: {
    fontFamily: 'Roboto Regular,Helvetica,sans-serif',
    width: '50%',
    minWidth: '400px',
    paddingBottom: '10px',
  },
  termsHeader: {
    margin: '20px 0 20px',
    borderBottom: '2px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    fontSize: '.875rem',
  },
  termsHeaderTitle: {
    fontSize: '.875rem',
    fontFamily: 'Roboto Regular,Helvetica,sans-serif',
    margin: '0',
    padding: '0',
  },
  termsContent: {
  },
  termsRow: {
    padding: '0 0 20px',
    fontSize: '.875rem',
  },
  termsHeaderDetail: {
    fontFamily: '"Roboto Medium", Helvetica, sans-serif',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '1.5',
    margin: '0',
  },
})

export const getBillingFrequencyText = (snapDay) => {
  if (snapDay === 0) { return 'Last day of month' }
  return `Day ${snapDay} of each month`
}

export const getPaymentMethodString = (paymentInfo) => {
  if (!paymentInfo) { return 'N/A' }

  if (paymentInfo.type === 'Shopify' && paymentInfo.shopify && paymentInfo.shopify.appSubscriptionIdNumber) { return `Shopify (Subscription ID: ${paymentInfo.shopify.appSubscriptionIdNumber})` }

  return paymentInfo.type
}

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        minHeight: '400px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

const affiliateSourceType = {
  Direct: 'direct',
  Shopify: 'shopify',
}

export function BillingTerms({ affiliate }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const match = useMatch('/partners/listings/:id/:tab/:subtab/:task')

  const [processing, setProcessing] = useState(false)

  const fetchBillingTerms = () => {
    setProcessing(true)
    dispatch(fetchBillingTermsByListingId(affiliate.listingId))
      .finally(() => setProcessing(false))
  }
  // eslint-disable-next-line no-unused-vars
  const showBillingTermsModal = (billingTrms) => {
    dispatch(showModal('UPDATE_BILLING_TERMS', { billingTerms: billingTrms, onModalClose: () => navigate('.', { relative: 'path' }) }))
  }

  const billingTerms = useSelector(
    createSelector(
      selectAllBillingTermsByListingId(affiliate.listingId),
      (allBillingTerms) => (allBillingTerms && allBillingTerms.length > 0 ? allBillingTerms[0] : undefined),
    ),
  )

  useEffect(() => {
    if (match?.params?.task === 'edit-terms' && billingTerms) {
      showBillingTermsModal(billingTerms)
    }
  }, [match?.params?.task, billingTerms])

  useEffect(() => {
    fetchBillingTerms()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.termsHeader}>
        <p className={classes.termsHeaderDetail}>Billing Terms</p>
        {/* eslint-disable-next-line eqeqeq */}
        {affiliate.source.toLowerCase() != affiliateSourceType.Direct
          && (
            <StyledLink
              key="editBillingTerms"
              contents="Edit"
              clickHandlerOnly
              onClick={() => navigate('./edit-terms', { relative: 'path' })}
            />
          )}
      </div>
      {
        processing
          ? <Processing />
          : billingTerms
            ? (
              <div className={classes.termsContent}>
                <div className={classes.termsRow}>
                  Billing Frequency:
                  {` ${getBillingFrequencyText(billingTerms.snapDay)}`}
                </div>
                <div className={classes.termsRow}>
                  Next Bill Date:
                  {` ${formatDate(billingTerms.currentPeriodEndsAt)}`}
                </div>
                <div className={classes.termsRow}>
                  Payment Terms:  Net
                  {billingTerms.netTerms}
                  {' '}
                  {pluralize(billingTerms.netTerms, 'day', 'days')}
                </div>
                <div className={classes.termsRow}>
                  Payment Method:
                  {` ${getPaymentMethodString(billingTerms.paymentInfo)}`}
                </div>
                {billingTerms.paymentInfo.shopify
                  && (
                    <div className={classes.termsRow}>
                      Monthly Billing Limit:  $
                      {numberWithCommas(billingTerms.paymentInfo.shopify.cappedAmount)}
                    </div>
                  )}
                {billingTerms.paymentInfo.shopify
                  && (
                    <div className={classes.termsRow}>
                      Shopify Subscription Status:
                      {' '}
                      <BillingTermsStatusBadge
                        status={capitalizeFirstLetter(billingTerms.paymentInfo.shopify.status)}
                      />
                    </div>
                  )}
                <div className={classes.termsRow}>
                  Opt-in Date:
                  {` ${formatDate(affiliate.dateCreated)}`}
                </div>
              </div>
            )
            : (
              <div className={classes.termsContent}>
                <div className={classes.termsRow}>N/A</div>
              </div>
            )
      }
    </div>
  )
}

BillingTerms.propTypes = {
  affiliate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    listingId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    dateCreated: PropTypes.string.isRequired,
  }).isRequired,
}

export default BillingTerms
