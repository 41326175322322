import React, { useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Input from '@mui/material/Input'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { createBulkOperation } from '../../../../../../../redux/modules/partners/listings'
import { Button } from '../../../../../../Button'

const RESULT_RESET = ''
const RESULT_ERROR = 'Could not create bulk operation.'

const styleInputRow = { margin: '20px 0' }

const buildRequest = ({ listingId, queryInput }) => {
  const errors = []
  const query = queryInput.trim()
  if (query === '') {
    errors.push('Query is missing.')
  }
  if (errors.length > 0) return { errors }
  return { listingId, query }
}

const readFileContent = (file) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target.result)
    reader.onerror = (error) => reject(error)
    reader.readAsText(file)
  })
}

function BulkOperations({ appId, listingId }) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const [myState, setMyState] = useState({ result: RESULT_RESET, disabled: false })
  const fileRef = useRef()
  const queryRef = useRef()

  const submitBulkOperation = () => {
    setMyState({ result: RESULT_RESET, disabled: true })

    const request = buildRequest({
      listingId,
      queryInput: queryRef.current.querySelector('textarea').value,
    })

    const { errors } = request
    if (errors && errors.length > 0) {
      setMyState({ result: JSON.stringify({ errors }, null, 4), disabled: false })
      return
    }

    dispatch(createBulkOperation(request)).then((response) => {
      const result = response && response.id
        ? response
        : ({ errors: RESULT_ERROR })
      setMyState({ result: JSON.stringify(result, null, 4), disabled: false })
      return {}
    })
  }

  const { disabled, result } = myState
  return (
    <div style={{ borderBottom: '1px dashed #ccc' }}>
      <div style={styleInputRow}>
        <h4>Bulk Operations</h4>
      </div>
      <div style={styleInputRow}>
        <TextField variant="outlined" label="App Id" value={appId} disabled />
        <span style={{ padding: '5px' }}>&nbsp;</span>
        <Input
          ref={fileRef}
          type="file"
          inputProps={{ accept: '.txt,.gql,.graphql,.graphqls,.ts,.js' }}
          onChange={(event) => {
            const input = event.target
            const file = input.files && input.files.length > 0 ? input.files[0] : null
            if (!file) return
            readFileContent(file).then((content) => setQuery(content))
          }}
          onClick={(event) => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = null
          }}
        />
      </div>
      <div style={styleInputRow}>
        <TextField
          variant="outlined"
          label="Query"
          fullWidth
          ref={queryRef}
          multiline
          rows={4}
          value={query}
          onChange={(event) => { setQuery(event.target.value) }}
        />
      </div>
      <div style={styleInputRow}>
        <Button
          label="Create Bulk Operation"
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={submitBulkOperation}
        >
          Create Bulk Operation
        </Button>
      </div>
      <div style={styleInputRow}>
        <pre
          style={{
            borderRadius: '5px',
            background: 'black',
            color: 'yellow',
            padding: '10px',
          }}
        >
          {result}
        </pre>
      </div>
    </div>
  )
}

BulkOperations.propTypes = {
  appId: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
}

export default BulkOperations
