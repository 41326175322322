import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import DynamicField from '../Fields/DynamicField'
import { selectOccupationPrequalifiersForOccupation, selectUser } from '../../redux/selectors'
import { createOfflineVerificationRequest } from '../../redux/actions'


export const PrequalForm = ({
	prequalifiers,
	handleSubmit,
	listingName,
	setProcessing
}) => (
	<form onSubmit={ handleSubmit }>

		{
			prequalifiers
			?	prequalifiers.filter(x => x.key !== 'first-name' && x.key !== 'last-name').map(f => <DynamicField key={ f.id } field={ f } />)
			:	null
		}
	</form>
)


const mapStateToProps = (state, ownProps) => ({
	prequalifiers: selectOccupationPrequalifiersForOccupation(ownProps.occupationId)(state),
	user: selectUser(ownProps.userId)(state)
})

const mapDispatchToProps = dispatch => ({})

export const handleSubmit = (values, dispatch, props) => {
 	var qualifiers = props.prequalifiers.map(x => x)
	if (qualifiers) {
		let metas = []

		qualifiers.forEach(q => {
			let meta
			if (q.key == 'first-name')
				meta = {key: q.key, value: props.user.firstName}
			else if (q.key == 'last-name')
				meta = {key: q.key, value: props.user.lastName}
			else if (typeof values[q.key] === 'object')
				meta = {key: q.key, value: JSON.stringify(values[q.key])}
			else
				meta = {key: q.key, value: values[q.key]}

			if (typeof q.value === 'undefined' && q.type === 'boolean')
				meta = {key: q.key, value: false}

			metas.push(meta)
		})
		props.setProcessing(true)
		return dispatch(createOfflineVerificationRequest(props.occupationId, props.user, metas, props.listingName ? props.listingName : 'govx'))
			.then(() => {
				props.onRequestCreated()
			})
	}
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({ form: 'verify-prequal', onSubmit: handleSubmit })
)

export default enhance(PrequalForm)