import { whitelistedEmailDomain } from '../../../api/schema'
import * as whiteListApi from '../../../api/whitelistApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

export const CREATE_WHITELISTEDEMAILDOMAINS_REQUEST = 'CREATE_WHITELISTEDEMAILDOMAINS_REQUEST'
export const CREATE_WHITELISTEDEMAILDOMAINS_SUCCESS = 'CREATE_WHITELISTEDEMAILDOMAINS_SUCCESS'
export const CREATE_WHITELISTEDEMAILDOMAINS_FAILURE = 'CREATE_WHITELISTEDEMAILDOMAINS_FAILURE'

export const APPROVE_WHITELISTEDEMAILDOMAIN_REQUEST = 'APPROVE_WHITELISTEDEMAILDOMAIN_REQUEST'
export const APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS = 'APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS'
export const APPROVE_WHITELISTEDEMAILDOMAIN_FAILURE = 'APPROVE_WHITELISTEDEMAILDOMAIN_FAILURE'

export const CREATE_WHITELISTEDEMAILDOMAIN_SEARCH = 'CREATE_WHITELISTEDEMAILDOMAIN_SEARCH'
export const UPDATE_WHITELISTEDEMAILDOMAIN_SEARCH = 'UPDATE_WHITELISTEDEMAILDOMAIN_SEARCH'

export const SEARCH_WHITELISTEDEMAILDOMAINS_REQUEST = 'SEARCH_WHITELISTEDEMAILDOMAINS_REQUEST'
export const SEARCH_WHITELISTEDEMAILDOMAINS_SUCCESS = 'SEARCH_WHITELISTEDEMAILDOMAINS_SUCCESS'
export const SEARCH_WHITELISTEDEMAILDOMAINS_FAILURE = 'SEARCH_WHITELISTEDEMAILDOMAINS_FAILURE'
export const DENY_WHITELISTEDEMAILDOMAIN_REQUEST = 'DENY_WHITELISTEDEMAILDOMAIN_REQUEST'

export const DENY_WHITELISTEDEMAILDOMAIN_SUCCESS = 'DENY_WHITELISTEDEMAILDOMAIN_SUCCESS'
export const DENY_WHITELISTEDEMAILDOMAIN_FAILURE = 'DENY_WHITELISTEDEMAILDOMAIN_FAILURE'

export const DELETE_WHITELISTEDEMAILDOMAIN_REQUEST = 'DELETE_WHITELISTEDEMAILDOMAIN_REQUEST'
export const DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS = 'DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS'
export const DELETE_WHITELISTEDEMAILDOMAIN_FAILURE = 'DELETE_WHITELISTEDEMAILDOMAIN_FAILURE'

export const createWhitelistedEmailDomains = (domainName, occupationIds) => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_WHITELISTEDEMAILDOMAINS_REQUEST })
	return whiteListApi.createWhitelistedEmailDomains(domainName, occupationIds)
		.then(response => {
			const data = normalize(response, [schema.whitelistedEmailDomain])
			dispatch({ type: CREATE_WHITELISTEDEMAILDOMAINS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: CREATE_WHITELISTEDEMAILDOMAINS_FAILURE, err })
			throw err
		})
}

export const createWhitelistEmailDomainSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_WHITELISTEDEMAILDOMAIN_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const updateWhitelistEmailDomainSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_WHITELISTEDEMAILDOMAIN_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const searchWhitelistEmailDomains = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_WHITELISTEDEMAILDOMAINS_REQUEST })
	let search = selectWhitelistEmailDomainSearch(searchId)(getState())

	return whiteListApi.fetchWhitelistedEmailDomains(search)
		.then(response => {
			const data = normalize(response.data, [schema.whitelistedEmailDomain])
			dispatch({ type: SEARCH_WHITELISTEDEMAILDOMAINS_SUCCESS, data, total: response.total, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_WHITELISTEDEMAILDOMAINS_FAILURE, err })
			throw err
		})
}

export const approveWhitelistedEmailDomainById = (id) => (dispatch, getState, { schema }) => {
	dispatch({ type: APPROVE_WHITELISTEDEMAILDOMAIN_REQUEST })
	return whiteListApi.approveWhitelistedEmailDomainById(id)
		.then(response => {
			const data = normalize(response, { whitelistedEmailDomain: schema.whitelistedEmailDomain, verificationRequests: [schema.verificationRequest] })
			dispatch({ type: APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS, data })
			return response
		})
		.catch(err => {
			if (err.domain)
				err.message = err.domain
			dispatch({ type: APPROVE_WHITELISTEDEMAILDOMAIN_FAILURE, err })
			throw err
		})
}

export const denyWhitelistedEmailDomain = id => (dispatch, getState, { schema }) => {
	dispatch({ type: DENY_WHITELISTEDEMAILDOMAIN_REQUEST })
	return whiteListApi.denyWhitelistedEmailDomain(id)
		.then(response => {
			const data = normalize(response, schema.whitelistedEmailDomain)
			dispatch({ type: DENY_WHITELISTEDEMAILDOMAIN_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: DENY_WHITELISTEDEMAILDOMAIN_FAILURE })
			throw err
		})
}

export const deleteWhitelistedEmailDomain = id => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_WHITELISTEDEMAILDOMAIN_REQUEST })
	return whiteListApi.deleteWhitelistedEmailDomain(id)
		.then(response => {
			dispatch({ type: DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS, id })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_WHITELISTEDEMAILDOMAIN_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'whitelistedemaildomains'

export const DEFAULT_STATE = {
	db: {},
	searches: {}
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const updateWhitelistDomain = (state, action) => {
	return {
		...state,
		db: { ...state.db, ...action.data.entities.whitelistedEmailDomains }
	}
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result
		}
	}

	let db = {
		...state.db,
		...action.data.entities.whitelistedEmailDomains
	}

	return { ...state, db, searches }
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case CREATE_WHITELISTEDEMAILDOMAIN_SEARCH:
		case UPDATE_WHITELISTEDEMAILDOMAIN_SEARCH:
			return handleSearchChange(state, action)
		case CREATE_WHITELISTEDEMAILDOMAINS_SUCCESS:
		case APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS:
		case DENY_WHITELISTEDEMAILDOMAIN_SUCCESS:
			return updateWhitelistDomain(state, action)
		case DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS:
			let { [action.id.toString()]: deleted, ...db } = state.db
			return { ...state, db }
		case SEARCH_WHITELISTEDEMAILDOMAINS_SUCCESS:
			return handleSearchSuccess(state, action)
		default:
			return { ...state }
	}
}

export default reducer

export const selectWhitelistEmailDomainsHashMap = state => state.verification.whitelistedemaildomains.db
export const selectWhitelistEmailDomainSearchesHashMap = state => state.verification.whitelistedemaildomains.searches

export const selectWhitelistedEmailDomains = createSelector(
	selectWhitelistEmailDomainsHashMap,
	domains => Object.keys(domains).map(k => domains[k])
)

export const selectWhitelistedEmailDomainSearches = createSelector(
	selectWhitelistEmailDomainSearchesHashMap,
	searches => Object.keys(searches).map(k => searches[k])
)

export const createFilteredWhitelistEmailDomainsSelector = id => {
	return createSelector(
		[selectWhitelistEmailDomainsHashMap, selectWhitelistEmailDomainSearchesHashMap],
		(domains, searches) => {
			if (!searches[id] || !searches[id].result)
				return []
			let results = searches[id].result.map(f => domains[f]).filter(d => d)
			return results
		})
}

export const selectWhitelistEmailDomainSearch = id => state => state.verification.whitelistedemaildomains.searches[id]
export const selectWhitelistEmailDomain = id => state => state.verification.whitelistedemaildomains.db[id]