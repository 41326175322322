import React, { useState } from 'react'
import { withStyles } from '@mui/styles'
import { compose, withState } from 'recompose'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

const useStyles =  createUseStyles({
	menuItem: {
		'&:hover': {
			backgroundColor: '#f1f1f1 !important',
			borderRadius: '2px !important',
		},
	}
})

/*selectedMenuItemStyle={{ backgroundColor: '#474747', color: '#fff' }} */
const TabWithSubNav = ({ items }) => {
	const [activeMenuItem, setActiveMenuItem] = useState(0)
	const classes = useStyles()

	return (
	<div style={{ maxWidth: '100%' }} className={classes.wrapper}>
		<div style={{ width: '210px', float: 'left', paddingRight: '10px', borderRight: '1px solid rgb(207, 207, 207)' }}>
			<div style={{ margin: '-8px 0px' }}>
				<MenuList
					width={220}
				>
					{items.map((i, idx) => (
						<MenuItem
							key={idx}
							onClick={e => setActiveMenuItem(e.target.value)}
							value={idx}
							classes={{
								root: classes.menuItem,
							}}
							sx={{
								textTransform: 'uppercase',
								fontSize: '13px',
								borderRadius: activeMenuItem === idx ? '2px' : 'inherit',
								borderLeft: activeMenuItem === idx ? '2px solid #000' : 'inherit',
								backgroundColor: activeMenuItem === idx ? '#f1f1f1' : 'inherit',
								color: activeMenuItem === idx ? 'rgba(0, 0, 0, 0.87)' : '#000',
								height: '50px',
								paddingY: '6px',
							}}
						>
							{i.primaryText}
						</MenuItem>
					))}
				</MenuList>
			</div>
		</div>
		<div style={{ marginLeft: '209px', paddingLeft: '20px', width: 'auto', borderLeft: '1px solid rgb(207, 207, 207)' }}>{items[activeMenuItem].component}</div>
	</div>
)}

export default TabWithSubNav
