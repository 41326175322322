import React from 'react'

function NotFoundScreen() {
  return (
    <div>
      <h1 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Not Found!</h1>
      <p>The page you were looking for does not exist.</p>
    </div>
  )
}

export default NotFoundScreen
