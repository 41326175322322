
export const getImageSizesAsync = (file) => new Promise((resolve, _) => {
    const img = new Image()
    img.onload = () => {
        const data = {
            width: img.width,
            height: img.height
        }
        URL.revokeObjectURL(img.src)
        resolve(data)
    }
    img.src = URL.createObjectURL(file)
})

export const getTargetSize = ({ width, height }) => {
    const delta = { value: 200, min: 1, max: 6 }
    if (height < 1 || width < 1) {
        return delta.value
    }

    const aspectRatioFloor = Math.floor(width / height)
    const ideal = aspectRatioFloor < delta.min
        ? delta.min
        : aspectRatioFloor > delta.max
            ? delta.max
            : aspectRatioFloor

    // Image may be smaller than aspect ratio allows
    const allowed = Math.ceil(width / delta.value)

    return (ideal > allowed ? allowed : ideal) * delta.value
}

export const getTargetSizeByFileAsync = async (file) => {
    const sizes = await getImageSizesAsync(file)
    return getTargetSize(sizes)
}
