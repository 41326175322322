import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from './ModalContainer'
import { hideModal } from '../../redux/actions'

export function UnsavedChangesModal({
  onSave,
  secondaryMessage,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())

  return (
    <ModalContainer
      title="Unsaved changes"
      modal
      maxWidth="xs"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: onSave,
        },
        secondaryAction: {
          label: 'Don\'t Save',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ marginTop: '30px', color: '#222' }}>You have unsaved changes. Do you wish to leave this page without saving?</p>
      {
    secondaryMessage
      ? <p style={{ fontSize: '14px' }}>{ secondaryMessage }</p>
      : null
  }
    </ModalContainer>
  )
}

UnsavedChangesModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  secondaryMessage: PropTypes.string,
}

export default UnsavedChangesModal
