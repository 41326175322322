import React from 'react'
import { AutoCompleteInput } from './ControlledAutoComplete'
import { Controller, useController, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { fillErrorMessages } from '../fillErrorMessages'

export function FormAutoCompleteInput({
  name,
  control,
  rules,
  options = [],
  id,
  label,
  itemLabelKey,
  noOptionsText = 'No options',
  onInputChange,
  gxid,
  ...props
}) {
  const { control: backupControl } = useForm({
    mode: 'onChange',
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors }, // eslint-disable-line no-unused-vars
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })

  return (
    <>
      <AutoCompleteInput
        id={id}
        name={field.name}
        value={field.value}
        label={label}
        options={options}
        noOptionsText={noOptionsText}
        onChange={(ev, value) => field.onChange(value)}
        onInputChange={onInputChange}
        itemLabelKey={itemLabelKey}
        gxid={gxid}
        fieldState={fieldState}
        {...props}
      />
      <ErrorMessage
        errors={fillErrorMessages(errors, field.name, label, rules)}
        name={field.name}
        render={({ message }) => (
          <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>{message}</p>
        )}
      />
    </>

  )
}
