import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetBillableSubscriptionByListingIdQuery, useCreateBillableEventMutation } from '../../../../../../redux/slices/billingSlice'
import { showErrorNotification, showSuccesNotification } from '../../../../../../redux/actions'

const useBilling = () => {
  const dispatch = useDispatch()
  const { id: partnerListingId } = useParams()

  const [createBillableEvent] = useCreateBillableEventMutation()

  const {
    data: billableSubscription,
    billableSubscriptionIsLoading,
    billableSubscriptionIsFetching,
    billableSubscriptionIsSuccess,
    billableSubscriptionIsError,
    billableSubscriptionError,
  } = useGetBillableSubscriptionByListingIdQuery(partnerListingId)

  const submitBillableEvent = async (billableEvent) => {
    try {
      var response = await createBillableEvent(billableEvent)
      if (response.error) {
        dispatch(showErrorNotification('Failed to create billable event. Please try again later.'))
      }
      else {
        dispatch(showSuccesNotification('Billable event successfully created'))
      }

    } catch (err) {
      console.error(err)
      dispatch(showErrorNotification('Failed to create billable event. Please try again later.'))
    }
  }

  return {
    billableSubscription,
    billableSubscriptionIsLoading,
    billableSubscriptionIsFetching,
    billableSubscriptionIsSuccess,
    billableSubscriptionIsError,
    billableSubscriptionError,
    submitBillableEvent,
  }
}

export default useBilling
