import React from 'react'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { useOutletContext } from 'react-router-dom'
import SectionHeadline from '../../../../Header/SectionHeadline'
import CustomizePageForm from '../../Forms/CustomizePageForm'
import Footer from './Footer'

export function CustomizePageSection() {
  const dispatch = useDispatch()
  const { affiliate, canUpdate } = useOutletContext()

  const handleSubmit = () => dispatch(submit('customize-page'))

  return (
    <div style={{ position: 'relative', minHeight: '800px' }}>
      <SectionHeadline>Customize Page</SectionHeadline>
      <CustomizePageForm affiliate={affiliate} canUpdate={canUpdate} />
      {canUpdate && <Footer label="Save" onSubmit={handleSubmit} />}
    </div>
  )
}

CustomizePageSection.propTypes = {
}

export default CustomizePageSection
