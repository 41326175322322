import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import ModalContainer from '../ModalContainer'
import { RouteConstants } from '../../../setup/routeConstants'
import { hideModal, deleteUser } from '../../../redux/actions'
import { LOCATION_CHANGE } from '../../../setup/history'

export function DeleteUserModal({ user }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onCancel = () => dispatch(hideModal())
  const onDelete = () => {
    dispatch(deleteUser(user.id))
      .then(dispatch(hideModal()))
      .then(() => {
        navigate(RouteConstants.users.index.path)
        dispatch({ type: LOCATION_CHANGE })
      })
  }

  return (
    <ModalContainer
      title="Delete Account"
      modal
      maxWidth="xs"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Delete User',
          onClick: () => onDelete(user),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <Typography style={{ marginTop: '10px' }}>
        Are you sure you want to delete this account?
      </Typography>
      <Typography style={{ marginTop: '10px' }}>
        The user will no longer be able to log in with their GovX
        credentials. This action cannot be undone.
      </Typography>
    </ModalContainer>
  )
}

DeleteUserModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default DeleteUserModal
