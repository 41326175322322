/* eslint-disable  */
import { getGridDateOperators, getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid'

const validOperators = [getGridSingleSelectOperators(), getGridStringOperators(), getGridDateOperators()].flat().map(op => op.value)

const buildFilters = (filters) => {
    let items = []
    let logicOperator = 'and'
    let sort = {}
    let pagination = {}
    Object.keys(filters).forEach((prop) => {
        const index = prop.slice(-1)
        if (prop === 'logicOperator') {
            logicOperator = filters[prop]
        }
        else if (prop === 'sortField') {
            sort.field = filters[prop]
        }
        else if (prop === 'sortDir') {
            sort.sort = filters[prop]
        }
        else if (prop === 'page') {
            pagination.page = parseInt(filters[prop])
        }
        else if (prop === 'pageSize') {
            pagination.pageSize = parseInt(filters[prop])
        }
        else {
            const filterField = prop.slice(0, -1)
            if (typeof items[index] === 'undefined') {
                let filterGroup = {}
                filterGroup[filterField] = filters[prop]
                items.push(filterGroup)
            } else {
                if (filterField === 'value') {
                    if (filters[prop] === 'true' || filters[prop] === 'false') {
                        items[index][filterField] = JSON.parse(filters[prop])
                    } else {
                        items[index][filterField] = filters[prop].includes && filters[prop].includes(',,,') ? filters[prop].split(',,,') : filters[prop]
                    }
                } else {
                    items[index][filterField] = filters[prop]
                }
            }
        }
    })
    return { items, logicOperator, sort, pagination }
}

export const getFilters = (searchParams, defaultFilters, storageKey, validFields, pagination) => {
    const params = Object.fromEntries([...searchParams])
    const invalid = Object.keys(params).some(key =>
        (key.includes('field') && !validFields.includes(params[key])) ||
        (key.includes('operator') && !validOperators.includes(params[key]))
    )
    if (!invalid) {
        if (searchParams.size > 0) {
            return buildFilters(params)
        }
        const localFilters = JSON.parse(localStorage.getItem(storageKey))
        if (localFilters) {
            return buildFilters(localFilters)
        }
    }
    return {
        items: defaultFilters.items,
        logicOperator: defaultFilters.logicOperator,
        sort: [],
        pagination: pagination
    }
}

export const buildSearchParams = (filters, storageKey, sortInfo, pagination) => {
    const params = {
        logicOperator: filters.logicOperator,
        ...(sortInfo.length > 0 && { sortField: sortInfo[0].field }),
        ...(sortInfo.length > 0 && { sortDir: sortInfo[0].sort }),
        ...(pagination && { page: pagination.page }),
        ...(pagination && { pageSize: pagination.pageSize }),
    }
    filters.items.forEach((filter, index) => {
        if (filter.value !== undefined || (filter.operator === 'isEmpty' || filter.operator === 'isNotEmpty')) {
            params[`field${index}`] = filter.field,
                params[`operator${index}`] = filter.operator,
                params[`value${index}`] = typeof filter.value === 'string' || typeof filter.value === 'boolean' ?
                    filter.value : filter.value ? filter.value.join(',,,') : null,
                params[`id${index}`] = filter.id ? filter.id : `${filter.field}${filter.value}`
        }
    })
    localStorage.setItem(storageKey, JSON.stringify(params))

    return params
}
