import { reducer as formReducer } from 'redux-form'
import { LOCATION_CHANGE } from '../../setup/history'

export const DIRTY_FORM = 'DIRTY_FORM'
export const CLEAN_FORM = 'CLEAN_FORM'

export const STATE_KEY = 'form'

export const DEFAULT_STATE = {
	isDirty: false,
}

const reducer = formReducer.plugin({
	initialize: (state = DEFAULT_STATE, action) => {
		switch (action.type) {
			case DIRTY_FORM:
				return {
					isDirty: true,
				}

			case LOCATION_CHANGE:
				return DEFAULT_STATE
			case CLEAN_FORM:
				return DEFAULT_STATE
			default:
				return state
		}
	},
})

export default reducer
