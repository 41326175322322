/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useRoutes } from 'react-router-dom'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import theme from '../theme'
import { useModal } from '../hooks/useModal'
import { reactPlugin } from '../applicationInsights'
import ErrorScreen from '../components/ErrorScreen'

function App({ routes }) {
  const element = useRoutes(routes)
  const { renderModal } = useModal()

  return (
    <AppInsightsErrorBoundary reactPlugin={reactPlugin} onError={<ErrorScreen />}>
      <ThemeProvider theme={theme}>
        <div style={{ position: 'relative' }}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {element}
          </LocalizationProvider>
        </div>
        {renderModal()}
      </ThemeProvider>
    </AppInsightsErrorBoundary>
  )
}

export default App
