/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import OfferPageFormPartial from './OfferPageFormPartial'
import {
  fetchListingOfferPageSettings,
  updateListingOfferPageSettings,
} from '../../../../redux/actions'
import {
  selectListingSettings,
} from '../../../../redux/selectors'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        minHeight: '400px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function OfferPageForm({
  listing,
  innerRef,
}) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)

  const initialValues = useSelector(selectListingSettings(listing.id, 'offerPage'))

  const { handleSubmit, ...form } = useForm()

  const getListingOfferPageSettings = () => {
    dispatch(fetchListingOfferPageSettings(listing.id))
      .then(() => setProcessing(false))
  }

  useEffect(() => {
    getListingOfferPageSettings()
  }, [])

  useEffect(() => {
    form.reset(initialValues)
  }, [initialValues])

  const onSubmit = (values) => {
    const settings = {
      welcomeHeadline: values.welcomeHeadline,
      welcomeMessage: values.welcomeMessage,
    }

    dispatch(updateListingOfferPageSettings(listing.id, settings))
  }

  return (
    <div style={{ position: 'relative', width: 'calc(100% - 2px)' }}>
      {
                processing
                  ? <Processing />
                  : (
                    <form ref={innerRef} onSubmit={handleSubmit(onSubmit)}>
                      <OfferPageFormPartial form={form} listing={listing} />
                    </form>
                  )
            }
    </div>
  )
}

export default OfferPageForm
