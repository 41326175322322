import { affiliate } from '../../../api/schema'
import * as affiliateApi from '../../../api/affiliateApi'
import * as picturesApi from '../../../api/picturesApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'


export const CREATE_AFFILIATES_SEARCH = 'CREATE_AFFILIATES_SEARCH'
export const UPDATE_AFFILIATES_SEARCH = 'UPDATE_AFFILIATES_SEARCH'

export const SEARCH_AFFILIATES_REQUEST = 'SEARCH_AFFILIATES_REQUEST'
export const SEARCH_AFFILIATES_SUCCESS = 'SEARCH_AFFILIATES_SUCCESS'
export const SEARCH_AFFILIATES_FAILURE = 'SEARCH_AFFILIATES_FAILURE'


export const createAffiliatesSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_AFFILIATES_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const updateAffiliatesSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_AFFILIATES_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const searchAffiliates = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_AFFILIATES_REQUEST })
	let search = selectAffiliatesSearch(searchId)(getState())

	return affiliateApi.fetchAffiliates(search)
		.then(response => {
			const data = normalize(response.data, [schema.affiliate])
			dispatch({ type: SEARCH_AFFILIATES_SUCCESS, data, total: response.total, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_AFFILIATES_FAILURE, err })
			throw err
		})
}


export const UPDATE_AFFILIATE_STATUS_REQUEST = 'UPDATE_AFFILIATE_STATUS_REQUEST'
export const UPDATE_AFFILIATE_STATUS_SUCCESS = 'UPDATE_AFFILIATE_STATUS_SUCCESS'
export const UPDATE_AFFILIATE_STATUS_FAILED = 'UPDATE_AFFILIATE_STATUS_FAILED'

export const updateAffiliateStatus = (affiliateId, status) => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_AFFILIATE_STATUS_REQUEST })
	return affiliateApi.updateAffiliateStatus(affiliateId, status)
		.then(response => {
			const data = normalize(response, schema.affiliate)
			dispatch({ type: UPDATE_AFFILIATE_STATUS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_AFFILIATE_STATUS_FAILED, err })
			throw err
		})
}

export const CREATE_AFFILIATE_REQUEST = 'UPDATE_AFFILIATE_STATUS_REQUEST'

export const createAffiliateWithStatus = (partnerListingId, status) => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_AFFILIATE_REQUEST })
	return affiliateApi.createAffiliateWithStatus(partnerListingId, status)
		.then(response => {
			const data = normalize(response, schema.affiliate)
			dispatch({ type: UPDATE_AFFILIATE_STATUS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_AFFILIATE_STATUS_FAILED, err })
			throw err
		})
}



export const UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_REQUEST = 'UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_REQUEST'
export const UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS = 'UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS'
export const UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_FAILED = 'UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_FAILED'


export const updateAffiliateAllowProductPulls = (affiliateId, allowProductPulls) => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_REQUEST })
	return affiliateApi.updateAffiliateAllowProductPulls(affiliateId, allowProductPulls)
		.then(response => {
			const data = normalize(response, schema.affiliate)
			dispatch({ type: UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_FAILED, err })
			throw err
		})
}

export const FETCH_AFFILIATE_REQUEST = 'FETCH_AFFILIATE_REQUEST'
export const FETCH_AFFILIATE_SUCCESS = 'FETCH_AFFILIATE_SUCCESS'
export const FETCH_AFFILIATE_FAILED = 'FETCH_AFFILIATE_FAILED'

export const fetchAffiliate = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_AFFILIATE_REQUEST })
	return affiliateApi
		.fetchAffiliate(id)
		.then(response => {
			let data = normalize(response, schema.affiliate)
			dispatch({ type: FETCH_AFFILIATE_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AFFILIATE_FAILED, err })
			throw err
		})
}

export const FETCH_AFFILIATE_BY_LISTINGID_REQUEST = 'FETCH_AFFILIATE_BY_LISTINGID_REQUEST'
export const FETCH_AFFILIATE_BY_LISTINGID_SUCCESS = 'FETCH_AFFILIATE_BY_LISTINGID_SUCCESS'
export const FETCH_AFFILIATE_BY_LISTINGID_FAILED = 'FETCH_AFFILIATE_BY_LISTINGID_FAILED'

export const fetchAffiliateByListingId = listingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_AFFILIATE_BY_LISTINGID_REQUEST })
	return affiliateApi
		.fetchAffiliateByListingId(listingId)
		.then(response => {
			let data = normalize(response, schema.affiliate)
			dispatch({ type: FETCH_AFFILIATE_BY_LISTINGID_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AFFILIATE_BY_LISTINGID_FAILED, err })
			throw err
		})
}

export const FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST = 'FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST'
export const FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS = 'FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS'
export const FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE = 'FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE'

export const fetchAffiliateMarketplacePageSettings = affiliateId => (dispatch, getState) => {
	dispatch({ type: FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST })
	return affiliateApi.getAffiliateMarketplacePageSettings(affiliateId)
		.then(response => {
			dispatch({ type: FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS, affiliateId: affiliateId, pageInfo: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST = 'UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST'
export const UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS = 'UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS'
export const UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE = 'UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE'

export const updateAffiliateMarketplacePageSettings = (affiliateId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_REQUEST })
	return affiliateApi.updateAffiliateMarketplacePageSettings(affiliateId, settings)
		.then(response => {
			dispatch({ type: UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS, affiliateId: affiliateId, pageInfo: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_DESKTOP_HERO_IMAGE = 'UPDATE_DESKTOP_HERO_IMAGE';
export const UPDATE_MOBILE_HERO_IMAGE = 'UPDATE_MOBILE_HERO_IMAGE';
export const UPDATE_PROMO_SQUARE_IMAGE = 'UPDATE_PROMO_SQUARE_IMAGE';
export const UPDATE_PROMO_TILE_IMAGE = 'UPDATE_PROMO_TILE_IMAGE';


export const updateDesktopHeroImage = (url, id, affiliateId) => ({
	type: UPDATE_DESKTOP_HERO_IMAGE,
	payload: {
	  url,
	  id,
	  affiliateId,
	},
  });

  export const updateMobileHeroImage = (url, id, affiliateId) => ({
	type: UPDATE_MOBILE_HERO_IMAGE,
	payload: {
	  url,
	  id,
	  affiliateId,
	},
  });

  export const updatePromoTileImage = (url, id, affiliateId) => ({
	type: UPDATE_PROMO_TILE_IMAGE,
	payload: {
	  url,
	  id,
	  affiliateId,
	},
  });

  export const updatePromoSquareImage = (url, id, affiliateId) => ({
	type: UPDATE_PROMO_SQUARE_IMAGE,
	payload: {
	  url,
	  id,
	  affiliateId,
	},
  });
  

export const FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST = 'FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST'
export const FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS = 'FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS'
export const FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE = 'FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE'

export const fetchAffiliateMarketingAssetsSettings = affiliateId => (dispatch, getState) => {
	dispatch({ type: FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST })
	return affiliateApi.getAffiliateMarketingAssetsSettings(affiliateId)
		.then(response => {
			dispatch({ type: FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS, affiliateId: affiliateId, marketingAssets: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST = 'UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST'
export const UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS = 'UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS'
export const UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE = 'UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE'

export const updateAffiliateMarketingAssetsSettings = (affiliateId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_REQUEST })
	return affiliateApi.updateAffiliateMarketingAssetsSettings(affiliateId, settings)
		.then(response => {
			dispatch({ type: UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS, affiliateId: affiliateId, marketingAssets: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_FAILURE, err })
			throw err
		})
}

export const SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_REQUEST = 'SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_REQUEST'
export const SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_SUCCESS = 'SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_SUCCESS'
export const SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_FAILURE = 'SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_FAILURE'

export const SAVE_AFFILIATE_HERO_IMAGE_MOBILE_REQUEST = 'SAVE_AFFILIATE_HERO_IMAGE_MOBILE_REQUEST'
export const SAVE_AFFILIATE_HERO_IMAGE_MOBILE_SUCCESS = 'SAVE_AFFILIATE_HERO_IMAGE_MOBILE_SUCCESS'
export const SAVE_AFFILIATE_HERO_IMAGE_MOBILE_FAILURE = 'SAVE_AFFILIATE_HERO_IMAGE_MOBILE_FAILURE'

export const SAVE_AFFILIATE_LOGO_IMAGE_REQUEST = 'SAVE_AFFILIATE_LOGO_IMAGE_REQUEST'
export const SAVE_AFFILIATE_LOGO_IMAGE_SUCCESS = 'SAVE_AFFILIATE_LOGO_IMAGE_SUCCESS'
export const SAVE_AFFILIATE_LOGO_IMAGE_FAILURE = 'SAVE_AFFILIATE_LOGO_IMAGE_FAILURE'

export const SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_REQUEST = 'SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_REQUEST'
export const SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_SUCCESS = 'SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_SUCCESS'
export const SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_FAILURE = 'SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_FAILURE'

export const SAVE_AFFILIATE_PROMO_TILE_IMAGE_REQUEST = 'SAVE_AFFILIATE_PROMO_TILE_IMAGE_REQUEST'
export const SAVE_AFFILIATE_PROMO_TILE_IMAGE_SUCCESS = 'SAVE_AFFILIATE_PROMO_TILE_IMAGE_SUCCESS'
export const SAVE_AFFILIATE_PROMO_TILE_IMAGE_FAILURE = 'SAVE_AFFILIATE_PROMO_TILE_IMAGE_FAILURE'

export const getAffiliatePictureRequestType = pictureType => {
	switch (pictureType) {
		case 'desktop':
			return SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_REQUEST
		case 'mobile':
			return SAVE_AFFILIATE_HERO_IMAGE_MOBILE_REQUEST
		case 'promoSquare':
			return SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_REQUEST
		case 'promoTile':
			return SAVE_AFFILIATE_PROMO_TILE_IMAGE_REQUEST
		case 'logo':
			return SAVE_AFFILIATE_LOGO_IMAGE_REQUEST
		default:
			return ''
	}
}

export const getAffiliatePictureSuccessType = pictureType => {
	switch (pictureType) {
		case 'desktop':
			return SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_SUCCESS
		case 'mobile':
			return SAVE_AFFILIATE_HERO_IMAGE_MOBILE_SUCCESS
		case 'promoSquare':
			return SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_SUCCESS
		case 'promoTile':
			return SAVE_AFFILIATE_PROMO_TILE_IMAGE_SUCCESS
		case 'logo':
			return SAVE_AFFILIATE_LOGO_IMAGE_SUCCESS
		default:
			return ''
	}
}

export const getAffiliatePictureFailureType = pictureType => {
	switch (pictureType) {
		case 'desktop':
			return SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_FAILURE
		case 'mobile':
			return SAVE_AFFILIATE_HERO_IMAGE_MOBILE_FAILURE
		case 'promoSquare':
			return SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_FAILURE
		case 'promoTile':
			return SAVE_AFFILIATE_PROMO_TILE_IMAGE_FAILURE
		case 'logo':
			return SAVE_AFFILIATE_LOGO_IMAGE_FAILURE
		default:
			return ''
	}
}

export const saveAffiliatePicture = (affiliateId, pictureType, formData) => (dispatch, getState) => {
	dispatch({ type: getAffiliatePictureRequestType(pictureType) })
	return picturesApi.createPicture(formData)
		.then(response => {
			let data = response
			data.affiliateId = affiliateId
			data.pictureType = pictureType
			dispatch({ type: getAffiliatePictureSuccessType(pictureType), data })
			return data
		})
		.catch(err => {
			dispatch({ type: getAffiliatePictureFailureType(pictureType), err })
			throw err
		})
}

export const deleteAffiliatePicture = ( pictureId,pictureType) => (dispatch, getState) => {
	dispatch({ type: getAffiliatePictureRequestType(pictureType) })

	return picturesApi.deletePicture(pictureId)
}

export const SYNC_PRODUCTS_REQUEST = 'SYNC_PRODUCTS_REQUEST'
export const SYNC_PRODUCTS_SUCCESS = 'SYNC_PRODUCTS_SUCCESS'
export const SYNC_PRODUCTS_FAILURE = 'SYNC_PRODUCTS_FAILURE'

export const syncProducts = (affiliateId, command) => (dispatch, getState) => {
	dispatch({ type: SYNC_PRODUCTS_REQUEST })
	return affiliateApi.syncProducts(affiliateId, command)
		.then(response => {
			dispatch({ type: SYNC_PRODUCTS_SUCCESS, affiliateId: affiliateId })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNC_PRODUCTS_FAILURE, err })
			throw err
		})
}

export const SYNC_PRODUCTS_STATUS_REQUEST = 'SYNC_PRODUCTS_STATUS_REQUEST'
export const SYNC_PRODUCTS_STATUS_SUCCESS = 'SYNC_PRODUCTS_STATUS_SUCCESS'
export const SYNC_PRODUCTS_STATUS_FAILURE = 'SYNC_PRODUCTS_STATUS_FAILURE'

export const getSyncProductsStatus = (affiliateId, command) => (dispatch, getState) => {
	dispatch({ type: SYNC_PRODUCTS_STATUS_REQUEST })
	return affiliateApi.getSyncProductsStatus(affiliateId, command)
		.then(response => {
			dispatch({ type: SYNC_PRODUCTS_STATUS_SUCCESS, affiliateId: affiliateId })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNC_PRODUCTS_STATUS_FAILURE, err })
			throw err
		})
}

export const SYNC_ORDERS_REQUEST = 'SYNC_ORDERS_REQUEST'
export const SYNC_ORDERS_SUCCESS = 'SYNC_ORDERS_SUCCESS'
export const SYNC_ORDERS_FAILURE = 'SYNC_ORDERS_FAILURE'

export const syncOrders = (affiliateId, command) => (dispatch, getState) => {
	dispatch({ type: SYNC_ORDERS_REQUEST })
	return affiliateApi.syncOrders(affiliateId, command)
		.then(response => {
			dispatch({ type: SYNC_ORDERS_SUCCESS, affiliateId: affiliateId })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNC_ORDERS_FAILURE, err })
			throw err
		})
}

export const SYNC_COLLECTIONS_REQUEST = 'SYNC_COLLECTIONS_REQUEST'
export const SYNC_COLLECTIONS_SUCCESS = 'SYNC_COLLECTIONS_SUCCESS'
export const SYNC_COLLECTIONS_FAILURE = 'SYNC_COLLECTIONS_FAILURE'

export const syncCollections = (affiliateId, command) => (dispatch, getState) => {
	dispatch({ type: SYNC_COLLECTIONS_REQUEST })
	return affiliateApi.syncCollections(affiliateId, command)
		.then(response => {
			dispatch({ type: SYNC_COLLECTIONS_SUCCESS, affiliateId: affiliateId })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNC_COLLECTIONS_FAILURE, err })
			throw err
		})
}

export const SYNC_MARKETING_REQUEST = 'SYNC_MARKETING_REQUEST'
export const SYNC_MARKETING_SUCCESS = 'SYNC_MARKETING_SUCCESS'
export const SYNC_MARKETING_FAILURE = 'SYNC_MARKETING_FAILURE'

export const syncMarketing = (listingId) => (dispatch, getState) => {
	dispatch({ type: SYNC_MARKETING_REQUEST })
	return affiliateApi.syncMarketing(listingId)
		.then(response => {
			dispatch({ type: SYNC_MARKETING_SUCCESS, listingId })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNC_MARKETING_FAILURE, err })
			throw err
		})
}

export const CURATE_PRODUCTS_REQUEST = 'CURATE_PRODUCTS_REQUEST'
export const CURATE_PRODUCTS_SUCCESS = 'CURATE_PRODUCTS_SUCCESS'
export const CURATE_PRODUCTS_FAILURE = 'CURATE_PRODUCTS_FAILURE'

export const curateProducts = (affiliateId, command) => (dispatch, getState) => {
	dispatch({ type: CURATE_PRODUCTS_REQUEST })
	return affiliateApi.curateProducts(affiliateId, command)
		.then(response => {
			dispatch({ type: CURATE_PRODUCTS_SUCCESS, affiliateId: affiliateId })
			return response
		})
		.catch(err => {
			dispatch({ type: CURATE_PRODUCTS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'affiliates'

export const DEFAULT_STATE = {
	db: {},
	searches: {}
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result
		}
	}

	let db = {
		...state.db,
		...action.data.entities.affiliates
	}

	return { ...state, db, searches }
}

export const updateAffiliateState = (state, action) => {
	var updated = { ...state.db[action.data.result], ...action.data.entities.affiliates[action.data.result] }
	return { ...state, db: { ...state.db, [action.data.result]: updated }}
}


const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_AFFILIATE_SUCCESS:
		case FETCH_AFFILIATE_BY_LISTINGID_SUCCESS:
		case UPDATE_AFFILIATE_STATUS_SUCCESS:
		case UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS:
			return updateAffiliateState(state, action)
		case CREATE_AFFILIATES_SEARCH:
		case UPDATE_AFFILIATES_SEARCH:
			return handleSearchChange(state, action)
		case UPDATE_DESKTOP_HERO_IMAGE:
			return {
			...state,
			db: {
				...state.db,
				[action.payload.affiliateId]: {
				...state.db[action.payload.affiliateId],
				pageInfo:{...state.db[action.payload.affiliateId].pageInfo,desktopHeroImageUrl: action.payload.url,
					desktopHeroImageId: action.payload.id,}
				},
			},
			};
		case UPDATE_MOBILE_HERO_IMAGE:
			return {
			...state,
			db: {
				...state.db,
				[action.payload.affiliateId]: {
				...state.db[action.payload.affiliateId],
				pageInfo:{...state.db[action.payload.affiliateId].pageInfo,mobileHeroImageUrl: action.payload.url,
					mobileHeroImageId: action.payload.id,}
				},
			},
			};
		case UPDATE_PROMO_SQUARE_IMAGE:
			return {
			...state,
			db: {
				...state.db,
				[action.payload.affiliateId]: {
				...state.db[action.payload.affiliateId],
				marketingAssets:{...state.db[action.payload.affiliateId].marketingAssets,promoSquareImageUrl: action.payload.url,
					promoSquareImageId: action.payload.id,}
				},
			},
			};
		case UPDATE_PROMO_TILE_IMAGE:
			return {
			...state,
			db: {
				...state.db,
				[action.payload.affiliateId]: {
				...state.db[action.payload.affiliateId],
				marketingAssets:{...state.db[action.payload.affiliateId].marketingAssets,promoTileImageUrl: action.payload.url,
					promoTileImageId: action.payload.id,}
				},
			},
			};
		case SEARCH_AFFILIATES_SUCCESS:
			return handleSearchSuccess(state, action)
		case FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS:
		case UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS:
			var affiliate = state.db[action.affiliateId]
			if (!affiliate)
				return state
			var updated = { ...affiliate, pageInfo: action.pageInfo}
			return { ...state, db: { ...state.db, [action.affiliateId]: updated }}
		case FETCH_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS:
		case UPDATE_AFFILIATE_MARKETING_ASSETS_SETTINGS_SUCCESS:
			var affiliate = state.db[action.affiliateId]
			if (!affiliate)
				return state
			var updated = { ...affiliate, marketingAssets: action.marketingAssets}
			return { ...state, db: { ...state.db, [action.affiliateId]: updated }}
		default:
			return { ...state }
	}
}

export default reducer

export const selectAffiliatesHashMap = state => state.affiliates.affiliates.db
export const selectAffiliateSearchesHashMap = state => state.affiliates.affiliates.searches

export const selectAffiliates = createSelector(
	selectAffiliatesHashMap,
	domains => Object.keys(domains).map(k => domains[k])
)

export const selectAffiliateSearches = createSelector(
	selectAffiliateSearchesHashMap,
	searches => Object.keys(searches).map(k => searches[k])
)

export const createFilteredAffiliatesSelector = id => {
	return createSelector(
		[selectAffiliatesHashMap, selectAffiliateSearchesHashMap],
		(domains, searches) => {
			if (!searches[id] || !searches[id].result)
				return []
			let results = searches[id].result.map(f => domains[f]).filter(d => d)
			return results
		})
}

export const selectAffiliatesSearch = id => state => state.affiliates.affiliates.searches[id]
export const selectAffiliate = id => state => state.affiliates.affiliates.db[id]
export const selectAffiliateByListingId = id => state => FindAffiliateByListingId(state.affiliates.affiliates.db, id)

const FindAffiliateByListingId = (obj, listingId) => {
	if (listingId) {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				let item = obj[key]
				if (listingId == item.listingId) {
					return item
				}
			}
		}
	}
	
    return null
}

export const selectAffiliatePageInfo = (affiliateId, key) => {
	return createSelector(
		selectAffiliatesHashMap, affiliates => {
			if (!affiliates[affiliateId] || !affiliates[affiliateId].pageInfo) {
				return null
			}
			return affiliates[affiliateId].pageInfo
		})
}

export const selectAffiliateMarketingAssets = (affiliateId, key) => {
	return createSelector(
		selectAffiliatesHashMap, affiliates => {
			if (!affiliates[affiliateId] || !affiliates[affiliateId].marketingAssets) {
				return null
			}
			return affiliates[affiliateId].marketingAssets
		})
}
