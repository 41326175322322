import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";

function MuiDatePicker({
    label,
    gxId,
    disablePast = false,
    minDate,
    control,
    name,
    rules
}) {
    const [dateError, setDateError] = useState(false)
    const { control: backupControl } = useForm();
    const {
        field,
        fieldState: { error },
        formState: { touchedFields, dirtyFields, errors },
    } = useController({
        name: name,
        control: control ?? backupControl,
        rules
    });
    return <>
        <DatePicker
            value={field?.value ? moment(field.value) : null}
            control={control}
            label={label}
            onChange={(date) => { field.onChange(date) }}
            onError={(error) => error ? setDateError(true) : setDateError(false)}
            disablePast={disablePast}
            minDate={minDate}
            slotProps={{
                textField: {
                    variant: 'filled',
                    error: (!!error || !!dateError),
                    sx: {
                        width: '100%'
                    },
                    inputProps: {
                        'data-gxid': gxId,
                    }
                },
            }}
        />
        {(error || dateError) &&
            <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                {error ? 'Event Date is required' : 'Invalid date entry'}
            </p>
        }

    </>
}

export default MuiDatePicker;