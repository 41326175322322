import React from 'react'
import ReduxFormSelect from '../Inputs/ReduxFormSelect'
import { Field } from 'redux-form'
import FormControl from '@mui/material/FormControl'

const SelectField = ({ name, label, hintText, validate, items, onChange, disabled = false }) => (
	<FormControl style={{width:'100%'}}>
		<Field
			name={name}
			label={label}
			hintText={hintText}
			items={items}
			component={ReduxFormSelect}
			validate={validate}
			onChange={onChange}
			disabled={disabled}>
		</Field>
	</FormControl>
)

export default SelectField
