import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const styles = {
    status: {
        display: 'inline-block',
        marginLeft: '5px',
        padding: '5px 13px',
        borderRadius: '2rem',
        color: '#111',
        fontFamily: 'Roboto Regular,Helvetica,sans-serif',
        fontSize: '0.8125rem',
        fontWeight: '400',
    },
    active: {
        backgroundColor: '#bbe5b3', //green
    },
    cancelled: {
        backgroundColor: '#fed3d1', //red
    },
    declined: {
        backgroundColor: '#ffd79d', //orange
    },
    expired: {
        backgroundColor: '#e4e5e7', //gray
    },
    frozen: {
        backgroundColor: '#a4e8f2', //blue
    },
    pending: {
        backgroundColor: '#ffea8a', //yellow
    },
}

const BillingTermsStatusBadge = ({
    classes,
    status,
}) => (
    status ?
    <span className={classNames(classes.status,
        status == 'Active' ? classes.active :
            status == 'Cancelled' ? classes.cancelled :
                status == 'Declined' ? classes.declined :
                    status == 'Expired' ? classes.expired :
                        status == 'Frozen' ? classes.frozen :
                            status == 'Pending' ? classes.pending :
                                classes.expired)}>
        {status}
    </span>
    : <span>N/A</span>
)

export default injectSheet(styles)(BillingTermsStatusBadge)
