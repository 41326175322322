import React from 'react'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_CODE_POOL_PAGE } from '../../auth/policies'

const CodePoolPage = React.lazy(() => import("../../components/CodePools"))

export default {
	path: RouteConstants.codePools.index.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_CODE_POOL_PAGE]}
			component={<CodePoolPage />} 
		/>
	),
}