import React from 'react'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { RouteConstants } from '../../setup/routeConstants'
import { DEVELOPER_ACCESS } from '../../auth/policies'

const DevelopmentPage = React.lazy(() => import("../../components/Development"))

export default {
	path: RouteConstants.development.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[DEVELOPER_ACCESS]}
			component={<DevelopmentPage />} 
		/>
	),
}