import React from 'react'
import injectSheet from 'react-jss'
import Typography from '@mui/material/Typography'

const styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        marginBottom: '0px',
    }
}

const Header = ({
    children,
    classes,
    title,
    sx = {}
}) => (
    <div className={classes.header}>
        <Typography variant='h1' sx={{
            fontSize: '24px',
            fontWeight: '500',
            fontFamily: '"Roboto Medium", Helvetica, sans-serif',
            lineHeight: '1.5',
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: '24px',
            ...sx
        }}>
            {title || ' '}
        </Typography>
        {children}
    </div>
)

export default injectSheet(styles)(Header)
