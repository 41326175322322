import {Box} from '@mui/material'
import React, {useState} from 'react'
import {MUIFormTextInput} from './FormTextInput'

function MUIFormHexInput({label, name, control, rules = {}}) {

    const [color, setColor] = useState(null)

    return (
        <Box display={'flex'}>
            <MUIFormTextInput
                control={control}
                name={name}
                label={label}
                rules={{...rules, pattern: {value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/}}}
                maxLength="7"
                normalizeMask={false}
                mask={"#HHHHHH"}
                onChange={(v) => {
                    setColor(v)
                }}
                onInit={(v) => {
                    setColor(v)
                }}
            />
            <Box sx={{
                position: 'relative',
                zIndex: '10',
                width: '15px',
                height: '55px',
                margin: '0px 0px 0px -11px',
                verticalAlign: 'middle',
                borderRadius: '0px 5px 0px 0px',
                display: 'inline-block',
                backgroundColor: color ? color : '#ffffff',
                border: 'solid #e3e3e3 1px',
                borderLeft: 'transparent',
            }} />
        </Box>
    )
}

export default MUIFormHexInput
