/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Controller, useController, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { MUIControlledFileInput } from './ControlledFileInput'

function MUIFormFileInput({
  control,
  name,
  fileTypes = '*',
  fileTypesDescription,
  required = false,
  maxSize = null,
  processing = false,
  onChange = () => { },
  showRemoveOption = true,
  gxid = '',
}) {
  const handleChange = (field) => {
    onChange(field)
  }

  const humanSize = (kb) => {
    // return kilobytes in human readable format
    const bytes = kb * 1024
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    return `${(bytes / (1024 ** i)).toFixed(0)} ${sizes[i]}`
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={{}} // eventuially add support for default values
      rules={{
        required: {
          value: required,
          message: 'File is required',
        },
        validate: {
          maxSize: (v) => {
            if (v && maxSize && (v.size / 1024) > maxSize) {
              return `File size must be maxium of ${humanSize(maxSize)}`
            }
            return true
          },
        },
      }}
      render={({ field, fieldState }) => (
        <Box>
          <MUIControlledFileInput
            name={field.name}
            value={field.value}
            fileTypes={fileTypes}
            fileTypesDescription={fileTypesDescription}
            showRemoveOption={showRemoveOption}
            onChange={(v) => { field.onChange(v); handleChange(field) }}
            invalid={fieldState.invalid}
            processing={processing}
            innerRef={field.ref}
            gxid={gxid}
          />
          <Box sx={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
            {fieldState.error?.message}
          </Box>
        </Box>
      )}
    />
  )
}

export { MUIFormFileInput }
