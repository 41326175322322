import * as partnerListingApi from '../../../api/partnerListingApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

export const CREATE_PARTNERS_LISTING_REQUEST = 'CREATE_PARTNERS_LISTING_REQUEST'
export const CREATE_PARTNERS_LISTING_SUCCESS = 'CREATE_PARTNERS_LISTING_SUCCESS'
export const CREATE_PARTNERS_LISTING_FAILED = 'CREATE_PARTNERS_LISTING_FAILED'

export const createPartnersListing = listing => (dispatch, getState, { schema }) => {
    dispatch({ type: CREATE_PARTNERS_LISTING_REQUEST })

    return partnerListingApi.createPartnersListing(listing)
        .then(response => {
            const data = normalize(response, schema.listing)
            dispatch({ type: CREATE_PARTNERS_LISTING_SUCCESS, data })
            return response.id
        })
        .catch(err => {
            dispatch({ type: CREATE_PARTNERS_LISTING_FAILED, err })
            throw err
        })
}

export const UPDATE_PARTNERS_LISTING_REQUEST = 'UPDATE_PARTNERS_LISTING_REQUEST'
export const UPDATE_PARTNERS_LISTING_SUCCESS = 'UPDATE_PARTNERS_LISTING_SUCCESS'
export const UPDATE_PARTNERS_LISTING_FAILED = 'UPDATE_PARTNERS_LISTING_FAILED'

export const updatePartnersListing = listing => (dispatch, getState, { schema }) => {
    dispatch({ type: UPDATE_PARTNERS_LISTING_REQUEST })

    return partnerListingApi.updatePartnersListing(listing)
        .then(response => {
            dispatch(fetchPartnerListing(response.id))
            const data = normalize(response, schema.listing)
            dispatch({ type: UPDATE_PARTNERS_LISTING_SUCCESS, data })
            return response.id
        })
        .catch(err => {
            dispatch({ type: UPDATE_PARTNERS_LISTING_FAILED, err })
            throw err
        })
}


export const UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_REQUEST = 'UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_REQUEST'
export const UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_SUCCESS = 'UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_SUCCESS'
export const UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_FAILURE = 'UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_FAILURE'

export const updateEligibleForGXMarketplace = (listingId, allowGXMarketplace) => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_REQUEST })
	return partnerListingApi.updateEligibleForGXMarketplace(listingId, allowGXMarketplace)
		.then(response => {
            dispatch(fetchPartnerListing(response.id))
			const data = normalize(response, schema.listing)
			dispatch({ type: UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_FAILURE, err })
			throw err
		})
}

export const CREATE_PARTNERS_LISTINGS_SEARCH = 'CREATE_PARTNERS_LISTINGS_SEARCH'
export const UPDATE_PARTNERS_LISTINGS_SEARCH = 'UPDATE_PARTNERS_LISTINGS_SEARCH'

export const SEARCH_PARTNERS_LISTINGS_REQUEST = 'SEARCH_PARTNERS_LISTINGS_REQUEST'
export const SEARCH_PARTNERS_LISTINGS_SUCCESS = 'SEARCH_PARTNERS_LISTINGS_SUCCESS'
export const SEARCH_PARTNERS_LISTINGS_FAILURE = 'SEARCH_PARTNERS_LISTINGS_FAILURE'

export const createPartnersListingsSearch = search => (dispatch, getState, { schema }) => {
    dispatch({ type: CREATE_PARTNERS_LISTINGS_SEARCH, search })
    return new Promise(resolve => resolve(search))
}

export const updatePartnersListingsSearch = search => (dispatch, getState, { schema }) => {
    dispatch({ type: UPDATE_PARTNERS_LISTINGS_SEARCH, search })
    return new Promise(resolve => resolve(search))
}

export const searchPartnersListings = searchId => (dispatch, getState, { schema }) => {
    dispatch({ type: SEARCH_PARTNERS_LISTINGS_REQUEST })
    let search = selectPartnersListingsSearch(searchId)(getState())
    return partnerListingApi.fetchPartnersListings(search)
        .then(response => {
            const data = normalize(response.data, [schema.partnerListing])
            dispatch({ type: SEARCH_PARTNERS_LISTINGS_SUCCESS, data, total: response.total, search })
            return response
        })
        .catch(err => {
            dispatch({ type: SEARCH_PARTNERS_LISTINGS_FAILURE, err })
            throw err
        })
}

export const FETCH_PARTNER_LISTING_REQUEST = 'FETCH_PARTNER_LISTING_REQUEST'
export const FETCH_PARTNER_LISTING_SUCCESS = 'FETCH_PARTNER_LISTING_SUCCESS'
export const FETCH_PARTNER_LISTING_FAILED = 'FETCH_PARTNER_LISTING_FAILED'

export const fetchPartnerListing = listingId => (dispatch, getState, { schema }) => {
    dispatch({ type: FETCH_PARTNER_LISTING_REQUEST })
    return partnerListingApi.fetchPartnerListing(listingId)
        .then(response => {
            return partnerListingApi.fetchPartnerListingBillingRule(listingId).then((billingResponse) => {
                response.billingRules = billingResponse
                let data = normalize(response, schema.partnerListing)
                dispatch({ type: FETCH_PARTNER_LISTING_SUCCESS, data })
                return response
            });
        })
        .catch(err => {
            dispatch({ type: FETCH_PARTNER_LISTING_FAILED, err })
            throw err
        })
}

export const CREATE_BULK_OPERATION_REQUEST = 'CREATE_BULK_OPERATION_REQUEST'
export const CREATE_BULK_OPERATION_SUCCESS = 'CREATE_BULK_OPERATION_SUCCESS'
export const CREATE_BULK_OPERATION_FAILURE = 'CREATE_BULK_OPERATION_FAILURE'

export const createBulkOperation = request => (dispatch, getState, { schema }) => {
    dispatch({ type: CREATE_BULK_OPERATION_REQUEST })

    return partnerListingApi.createBulkOperation(request)
        .then(response => {
            dispatch({ type: CREATE_BULK_OPERATION_SUCCESS, response })
            return response
        })
        .catch(err => {
            dispatch({ type: CREATE_BULK_OPERATION_FAILURE, err })
            throw err
        })
}

export const GENERATE_DELEGATE_ACCESS_TOKEN_REQUEST = 'GENERATE_DELEGATE_ACCESS_TOKEN_REQUEST'
export const GENERATE_DELEGATE_ACCESS_TOKEN_SUCCESS = 'GENERATE_DELEGATE_ACCESS_TOKEN_SUCCESS'
export const GENERATE_DELEGATE_ACCESS_TOKEN_FAILURE = 'GENERATE_DELEGATE_ACCESS_TOKEN_FAILURE'

export const generateDelegateAccessToken = request => (dispatch, getState, { schema }) => {
    dispatch({ type: GENERATE_DELEGATE_ACCESS_TOKEN_REQUEST })

    return partnerListingApi.generateDelegateAccessToken(request)
        .then(response => {
            dispatch({ type: GENERATE_DELEGATE_ACCESS_TOKEN_SUCCESS, response })
            return response
        })
        .catch(err => {
            dispatch({ type: GENERATE_DELEGATE_ACCESS_TOKEN_FAILURE, err })
            throw err
        })
}

export const FETCH_DEFAULT_BILLING_RULES_REQUEST = 'FETCH_DEFAULT_BILLING_RULES_REQUEST'
export const FETCH_DEFAULT_BILLING_RULES_SUCCESS = 'FETCH_DEFAULT_BILLING_RULES_SUCCESS'
export const FETCH_DEFAULT_BILLING_RULES_FAILED = 'FETCH_DEFAULT_BILLING_RULES_FAILED'

export const fetchDefaultBillingRules = () => (dispatch, getState, { schema }) => {
    dispatch({ type: FETCH_DEFAULT_BILLING_RULES_REQUEST })
    return partnerListingApi.fetchDefaultBillingRules()
        .then(response => {
            let data = normalize(response, schema.defaultBillingTerms)
            dispatch({ type: FETCH_DEFAULT_BILLING_RULES_SUCCESS, data })
            return response
        })
        .catch(err => {
            dispatch({ type: FETCH_DEFAULT_BILLING_RULES_FAILED, err })
            throw err
        })
}


export const GET_WEBHOOK_SETTINGS_REQUEST = 'GET_WEBHOOK_SETTINGS_REQUEST'
export const GET_WEBHOOK_SETTINGS_SUCCESS = 'GET_WEBHOOK_SETTINGS_SUCCESS'
export const GET_WEBHOOK_SETTINGS_FAILURE = 'GET_WEBHOOK_SETTINGS_FAILURE'

export const getWebhookSettings = appId => (dispatch, getState, { schema }) => {
    dispatch({ type: GET_WEBHOOK_SETTINGS_REQUEST })

    return partnerListingApi.getWebhookSettings(appId)
        .then(response => {
            console.log('getWebhookSettings', { response })
            dispatch({ type: GET_WEBHOOK_SETTINGS_SUCCESS, response })
            return response
        })
        .catch(err => {
            dispatch({ type: GET_WEBHOOK_SETTINGS_FAILURE, err })
            throw err
        })
}

export const SET_WEBHOOK_SETTINGS_REQUEST = 'SET_WEBHOOK_SETTINGS_REQUEST'
export const SET_WEBHOOK_SETTINGS_SUCCESS = 'SET_WEBHOOK_SETTINGS_SUCCESS'
export const SET_WEBHOOK_SETTINGS_FAILURE = 'SET_WEBHOOK_SETTINGS_FAILURE'

export const setWebhookSettings = request => (dispatch, getState, { schema }) => {
    dispatch({ type: SET_WEBHOOK_SETTINGS_REQUEST })

    return partnerListingApi.setWebhookSettings(request)
        .then(response => {
            dispatch({ type: SET_WEBHOOK_SETTINGS_SUCCESS, response })
            return response
        })
        .catch(err => {
            dispatch({ type: SET_WEBHOOK_SETTINGS_FAILURE, err })
            throw err
        })
}

export const STATE_KEY = 'listings'

export const DEFAULT_STATE = {
    db: {},
    searches: {},
}

export const handleSearchChange = (state, action) => {
    const searches = { ...state.searches, [action.search.id]: action.search }
    return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
    let searches = {
        ...state.searches,
        [action.search.id]: {
            ...state.searches[action.search.id],
            total: action.total,
            result: action.data.result
        }
    }

    let db = {
        ...state.db,
        ...action.data.entities.partnerListings
    }

    return { ...state, db, searches }
}

export const updatePartnerListingState = (state, action) => {
    var updated = { ...state.db[action.data.result], ...action.data.entities.partnerListings[action.data.result] }
    return { ...state, db: { ...state.db, [action.data.result]: updated } }
}

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_PARTNER_LISTING_SUCCESS:
            return updatePartnerListingState(state, action)
        case CREATE_PARTNERS_LISTINGS_SEARCH:
        case UPDATE_PARTNERS_LISTINGS_SEARCH:
            return handleSearchChange(state, action)
        case SEARCH_PARTNERS_LISTINGS_SUCCESS:
            return handleSearchSuccess(state, action)
        default:
            return { ...state }
    }
}

export default reducer

export const selectPartnersListingsHashMap = state => state.partners.listings.db
export const selectPartnersListingsSearchesHashMap = state => state.partners.listings.searches

export const selectPartnersListings = createSelector(selectPartnersListingsHashMap, partnersListings => Object.keys(partnersListings).map(k => partnersListings[k]))

export const selectPartnersListingsSearches = createSelector(selectPartnersListingsSearchesHashMap, searches =>
    Object.keys(searches).map(k => searches[k])
)

export const createFilteredPartnersListingsSelector = id => {
    return createSelector([selectPartnersListingsHashMap, selectPartnersListingsSearchesHashMap], (partnersListings, searches) => {
        if (!searches[id] || !searches[id].result) return []
        let results = searches[id].result
            .map(f => partnersListings[f])
            .filter(d => d)
            .filter(u => !u.deleted)
        return results
    })
}

export const selectPartnersListingsSearch = id => state => state.partners.listings.searches[id]

export const selectPartnerListing = id => createSelector(selectPartnersListingsHashMap, partnersListings => partnersListings[id.toLowerCase()])
