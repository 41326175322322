import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import MUITextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import { IMaskInput } from 'react-imask';

const Wrapper = ({ children }) => <div style={{ width: '100%', overflow: 'hidden' }}>{children}</div>

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, mask, ...other } = props;
	return (
		<Wrapper>
			<IMaskInput
				{...other}
				mask={mask}
				definitions={{
					'#': /[0-9]/,
				}}
				inputRef={ref}
			/>
		</Wrapper>
	);
});

TextMaskCustom.propTypes = {
	name: PropTypes.string.isRequired,
};

export const renderTextField = ({
	input,
	label,
	placeholder,
	fullWidth = true,
	meta: { touched, error },
	mask,
	onChange,
	...custom
}) => {

	return (
	<Wrapper>
		<MUITextField
			{...input}
			variant='filled'
			fullWidth={fullWidth}
			label={label}
			placeholder={placeholder}
			error={touched && !!error}
			InputProps={{
				inputComponent: TextMaskCustom,
				inputProps: {
					mask,
					onChange
				}
			}}
			{...custom}
		/>
		{touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
	</Wrapper>
)}



const TextField = ({ name, label, placeholder, shrink, fullWidth, validate, mask, onChange, ...custom }) => (
	<Field
		name={name}
		label={label}
		placeholder={placeholder}
		fullWidth={fullWidth}
		component={renderTextField}
		validate={validate}
		mask={mask}
		onChange={onChange}
		{...custom}
	/>
)

TextField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	validate: PropTypes.array,
}

export default TextField
