/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { deleteVerificationRequest, hideModal } from '../../redux/actions'
import ModalContainer from './ModalContainer'

export function DeleteVerificationRequestModal({ verificationRequest }) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const onDelete = () => dispatch(deleteVerificationRequest(verificationRequest.id)).then(dispatch(hideModal()))

  return (
    <ModalContainer
      title="Delete verification request"
      modal
      maxWidth="sm"
      fullWidth="false"
      actions={{
        primaryAction: {
          label: 'Delete record',
          onClick: () => onDelete(verificationRequest),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <Typography style={{ marginTop: '10px' }}>Are you sure you want to delete this verification request?</Typography>
    </ModalContainer>
  )
}

export default DeleteVerificationRequestModal
