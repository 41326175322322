import { allowedVerificationTypeGroup } from '../../../api/schema'
import * as allowedVerificationTypeGroupsApi from '../../../api/allowedVerificationTypeGroupsApi'
import { normalize, denormalize } from 'normalizr'
import { createSelector } from 'reselect'
import { UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS } from './occupations'

export const FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_REQUEST = 'FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_REQUEST'
export const FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_SUCCESS = 'FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_SUCCESS'
export const FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_FAILURE = 'FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_FAILURE'

export const fetchAllowedVerificationTypeGroups = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_REQUEST })
	return allowedVerificationTypeGroupsApi.fetchAllowedVerificationTypeGroups()
		.then(response => {
			const data = normalize(response, [allowedVerificationTypeGroup])
			dispatch({ type: FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'allowedVerificationTypeGroups'

export const DEFAULT_STATE = {
	db: {}
}

export const mergeAllowedVerificationTypeGroups = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.allowedVerificationTypeGroups }}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_ALLOWED_VERIFICATIONTYPE_GROUPS_SUCCESS:
		case UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS:
			return mergeAllowedVerificationTypeGroups(state, action)
		default:
			return { ...state }
	}
}

export default reducer

export const selectAllowedVerificationTypeGroupsHashMap = state => state.verification.allowedVerificationTypeGroups.db

export const selectAllowedVerificationTypeGroups = createSelector(
	selectAllowedVerificationTypeGroupsHashMap,
	allowedVerificationTypeGroups => Object.keys(allowedVerificationTypeGroups).map(k => allowedVerificationTypeGroups[k])
)

export const selectAllowedVerificationTypeGroup = id => state => state.verification.allowedVerificationTypeGroups.db[id]

export const selectAllowedVerificationTypeGroupsForOccupation = occupationId => createSelector(selectAllowedVerificationTypeGroups, allowedVerificationTypeGroups => allowedVerificationTypeGroups.find(p => p.occupationId === occupationId))