import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import ModalContainer from '../../ModalContainer'
import { getPartnerListingPath } from '../../../../setup/routeConstants'
import { hideModal, updatePartnersListing, showErrorNotification } from '../../../../redux/actions'
import { LOCATION_CHANGE } from '../../../../setup/history'
import { MUIFormTextInput } from '../../../@Common/Form/TextInput/FormTextInput'
import HelperText from './HelperText'

export function UpdatePartnersListingModal({
  partnerListing,
}) {
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: partnerListing.name,
      website: partnerListing.website,
      commissionRate: partnerListing.billingRules.commissionRate,
    },
  })

  const onSubmit = async (values) => {
    setProcessing(true)

    const pl = {
      id: partnerListing.id,
      name: values.name,
      description: partnerListing.description,
      website: values.website,
      status: partnerListing.status,
      logoId: partnerListing.logoPictureId,
      billingRules: {
        commissionRate: values.commissionRate,
        flatRate: null,
      },
    }

    dispatch(updatePartnersListing(pl))
      .then((id) => {
        setProcessing(false)
        navigate(getPartnerListingPath(id))
        dispatch({ type: LOCATION_CHANGE })
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('Form error', err)
        dispatch(showErrorNotification('Something went wrong. Please try that again.'))
        dispatch(hideModal())
      })
  }

  return (
    <ModalContainer
      title="Edit Listing"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: { label: 'Save', onClick: handleSubmit(onSubmit) },
        secondaryAction: { label: 'Cancel', onClick: () => dispatch(hideModal()) },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Box mb="16px">
          <MUIFormTextInput
            name="name"
            label="Listing Name"
            autoFocus
            control={control}
            shrinkLabel
            rules={{
              required: {
                value: true,
                message: 'Listing name is required',
              },
            }}
          />
          <HelperText>
            Typically this is the name of your organization.
            Keep in mind, the listing name will be visible to your customers.
            You can always change this later.
          </HelperText>
        </Box>
        <MUIFormTextInput
          name="website"
          label="Website URL"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Website URL is required',
            },
            pattern: {
              value: /^https?:\/\//,
              message: 'Website URL must start with http:// or https://',
            },
          }}
        />
        <HelperText>
          Please enter the URL of the website where you intend to deploy GovX verification.
        </HelperText>

        <Box margin={{ marginTop: '16px' }}>
          <Box display="flex" sx={{ justifyContent: 'space-between' }}>
            <Box style={{ fontSize: '.875rem' }}>
              <Typography style={{ margin: '0px' }}>
                <strong>Billing Rules</strong>
              </Typography>
              <Box>
                Defines the commission rate on a sale
              </Box>
            </Box>
            <Box display="flex" sx={{ alignItems: 'center', width: '50%' }}>
              <Box width="45%">
                <MUIFormTextInput
                  control={control}
                  type="number"
                  name="commissionRate"
                  label="Commission"
                  rules={{
                    required: {
                      value: true,
                      message: 'Commission rate is required',
                    },
                    min: 0,
                    max: 100,
                  }}
                  suffix="%"
                  shrinkLabel="true"
                />
              </Box>
            </Box>
          </Box>
          {/* <Box style={{ paddingLeft: '50%' }}>
                        {formInfo.billingFlatRate && formInfo.billingFlatRate.touched &&
            formErrors.billingFlatRate &&
            <FormHelperText error={true}>Billing Flate Rate is Required
                          </FormHelperText>}
                    </Box> */}
        </Box>
      </form>
    </ModalContainer>
  )
}

UpdatePartnersListingModal.propTypes = {
  partnerListing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    website: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    logoPictureId: PropTypes.number,
    commissionRate: PropTypes.number.isRequired,
    billingRules: PropTypes.shape({
      commissionRate: PropTypes.number.isRequired,
      flatRate: PropTypes.number,
    }).isRequired,
  }).isRequired,
}

export default UpdatePartnersListingModal
