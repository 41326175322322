/* eslint-disable react/prop-types */
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { showModal } from '../../../../../../redux/actions'
import DialogSubsectionHeadline from '../../../../../Header/DialogSubsectionHeadline'

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export function ApiSection({ listing }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const createNewBearer = () => dispatch(showModal('SHOW_BEARER', { listing }))

  return (
    <div>
      <div>
        <DialogSubsectionHeadline>Bearer Token</DialogSubsectionHeadline>
      </div>
      <div>
        <div className={classes.header}>
          <Typography style={{ margin: '10px 0px' }}>
            <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={createNewBearer}>
              Create New Bearer
            </Button>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ApiSection
