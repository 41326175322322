import React from 'react'
import FontFaceObserver from 'font-face-observer' // github.com/cymen/font-face-observer
import * as govxStorage from '../localStorage/govxStorage'

export function loadFontFaceObserver() {
	if (!govxStorage.getFontsLoaded()) {
		Promise.all([
			new FontFaceObserver('Roboto', { weight: 400 }).check(),
			new FontFaceObserver('Roboto Light', { weight: 300 }).check(),
			new FontFaceObserver('Roboto Medium', { weight: 500 }).check()
		])
		.then(() => {
			document.body.classList.add('fonts-loaded')
			govxStorage.setFontsLoaded()
		})
		.catch(err => {
		})
	} else {
		document.body.classList.add('fonts-loaded')
	}
}