import { createSlice } from "@reduxjs/toolkit";
import { api } from './apiSlice'

export const verificationsApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getVerificationListingByPartnerListingId: build.query({
            query: partnerListingId => `/listing?partnerListingId=${partnerListingId}`,
            providesTags: ['VerificationListing']
        }),
        createVerificationListing: build.mutation({
            query: newVerificationListing => ({
                url: 'listings',
                method: 'POST',
                body: newVerificationListing
            }),
            invalidatesTags: ['VerificationListing']
        })
    }),
})

const initialState = [];
const verificationsSlice = createSlice({
    name: 'verificationNew',
    initialState,
    reducers: {},
})

export const {
    useGetVerificationListingByPartnerListingIdQuery,
    useLazyGetVerificationListingByPartnerListingIdQuery,
    useCreateVerificationListingMutation
} = verificationsApiSlice;

export default verificationsSlice.reducer;