import React from 'react'
import {connect} from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import {clearNotification} from '../../redux/actions'
import {selectNotification} from '../../redux/selectors'
import {Alert, Zoom} from '@mui/material'
import {CheckCircle, Error} from '@mui/icons-material'

export const GlobalNotification = ({message, showing, clearNotification, severity}) => (
	<Snackbar
		open={showing}
		onClose={() => clearNotification(severity)}
		onClick={() => clearNotification(severity)}
		anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
		TransitionComponent={Zoom}
		autoHideDuration={5000}>
		<Alert
			onClose={() => clearNotification(severity)}
			severity={severity}
			iconMapping={{
				success: <CheckCircle fontSize="inherit" />,
				error: <Error fontSize="inherit" />,
			}}>
			{message || ''}
		</Alert>
	</Snackbar>
)

const mapStateToProps = (state, ownProps) => (selectNotification(state))

const mapDispatchToProps = dispatch => ({
	clearNotification: (severity) => dispatch(clearNotification(severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotification)