import { createSlice } from "@reduxjs/toolkit";
import { api } from './apiSlice'

const API_PATH_QR_CODE_SETTINGS = '/partners/listings/shopify/quick-response-code-settings'

export const shopifyApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getQuickResponseCodeSettings: build.query({
            query: appId => `${API_PATH_QR_CODE_SETTINGS}?appid=${appId}`,
            providesTags: ['QuickResponseCodeSettings']
        }),
        setQuickResponseCodeSettings: build.mutation({
            query: (body) => ({
                url: API_PATH_QR_CODE_SETTINGS,
                method: 'POST',
                body
            }),
            invalidatesTags: ['QuickResponseCodeSettings'],
        }),
        repairShopifyInstall: build.mutation({
            query: appId => ({
                url: `/partners/listings/shopify/repair-install`,
                method: 'POST',
                body: { appId }
            }),
        }),
        searchBulkOperations: build.mutation({
            query: (body) => ({
                url: '/partners/listings/shopify/search-bulk-operations',
                method: 'POST',
                body,
            }),
        })
    }),
})

const initialState = [];
const partnersSlice = createSlice({
    name: 'shopify',
    initialState,
    reducers: {},
})

export const {
    useGetQuickResponseCodeSettingsQuery,
    useSetQuickResponseCodeSettingsMutation,
    useRepairShopifyInstallMutation,
    useSearchBulkOperationsMutation
} = shopifyApiSlice;

export default partnersSlice.reducer;