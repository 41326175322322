import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'
import TabWithSubNav from '../../../../TabWithSubNav'
import MessagingForm from './Forms/MessagingSectionForm'
import TemplateSection from '../../../../Listings/Listing/Tabs/Settings/TemplateSection'
import CredentialsSection from '../../../../Listings/Listing/Tabs/Deploy/CredentialsSection'
import LoginSection from '../../../../Listings/Listing/Tabs/Deploy/LoginSection'
import EmailSection from '../../../../Listings/Listing/Tabs/Settings/EmailSection'
import DataSharing from '../../../../Listings/Listing/Tabs/Settings/DataSharingSection'
import RedirectsSection from '../../../../Listings/Listing/Tabs/Deploy/RedirectsSection'
import AdvertisementSection from '../../../../Listings/Listing/Tabs/Settings/AdvertisementSection'
import OccupationsSection from '../../../../Listings/Listing/Tabs/Settings/OccupationsSection'
import CampaignsSection from '../../../../Listings/Listing/Tabs/Deploy/CampaignsSection'
import TicketsSection from '../../../../Listings/Listing/Tabs/Deploy/TicketsSection'
import OffersSection from '../../../../Listings/Listing/Tabs/Settings/OffersSection'
import { fetchTicketSettings } from '../../../../../redux/actions'
import { hasPolicy, selectListingSettings } from '../../../../../redux/selectors'
import {
  ACCESS_LISTING_SETTINGS_DATA_SHARING_TAB,
  EDIT_LISTING_DEPLOY_ADVANTAGE_LINKS,
} from '../../../../../auth/policies'
import OAuthSection from '../../../../Listings/Listing/Tabs/Deploy/OAuthSection'

export function GovXIDTab() {
  const dispatch = useDispatch()

  const { verificationListing } = useOutletContext()

  const canAccessListingSettingsDataSharingTab = useSelector(hasPolicy(ACCESS_LISTING_SETTINGS_DATA_SHARING_TAB))
  const canEditListingDeployAdvantageLinks = useSelector(hasPolicy(EDIT_LISTING_DEPLOY_ADVANTAGE_LINKS))
  const ticketSettings = useSelector(selectListingSettings(verificationListing?.id, 'tickets'))

  const getTicketSettings = () => dispatch(fetchTicketSettings(verificationListing))

  useEffect(() => {
    if (verificationListing) {
      getTicketSettings()
    }
  }, [verificationListing?.id])

  return (
    <div style={{ margin: '30px 0 0 0' }}>
      {verificationListing && (
        <TabWithSubNav
          items={[
            { primaryText: 'Affiliations', component: <OccupationsSection listing={verificationListing} /> },
            { primaryText: 'Listing Display', component: <TemplateSection listing={verificationListing} /> },
            { primaryText: 'Listing Messaging', component: <MessagingForm verificationListing={verificationListing} isWizard={false} /> },
            { primaryText: 'Campaigns', component: <CampaignsSection listing={verificationListing} /> },
            ...(canAccessListingSettingsDataSharingTab ? [{ primaryText: 'Data Sharing', component: <DataSharing listing={verificationListing} /> }] : []),
            { primaryText: 'Email Settings', component: <EmailSection listing={verificationListing} /> },
            { primaryText: 'Offer Page', component: <OffersSection listing={verificationListing} /> },
            { primaryText: 'Advertising', component: <AdvertisementSection listing={verificationListing} /> },
            ...((canEditListingDeployAdvantageLinks
              || (ticketSettings && (ticketSettings.entertainerId || ticketSettings.venueId)))
              ? [{ primaryText: 'Ticket Links', component: <TicketsSection listing={verificationListing} ticketSettings={ticketSettings} canEditListingDeployAdvantageLinks={canEditListingDeployAdvantageLinks} /> }]
              : []),
            { primaryText: 'OAuth Links', component: <OAuthSection listing={verificationListing} /> },
            { primaryText: 'Whitelisted URLs', component: <RedirectsSection listing={verificationListing} /> },
            { primaryText: 'Credentials', component: <CredentialsSection listing={verificationListing} /> },
            { primaryText: 'GOVX Login', component: <LoginSection listing={verificationListing} /> },
          ]}
        />
      )}
    </div>
  )
}

GovXIDTab.propTypes = {
  verificationListing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
}

export default GovXIDTab
