/* eslint-disable react/prop-types */
/* eslint-disable */
import React, { useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { GridToolbar } from '@mui/x-data-grid'
import { useLocation } from 'react-router-dom'
import useFilterParams from './useFilterParams'
import { buildSearchParams } from './filters'

function DataTable({
  columns,
  rowSelection = false,
  rows,
  pagination = true,
  disableColumnMenu = false,
  paginationMode = 'client',
  disableColumnPinning = true,
  disableColumnSelector = true,
  disableDensitySelector = true,
  disableExport = true,
  pageSizeOptions = [10, 25, 50, 100, 250, 500, 1000, 10000],
  noRowOverlay = null,
  customFooter = null,
  enableFilterParams = false,
  defaultFilters = null,
  handleServerPaginationChange = null,
  autoHeight = false,
  filterColumnSelectWidth = null,
  ...props
}) {
  const { pathname } = useLocation()
  const [sort, setSort] = useState([])
  const [paginationModel, setPaginationModel] = React.useState()
  const {
    filters, setFilters, setSearchParams, sortParams, setSortParams, paginationParams, setPaginationParams,
  } = enableFilterParams
      ? useFilterParams({ columns, defaultFilters })
      : {
        filters: null,
        setFilters: null,
        setSearchParams: null,
        setSortParams: null,
        sortParams: null,
        paginationParams: null,
        setPaginationParams: null,
      }
  return (
    <div>
      <DataGridPro
        filterModel={enableFilterParams ? {
          items: filters.items,
          logicOperator: filters?.logicOperator,
        } : {
          items: [],
          logicOperator: 'and',
        }}
        sortModel={sortParams || sort}
        onFilterModelChange={enableFilterParams ? (model) => {
          setSearchParams(buildSearchParams(model, pathname, sortParams, paginationParams))
          setFilters({
            items: model.items,
            logicOperator: model.logicOperator,
          })
        } : null}
        onSortModelChange={enableFilterParams ? (model) => {
          setSearchParams(buildSearchParams(filters, pathname, model, paginationParams))
          setSortParams(model)
        } : (model) => setSort(model)}
        paginationModel={paginationParams || paginationModel}
        onPaginationModelChange={enableFilterParams ? (model) => {
          setSearchParams(buildSearchParams(filters, pathname, sortParams, model))
          setPaginationParams(model)
        } : handleServerPaginationChange ? (model) => {
          handleServerPaginationChange(model)
        } : (model) => setPaginationModel(model)
        }
        pagination={pagination}
        paginationMode={paginationMode}
        sx={{
          border: '1px solid rgb(204, 204, 204)',
          fontFamily: '"Roboto Regular", Helvetica, sans-serif',
          borderRadius: '2px',
          fontSize: '0.875rem',
          color: '#222',
          '.MuiDataGrid-footerContainer .MuiDataGrid-withBorderColor': {
            marginBottom: !pagination ? '-50px' : '',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f1f1f1',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:first-of-type': {
            paddingLeft: props.checkboxSelection ? '0px' : '15px',
            paddingRight: props.checkboxSelection ? '0px' : '',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader': {
            paddingRight: '20px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type': {
            paddingLeft: props.checkboxSelection ? '0px' : '15px',
          },
          '.MuiDataGrid-footerContainer': {
            display: 'block',
            borderTop: 'none',
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: props.onRowClick ? 'pointer' : 'default',
          },
          '& .MuiDataGrid-toolbarContainer': {
            backgroundColor: 'rgb(241, 241, 241)',
          },
          '.MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          '.MuiDataGrid-virtualScroller': {
            minHeight: rows.length > 0 ? null : '400px',
          },
        }}
        columns={columns}
        autoHeight={true}
        rows={rows}
        rowSelection={rowSelection}
        disableColumnMenu={disableColumnMenu}
        disableColumnPinning={disableColumnPinning}
        disableColumnSelector={disableColumnSelector}
        disableDensitySelector={disableDensitySelector}
        pageSizeOptions={pageSizeOptions}
        initialState={{
          ...props.initialState,
          pagination: {
            ...props.initialState?.pagination,
          },
        }}
        slots={{
          noRowsOverlay: () => noRowOverlay,
          toolbar: GridToolbar,
          ...(customFooter && { footer: () => customFooter }),
        }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: disableExport },
            csvOptions: { disableToolbarButton: disableExport },
          },
          filterPanel: {
            sx: {
              '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: '5px' },
              '& .MuiDataGrid-filterFormColumnInput': { mr: '5px', width: filterColumnSelectWidth ? filterColumnSelectWidth : null },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: '5px' },
              '& .css-1nrlq1o-MuiFormControl-root': { width: '100%' },
            },
          },
        }}
        {...props}
      />
    </div>
  )
}

export default DataTable
