/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import AdvertisingFormPartial from './AdvertisingFormPartial'
import {
  fetchListingAdvertisementSettings,
  fetchListingSpecialOffers,
  updateListingAdvertisementSettings,
} from '../../../../redux/actions'
import {
  selectListingSettings,
} from '../../../../redux/selectors'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        minHeight: '400px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function AdvertisingForm({
  listing,
  innerRef,
}) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)

  const initialValues = useSelector(selectListingSettings(listing.id, 'advertisement'))

  const { handleSubmit, ...form } = useForm()

  const getListingAdvertisementSettings = () => {
    dispatch(fetchListingAdvertisementSettings(listing.id))
      .then(() => setProcessing(false))
  }
  const getListingSpecialOffers = () => {
    dispatch(fetchListingSpecialOffers(listing.id))
      .then(() => setProcessing(false))
  }

  useEffect(() => {
    getListingAdvertisementSettings()
    getListingSpecialOffers()
  }, [])

  useEffect(() => {
    form.reset(initialValues)
  }, [initialValues])

  const onSubmit = (values) => {
    const settings = {}
    settings.welcomeFooter = values.welcomeFooter
    settings.advertisement = values.advertisement
    settings.specialOfferHeadline = values.specialOfferHeadline
    settings.specialOfferIntro = values.specialOfferIntro

    dispatch(updateListingAdvertisementSettings(listing.id, settings))
  }

  return (
    <div style={{ position: 'relative', width: 'calc(100% - 2px)' }}>
      {
    processing
      ? <Processing />
      : (
        <form ref={innerRef} onSubmit={handleSubmit(onSubmit)}>
          <AdvertisingFormPartial form={form} listingId={listing.id} />
        </form>
      )
  }
    </div>
  )
}

export default AdvertisingForm
