import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import {
  hideModal, searchUsersByClaim, deleteUserClaim, deleteUserRole,
} from '../../../redux/actions'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'

export function RemoveListingUserModal({
  userId,
  listing,
  claimType,
}) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, control } = useForm({
    defaultValues: {
      removePartnerRole: false,
    },
  })

  const onSubmit = (values) => {
    setProcessing(true)

    const claim = {
      type: claimType,
      value: listing.id,
    }

    return dispatch(deleteUserClaim(userId, claim))
      .then(() => {
        setProcessing(false)

        if (values.removePartnerRole) dispatch(deleteUserRole(userId, 'Partner'))

        dispatch(searchUsersByClaim(claim, 'users-listing'))
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('removeUserClaim error', err)
      })
  }

  return (
    <ModalContainer
      title="Remove User"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Remove',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '25px' }}>
        <div>
          Are you sure you want to remove this user from the
          {' '}
          { listing.name }
          {' '}
          listing? They will no longer be able to access the listing.
        </div>
        <MUIFormCheckboxInput
          name="removePartnerRole"
          label="Remove Partner role"
          control={control}
        />
      </form>
    </ModalContainer>
  )
}

RemoveListingUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  claimType: PropTypes.string.isRequired,
}

export default RemoveListingUserModal
