import React from 'react'
import injectSheet from 'react-jss'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'

const styles = {
	overlay: {
		position: 'absolute',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
		height: 'calc(100vh - 56px)',
		backgroundColor: 'rgba(255,255,255,.8)',
		overflow: 'hidden',
		zIndex: '100',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inner: {
		padding: '.5rem',
		backgroundColor: '#fff',
		zIndex: '101',
	},
}

const Loader = ({ classes }) => (
	<div className={ classes.overlay }>
		<Paper  className={ classes.inner} elevation={0}>
			<CircularProgress />
		</Paper>
	</div>
)

export default injectSheet(styles)(Loader)