import React from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'

export function AutoCompleteInput({
  options = [],
  id,
  name,
  value,
  label,
  itemLabelKey,
  noOptionsText = 'No options',
  onChange,
  onInputChange,
  gxid,
  fieldState,
  ...props
}) {
  return (
    <Box>
      <Autocomplete
        id={id}
        value={value}
        options={options}
        noOptionsText={noOptionsText}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={(item) => item[itemLabelKey ?? 'label'] ?? item}
        renderInput={(params) =>
          <TextField
            {...params}
            label={label}
            fullWidth
            variant='filled'
            data-gxid={gxid}
            error={!!fieldState.error}
          />}
        renderGroup={(params) => {
          return (
            <div data-gxid={`autocompleteGroup-${gxid || id || name}`} {...params}>
              {params.group}
            </div>
          )
        }}
        {...props}
      />
    </Box>

  )
}
