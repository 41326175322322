import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Button from '../../../../Button'

const useStyles = createUseStyles({
  footer: {
    height: 'auto',
    backgroundColor: '#f1f1f1',
    padding: '10px 30px',
    marginTop: '30px',
    display: 'flex',
    marginLeft: '-20px',
    '&>Button': { marginLeft: 'auto' },
  },
})

export function Footer({ label, processing, onSubmit }) {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Button label={label} color="primary" disabled={processing} onClick={onSubmit}>
        {label}
      </Button>
    </div>
  )
}

Footer.propTypes = {
  label: PropTypes.string.isRequired,
  processing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default Footer
