import React from 'react'
import TextField from '../Fields/TextField'
import { YearMask } from '../../masks'
import { year } from '../../validation'

export const formatYear = value => {
	return YearMask.mask(value)
}

export const normalizeYear = value => {
	return YearMask.unmask(value)
}

const YearField = ({name, label, preValidate, postValidate}) => (
	<TextField
		name={name}
		label={label}
		placeholder='YYYY'
		mask='####'
		validate={[...preValidate, ...[year], ...postValidate]}
	/>
)

export default YearField