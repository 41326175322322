/* eslint-disable react/prop-types */
import React from 'react'
import Grid from '@mui/material/Grid'
import GovXLoginForm from '../../../Forms/GovXLoginForm'

export function LoginSection({
  listing,
}) {
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <GovXLoginForm listing={listing} />
        </Grid>
      </Grid>
    </div>
  )
}

export default LoginSection
