import { getJson, postJson, putJson } from './baseApi'
import {
    removeUtcFromDateTime,
} from '../formatting'

const toDateTimeUrl = (momentDateTime) => encodeURIComponent(removeUtcFromDateTime(momentDateTime).format())

const getDateRangePairs = (filter, key) => {
    const range = filter[key]
    if (!range) {
        return []
    }
    const pairs = []
    if (range.from) {
        pairs.push(`${key}.from=${toDateTimeUrl(range.from)}`)
    }
    if (range.to) {
        pairs.push(`${key}.to=${toDateTimeUrl(range.to)}`)
    }
    pairs.push(`${key}.type=${range.type}`)
    return pairs
}

const getMenuPairs = (filter, key) => filter[key]
    .filter(x => x.selected)
    .map(x => `${key}=${x.type}`)

const buildQuery = (search) => {
    const { paging, sorting, filter } = search
    const groupings = { paging, sorting }
    const pairs = []
    for (let groupKey in groupings) {
        let grouping = groupings[groupKey]
        for (let key in grouping) {
            let value = grouping[key]
            if (value != null) {
                pairs.push(`${key}=${value}`)
            }
        }
    }
    const listingStatusesPairs = getMenuPairs(filter, 'listingStatuses')
    const marketplaceStatusesPairs = getMenuPairs(filter, 'marketplaceStatuses')
    const createdPairs = getDateRangePairs(filter, 'created')
    const updatedPairs = getDateRangePairs(filter, 'updated')
    return pairs
        .concat(createdPairs)
        .concat(updatedPairs)
        .concat(listingStatusesPairs)
        .concat(marketplaceStatusesPairs)
        .join('&')
}

const options = { timeout: 30000 }

export const fetchPartnersListings = search => getJson(`/api/partners/listings?${buildQuery(search)}`)

export const createPartnersListing = listing => postJson('/api/partners/listings', listing, options)

export const updatePartnersListing = listing => putJson(`/api/partners/listings/${listing.id}`, listing, options)

export const fetchPartnerListing = listingId => getJson(`/api/partners/listing/${listingId}`)

export const createBulkOperation = request => postJson('/api/partners/listings/create-bulk-operation', request, options)

export const generateDelegateAccessToken = request => postJson('/api/partners/listings/generate-delegate-access-token', request, options)

export const fetchPartnerListingBillingRule = listingId => getJson(`/api/resources/billing-rules/listing/${listingId}`)

export const fetchDefaultBillingRules = () => getJson(`/api/resources/billing-rules`)

export const updateEligibleForGXMarketplace = (listingId, allowGXMarketplace) => {
	return postJson(`/api/partners/listings/${listingId}/update-eligible-for-gx-marketplace?eligibleForGXMarketplace=${allowGXMarketplace}`)
}


const API_PATH_WEBHOOK_SETTINGS = '/api/partners/listings/webhook-settings'
export const getWebhookSettings = appId => getJson(`${API_PATH_WEBHOOK_SETTINGS}?appid=${appId}`)
export const setWebhookSettings = request => postJson(API_PATH_WEBHOOK_SETTINGS, request, options)
