import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose, withState, lifecycle } from 'recompose'
import {
	fetchOffersByAffiliateId,
	showModal,
} from '../../../../redux/actions'
import { selectOffersByAffiliateId } from '../../../../redux/selectors'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import StyledLink from '../../../StyledLink'
import { withStyles } from '@mui/styles'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { useParams, withRouter } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
	inputLabel: {
		margin: '20px 0 20px',
		padding: '15px 0',
		fontSize: '14px',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		borderBottom: '2px solid #ddd',
	},
	tableHeadRow: {},
	tableHeadCell: {
		fontSize: '14px',
		color: '#222',
		fontWeight: '600',
		position: 'relative',
	},
	tableHeadCellDivider: {
		position: 'absolute',
		borderLeft: '1px solid #bbb',
		top: '25%',
		left: '0',
		height: '50%',
		margin: '0 auto',
	},
	tableCell: {
		fontSize: '14px',
	},
	editLink: {
		textTransform: 'none',
		color: '#0E5777',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		fontWeight: 400,
		textDecoration: 'none',
		cursor: 'pointer',

		'&:hover, &:focus': {
			color: '#000',
			background: 'none',
		},
	},
})

const OffersTable = ({
	canUpdate,
	affiliate,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const params = useParams()
	let offers = useSelector(selectOffersByAffiliateId(affiliate.id))
	
	const getOffersByAffiliateId = () => {
		dispatch(fetchOffersByAffiliateId(affiliate.id))
	}
	const editOffer = (offer, partnerListingId) => {
		dispatch(showModal('UPDATE_OFFER_BILLING_RULES', { offer: offer, partnerListingId: partnerListingId, fetchOffersByAffiliateId: getOffersByAffiliateId }))
	}

	useEffect(() => {
		getOffersByAffiliateId()
	}, [])

	return (
	<>
		<DialogSubsectionHeadline>Commission Rates</DialogSubsectionHeadline>
		<Table style={{ position: 'relative', minHeight: '120px' }}>
			<TableHead style={{ backgroundColor: '#f1f1f1' }}>
				<TableRow className={classes.tableHeadRow}>
					<TableCell className={classes.tableHeadCell} style={{ width: '27%' }}>
						Offer ID
					</TableCell>
					<TableCell className={classes.tableHeadCell} style={{ width: '20%' }}>
						<div className={classes.tableHeadCellDivider} />
						Offer Name
					</TableCell>
					<TableCell className={classes.tableHeadCell} style={{ width: '15%' }}>
						<div className={classes.tableHeadCellDivider} />
						Billing Type
					</TableCell>
					<TableCell className={classes.tableHeadCell} style={{ width: '15%' }}>
						<div className={classes.tableHeadCellDivider} />
						Commission Rate
					</TableCell>
					{/* <TableCell className={ classes.tableHeadCell } style={{ width:'10%' }}>
					Base Rate
				</TableCell> */}
					<TableCell className={classes.tableHeadCell} style={{ width: '13%' }}>
						<div className={classes.tableHeadCellDivider} />
						Payment Method
					</TableCell>
					{canUpdate &&
						<TableCell className={classes.tableHeadCell} style={{ cursor: 'default', width: '10%' }}>
							&nbsp;
						</TableCell>
					}
				</TableRow>
			</TableHead>
			<TableBody>
				{offers && offers.map(o => (
					<TableRow key={o.id} className={classes.tableRow}>
						<TableCell className={classes.tableCell}>{o.id}</TableCell>
						<TableCell className={classes.tableCell}>{o.name}</TableCell>
						<TableCell className={classes.tableCell}>{o.pricingSchemeType}</TableCell>
						<TableCell className={classes.tableCell}>{o.commissionRate}%</TableCell>
						{/* <TableCell className={ classes.tableCell }>${o.baseRate.toFixed(2)}</TableCell> */}
						<TableCell className={classes.tableCell}>{o.source.charAt(0).toUpperCase() + o.source.slice(1)}</TableCell>
						{ canUpdate &&
							<TableCell className={classes.tableCell} style={{ textAlign: 'center', paddingRight: 'inherit' }}>
								<StyledLink
									key="editOffer"
									contents="Edit"
									clickHandlerOnly={true}
									onClick={() => editOffer(o, params.id)}
									styles={{}}
								/>
							</TableCell>
						} 
					</TableRow>
				))}
			</TableBody>
		</Table>
	</>
)}

export default OffersTable