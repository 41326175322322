/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'
import { fetchPolicies, hasPolicy } from '../../../../../../redux/modules/authentication/authentication'
import { fetchPartnerListing, selectPartnerListing } from '../../../../../../redux/modules/partners/listings'
import {
  useDeleteOfferMutation,
  useGetOffersByPartnerListingIdQuery,
  useGetSyndicateOffersByPartnerListingIdQuery,
  useLazyGetOfferBillingRulesHistoryQuery,
  usePublishOfferMutation,
  useSyncShopifyOfferMutation,
  useUnpublishOfferMutation,
  useCreateSyndicateOfferMutation,
  useDeleteSyndicateOfferMutation
} from '../../../../../../redux/slices/offersSlice'
import {
  ACCESS_OFFERS_LIST_PAGE,
  ACCESS_PRIVATE_AFFILIATIONS,
  CREATE_OFFER,
  DELETE_OFFER,
  SET_OFFER_CHANNELS,
  SET_OFFER_STATUS,
  SYNC_OFFERS,
  VIEW_BILLING_RULES,
  SYNDICATE_OFFER,
  DELETE_SYNDICATE_OFFER,
  DEVELOPER_ACCESS,
  VIEW_SYNDICATED_LISTINGS
} from '../../../../../../auth/policies'
import { api } from '../../../../../../redux/slices/apiSlice'
import {
  fetchPublicAndPublishedOccupations,
  fetchPublishedOccupations,
  hideModal,
  showModal,
  showSuccesNotification,
  showErrorNotification
} from '../../../../../../redux/actions'
import { useGetOffersLookupsQuery } from "../../../../../../redux/slices/lookupsSlice"
import { useLazyGetPartnerListingQuery } from "../../../../Listings/partnerListingsSlice"

const useOffersListing = () => {
  const dispatch = useDispatch()
  const { id: partnerListingId } = useParams()
  const [processing, setProcessing] = useState(false)
  const [getBillingRulesHistory] = useLazyGetOfferBillingRulesHistoryQuery()

  // selectors
  const canAddOffers = useSelector(hasPolicy(CREATE_OFFER))
  const canViewOffersList = useSelector(hasPolicy(ACCESS_OFFERS_LIST_PAGE))
  const canDeleteOffer = useSelector(hasPolicy(DELETE_OFFER))
  const canSetStatus = useSelector(hasPolicy(SET_OFFER_STATUS))
  const canSyncOffer = useSelector(hasPolicy(SYNC_OFFERS))
  const canSyndicateOffer = useSelector(hasPolicy(SYNDICATE_OFFER))
  const canDeleteSyndicateOffer = useSelector(hasPolicy(DELETE_SYNDICATE_OFFER))
  const partnerListing = useSelector(selectPartnerListing(partnerListingId))
  const selectActiveSyndicateOffers = createSelector(
    (res) => res.data,
    (data) => data ?? [],
  )
  const canViewSyndicatedListings = useSelector(hasPolicy(VIEW_SYNDICATED_LISTINGS))

  // RTK Query Hooks
  const [deleteOffer] = useDeleteOfferMutation()
  const [publishOffer] = usePublishOfferMutation()
  const [unpublishOffer] = useUnpublishOfferMutation()
  const [syncShopifyOffer] = useSyncShopifyOfferMutation()
  const {
    syndicateOffers,
    isLoading: syndicateOffersIsLoading,
    isSuccess: syndicateOffersIsSuccess,
    hasError: syndicateOffersHasError,
  } = useGetSyndicateOffersByPartnerListingIdQuery(partnerListingId, {
    selectFromResult: (result) => {
      return {
        ...result,
        syndicateOffers: selectActiveSyndicateOffers(result),
      }
    },
  })
  const [createSyndicateOffer] = useCreateSyndicateOfferMutation()
  const [deleteSyndicateOffer] = useDeleteSyndicateOfferMutation()
  const channels = useGetOffersLookupsQuery().data?.channels ?? []
  const [getPartnerListing] = useLazyGetPartnerListingQuery()

  // Callback actions
  const openGovXIdLinkModal = (offer) => dispatch(showModal('VIEW_GOVX_LINK', {
    offer,
    title: 'GOVX Offer ID Link',
    message: `Use this link to advertise the offer on your website. Customers that
    click the link will be asked to log in or verify before accessing the
    discount.` })) // TODO: https://govx.atlassian.net/browse/BTP-11156
  const openPublishModal = (offer, setPublished, onChangeStatus) => dispatch(showModal('PUBLISH_OFFER_MODAL', { offer, setPublished, onChangeStatus }))
  const openCommissionRateHistoryModal = (props) => dispatch(showModal('VIEW_OFFER_COMMISSION_RATE_HISTORY', props))
  const openSydnicatedListingsModal = (props) => dispatch(showModal('VIEW_OFFER_SYNDICATED_LISTINGS', props))
  const openSyndicateOfferCreateModal = (props) => dispatch(showModal('CREATE_SYNDICATE_OFFER_MODAL', props))
  const openSyndicateOfferToListingCreateModal = (props) => dispatch(showModal('CREATE_LISTING_SYNDICATE_OFFER_MODAL', props))
  const openSyndicateRemoveOfferModal = (props) => dispatch(showModal('REMOVE_SYNDICATE_OFFER_MODAL', props))


  const onChangeStatus = async (offer, setPublished) => {
    if (offer.source.type.toLowerCase() === 'shopify') throw new Error('Cannot change status of Shopify offers')
    try {
      const method = setPublished ? publishOffer : unpublishOffer
      const response = await method(offer).unwrap()
      dispatch(showSuccesNotification(`Offer is now ${setPublished ? 'published' : 'unpublished'}`))
      dispatch(hideModal())
      return response
    } catch (err) {
      dispatch(showErrorNotification(`There was an error while attempting to ${setPublished ? 'publish' : 'unpublish'} this offer`))
      console.error(err)
      return Promise.reject(err)
    }
  }
  const openDeleteModal = (offer, onDelete) => dispatch(showModal('DELETE_OFFER_MODAL', { offer, onDelete }))
  const onDelete = async (offer) => {
    if (offer.source.type.toLowerCase() === 'shopify') throw new Error('Cannot delete Shopify offers')
    try {
      const response = await deleteOffer(offer).unwrap()
      dispatch(showSuccesNotification('Offer successfully deleted'))
      dispatch(hideModal())
      return response
    } catch (err) {
      dispatch(showErrorNotification('There was an error while attempting to delete the offer'))
      console.error(err)
      return Promise.reject(err)
    }
  }

  const onClickSyncOffer = async (sourceId) => {
    try {
      await syncShopifyOffer(sourceId).unwrap()
      dispatch(showSuccesNotification('Offer sync initialized. Please wait a few minutes before refreshing the page to view the updated offer details.'))
    } catch (err) {
      dispatch(showErrorNotification('Could not initialize offer sync. Please try again later.'))
      console.error(err)
      return Promise.reject(err)
    }
  }

  const onClickSyndicateOffer = async (offerId) => {
    openSyndicateOfferToListingCreateModal({
      onClickCancel: closeModal,
      offerId: offerId,
      onClickSubmit: async (data, offerId) => {
        try {
          var createSyndicateOfferResponse = await createSyndicateOffer({ offerId: offerId, listingId: data.listingId }).unwrap()
          var syndicatePartnerListing = await getPartnerListing(data.listingId).unwrap()
          if (createSyndicateOfferResponse.success) {
            dispatch(showSuccesNotification(`Offer successfully syndicated to ${syndicatePartnerListing.name}`))
            dispatch(closeModal())
          }
          else if (createSyndicateOfferResponse.alreadyExists) {
            dispatch(showErrorNotification(`This syndicate offer has already been added to ${syndicatePartnerListing.name}`))
          }
          else {
            dispatch(showErrorNotification('This listing ID is not valid.'))
          }
        } catch (err) {
          console.error(err)
          dispatch(showErrorNotification('Couldn\'t syndicate offer. Please try again later.'))
        }
      }
    })
  }

  const onClickRemoveSyndicateOffer = async (syndicateOffer) => {
    openSyndicateRemoveOfferModal({
      onClickCancel: closeModal,
      syndicateOffer: syndicateOffer,
      onClickSubmit: async (syndicateOffer) => {
        try {
          await deleteSyndicateOffer(syndicateOffer)
          dispatch(showSuccesNotification('Syndicate offer successfully removed'))
          dispatch(closeModal())
        } catch (err) {
          dispatch(showErrorNotification('Could not delete syndicate offer. Please try again later.'))
          console.error(err)
        }
      }
    })
  }

  const onClickCommissionRateHistoryModal = async (offer) => {
    const billingRulesHistory = await getBillingRulesHistory({ listingId: partnerListingId, offerId: offer.offerId }).unwrap()
    openCommissionRateHistoryModal({
      onClickCancel: closeModal,
      billingRulesHistory: billingRulesHistory ? billingRulesHistory.billingRules : null
    })
  }

  const onClickSyndicatedListingsModal = async (offer) => {
    openSydnicatedListingsModal({
      onClickClose: closeModal,
      onClickDelete: removeSyndicateListing,
      listings: offer.syndicatedListings ? offer.syndicatedListings : null,
      offer: offer,
    })
  }

  const onClickSyndicateOfferCreateModal = async () => {
    openSyndicateOfferCreateModal({
      onClickCancel: closeModal,
      listingId: partnerListingId,
      onClickSubmit: async (data, listingId) => {
        try {
          var createSyndicateOfferResponse = await createSyndicateOffer({ offerId: data.offerId, listingId: listingId }).unwrap()
          if (createSyndicateOfferResponse.success) {
            dispatch(showSuccesNotification('Syndicate offer successfully created'))
            dispatch(hideModal())
          }
          else if (createSyndicateOfferResponse.alreadyExists) {
            dispatch(showErrorNotification('This offer has already been added to this listing'))
          }
          else {
            dispatch(showErrorNotification('This offer ID is not valid.'))
          }
        } catch (err) {
          console.error(err)
          dispatch(showErrorNotification('There was an error creating this syndicate offer. Please try again.'))
        }
      }
    })
  }


  const removeSyndicateListing = async (syndicateOffer) => {
    try {
      await deleteSyndicateOffer(syndicateOffer)
      dispatch(showSuccesNotification(`${syndicateOffer.name} is no longer syndicated to ${syndicateOffer.syndicateListingName}`))
    } catch (err) {
      dispatch(showErrorNotification(`Something went wrong revoking the syndication to ${syndicateOffer.syndicateListingName}. Please try again later.`))
      console.error(err)
    }
  }

  const closeModal = () => dispatch(hideModal())

  // prefetchers
  const prefetchOffer = api.usePrefetch('getOfferById')
  const prefetchOfferDetails = (offer) => {
    prefetchOffer(offer.offerId, { ifOlderThan: 90 })
  }

  // init
  useEffect(() => {
    const runDispatches = async () => {
      setProcessing(true)
      await Promise.all([
        dispatch(fetchPolicies([
          ACCESS_OFFERS_LIST_PAGE,
          CREATE_OFFER,
          SET_OFFER_STATUS,
          ACCESS_PRIVATE_AFFILIATIONS,
          CREATE_OFFER,
          DELETE_OFFER,
          VIEW_BILLING_RULES,
          SET_OFFER_CHANNELS,
          SYNDICATE_OFFER,
          DELETE_SYNDICATE_OFFER,
          DEVELOPER_ACCESS,
          VIEW_SYNDICATED_LISTINGS
        ])),
        dispatch(fetchPartnerListing(partnerListingId)),
        dispatch(fetchPublishedOccupations()),
        dispatch(fetchPublicAndPublishedOccupations()),
      ]).then(() => setProcessing(false))
    }
    runDispatches()
  }, [])

  return {
    processing,
    canAddOffers,
    canViewOffersList,
    canDeleteOffer,
    canSyncOffer,
    canSetStatus,
    canSyndicateOffer,
    canDeleteSyndicateOffer,
    partnerListing,
    canViewSyndicatedListings,
    openGovXIdLinkModal,
    openPublishModal,
    onClickCommissionRateHistoryModal,
    onClickSyndicatedListingsModal,
    onClickSyndicateOfferCreateModal,
    onChangeStatus,
    openDeleteModal,
    onDelete,
    prefetchOfferDetails,
    onClickSyncOffer,
    onClickSyndicateOffer,
    channels,
    syndicateOffers,
    syndicateOffersIsLoading,
    syndicateOffersIsSuccess,
    syndicateOffersHasError,
    onClickRemoveSyndicateOffer,
  }
}

export default useOffersListing
