/* eslint-disable react/prop-types */
import { Box, Button } from '@mui/material'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from '@mui/material/Typography'
import { showSuccesNotification, showErrorNotification } from '../../../../redux/actions'
import { MUITextInput } from '../../../@Common/Form/TextInput/ControlledTextInput'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { MUIFormTextAreaInput } from '../../../@Common/Form/TextAreaInput/FormTextAreaInput'

const headingText = `Use the GOVX ID offer page to showcase your offers on your website.
Simply copy and paste the link below into your website and make it easy for your customers to find offers that apply to them. `

function OfferPageFormPartial({ listing, form }) {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const onClickCopyLink = (textInputRef) => {
    if (!navigator.clipboard) {
      // Explanation: https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext/71876238#71876238
      // eslint-disable-next-line no-console
      console.warn('Unable to access clipboard. If you are debugging the application'
                + ' access to the clipboard may be restricted due to running over http (not https).')
      dispatch(showErrorNotification('Failed to copy GovX ID Offer Link to clipboard'))
      return
    }

    textInputRef.current.select()
    try {
      navigator.clipboard.writeText(textInputRef.current.value)
      dispatch(showSuccesNotification('GovX ID Offer Link copied to clipboard'))
    } catch (err) {
      console.error('Failed to copy: ', err)
      dispatch(showErrorNotification('Failed to copy GovX ID Offer Link to clipboard'))
    }
  }
  const { control, watch } = form
  return (
    <>
      <div style={{ border: 'solid 1px', padding: '15px', borderColor: '#CCCCCC' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            {headingText}
          </p>
        </Box>
        <Box>
          <MUITextInput
            label="GovX ID Offer Link"
            value={listing.offerLink}
            disabled
            inputRef={ref}
          />
          <Button
            onClick={() => onClickCopyLink(ref)}
            startIcon={<ContentCopyIcon />}
          >
            Copy
          </Button>
        </Box>
      </div>
      <div style={{ padding: '50px 0' }}>
        <div style={{ position: 'relative', float: 'left', width: '25%' }}>
          <DialogSubsectionHeadline>Customize Offer Page</DialogSubsectionHeadline>
          <span maxLength="160" style={{ fontSize: '85%' }}>Customize your headline and welcome message. If you leave these blank, we will automatically use the messaging in your GOVX ID settings.</span>
        </div>
        <div style={{ position: 'relative', float: 'right', width: '70%' }}>
          <div style={{ position: 'relative' }}>
            <MUIFormTextAreaInput
              control={control}
              name="welcomeHeadline"
              label="Headline"
              rules={{ maxLength: 50 }}
              rows={1}
              shrinkLabel
            />
            <Typography>
              <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
                {watch('welcomeHeadline') ? watch('welcomeHeadline').length : 0}
                {' '}
                of 50
              </small>
            </Typography>
          </div>

          <div style={{ position: 'relative', margin: '30px 0px' }}>
            <MUIFormTextAreaInput
              control={control}
              name="welcomeMessage"
              label="Welcome Message"
              rules={{ maxLength: 300 }}
              rows={3}
              shrinkLabel
            />
            <Typography>
              <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
                {watch('welcomeMessage') ? watch('welcomeMessage').length : 0}
                {' '}
                of 300
              </small>
            </Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default OfferPageFormPartial
