import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../../../redux/actions'
import ModalContainer from '../../../../../Modals/ModalContainer'
import { Alert, AlertTitle, CircularProgress, List, ListItem, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MUIFormFileInput } from '../../../../../@Common/Form/FileInput/FormFileInput'
import { useForm } from 'react-hook-form'
import ErrorIcon from '@mui/icons-material/Error'
import Loader from '../../../../../Loader'

function ImportEventsModal({
    importErrors,
    importProcessing,
    onClickClose,
    onClickSubmit,
    onFileChange
}) {
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const { control, watch, handleSubmit } = useForm({
        defaultValues: {
            eventsFile: null,
        },
        mode: 'onChange',
    })

    const onSubmit = async (data) => {
        onClickSubmit(data)
    }

    return (
        <ModalContainer
            title={`Import Events`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}

            actions={{
                primaryAction: { label: 'Import', onClick: handleSubmit(onSubmit), disabled: watch('eventsFile') === null ? watch('eventsFile') === null : importProcessing },
                secondaryAction: { label: 'Cancel', onClick: () => onClickClose(), disabled: importProcessing },
            }}
        >
            <>
                <Typography component="div">
                    {importErrors.length > 0 &&
                        <Box sx={{ overflow: 'scroll', overflowX: 'hidden', maxHeight: '400px', maxWidth: '1000px', marginBottom: '24px' }}>
                            <Alert icon={<ErrorIcon />} sx={{ marginTop: '0px', backgroundColor: '#FDEDED', color: '#5F2120', '& .MuiAlert-icon': { color: '#d32f2f' } }} severity="error">
                                <AlertTitle sx={{ fontWeight: 'bold' }}>Please correct the following errors in the file and try again.</AlertTitle>
                                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                                    {importErrors.map((error, index) => (
                                        <ListItem sx={{ display: 'list-item' }} key={index}>{error}</ListItem>
                                    ))}
                                </List>
                            </Alert>
                        </Box>}
                    {importErrors.length === 0 &&
                        <>
                            <Box>
                                To import events, make sure your file adheres to the following standards:
                            </Box>
                            <ul style={{ listStyle: 'inside', margin: '.75em' }}>
                                <li>File should be CSV format.</li>
                            </ul>
                        </>
                    }
                </Typography>

                <>

                    <Box sx={{ mx: '40px', my: '20px' }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <MUIFormFileInput
                                name="eventsFile"
                                control={control}
                                fileTypes=".csv"
                                fileTypesDescription="CSV"
                                processing={importProcessing}
                                onChange={() => { setShowErrorMessage(false); setErrorMessage(null); onFileChange() }}
                                gxid="btnImportEventsBrowseFiles"
                            />
                            {showErrorMessage
                                && (
                                    <Box>
                                        <p style={{ color: '#d32f2f' }}>
                                            Import failed.
                                            {' '}
                                            {errorMessage}
                                            {' '}
                                            Please try again.
                                        </p>
                                    </Box>
                                )}
                        </form>
                    </Box>

                </>
            </>
        </ModalContainer>
    )

}

export default ImportEventsModal    