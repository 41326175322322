import React from 'react'
import { Box } from '@mui/material'
import { StatusIndicator } from './StatusIndicator'

export const PublishedStatusIndicator = function ({
    publishedStatus,
    gxid = '',
    sx = {},
    marginTopIndicator = '8px',
    marginTopStatus = '9px'
}) {
    return (
        <Box data-gxid={gxid} sx={sx}>
            <Box sx={{ mt: marginTopIndicator, mr: '10px', float: 'left' }}>
                <div style={{ width: '10px' }}>
                    <StatusIndicator publishedStatus={publishedStatus} />
                </div>
            </Box>
            <Box sx={{
                mt: marginTopStatus, float: 'left', fontSize: '14px', pr: '5px',
            }}
            >
                {publishedStatus ? 'Published' : 'Unpublished'}
            </Box>
        </Box>
    )
}
