import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RouteConstants } from '../../setup/routeConstants'
import { login } from '../../redux/actions'
import { useModal } from '../../hooks/useModal'

function useLogin() {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showOverlay, setShowOverlay] = useState(false)
  const { closeModal } = useModal()

  useEffect(() => {
    closeModal()
  }, [])

  const onLogin = async (values) => {
    setShowOverlay(true)
    const userAuth = {
      email: values.email,
      password: values.password,
    }
    try {
      const user = await dispatch(login(userAuth))
      /* this homepage check is there because if you have a link to a user page
            but you are a partner you can get stuck on the dashboard
            Checking to making sure you are not able to see the root will make sure that
            partners get sent to the right place
            This is a unique condition to the dashboard
            */
      if (params.has('returnUrl') && user.adminHomepage === '/') {
        navigate(decodeURIComponent(params.get('returnUrl')))
      } else {
        navigate(user.adminHomepage || RouteConstants.dashboard.index.path)
      }
    } catch (error) {
      if (Boolean(error.response) || Boolean(error.email) || Boolean(error.password)) {
        throw new Error('We don\'t recognize that email or password. Please try again.')
      } else {
        throw new Error('There was an error while attempting to login. Please try again.')
      }
    } finally {
      setShowOverlay(false)
    }
  }

  return {
    showOverlay,
    onLogin,
  }
}

export default useLogin
