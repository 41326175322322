import { Box, Skeleton } from '@mui/material'
import React from 'react'

export default function OffersLoadingSkeleton() {
  return (
    <Box
      display="flex"
      sx={{
        border: '1px #e3e3e3 solid',
        paddingX: '60px',
        paddingY: '25px',
        marginBottom: '20px',
        justifyContent: 'space-between',
        minHeight: '190px',
      }}
    >
      <Box display="flex">
        <Box>
          <Skeleton variant="rounded" width="200px" height="40px" />
          <Box sx={{ mt: '25px' }}>
            <Skeleton variant="rounded" width="200px" sx={{ mb: '10px' }} />
            <Skeleton variant="rounded" width="200px" sx={{ mb: '10px' }} />
          </Box>
        </Box>
        <Box sx={{ marginLeft: '80px' }}>
          <Box display="flex" sx={{ marginBottom: '10px' }}>
            <Skeleton variant="rounded" width="400px" />
          </Box>
          <Box sx={{ mt: '19px' }}>
            <Skeleton variant="rounded" width="200px" sx={{ mb: '10px' }} />
            <Skeleton variant="rounded" width="200px" sx={{ mb: '10px' }} />
          </Box>
          {/* <p>
            Commission Rate: 10%{" "}
            <Link
              sx={{ marginLeft: "8px" }}
              href="#"
              underline="none"
              onClick={(e) => {e.preventDefault(); ViewCommissionRateHistory(offer)}}
            >
              View History
            </Link>
          </p> */}
        </Box>
      </Box>
      <Box display="flex" sx={{ alignItems: 'center', alignSelf: 'start', mt: '-10px' }}>
        <Skeleton variant="rounded" width="200px" />
      </Box>
    </Box>
  )
}
