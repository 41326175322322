import React, { useState } from 'react'
import { compose, withState } from 'recompose'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextArea from '../../../Fields/TextArea'
import FileInput from '../../../Fields/FileInput'
import { fileExtensionValid, filesizeWithinLimits } from '../../../../validation'
import { withStyles } from '@mui/styles'
import PhotoIcon from '@mui/icons-material/Photo';
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { useDispatch } from 'react-redux'

import { updateDesktopHeroImage, updateMobileHeroImage } from '../../../../redux/modules/affiliates/affiliates'
const fileSizeCheck = filesizeWithinLimits(1)
const logoFileSizeCheck = filesizeWithinLimits(2)
const fileExtensionCheck = fileExtensionValid(['jpg', 'jpeg', 'png'])

const styles = {
	root: {
		color: '#777',
	},
	focused: {
		'&$focused': {
			color: '#00a1df',
		},
	},
	inputLabel: {
		margin: '20px 0 0',
		fontSize: '14px',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
	},
	inputDescription: {
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
	},
	imageUploadContainerEmpty: {
		margin: '15px 0 0',
		textAlign: 'center',
		border: '2px solid #ddd',
		padding: '15px',
	},
	imageUploadContainer: {
		margin: '15px 0 0',
		textAlign: 'right',
	},
	pictureContainer: {
		border: '2px solid #ddd',
		padding: '15px',
		textAlign: 'center',
	},
	previewImage: {
		backgroundColor: '#d9d9d9',
		maxWidth: '100%',
	},
	changeLogoLink: {
		textTransform:'none',
		color: '#0E5777',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		fontWeight: 400,
		textDecoration: 'none',
		cursor: 'pointer',

		'&:hover, &:focus': {
			color: '#000',
			background: 'none',
		},
	},
	changeLogoButton: {
		textTransform:'none',
		color:'#222',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		padding: '8px 10px',
		border: '1px solid #222'
	},
	textContainer: {
		padding: '15px 0 0',
	},
	flexButtonContainer: {
		display: 'flex',
		float:'right'
	},  
	deleteButton: {
		marginLeft: '10px !important'
	},
}





const CustomizePageFormPartial = ({
	affiliate,
	initialValues,
	fieldValues,
	classes,
	canUpdate,
}) => {
    const [fieldValuesState,setFieldValuesState]=useState(initialValues);
	const dispatch = useDispatch();
	const handleDeleteImage = (imageType) => {
		
        if(imageType === 'desktop'){
			dispatch(updateDesktopHeroImage(null, fieldValuesState.desktopHeroImageId, affiliate.id));
			
		}
		else if (imageType === 'mobile'){
			dispatch(updateMobileHeroImage(null,fieldValuesState.mobileHeroImageId,affiliate.id));
		}
    };
	const handleEdit = (imageType, file) => {
		if(imageType === 'desktop'){
			setFieldValuesState(prevState => ({
				...prevState,
				desktopHeroImageUrl: file,
			}));
			dispatch(updateDesktopHeroImage(file, 0, affiliate.id));
		}
		else if (imageType === 'mobile'){
			setFieldValuesState(prevState => ({
				...prevState,
				mobileHeroImageUrl: file,
			}));
			dispatch(updateMobileHeroImage(file, 0 ,affiliate.id));
		}
	}
	return(
	<div style={{ fontSize: '14px', minHeight:'55vh' }}>
		<Grid container>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Logo</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Minimum Height:  40px for horizontal logos / 100px for square or vertical logos</p>
						<p className={ classes.inputDescription }>Max File Size: 2MB</p>
						<p className={ classes.inputDescription }>File types: JPG, JPEG, PNG</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<div className={fieldValues.logoImageUrl ? classes.imageUploadContainer : classes.imageUploadContainerEmpty}>
					{fieldValues.logoImageUrl &&
						<div className={classes.pictureContainer}>
							{ (typeof fieldValues.logoImageUrl !== 'string') &&
								<div>
									<img src={URL.createObjectURL(fieldValues.logoImageUrl)} className={classes.previewImage} style={{ }} />
									<img id='logoImagePreview' src={URL.createObjectURL(fieldValues.logoImageUrl)} style={{ visibility: 'hidden', position: 'absolute' }} />
								</div>
							}
							{ (typeof fieldValues.logoImageUrl === 'string') &&
								<img src={fieldValues.logoImageUrl} className={classes.previewImage} style={{ }} />
							}
						</div>
					}
					{!fieldValues.logoImageUrl &&
						<div>
							<PhotoIcon
								style={{
									width: '36px',
									height: '36px',
									margin: '0px',
									padding: '0px',
									position: 'static',
									fill: '#ddd',
								}}
							/>
							<p style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Upload an image</p>
							<p style={{ fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Supports: JPEG, JPG, PNG</p>
						</div>
					}
					<FileInput
						name="logoImageUrl"
						validate={[logoFileSizeCheck, fileExtensionCheck]}
						label={
							<Button component='span' className={fieldValues.logoImageUrl ? classes.changeLogoLink : classes.changeLogoButton}>
								{fieldValues.logoImageUrl ? 'Edit image' : 'Browse Files'}
							</Button>
						}
					/>
				</div>
  			</Grid>
		</Grid>
		<Grid container>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Desktop Hero</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Dimensions: 1800 x 500px</p>
						<p className={ classes.inputDescription }>File types: JPEG, JPG, PNG</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<div className={fieldValuesState.desktopHeroImageUrl ? classes.imageUploadContainer : classes.imageUploadContainerEmpty}>
				{fieldValuesState.desktopHeroImageUrl && (
						<div className={classes.pictureContainer}>
							{typeof fieldValuesState.desktopHeroImageUrl !== 'string' ? (
								<div>
									<img src={URL.createObjectURL(fieldValuesState.desktopHeroImageUrl)} className={classes.previewImage} style={{ }} />
									<img id='desktopHeroImagePreview' src={URL.createObjectURL(fieldValuesState?.desktopHeroImageUrl)} style={{ visibility: 'hidden', position: 'absolute' }} />
								</div>
							) : (
								<img src={fieldValuesState.desktopHeroImageUrl} className={classes.previewImage} style={{ }} />
							)}
						</div>
					)}

					{!fieldValuesState.desktopHeroImageUrl &&
						<div>
							<PhotoIcon
								style={{
									width: '36px',
									height: '36px',
									margin: '0px',
									padding: '0px',
									position: 'static',
									fill: '#ddd',
								}}
							/>
							<p style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Upload an image</p>
							<p style={{ fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Supports: JPEG, JPG, PNG</p>
						</div>
					}
					<div className={fieldValuesState.desktopHeroImageUrl ? classes.flexButtonContainer : classes.root}>
						<FileInput
							name="desktopHeroImageUrl"
							validate={[fileSizeCheck, fileExtensionCheck]}
							onChange={(event) => {
								handleEdit('desktop',event)
							}}
							label={
								<Button component='span' className={fieldValuesState.desktopHeroImageUrl ? classes.changeLogoLink : classes.changeLogoButton}>
									{fieldValuesState.desktopHeroImageUrl ? 'Edit image' : 'Browse Files'}
								</Button>
							}
						/>
						{fieldValuesState?.desktopHeroImageUrl && fieldValuesState?.desktopHeroImageId > 0 &&
							<Button
								component='span'
								className={classes.deleteButton}
								onClick={() => {
									setFieldValuesState((prev)=>{
										return{
											...prev,
											desktopHeroImageUrl:null,
											desktopHeroImageId:0
										}
									})
									handleDeleteImage('desktop')}}>
								Delete Image
							</Button>}
					</div>

				</div>
  			</Grid>
		</Grid>
		<Grid container style={{ marginTop: '25px '}}>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Mobile Hero</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Dimensions: 800 x 500px</p>
						<p className={ classes.inputDescription }>File types: JPEG, JPG, PNG</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<div className={fieldValuesState.mobileHeroImageUrl ? classes.imageUploadContainer : classes.imageUploadContainerEmpty}>
					{fieldValuesState.mobileHeroImageUrl &&
						<div className={classes.pictureContainer}>
							{ (typeof fieldValuesState.mobileHeroImageUrl !== 'string') &&
								<div>
									<img src={URL.createObjectURL(fieldValuesState.mobileHeroImageUrl)} className={classes.previewImage} style={{ }} />
									<img id='mobileHeroImagePreview' src={URL.createObjectURL(fieldValuesState.mobileHeroImageUrl)} style={{ visibility: 'hidden', position: 'absolute' }} />
								</div>
							}
							{ (typeof fieldValuesState.mobileHeroImageUrl === 'string') &&
								<img src={fieldValuesState.mobileHeroImageUrl} className={classes.previewImage} style={{ }} />
							}
						</div>
					}
					{!fieldValuesState.mobileHeroImageUrl &&
						<div style={{ textAlign: 'center' }}>
							<PhotoIcon
								style={{
									width: '36px',
									height: '36px',
									margin: '0px',
									padding: '0px',
									position: 'static',
									fill: '#ddd',
								}}
							/>
							<p style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Upload an image</p>
							<p style={{ fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Supports: JPEG, JPG, PNG</p>
						</div>
					}
					<div className={fieldValuesState.mobileHeroImageUrl ? classes.flexButtonContainer : classes.root}>
						<FileInput
							name="mobileHeroImageUrl"
							validate={[fileSizeCheck, fileExtensionCheck]}
							onChange={(event) => {
								handleEdit('mobile',event)
							}}
							label={
								<Button component='span' className={fieldValuesState.mobileHeroImageUrl ? classes.changeLogoLink : classes.changeLogoButton}>
									{fieldValuesState.mobileHeroImageUrl ? 'Edit image' : 'Browse Files'}
								</Button>
							}
						/>
						{fieldValuesState.mobileHeroImageUrl && fieldValuesState.mobileHeroImageId > 0 &&
							<Button
								component='span'
								className={classes.deleteButton}
								onClick={() => {
									setFieldValuesState((prev)=>{
										return{
											...prev,
											mobileHeroImageUrl:null,
											mobileHeroImageId:0
										}
									})
									handleDeleteImage('mobile')}}>
								Delete Image
							</Button>}
					</div>
				</div>
  			</Grid>
		</Grid>

		<Grid container style={{ marginTop: '25px '}}>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>About</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>This message will go at the top of the About section on the affiliate page.</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8} className={ classes.textContainer }>
				<TextArea
					name='about'
					multiline={true}
					rows = {3}
					InputLabelProps={{
						classes: {
							root: classes.root,
							focused: classes.focused,
						},
					}}
				/>
  			</Grid>
		</Grid>

		<Grid container style={{ marginTop: '25px '}}>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>How it works</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Explain how the discount is redeemed.</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8} className={ classes.textContainer }>
				<TextArea
					name='howItWorks'
					multiline={true}
					rows = {3}
					InputLabelProps={{
						classes: {
							root: classes.root,
							focused: classes.focused,
						},
					}}
				/>
  			</Grid>
		</Grid>
	</div>
)}

const enhance = compose(
	withStyles(styles)
)

export default enhance(CustomizePageFormPartial)
