import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import { isOver18 } from '../../../validation'
import { hideModal, createUser, searchUsers } from '../../../redux/actions'
import { normalizeNumber } from './normalizeUtils'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'

export function AddUserModal() {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, control } = useForm({
    defaultValues: {
      triggerPasswordResetEmail: false,
    },
  })

  const onSave = (values) => {
    setProcessing(true)
    const user = {}
    let dateOfBirth = null

    if (values.dateOfBirth) {
      const monthDayYear = values.dateOfBirth.split('/')
      const month = monthDayYear[0]
      const day = monthDayYear[1]
      const year = monthDayYear[2]
      dateOfBirth = new Date(year, month - 1, day)
    }

    user.email = values.email.replace('‘', "'").replace('’', "'")
    user.firstName = values.firstName
    user.lastName = values.lastName
    user.phoneNumber = normalizeNumber(values.phoneNumber)
    user.zipCode = normalizeNumber(values.zipCode)
    user.gender = values.gender
    user.dateOfBirth = dateOfBirth
    user.triggerPasswordResetEmail = values.triggerPasswordResetEmail
    user.listingId = values.sourceListing

    return dispatch(createUser(user))
      .then(() => {
        setProcessing(false)
        dispatch(searchUsers('users-queue'))
        dispatch(hideModal())
      })
      .catch((error) => {
        console.log(error)
      }).finally(() => {
        setProcessing(false)
      })
  }

  return (
    <ModalContainer
      title="Add a new user"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSave),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSave)} style={{ marginTop: '10px' }}>
        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="email"
            label="Email"
            autoFocus
            type="email"
            rules={{
              required: 'Please enter an email.',
              pattern: {
                value: /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email',
              },
            }}
          />
        </Box>
        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="firstName"
            label="First name"
            rules={{
              required: true,
            }}
          />
        </Box>

        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="lastName"
            label="Last name"
            rules={{
              required: true,
            }}
          />
        </Box>
        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="phoneNumber"
            label="Mobile phone number"
            rules={{
              pattern: {
                value: /\(\d{3}\)\s\d{3}-\d{4}\s?[0-9]?[0-9]?[0-9]?[0-9]?/,
                message: 'Invalid phone number',
              },
            }}
            mask="(999) 999-9999 x9999"
          />
        </Box>
        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="zipCode"
            label="Zip code"
            rules={{
              pattern: {
                value: /^\d{5}(?:[-\s]\d{4})?$/,
                message: 'Invalid zip code',
              },
            }}
            mask="99999-9999"
          />
        </Box>

        <div style={{ marginTop: '10px' }}>
          <MUIFormSelectInput
            control={control}
            name="gender"
            label="Gender"
            items={[
              { key: 'none', value: null, label: '' },
              { key: 'male', value: 'Male', label: 'Male' },
              { key: 'female', value: 'Female', label: 'Female' },
            ]}
          />
        </div>

        <Box style={{ marginTop: '10px' }}>
          <MUIFormTextInput
            control={control}
            name="dateOfBirth"
            label="Date of birth (MM/DD/YYYY)"
            rules={{
              pattern: {
                value: /^\d{2}\/\d{2}\/\d{4}$/,
                message: 'Invalid date',
              },
              validate: (value) => isOver18(value),
            }}
            mask="99/99/9999"
          />
        </Box>

        <Box style={{ marginTop: '10px' }}>
          <MUIFormSelectInput
            control={control}
            name="sourceListing"
            label="Source Listing"
            rules={{
            }}
            items={[
              { key: '0', value: null, label: '' },
              { key: '1', value: '1', label: 'GovX (1)' },
              { key: '55', value: '55', label: 'VCS (55)' },
            ]}
          />
        </Box>

        <MUIFormCheckboxInput
          control={control}
          name="triggerPasswordResetEmail"
          label="Trigger password reset email"
          size="small"
        />
      </form>
    </ModalContainer>
  )
}

export default AddUserModal
