import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAPIDomain, getConfiguration } from '../../setup/configuration'
import appInsights from '../../applicationInsights';

export const DEFAULT_TIMEOUT = 20000

const customBaseQuery = async (args, api, extraOptions) => {
    const baseResult = await fetchBaseQuery({
        baseUrl: `${getConfiguration().apiDomain}/api`,
        timeout: DEFAULT_TIMEOUT,
        mode: 'cors',
        credentials: 'include',
    })(
      args,
      api,
      extraOptions
    );

    if (baseResult.error) {
        const error = baseResult.error;
        if ("status" in error) {
          appInsights.ai().trackException({ exception: baseResult })
        }
      }
    
    return baseResult;
  };

export const api = createApi({
    baseQuery: customBaseQuery,
    endpoints: build => ({

    })
})

