import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextArea from '../Inputs/TextArea'
import ModalContainer from './ModalContainer'
import { rejectVerificationRequest, hideModal } from '../../redux/actions'

export function RejectVerificationRequestModal({ verificationRequest }) {
  const dispatch = useDispatch()
  const [rejectionState, setRejectionState] = useState({ message: '', sendEmail: true })

  const onCancel = () => dispatch(hideModal())
  const onSave = (verificationRequestObj, rejectionStateObj) => dispatch(
    rejectVerificationRequest(verificationRequestObj, rejectionStateObj.message, rejectionStateObj.sendEmail),
  )
    .then(
      dispatch(hideModal()),
    )

  return (
    <ModalContainer
      title={`Reject verification request #${verificationRequest.id}`}
      modal
      maxWidth="sm"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Reject',
          onClick: () => onSave(verificationRequest, rejectionState),
          disabled: !rejectionState.message,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ color: '#222' }}>Why are you rejecting this verification request?</p>
      <TextArea
        value={rejectionState.message}
        onChange={(e) => setRejectionState({ ...rejectionState, message: e.target.value })}
        autoFocus
        rows={2}
        rowsMax={5}
        fullWidth
        name="message"
        label="Enter rejection reason"
        style={{ marginTop: '5px' }}
      />
      <div style={{ marginTop: '15px' }}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={(e, v) => setRejectionState({ ...rejectionState, sendEmail: v })}
              value="reject state send email"
              checked={rejectionState.sendEmail}
              name="sendEmail"
            />
   )}
          label="Send rejection email"
        />
      </div>
    </ModalContainer>
  )
}

RejectVerificationRequestModal.propTypes = {
  verificationRequest: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
}

export default RejectVerificationRequestModal
