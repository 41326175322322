/* eslint-disable  */
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchPublicAndPublishedOccupations, fetchPublishedOccupations, hideModal, showErrorNotification, showModal, showSuccesNotification
} from '../../../../../../redux/actions'
import {
  hasPolicy,
  selectPartnerListing,
  selectPublicAndPublishedOccupations,
  selectPublicAndPublishedOccupationsHierarchy,
  selectPublishedOccupations,
  selectPublishedOccupationsHierarchy,
} from '../../../../../../redux/selectors'
import { useGetOffersLookupsQuery } from '../../../../../../redux/slices/lookupsSlice'
import { useCreateVerificationListingMutation, useGetVerificationListingByPartnerListingIdQuery } from '../../../../../../redux/slices/verificationsSlice'
import {
  useCreateOfferMutation,
  useLazyGetOfferByIdQuery,
  useUpdateOfferMutation,
  useUpdateSalesChannelsMutation,
  useUpdateInfoMutation,
  useLazyGetBillingRulesForOfferQuery,
  useUpdateBillingRulesforOfferMutation,
  usePublishOfferMutation
} from '../../../../../../redux/slices/offersSlice'
import { ACCESS_PRIVATE_AFFILIATIONS, DEVELOPER_ACCESS, SET_OFFER_CHANNELS, VIEW_BILLING_RULES } from '../../../../../../auth/policies'
import { useLazyGetCodePoolsByPartnerIdQuery } from '../../../../../CodePools/codePoolsSlice'

function useOfferCreateEdit() {
  const { id: partnerListingId } = useParams()
  const dispatch = useDispatch()

  // selectors
  const partnerListing = useSelector(selectPartnerListing(partnerListingId))
  const canSetOfferChannels = useSelector(hasPolicy(SET_OFFER_CHANNELS))
  const canViewBillingRules = useSelector(hasPolicy(VIEW_BILLING_RULES))
  const canAccessPrivateAffiliations = useSelector(hasPolicy(ACCESS_PRIVATE_AFFILIATIONS))
  const publishedOccupations = useSelector(selectPublishedOccupations)
  const publishedOccupationsHierarchy = useSelector(selectPublishedOccupationsHierarchy)
  const publicAndPublishedOccupationsHierarchy = useSelector(
    selectPublicAndPublishedOccupationsHierarchy,
  )
  const publicAndPublishedOccupations = useSelector(selectPublicAndPublishedOccupations)

  // RTK Query Hooks
  const lookups = useGetOffersLookupsQuery()
  const verificationListing = useGetVerificationListingByPartnerListingIdQuery(partnerListingId)
  const [getOfferById] = useLazyGetOfferByIdQuery()
  const [getBillingRules] = useLazyGetBillingRulesForOfferQuery()
  const [createOffer] = useCreateOfferMutation()
  const [updateOffer] = useUpdateOfferMutation()
  const [updateInfo] = useUpdateInfoMutation()
  const [updateSalesChannels] = useUpdateSalesChannelsMutation()
  const [updateBillingRulesforOffer] = useUpdateBillingRulesforOfferMutation()
  const [getCodePoolsByPartnerId] = useLazyGetCodePoolsByPartnerIdQuery()
  const [publishOffer] = usePublishOfferMutation()
  const [createVerificationListing] = useCreateVerificationListingMutation()


  // dispatch legacy actions
  const getPublishedOccupations = () => dispatch(fetchPublishedOccupations())
  const getPublicAndPublishedOccupations = () => dispatch(fetchPublicAndPublishedOccupations())

  // Callback actions
  const openEditModal = (props) => dispatch(showModal('EDIT_PARTNERS_OFFER', props))
  const openCreateModal = (props) => dispatch(showModal('ADD_PARTNERS_OFFER', props))

  const updatePublishStatus = async (offer) => {
    if (offer.source.type.toLowerCase() === 'shopify') throw new Error('Cannot change status of Shopify offers')
    try {
      const response = await publishOffer(offer).unwrap()
      dispatch(hideModal())
      return response
    } catch (err) {
      dispatch(showErrorNotification(`There was an error while attempting to publish this offer`))
      console.error(err)
      return Promise.reject(err)
    }
  }

  const onClickCreateOffer = async () => {
    var { data: codePools } = await getCodePoolsByPartnerId(partnerListing.partnerId, false)
    openCreateModal({
      partnerListing,
      verificationListing: verificationListing?.data,
      lookups,
      canSetOfferChannels,
      publishedOccupations,
      publishedOccupationsHierarchy,
      publicAndPublishedOccupationsHierarchy,
      publicAndPublishedOccupations,
      canAccessPrivateAffiliations,
      canViewBillingRules,
      codePools: codePools.rows.filter(x => x.published === true).map((cp) => ({
        value: cp.id, key: cp.id, label: cp.name,
      })),
      onClickSubmit: async (data) => {
        try {
          if (!verificationListing?.data) {
            const request = {
              listingName: partnerListing.name,
              partnerName: partnerListing.name,
              partnerId: partnerListing.partnerId,
              partnerListingId: partnerListing.id,
              logoPictureId: partnerListing.logoPictureId,
              defaultOfflineRedirectUrl: partnerListing.website,
            }
            await createVerificationListing(request)
          }
          await createOffer(data).unwrap()
          dispatch(showSuccesNotification('Offer successfully created.'))
          dispatch(hideModal())
        } catch (err) {
          console.error(err)
          dispatch(showErrorNotification('There was an error creating this offer. Please try again.'))
        }
      },
      onClickCancel: () => dispatch(hideModal()),
    })
  }
  const onClickEditOffer = async (offerId, isPublishMode) => {
    getPublishedOccupations()
    getPublicAndPublishedOccupations()
    const [offerResult, billingRules, codePoolsResponse] = await Promise.all([
      getOfferById(offerId).unwrap(),
      getBillingRules({ listingId: partnerListingId, offerId: offerId }).unwrap(),
      getCodePoolsByPartnerId(partnerListing.partnerId, false)
    ]);
    openEditModal({
      offer: offerResult,
      billingRules: billingRules,
      partnerListing,
      verificationListing: verificationListing?.data,
      lookups,
      canSetOfferChannels,
      publishedOccupations,
      publishedOccupationsHierarchy,
      publicAndPublishedOccupationsHierarchy,
      publicAndPublishedOccupations,
      canAccessPrivateAffiliations,
      canViewBillingRules,
      codePools: codePoolsResponse.data.rows.filter(x => x.published === true).map((cp) => ({
        value: cp.id, key: cp.id, label: cp.name,
      })),
      isPublishMode,
      onClickSubmit: async (data) => {
        try {
          if (offerResult.source.type.toLowerCase() === 'shopify') {
            await Promise.all([
              updateInfo({
                offerId: offerResult.offerId,
                name: data.name,
                description: data.description,
                link: offerResult.link,
                tags: offerResult.tags,
              }).unwrap(),
              updateBillingRulesforOffer(
                {
                  offerId: offerResult.offerId,
                  listingId: partnerListingId,
                  billingRules: data.billingRules
                }).unwrap(),
              canSetOfferChannels ? [
                updateSalesChannels({
                  offerId: offerResult.offerId,
                  channels: data.channels,
                }).unwrap(),
              ] : [],
            ])
          } else {
            const updateData = { ...data, status: isPublishMode ? 'Published' : data.status }
            await Promise.all([updateOffer(updateData).unwrap(), updateBillingRulesforOffer(
              {
                offerId: offerId,
                listingId: partnerListingId,
                billingRules: data.billingRules
              }).unwrap()])
          }
          if (isPublishMode) {
            await updatePublishStatus(offerResult)
          }
          dispatch(showSuccesNotification('Offer changes saved successfully.'))
          dispatch(hideModal())
        } catch (err) {
          console.error(err)
          dispatch(showErrorNotification('There was an error updating this offer. Please try again.'))
        }
      },
      onClickCancel: () => dispatch(hideModal()),
    })
  }

  return {
    onClickCreateOffer,
    onClickEditOffer,
  }
}

export default useOfferCreateEdit
