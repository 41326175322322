import { schema } from 'normalizr'

export const occupation = new schema.Entity('occupations')
occupation.schema = { children: [occupation] }

export const verificationRequest = new schema.Entity('verificationRequests')

export const governmentDepartment = new schema.Entity('governmentDepartments')

export const occupationPrequalifier = new schema.Entity('occupationPrequalifiers')

export const occupationInfoModel = new schema.Entity('occupationInfoModels')

export const listingVerificationLink = new schema.Entity('listingVerificationLinks')
export const listing = new schema.Entity('listings')
export const campaign = new schema.Entity('campaigns')
export const listingName = new schema.Entity('listingNames')

export const whitelistedEmailDomain = new schema.Entity('whitelistedEmailDomains')

export const authorizationRecord = new schema.Entity('authorizationRecords')

export const user = new schema.Entity('users')
export const affiliate = new schema.Entity('affiliates')
export const partner = new schema.Entity('partners')
export const partnerListing = new schema.Entity('partnerListings')
export const offer = new schema.Entity('offers')
export const offerV2 = new schema.Entity('offers', {}, { idAttribute: 'offerId' })

export const policy = new schema.Entity('policies', {}, { idAttribute: 'policy' })

export const allowedVerificationType = new schema.Entity('allowedVerificationTypes', {}, { idAttribute: 'verificationTypeId' })
export const allowedVerificationTypeGroup = new schema.Entity('allowedVerificationTypeGroups', {}, { idAttribute: 'occupationId' })

export const entertainer = new schema.Entity('entertainers')
export const eventTicket = new schema.Entity('eventTickets', {}, { idAttribute: 'eventId' })

export const venue = new schema.Entity('venues')
export const venueTicket = new schema.Entity('venueTickets', {}, { idAttribute: 'ticketId' })

export const documentRuleSet = new schema.Entity('documentRuleSets')
export const offerUser = new schema.Entity('offerUsers')

export const billingTerms = new schema.Entity('billingTerms')
export const defaultBillingTerms = new schema.Entity('defaultBillingTerms')