export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'

export const openDrawer = () => (dispatch, getState, { schema }) => {
	dispatch({ type: OPEN_DRAWER })
}

export const closeDrawer = () => (dispatch, getState, { schema }) => {
	dispatch({ type: CLOSE_DRAWER })
}

export const STATE_KEY = 'drawer'

export const DEFAULT_STATE = {
	isOpen: false,
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case CLOSE_DRAWER:
			return {
				...state,
				isOpen: false,
			}
		case OPEN_DRAWER:
			return {
				...state,
				isOpen: true,
			}
		default:
			return state
	}
}

export default reducer

export const selectDrawerStatus = state => state.drawer.isOpen
