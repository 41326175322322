/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Box, Typography } from '@mui/material'
import DialogSubsectionHeadline from '../../../../Header/DialogSubsectionHeadline'
import { MUIFormSelectInput } from '../../../../@Common/Form/SelectInput/FormSelectInput'
import MUIFormHexInput from '../../../../@Common/Form/TextInput/FormHexInput'
import { MUIFormFileInput } from '../../../../@Common/Form/FileInput/FormFileInput'

export function FormBackgroundSettings({
  form,
  isGovX,
  canEditListingCobrandTemplate,
  classes,
}) {
  return (
    <>
      {(form.watch('type') !== 'CoBranded' || isGovX) && (
        <>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" style={{ marginTop: isGovX ? '20px' : '40px' }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12">
              <Box gridColumn="span 4">
                <DialogSubsectionHeadline>Form Background</DialogSubsectionHeadline>
                <Typography className={classes.sectionSubHeader}>
                  Dimensions: 585 x 360px
                  <br />
                  Supports: JPG, JPEG, PNG
                  <br />
                  Color inputs must be in hex code format
                </Typography>
              </Box>
              <Box gridColumn="span 3">
                <MUIFormSelectInput
                  control={form.control}
                  name="bodyBackgroundType"
                  label="Background Type"
                  items={[
                    { value: 'color', label: 'Solid Color', disabled: isGovX },
                    { value: 'image', label: 'Image', disabled: isGovX },
                  ]}
                />
              </Box>
              {form.watch('bodyBackgroundType') === 'color' && (
                <Box gridColumn="span 2" sx={{ paddingLeft: '10px' }}>
                  <MUIFormHexInput
                    control={form.control}
                    name="bodyBackgroundColor"
                    label="Background Color"
                    rules={{ required: true }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {form.watch('bodyBackgroundType') === 'image' && (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">

              <Box gridColumn="5/12">
                <MUIFormFileInput
                  control={form.control}
                  name="bodyBackgroundImageUrl"
                  label="Background Image"
                  rules={{ required: true }}
                  fileTypes=".jpg,.jpeg,.png"
                  maxSize="2048"
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default FormBackgroundSettings
