import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ModalContainer from '../../ModalContainer'
import { getPartnerListingPath } from '../../../../setup/routeConstants'
import {
  hideModal, updatePartnersListing, showSuccesNotification, showErrorNotification,
} from '../../../../redux/actions'
import { LOCATION_CHANGE } from '../../../../setup/history'

function isListingPublished(partnerListing) {
  return partnerListing.status === 'Published'
}

export function PartnersListingStatusModal({
  partnerListing,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    setProcessing(true)

    const pl = {
      id: partnerListing.id,
      name: partnerListing.name,
      description: partnerListing.description,
      website: partnerListing.website,
      status: isListingPublished(partnerListing) ? 'Unpublished' : 'Published',
      logoId: partnerListing.logoPictureId,
    }

    dispatch(updatePartnersListing(pl))
      .then((id) => {
        setProcessing(false)
        navigate(getPartnerListingPath(id))
        dispatch({ type: LOCATION_CHANGE })
        dispatch(showSuccesNotification(`Listing ${pl.name} is ${pl.status.toLowerCase()}`))
        dispatch(hideModal())
      })
      .catch(() => {
        setProcessing(false)
        dispatch(showErrorNotification('Something went wrong. Please try that again.'))
        dispatch(hideModal())
      })
  }

  return (
    <ModalContainer
      title={isListingPublished(partnerListing) ? 'Unpublish Listing' : 'Publish Listing'}
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: { label: isListingPublished(partnerListing) ? 'Unpublish' : 'Publish', onClick: handleSubmit(onSubmit) },
        secondaryAction: { label: 'Cancel', onClick: () => dispatch(hideModal()) },
      }}
    >
      <div>
        <p>Are you sure you want to continue?</p>
      </div>
    </ModalContainer>
  )
}

PartnersListingStatusModal.propTypes = {
  partnerListing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    logoPictureId: PropTypes.number.isRequired,
  }).isRequired,
}

export default PartnersListingStatusModal
