import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { ACCESS_DOMAIN_WHITELIST_PAGE } from '../../auth/policies'

const DomainWhitelistPage = React.lazy(() => import('../../components/DomainWhitelist'))

export default {
	path: RouteConstants.whitelist.review.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_DOMAIN_WHITELIST_PAGE]}
			component={<DomainWhitelistPage />} 
		/>
	),
}