import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import {
  isOver18,
} from '../../../validation'
import { hideModal, updateUser } from '../../../redux/actions'
import { formatDateWithoutTimezone, formatPhoneNumber } from '../../../formatting'
import { normalizeNumber } from './normalizeUtils'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'

export function EditUserModal({ user }) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const formRef = React.useRef(null)

  const onSave = (values) => {
    setProcessing(true)
    const updatedUser = { ...user }
    let dateOfBirth = null

    if (values.dateOfBirth) {
      if (values.dateOfBirth.length === 8) {
        const month = values.dateOfBirth.substr(0, 2)
        const day = values.dateOfBirth.substr(2, 2)
        const year = values.dateOfBirth.substr(4, 4)
        dateOfBirth = new Date(year, month - 1, day)
      } else {
        const monthDayYear = values.dateOfBirth.split('/')
        const month = monthDayYear[0]
        const day = monthDayYear[1]
        const year = monthDayYear[2]
        dateOfBirth = new Date(year, month - 1, day)
      }
    }

    updatedUser.email = values.email
    updatedUser.firstName = values.firstName
    updatedUser.lastName = values.lastName
    updatedUser.phoneNumber = normalizeNumber(values.phoneNumber)
    updatedUser.zipCode = normalizeNumber(values.zipCode)
    updatedUser.gender = values.gender
    updatedUser.dateOfBirth = dateOfBirth

    return dispatch(updateUser(updatedUser))
      .then(() => {
        setProcessing(false)
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('updateUser error', err)
      })
  }

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      ...user,
      dateOfBirth: user.dateOfBirth ? formatDateWithoutTimezone(user.dateOfBirth) : null,
      phoneNumber: user.phoneNumber ? formatPhoneNumber(user.phoneNumber) : null,
    },
  })

  return (
    <ModalContainer
      title="Edit User"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSave),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSave)} style={{ marginTop: '10px' }}>
        <MUIFormTextInput
          control={control}
          name="email"
          label="Email"
          disabled
        />
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="firstName"
            label="First name"
            rules={{ required: true }}
          />
        </Box>
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="lastName"
            label="Last name"
            rules={{ required: true }}
          />
        </Box>
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="phoneNumber"
            label="Mobile phone number"
            rules={{
              pattern: {
                value: /\(\d{3}\)\s\d{3}-\d{4}\s?[0-9]?[0-9]?[0-9]?[0-9]?/,
                message: 'Invalid phone number',
              },
            }}
            mask="(999) 999-9999 x9999"
          />
        </Box>
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="zipCode"
            label="Zip code"
            rules={{
              pattern: {
                value: /\d{5}-\d{4}|\d{5}/,
                message: 'Invalid zip code',
              },
            }}
            mask="99999-9999"
          />
        </Box>
        <Box mt="10px">
          <MUIFormSelectInput
            control={control}
            name="gender"
            label="Gender"
            items={[
              { key: '', value: '', label: '' },
              { key: 'male', value: 'Male', label: 'Male' },
              { key: 'female', value: 'Female', label: 'Female' },
            ]}
          />
        </Box>
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="dateOfBirth"
            label="Date of birth (MM/DD/YYYY)"
            rules={{
              pattern: {
                value: /^\d{2}\/\d{2}\/\d{4}$/,
                message: 'Invalid date',
              },
              validate: (value) => isOver18(value),
            }}
            mask="99/99/9999"
          />
        </Box>
      </form>
    </ModalContainer>
  )
}

EditUserModal.propTypes = {
  user: PropTypes.shape({
    dateOfBirth: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
}

export default EditUserModal
