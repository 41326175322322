/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { fetchScanResults, hideModal } from '../../redux/actions'
import ModalContainer from './ModalContainer'
import { formatDateTime, formatVerificationDocumentType } from '../../formatting'

export function DocScanResultsModal({ verificationRequestId }) {
  const dispatch = useDispatch()

  const [scanResults, setScanResults] = useState(null)
  const [ready, setReady] = useState(false)

  const getScanResults = () => dispatch(fetchScanResults(verificationRequestId))
  const onCancel = () => dispatch(hideModal())

  useEffect(() => {
    const fetchData = async () => {
      const results = await getScanResults()
      setScanResults(results[0])
      setReady(true)
    }
    fetchData()
  }, [])

  return (
    <ModalContainer
      title="Document Scan Results"
      maxWidth="sm"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onCancel,
        },
      }}
    >
      {
    ready && !scanResults
    && (
    <div>
      Document not scanned
    </div>
    )
  }
      {
    ready && scanResults
    && (
    <div>
      <Box
        sx={{
          backgroundColor: '#e3e3e3',
          margin: '0px -24px',
          padding: '10px 24px',
          width: 'calc(100% + 48px)',
        }}
      >
        <Grid container spacing={1}>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <strong>Document Type:</strong>
            </Grid>
            <Grid item xs={9}>
              { formatVerificationDocumentType(scanResults.verificationDocumentType) }
              <br />
              { scanResults.documentSubType && scanResults.documentSubType !== 'undefined' && <span style={{ color: '#878a8f' }}>{ scanResults.documentSubType }</span> }
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <strong>Date Scanned:</strong>
            </Grid>
            <Grid item xs={9}>
              { formatDateTime(scanResults.scanDateUtc) }
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <strong>Time to Scan:</strong>
            </Grid>
            <Grid item xs={9}>
              { scanResults.timeToScanMilliseconds / 1000.0 }
              {' '}
              seconds
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <strong>Result:</strong>
            </Grid>
            <Grid item xs={9}>
              <strong style={{ color: scanResults.passed ? '#68cc72' : '#bd2326' }}>{ scanResults.passed ? 'PASSED' : 'FAILED' }</strong>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: '10px', padding: '2px' }}>
        <div style={{ textAlign: 'right', color: '#878a8f', fontSize: '12px' }}>
          Rule-set applied:
          { scanResults.ruleSetName }
          {' '}
          (
          { scanResults.ruleSetVersion }
          )
        </div>
      </div>
      {
        !scanResults.items.length && (
          <div>
            <strong>Document not scanned</strong>
            <p style={{ color: '#878a8f' }}>This document was not scanned, no matching rule sets were found during scan.</p>
          </div>
        )
      }
      <div>
        <List>
          {
            scanResults.items.map((x, i) => (
              <ListItem
                key={i}
                sx={{
                  border: '1px solid #cfcfcf', marginTop: '-1px', overflowWrap: 'anywhere', padding: '11px 24px',
                }}
              >
                <Grid container>
                  <Grid item xs={10} sx={{ color: '#222', lineHeight: '1.25', fontSize: '14px' }}>
                    { x.description }
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {
                      x.passed
                        ? <CheckCircle style={{ color: '#68cc72' }} />
                        : <Cancel style={{ color: '#bd2326' }} />
                    }
                  </Grid>
                </Grid>
              </ListItem>
            ))
          }
        </List>
      </div>
    </div>
    )
  }
    </ModalContainer>
  )
}

export default DocScanResultsModal
