import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, updateListing } from '../../../redux/actions'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export function EditListingNameModal({ listing }) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: listing.name,
    },
  })

  const onSubmit = (values) => dispatch(updateListing({ ...listing, name: values.name }))
    .then(dispatch(hideModal()))

  return (
    <ModalContainer
      title="Edit listing name"
      modal
      maxWidth="xs"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        <MUIFormTextInput
          name="name"
          autoFocus
          label="Listing name"
          shrinkLabel
          rules={{ required: true }}
          control={control}
        />
      </form>
    </ModalContainer>
  )
}

EditListingNameModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default EditListingNameModal
