import React from "react";
import classNames from "classnames";
import MUICheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box } from "@mui/material";

export const CheckboxInput = ({
  label,
  checked,
  name,
  disabled,
  onChange,
  labelPosition = "right",
  gxid,
}) => {
  return (
    <div
      className={classNames(
        labelPosition === "left" ? "inline-flex items-center gap-3" : "",
        labelPosition === "right"
          ? "inline-flex flex-row-reverse items-center gap-3"
          : ""
      )}
    >
      {label.length > 0 && (
        <label
          onClick={() => onChange(!on)}
          htmlFor={name}
          className={classNames(
            labelPosition === "top" ? "mb-1" : "",
            `block cursor-pointer select-none leading-6 text-gray-dark`
          )}
        >
          {label}
        </label>
      )}
      <input
        className={
          "w-5 h-5 ease-soft text-base rounded-sm checked:bg-blue-dark after:text-sm font-bold cursor-pointer appearance-none bg-white border border-gray-300 checked:border-transparent shadow-sm after:text-white checked:after:flex checked:after:items-center checked:after:justify-center checked:after:content-['✓'] after:opacity-0 checked:after:opacity-100"
        }
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.currentTarget.checked)}
        data-gxid={gxid}
      />
    </div>
  );
};


export const MUICheckboxInput = ({
  label,
  checked,
  name,
  disabled,
  onChange,
  onClick,
  labelPosition = "right",
  reducedPadding = false,
  gxid,
  style = "",
  size = "medium",
}) => {
  const mapLabelPlacement = (position) => {
    switch (position) {
      case "left":
        return "start";
      case "right":
        return "end";
      case "top":
        return "top";
    }
  }
  const smallStyle = {
    paddingTop: '15px',
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
  }

  return (
    <Box sx={size === 'small' ? { ...smallStyle, ...style } : { ...style }}>
      <FormControlLabel
        labelPlacement={mapLabelPlacement(labelPosition)}
        sx={{
          userSelect: 'none',
        }}
        control={
          <MUICheckbox label={label} name={name} onClick={onClick} onChange={onChange} value={label} checked={checked} disabled={disabled} aria-label={label} inputProps={{ 'data-gxid': gxid }} sx={reducedPadding ? { padding: '2px 12px 3px' } : {}} />
        }
        label={label}
      />
    </Box>
  )
};