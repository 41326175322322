import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataTable from '../../../../../@Common/DataTable'
import NoRows from '../../../../../@Common/DataTable/NoRows'
import Button from '../../../../../Button'
import { useSearchParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import Header from '../../../../../Header'
import moment from 'moment';
import Loader from '../../../../../Loader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getGridStringOperators, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { getPaymentMethodString } from '../../Marketplace/Billing/BillingTerms';


function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ padding: '4px 4px 4px' }}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function InvoiceDetails({ invoiceDetails, billingTerms, isError }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState({
        items: [
        ],
        logicOperator: 'and',
    })

    const columns = [
        {
            field: 'orderDate',
            headerName: 'Date',
            flex: 1,
            type: 'date',
            valueGetter: (params) => params.row.orderDate ? new Date(moment(params.row.orderDate).format('MM/DD/YYYY')) : null,
            renderCell: (params) => params.row.orderDate ? `${moment(params.row.orderDate).format('M/D')}` : null,

        },
        {
            field: 'orderNumber',
            headerName: 'Order Number',
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            flex: 1,
            numeric: true,
        },
        {
            field: 'discountCode',
            headerName: 'Discount Code',
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            flex: 1,
        },
        {
            field: 'orderTotal',
            headerName: 'Order Subtotal',
            numeric: true,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            valueGetter: (params) => parseFloat(params.row.orderTotal?.toFixed(2)),
            renderCell: (params) => `$${params.value?.toFixed(2)}`,
            flex: 1,
        },
        {
            field: 'commissionRate',
            headerName: 'Commission %',
            flex: 1,
            numeric: true,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            valueGetter: (params) => params.row.commissionRate * 100,
            renderCell: (params) => `${params.value}%`
        },
        {
            field: 'commissionTotal',
            headerName: 'Commission',
            flex: 1,
            numeric: true,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
            valueGetter: (params) => parseFloat(params.row.commissionTotal?.toFixed(2)),
            renderCell: (params) => `$${params.row.commissionTotal?.toFixed(2)}`,

        }
    ]

    return (
        invoiceDetails ? <>
            <Button startIcon={<ArrowBackIcon />} variant={'outlined'} color="primary" label={'View All Invoices'} onClick={() => setSearchParams('')}></Button>

            <Grid container sx={{ marginTop: '25px' }}>
                <Grid item xs={12} sx={{ fontSize: '24px' }}>
                    <Header title={`Billing Period ${moment(Date.parse(invoiceDetails.billingCycleStart)).format('M/DD')} - ${moment(Date.parse(invoiceDetails.billingCycleEnd)).format('M/DD/YYYY')}`} />
                </Grid>
            </Grid>
            <Grid container sx={{ fontSize: '14px', marginBottom: '10px' }} columns={16} spacing={1}>
                <Grid item xs={'auto'}>
                    <Box sx={{ borderRight: '1px solid #C5C5C5', padding: '0px 15px 8px 0px', paddingTop: '0', paddingBottom: '0' }}>
                        Invoice #{invoiceDetails.invoiceId}
                    </Box>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box sx={{ borderRight: '1px solid #C5C5C5', padding: '0px 15px 8px 0px', paddingTop: '0', paddingBottom: '0' }}>
                        Subscription ID #{invoiceDetails.subscriptionId}
                    </Box>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box sx={{ padding: '0px 15px 8px 0px', paddingTop: '0', paddingBottom: '0' }}>
                        Payment Method: {`${getPaymentMethodString(billingTerms.paymentInfo)}`}
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ fontSize: '14px', marginBottom: '50px' }} columns={16} spacing={1}>
                <Grid item xs={'auto'}>
                    <Box sx={{ borderRight: '1px solid #C5C5C5', padding: '0px 15px 8px 0px', paddingTop: '0', paddingBottom: '0' }}>
                        Issue Date: {moment(invoiceDetails.issuedDate).format('M/DD/YYYY')}
                    </Box>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box sx={{ padding: '0px 15px 8px 0px', paddingTop: '0', paddingBottom: '0' }}>
                        Due Date: {moment(invoiceDetails.dueDate).format('M/DD/YYYY')}
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ fontSize: '14px', marginBottom: '50px', textAlign: 'center' }} spacing={10}>
                <Grid item xs={'auto'}>
                    <Grid container>
                        <Grid item xs={'auto'}>
                            <Box sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>Orders</Box>
                            <Box sx={{ fontSize: '24px', fontWeight: 700 }}>{invoiceDetails.totalOrders}</Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={'auto'}>
                    <Grid container>
                        <Grid item xs={'auto'}>
                            <Box sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>Total Sales</Box>
                            <Box sx={{ fontSize: '24px', fontWeight: 700 }}>${invoiceDetails.totalSales}</Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={'auto'}>
                    <Grid container>
                        <Grid item xs={'auto'}>
                            <Box sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>Commission</Box>
                            <Box sx={{ fontSize: '24px', fontWeight: 700 }}>${invoiceDetails.totalCommission}</Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={'auto'}>
                    <Grid container>
                        <Grid item xs={'auto'}>
                            <Box sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '20px' }}>Status</Box>
                            <Box sx={{ fontSize: '24px', fontWeight: 700 }}>{invoiceDetails.status}</Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DataTable
                slots={{
                    noRowsOverlay: () => <NoRows message={'No invoice items found'} />,
                    toolbar: () => <CustomToolbar />,
                    exportIcon: FileDownloadIcon,
                }}
                getRowId={(row) => row.orderNumber}
                pagination={true}
                hideFooterSelectedRowCount
                rowSelection
                rowCount={invoiceDetails ? invoiceDetails.items.length : 0}
                rows={invoiceDetails ? invoiceDetails.items : []}
                pageSizeOptions={[25, 50, 100, 500]}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100, 500]}
                disableRowSelectionOnClick
                filterModel={{
                    items: filters.items,
                    logicOperator: filters.logicOperator,
                }}
                onFilterModelChange={(model) => {
                    setFilters({
                        items: model.items,
                        logicOperator: model.logicOperator,
                    })
                }}
            />


        </> : isError ?
            <>
                <Button startIcon={<ArrowBackIcon />} variant={'outlined'} color="primary" label={'View All Invoices'} onClick={() => setSearchParams('')}></Button>
                <DataTable
                    slots={{
                        noRowsOverlay: () => <NoRows message={'No invoice items found'} />,
                        toolbar: () => <CustomToolbar />,
                        exportIcon: FileDownloadIcon,
                    }}
                    getRowId={(row) => row.orderNumber}
                    pagination={true}
                    hideFooterSelectedRowCount
                    rowSelection
                    rowCount={invoiceDetails ? invoiceDetails.items.length : 0}
                    rows={invoiceDetails ? invoiceDetails.items : []}
                    pageSizeOptions={[25, 50, 100, 500]}
                    columns={columns}
                    rowsPerPageOptions={[25, 50, 100, 500]}
                    disableRowSelectionOnClick
                    filterModel={{
                        items: filters.items,
                        logicOperator: filters.logicOperator,
                    }}
                    onFilterModelChange={(model) => {
                        setFilters({
                            items: model.items,
                            logicOperator: model.logicOperator,
                        })
                    }}
                />
            </>
            : <Loader />

    )
}

export default InvoiceDetails