import MoreVert from '@mui/icons-material/MoreVert'
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import EligibilityIconList from '../EligibilityIconList'
import { PublishedStatusIndicator } from '../../../../../../@Common/StatusIndicator/PublishedStatusIndicator'
import CodePoolOfferDetail from './CodePoolOfferDetail'
import ProtectedLinkOfferDetail from './ProtectedLinkOfferDetail'
import DiscountCodeOfferDetail from './DiscountCodeOfferDetail'
import { copyAction } from '../../../../../../@Common/Actions/copyAction'

const discountTypes = {
  percentOff: { type: 'PercentOff', name: 'Percentage' },
  fixedAmount: { type: 'FixedAmount', name: 'Fixed Amount' },
  noScheme: { type: 'NoScheme', name: 'None' },
}

const offerFont = {
  fontSize: '0.875em',
}

const useStyles = createUseStyles({
  offerDataLine: {
    marginBottom: '5px',
  },
})

const getDiscountType = (discountObj) => {
  switch (discountObj.scheme.typeName) {
    case discountTypes.percentOff.type:
      return discountTypes.percentOff.name
    case discountTypes.fixedAmount.type:
      return discountTypes.fixedAmount.name
    case discountTypes.noScheme.type:
    default:
      return discountTypes.noScheme.name
  }
}

const getOfferType = (discountObj) => {
  if (discountObj.protectedLink) { return { name: 'Protected Link', key: 'protectedLink' } }
  if (discountObj.codePool) { return { name: 'Discount Codes', key: 'codePool' } }
  if (discountObj.discountCode) { return { name: 'Single Discount Code', key: 'discountCode' } }
  return { name: '', key: 'none' }
}

function OfferListItem({
  offer,
  onMenuHover,
  onClickCommissionRateHistory,
  onClickViewSyndicatedListings,
  onClickEdit,
  onClickGovXIdLink,
  onClickPublish,
  onClickUnpublish,
  onClickDelete,
  onClickSyncOffer,
  onClickSyndicateOffer,
  canSetStatus,
  canDeleteOffer,
  canSyncOffer,
  canSyndicateOffer,
  canDeleteSyndicateOffer,
  canViewSyndicatedListings,
  channels,
  isSyndicateOffer,
  onClickRemoveSyndicate,
}) {
  const dispatch = useDispatch()
  const [menuAnchor, setMenuAnchor] = useState(null)
  const isShopifyOffer = offer.source.type.toLowerCase() === 'shopify'
  const classes = useStyles()

  return (
    <Box
      sx={{
        border: '1px #e3e3e3 solid',
        paddingX: '25px',
        paddingY: '25px',
        marginBottom: '20px',
        minHeight: '150px',
        flexWrap: 'wrap',
      }}
    >
      <Grid container>
        <Grid item lg={10} md={12} order={{ lg: 1, md: 2, sm: 2 }}>
          <Grid container>
            <Grid item sm={12} md={6}>
              <Typography
                variant="h4"
                style={{
                  margin: '0px',
                  fontSize: '20px',
                  whiteSpace: 'wrap',
                  fontWeight: 'bold',
                }}
              >
                {offer.name}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box display="flex" sx={{ marginBottom: '10px' }}>
                <Box sx={{ fontWeight: 'bold', marginRight: '16px' }}>
                  Eligibility:
                </Box>
                <div>
                  <EligibilityIconList eligibleGroups={offer.verification.eligibleGroups} />
                </div>
              </Box>
            </Grid>
            {
              getOfferType(offer.discount).key === 'codePool'
              && (
                <CodePoolOfferDetail
                  offer={offer}
                  discountTypeName={getDiscountType(offer.discount)}
                  offerType={getOfferType(offer.discount)}
                  discountTypes={discountTypes}
                  offerFont={offerFont}
                  onClickCommissionRateHistory={onClickCommissionRateHistory}
                  classes={classes}
                  channels={channels}
                  isSyndicateOffer={isSyndicateOffer && canSyndicateOffer}
                  onClickViewSyndicatedListings={onClickViewSyndicatedListings}
                  canViewSyndicatedListings={canViewSyndicatedListings}
                />
              )
            }
            {
              getOfferType(offer.discount).key === 'protectedLink'
              && (
                <ProtectedLinkOfferDetail
                  offer={offer}
                  discountTypeName={getDiscountType(offer.discount)}
                  offerType={getOfferType(offer.discount)}
                  discountTypes={discountTypes}
                  offerFont={offerFont}
                  onClickCommissionRateHistory={onClickCommissionRateHistory}
                  classes={classes}
                  channels={channels}
                  isSyndicateOffer={isSyndicateOffer && canSyndicateOffer}
                  onClickViewSyndicatedListings={onClickViewSyndicatedListings}
                  canViewSyndicatedListings={canViewSyndicatedListings}
                />
              )
            }
            {
              getOfferType(offer.discount).key === 'discountCode'
              && (
                <DiscountCodeOfferDetail
                  offer={offer}
                  discountTypeName={getDiscountType(offer.discount)}
                  offerType={getOfferType(offer.discount)}
                  discountTypes={discountTypes}
                  offerFont={offerFont}
                  onClickCommissionRateHistory={onClickCommissionRateHistory}
                  classes={classes}
                  channels={channels}
                  isSyndicateOffer={isSyndicateOffer && canSyndicateOffer}
                  onClickViewSyndicatedListings={onClickViewSyndicatedListings}
                  canViewSyndicatedListings={canViewSyndicatedListings}
                />
              )
            }
          </Grid>
        </Grid>
        <Grid item lg={2} md={12} sm={12} order={{ lg: 2, md: 1, sm: 1 }}>
          <Grid
            container
            sx={{
              alignItems: 'center', alignSelf: 'start', mt: '-10px',
            }}
            justifyContent="flex-end"
          >
            <PublishedStatusIndicator
              publishedStatus={offer.status.toLowerCase() === 'published'}
              gxid="offerItemStatusIndicator"
              sx={{ paddingBottom: '5px' }}
            />
            {((
              (isSyndicateOffer && canDeleteSyndicateOffer)
              || (!isSyndicateOffer && (canSyndicateOffer || (isShopifyOffer && canSyncOffer) || (!isShopifyOffer && (canDeleteOffer || (canSetStatus && offer.status !== 'Unspecified'))))))
              && (
                <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)} onMouseEnter={onMenuHover}>
                  <MoreVert />
                </IconButton>
              )
            )}
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => {
                setMenuAnchor(null)
              }}
            >
              {isSyndicateOffer && canDeleteSyndicateOffer
                && (
                  <MenuItem onClick={() => { onClickRemoveSyndicate(offer); setMenuAnchor(null) }}>
                    Remove
                  </MenuItem>
                )}
              {!isSyndicateOffer
                && (
                  <MenuItem onClick={() => { onClickEdit(offer); setMenuAnchor(null) }}>
                    Edit
                  </MenuItem>
                )}
              {!isSyndicateOffer && !isShopifyOffer
                && (
                  <MenuItem onClick={() => { onClickGovXIdLink(offer); setMenuAnchor(null) }}>
                    GovX ID Link
                  </MenuItem>
                )}
              {!isSyndicateOffer && (
                <MenuItem onClick={() => {
                  copyAction({ dispatch, text: offer.offerId, label: 'Offer ID' })
                  setMenuAnchor(null)
                }}
                >
                  Copy Offer ID
                </MenuItem>
              )}
              {!isSyndicateOffer && canSyndicateOffer
                && (
                  <MenuItem onClick={() => { onClickSyndicateOffer(offer.offerId); setMenuAnchor(null) }}>
                    Syndicate Offer
                  </MenuItem>
                )}
              {!isSyndicateOffer && !isShopifyOffer && canDeleteOffer
                && (
                  <MenuItem onClick={() => { onClickDelete(offer); setMenuAnchor(null) }}>
                    Delete
                  </MenuItem>
                )}
              {!isSyndicateOffer && !isShopifyOffer && canSetStatus && (offer.status === 'Unpublished' || offer.status === 'Unspecified')
                && (
                  <MenuItem onClick={() => { onClickPublish(offer); setMenuAnchor(null) }}>
                    Publish
                  </MenuItem>
                )}
              {!isSyndicateOffer && !isShopifyOffer && canSetStatus && offer.status === 'Published'
                && (
                  <MenuItem onClick={() => { onClickUnpublish(offer); setMenuAnchor(null) }}>
                    Unpublish
                  </MenuItem>
                )}
              {!isSyndicateOffer && isShopifyOffer && canSyncOffer
                && (
                  <MenuItem onClick={() => { onClickSyncOffer(offer); setMenuAnchor(null) }}>
                    Sync Offer
                  </MenuItem>
                )}
            </Menu>
          </Grid>

        </Grid>
      </Grid>

    </Box>
  )
}

OfferListItem.defaultProps = {}

OfferListItem.propTypes = {
  offer: PropTypes.shape({
    offerId: PropTypes.string,
    listingId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string.isRequired,
    source: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
    discount: PropTypes.shape({
      protectedLink: PropTypes.shape({
        link: PropTypes.string.isRequired,
      }),
      codePool: PropTypes.shape({
        code: PropTypes.string,
        total: PropTypes.number,
        used: PropTypes.number,
        type: PropTypes.string,
      }),
      discountCode: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
      scheme: PropTypes.shape({
        typeName: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    verification: PropTypes.shape({
      eligibleGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    syndicateListingName: PropTypes.string,
    syndicateId: PropTypes.string,
  }).isRequired,

  onMenuHover: PropTypes.func.isRequired,
  onClickCommissionRateHistory: PropTypes.func.isRequired,
  onClickViewSyndicatedListings: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickGovXIdLink: PropTypes.func.isRequired,
  onClickPublish: PropTypes.func.isRequired,

  onClickUnpublish: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickSyncOffer: PropTypes.func.isRequired,
  onClickSyndicateOffer: PropTypes.func.isRequired,

  canSetStatus: PropTypes.bool.isRequired,

  canDeleteOffer: PropTypes.bool.isRequired,
  canSyncOffer: PropTypes.bool.isRequired,
  canSyndicateOffer: PropTypes.bool,
  canDeleteSyndicateOffer: PropTypes.bool,
  canViewSyndicatedListings: PropTypes.bool,
  isSyndicateOffer: PropTypes.bool.isRequired,

  onClickRemoveSyndicate: PropTypes.func,

  channels: PropTypes.arrayOf(PropTypes.shape({
    defaultChannel: PropTypes.bool,
    disable: PropTypes.bool,
    order: PropTypes.number,
    text: PropTypes.string,
    value: PropTypes.string,
  })),
}

export default OfferListItem
