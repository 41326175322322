/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Box } from '@mui/material'
import HeaderSettings from './TemplateFormControls/HeaderSettings'
import FormBackgroundSettings from './TemplateFormControls/FormBackgroundSettings'
import FooterSettings from './TemplateFormControls/FooterSettings'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { MUIFormSelectInput } from '../../../@Common/Form/SelectInput/FormSelectInput'

const useStyles = createUseStyles({
  sectionHeader: {
    margin: '0px',
    fontSize: '1.2em',
    fontWeight: '600 !important',
  },
  sectionSubHeader: {
    fontSize: '1em',
  },
  colorInputContainer: {
    marginTop: '2px',
  },
  swatchContainer: {
    marginTop: '5px',
  },
})

function TemplateFormPartial({
  form,
  isGovX = false,
  canEditListingCobrandTemplate,
  initialValues,
}) {
  const classes = useStyles()
  const { control } = form
  const [showFooterLinks, setShowFooterLinks] = useState(initialValues.footerShowFooterLinks)
  const [showGovXLogo, setShowGovXLogo] = useState(initialValues.footerShowGovXLogo)

  return (
    <div style={{ fontSize: '14px' }}>
      {!isGovX && (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn="span 4">
            <DialogSubsectionHeadline marginTop="0px">Template</DialogSubsectionHeadline>
          </Box>
          <Box gridColumn="span 3">
            <MUIFormSelectInput
              name="type"
              label="Template Type"
              control={control}
              items={[
                { value: 'CoBranded', label: 'Co-branded', disabled: false },
                { value: 'PrivateLabel', label: 'Private label', disabled: false },
              ]}
            />
          </Box>
        </Box>
      )}

      <HeaderSettings
        form={form}
        isGovX={isGovX}
        canEditListingCobrandTemplate={canEditListingCobrandTemplate}
        classes={classes}
      />

      <FormBackgroundSettings
        form={form}
        isGovX={isGovX}
        canEditListingCobrandTemplate={canEditListingCobrandTemplate}
        classes={classes}
      />

      <FooterSettings
        form={form}
        isGovX={isGovX}
        canEditListingCobrandTemplate={canEditListingCobrandTemplate}
        classes={classes}
        showGovXLogo={showGovXLogo}
        showFooterLinks={showFooterLinks}
        setShowFooterLinks={setShowFooterLinks}
        setShowGovXLogo={setShowGovXLogo}
      />
    </div>
  )
}

export default TemplateFormPartial
