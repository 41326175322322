/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { showModal } from '../../../../redux/actions'
import {
  selectSpecialOffersByListingId,
  hasPolicy,
} from '../../../../redux/selectors'
import {
  ACCESS_LISTING_ADVERTISEMENT,
} from '../../../../auth/policies'
import { downloadListingSpecialOffersUserSubscriptionReportLink } from '../../../../setup/routeConstants'
import StyledLink from '../../../StyledLink'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { MUIFormTextAreaInput } from '../../../@Common/Form/TextAreaInput/FormTextAreaInput'

const DEFAULT_SEARCH = (listing) => ({
  id: 'offer-users',
  filter: {
    timeFrame: {
      type: 'any', from: null, to: null, expanded: false,
    },
    listing,
  },
  paging: {
    pageSize: 25,
  },
})

const ConvertOption = (option) => option.replace(/[A-Z]/g, (match, index) => (index > 0 ? `-${match.toLowerCase()}` : match))

function AdvertisingFormPartial({
  listingId,
  form,
}) {
  const dispatch = useDispatch()

  const specialOffers = useSelector(selectSpecialOffersByListingId(listingId))
  const canAccessListingSettingsAdvertisementTab = useSelector(hasPolicy(ACCESS_LISTING_ADVERTISEMENT))

  const createSpecialOffer = () => dispatch(showModal('CREATE_LISTING_SPECIAL_OFFER', { listingId }))
  const deleteSpecialOffer = (so) => dispatch(showModal('DELETE_LISTING_SPECIAL_OFFER', { listingId, specialOffer: so }))

  const { control, watch } = form

  return (
    <div style={{ position: 'relative', fontSize: '14px' }}>
      {canAccessListingSettingsAdvertisementTab && (
      <div>
        <div style={{ position: 'relative', float: 'left', width: '30%' }}>
          <DialogSubsectionHeadline>Ad Placement</DialogSubsectionHeadline>
          <span>Fields take basic html with inline styles.</span>
        </div>
        <div style={{ position: 'relative', float: 'left', width: '70%' }}>
          <div style={{ position: 'relative' }}>
            <MUIFormTextAreaInput
              control={control}
              name="welcomeFooter"
              label="Welcome footer"
              rules={{ maxLength: 500 }}
              rows={3}
              shrinkLabel
            />
            <Typography>
              <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
                { watch('welcomeFooter') ? watch('welcomeFooter').length : 0 }
                {' '}
                of 500
              </small>
            </Typography>
          </div>

          <div style={{ position: 'relative', margin: '30px 0px' }}>
            <MUIFormTextAreaInput
              control={control}
              name="advertisement"
              label="Occupation Group Message"
              rules={{ maxLength: 500 }}
              rows={3}
              shrinkLabel
            />
            <Typography>
              <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
                { watch('advertisement') ? watch('advertisement').length : 0 }
                {' '}
                of 500
              </small>
            </Typography>
          </div>
        </div>
      </div>
      )}
      <div style={{ position: 'relative', float: 'left', width: '30%' }}>
        <DialogSubsectionHeadline>Special Offers</DialogSubsectionHeadline>
        <span>Offers are served after the verification process.</span>
      </div>
      <div style={{ position: 'relative', float: 'left', width: '70%' }}>
        <div style={{ position: 'relative' }}>
          <MUIFormTextAreaInput
            control={control}
            name="specialOfferHeadline"
            label="Headline"
            rules={{ maxLength: 100 }}
            rows={1}
            shrinkLabel
          />
          <Typography>
            <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
              { watch('specialOfferHeadline') ? watch('specialOfferHeadline').length : 0 }
              {' '}
              of 100
            </small>
          </Typography>
        </div>
        <div style={{ position: 'relative', margin: '30px 0px' }}>
          <MUIFormTextAreaInput
            control={control}
            name="specialOfferIntro"
            label="Offer Intro"
            rules={{ maxLength: 400 }}
            rows={3}
            shrinkLabel
          />
          <Typography>
            <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
              { watch('specialOfferIntro') ? watch('specialOfferIntro').length : 0 }
              {' '}
              of 400
            </small>
          </Typography>
        </div>
        <Box sx={{ mb: '5px' }}>
          <StyledLink
            to={downloadListingSpecialOffersUserSubscriptionReportLink(DEFAULT_SEARCH({ id: listingId }))}
            contents="Export"
            newWindow
          />
        </Box>
        <Table>
          <TableHead style={{ backgroundColor: '#f1f1f1' }}>
            <TableRow>
              <TableCell style={{
                width: '10%', fontSize: '14px', color: '#222', fontWeight: '600',
              }}
              >
                ID
              </TableCell>
              <TableCell style={{
                width: '60%', fontSize: '14px', color: '#222', fontWeight: '600',
              }}
              >
                Offer
              </TableCell>
              <TableCell style={{
                width: '10%', fontSize: '14px', color: '#222', fontWeight: '600',
              }}
              >
                State
              </TableCell>
              <TableCell style={{
                width: '10%', fontSize: '14px', color: '#222', fontWeight: '600',
              }}
              >
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialOffers && specialOffers.map((o) => (
              <TableRow hover key={o.id}>
                <TableCell style={{ position: 'relative' }}>{o.id}</TableCell>
                <TableCell style={{ position: 'relative' }}>{o.message}</TableCell>
                <TableCell style={{ position: 'relative' }}>{ConvertOption(o.displayOption)}</TableCell>
                <TableCell style={{ position: 'relative', cursor: 'pointer' }}>
                  <IconButton
                    aria-label="Delete"
                    label=""
                    aria-haspopup="true"
                    onClick={() => deleteSpecialOffer(o)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            { specialOffers && specialOffers.length < 5 && (
            <TableRow>
              <TableCell colSpan="4">
                <Box>
                  <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: 'transparent' }} onClick={createSpecialOffer}>
                      <div style={{
                        position: 'relative', width: '60px', height: '60px', margin: '0 auto', left: '-10px',
                      }}
                      >
                        <AddCircleIcon sx={{ width: 'inherit', height: 'inherit', color: '#f1f1f1' }} />
                        <span style={{
                          backgroundClip: 'circle(15px at 18px 17px)',
                          backgroundColor: '#0E5777',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: '0px',
                          display: 'block',
                          zIndex: '-1',
                          clipPath: 'circle(35% at 50% 45%)',
                        }}
                        />
                      </div>
                    </Button>
                    <div>No offer has been added</div>
                    <Button variant="text" color="primary" style={{ textTransform: 'none', marginLeft: '-16px' }} onClick={createSpecialOffer}>
                      <span>Add Offer</span>
                    </Button>
                  </div>
                </Box>
              </TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default AdvertisingFormPartial
