import React from 'react'
import { connect } from 'react-redux'
// Partner Listings
import ShopifyIntegrationSideSheet from './PartnerListings/ShopifyIntegrationSideSheet'

let SIDE_SHEET_COMPONENTS = {
	'SHOPIFY_INTEGRATION_SYNC': ShopifyIntegrationSideSheet,
}

export const addSideSheets = sideSheets => {
	SIDE_SHEET_COMPONENTS = { ...SIDE_SHEET_COMPONENTS, ...sideSheets }
}

export const SideSheetRoot = ({ sideSheetType, sideSheetProps }) => {
	if (!sideSheetType) return null
	let SpecificSideSheet = SIDE_SHEET_COMPONENTS[sideSheetType]
	return <SpecificSideSheet { ...sideSheetProps } />
}

export default connect(state => state.sideSheet)(SideSheetRoot)