/* eslint-disable import/prefer-default-export */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../redux/actions'
import ModalContainer from '../ModalContainer'

export function PublishOfferModal({ offer, setPublished, onChangeStatus }) {
  const dispatch = useDispatch()
  const onCancel = useCallback(() => dispatch(hideModal()), [dispatch])
  const [processing, setProcessing] = useState(false)
  return (
    <ModalContainer
      title={`${setPublished ? 'Publish' : 'Unpublish'} Offer`}
      modal
      maxWidth="md"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: setPublished ? 'Publish' : 'Unpublish',
          onClick: async () => {
            setProcessing(true)
            try {
              await onChangeStatus(offer, setPublished)
            } finally {
              setProcessing(false)
            }
          },
        },
        secondaryAction: { label: 'Cancel', onClick: onCancel },
      }}
    >
      <div>
        Are you sure you want to
        {' '}
        {setPublished ? 'publish' : 'unpublish'}
        {' '}
        <strong>{offer.name}</strong>
        ?
      </div>
    </ModalContainer>
  )
}

PublishOfferModal.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setPublished: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}
