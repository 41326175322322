import { createTheme } from '@mui/material/styles'
import { getAPIDomain } from './configuration'

const placeholder = {
	color: '#222',
}

export const adminMuiTheme = createTheme({
	fontFamily: '"Roboto Regular", Helvetica, sans-serif',
	typography: {
		fontFamily: ['Roboto Regular', 'Helvetica', 'sans-serif'].join(','),
		fontSize: 13,
		h3: {
			fontSize: 13,
		  },
	},
	palette: {
		primary: {
			main: '#fff',
		},
		accent1Color: '#cf5928',
	},
	overrides: {
		MuiStepIcon: {
			root: {
				'&$active': {
					color: '#00a1df',
				},
				'&$completed': {
					color: '#00a1df',
				},
			},
			text: {
				fill: 'white',
			},
		},
		MuiSnackbar: {},
		MuiSwitch: {
			root: {
				'&.Mui-checked': {
					'& + $bar': {
						opacity: 0.85,
					},
				},
			},
			iconChecked: {
				backgroundColor: '#0E5777',
			},
			colorPrimary: {
				'&$checked': {
					color: '#0E5777',
					'& + $bar': {
						backgroundColor: '#0E5777',
					},
				},
			},
			colorSecondary: {
				'&$checked': {
					color: '#0E5777',
					'& + $bar': {
						backgroundColor: '#0E5777',
					},
				},
			},
			bar: {
				backgroundColor: '#0E5777',
				opacity: 0.25,
			},
		},
		MuiSelect: {
			select: {
				'&:focus': {
					background: '#fff',
				},
			},
		},
		MuiButton: {
			//root: {},
			textPrimary: {
				color: '#00a1df',
				padding: '8px 16px',
				'&:hover': {
					//backgroundColor: 'transparent',
					transition: 'opacity .15s ease-in',

					opacity: 1,
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						//backgroundColor: 'transparent',
					},
				},
			},
			containedPrimary: {
				backgroundColor: '#00a1df',
				color: 'white',
				'&:hover': {
					backgroundColor: '#00a1df',
					transition: 'opacity .15s ease-in',
					opacity: 0.5,
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
			},
			containedSecondary: {
				backgroundColor: '#CF5944',
				color: 'white',
				'&:hover': {
					backgroundColor: '#CF5944',
					transition: 'opacity .15s ease-in',
					opacity: 0.5,
					// Reset on touch devices, it doesn't add specificity
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiFormHelperText: {
			root: {
				color: '#9B9B9B',
			},
		},
		MuiInput: {
			underline: {
				'&:after': {
					borderBottom: '2px solid #9B9B9B',
				},
			},
			root: {
				color: '#222',
			},

			disabled: {
				color: '#C1C1C1',
			},
		},
		MuiFormLabel: {
			root: {
				'&$focused': {
					color: '#00A1DF',
				},
			},
		},
		MuiInputLabel: {
			root: {},
			focused: {
				color: '#00A1DF',
			},
			disabled: {
				color: '#C1C1C1',
			},
		},
		MuiInputBase: {
			root: {
				marginTop: '25px',
			},
			input: {
				'&::-webkit-input-placeholder': placeholder,
				'&::-moz-placeholder': placeholder, // Firefox 19+
				'&:-ms-input-placeholder': placeholder, // IE 11
				'&::-ms-input-placeholder': placeholder,
			},
		},
		MuiRadio: {
			root: {
				'&$checked': {
					color: '#00a1df',
				},
			},

			colorPrimary: {
				'&$checked': {
					color: '#00a1df',
				},
			},
			colorSecondary: {
				'&$checked': {
					color: '#00a1df',
				},
			},
		},
		MuiCheckbox: {
			root: {
				'&$checked': {
					color: '#00a1df',
				},
			},

			colorPrimary: {
				'&$checked': {
					color: '#00a1df',
				},
			},
			colorSecondary: {
				'&$checked': {
					color: '#00a1df',
				},
			},
		},
		MuiTabs: {
			indicator: {
				backgroundColor: 'black',
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: 'inherit',
					opacity: '0.7',

					'&.Mui-selected': {
						color: 'inherit',
						opacity: '1',
					},
				},
			},
		},
		MuiFormControlLabel: {
			root: {
				minHeight: '25px',
			},
		},
		MuiCircularProgress: {
			colorPrimary: {
				color: '#00a1df',
			},
		},
		MuiTable: {
			root: {
				border: '1px solid rgb(204, 204, 204)',
				borderRadius: '2px',
				borderCollapse: 'inherit',
			},
		},
		MuiTableRow: {
			root: {
				'&:last-child > td': {
					/*MuiTableCell: {
						root: {
							borderBottom: 'none',
						},
					},*/
					borderBottom: 'none',
				},
			},
		},
		MuiTableCell: {
			root: {
				padding: '4px 15px',

				'&:last-child': {
					paddingRight: '24px',
				},

				'&:first-of-type': {
					paddingLeft: '24px',
				},
			},
		},
	},
})

export const allowedHtmlTagsForMessages = {
	allowedTags: ['p', 'strong', 'em', 'br', 'ul', 'ol', 'dl', 'dd', 'dt', 'li', 'span', 'svg', 'path', 'g'],
	allowedAttributes: {
		p: ['style', 'class'],
		span: ['style', 'class'],
		ul: ['style', 'class'],
		ol: ['style', 'class'],
		li: ['style', 'class'],
		svg: ['style', 'class', 'fill', 'stroke', 'width', 'height'],
	},
}

// Note that we convert all query string parameters to lowercase
export const getParams = () => {

	let query = window.location.search

	if (!query) return {}

	return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
		let [key, value] = param.split('=')
		params[key.toLowerCase()] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
		return params
	}, {})
}


export const isProductionEnvironment = () => {
	const regex = new RegExp(/.+\/\/partners(-admin)?.govx.com(.+)?/);
	return regex.test(getAPIDomain());
}

export const isLocalEnvironment = () => {
	return process.env.NODE_ENV !== 'production';
}