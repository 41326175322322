import React from 'react'
import { useForm, useController } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { TextAreaInput, MUITextAreaInput } from './ControlledTextAreaInput'
import { fillErrorMessages } from '../fillErrorMessages'

export const FormTextAreaInput = function ({
  name,
  control,
  rules,
  label,
  disabled,
  id,
  rows,
  validationMode = 'onSubmit', // onChange | onBlur | onSubmit | onTouched
  gxid,
}) {
  const { control: backupControl } = useForm({
    mode: validationMode,
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })
  return (
    <div>
      <TextAreaInput
        id={id}
        rows={rows}
        disabled={disabled}
        inputRef={field.ref}
        name={field.name}
        onChange={(v) => {
          field.onChange(v)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        invalid={fieldState.invalid}
        gxid={gxid}
      />
      <ErrorMessage
        errors={fillErrorMessages(errors, field.name)}
        name={field.name}
        render={({ message }) => (
          <p className="text-red text-sm mt-1">{message}</p>
        )}
      />
    </div>
  )
}

export const MUIFormTextAreaInput = function ({
  name,
  control,
  rules,
  label,
  shrinkLabel,
  disabled,
  id,
  rows,
  maxLength,
  validationMode = 'onSubmit', // onChange | onBlur | onSubmit | onTouched
  gxid,
}) {
  const { control: backupControl } = useForm({
    mode: validationMode,
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })
  return (
    <div>
      <MUITextAreaInput
        id={id}
        rows={rows}
        disabled={disabled}
        inputRef={field.ref}
        name={field.name}
        onChange={(v) => {
          field.onChange(v)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        maxLength={maxLength}
        invalid={fieldState.invalid}
        shrinkLabel={shrinkLabel}
        gxid={gxid}
      />
      <ErrorMessage
        errors={fillErrorMessages(errors, field.name, label, rules)}
        name={field.name}
        render={({ message }) => (
          <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>{message}</p>
        )}
      />
    </div>
  )
}
