/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  compose, withState, withHandlers, lifecycle,
} from 'recompose'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import OccupationsForm from '../../Forms/OccupationsForm'
import Footer from './Footer'
import {
  fetchPublishedOccupations,
  fetchPublicAndPublishedOccupations,
  setListingOccupations,
} from '../../../../../redux/actions'
import {
  selectPublishedOccupations,
  selectPublishedOccupationsHierarchy,
  selectPublicAndPublishedOccupations,
  selectPublicAndPublishedOccupationsHierarchy,
  hasPolicy,
} from '../../../../../redux/selectors'
import { ACCESS_PRIVATE_AFFILIATIONS } from '../../../../../auth/policies'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '-30px',
        bottom: '0px',
        left: '-30px',
        minHeight: '344px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function OccupationsSection({ listing }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)
  const [selectedOccupationMappings, setSelectedOccupationMappings] = useState(listing.occupationMappings)

  const publishedOccupationsHierarchy = useSelector(selectPublishedOccupationsHierarchy)
  const publishedOccupations = useSelector(selectPublishedOccupations)
  const publicAndPublishedOccupationsHierarchy = useSelector(selectPublicAndPublishedOccupationsHierarchy)
  const publicAndPublishedOccupations = useSelector(selectPublicAndPublishedOccupations)
  const canAccessPrivateAffiliations = useSelector(hasPolicy(ACCESS_PRIVATE_AFFILIATIONS))

  const getPublishedOccupations = () => {
    setProcessing(true)
    return dispatch(fetchPublishedOccupations()).then((x) => setProcessing(false))
  }
  const getPublicAndPublishedOccupations = () => {
    setProcessing(true)
    return dispatch(fetchPublicAndPublishedOccupations()).then((x) => setProcessing(false))
  }
  const setListingOccupationsFn = (listingId) => {
    setProcessing(true)
    return dispatch(setListingOccupations(listingId, selectedOccupationMappings)).then((x) => setProcessing(false))
  }

  const onSaveOccupations = () => {
    setListingOccupationsFn(listing.id, selectedOccupationMappings)
    // eslint-disable-next-line no-param-reassign
    listing.occupationMappings = selectedOccupationMappings
  }

  useEffect(() => {
    getPublishedOccupations()
    getPublicAndPublishedOccupations()
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Typography>
        De-selecting an entire group will also mark any subordinate affiliations ineligible for the listing. For
        example, if you de-select "Military", all branches will be marked ineligible.
      </Typography>
      <Typography>
        If you only want to mark specific affiliations ineligible, simply drill into the group and uncheck the ones
        that don''t apply. For example, drilling into "Military" and de-selecting "Army" means that all other
        branches will still be eligible for the listing.
      </Typography>
      <OccupationsForm
        publishedOccupationsHierarchy={publishedOccupationsHierarchy}
        publishedOccupations={publishedOccupations}
        publicAndPublishedOccupationsHierarchy={publicAndPublishedOccupationsHierarchy}
        publicAndPublishedOccupations={publicAndPublishedOccupations}
        canAccessPrivateAffiliations={canAccessPrivateAffiliations}
        selectedOccupationMappings={selectedOccupationMappings}
        setSelectedOccupationMappings={setSelectedOccupationMappings}
      />
      <Footer label="Save" processing={processing} onSubmit={onSaveOccupations} />
      {processing ? <Processing /> : null}
    </div>
  )
}

export default OccupationsSection
