/* eslint-disable react/prop-types */
import React from 'react'
import { Box } from '@mui/material'
import { Launch } from '@mui/icons-material'
import ModalContainer from '../../Modals/ModalContainer'
import DataTable from '../../@Common/DataTable'
import StyledLink from '../../StyledLink'

function LinkedOffersModal({
  onClickCancel,
  linkedOffers,
}) {
  return (
    <ModalContainer
      processing={false}
      title="Linked Offers"
      maxWidth="sm"
      fullWidth="true"
      hideModal={() => { }}
      actions={{ secondaryAction: { label: 'Cancel', onClick: onClickCancel } }}
    >
      <Box>
        <DataTable
          autoPageSize
          hideFooterRowCount
          disableColumnFilter
          pagination={linkedOffers.length > 5}
          rows={linkedOffers || []}
          getRowId={(row) => row.offerId}
          columns={[
            {
              field: 'name',
              headerName: 'Offer Name',
              type: 'string',
              width: 250,
              filterable: false,
            },
            {
              field: 'listingId',
              headerName: '',
              type: 'string',
              width: 250,
              align: 'left',
              renderCell: (row) => (
                <Box alignItems="center" display="flex" flexWrap="wrap">
                  <Box display="inline"><Launch style={{ color: '#0E5777' }} /></Box>
                  <Box display="inline" style={{ marginLeft: '5px' }}>
                    <StyledLink
                      onClick={() => onClickCancel()}
                      to={`/partners/listings/${row.row.listingId}`}
                      contents="View Linked Offer"
                    />
                  </Box>

                </Box>
              ),
            },
          ]}
        />
      </Box>
    </ModalContainer>
  )
}

export default LinkedOffersModal
