import { FETCH_USER_SUCCESS } from '../../redux/modules/users/users'
import { FETCH_LISTING_SUCCESS } from '../../redux/modules/verification/listings'
import { history, LOCATION_CHANGE } from '../../setup/history'

const pageTitleMiddleware = store => next => action => {
	let result = next(action)

	switch(action.type) {
		case LOCATION_CHANGE:
			let pathname = window.location.pathname
			if (pathname === '/') {
				document.title = 'GovX Verification Admin'
			} else if (pathname === '/users/review') {
				document.title = 'GovX Verification | Users'
			} else if (pathname === '/verification/review') {
				document.title = 'GovX Verification | Verification Requests'
			} else if (pathname === '/whitelist/review') {
				document.title = 'GovX Verification | Domain Whitelist'
			} else if (pathname === '/verification/listings') {
				document.title = 'GovX Verification | Listings'
			} else if (pathname === '/occupations/taxonomy') {
				document.title = 'GovX Verification | Affiliations'
			}
			break

		case FETCH_USER_SUCCESS:
			let user = action.data.entities.users[action.data.result]
			document.title = 'GovX Verification | ' + user.firstName + ' ' + user.lastName
			break

		case FETCH_LISTING_SUCCESS:
			let listing = action.data.entities.listings[action.data.result]
			document.title = 'GovX Verification | ' + listing.name
			break

		default:
			break
	}
	return result
}

export default pageTitleMiddleware