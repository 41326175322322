import React from 'react'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { useOutletContext } from 'react-router-dom'
import SectionHeadline from '../../../../Header/SectionHeadline'
import MarketingAssetsForm from '../../Forms/MarketingAssetsForm'
import Footer from './Footer'

export function MarketingAssetsSection() {
  const dispatch = useDispatch()
  const { affiliate, canUpdate } = useOutletContext()

  const handleSubmit = () => dispatch(submit('marketing-assets'))

  return (
    <div style={{ position: 'relative', minHeight: '800px' }}>
      <SectionHeadline>Marketing Assets</SectionHeadline>
      <MarketingAssetsForm affiliate={affiliate} canUpdate={canUpdate} />
      {canUpdate && <Footer label="Save" onSubmit={handleSubmit} />}
    </div>
  )
}

MarketingAssetsSection.propTypes = {
}

export default MarketingAssetsSection
