/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import { Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import Footer from './Footer'
import { fetchListingDataSharingSettings, updateListingDataSharingSettings } from '../../../../../redux/actions'
import { selectListingSettings } from '../../../../../redux/selectors'
import DialogSubsectionHeadline from '../../../../Header/DialogSubsectionHeadline'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '-30px',
        bottom: '0px',
        left: '-30px',
        minHeight: '344px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export function DataSharingSection({ listing }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)
  const [hasImplicitScope, setHasImplicitScope] = useState('false')
  const [shareScopeWithGovX, setShareScopeWithGovX] = useState('None')

  // replace with formhook
  const dataSharingSettings = useSelector(selectListingSettings(listing.id, 'dataSharing'))

  const getListingDataSharingSettings = () => {
    dispatch(fetchListingDataSharingSettings(listing.id))
      .then((response) => {
        setHasImplicitScope(response.hasImplicitScope.toString())
        setShareScopeWithGovX(response.shareScopeWithGovX.toString())
        return response
      })
      .then(() => setProcessing(false))
  }

  useEffect(() => {
    getListingDataSharingSettings()
  }, [])

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      hasImplicitScope: dataSharingSettings?.hasImplicitScope,
      shareScopeWithGovX: dataSharingSettings?.shareScopeWithGovX,
    },
  })

  useEffect(() => {
    reset({
      hasImplicitScope: dataSharingSettings?.hasImplicitScope,
      shareScopeWithGovX: dataSharingSettings?.shareScopeWithGovX,
    })
  }, [dataSharingSettings])

  const onSubmit = (values) => {
    const settings = {}
    settings.hasImplicitScope = values.hasImplicitScope
    settings.shareScopeWithGovX = values.shareScopeWithGovX
    setHasImplicitScope(values.hasImplicitScope)
    setShareScopeWithGovX(values.shareScopeWithGovX)
    dispatch(updateListingDataSharingSettings(listing.id, settings))
  }

  return (
    <div style={{ position: 'relative' }}>
      {processing || !dataSharingSettings ? (
        <Processing />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogSubsectionHeadline>Which grant type should GovX enforce?</DialogSubsectionHeadline>

          <Controller
            name="hasImplicitScope"
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-label="Has implicit scope"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={(
                    <div>
                      <p style={{ marginTop: '12px' }}>
                        Implicit grant
                        <br />
                        <small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>
                          Customer consent to share data is implied as part of registration.
                        </small>
                      </p>
                    </div>
                  )}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={(
                    <p style={{ marginTop: '12px' }}>
                      Explicit grant
                      <br />
                      <small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>
                        Customer will have to approve sharing their data at the end of registration.
                      </small>
                    </p>
                  )}
                />
              </RadioGroup>
            )}
          />

          <Box sx={{ mt: '35px' }}>
            <DialogSubsectionHeadline>
              What rights does GovX receive for user data generated by this listing?
            </DialogSubsectionHeadline>
          </Box>

          <Controller
            name="shareScopeWithGovX"
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-label="Shares scope with GovX"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              >
                <FormControlLabel
                  value="Implicit"
                  control={<Radio />}
                  label={(
                    <p style={{ marginTop: '12px' }}>
                      Implicit scope grant
                      <br />
                      <small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>
                        GovX receives implicit grant to all user data. All registrants will become GovX
                        members.
                      </small>
                    </p>
     )}
                />
                <FormControlLabel
                  value="Explicit"
                  control={<Radio />}
                  label={(
                    <p style={{ marginTop: '12px' }}>
                      Explicit scope grant
                      <br />
                      <small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>
                        User will be presented with an opt-in to create a GovX membership.
                      </small>
                    </p>
     )}
                />
                <FormControlLabel
                  value="None"
                  control={<Radio />}
                  label={(
                    <p style={{ marginTop: '12px' }}>
                      No scope grant
                      <br />
                      <small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>
                        GovX receives no data rights. Registrants will not become GovX members.
                      </small>
                    </p>
     )}
                />
              </RadioGroup>
            )}
          />
          <Footer label="Save" processing={processing} onSubmit={handleSubmit(onSubmit)} />
        </form>
      )}
    </div>
  )
}

export default DataSharingSection
