import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ModalContainer from '../ModalContainer'
import { showSuccesNotification, hideModal } from '../../../redux/actions'
import TextArea from '../../Inputs/TextArea'

export function OAuthLinkDisplayModal({ verificationLink }) {
  const dispatch = useDispatch()

  const onClose = () => dispatch(hideModal())
  const copyVerificationLinkToClipboard = (link) => {
    copy(link)
    dispatch(showSuccesNotification('Link copied to clipboard'))
  }

  return (
    <ModalContainer
      title={`Verification link: ${verificationLink.name}`}
      modal
      maxWidth="md"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div style={{ marginTop: '20px' }}>
        <TextArea
          name="copy-oauth-link"
          fullWidth
          readOnly
          disabled
          value={verificationLink.link}
        />
        <Grid container direction="row" style={{ cursor: 'pointer' }} onClick={() => copyVerificationLinkToClipboard(verificationLink.link)}>
          <Grid item>
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          </Grid>
          <Grid item style={{ alignSelf: 'center' }}>
            <Typography style={{ position: 'relative', color: '#222', fontSize: '12px' }}>
              Copy to clipboard
            </Typography>
          </Grid>
        </Grid>
        <div style={{ lineHeight: '30px', verticalAlign: 'center' }} />
        <Typography>{verificationLink.description}</Typography>
      </div>
    </ModalContainer>
  )
}

OAuthLinkDisplayModal.propTypes = {
  verificationLink: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default OAuthLinkDisplayModal
