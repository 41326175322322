import { getJson, postJson } from './baseApi'

export const fetchEntertainer = id => {
	return getJson(`/api/tickets/entertainers/${id}`)
}

export const fetchEntertainerEventTickets = (listingId, entertainerId) => {
	return getJson(`/api/tickets/entertainers/${entertainerId}/event-tickets?listingId=${listingId}`)
}

export const fetchVenue = id => {
	return getJson(`/api/tickets/venues/${id}`)
}

export const fetchVenueTickets = (listingId, venueId) => {
	return getJson(`/api/tickets/venues/${venueId}/venue-tickets?listingId=${listingId}`)
}