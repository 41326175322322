import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'
import { formatDateTime } from '../../../../../../formatting'
import { getUserPath } from '../../../../../../setup/routeConstants'
import { createUsersSearch, searchUsersByClaim, showModal } from '../../../../../../redux/actions'
import { createFilteredUsersSelector, selectUsersSearch } from '../../../../../../redux/selectors'
import SectionHeadline from '../../../../../Header/SectionHeadline'

const CLAIM_TYPE = 'partners.admin.listing.access'

export const DEFAULT_SEARCH = (listingId) => ({
  id: 'users-listing',
  filter: {
    claim: { type: CLAIM_TYPE, listingId },
  },
})

const SEARCH_ID = 'users-listing'

const useStyles = createUseStyles({
  userLink: {
    display: 'flex',
    alignItems: 'center',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',

    color: '#222',
    textDecoration: 'none',
  },
})

function UserLink({ to, classes, contents }) {
  return <Link name="userLink" to={to} className={classes.userLink}>{contents}</Link>
}
UserLink.propTypes = {
  to: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    userLink: PropTypes.string.isRequired,
  }).isRequired,
  contents: PropTypes.string.isRequired,
}

export function UserPermissionsTab({ listing }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const search = useSelector(selectUsersSearch(SEARCH_ID))
  const users = useSelector(createFilteredUsersSelector(SEARCH_ID))

  const createSearch = () => dispatch(createUsersSearch(DEFAULT_SEARCH(listing.id)))
  const getUsersByClaim = () => dispatch(
    searchUsersByClaim({ type: CLAIM_TYPE, value: listing.id.toString() }, SEARCH_ID),
  )
  const onAddUser = () => dispatch(showModal('ADD_LISTING_USER', { claim: { type: CLAIM_TYPE, value: listing.id.toString() } }))
  const onRemoveUser = (userId) => dispatch(showModal('REMOVE_LISTING_USER', { claimType: CLAIM_TYPE, listing, userId }))

  useEffect(() => {
    createSearch()
      .then(() => getUsersByClaim())
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      <Box display="flex" sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <SectionHeadline sx={{ margin: '0px', fontWeight: 700 }}>Permissions</SectionHeadline>
        <div>
          <Button label="Add User" color="primary" fullWidth={false} onClick={onAddUser} />
        </div>
      </Box>
      <Table>
        <TableHead style={{ backgroundColor: '#f1f1f1' }}>
          <TableRow>
            <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>Name</TableCell>
            <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>Email</TableCell>
            <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>Last Updated</TableCell>
            <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {
        search && search.total > 0
          ? (users.map((u) => (
            <TableRow key={u.id}>
              <TableCell style={{ position: 'relative' }}><UserLink to={getUserPath(u.id)} classes={classes} contents={`${u.firstName} ${u.lastName}`} /></TableCell>
              <TableCell style={{ position: 'relative' }}><UserLink to={getUserPath(u.id)} classes={classes} contents={u.email} /></TableCell>
              <TableCell style={{ position: 'relative' }}><UserLink to={getUserPath(u.id)} classes={classes} contents={formatDateTime(u.updatedOnUtc)} /></TableCell>
              <TableCell style={{ position: 'relative' }}><StyledLink contents="Remove" clickHandlerOnly onClick={() => onRemoveUser(u.id)} /></TableCell>
            </TableRow>
          )))
          : (
            <TableRow key={1}>
              <TableCell colSpan={4} style={{ position: 'relative' }}>No users assigned to this listing</TableCell>
            </TableRow>
          )
      }
        </TableBody>
      </Table>
    </div>
  )
}

UserPermissionsTab.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default UserPermissionsTab
