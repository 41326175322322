import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'

function MUIRadioGroupInput({
  onChange = () =>{},
  onBlur = () => {},
  items = [],
  label,
  value,
  name,
  disabled = false,
  id,
}) {
  return (
    <RadioGroup id={id} name={name} value={value} onBlur={() => onBlur} >
      {
        label && 
        (<Box>
          <DialogSubsectionHeadline>{label}</DialogSubsectionHeadline>
        </Box>)
      }
      {items.map((item) => {
        const label = item.helperText ? (
          <Box>
            {item.label}<Typography style={{ margin: '0px' }}><small style={{ display: 'block', lineHeight: '14px', color: '#777' }}>{item.helperText}</small></Typography>
          </Box>
          ) : item.label
        return (
          <FormControlLabel
            key={item.key || item.value}
            value={item.value}
            control={<Radio />}
            label={label}
            disabled={item.disabled || disabled}
            checked={item.checked}
            onChange={onChange}
          />
      )})}
    </RadioGroup>
  )
}

MUIRadioGroupInput.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
  })),
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MUIRadioGroupInput
