/* eslint-disable react/no-array-index-key */
/* eslint-disable no-array-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import List from '@mui/material/List'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import OccupationListItem from './OccupationListItem'

export const getOccupationDescendants = (parentOccupation) => {
  if (!parentOccupation || !parentOccupation.children || parentOccupation.children.length < 1) return []

  let childrenIds = parentOccupation.children.map((x) => x.id)

  for (let i = 0; i < parentOccupation.children.length; i++) {
    const results = getOccupationDescendants(parentOccupation.children[i])
    if (results && results.length > 0) {
      childrenIds = childrenIds.concat(results)
    }
  }
  return childrenIds
}

const getAncestorIds = (occupation, publishedOccupations) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) return []

  let ancestorIds = [occupation.parentId]
  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  ancestorIds = ancestorIds.concat(getAncestorIds(parent, publishedOccupations))

  return ancestorIds
}

const getAncestorsToUncheck = (occupation, publishedOccupations, selectedOccupations, occupationsToUpdate) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) return occupationsToUpdate

  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  const siblingIds = parent.children.filter((x) => x !== occupation.id)
  const selectedSiblings = selectedOccupations.occupations.filter((x) => siblingIds.includes(x.id) && x.selected)
  if (selectedSiblings && selectedSiblings.length > 0) return occupationsToUpdate

  occupationsToUpdate = occupationsToUpdate.concat(parent.id)
  return getAncestorsToUncheck(parent, publishedOccupations, selectedOccupations, occupationsToUpdate)
}

const getAncestorsToUntoggle = (occupation, publishedOccupations, selectedOccupations, occupationsToUpdate) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) return occupationsToUpdate

  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  const siblingIds = parent.children.filter((x) => x !== occupation.id)
  const selectedSiblings = selectedOccupations.occupations.filter(
    (x) => siblingIds.includes(x.id) && x.shownInRegistration,
  )
  if (selectedSiblings && selectedSiblings.length > 0) return occupationsToUpdate

  occupationsToUpdate = occupationsToUpdate.concat(parent.id)
  return getAncestorsToUntoggle(parent, publishedOccupations, selectedOccupations, occupationsToUpdate)
}

export const allowedOccupations = (publishedOccupations, selectedOccupationMappings) => {
  const allowedOccupations = { occupations: [] }

  if (selectedOccupationMappings) {
    publishedOccupations.forEach((o) => {
      const foundOccupationMapping = selectedOccupationMappings.find((x) => x.occupationId === o.id)
      if (foundOccupationMapping) {
        allowedOccupations.occupations.push({
          id: o.id,
          selected: true,
          shownInRegistration: foundOccupationMapping.shownInRegistration,
        })
      } else {
        allowedOccupations.occupations.push({ id: o.id, selected: false, shownInRegistration: false })
      }
    })
  } else {
    // eslint-disable-next-line no-undef
    allowedOccupations.occupations.push({ id: o.id, selected: false, shownInRegistration: false })
  }

  return allowedOccupations
}

export const isOccupationChecked = (occupationId, selectedOccupations) => {
  const allowedOccupation = selectedOccupations.occupations.find((x) => x.id === occupationId)
  if (allowedOccupation) return allowedOccupation.selected
  return false
}

export const isOccupationToggled = (occupationId, selectedOccupations) => {
  const allowedOccupation = selectedOccupations.occupations.find((x) => x.id === occupationId)
  if (allowedOccupation && allowedOccupation.shownInRegistration) return allowedOccupation.shownInRegistration
  return false
}

export const mapChecked = (allowedOccupation, checkedId, checked) => ({
  ...allowedOccupation,
  selected: allowedOccupation.id === checkedId ? checked : allowedOccupation.selected,
})

export const mapCheckedBulk = (allowedOccupation, checkedIds, checked) => ({
  ...allowedOccupation,
  selected: checkedIds.includes(allowedOccupation.id) ? checked : allowedOccupation.selected,
})

export const mapToggledBulk = (allowedOccupation, toggledIds, toggled) => ({
  ...allowedOccupation,
  shownInRegistration: toggledIds.includes(allowedOccupation.id) ? toggled : allowedOccupation.shownInRegistration,
})

export const mapCheckedAndToggledBulk = (allowedOccupation, checkedIds, checked, toggledIds, toggled) => ({
  ...allowedOccupation,
  selected: checkedIds.includes(allowedOccupation.id) ? checked : allowedOccupation.selected,
  shownInRegistration: toggledIds.includes(allowedOccupation.id) ? toggled : allowedOccupation.shownInRegistration,
})

export const occupationCheckbox = (occupation, selectedOccupations, isDisabled, onCheckOccupation) => (
  <Checkbox
    key={occupation.id}
    name={occupation.name}
    checked={isOccupationChecked(occupation.id, selectedOccupations)}
    onCheck={(e, checked) => onCheckOccupation(e, checked, occupation)}
    disabled={isDisabled}
  />
)

export const occupationShowHideToggle = (occupation, selectedOccupations, onToggleOccupation) => (
  <Switch
    label="Show in registration"
    labelPosition="left"
    labelStyle={{
      width: 'auto',
      fontSize: '14px',
      fontWeight: '700',
      color: 'rgb(171, 171, 171)',
      marginRight: '8px',
    }}
    style={{
      display: 'inline-block', float: 'right', width: 'auto', marginTop: '-3px', marginRight: '15px',
    }}
    toggled={isOccupationToggled(occupation.id, selectedOccupations)}
    onToggle={(e, toggled) => onToggleOccupation(e, toggled, occupation)}
    disabled={!isOccupationChecked(occupation.id, selectedOccupations)}
  />
)

function TaxonomyListItem({
  index,
  occupation,
  depth,
  classes,
  selectedOccupations,
  privateAndPublishedOccupations,
  publicAndPublishedOccupationIds,
  canAccessPrivateAffiliations,
  onCheckOccupation,
  onToggleOccupation,
  onSelectedStateChange,
  setCampaignOccupations,
}) {
  const level = depth
  const hasChildren = occupation.children && occupation.children.length > 0
  const availableChildOccupations = canAccessPrivateAffiliations || !hasChildren
    ? occupation.children
    : occupation.children.filter((x) => publicAndPublishedOccupationIds.includes(x.id))

  let isDisabled = false
  if (!canAccessPrivateAffiliations) {
    if (occupation.private) {
      isDisabled = true
    } else {
      const descendentIds = getOccupationDescendants(occupation)
      const privateDescendents = privateAndPublishedOccupations.filter((x) => descendentIds.includes(x.id))
      isDisabled = privateDescendents && privateDescendents.length > 0
    }
  }

  return (
    <div>
      {/* {index === 0 && depth !== 0 ? (
    <ListSubheader
     style={{ margin: '15px 0px 5px', paddingLeft: level * 52, color: '#222', lineHeight: 'auto' }}
    >
     {formatOccupationType(occupation.type)}
    </ListSubheader>
   ) : null}
   <ListItem
    key={occupation.id}
    primaryText={
     <div>
      <div style={{ display: 'inline-block' }}>{occupation.name}</div>
      {occupationShowHideToggle(occupation, selectedOccupations, onToggleOccupation)}
     </div>
    }
    leftCheckbox={occupationCheckbox(occupation, selectedOccupations, isDisabled, onCheckOccupation)}
    className={classes.listItem}
    style={{
     marginTop: '-1px',
     marginLeft: level * 52,
     backgroundColor: '#fbfbfb',
     border: '1px solid #cfcfcf',
     cursor: 'default',
    }}
    nestedListStyle={{ paddingBottom: '48px' }}
    nestedItems={
     hasChildren
      ? availableChildOccupations
        .sort((a, b) => {
         return a.displayOrder - b.displayOrder
        })
        .map((o, i) => (
         <TaxonomyListItem
          key={o.id}
          index={i}
          occupation={o}
          depth={level + 1}
          classes={classes}
          selectedOccupations={selectedOccupations}
          privateAndPublishedOccupations={privateAndPublishedOccupations}
          publicAndPublishedOccupationIds={publicAndPublishedOccupationIds}
          canAccessPrivateAffiliations={canAccessPrivateAffiliations}
          onCheckOccupation={onCheckOccupation}
          onToggleOccupation={onToggleOccupation}
         />
        ))
      : []
    }
   /> */}

      <OccupationListItem occupation={occupation} level={0} onSelectedStateChange={onSelectedStateChange} />
    </div>
  )
}

let count = 0

const enrichOccupation = (occupation, mappings) => {
  const s = mappings.find((element) => element.id === occupation.id)
  if (s) {
    occupation.shownInRegistration = s.shownInRegistration
    occupation.selected = s.selected
    occupation.updated = new Date().getTime() + occupation.id
  }
  if (occupation.children && occupation.children.length > 0) {
    occupation.children.forEach((o) => {
      enrichOccupation(o, mappings)
      const s = mappings.find((element) => element.occupationId === o.id)
      if (s) {
        o.shownInRegistration = s.shownInRegistration
        o.selected = s.selected
        o.updated = new Date().getTime() + o.id
      }

      count += 1
    })
  }
}

const f = (id, occupation, level) => {
  if (occupation.id === id) {
    return occupation
  }
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      const g = f(id, o, level++)
      if (g && g.id === id) {
        return g
      }
    }
  }
}

const findOccupation = (id, occupations, level) => {
  for (const o of occupations) {
    const r = f(id, o, level++)
    if (r && r.id === id) {
      return r
    }
  }
  return null
}

const forceChildrenShowInRegistrationOn = (occupation) => {
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      o.shownInRegistration = true
      o.updated = new Date().getTime() + o.id
      forceChildrenShowInRegistrationOn(o)
    }
  }
}

const forceChildrenShowInRegistrationOff = (occupation) => {
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      o.shownInRegistration = false
      o.updated = new Date().getTime() + o.id
      forceChildrenShowInRegistrationOff(o)
    }
  }
}

const forceChildrenOn = (occupation) => {
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      o.selected = true
      o.shownInRegistration = true
      o.updated = new Date().getTime() + o.id
      forceChildrenOn(o)
    }
  }
}

const forceChildrenOff = (occupation) => {
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      o.selected = false
      o.shownInRegistration = false
      o.updated = new Date().getTime() + o.id
      forceChildrenOff(o)
    }
  }
}

const flattenChildren = (result, occupation) => {
  if (occupation.children && occupation.children.length > 0) {
    for (const o of occupation.children) {
      flattenChildren(result, o)
      if (o.selected) {
        result.push({ occupationId: o.id, shownInRegistration: o.shownInRegistration })
      }
    }
  }
}
const r = 0
function OccupationsFormPartial({
  publishedOccupationsHierarchy,
  publishedOccupations,
  publicAndPublishedOccupations,
  canAccessPrivateAffiliations,
  onCheckOccupation,
  onToggleOccupation,
  selectedOccupationMappings,
  setCampaignOccupations,
}) {
  const [selectedOccupations, setSelectedOccupations] = useState(() => allowedOccupations(
    publishedOccupations,
    selectedOccupationMappings || [],
  ))
  const [testOccupations, setTestOccupations] = useState(() => {
    const occupations = publishedOccupationsHierarchy.slice()
    const mappings = selectedOccupations.occupations.slice()
    occupations.forEach((o) => {
      enrichOccupation(o, mappings)
    })
    const result = new Array()
    for (const occ of occupations) {
      if (occ.selected) {
        result.push({ occupationId: occ.id, shownInRegistration: occ.shownInRegistration })
        flattenChildren(result, occ)
      }
    }
    setCampaignOccupations(result)
    return occupations
  })

  const onShownInRegistrationChange = (id, isChecked) => {
    const occupations = testOccupations
    const occupation = findOccupation(id, occupations, 0)
    occupation.shownInRegistration = isChecked
    occupation.updated = new Date().getTime() + occupation.id
    if (isChecked) {
      forceChildrenShowInRegistrationOn(occupation)
    } else {
      forceChildrenShowInRegistrationOff(occupation)
    }
    if (occupation.parentId !== 0) {
      let p = findOccupation(occupation.parentId, occupations, 0)

      while (p && p.parentId != null) {
        if (p) {
          if (p.children != null && p.children.length > 0) {
            let countOn = 0
            for (const o of p.children) {
              if (o.shownInRegistration === true) countOn++
            }
            if (countOn === 0) p.shownInRegistration = false
            if (countOn > 0) p.shownInRegistration = true
          }
        }
        p = findOccupation(p.parentId, occupations, 0)
      }
    }
    setTestOccupations(occupations)
    const result = new Array()
    for (const occ of occupations) {
      if (occ.selected) {
        result.push({ occupationId: occ.id, shownInRegistration: occ.shownInRegistration })
        flattenChildren(result, occ)
      }
    }
    setCampaignOccupations(result)
  }
  const onSelectedStateChange = (id, isChecked) => {
    const occupations = testOccupations
    const occupation = findOccupation(id, occupations, 0)
    occupation.selected = isChecked
    occupation.updated = new Date().getTime() + occupation.id
    if (isChecked) {
      forceChildrenOn(occupation)
      occupation.shownInRegistration = true
    } else {
      occupation.shownInRegistration = false
      forceChildrenOff(occupation)
    }
    if (occupation.parentId !== 0) {
      let p = findOccupation(occupation.parentId, occupations, 0)

      while (p && p.parentId != null) {
        if (p) {
          if (p.children != null && p.children.length > 0) {
            let countOn = 0
            for (const o of p.children) {
              if (o.selected === true) countOn++
            }
            if (countOn === 0) p.selected = false
            if (countOn > 0) p.selected = true
          }
        }
        p = findOccupation(p.parentId, occupations, 0)
      }
    }
    setTestOccupations(occupations)
    const result = new Array()
    for (const occ of occupations) {
      if (occ.selected) {
        result.push({ occupationId: occ.id, shownInRegistration: occ.shownInRegistration })
        flattenChildren(result, occ)
      }
    }
    setCampaignOccupations(result)
  }

  const privateAndPublishedOccupations = publishedOccupations.filter((x) => x.private)
  const publicAndPublishedOccupationIds = publicAndPublishedOccupations.map((y) => y.id)

  const availableOccupationsHierarchy = canAccessPrivateAffiliations
    ? publishedOccupationsHierarchy
    : publishedOccupationsHierarchy.filter((x) => publicAndPublishedOccupationIds.includes(x.id))

  return (
    <div style={{ width: '100%', minWidth: 'min-content', minHeight: '70vh' }}>
      <List>
        {testOccupations
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((o, i) => (
            <OccupationListItem
              key={i}
              index={i}
              occupation={o}
              level={0}
              onSelectedStateChange={onSelectedStateChange}
              onShownInRegistrationChange={onShownInRegistrationChange}
              canAccessPrivate={canAccessPrivateAffiliations}
            />
          ))}
      </List>
    </div>
  )
}

export default OccupationsFormPartial
