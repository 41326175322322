import React from 'react'
import { useController, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { fillErrorMessages } from '../fillErrorMessages'
import { MUITextInput, TextInput } from './ControlledTextInput'

export const FormTextInput = function({
  name,
  control,
  rules,
  label,
  disabled,
  id,
  type,
  prefix,
  suffix,
  mask,
  validationMode = 'onSubmit', // onChange | onBlur | onSubmit | onTouched
}) {
  const { control: backupControl } = useForm({
    mode: validationMode,
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })
  return (
    <div>
      <TextInput
        id={id}
        disabled={disabled}
        type={type}
        inputRef={field.ref}
        name={field.name}
        onChange={(v) => {
          field.onChange(v)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        invalid={fieldState.invalid}
        prefix={prefix}
        suffix={suffix}
        mask={mask}
      />
      <ErrorMessage
        errors={fillErrorMessages(errors, field.name)}
        name={field.name}
        render={({ message }) => (
          <p className="text-red text-sm mt-1">{message}</p>
        )}
      />
    </div>
  )
}

export const MUIFormTextInput = function({
  name,
  control,
  rules,
  label,
  disabled,
  id,
  type,
  size = 'medium',
  prefix,
  suffix,
  mask,
  normalizeMask,
  shrinkLabel = false,
  autoFocus = false,
  validationMode = 'onSubmit', // onChange | onBlur | onSubmit | onTouched
  gxid,
  hideErrorMessage = false,
  inputLabelProps,
  onChange = () => { },
  onInit = () => { },
  styles = {}
}) {
  const { control: backupControl } = useForm({
    mode: validationMode,
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })

  React.useEffect(() => {
    onInit(field.value)
  }, [])

  return (
    <div>
      <MUITextInput
        id={id}
        disabled={disabled}
        type={type}
        inputRef={field.ref}
        name={field.name}
        size={size}
        onChange={(v) => {
          field.onChange(v)
          onChange(v)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        invalid={fieldState.invalid}
        prefix={prefix}
        suffix={suffix}
        mask={mask}
        normalizeMask={normalizeMask}
        shrinkLabel={shrinkLabel}
        autoFocus={autoFocus}
        gxid={gxid}
        inputLabelProps={inputLabelProps}
        styles={styles}
      />
      {!hideErrorMessage &&
        <ErrorMessage
          errors={fillErrorMessages(errors, field.name, label, rules)}
          name={field.name}
          render={({ message }) => (
            <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>{message}</p>
          )}
        />
      }
    </div>
  )
}
