import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { ACCESS_VA_STATUS_PAGE } from '../../auth/policies'

const VaStatsReportPage = React.lazy(() => import('../../components/Reports/Verification/VaStatsReport'))

export default {
	path: RouteConstants.reports.vaStats.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_VA_STATUS_PAGE]}
			component={<VaStatsReportPage />} 
		/>
	),
}