import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import VerificationIcon from '@mui/icons-material/HowToReg'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupIcon from '@mui/icons-material/Group'
// import ListingIcon from '@mui/icons-material/VerifiedUser'
import PartnersIcon from '@mui/icons-material/Business'
import CodePoolIcon from '@mui/icons-material/Sell'
import { DataObject } from '@mui/icons-material'
import { RouteConstants } from '../../setup/routeConstants'
import NavListItem from './NavListItem'
import StorefrontIcon from '../Icons/Storefront'
import { hasPolicy } from '../../redux/selectors'
import { fetchPolicies } from '../../redux/actions'
import {
  ACCESS_USER_LIST_PAGE,
  ACCESS_USER_DETAIL_PAGE,
  ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
  ACCESS_AFFILIATIONS_PAGE,
  ACCESS_DMDC_STATUS_PAGE,
  ACCESS_VA_STATUS_PAGE,
  ACCESS_DOMAIN_WHITELIST_PAGE,
  ACCESS_DOCUMENT_RULE_SETS_PAGE,
  ACCESS_LISTING_LIST,
  DEVELOPER_ACCESS,
  ACCESS_CODE_POOL_PAGE,
  ACCESS_PARTNER_LIST_PAGE,
} from '../../auth/policies'

const getSetting = (text, link, { pathname }) => ({ active: pathname === link, text, link })

const getGlobalSettings = (
  location,
  canAccessAffiliationsPage,
  canAccessDmdcStatusPage,
  canAccessVaStatusPage,
  canAccessDomainWhitelistPage,
  canAccessDocumentRuleSetsPage,
) => {
  const settings = []
  if (canAccessAffiliationsPage) {
    settings.push(getSetting('Affiliations', RouteConstants.occupations.taxonomy.path, location))
  }
  if (canAccessDmdcStatusPage) {
    settings.push(getSetting('DMDC status', RouteConstants.reports.dmdcStats.path, location))
  }
  if (canAccessVaStatusPage) {
    settings.push(getSetting('VA status', RouteConstants.reports.vaStats.path, location))
  }
  if (canAccessDomainWhitelistPage) {
    settings.push(getSetting('Domain whitelist', RouteConstants.whitelist.review.path, location))
  }
  if (canAccessDocumentRuleSetsPage) {
    settings.push(getSetting('Document rules', RouteConstants.documentRuleSets.root.path, location))
  }
  return settings
}

function NavList({
  drawerExpanded,
  expandDrawer,
}) {
  const location = useLocation()
  const dispatch = useDispatch()

  const getStyle = (path) => ({
    minWidth: '100%',
    minHeight: '100%',
    // position: 'relative',
    fill: location.pathname === path ? '#fff' : '#ccc',
  })

  useEffect(() => {
    dispatch(
      fetchPolicies([
        ACCESS_USER_LIST_PAGE,
        ACCESS_USER_DETAIL_PAGE,
        ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
        ACCESS_AFFILIATIONS_PAGE,
        ACCESS_DMDC_STATUS_PAGE,
        ACCESS_VA_STATUS_PAGE,
        ACCESS_DOMAIN_WHITELIST_PAGE,
        ACCESS_DOCUMENT_RULE_SETS_PAGE,
        ACCESS_LISTING_LIST,
        DEVELOPER_ACCESS,
        ACCESS_CODE_POOL_PAGE,
        ACCESS_PARTNER_LIST_PAGE,
      ]),
    )
  }, [])

  const canAccessUserListPage = useSelector(hasPolicy(ACCESS_USER_LIST_PAGE))
  //   const canAccessUserDetailPage = useSelector(hasPolicy(ACCESS_USER_DETAIL_PAGE))
  const canAccessVerificationRequestListPage = useSelector(
    hasPolicy(ACCESS_VERIFICATION_REQUEST_LIST_PAGE),
  )
  const canAccessAffiliationsPage = useSelector(hasPolicy(ACCESS_AFFILIATIONS_PAGE))
  const canAccessDmdcStatusPage = useSelector(hasPolicy(ACCESS_DMDC_STATUS_PAGE))
  const canAccessVaStatusPage = useSelector(hasPolicy(ACCESS_VA_STATUS_PAGE))
  const canAccessDomainWhitelistPage = useSelector(hasPolicy(ACCESS_DOMAIN_WHITELIST_PAGE))
  const canAccessDocumentRuleSetsPage = useSelector(hasPolicy(ACCESS_DOCUMENT_RULE_SETS_PAGE))
  const canAccessListingListPage = useSelector(hasPolicy(ACCESS_LISTING_LIST))
  const canAccessCodePoolsPage = useSelector(hasPolicy(ACCESS_CODE_POOL_PAGE))
  const canAccessDeveloperPage = useSelector(hasPolicy(DEVELOPER_ACCESS))
  const canAccessPartnerPage = useSelector(hasPolicy(ACCESS_PARTNER_LIST_PAGE))

  return (
    <>
      {canAccessUserListPage && (
        <NavListItem
          text="Users"
          icon={<GroupIcon style={getStyle(RouteConstants.users.index.path)} />}
          link={RouteConstants.users.index.path}
          drawerExpanded={drawerExpanded}
        />
      )}

      {canAccessPartnerPage && (
        <NavListItem
          text="Partners"
          icon={<PartnersIcon style={getStyle(RouteConstants.partners.index.path)} />}
          link={RouteConstants.partners.index.path}
          drawerExpanded={drawerExpanded}
        />
      )}

      {canAccessListingListPage && (
        <NavListItem
          text="Listings"
          icon={<StorefrontIcon style={getStyle(RouteConstants.partners.listings.index.path)} />}
          link={RouteConstants.partners.listings.index.path}
          drawerExpanded={drawerExpanded}
        />
      )}

      {canAccessCodePoolsPage && (
        <NavListItem
          text="Code Pools"
          icon={<CodePoolIcon style={getStyle(RouteConstants.codePools.index.path)} />}
          link={RouteConstants.codePools.index.path}
          drawerExpanded={drawerExpanded}
        />
      )}
      {canAccessVerificationRequestListPage && (
        <NavListItem
          text="Verification requests"
          icon={<VerificationIcon style={getStyle(RouteConstants.verification.review.path)} />}
          link={RouteConstants.verification.review.path}
          drawerExpanded={drawerExpanded}
        />
      )}
      {(canAccessAffiliationsPage
        || canAccessDmdcStatusPage
        || canAccessVaStatusPage
        || canAccessDomainWhitelistPage
        || canAccessDocumentRuleSetsPage) && (
          // eslint-disable-next-line
          <div onClick={() => expandDrawer(true)}>
            <NavListItem
              text="Settings"
              icon={<SettingsIcon style={getStyle(RouteConstants.whitelist.review.path)} />}
              drawerExpanded={drawerExpanded}
              // eslint-disable-next-line
              children={
                drawerExpanded
                && getGlobalSettings(
                  location,
                  canAccessAffiliationsPage,
                  canAccessDmdcStatusPage,
                  canAccessVaStatusPage,
                  canAccessDomainWhitelistPage,
                  canAccessDocumentRuleSetsPage,
                )
              }
            />
          </div>
      )}
      {canAccessDeveloperPage && (
        <NavListItem
          text="Development Tools"
          icon={<DataObject style={getStyle(RouteConstants.codePools.index.path)} />}
          link={RouteConstants.development.path}
          drawerExpanded={drawerExpanded}
        />
      )}
      {!(drawerExpanded)
        && (canAccessListingListPage
          || canAccessVerificationRequestListPage
          || canAccessAffiliationsPage
          || canAccessDmdcStatusPage
          || canAccessVaStatusPage
          || canAccessDomainWhitelistPage
          || canAccessDocumentRuleSetsPage)}
    </>
  )
}

NavList.propTypes = {
  drawerExpanded: PropTypes.bool.isRequired,
  expandDrawer: PropTypes.func.isRequired,
}

export default NavList
