import { Box, Button } from '@mui/material'
import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { hideModal, showSuccesNotification, showErrorNotification } from '../../../redux/actions'
import ModalContainer from '../ModalContainer'
import { MUITextInput } from '../../@Common/Form/TextInput/ControlledTextInput'

// eslint-disable-next-line import/prefer-default-export
export function GovXIdLinkModal({
  offer,
  title,
  message,
}) {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const closeModal = useCallback(() => dispatch(hideModal()), [dispatch])

  const onClickCopyLink = (textInputRef) => {
    if (!navigator.clipboard) {
      // Explanation: https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext/71876238#71876238
      // eslint-disable-next-line no-console
      console.warn('Unable to access clipboard. If you are debugging the application'
        + ' access to the clipboard may be restricted due to running over http (not https).')
      dispatch(showErrorNotification('Failed to copy GovX ID Offer Link to clipboard'))
      return
    }

    textInputRef.current.select()
    try {
      navigator.clipboard.writeText(textInputRef.current.value)
      dispatch(showSuccesNotification('GovX ID Offer Link copied to clipboard'))
    } catch (err) {
      console.error('Failed to copy: ', err)
      dispatch(showErrorNotification('Failed to copy GovX ID Offer Link to clipboard'))
    }
  }

  return (
    <ModalContainer
      title={title}
      modal
      maxWidth="md"
      fullWidth="true"
      processing={false}
      actions={{
        secondaryAction: { label: 'Done', onClick: closeModal },
      }}
    >
      <Box>
        <p>
          {message}
        </p>
      </Box>
      <Box mt="1rem">
        <MUITextInput label={title} value={offer.link} disabled inputRef={ref} />
      </Box>
      <Box mt=".5rem">
        <Button
          sx={{
            background: 'transparent', textTransform: 'capitalize', fontWeight: 'normal', fontSize: '.875rem',
          }}
          onClick={() => onClickCopyLink(ref)}
          startIcon={<ContentCopyIcon />}
        >
          Copy to clipboard
        </Button>
      </Box>
    </ModalContainer>
  )
}

GovXIdLinkModal.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}
