import React from 'react'
import PropTypes from 'prop-types'
import MUITextField from '@mui/material/TextField'
import { Field } from 'redux-form'
import { DateMask } from '../../masks'
import { date } from '../../validation'
import {IMaskInput} from "react-imask";

export const formatDate = value => {
	return DateMask.mask(value)
}

export const normalizeDate = value => {
	return DateMask.unmask(value)
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const { onChange, mask, ...other } = props;
	return (
		<div>
			<IMaskInput
				{...other}
				mask={Date}
				pattern="m/d/Y"
				blocks={{
					d: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 31,
						maxLength: 2,
					},
						m: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 12,
						maxLength: 2,
					},
						Y: {
						mask: IMask.MaskedRange,
						from: 1900,
						to: 30000,
					}
				}}
				parse={(str) => {
					const monthDayYear = str.split('/');
					return new Date(monthDayYear[2]-1, monthDayYear[0], monthDayYear[1]);
				}}
				min={new Date(1900, 0, 1)}
				max={new Date()}
				inputRef={ref}
				// onAccept={(value) => {console.log(value)}}
				// overwrite
				unmask={true}
			/>
		</div>
	);
});

TextMaskCustom.propTypes = {
	name: PropTypes.string.isRequired,
};

export const renderDateField = ({ input, label, placeholder, mask, meta: { touched, error }, children, ...custom }) => (
	<MUITextField
		fullWidth
		variant='filled'
		label={label}
		helperText={touched && error}
		error={touched && !!error}
		placeholder={placeholder}
		InputProps={{
			inputComponent: TextMaskCustom,
			inputProps: {
				mask,
				'aria-label': label || placeholder,
			},
		}}
		{...input}
		{...custom}
	/>
)

const DateField = ({ name, label, placeholder, preValidate = [], postValidate = [], ...custom }) => (
	<Field
		name={name}
		variant='filled'
		label={label}
		placeholder={placeholder}
		component={renderDateField}
		//normalize={normalizeDate}
		//format={formatDate}
		validate={[...preValidate, ...[date], ...postValidate]}
		{...custom}
	/>
)

DateField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	preValidate: PropTypes.array,
	postValidate: PropTypes.array,
}

export default DateField
