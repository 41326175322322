import React from 'react'
import { useSelector } from 'react-redux'
// Affiliations
import AddAffiliationModal from './Occupations/AddAffiliationModal'
import EditAffiliationModal from './Occupations/EditAffiliationModal'
import EditAffiliationNameModal from './Occupations/EditAffiliationNameModal'
// Domain whitelist
import AddWhitelistedDomainModal from './DomainWhitelist/AddWhitelistedDomainModal'
import DeleteWhitelistedDomainModal from './DomainWhitelist/DeleteWhitelistedDomainModal'
// Listings
import CreateListingModal from './Listings/CreateListingModal'
import UpdatePartnersListingModal from './Partners/Listings/UpdatePartnersListingModal'
import PartnersListingStatusModal from './Partners/Listings/PartnersListingStatusModal'
import { ArchivePartnerListingModal } from './Partners/Listings/ArchivePartnerListingModal'
import OAuthLinkDisplayModal from './Listings/OAuthLinkDisplayModal'
import VerificationLinkModal from './Listings/VerificationLinkModal'
import DeleteVerificationLinkModal from './Listings/DeleteVerificationLinkModal'
import ConfirmPublishModal from './Listings/ConfirmPublishModal'
import EditListingNameModal from './Listings/EditListingNameModal'
import SecretKeyModal from './Listings/SecretKeyModal'
import BearerModal from './Listings/BearerModal'
import LinkAdvantageModal from './Listings/LinkAdvantageModal'
import DeleteAdvantageEntertainerModal from './Listings/DeleteAdvantageEntertainerModal'
import DeleteAdvantageVenueModal from './Listings/DeleteAdvantageVenueModal'
import CampaignModal from './Listings/CampaignModal'
import ConfirmCampaignDeleteModal from './Listings/ConfirmCampaignDeleteModal'
import AddListingUserModal from './Listings/AddListingUserModal'
import RemoveListingUserModal from './Listings/RemoveListingUserModal'
import CreateListingRedirectUrlModal from './Listings/CreateListingRedirectUrlModal'
import DeleteListingRedirectUrlModal from './Listings/DeleteListingRedirectUrlModal'
import DeleteListingSpecialOfferModal from './Listings/DeleteListingSpecialOfferModal'
import EditOfferPageModal from './Listings/EditOfferPageModal'
import ViewOfferPageModal from './Listings/ViewOfferPageModal'
// Users
import AddUserModal from './Users/AddUserModal'
import DeleteUserModal from './Users/DeleteUserModal'
import EditUserModal from './Users/EditUserModal'
import LockOutUserModal from './Users/LockOutUserModal'
import UnlockUserModal from './Users/UnlockUserModal'
import BulkImportUsersModal from './Users/BulkImportUsersModal'
import DuplicateUserMappingsModal from './Users/DuplicateUserMappingsModal'
// Verification
import DeleteVerificationRequestModal from './DeleteVerificationRequestModal'
import RejectVerificationRequestModal from './RejectVerificationRequestModal'
import VerificationRequestLogModal from './VerificationRequestLogModal'
import DocScanResultsModal from './DocScanResultsModal'
import RuleSetInfoModal from './RuleSetInfoModal'
// Tickets
import EventTicketInfoModal from './Tickets/EventTicketInfoModal'
import VenueTicketInfoModal from './Tickets/VenueTicketInfoModal'
// Common
import DescriptionModal from './DescriptionModal'
import UnsavedChangesModal from './UnsavedChangesModal'
// Marketplace
import UpdateAffiliateStatusModal from './Affiliates/UpdateAffiliateStatusModal'
import UpdateBillingTermsModal from './Marketplace/UpdateBillingTermsModal'
import CreateListingSpecialOfferModal from './Listings/CreateListingSpecialOfferModal'

// Offers
import OfferCreateEditModal from '../Partners/Listing/Tabs/Offers/OffersSection/OfferCreateEditModal'
import { GovXIdLinkModal } from './Offers/GovXIdLinkModal'
import { PublishOfferModal } from './Offers/PublishOfferModal'
import { DeleteOfferModal } from './Offers/DeleteOfferModal'
import UpdateOfferBillingRulesModal from '../Partners/Listing/Tabs/Marketplace/Billing/updateOfferBillingRulesModal'

// Code Pools
import CreateEditCodePoolModal from '../CodePools/createEdit/CreateEditCodePoolModal'

// Dialogs
import DialogModal from '../@Common/Modal/DialogModal'
import ImportCodesModal from '../CodePools/detail/import/ImportCodesModal'
import OfferCommissionRateHistoryModal from '../Partners/Listing/Tabs/Offers/OffersSection/OfferCommissionRateHistoryModal'
import SyndicateOfferCreateModal from '../Partners/Listing/Tabs/Offers/OffersSection/SyndicateOfferCreateModal'
import SyndicateOfferToListingCreateModal from '../Partners/Listing/Tabs/Offers/OffersSection/SyndicateOfferToListingCreateModal'
import SyndicateOfferRemoveModal from '../Partners/Listing/Tabs/Offers/OffersSection/SyndicateOfferRemoveModal'
import LinkedOffersModal from '../CodePools/detail/LinkedOfferModal'
import DeleteCodesModal from '../CodePools/detail/DeleteCodesModal'
import CodePoolDetailsModal from '../CodePools/detail/CodePoolDetailsModal'
import EventCreateEditModal from '../Partners/Listing/Tabs/Offers/EventsSection/EventCreateEditModal'
import ImportEventsModal from '../Partners/Listing/Tabs/Offers/EventsSection/ImportEventsModal'
import ArchiveEventModal from '../Partners/Listing/Tabs/Offers/EventsSection/ArchiveEventModal'
import BulkEditEventsModal from '../Partners/Listing/Tabs/Offers/EventsSection/BulkEditEventsModal'
import SyndicatedListingsModal from '../Partners/Listing/Tabs/Offers/OffersSection/SyndicatedListingsModal'

let MODAL_COMPONENTS = {
  VERIFICATION_REQUEST_LOG: VerificationRequestLogModal,
  DOC_SCAN_RESULTS: DocScanResultsModal,
  RULE_SET_INFO: RuleSetInfoModal,
  REJECT_VERIFICATIONREQUEST: RejectVerificationRequestModal,
  DELETE_VERIFICATIONREQUEST: DeleteVerificationRequestModal,
  ADD_AFFILIATION: AddAffiliationModal,
  ADD_WHITELISTED_DOMAIN: AddWhitelistedDomainModal,
  DELETE_WHITELISTED_DOMAIN: DeleteWhitelistedDomainModal,
  ADD_USER: AddUserModal,
  EDIT_USER: EditUserModal,
  LOCK_OUT_USER: LockOutUserModal,
  UNLOCK_USER: UnlockUserModal,
  EDIT_AFFILIATION: EditAffiliationModal,
  EDIT_AFFILIATION_NAME: EditAffiliationNameModal,
  DELETE_USER: DeleteUserModal,
  DUPLICATE_USER_MAPPINGS: DuplicateUserMappingsModal,
  CREATE_LISTING: CreateListingModal,
  UPDATE_PARTNERS_LISTING: UpdatePartnersListingModal,
  UPDATE_PARTNERS_LISTING_STATUS: PartnersListingStatusModal,
  ARCHIVE_PARTNER_LISTING: ArchivePartnerListingModal,
  VERIFICATION_LINK: VerificationLinkModal,
  OAUTH_LINK_DISPLAY: OAuthLinkDisplayModal,
  SHOW_DESCRIPTION: DescriptionModal,
  UNSAVED_CHANGES: UnsavedChangesModal,
  DELETE_VERIFICATION_LINK: DeleteVerificationLinkModal,
  CONFIRM_PUBLISH: ConfirmPublishModal,
  EDIT_LISTING_NAME: EditListingNameModal,
  SHOW_SECRET_KEY: SecretKeyModal,
  SHOW_BEARER: BearerModal,
  LINK_ADVANTAGE: LinkAdvantageModal,
  DELETE_ENTERTAINER: DeleteAdvantageEntertainerModal,
  EDIT_OFFER_PAGE: EditOfferPageModal,
  VIEW_OFFER_PAGE: ViewOfferPageModal,
  ADD_PARTNERS_OFFER: OfferCreateEditModal,
  EDIT_PARTNERS_OFFER: OfferCreateEditModal,
  VIEW_OFFER_COMMISSION_RATE_HISTORY: OfferCommissionRateHistoryModal,
  VIEW_OFFER_SYNDICATED_LISTINGS: SyndicatedListingsModal,
  CREATE_SYNDICATE_OFFER_MODAL: SyndicateOfferCreateModal,
  CREATE_LISTING_SYNDICATE_OFFER_MODAL: SyndicateOfferToListingCreateModal,
  REMOVE_SYNDICATE_OFFER_MODAL: SyndicateOfferRemoveModal,
  VIEW_GOVX_LINK: GovXIdLinkModal,
  PUBLISH_OFFER_MODAL: PublishOfferModal,
  DELETE_OFFER_MODAL: DeleteOfferModal,
  DELETE_VENUE: DeleteAdvantageVenueModal,
  SHOW_EVENTTICKET_INFO: EventTicketInfoModal,
  SHOW_VENUETICKET_INFO: VenueTicketInfoModal,
  CAMPAIGN: CampaignModal,
  CONFIRM_CAMPAIGN_DELETE: ConfirmCampaignDeleteModal,
  ADD_LISTING_USER: AddListingUserModal,
  REMOVE_LISTING_USER: RemoveListingUserModal,
  CREATE_LISTING_REDIRECT_URL: CreateListingRedirectUrlModal,
  DELETE_LISTING_REDIRECT_URL: DeleteListingRedirectUrlModal,
  CREATE_LISTING_SPECIAL_OFFER: CreateListingSpecialOfferModal,
  DELETE_LISTING_SPECIAL_OFFER: DeleteListingSpecialOfferModal,
  BULK_USER_IMPORT: BulkImportUsersModal,
  UPDATE_AFFILIATE_STATUS: UpdateAffiliateStatusModal,
  UPDATE_BILLING_TERMS: UpdateBillingTermsModal,
  CREATE_CODE_POOL: CreateEditCodePoolModal,
  IMPORT_CODEPOOL_CODES: ImportCodesModal,
  DIALOG: DialogModal,
  UPDATE_OFFER_BILLING_RULES: UpdateOfferBillingRulesModal,
  DELETE_CODES: DeleteCodesModal,
  LINKED_OFFERS: LinkedOffersModal,
  CODE_POOL_DETAILS: CodePoolDetailsModal,
  CREATE_EVENT_MODAL: EventCreateEditModal,
  IMPORT_EVENTS_MODAL: ImportEventsModal,
  ARCHIVE_EVENT_MODAL: ArchiveEventModal,
  BULK_EDIT_EVENTS_MODAL: BulkEditEventsModal,
}

export const addModals = (modals) => {
  MODAL_COMPONENTS = { ...MODAL_COMPONENTS, ...modals }
}

export function ModalRoot() {
  const { modalType, modalProps } = useSelector((state) => state.modal)
  if (!modalType) return null
  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} />
}

export default ModalRoot
