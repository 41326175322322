import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, lifecycle, withState, branch, renderComponent } from 'recompose'
import { withStyles } from '@mui/styles'
import Switch from '@mui/material/Switch'
import ActionSection from './ActionSection'
import EnableQuickResponseCodesSection from './EnableQuickResponseCodesSection'
import SideSheetContainer from '../SideSheetContainer'
import Button from '../../Button'
import Loader from '../../Loader'
import {
    hideSideSheet,
    showSuccesNotification,
    showErrorNotification,
    syncProducts,
    getSyncProductsStatus,
    syncOrders,
    syncCollections,
    syncMarketing,
    curateProducts,
    fetchPolicies,
    updateAffiliateAllowProductPulls,
    fetchAffiliateByListingId,
    updateEligibleForGXMarketplace,
} from '../../../redux/actions'
import { hasPolicy, selectAffiliateByListingId, selectPartnerListing } from '../../../redux/selectors'
import { SYNC_SHOPIFY_AFFILIATE } from '../../../auth/policies'
import { getFeatureFlags } from '../../../setup/configuration'

const styles = {
    root: {
        fontFamily: 'Roboto Regular,Helvetica,sans-serif',
    },
    actionsTitle: {
        fontSize: '16px',
        fontWeight: '600',
        margin: '18px 0px',
    },
    actionSectionContainer: {
        border: '1px solid #cfcfcf',
        padding: '15px',
        margin: '10px 0',
        display: 'flex',
    },
    actionSectionText: {
        width: '70%',
        display: 'inline-block',
    },
    actionSectionTitle: {
        fontWeight: '600',
    },
    actionSectionDescription: {
        marginTop: '10px',
    },
    actionSectionButtonContainer: {
        minWidth: '120px',
        width: '30%',
        display: 'inline-block',
        textAlign: 'right',
        position: 'relative',
    },
    actionSectionButton: {
        minWidth: '120px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
    },
}

const ButtonSection = ({ classes, title, description, buttonLabel, onClick }) => (
    <div className={classes.actionSectionContainer}>
        <div className={classes.actionSectionText}>
            <div className={classes.actionSectionTitle}>{title}</div>
            <div className={classes.actionSectionDescription}>{description}</div>
        </div>
        <div className={classes.actionSectionButtonContainer}>
            <Button className={classes.actionSectionButton} label={buttonLabel} color="primary" disabled={false} onClick={onClick}>
                {buttonLabel}
            </Button>
        </div>
    </div>)

export const ShopifyIntegrationSideSheet = ({
    affiliate,
    listing,
    runSyncProducts,
    runSyncOrders,
    runSyncCollections,
    runSyncMarketing,
    runCurateProducts,
    onClose,
    classes,
    canSync,
    updateAffiliateAllowProductPulls,
    updateEligibleForGXMarketplace
}) => {
    let statusTypes = {
        live: 'live',
        listed: 'listed'
    }
    let marketplaceOptedIn = !(affiliate == null || (affiliate.status.toLowerCase() !== statusTypes.live && affiliate.status.toLowerCase() !== statusTypes.listed))
    const { EligibleForGovXMarketplace, EnableQuickResponseCodes } = getFeatureFlags()

    return (
        <SideSheetContainer
            title={'Shopify Integration'}
            sideSheet={true}
            maxWidth='md'
            fullWidth='true'
            actions={{
                secondaryAction: {
                    label: 'Close',
                    onClick: onClose
                }
            }}
        >
            <div className={classes.root}>
                {EligibleForGovXMarketplace &&
                    <div style={{ margin: '0px' }}>
                        <div>
                            <strong>Eligible for GOVX Marketplace</strong>
                            <Switch
                                value={listing.eligibleForGXMarketplace}
                                checked={listing.eligibleForGXMarketplace}
                                disabled={marketplaceOptedIn}
                                onChange={e => updateEligibleForGXMarketplace(listing, e.target.checked)}
                            />
                        </div>
                        {listing.eligibleForGXMarketplace && <div>The marketing tab is now accessible in the partner's Shopify app.</div>}
                    </div>
                }
                <EnableQuickResponseCodesSection
                    show={EnableQuickResponseCodes}
                    partnerId={listing.partnerId}
                />
                {affiliate != null &&
                    <div className={classes.actionsTitle}>Actions</div>
                }
                {affiliate != null && canSync && ButtonSection({
                    classes,
                    title: 'Sync Affiliate',
                    description: 'Refresh affiliate, including name and logo, with the latest data available from Shopify.',
                    buttonLabel: 'Sync Affiliate',
                    onClick: () => runSyncMarketing(affiliate)
                })}
                {affiliate != null &&
                    <div style={{ margin: '18px 0px' }}>
                        <div style={{ display: 'inline-block', padding: '0px 15px' }}>
                            <strong>Enable Products</strong>
                            <Switch
                                value={affiliate.allowProductPulls}
                                checked={affiliate.allowProductPulls}
                                disabled={affiliate.status === 'Off' || affiliate.status === 'Disabled'}
                                onChange={e => updateAffiliateAllowProductPulls(affiliate, e.target.checked)}
                            />
                        </div>
                    </div>
                }
                {affiliate != null && canSync && affiliate.allowProductPulls &&
                    <ActionSection
                        title='Sync Products'
                        description='Refresh product information with the latest data available in Shopify.'
                        buttonId='syncProductsButton'
                        buttonLabel='Sync Now'
                        options={[
                            { type: 'boolean', text: 'Force Update', key: 'forceUpdate', value: false }
                        ]}
                        onClick={(forceUpdate) => runSyncProducts(affiliate, forceUpdate)}
                    />
                }
                {affiliate != null && canSync && affiliate.allowProductPulls &&
                    <div className={classes.actionSectionContainer}>
                        <div className={classes.actionSectionText}>
                            <div className={classes.actionSectionTitle}>Sync Orders</div>
                            <div className={classes.actionSectionDescription}>Refresh product order data with the latest 30 days available in Shopify. This impacts product curation and sorting.</div>
                        </div>
                        <div className={classes.actionSectionButtonContainer}>
                            <Button
                                className={classes.actionSectionButton}
                                label={'Sync Now'}
                                id={'syncOrdersButton'}
                                color="primary"
                                disabled={false}
                                onClick={() => runSyncOrders(affiliate)}
                            >
                                Sync Now
                            </Button>
                        </div>
                    </div>
                }
                {affiliate != null && canSync && affiliate.allowProductPulls &&
                    <ActionSection
                        title='Curate Products'
                        description='Curation should run after all other data points have been pulled from Shopify. The curation process decides which products are eligible to publish to the site.'
                        buttonId='curateProductsButton'
                        buttonLabel='Curate'
                        options={[
                            { type: 'boolean', text: 'Force Run', key: 'forceRun', value: false }
                        ]}
                        onClick={({ forceRun }) => runCurateProducts({ affiliate, forceRun })}
                    />
                }
            </div>
        </SideSheetContainer>
    )
}

ShopifyIntegrationSideSheet.propTypes = {
    affiliate: PropTypes.object,
    listing: PropTypes.object,
    onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    canSync: hasPolicy(SYNC_SHOPIFY_AFFILIATE)(state),
    affiliate: selectAffiliateByListingId(ownProps.affiliate?.listingId)(state),
    listing: selectPartnerListing(ownProps.listing.id)(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClose: () => dispatch(hideSideSheet()),
    fetchPolicies: () => dispatch(fetchPolicies([SYNC_SHOPIFY_AFFILIATE])),
    runSyncProducts: async (affiliate, forceUpdate) => {
        if (affiliate.shopId === '') {
            dispatch(showErrorNotification('Unable to run a product sync. Please sync affiliate and refresh.'))
        }
        else {
            let statusCommand = {}
            statusCommand.shopIdString = affiliate.shopId
            await dispatch(getSyncProductsStatus(affiliate.id, statusCommand))
                .then(x => ownProps.syncProductsStatus = x);
            if (ownProps.syncProductsStatus) {
                dispatch(showErrorNotification('Unable to run a product sync. A sync operation is already in progress.'))
            }
            else {
                let command = {}
                command.shopIdString = affiliate.shopId
                command.pullAllProducts = forceUpdate.forceUpdate
                dispatch(syncProducts(affiliate.id, command))
                    .then(x => dispatch(showSuccesNotification('Sync action has been initiated')))
            }
        }
    },
    runSyncOrders: (affiliate) => {
        if (affiliate.shopId === '') {
            dispatch(showErrorNotification('Unable to run an order sync. Please sync affiliate.'))
        }
        else {
            let command = {}
            command.shopIdString = affiliate.shopId
            dispatch(syncOrders(affiliate.id, command))
                .then(x => dispatch(showSuccesNotification('Sync action has been initiated')))
        }
    },
    runSyncCollections: (affiliate) => {
        let command = {}
        command.shopIdString = affiliate.shopId
        dispatch(syncCollections(affiliate.id, command))
            .then(x => dispatch(showSuccesNotification('Sync action has been initiated')))
    },
    runSyncMarketing: (affiliate) => {
        const { listingId } = affiliate
        dispatch(syncMarketing(listingId))
            .then(x => {
                dispatch(showSuccesNotification('Sync action has been initiated'))
                ownProps.refreshAffiliate(listingId)
            })
    },
    runCurateProducts: ({ affiliate, forceRun }) => {
        if (affiliate.shopId === '') {
            dispatch(showErrorNotification('Unable to run order curation. Please sync affiliate and refresh.'))
        }
        else {
            let command = { forceRun }
            command.shopIdString = affiliate.shopId
            dispatch(curateProducts(affiliate.id, command))
                .then(x => dispatch(showSuccesNotification('Sync action has been initiated')))
        }
    },
    updateAffiliateAllowProductPulls: (affiliate, allow) => {
        return dispatch(updateAffiliateAllowProductPulls(affiliate.id, allow))
    },
    updateEligibleForGXMarketplace: (listingId, allow) => {
        return dispatch(updateEligibleForGXMarketplace(listingId.id, allow))
    },
    fetchAffiliateByListingId: listingId => {
        ownProps.setLoaded(false)
        return dispatch(fetchAffiliateByListingId(listingId)).then(x => ownProps.setLoaded(true))
    },
})

const enhance = compose(
    withState('loaded', 'setLoaded', props => false),
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    lifecycle({
        componentDidMount() {
            if (this.props.affiliate != null)
                this.props.fetchAffiliateByListingId(this.props.affiliate.listingId)
            else
                this.props.setLoaded(true)
        },
        componentWillReceiveProps(nextProps) {
            if (nextProps.affiliate != null) {
                let listingId = nextProps.affiliate.listingId
                if (listingId !== this.props.affiliate.listingId) {
                    this.props.fetchAffiliateByListingId(listingId)
                }
            }
        },
    }),
    branch(({ loaded }) => !loaded, renderComponent(Loader), x => x)
)

export default enhance(ShopifyIntegrationSideSheet)
