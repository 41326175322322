import React from 'react'
import TabWithSubNav from '../../../../TabWithSubNav'
import Invoices from './Invoices/Invoices'
import useInvoices from './Invoices/useInvoices'

const getItems = () => {
    const items = []
    items.push({ primaryText: 'Invoices', component: <Invoices {...useInvoices()} /> })
    return items
}

function BillingTab() {
    const items = getItems()
    return items ? (
        <div style={{ margin: '30px 0 0 0' }}>
            <TabWithSubNav items={items} />
        </div>
    ) : null
}

export default BillingTab
