export const SOURCE = Object.freeze({
    direct: 'direct',
    shopify: 'shopify'
})

export const MARKETPLACE_STATUS = Object.freeze({
    off: 'off',
    live: 'live',
    listed: 'listed',
    disabled: 'disabled'
})

export const LISTING_STATUS = Object.freeze({
    published: 'published',
})

export const SALES_CHANNEL = Object.freeze({
    govx: 'GovXMarketplace',
    offer: 'GovXMarketplaceOfferPage'
})