import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {hideModal, showSuccesNotification, showErrorNotification} from '../../../../../../redux/actions'
import {useUpdateBillingRulesforOfferMutation} from '../../../../../../redux/slices/offersSlice'
import ModalContainer from '../../../../../Modals/ModalContainer'
import {MUIFormTextInput} from '../../../../../@Common/Form/TextInput/FormTextInput'
import {Box} from '@mui/material'

export function updateOfferBillingRulesModal({
    offer,
    partnerListingId,
    fetchOffersByAffiliateId
}) {
    const [processing, setProcessing] = useState(false)
    const [updateBillingRulesforOffer] = useUpdateBillingRulesforOfferMutation()
    const dispatch = useDispatch()

    const {handleSubmit, control} = useForm({
        defaultValues: {
            commissionRate: offer.commissionRate
        },
    })

    const onSubmit = async (values) => {
        setProcessing(true)
        const billingRules = {
            commissionRate: values.commissionRate,
            flatRate: null
        }

        try {
            await updateBillingRulesforOffer({offerId: offer.id, listingId: partnerListingId, billingRules: billingRules})
            fetchOffersByAffiliateId()
            dispatch(showSuccesNotification(`Commission rate for "${ offer.name }" has been updated.`))
        } catch (err) {
            console.error(err)
            dispatch(showErrorNotification(`There was an error updating the commision rate. Please try again.`))

        }

        dispatch(hideModal())
    }

    return (
        <ModalContainer
            title='Edit Commission Rate'
            modal={true}
            maxWidth='xs'
            fullWidth='true'
            processing={processing}
            actions={{
                primaryAction: {
                    label: 'Confirm',
                    onClick: handleSubmit(onSubmit)
                },
                secondaryAction: {
                    label: 'Cancel',
                    onClick: () => dispatch(hideModal())
                }
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display={'flex'} alignItems={'center'}>
                    <Box>
                        <MUIFormTextInput
                            control={control}
                            type="number"
                            name={'commissionRate'}
                            label='Commission'
                            rules={{
                                required: true,
                                min: 0,
                                max: 100,
                            }}
                            suffix={'%'}
                            inputLabelProps={{shrink: true}}
                        />
                    </Box>
                </Box>
                {/* <div className={ classes.inputRow }>
				<div className={ classes.inputLabel }>Base Rate:</div>
				<div className={ classes.inputLabelDollars }>$</div>
				<TextField
					name="offerDollarsRate"
					//label="Base Rate"
					//normalize={normalizeDollars}
					//format={formatDollars}
					validate={[ isNumber ]}
					fullWidth={false}
					style={{}}
					inputProps={{
						style: {
							padding:'12px',
							width: '60px',
						}
					}}
				/>
			</div> */}
            </form>
        </ModalContainer>
    )
}

updateOfferBillingRulesModal.propTypes = {
    offer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        affiliateId: PropTypes.string.isRequired,
        baseRate: PropTypes.number,
        commissionRate: PropTypes.number,
        name: PropTypes.string.isRequired,
        pricingSchemeType: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired
    }).isRequired,
    partnerListingId: PropTypes.string.isRequired,
    fetchOffersByAffiliateId: PropTypes.func.isRequired
}

export default updateOfferBillingRulesModal