import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import { hideSideSheet } from '../../../redux/actions'

const ProcessingIcon = () => (
	<div
		style={{
			position: 'absolute',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(255,255,255,.83)',
		}}
	>
		<CircularProgress />
	</div>
)

export const SideSheetContainer = ({
	hideSideSheet,
	title,
	children,
	actions,
	maxWidth,
	fullWidth,
	processing,
	disabled,
	showScrollAlways = false,
	sideSheet = false,
}) => (
	<Dialog
		open={true}
		onClose={hideSideSheet}
		scroll="paper"
		maxWidth={maxWidth ? maxWidth : 'sm'}
		fullWidth={fullWidth == 'false' ? false : true}
	>
		<DialogTitle id="form-dialog-title" style={{ borderBottom: `1px solid #e0e0e0` }}>
			{title}
		</DialogTitle>
		<DialogContent style={{ overflowY: showScrollAlways ? 'scroll' : 'auto', maxHeight: '70vh', paddingTop: '24px' }}>
			{children}
		</DialogContent>
		<DialogActions style={{ padding: '12px 24px', margin: '0px', backgroundColor: '#f3f3f3' }}>
			{actions &&
				actions.secondaryAction && (
					<Button
						variant="text"
						onClick={actions.secondaryAction.onClick}
						disabled={actions.secondaryAction.disabled}
						label={actions.secondaryAction.label}
					>
						{actions.secondaryAction.label}
					</Button>
				)}
			{actions &&
				actions.primaryAction && (
					<Button
						variant='contained'
						color="primary"
						onClick={actions.primaryAction.onClick}
						disabled={actions.primaryAction.disabled}
						label={actions.primaryAction.label}
					>
						{actions.primaryAction.label}
					</Button>
				)}
		</DialogActions>

		{processing ? <ProcessingIcon /> : null}
	</Dialog>
)

SideSheetContainer.propTypes = {
	hideSideSheet: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.node,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
	hideSideSheet: (event, reason) => {
		if (!ownProps.sideSheet || reason !== 'backdropClick')
			dispatch(hideSideSheet())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(SideSheetContainer)
