/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import TemplateFormPartial from './TemplateFormPartial'
import {
  fetchListingTemplateSettings, updateListingTemplateSettings, saveListingPicture, updateListingWizardSettings,
} from '../../../../redux/actions'
import { selectListingSettings, hasPolicy } from '../../../../redux/selectors'
import {
  EDIT_LISTING_COBRAND_TEMPLATE,
} from '../../../../auth/policies'
import { getTargetSizeByFileAsync } from '../../../@Common/Pictures/pictureUtils'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        minHeight: '400px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function TemplateForm({
  listing,
  innerRef,
  isWizard,
  wizardStepBit,
  currentStep,
  setCurrentStep,
}) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)

  const canEditListingCobrandTemplate = useSelector(hasPolicy(EDIT_LISTING_COBRAND_TEMPLATE))
  const templateSettings = useSelector(selectListingSettings(listing.id, 'template'))

  const { handleSubmit, reset, ...form } = useForm({
    defaultValues: templateSettings,
  })

  const getListingTemplateSettings = () => {
    dispatch(fetchListingTemplateSettings(listing.id))
      .then((response) => {
        setProcessing(false)
        reset(response)
      })
  }

  useEffect(() => {
    getListingTemplateSettings()
  }, [])

  const saveListingPicturePromise = async ({
    listingId,
    pictureType,
    file,
    seoFilename,
  }) => {
    const formData = new window.FormData()
    const targetSize = await getTargetSizeByFileAsync(file)
    formData.append('file', file)
    formData.append('seoFilename', seoFilename)
    formData.append('targetSize', targetSize)
    return dispatch(saveListingPicture(listingId, pictureType, formData))
  }

  const onSubmit = (values) => {
    setProcessing(true)
    const settings = {}
    settings.type = values.type
    settings.headerBackgroundColor = values.headerBackgroundType === 'transparent' ? 'transparent' : values.headerBackgroundColor
    settings.headerMobileBackgroundColor = values.headerMobileBackgroundColor
    settings.headerBackgroundType = values.headerBackgroundType
    settings.headerLogoAlignment = values.headerLogoAlignment
    settings.headerLogoHeight = values.headerLogoHeight > 150 ? 150 : values.headerLogoHeight < 30 ? 30 : values.headerLogoHeight
    settings.headerLogoOrientation = templateSettings.headerLogoOrientation
    settings.bodyBackgroundColor = values.bodyBackgroundColor
    settings.bodyBackgroundType = values.bodyBackgroundType
    settings.footerBackgroundType = values.footerBackgroundType
    settings.footerBackgroundColor = values.footerBackgroundColor
    settings.footerGovXLogoColor = values.footerGovXLogoColor
    settings.footerLinkColor = values.footerLinkColor
    settings.footerShowFooterLinks = !!values.footerShowFooterLinks
    settings.footerShowGovXLogo = !!values.footerShowGovXLogo

    const promises = []
    if (typeof values.headerLogoImageUrl !== 'string') {
      promises.push(saveListingPicturePromise({
        listingId: listing.id,
        pictureType: 'logo',
        file: values.headerLogoImageUrl,
        seoFilename: 'listing-header-logo',
      }))
    } else {
      settings.headerLogoImageUrl = values.headerLogoImageUrl
    }
    if (typeof values.bodyBackgroundImageUrl !== 'string' && values.bodyBackgroundImageUrl) {
      const backgroundImageFormData = new window.FormData()
      backgroundImageFormData.append('file', values.bodyBackgroundImageUrl)
      backgroundImageFormData.append('seoFilename', 'listingBackgroundImage')
      backgroundImageFormData.append('targetSize', 2000)
      promises.push(dispatch(saveListingPicture(listing.id, 'background', backgroundImageFormData)))
    } else {
      settings.bodyBackgroundImageUrl = values.bodyBackgroundImageUrl || ''
    }

    if (isWizard) promises.push(dispatch(updateListingWizardSettings(listing.id, { stepsCompleted: wizardStepBit })))

    return promises.length > 0
      ? Promise.all(promises)
        .then((responses) => {
          responses.forEach((r) => {
            if (r.pictureType && r.pictureType === 'logo') {
              settings.headerLogoImageUrl = r.url
              settings.headerLogoOrientation = r.orientation
            } else if (r.pictureType && r.pictureType === 'background') {
              settings.bodyBackgroundImageUrl = r.url
            }
          })
          dispatch(updateListingTemplateSettings(listing.id, settings))
            .then(() => {
              if (isWizard) setCurrentStep(currentStep + 1)
              setProcessing(false)
            })
        })
        .catch(() => { })
      : dispatch(updateListingTemplateSettings(listing.id, settings))
        .then(() => setProcessing(false))
  }

  return (
    <div style={{ position: 'relative', minHeight: '265px' }}>
      {
        processing || !templateSettings
          ? <Processing />
          : (
            <form ref={innerRef} onSubmit={handleSubmit(onSubmit)}>
              <TemplateFormPartial
                form={form}
                isGovX={listing.id === 1}
                canEditListingCobrandTemplate={canEditListingCobrandTemplate}
                initialValues={templateSettings}
              />
            </form>
          )
      }
    </div>
  )
}

export default TemplateForm
