import React from 'react'
import { RouteConstants } from './routeConstants'
import Admin from '../components/Admin'
import ErrorScreen from '../components/ErrorScreen'
import UsersPageContainer from '../containers/Users'
import UserViewPageContainer from '../containers/User'
import PartnersPageContainer from '../containers/Partners'
import PartnersListingsPageContainer from '../containers/Partners/Listings'
import PartnersListingPageContainer from '../containers/Partners/Listing'
import LoginPageContainer from '../containers/Login'
import DomainWhitelistPageContainer from '../containers/Whitelist'
import DocumentRuleSetsPageContainer from '../containers/DocumentRuleSets'
import VerificationListingsPageContainer from '../containers/VerificationListings'
import VerificationListingPageContainer from '../containers/VerificationListing'
import VerificationRequestsPageContainer from '../containers/VerificationRequests'
import DmdcStatusReportPageContainer from '../containers/DmdcStatusReport'
import VaStatusReportPageContainer from '../containers/VaStatusReport'
import OccupationTaxonomyPageContainer from '../containers/OccupationTaxonomy'
import DashboardPageContainer from '../containers/Dashboard'
import CodePoolsPageContainer from '../containers/CodePools'
import CodePoolDetailPageContainer from '../containers/CodePoolDetail'
import DevelopmentPageContainer from '../containers/Development'
import NotFoundScreen from '../components/ErrorScreen/NotFound'

export const routes = [
  {
    path: '/',
    element: <Admin />,
    children: [
      DashboardPageContainer,
      UserViewPageContainer,
      UsersPageContainer,
      PartnersPageContainer,
      PartnersListingsPageContainer,
      PartnersListingPageContainer,
      DomainWhitelistPageContainer,
      DocumentRuleSetsPageContainer,
      LoginPageContainer,
      VerificationListingsPageContainer,
      VerificationListingPageContainer,
      VerificationRequestsPageContainer,
      DmdcStatusReportPageContainer,
      VaStatusReportPageContainer,
      OccupationTaxonomyPageContainer,
      DevelopmentPageContainer,
      CodePoolsPageContainer,
      CodePoolDetailPageContainer,
      {
        path: RouteConstants.error.path,
        element: <ErrorScreen />,
        exact: true,
      },
      {
        path: '*',
        element: <NotFoundScreen />,
      },
    ],
  },
]
