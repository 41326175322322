/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../redux/actions'
import ModalContainer from '../../ModalContainer'

export function ArchivePartnerListingModal({ partnerListing, onSubmit }) {
  const dispatch = useDispatch()
  const onCancel = useCallback(() => dispatch(hideModal()), [dispatch])
  const [processing, setProcessing] = useState(false)
  return (
    <ModalContainer
      title="Archive Listing"
      modal
      maxWidth="md"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Confirm',
          onClick: async () => {
            setProcessing(true)
            try {
              await onSubmit(partnerListing)
            } finally {
              setProcessing(false)
            }
          },
        },
        secondaryAction: { label: 'Cancel', onClick: onCancel },
      }}
    >
      <div>This action is not reversible. Are you sure you want to continue?</div>
    </ModalContainer>
  )
}
