/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ModalContainer from './ModalContainer'
import { formatVerificationDocumentType } from '../../formatting'
import { hideModal } from '../../redux/actions'

export function RuleSetInfoModal({
  ruleSet,
}) {
  const dispatch = useDispatch()

  const onClose = () => dispatch(hideModal())

  return (
    <ModalContainer
      title="View Rules"
      maxWidth="sm"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div>
        <Box
          sx={{
            backgroundColor: '#e3e3e3',
            margin: '0px -24px',
            padding: '10px 24px',
            width: 'calc(100% + 48px)',
          }}
        >
          <Grid container spacing={1}>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <strong>Display name:</strong>
              </Grid>
              <Grid item xs={9}>
                { ruleSet.name }
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <strong>Affiliations:</strong>
              </Grid>
              <Grid item xs={9}>
                {[...new Set(ruleSet.verificationRuleSets.map((x) => x.occupationId))].join(', ')}
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <strong>Document types:</strong>
              </Grid>
              <Grid item xs={9}>
                {[...new Set(ruleSet.verificationRuleSets.map((x) => formatVerificationDocumentType(x.verificationDocumentType)))].join(', ')}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: '10px' }}>
          <List>
            {
              ruleSet.ruleDescriptions.map((x, i) => (
                <ListItem
                  key={i}
                  sx={{
                    border: '1px solid #cfcfcf', marginTop: '-1px', overflowWrap: 'anywhere', padding: '11px 24px',
                  }}
                >
                  <Grid container>
                    <Grid item xs={10} sx={{ color: '#222', lineHeight: '1.25', fontSize: '14px' }}>
                      { x }
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            }
          </List>
        </div>
      </div>
    </ModalContainer>
  )
}

export default RuleSetInfoModal
