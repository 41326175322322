import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import injectSheet from 'react-jss'
import StyledLink from '../StyledLink'

export const styles = {
	container: {
		position: 'relative',
		display: 'inline',
	},
	fileInput: {
		display: 'none',
	},
}

export const handleChange = (e, field) => {
	e.preventDefault()
	field.input.onChange(e.target.files[0] || null)
}

export const renderFileInput = field => (
	<div>
		<p className={ field.classes.container }>
			<input
				type='file'
				className={ field.classes.fileInput }
				onChange={ e => handleChange(e, field) }
				id={field.input.name + '_fileinput'}
				accept='.jpeg, .jpg, .png'
			/>
			<label htmlFor={field.input.name + '_fileinput'}>{field.label}</label>
		</p>
		{
			field.meta.error && field.meta.touched
			?	<p style={{ marginTop: '3px' }}><small style={{ color: '#f44336' }}>{ field.meta.error }</small></p>
			:	null
		}
	</div>
)

export const renderFileInputCSS = injectSheet(styles)(renderFileInput)

const FileInput = ({ name, label, validate, ...custom }) => (
	<Field name={ name } label={ label } component={ renderFileInputCSS } validate={ validate } { ...custom } />
)

FileInput.propTypes = {
	name: PropTypes.string.isRequired,
	validate: PropTypes.array
}

export default FileInput