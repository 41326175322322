/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { createUseStyles } from 'react-jss'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import { MUIFormTextAreaInput } from '../../../@Common/Form/TextAreaInput/FormTextAreaInput'
import { MUIFormSelectInput } from '../../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../../@Common/Form/TextInput/FormTextInput'

const useStyles = createUseStyles({
  root: {
    color: '#777',
  },
  focused: {
    '&$focused': {
      color: '#00a1df',
    },
  },
})

function MessagingFormPartial({ form }) {
  const classes = useStyles()

  const { control, watch } = form

  const getCharCount = (name) => {
    const value = watch(name)
    return value ? value.length : 0
  }

  return (
    <div style={{
      position: 'relative', minHeight: '400px', fontSize: '14px',
    }}
    >
      <div style={{ position: 'relative', margin: '30px 0px' }}>
        <MUIFormTextAreaInput
          control={control}
          name="welcomeHeadline"
          label="Welcome headline"
          rules={
            {
              required: true,
              maxLength: {
                value: 50,
                message: 'Must be 50 characters or less',
              },
            }
          }
          maxLength="50"
          rows={3}
          shrinkLabel
        />
        <Typography>
          <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
            {getCharCount('welcomeHeadline')}
            {' '}
            of 50
          </small>
        </Typography>
      </div>

      <div style={{ position: 'relative', margin: '30px 0px' }}>
        <MUIFormTextAreaInput
          control={control}
          name="welcomeMessage"
          label="Welcome message"
          rules={
            {
              required: true,
            }
          }
          maxLength="200"
          rows={3}
          shrinkLabel
        />
        <Typography>
          <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
            {getCharCount('welcomeMessage')}
            {' '}
            of 200
          </small>
        </Typography>
      </div>

      <div style={{ position: 'relative', margin: '30px 0px' }}>
        <MUIFormTextAreaInput
          control={control}
          name="registerEmailHint"
          label="Email prompt"
          rules={
            {
              required: true,
            }
          }
          maxLength="40"
          rows={3}
          shrinkLabel
        />
        <Typography>
          <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
            {getCharCount('registerEmailHint')}
            {' '}
            of 40
          </small>
        </Typography>
      </div>

      <div style={{ position: 'relative' }}>
        <MUIFormTextAreaInput
          control={control}
          name="prequalifierMessage"
          label="Pre-qualification message"
          rules={
            {
              required: true,
            }
          }
          maxLength="100"
          rows={3}
          shrinkLabel
        />
        <Typography>
          <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
            {getCharCount('prequalifierMessage')}
            {' '}
            of 100
          </small>
        </Typography>
      </div>

      <Box sx={{ margin: '20px 0px', width: '126px' }}>
        <DialogSubsectionHeadline>Show social proof?</DialogSubsectionHeadline>
        <MUIFormSelectInput
          control={control}
          name="showSocialProof"
          items={
            [
              { key: '1', value: 'true', label: 'Yes' },
              { key: '2', value: 'false', label: 'No' },
            ]
          }
        />
      </Box>

      <DialogSubsectionHeadline>
        Any user data shared as part of verification will be governed by your privacy policy and terms of service.
        Please provide the links to each below.
      </DialogSubsectionHeadline>
      <MUIFormTextInput
        control={control}
        name="privacyPolicyLink"
        label="Link to privacy policy"
        rules={
          {
            required: true,
            pattern: {
              value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
              message: 'Please enter a valid URL',
            },
          }
        }
        shrinkLabel
      />
      <Box sx={{ marginTop: '20px' }}>
        <MUIFormTextInput
          control={control}
          name="termsOfServiceLink"
          label="Link to terms of service"
          rules={{
            required: true,
            pattern: {
              value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
              message: 'Please enter a valid URL',
            },
          }}
          shrinkLabel
        />
      </Box>
    </div>
  )
}

export default MessagingFormPartial
