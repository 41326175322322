import { createSlice } from "@reduxjs/toolkit";
import { api } from './apiSlice'

export const eventsApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getEventsByPartnerListingId: build.query({
            query: (listingId) => `events?listingId=${listingId}`,
            providesTags: ['EventsByPartner'],
            method: 'GET',
        }),
        createEvent: build.mutation({
            query: newEvent => ({
                url: 'events',
                method: 'POST',
                body: newEvent,
                responseHandler: 'text',
            }),
            invalidatesTags: ['EventsByPartner'],
        }),
        updateEvent: build.mutation({
            query: oldEvent => ({
                url: 'events',
                method: 'PUT',
                body: oldEvent,
                responseHandler: 'text',
            }),
            invalidatesTags: ['EventsByPartner'],
        }),
        bulkUpdateEvents: build.mutation({
            query: events => ({
                url: 'events/bulk',
                method: 'PUT',
                body: events,
                responseHandler: 'text',
            }),
            invalidatesTags: ['EventsByPartner'],
        }),
        getEventById: build.query({
            query: eventId => `/events/${eventId}`,
            providesTags: ['Event'],

        }),
        archiveEvent: build.mutation({
            query: eventId => ({
                url: `/events/${eventId}/archive`,
                method: 'PUT',
            }),
            invalidatesTags: ['EventsByPartner'],
        }),
        bulkArchiveEvents: build.mutation({
            query: eventIds => ({
                url: `/events/bulk-archive`,
                method: 'PUT',
                body: eventIds
            }),
            invalidatesTags: ['EventsByPartner'],
        }),
        importEvents: build.mutation({
            query: ({ id, body }) => {
                const formBody = new FormData()
                formBody.append('importFile', body)
                formBody.append('listingId', id)
                return {
                    url: 'events/import-events',
                    method: 'POST',
                    body: formBody,
                    formData: true,
                }
            },
            invalidatesTags: ['EventsByPartner'],
        }),
        getEventImportById: build.query({
            query: importId => `/events/event-import/${importId}`,
            invalidatesTags: ['EventsByPartner'],
        }),
    })
})

const initialState = [];
const eventsSlice = createSlice({
    name: 'eventsNew',
    initialState,
    reducers: {},
})

export const {
    useGetEventsByPartnerListingIdQuery,
    useLazyGetEventsByPartnerListingIdQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useLazyGetEventByIdQuery,
    useArchiveEventMutation,
    useBulkArchiveEventsMutation,
    useBulkUpdateEventsMutation,
    useImportEventsMutation,
    useLazyGetEventImportByIdQuery
} = eventsApiSlice;

export default eventsSlice.reducer;
