import * as authenticationApi from '../../../api/authenticationApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'
import {
	ACCESS_USER_LIST_PAGE,
	ACCESS_AFFILIATE_LIST_PAGE,
	ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
	ACCESS_AFFILIATIONS_PAGE,
	ACCESS_DMDC_STATUS_PAGE,
	ACCESS_VA_STATUS_PAGE,
	ACCESS_DOMAIN_WHITELIST_PAGE,
	ACCESS_DOCUMENT_RULE_SETS_PAGE,
	ACCESS_LISTING_LIST,
	ACCESS_LISTING_ADVERTISEMENT,
	ACCESS_CODE_POOL_PAGE,
	ACCESS_PARTNER_LIST_PAGE
} from '../../../auth/policies'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const login = data => (dispatch, getState, { schema }) => {
	dispatch({ type: LOGIN_REQUEST })
	return authenticationApi
		.login(data)
		.then(response => {
			dispatch({ type: LOGIN_SUCCESS, user: response })
			return response
		})
		.catch(err => {
			dispatch({ type: LOGIN_FAILURE, err })
			throw err
		})
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const logout = () => (dispatch, getState, { schema }) => {
	dispatch({ type: LOGOUT_REQUEST })
	return authenticationApi
		.logout()
		.then(response => {
			dispatch({ type: LOGOUT_SUCCESS, user: response })
			return response
		})
		.catch(err => {
			dispatch({ type: LOGOUT_FAILURE, err })
			throw err
		})
}

export const FETCH_AUTHENTICATEDUSER_REQUEST = 'FETCH_AUTHENTICATEDUSER_REQUEST'
export const FETCH_AUTHENTICATEDUSER_SUCCESS = 'FETCH_AUTHENTICATEDUSER_SUCCESS'
export const FETCH_AUTHENTICATEDUSER_FAILURE = 'FETCH_AUTHENTICATEDUSER_FAILURE'

export const requireUser = () => (dispatch, getState, { schema }) => {
	let state = getState()
	let user = getLoggedInUser(state)
	if (user) dispatch({ type: FETCH_AUTHENTICATEDUSER_REQUEST })
	return authenticationApi
		.fetchCurrentUser()
		.then(response => {
			dispatch({ type: FETCH_AUTHENTICATEDUSER_SUCCESS, user: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AUTHENTICATEDUSER_FAILURE, err })
			return null
		})
}

export const FETCH_AUTHENTICATION_SETTINGS_REQUEST = 'FETCH_AUTHENTICATION_SETTINGS_REQUEST'
export const FETCH_AUTHENTICATION_SETTINGS_SUCCESS = 'FETCH_AUTHENTICATION_SETTINGS_SUCCESS'
export const FETCH_AUTHENTICATION_SETTINGS_FAILURE = 'FETCH_AUTHENTICATION_SETTINGS_FAILURE'

export const requireSettings = () => (dispatch, getState, { schema }) => {
	let state = getState()
	let settings = getSettings(state)
	if (settings) {
		dispatch({ type: FETCH_AUTHENTICATION_SETTINGS_SUCCESS, settings })
		return settings
	}
	dispatch({ type: FETCH_AUTHENTICATION_SETTINGS_REQUEST })
	return authenticationApi
		.fetchSettings()
		.then(response => {
			dispatch({ type: FETCH_AUTHENTICATION_SETTINGS_SUCCESS, settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AUTHENTICATION_SETTINGS_FAILURE, err })
			return null
		})
}

export const FETCH_POLICIES_REQUEST = 'FETCH_POLICIES_REQUEST'
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS'
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE'

export const fetchPolicies = policies => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_POLICIES_REQUEST })
	return authenticationApi
		.fetchPolicies(policies)
		.then(response => {
			const data = normalize(response, [schema.policy])
			dispatch({ type: FETCH_POLICIES_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_POLICIES_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'authentication'

export const DEFAULT_STATE = {
	user: null,
	policies: {},
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case LOGOUT_SUCCESS:
			return DEFAULT_STATE
		case LOGIN_SUCCESS:
		case FETCH_AUTHENTICATEDUSER_SUCCESS:
			return { ...state, user: action.user }
		case FETCH_AUTHENTICATION_SETTINGS_SUCCESS:
			return { ...state, settings: action.settings }
		case FETCH_POLICIES_SUCCESS:
			return { ...state, policies: { ...state.policies, ...action.data.entities.policies } }
		default:
			if (action.err && action.err.unauthorized) return DEFAULT_STATE
			return state
	}
}

export default reducer

export const isLoggedIn = state => state.authentication.user != null
export const getLoggedInUser = state => state.authentication.user
export const selectPoliciesHashMap = state => state.authentication.policies
export const hasPolicy = policyName =>
	createSelector(selectPoliciesHashMap, policies => {
		if (policies[policyName] && policies[policyName].isAuthorized) return true
		return false
	})
export const showNav = createSelector(selectPoliciesHashMap, policies => {
	let navPages = [
		ACCESS_USER_LIST_PAGE,
		ACCESS_AFFILIATE_LIST_PAGE,
		ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
		ACCESS_AFFILIATIONS_PAGE,
		ACCESS_DMDC_STATUS_PAGE,
		ACCESS_VA_STATUS_PAGE,
		ACCESS_DOMAIN_WHITELIST_PAGE,
		ACCESS_DOCUMENT_RULE_SETS_PAGE,
		ACCESS_LISTING_LIST,
		ACCESS_LISTING_ADVERTISEMENT,
		ACCESS_CODE_POOL_PAGE,
		ACCESS_PARTNER_LIST_PAGE
	]
	for (var i = 0, len = navPages.length; i < len; i++) {
		let policyName = navPages[i]
		if (policies[policyName] && policies[policyName].isAuthorized) return true
	}
	return false
})
export const userAdminHomepage = state => state.authentication.user.adminHomepage
export const getSettings = state => state.authentication.settings
