import { createSelector } from 'reselect'
import { normalize } from 'normalizr'
import * as picturesApi from '../../../api/picturesApi'
import * as campaignApi from '../../../api/campaignsApi'

export const FETCH_LISTING_CAMPAIGNS_REQUEST = 'FETCH_LISTING_CAMPAIGNS_REQUEST'
export const FETCH_LISTING_CAMPAIGNS_SUCCESS = 'FETCH_LISTING_CAMPAIGNS_SUCCESS'
export const FETCH_LISTING_CAMPAIGNS_FAILURE = 'FETCH_LISTING_CAMPAIGNS_FAILURE'

export const fetchCampaignsByListingId = listingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_CAMPAIGNS_REQUEST })
	return campaignApi.getCampaignsByListingId(listingId)
		.then(response => {
			const data = normalize(response, [schema.campaign])
			dispatch({ type: FETCH_LISTING_CAMPAIGNS_SUCCESS, listingId, data: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_CAMPAIGNS_FAILURE, err })
			throw err
		})
}

export const FETCH_CAMPAIGN_REQUEST = 'FETCH_CAMPAIGN_REQUEST'
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS'
export const FETCH_CAMPAIGN_FAILURE = 'FETCH_CAMPAIGN_FAILURE'

export const fetchCampaign = campaignId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_CAMPAIGN_REQUEST })
	return campaignApi.getCampaign(campaignId)
		.then(response => {
			const data = normalize(response, schema.campaign)
			dispatch({ type: FETCH_CAMPAIGN_SUCCESS, campaignId, data: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_CAMPAIGN_FAILURE, err })
			throw err
		})
}

export const FETCH_CAMPAIGN_TEMPLATESETTINGS_REQUEST = 'FETCH_CAMPAIGN_TEMPLATESETTINGS_REQUEST'
export const FETCH_CAMPAIGN_TEMPLATESETTINGS_SUCCESS = 'FETCH_CAMPAIGN_TEMPLATESETTINGS_SUCCESS'
export const FETCH_CAMPAIGN_TEMPLATESETTINGS_FAILURE = 'FETCH_CAMPAIGN_TEMPLATESETTINGS_FAILURE'

export const fetchCampaignTemplateSettings = campaignId => (dispatch, getState) => {
	dispatch({ type: FETCH_CAMPAIGN_TEMPLATESETTINGS_REQUEST })
	return campaignApi.getCampaignTemplateSettings(campaignId)
		.then(response => {
			dispatch({ type: FETCH_CAMPAIGN_TEMPLATESETTINGS_SUCCESS, campaignId: campaignId, key: 'template', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_CAMPAIGN_TEMPLATESETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_CAMPAIGN_MESSAGINGSETTINGS_REQUEST = 'FETCH_CAMPAIGN_MESSAGINGSETTINGS_REQUEST'
export const FETCH_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS = 'FETCH_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS'
export const FETCH_CAMPAIGN_MESSAGINGSETTINGS_FAILURE = 'FETCH_CAMPAIGN_MESSAGINGSETTINGS_FAILURE'

export const fetchCampaignMessagingSettings = campaignId => (dispatch, getState) => {
	dispatch({ type: FETCH_CAMPAIGN_MESSAGINGSETTINGS_REQUEST })
	return campaignApi.getCampaignMessagingSettings(campaignId)
		.then(response => {
			dispatch({ type: FETCH_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS, campaignId: campaignId, key: 'messaging', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_CAMPAIGN_MESSAGINGSETTINGS_FAILURE, err })
			throw err
		})
}

export const SAVE_CAMPAIGN_LOGO_REQUEST = 'SAVE_CAMPAIGN_LOGO_REQUEST'
export const SAVE_CAMPAIGN_LOGO_SUCCESS = 'SAVE_CAMPAIGN_LOGO_SUCCESS'
export const SAVE_CAMPAIGN_LOGO_FAILURE = 'SAVE_CAMPAIGN_LOGO_FAILURE'

export const SAVE_CAMPAIGN_BACKGROUNDIMAGE_REQUEST = 'SAVE_CAMPAIGN_BACKGROUNDIMAGE_REQUEST'
export const SAVE_CAMPAIGN_BACKGROUNDIMAGE_SUCCESS = 'SAVE_CAMPAIGN_BACKGROUNDIMAGE_SUCCESS'
export const SAVE_CAMPAIGN_BACKGROUNDIMAGE_FAILURE = 'SAVE_CAMPAIGN_BACKGROUNDIMAGE_FAILURE'

export const saveCampaignPicture = (campaignId, pictureType, formData) => (dispatch, getState, { schema }) => {
	dispatch({ type: pictureType === 'logo' ? SAVE_CAMPAIGN_LOGO_REQUEST : SAVE_CAMPAIGN_BACKGROUNDIMAGE_REQUEST })
	return picturesApi.createPicture(formData)
		.then(response => {
			let data = response
			data.campaignId = campaignId
			data.pictureType = pictureType
			dispatch({ type: pictureType === 'logo' ? SAVE_CAMPAIGN_LOGO_SUCCESS : SAVE_CAMPAIGN_BACKGROUNDIMAGE_SUCCESS, data })
			return data
		})
		.catch(err => {
			dispatch({ type: pictureType === 'logo' ? SAVE_CAMPAIGN_LOGO_FAILURE : SAVE_CAMPAIGN_BACKGROUNDIMAGE_FAILURE, err })
			throw err
		})
}

export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST'
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE'
export const CREATE_CAMPAIGN_FAILURE_DUPLICATE = 'CREATE_CAMPAIGN_FAILURE_DUPLICATE'

export const createCampaign = campaign => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_CAMPAIGN_REQUEST })
	return campaignApi.createCampaign(campaign)
		.then(r => {
			let data = normalize(r, schema.campaign)
			dispatch({ type: CREATE_CAMPAIGN_SUCCESS, data })
			return r
		}).catch(err => {
			if (err.response.status === 409) {
				dispatch({ type: CREATE_CAMPAIGN_FAILURE_DUPLICATE, err })
			} else {
				dispatch({ type: CREATE_CAMPAIGN_FAILURE, err })
			}
			throw err
		})
}

export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST'
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE'
export const UPDATE_CAMPAIGN_FAILURE_DUPLICATE = 'UPDATE_CAMPAIGN_FAILURE_DUPLICATE'

export const updateCampaign = campaign => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_CAMPAIGN_REQUEST })
	return campaignApi.updateCampaign(campaign)
		.then(r => {
			let data = normalize(r, schema.campaign)
			dispatch({ type: UPDATE_CAMPAIGN_SUCCESS, data })
			return r
		}).catch(err => {
			if (err.response.status === 409) {
				dispatch({ type: UPDATE_CAMPAIGN_FAILURE_DUPLICATE, err })
			} else {
				dispatch({ type: UPDATE_CAMPAIGN_FAILURE, err })
			}
			throw err
		})
}

export const SAVE_CAMPAIGN_TEMPLATESETTINGS_REQUEST = 'SAVE_CAMPAIGN_TEMPLATESETTINGS_REQUEST'
export const SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS = 'SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS'
export const SAVE_CAMPAIGN_TEMPLATESETTINGS_FAILURE = 'SAVE_CAMPAIGN_TEMPLATESETTINGS_FAILURE'

export const saveCampaignTemplateSettings = (campaignId, settings) => (dispatch, getState) => {
	dispatch({ type: SAVE_CAMPAIGN_TEMPLATESETTINGS_REQUEST })
	return campaignApi.saveCampaignTemplateSettings(campaignId, settings)
		.then(r => {
			dispatch({ type: SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS, campaignId: campaignId, key: 'template', settings: r })
			return r
		}).catch(err => {
			dispatch({ type: SAVE_CAMPAIGN_TEMPLATESETTINGS_FAILURE, err })
			throw err
		})
}

export const SAVE_CAMPAIGN_MESSAGINGSETTINGS_REQUEST = 'SAVE_CAMPAIGN_MESSAGINGSETTINGS_REQUEST'
export const SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS = 'SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS'
export const SAVE_CAMPAIGN_MESSAGINGSETTINGS_FAILURE = 'SAVE_CAMPAIGN_MESSAGINGSETTINGS_FAILURE'

export const saveCampaignMessagingSettings = (campaignId, settings) => (dispatch, getState) => {
	dispatch({ type: SAVE_CAMPAIGN_MESSAGINGSETTINGS_REQUEST })
	return campaignApi.saveCampaignMessagingSettings(campaignId, settings)
		.then(r => {
			dispatch({ type: SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS, campaignId: campaignId, key: 'messaging', settings: r })
			return r
		}).catch(err => {
			dispatch({ type: SAVE_CAMPAIGN_MESSAGINGSETTINGS_FAILURE, err })
			throw err
		})
}

export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST'
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS'
export const DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE'

export const deleteCampaign = campaign => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_CAMPAIGN_REQUEST })
	return campaignApi.deleteCampaign(campaign)
		.then(r => {
			dispatch({ type: DELETE_CAMPAIGN_SUCCESS, data: campaign })
			return r
		}).catch(err => {
			dispatch({ type: DELETE_CAMPAIGN_FAILURE, err })
			throw err
		})
}

export const SET_CAMPAIGN_OCCUPATIONS_REQUEST = 'SET_CAMPAIGN_OCCUPATIONS_REQUEST'
export const SET_CAMPAIGN_OCCUPATIONS_SUCCESS = 'SET_CAMPAIGN_OCCUPATIONS_SUCCESS'
export const SET_CAMPAIGN_OCCUPATIONS_FAILURE = 'SET_CAMPAIGN_OCCUPATIONS_FAILURE'

export const setCampaignOccupations = (campaignId, occupationMappings) => (dispatch, getState, { schema }) => {
	dispatch({ type: SET_CAMPAIGN_OCCUPATIONS_REQUEST })
	return campaignApi.setCampaignOccupations(campaignId, occupationMappings)
		.then(response => {
			dispatch({ type: SET_CAMPAIGN_OCCUPATIONS_SUCCESS, campaignId: campaignId, occupationMappings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: SET_CAMPAIGN_OCCUPATIONS_FAILURE, err })
			throw err
		})
}

export const updateCampaignState = (state, action) => {
	var updated = { ...state.db[action.data.result], ...action.data.entities.campaigns[action.data.result] }
	return { ...state, db: { ...state.db, [action.data.result]: updated }}
}

export const mergeSettings = (state, campaignId, settingsKey, settings) => {
	var campaign = { ...state.db[campaignId] }
	if (!campaign.settings)
		campaign.settings = {}
	campaign.settings = { ...campaign.settings, [settingsKey]: settings }
	return {
		...state,
		db: {
			...state.db,
			[campaignId]: campaign
		}
	}
}

export const updateCampaignOccupationsState = (state, campaignId, occupationMappings) => {
	var campaign = { ...state.db[campaignId] }
	if (!campaign.occupationMappings)
		campaign.occupationMappings = []
	campaign.occupationMappings = occupationMappings
	return {
		...state,
		db: {
			...state.db,
			[campaignId]: campaign
		}
	}
}

export const STATE_KEY = 'campaigns'

export const DEFAULT_STATE = {
	db: {}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case UPDATE_CAMPAIGN_SUCCESS:
		case CREATE_CAMPAIGN_SUCCESS:
		case FETCH_CAMPAIGN_SUCCESS:
			return updateCampaignState(state, action)

		case FETCH_LISTING_CAMPAIGNS_SUCCESS:
			return {
				...state,
				db: {
					...state.db,
					...action.data.entities.campaigns
				}
			}

		case DELETE_CAMPAIGN_SUCCESS:
			let deleteCampaignId = action.data.id.toString()
			let { [deleteCampaignId]: deletedCampaign, ...rest } = state.db
			return { ...state, db: rest }

		case FETCH_CAMPAIGN_TEMPLATESETTINGS_SUCCESS:
		case SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS:
		case FETCH_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS:
		case SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS:
			return mergeSettings(state, action.campaignId, action.key, action.settings)

    case SET_CAMPAIGN_OCCUPATIONS_SUCCESS:
			return updateCampaignOccupationsState(state, action.campaignId, action.occupationMappings)

		default:
			return state
	}
}

export default reducer

export const selectCampaignsHashMap = state => state.verification.campaigns.db

export const selectCampaign = id => createSelector(selectCampaignsHashMap, campaigns => campaigns[id])

export const selectCampaigns = createSelector(
	selectCampaignsHashMap,
	campaigns => Object.keys(campaigns).map(k => campaigns[k])
)

export const selectCampaignsByListingId = listingId => createSelector(selectCampaigns, campaigns => campaigns.filter(r => r.listingId === listingId))