import React, {useState} from 'react'
import { compose, withState } from 'recompose'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '../../../Fields/TextField'
import FileInput from '../../../Fields/FileInput'
import { filesizeWithinLimits } from '../../../../validation'
import { withStyles } from '@mui/styles'
import PhotoIcon from '@mui/icons-material/Photo';
import Typography from '@mui/material/Typography'
import DialogSubsectionHeadline from '../../../Header/DialogSubsectionHeadline'
import TextArea from '../../../Fields/TextArea'
import { updatePromoTileImage,updatePromoSquareImage } from '../../../../redux/modules/affiliates/affiliates'
import { useDispatch } from 'react-redux'

const fileSizeCheck = filesizeWithinLimits(1)

const styles = {
	root: {
		color: '#777',
	},
	focused: {
		'&$focused': {
			color: '#00a1df',
		},
	},
	inputLabel: {
		margin: '20px 0 0',
		fontSize: '14px',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
	},
	inputDescription: {
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
	},
	imageUploadContainerEmpty: {
		margin: '15px 0 0',
		textAlign: 'center',
		border: '2px solid #ddd',
		padding: '15px',
	},
	imageUploadContainer: {
		margin: '15px 0 0',
		textAlign: 'right',
	},
	pictureContainer: {
		border: '2px solid #ddd',
		padding: '15px',
		textAlign: 'center',
	},
	previewImage: {
		backgroundColor: '#d9d9d9',
		maxWidth: '100%',
	},
	changeLogoLink: {
		textTransform:'none',
		color: '#0E5777',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		fontWeight: 400,
		textDecoration: 'none',
		cursor: 'pointer',

		'&:hover, &:focus': {
			color: '#000',
			background: 'none',
		},
	},
	changeLogoButton: {
		textTransform:'none',
		color:'#222',
		fontFamily: 'Roboto Regular,Helvetica,sans-serif',
		padding: '8px 10px',
		border: '1px solid #222'
	},
	textContainer: {
		padding: '15px 0 0',
		position: 'relative',
	},
	textAreaWithCounter: {
		'& > div': {
			padding: '24px 12px 60px !important',
		},
	},
	flexButtonContainer: {
		display: 'flex',
		float:'right'
	},  
	deleteButton: {
		marginLeft: '10px !important'
	},
}


const MarketingAssetsFormPartial = ({
	taglineCharacterCount,
	setTaglineCharacterCount,
	affiliate,
	initialValues,
	fieldValues,
	classes,
	canUpdate,
}) => {
	const [fieldValuesState,setFieldValuesState]=useState(initialValues);
	const dispatch = useDispatch();
	const handleDeleteImage = (imageType) => {
        if(imageType === 'promoTile'){
			dispatch(updatePromoTileImage(null, fieldValuesState.promoTileImageId, affiliate.id));
		}
		else if (imageType === 'promoSquare'){
			dispatch(updatePromoSquareImage(null, fieldValuesState.promoSquareImageId,affiliate.id));
		}
    };
	const handleEdit = (imageType, file) => {
		if(imageType === 'promoTile'){
			setFieldValuesState(prevState => ({
				...prevState,
				promoTileImageUrl: file,
			}));
			dispatch(updatePromoTileImage(file, 0, affiliate.id));
		}
		else if (imageType === 'promoSquare'){
			setFieldValuesState(prevState => ({
				...prevState,
				promoSquareImageUrl: file,
			}));
			dispatch(updatePromoSquareImage(file, 0 ,affiliate.id));
		}
	}

	return(
		<div style={{ fontSize: '14px', minHeight:'55vh' }}>
		<Grid container>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Promo Square</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Dimensions: 500 x 500px</p>
						<p className={ classes.inputDescription }>File types: JPEG, JPG, PNG</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<div className={fieldValuesState.promoSquareImageUrl ? classes.imageUploadContainer : classes.imageUploadContainerEmpty}>
					{fieldValuesState.promoSquareImageUrl &&
						<div className={classes.pictureContainer}>
							{ (typeof fieldValuesState.promoSquareImageUrl !== 'string') &&
								<div>
									<img src={URL.createObjectURL(fieldValuesState.promoSquareImageUrl)} className={classes.previewImage} style={{ }} />
									<img id='promoSquareImagePreview' src={URL.createObjectURL(fieldValuesState.promoSquareImageUrl)} style={{ visibility: 'hidden', position: 'absolute' }} />
								</div>
							}
							{ (typeof fieldValuesState.promoSquareImageUrl === 'string') &&
								<img src={fieldValuesState.promoSquareImageUrl} className={classes.previewImage} style={{ }} />
							}
						</div>
					}
					{!fieldValuesState.promoSquareImageUrl &&
						<div>
							<PhotoIcon
								style={{
									width: '36px',
									height: '36px',
									margin: '0px',
									padding: '0px',
									position: 'static',
									fill: '#ddd',
								}}
							/>
							<p style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Upload an image</p>
							<p style={{ fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Supports: JPEG, JPG, PNG</p>
						</div>
					}
					<div className={fieldValuesState.promoSquareImageUrl ? classes.flexButtonContainer : root}>
						<FileInput
							name="promoSquareImageUrl"
							validate={[fileSizeCheck]}
							onChange={(event) => {
								handleEdit('promoSquare', event)
							}}
							label={
								<Button component='span' className={fieldValuesState.promoSquareImageUrl ? classes.changeLogoLink : classes.changeLogoButton}>
									{fieldValuesState.promoSquareImageUrl ? 'Edit image' : 'Browse Files'}
								</Button>
							}
						/>
						{fieldValuesState?.promoSquareImageUrl && fieldValuesState?.promoSquareImageId > 0 &&
							<Button
								component='span'
								className={classes.deleteButton}
								onClick={() => {
									setFieldValuesState((prev)=>{
										return{
											...prev,
											promoSquareImageUrl:null,
											promoSquareImageId:0
										}
									})
									handleDeleteImage('promoSquare')}}>
								Delete Image
							</Button>}
					</div>

				</div>
  			</Grid>
		</Grid>
		<Grid container style={{ marginTop: '25px '}}>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Promo Tile</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>Dimensions: 585 x 360px</p>
						<p className={ classes.inputDescription }>File types: JPEG, JPG, PNG</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<div className={fieldValuesState.promoTileImageUrl ? classes.imageUploadContainer : classes.imageUploadContainerEmpty}>
					{fieldValuesState.promoTileImageUrl &&
						<div className={classes.pictureContainer}>
							{ (typeof fieldValuesState.promoTileImageUrl !== 'string') &&
								<div>
									<img src={URL.createObjectURL(fieldValuesState.promoTileImageUrl)} className={classes.previewImage} style={{ }} />
									<img id='promoTileImagePreview' src={URL.createObjectURL(fieldValuesState.promoTileImageUrl)} style={{ visibility: 'hidden', position: 'absolute' }} />
								</div>
							}
							{ (typeof fieldValuesState.promoTileImageUrl === 'string') &&
								<img src={fieldValuesState.promoTileImageUrl} className={classes.previewImage} style={{ }} />
							}
						</div>
					}
					{!fieldValuesState.promoTileImageUrl &&
						<div style={{ textAlign: 'center' }}>
							<PhotoIcon
								style={{
									width: '36px',
									height: '36px',
									margin: '0px',
									padding: '0px',
									position: 'static',
									fill: '#ddd',
								}}
							/>
							<p style={{ fontWeight: '600', fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Upload an image</p>
							<p style={{ fontSize: '13px', fontFamily: 'Roboto Regular,Helvetica,sans-serif' }}>Supports: JPEG, JPG, PNG</p>
						</div>
					}
					<div className={fieldValuesState.promoTileImageUrl ? classes.flexButtonContainer : root}>
						<FileInput
							name="promoTileImageUrl"
							validate={[fileSizeCheck]}
							onChange={(event) => {
								handleEdit('promoTile',event)
							}}
							label={
								<Button component='span' className={fieldValuesState.promoTileImageUrl ? classes.changeLogoLink : classes.changeLogoButton}>
									{fieldValuesState.promoTileImageUrl ? 'Edit image' : 'Browse Files'}
								</Button>
							}
						/>
						{fieldValuesState?.promoTileImageUrl && fieldValuesState?.promoTileImageId > 0 &&
							<Button
								component='span'
								className={classes.deleteButton}
								onClick={() => {
									setFieldValuesState((prev)=>{
										return{
											...prev,
											promoTileImageUrl:null,
											promoTileImageId:0
										}
									})
									handleDeleteImage('promoTile')}}>
								Delete Image
							</Button>}
					</div>
				</div>
  			</Grid>
		</Grid>

		<Grid container style={{ marginTop: '25px '}}>
			<Grid item xs={4}>
				<Grid container>
					<Grid item xs={10}>
						<DialogSubsectionHeadline>Tagline</DialogSubsectionHeadline>
					</Grid>
					<Grid item xs={10}>
						<p className={ classes.inputDescription }>This tagline will be used on marketing your brand in the marketplace.</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={8} className={ classes.textContainer }>
				<TextArea
					name='tagline'
					maxLength="70"
					inputProps={{ maxLength: 70 }}
					onChange={(e, v) => setTaglineCharacterCount(e.target.value.length)}
					multiline={true}
					rows = {1}
					className={ classes.textAreaWithCounter }
					InputLabelProps={{
						classes: {
							root: classes.root,
							focused: classes.focused,
						},
					}}
				/>
				<Typography>
					<small style={{ position: 'absolute', bottom: '15px', right: '10px' }}>
						{taglineCharacterCount} of 70
					</small>
				</Typography>
  			</Grid>
		</Grid>
	</div>
	
)}

const enhance = compose(
	withState(
		'taglineCharacterCount',
		'setTaglineCharacterCount',
		props =>
			props.initialValues && props.initialValues.tagline ? props.initialValues.tagline.length : 0
	),
	withStyles(styles)
)

export default enhance(MarketingAssetsFormPartial)
