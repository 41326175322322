import React from 'react'
import { RouteConstants } from '../../../setup/routeConstants'
import { ACCESS_EVENTS_LIST_PAGE, ACCESS_LISTING_INVOICES, ACCESS_LISTING_LIST, DEVELOPER_ACCESS } from '../../../auth/policies'
import LazyProtectedRoute from '../../LazyProtectedRoute'
import Development from '../../../components/Partners/Listing/Tabs/Development'
import OffersTab from '../../../components/Partners/Listing/Tabs/Offers'
import { Redirect } from 'react-router-dom'
import GovXIDTab from '../../../components/Partners/Listing/Tabs/GovXID'
import SettingsTab from '../../../components/Partners/Listing/Tabs/Settings/SettingsTab'
import MarketplaceTab from '../../../components/Partners/Listing/Tabs/Marketplace'
import CustomizePageSection from '../../../components/Partners/Listing/Tabs/Marketplace/CustomizePageSection'
import MarketingAssetsSection from '../../../components/Partners/Listing/Tabs/Marketplace/MarketingAssetsSection'
import BillingSection from '../../../components/Partners/Listing/Tabs/Marketplace/BillingSection'
import EventsSection from '../../../components/Partners/Listing/Tabs/Offers/EventsSection'
import OffersSection from '../../../components/Partners/Listing/Tabs/Offers/OffersSection'
import BillingTab from '../../../components/Partners/Listing/Tabs/Billing'

const PartnersListingComponent = React.lazy(() => import("../../../components/Partners/Listing"))

export default {
	path: RouteConstants.partners.listings.listing.path,
	element: (
		<LazyProtectedRoute
			component={<PartnersListingComponent />}
		/>
	),
	children: [
		{
			path: "",
			element: <LazyProtectedRoute component={<OffersTab />} requiredPolicies={[]} />
		},
		{
			path: "development",
			element: <LazyProtectedRoute component={<Development />} requiredPolicies={[DEVELOPER_ACCESS]} />
		},
		{
			path: "offers",
			element: <LazyProtectedRoute component={<OffersTab />} requiredPolicies={[]} />,
			children: [
				{
					path: "",
					element: <LazyProtectedRoute component={<OffersSection />} requiredPolicies={[]} />,
				},
				{
					path: "offers",
					element: <LazyProtectedRoute component={<OffersSection />} requiredPolicies={[]} />,
				},
				{
					path: "events",
					element: <LazyProtectedRoute component={<EventsSection />} requiredPolicies={[]} />,
				},
			]
		},
		{
			path: "govx-id",
			element: <LazyProtectedRoute component={<GovXIDTab />} requiredPolicies={[]} />,
		},
		{
			path: "settings",
			element: <LazyProtectedRoute component={<SettingsTab />} requiredPolicies={[]} />,
		},
		{
			path: "marketplace",
			element: <LazyProtectedRoute component={<MarketplaceTab />} requiredPolicies={[]} />,
			children: [
				{
					path: "",
					element: <LazyProtectedRoute component={<CustomizePageSection />} requiredPolicies={[]} />,
				},
				{
					path: "customize-page",
					element: <LazyProtectedRoute component={<CustomizePageSection />} requiredPolicies={[]} />,
				},
				{
					path: "marketing-assets",
					element: <LazyProtectedRoute component={<MarketingAssetsSection />} requiredPolicies={[]} />,
				},
				{
					path: "billing/*",
					element: <LazyProtectedRoute component={<BillingSection />} requiredPolicies={[]} />,
				},
			]
		},
		{
			path: "billing/invoices",
			element: <LazyProtectedRoute component={<BillingTab />} requiredPolicies={[ACCESS_LISTING_INVOICES]} />,
		},
	]
}