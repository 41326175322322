import React from 'react'

function ErrorScreen() {
  return (
    <div>
      <h1 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Oops, an unexpected error has occurred.</h1>
      <p>Please refresh the page.</p>
    </div>
  )
}

export default ErrorScreen
