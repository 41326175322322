/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import copy from 'copy-to-clipboard'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { showModal, showSuccesNotification } from '../../../../../../redux/actions'
import DialogSubsectionHeadline from '../../../../../Header/DialogSubsectionHeadline'

export function SecretKeySection({ listing }) {
  const dispatch = useDispatch()

  const regenerateSecretKey = () => dispatch(showModal('SHOW_SECRET_KEY', { listing }))
  const copyClientId = (clientId) => {
    copy(clientId)
    dispatch(showSuccesNotification('Client ID copied to clipboard'))
  }

  return (
    <div style={{ paddingBottom: '25px' }}>
      <div>
        {listing.id && (
        <Box display="flex" gap="2rem">
          <DialogSubsectionHeadline style={{ paddingBottom: '0px' }}>Verification ID</DialogSubsectionHeadline>
          <Box style={{ display: 'grid', alignItems: 'center' }}>{listing.id}</Box>
        </Box>
        )}
      </div>
      <div>
        {listing.clientId && (
        <Box display="flex" alignItems="center" gap="2rem">
          <div>
            <DialogSubsectionHeadline>Client ID</DialogSubsectionHeadline>
          </div>
          <div>
            {listing.clientId}
            <IconButton style={{ verticalAlign: 'middle' }} onClick={() => copyClientId(listing.clientId)}>
              <ContentCopyIcon />
            </IconButton>
          </div>
        </Box>
        )}
      </div>
      <div>
        <div>
          <Box>
            <DialogSubsectionHeadline>Secret Key</DialogSubsectionHeadline>
            <Box sx={{ fontSize: '0.875rem', maxWidth: '650px' }}>
              Your secret key is unique to your listing and is required when requesting the token to access the Authorization API. You will pass the secret key as part of the request to our token endpoint.
            </Box>
            <Button variant="contained" color="primary" style={{ textTransform: 'none', marginTop: '16px' }} onClick={regenerateSecretKey}>
              Regenerate Secret Key
            </Button>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default SecretKeySection
