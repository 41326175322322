import { occupation } from '../../../api/schema'
import * as occupationsApi from '../../../api/occupationsApi'
import { normalize, denormalize } from 'normalizr'
import { createSelector } from 'reselect'

export const FETCH_OCCUPATIONS_REQUEST = 'FETCH_OCCUPATIONS_REQUEST'
export const FETCH_OCCUPATIONS_SUCCESS = 'FETCH_OCCUPATIONS_SUCCESS'
export const FETCH_OCCUPATIONS_FAILURE = 'FETCH_OCCUPATIONS_FAILURE'

export const FETCH_PUBLISHED_OCCUPATIONIDS_REQUEST = 'FETCH_PUBLISHED_OCCUPATIONIDS_REQUEST'
export const FETCH_PUBLISHED_OCCUPATIONIDS_SUCCESS = 'FETCH_PUBLISHED_OCCUPATIONIDS_SUCCESS'
export const FETCH_PUBLISHED_OCCUPATIONIDS_FAILURE = 'FETCH_PUBLISHED_OCCUPATIONIDS_FAILURE'

export const FETCH_PUBLIC_OCCUPATIONIDS_REQUEST = 'FETCH_PUBLIC_OCCUPATIONIDS_REQUEST'
export const FETCH_PUBLIC_OCCUPATIONIDS_SUCCESS = 'FETCH_PUBLIC_OCCUPATIONIDS_SUCCESS'
export const FETCH_PUBLIC_OCCUPATIONIDS_FAILURE = 'FETCH_PUBLIC_OCCUPATIONIDS_FAILURE'

export const FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_REQUEST = 'FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_REQUEST'
export const FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_SUCCESS = 'FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_SUCCESS'
export const FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_FAILURE = 'FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_FAILURE'

export const UPDATE_OCCUPATION_REQUEST = 'UPDATE_OCCUPATION_REQUEST'
export const UPDATE_OCCUPATION_SUCCESS = 'UPDATE_OCCUPATION_SUCCESS'
export const UPDATE_OCCUPATION_FAILURE = 'UPDATE_OCCUPATION_FAILURE'

export const UPDATE_OCCUPATIONS_REQUEST = 'UPDATE_OCCUPATIONS_REQUEST'
export const UPDATE_OCCUPATIONS_SUCCESS = 'UPDATE_OCCUPATIONS_SUCCESS'
export const UPDATE_OCCUPATIONS_FAILURE = 'UPDATE_OCCUPATIONS_FAILURE'

export const UPDATE_OCCUPATION_INFOANDTYPES_REQUEST = 'UPDATE_OCCUPATION_INFOANDTYPES_REQUEST'
export const UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS = 'UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS'
export const UPDATE_OCCUPATION_INFOANDTYPES_FAILURE = 'UPDATE_OCCUPATION_INFOANDTYPES_FAILURE'

export const requireOccupations = (dispatch, getState, { schema }, asset) => {
	let currentState = getState()
	 fetchPublishedOccupations(dispatch, getState, { schema })
	if (selectOccupations(currentState).length !== 0)
		return new Promise(resolve => resolve())
	return fetchOccupations()(dispatch, getState, { schema })
}

export const fetchOccupations = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_OCCUPATIONS_REQUEST })
	return occupationsApi.fetchOccupations()
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: FETCH_OCCUPATIONS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_OCCUPATIONS_FAILURE, err })
			throw err
		})
}

export const fetchPublishedOccupations = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_PUBLISHED_OCCUPATIONIDS_REQUEST })
	return occupationsApi.fetchPublishedOccupations()
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: FETCH_PUBLISHED_OCCUPATIONIDS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_PUBLISHED_OCCUPATIONIDS_FAILURE, err })
			throw err
		})
}

export const fetchPublicOccupations = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_PUBLIC_OCCUPATIONIDS_REQUEST })
	return occupationsApi.fetchPublicOccupations()
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: FETCH_PUBLIC_OCCUPATIONIDS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_PUBLIC_OCCUPATIONIDS_FAILURE, err })
			throw err
		})
}

export const fetchPublicAndPublishedOccupations = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_REQUEST })
	return occupationsApi.fetchPublicAndPublishedOccupations()
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_FAILURE, err })
			throw err
		})
}

export const updateOccupation = occupation => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_OCCUPATION_REQUEST })
	return occupationsApi.updateOccupation(occupation)
		.then(response => {
			const data = normalize(response, schema.occupation)
			dispatch({ type: UPDATE_OCCUPATION_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_OCCUPATION_FAILURE, err })
			throw err
		})
}

export const updateOccupationDisplayOrders = occupationDisplayOrders => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_OCCUPATIONS_REQUEST })
	return occupationsApi.updateOccupationDisplayOrders(occupationDisplayOrders)
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: UPDATE_OCCUPATIONS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_OCCUPATIONS_FAILURE, err })
			throw err
		})
}

export const updateOccupationInfoAndTypes = occupationInfoAndTypes => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_OCCUPATION_INFOANDTYPES_REQUEST })
	return occupationsApi.updateOccupationInfoAndTypes(occupationInfoAndTypes)
		.then(response => {
			const data = normalize(response, { occupation: schema.occupation, occupationInfoModel: schema.occupationInfoModel, allowedVerificationTypeGroup: schema.allowedVerificationTypeGroup })
			dispatch({ type: UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS, data })
			return dispatch(fetchPublishedOccupations())
				.then(fetchResponsePublished => {
					return dispatch(fetchPublicOccupations())
						.then(fetchResponsePublic => {
							return response
						})
				})
		})
		.catch(err => {
			dispatch({ type: UPDATE_OCCUPATION_INFOANDTYPES_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'occupations'

export const DEFAULT_STATE = {
	db: {},
	hierarchy: [],
	publishedDb: {},
	publishedHierarchy: [],
	publicDb: {},
	publicHierarchy: [],
	publicAndPublishedDb: {},
	publicAndPublishedHierarchy: []
}

export const mergeOccupations = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.occupations }}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case UPDATE_OCCUPATION_SUCCESS:
		case UPDATE_OCCUPATIONS_SUCCESS:
		case UPDATE_OCCUPATION_INFOANDTYPES_SUCCESS:
			return mergeOccupations(state, action)
		case FETCH_OCCUPATIONS_SUCCESS:
			return { ...state, db: action.data.entities.occupations, hierarchy: action.data.result }
		case FETCH_PUBLISHED_OCCUPATIONIDS_SUCCESS:
			return { ...state, publishedDb: action.data.entities.occupations, publishedHierarchy: action.data.result }
		case FETCH_PUBLIC_OCCUPATIONIDS_SUCCESS:
			return { ...state, publicDb: action.data.entities.occupations, publicHierarchy: action.data.result }
		case FETCH_PUBLIC_AND_PUBLISHED_OCCUPATIONIDS_SUCCESS:
			return { ...state, publicAndPublishedDb: action.data.entities.occupations, publicAndPublishedHierarchy: action.data.result }
		default:
			return state
	}
}

export default reducer

export const selectOccupationsHashMap = state => state.verification.occupations.db
export const selectHierarchy = state => state.verification.occupations.hierarchy
export const selectPublishedOccupationsHashMap = state => state.verification.occupations.publishedDb
export const selectPublishedHierarchy = state => state.verification.occupations.publishedHierarchy
export const selectPublicOccupationsHashMap = state => state.verification.occupations.publicDb
export const selectPublicHierarchy = state => state.verification.occupations.publicHierarchy
export const selectPublicAndPublishedOccupationsHashMap = state => state.verification.occupations.publicAndPublishedDb
export const selectPublicAndPublishedHierarchy = state => state.verification.occupations.publicAndPublishedHierarchy

export const selectOccupations = createSelector(
	selectOccupationsHashMap,
	occupations => Object.keys(occupations).map(k => occupations[k])
)

export const selectOccupationsHierarchy = createSelector(
	[selectHierarchy, selectOccupationsHashMap],
	(hierarchy, occupations) => {
		let results = denormalize(hierarchy, [occupation], { occupations })
		return results
	}
)

export const selectPublishedOccupations = createSelector(
	selectPublishedOccupationsHashMap,
	occupations => Object.keys(occupations).map(k => occupations[k])
)

export const selectPublishedOccupationsHierarchy = createSelector(
	[selectPublishedHierarchy, selectPublishedOccupationsHashMap],
	(publishedHierarchy, occupations) => {
		let results = denormalize(publishedHierarchy, [occupation], { occupations })
		return results
	}
)

export const selectPublicOccupations = createSelector(
	selectPublicOccupationsHashMap,
	occupations => Object.keys(occupations).map(k => occupations[k])
)

export const selectPublicOccupationsHierarchy = createSelector(
	[selectPublicHierarchy, selectPublicOccupationsHashMap],
	(publicHierarchy, occupations) => {
		let results = denormalize(publicHierarchy, [occupation], { occupations })
		return results
	}
)

export const selectPublicAndPublishedOccupations = createSelector(
	selectPublicAndPublishedOccupationsHashMap,
	occupations => Object.keys(occupations).map(k => occupations[k])
)

export const selectPublicAndPublishedOccupationsHierarchy = createSelector(
	[selectPublicAndPublishedHierarchy, selectPublicAndPublishedOccupationsHashMap],
	(publicAndPublishedHierarchy, occupations) => {
		let results = denormalize(publicAndPublishedHierarchy, [occupation], { occupations })
		return results
	}
)

export const selectOccupation = id => {
	return createSelector(selectOccupationsHashMap, occupations => occupations[id])
}

export const selectPublishedOccupation = id => {
	return createSelector(selectPublishedOccupationsHashMap, occupations => occupations[id])
}