import { getJson, postJson, putJson } from './baseApi'

export const fetchAffiliates = search => {
	let query = ''
	// paging
	query += 'pageSize=' + search.paging.pageSize
	let pageIndex = search.paging.pageIndex > -1 ? search.paging.pageIndex : 0
	query += '&pageIndex=' + pageIndex
	//affiliateStatuses
	search.filter.affiliateStatuses.statuses.filter(m => m.selected).forEach((m, i) => {
		query += '&affiliateStatuses.statuses[' + i +']=' + m.type
	})

	return getJson(`/api/marketplace/affiliates?${query}`)
}

export const fetchAffiliate = (affiliateId) => {
	return getJson(`/api/marketplace/affiliates/${affiliateId}`)
}

export const fetchAffiliateByListingId = (listingId) => {
	return getJson(`/api/marketplace/affiliates/listing/${listingId}`)
}

export const fetchAllAffiliates = search => {
	return getJson('/api/marketplace/affiliates/all')
}

export const updateAffiliateStatus = (affiliateId, status) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/update-affiliate-status?status=${status}`)
}

export const createAffiliateWithStatus = (partnerListingId, status) => {
	return postJson(`/api/marketplace/affiliates/listing/${partnerListingId}?status=${status}`)
}

export const updateAffiliateAllowProductPulls = (affiliateId, allowProductPulls) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/update-affiliate-allow-product-pulls?allowProductPulls=${allowProductPulls}`)
}

export const getAffiliateMarketplacePageSettings = affiliateId => {
	return getJson(`/api/marketplace/affiliates/${affiliateId}/page-info`)
}

export const updateAffiliateMarketplacePageSettings = (affiliateId, settings) => {
	return putJson(`/api/marketplace/affiliates/${affiliateId}/page-info`, settings)
}

export const getAffiliateMarketingAssetsSettings = affiliateId => {
	return getJson(`/api/marketplace/affiliates/${affiliateId}/marketing-assets`)
}

export const updateAffiliateMarketingAssetsSettings = (affiliateId, settings) => {
	return putJson(`/api/marketplace/affiliates/${affiliateId}/marketing-assets`, settings)
}

export const syncProducts = (affiliateId, command) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/sync-products`, command)
}

export const getSyncProductsStatus = (affiliateId, command) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/sync-products-status`, command)
}

export const syncOrders = (affiliateId, command) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/sync-orders`, command)
}

export const syncCollections = (affiliateId, command) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/sync-collections`, command)
}

export const syncMarketing = (listingId) => {
	return postJson(`/api/marketplace/affiliates/listing/${listingId}/sync-marketing-partner`)
}

export const curateProducts = (affiliateId, command) => {
	return postJson(`/api/marketplace/affiliates/${affiliateId}/curate-products`, command)
}
