import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TextAreaInput from '../Inputs/TextArea'
import FormHelperText from '@mui/material/FormHelperText'
export const renderTextArea = ({ input, label, fullWidth = true, rows, placeholder, meta: { touched, error }, defaultValue, maxLength, ...custom }) => (
<div>
	<TextAreaInput
		{ ...input }
		fullWidth={ fullWidth }
		multiline
		variant='filled'
		label={ label }
		placeholder={ placeholder }
		error={ touched && !!error }
		rows={rows}
		value={ input.value ? (input.value !== '' ? input.value : defaultValue) : defaultValue }
		maxLength={ maxLength }
		{ ...custom } />
		{touched && error && <FormHelperText error={true}>{error}</FormHelperText>}
</div>
)

const TextArea = ({ name, label, fullWidth, placeholder, validate, rows, maxLength, ...custom }) => (
	<Field
		name={ name }
		label={ label }
		fullWidth={ fullWidth }
		placeholder={ placeholder }
		component={ renderTextArea }
		validate={ validate }
		maxLength={ maxLength }
		{ ...custom } />
)

TextArea.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	validate: PropTypes.array
}

export default TextArea
