/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import ModalContainer from '../../Modals/ModalContainer'

function DeleteCodesModal({
  onClickDelete,
  onClickCancel,
  codes,
}) {
  const [processing, setProcessing] = useState(false)

  const onSubmit = async () => {
    try {
      setProcessing(true)
      await onClickDelete()
    } catch (error) {
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <ModalContainer
      processing={processing}
      title="Are you sure?"
      maxWidth="sm"
      fullWidth="true"
      hideModal={() => { }}
      actions={{
        primaryAction: { label: 'Delete', onClick: onSubmit },
        secondaryAction: { label: 'Cancel', onClick: onClickCancel },
      }}
    >
      <Typography component="div">
        <Box>
          You are about to delete
          {' '}
          {codes.length}
          {' '}
          code
          {codes.length > 1 ? 's' : ''}
          {' '}
          from this pool. This action cannot be undone.
        </Box>
      </Typography>
    </ModalContainer>
  )
}

export default DeleteCodesModal
