import { createSlice } from "@reduxjs/toolkit";
import { api } from './apiSlice'
import { optimisticCreate, optimisticDelete, optimisticPublishUnpublish } from "./optimisticUpdates/offersOptimisticUpdates";

export const offersApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getOffersByPartnerListingId: build.query({
            query: partnerListingId => `/offers/listing/${partnerListingId}`,
            providesTags: ['Offer'],
            transformResponse: (response) => {
                const offers = response ?
                    response.map(offer => ({ ...offer, billingRules: { ...offer.billingRules, commissionRate: Number(Number(offer.billingRules.commissionRate).toFixed(2)) } })) : []
                return offers
            }
        }),
        getOffersByCodePoolId: build.query({
            query: codePoolId => `/offers/bycodepool/${codePoolId}/published`,
            providesTags: ['OffersByCodePool']
        }),
        getOfferById: build.query({
            query: offerId => `/offers/${offerId}`,
            providesTags: ['Offer'],
            transformResponse: (response) => {
                return response.billingRules ? { ...response, billingRules: { ...response.billingRules, commissionRate: Number(Number(response.billingRules.commissionRate).toFixed(2)) } } : response
            }
        }),
        getBillingRulesForOffer: build.query({
            query: offer => `/resources/billing-rules/listing/${offer.listingId}/offer/${offer.offerId}`,
            providesTags: ['OfferBillingRules'],
            transformResponse: (response) => {
                return { ...response, commissionRate: Number(Number(response.commissionRate).toFixed(2)) }
            }

        }),
        createOffer: build.mutation({
            query: newOffer => ({
                url: 'offers',
                method: 'POST',
                body: newOffer,
                responseHandler: 'text',
            }),
            invalidatesTags: ['Offer', 'OffersByCodePool'],
        }),
        updateOffer: build.mutation({
            query: updatedOffer => ({
                url: `/offers/${updatedOffer.offerId}`,
                method: 'PUT',
                body: updatedOffer,
            }),
            invalidatesTags: ['Offer'],
        }),
        createSyndicateOffer: build.mutation({
            query: ({ offerId, listingId }) => ({
                url: '/offers/syndicate',
                method: 'POST',
                body: { offerId, listingId },
                responseHandler: 'text',
            }),
            invalidatesTags: ['SyndicateOffers'],
            transformResponse: (response) => {
                var parsedResponse = JSON.parse(response)
                return parsedResponse
            }
        }),
        updateBillingRulesforOffer: build.mutation({
            query: updatedBillingRules => ({
                url: `resources/billing-rules/listing/${updatedBillingRules.listingId}/offer/${updatedBillingRules.offerId}`,
                method: 'POST',
                body: updatedBillingRules.billingRules,
            }),
            invalidatesTags: ['OfferBillingRules'],
        }),
        updateSalesChannels: build.mutation({
            query: ({ offerId, channels }) => ({
                url: `/offers/${offerId}/channels`,
                method: 'PATCH',
                body: { channels },
            }),
            invalidatesTags: ['Offer'],
        }),
        updateInfo: build.mutation({
            query: ({ offerId, name, description, link, tags }) => ({
                url: `/offers/${offerId}/info`,
                method: 'PATCH',
                body: { name, description, link, tags },
            }),
            invalidatesTags: ['Offer'],
        }),
        publishOffer: build.mutation({
            query: offer => ({
                url: `/offers/${offer.offerId}/published-status`,
                method: 'PATCH',
                body: { published: true },
            }),

            // optimistic update to listing
            onQueryStarted: optimisticPublishUnpublish(true),
        }),
        unpublishOffer: build.mutation({
            query: offer => ({
                url: `/offers/${offer.offerId}/published-status`,
                method: 'PATCH',
                body: { published: false },
            }),
            // optimistic update to listing
            onQueryStarted: optimisticPublishUnpublish(false),
        }),
        deleteOffer: build.mutation({
            query: offer => ({
                url: `/offers/${offer.offerId}`,
                method: 'DELETE',
            }),
            // optimistic update to listing
            onQueryStarted: optimisticDelete(),
        }),
        deleteSyndicateOffer: build.mutation({
            query: syndicateOffer => ({
                url: `/offers/syndicate/${syndicateOffer.syndicateId}/${syndicateOffer.syndicateListingId}`,
                method: 'DELETE',
            }),
            // optimistic update to listing
            invalidatesTags: ['SyndicateOffers', 'Offer'],
        }),
        syncShopifyOffer: build.mutation({
            query: appId => ({
                url: `/offers/listing/shopifysync`,
                method: 'POST',
                body: { MyShopifyDomain: appId }
            })
        }),
        getOfferBillingRulesHistory: build.query({
            query: offer => `/resources/billing-rules/listing/${offer.listingId}/offer/${offer.offerId}/history`,
            transformResponse: (response) => {
                return { ...response, billingRules: response.billingRules.map(billingRules => ({ ...billingRules, commissionRate: Number(Number(billingRules.commissionRate).toFixed(2)) })) }
            }
        }),
        GetSyndicateOffersByPartnerListingId: build.query({
            query: partnerListingId => `/offers/syndicate/listing/${partnerListingId}`,
            providesTags: ['SyndicateOffers'],
            transformResponse: (response) => {
                const syndicateOffers = response ?
                    response.map(offer => ({ ...offer, billingRules: { ...offer.billingRules, commissionRate: Number(Number(offer.billingRules.commissionRate).toFixed(2)) } })) : []
                return syndicateOffers
            }
        }),
    })
})

const initialState = [];
const offersSlice = createSlice({
    name: 'offersNew',
    initialState,
    reducers: {},
})

export const {
    useGetOffersByPartnerListingIdQuery,
    useGetOfferByIdQuery,
    useGetBillingRulesForOfferQuery,
    useLazyGetOfferBillingRulesHistoryQuery,
    useLazyGetBillingRulesForOfferQuery,
    useLazyGetOfferByIdQuery,
    useDeleteOfferMutation,
    usePublishOfferMutation,
    useUnpublishOfferMutation,
    useCreateOfferMutation,
    useCreateSyndicateOfferMutation,
    useUpdateBillingRulesforOfferMutation,
    useUpdateOfferMutation,
    useUpdateSalesChannelsMutation,
    useUpdateInfoMutation,
    useSyncShopifyOfferMutation,
    useGetOffersByCodePoolIdQuery,
    useGetSyndicateOffersByPartnerListingIdQuery,
    useDeleteSyndicateOfferMutation,
} = offersApiSlice;

export default offersSlice.reducer;
