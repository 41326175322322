import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import GlobalNotification from '../GlobalNotification'
import ModalRoot from '../Modals'
import SideSheetRoot from '../SideSheets'
import Login from '../Login'
import { RouteConstants } from '../../setup/routeConstants'
import Grid from '@mui/material/Grid'
import { openDrawer, closeDrawer, fetchPolicies, fetchOccupations } from '../../redux/actions'
import { showNav as showNavSelector, hasPolicy } from '../../redux/selectors'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import NavList from '../Nav/NavList'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CssBaseline from '@mui/material/CssBaseline'
import QuickSearch from '../QuickSearch'
import AccountMenu from '../AccountMenu'
import HelpIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
	EDIT_USER_ROLES,
	DELETE_USERS,
	DEVELOPER_ACCESS,
	GLOBAL_SEARCH,
	ACCESS_USER_LIST_PAGE,
	ACCESS_USER_DETAIL_PAGE,
	ACCESS_AFFILIATE_LIST_PAGE,
	UPDATE_AFFILIATE,
	SYNC_SHOPIFY_AFFILIATE,
	EDIT_AFFILIATIONS,
	REORDER_AFFILIATION_TAXONOMY,
	ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
	ACCESS_AFFILIATIONS_PAGE,
	ACCESS_DMDC_STATUS_PAGE,
	ACCESS_VA_STATUS_PAGE,
	ACCESS_DOMAIN_WHITELIST_PAGE,
	ACCESS_DOCUMENT_RULE_SETS_PAGE,
	ACCESS_PRIVATE_AFFILIATIONS,
	ACCESS_LISTING_SETTINGS_DATA_SHARING_TAB,
	ACCESS_LISTING_SETTINGS_VERIFICATION_TYPES_TAB,
	EDIT_LISTING_DEPLOY_ADVANTAGE_LINKS,
	ACCESS_LISTING_USER_PERMISSIONS_TAB,
	CREATE_LISTING,
	EDIT_LISTING_COBRAND_TEMPLATE,
	ACCESS_LISTING_LIST,
	ACCESS_LISTING_NAMES,
	ACCESS_LISTING_ADVERTISEMENT,
	EXPORT_VERIFICATION_REQUESTS,
	ACCESS_CODE_POOL_PAGE,
	ACCESS_PARTNER_LIST_PAGE,
	SYNC_OFFERS,
	ACCESS_EVENTS_LIST_PAGE,
	ACCESS_LISTING_INVOICES,
	VIEW_SYNDICATED_LISTINGS
} from '../../auth/policies'
import { closedDrawerWidth, openedDrawerWidth } from '../../setup/constants'
import GovXLogo from '../GovXLogo'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		maxWidth: '1500px',
	},
	appBar: {
		zIndex: 100,
		backgroundColor: 'white',
	},

	content: {
		flexGrow: 1,
		padding: '24px', // Note: theme.spacing.unit * 3 was returning NaN
	},
	toolbar: theme.mixins.toolbar,
	logo: {
		width: '120px',
		height: '20px',
		border: 'none',
	},
	drawer: {
		width: openedDrawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		backgroundColor: 'rgb(71,71,71) !important',
		width: openedDrawerWidth,
		top: '65px !important',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: 'rgb(71,71,71) !important',
		top: '65px !important',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: closedDrawerWidth
	},
}))

const Admin = () => {
	const [open, setOpen] = useState(false)
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const expandDrawer = () => dispatch(openDrawer());
	const getOccupations = () => dispatch(fetchOccupations()); //convert to a requires
	const collapseDrawer = () => dispatch(closeDrawer());
	const getPolicies = () => {
		dispatch(
			fetchPolicies([
				EDIT_USER_ROLES,
				DELETE_USERS,
				DEVELOPER_ACCESS,
				GLOBAL_SEARCH,
				ACCESS_USER_LIST_PAGE,
				ACCESS_USER_DETAIL_PAGE,
				ACCESS_AFFILIATE_LIST_PAGE,
				UPDATE_AFFILIATE,
				SYNC_SHOPIFY_AFFILIATE,
				EDIT_AFFILIATIONS,
				REORDER_AFFILIATION_TAXONOMY,
				ACCESS_VERIFICATION_REQUEST_LIST_PAGE,
				ACCESS_AFFILIATIONS_PAGE,
				ACCESS_DMDC_STATUS_PAGE,
				ACCESS_VA_STATUS_PAGE,
				ACCESS_DOMAIN_WHITELIST_PAGE,
				ACCESS_DOCUMENT_RULE_SETS_PAGE,
				ACCESS_PRIVATE_AFFILIATIONS,
				ACCESS_LISTING_SETTINGS_DATA_SHARING_TAB,
				ACCESS_LISTING_SETTINGS_VERIFICATION_TYPES_TAB,
				ACCESS_LISTING_ADVERTISEMENT,
				EDIT_LISTING_DEPLOY_ADVANTAGE_LINKS,
				ACCESS_LISTING_USER_PERMISSIONS_TAB,
				CREATE_LISTING,
				EDIT_LISTING_COBRAND_TEMPLATE,
				ACCESS_LISTING_LIST,
				ACCESS_LISTING_NAMES,
				EXPORT_VERIFICATION_REQUESTS,
				ACCESS_CODE_POOL_PAGE,
				ACCESS_PARTNER_LIST_PAGE,
				SYNC_OFFERS,
				ACCESS_EVENTS_LIST_PAGE,
				ACCESS_LISTING_INVOICES,
				VIEW_SYNDICATED_LISTINGS
			])
		)
	};

	const showNav = useSelector(showNavSelector)
	const canQuickSearch = useSelector(hasPolicy(GLOBAL_SEARCH))
	const user = useSelector(state => state.authentication.user)

	const [isLogin, setIsLogin] = useState(false)
	useEffect(() => {
		if (!user) return
		getOccupations()
		getPolicies()
	}, [isLogin, user])

	useEffect(() => {
		setIsLogin(location.pathname === RouteConstants.login.path)
	}, [location.pathname,])

	if (isLogin) {
		return <Login />
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={classes.appBar}
				elevation={0}
			>
				<Toolbar style={{ backgroundColor: '#0e5777', height: '65px' }}>
					<Grid container alignItems="center">
						<Grid item>
							<GovXLogo className={classes.logo} />
						</Grid>
						<Grid item style={{ flex: 1 }} />
						<Grid item>
							<Grid container alignItems="center" justify="space-evenly">
								{canQuickSearch && (
									<Grid item>
										<QuickSearch />
									</Grid>
								)}
								<Grid item>
									<IconButton href="https://support.govxinc.com/hc/en-us" target='_blank'>
										<HelpIcon style={{ color: 'white' }} />
									</IconButton>
								</Grid>
								<Grid item>
									<AccountMenu />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{showNav &&
				<Drawer
					variant="permanent"
					className={classNames(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: classNames({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
					open={open}
				>
					<List style={{ height: '100%', overflow: 'hidden', paddingTop: '0px' }}>
						<NavList drawerExpanded={open} expandDrawer={setOpen} />

						<ListItem
							onClick={() => {
								if (open) {
									collapseDrawer()
									setOpen(false)
								} else {
									setOpen(true)
									expandDrawer()
								}
							}}
							style={{
								width: 'auto',
								maxWidth: '100%',
								position: 'absolute',
								bottom: '65px',
								right: '0px',
								cursor: 'pointer',
								padding: '15px 20px'
							}}
						>
							<ListItemIcon style={{ margin: '0px', minWidth: '36px' }}>
								{open ? (
									<ChevronLeftIcon
										style={{
											width: '36px',
											height: '36px',
											margin: '0px',
											padding: '0px',
											position: 'static',
											fill: '#ccc',
										}}
									/>
								) : (
									<ChevronRightIcon
										style={{
											width: '36px',
											height: '36px',
											margin: '0px',
											padding: '0px',
											position: 'static',
											fill: '#ccc',
										}}
									/>
								)}
							</ListItemIcon>
						</ListItem>
					</List>
				</Drawer>
			}
			<main className={classes.content} style={{ width: showNav ? `calc(100% - ${open ? openedDrawerWidth : closedDrawerWidth}px)` : '100%' }}>
				<div className={classes.toolbar} />
				<Outlet />
			</main>
			<GlobalNotification />
			<ModalRoot />
			<SideSheetRoot />
		</div>
	)
}

export default Admin
