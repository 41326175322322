/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Typography } from '@mui/material'
import DialogSubsectionHeadline from '../../../../Header/DialogSubsectionHeadline'
import { MUIFormTextInput } from '../../../../@Common/Form/TextInput/FormTextInput'
import { MUIFormSelectInput } from '../../../../@Common/Form/SelectInput/FormSelectInput'
import MUIFormHexInput from '../../../../@Common/Form/TextInput/FormHexInput'
import { MUIFormFileInput } from '../../../../@Common/Form/FileInput/FormFileInput'

export function HeaderSettings({
  form, isGovX, canEditListingCobrandTemplate, classes,
}) {
  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" style={{ marginTop: isGovX ? '20px' : '40px' }}>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12">
        <Box gridColumn="span 4">
          <DialogSubsectionHeadline marginTop="0px">Logo</DialogSubsectionHeadline>
          <Typography className={classes.sectionSubHeader}>
            Logo height: between 30 and 150px
            <br />
            Supports: JPG, JPEG, PNG
          </Typography>
        </Box>
        {!isGovX && (
          <>
            <Box gridColumn="span 7" display="block">
              <MUIFormFileInput
                control={form.control}
                name="headerLogoImageUrl"
                label="Logo"
                rules={{ required: true }}
                fileTypes=".jpg,.jpeg,.png"
                maxSize="1024"
              />
            </Box>

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12">
              {form.watch('type') === 'PrivateLabel' && (

                <Box gridColumn="5/8" sx={{ mt: '40px' }}>
                  <MUIFormSelectInput
                    control={form.control}
                    name="headerLogoAlignment"
                    label="Logo Alignment"
                    items={[
                      { value: 'left', label: 'Left', disabled: false },
                      { value: 'center', label: 'Center', disabled: false },
                    ]}
                  />
                </Box>
              )}

              <Box gridColumn={form.watch('type') === 'PrivateLabel' ? '8/10' : '5/7'} sx={{ mt: '40px', pl: form.watch('type') === 'PrivateLabel' ? '10px' : '0px' }}>
                <MUIFormTextInput
                  control={form.control}
                  name="headerLogoHeight"
                  label="Logo Height"
                  rules={{ required: true }}
                  maxLength="3"
                  suffix="px"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12" style={{ marginTop: isGovX ? '20px' : '40px' }}>
        <Box gridColumn="span 4">
          <DialogSubsectionHeadline marginTop="0px">Header Background</DialogSubsectionHeadline>
          <Typography className={classes.sectionSubHeader}>
            Color inputs must be in hex code format
          </Typography>
        </Box>
        <Box gridColumn="span 8">
          <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gridColumn="span 8">

            {form.watch('type') === 'PrivateLabel' && (
              <>
                <Box gridColumn="span 3">
                  <MUIFormSelectInput
                    control={form.control}
                    name="headerBackgroundType"
                    label="Desktop"
                    items={[
                      { value: 'color', label: 'Solid color', disabled: false },
                      { value: 'transparent', label: 'Transparent', disabled: false },
                    ]}
                  />
                </Box>
                {form.watch('headerBackgroundType') === 'color' && !isGovX && (
                  <Box gridColumn="span 2" style={{ paddingLeft: '10px' }}>
                    <MUIFormHexInput
                      control={form.control}
                      name="headerBackgroundColor"
                      label="Desktop Color"
                      rules={{ required: true }}
                    />
                  </Box>
                )}
              </>
            )}
            <Box gridColumn="span 2" style={{ paddingLeft: form.watch('type') === 'PrivateLabel' ? '10px' : '0px' }}>
              <MUIFormHexInput
                placeHolder="Hexcode"
                control={form.control}
                name="headerMobileBackgroundColor"
                label="Mobile Color"
                rules={{ required: true }}
              />
            </Box>
          </Box>

        </Box>

      </Box>
    </Box>
  )
}

export default HeaderSettings
