// Global

// User policies
export const EDIT_USER_ROLES = 'Edit User Roles'
export const DELETE_USERS = 'Delete Users'
export const DEVELOPER_ACCESS = 'Developer Access'
export const GLOBAL_SEARCH = 'Global Search'
export const ACCESS_USER_LIST_PAGE = 'Access User List Page'
export const ACCESS_USER_DETAIL_PAGE = 'Access User Detail Page'

// Affiliate policies
export const ACCESS_AFFILIATE_LIST_PAGE = 'Access Affiliate List Page'
export const UPDATE_AFFILIATE = 'Update Affiliate'
export const SYNC_SHOPIFY_AFFILIATE = 'Sync Shopify Affiliate'

// Verification policies
export const EDIT_AFFILIATIONS = 'Edit Affiliations'
export const REORDER_AFFILIATION_TAXONOMY = 'Reorder Affiliation Taxonomy'
export const ACCESS_VERIFICATION_REQUEST_LIST_PAGE = 'Access Verification Request List Page'
export const ACCESS_AFFILIATIONS_PAGE = 'Access Affiliations Page'
export const ACCESS_DMDC_STATUS_PAGE = 'Access DMDC Status Page'
export const ACCESS_VA_STATUS_PAGE = 'Access VA Status Page'
export const ACCESS_DOMAIN_WHITELIST_PAGE = 'Access Domain Whitelist Page'
export const ACCESS_DOCUMENT_RULE_SETS_PAGE = 'Access Document Rule Sets Page'
export const ACCESS_PRIVATE_AFFILIATIONS = 'Access Private Affiliations'
export const BULK_IMPORT = 'Bulk Import'
export const EXPORT_VERIFICATION_REQUESTS = 'Export Verification Requests'

// Listing policies
export const ACCESS_LISTING_SETTINGS_DATA_SHARING_TAB = 'Access Listing Settings Data Sharing Tab'
export const ACCESS_LISTING_SETTINGS_VERIFICATION_TYPES_TAB = 'Access Listing Settings Verification Types Tab'
export const EDIT_LISTING_DEPLOY_ADVANTAGE_LINKS = 'Edit Listing Deploy Advantage Links'
export const ACCESS_LISTING_USER_PERMISSIONS_TAB = 'Access Listing User Permissions Tab'
export const CREATE_LISTING = 'Create Listing'
export const EDIT_LISTING_COBRAND_TEMPLATE = 'Edit Listing Cobrand Template'
export const ACCESS_LISTING_LIST = 'Access Listing List'
export const ACCESS_LISTING_NAMES = 'Access Listing Names'
export const ACCESS_LISTING_ADVERTISEMENT = 'Access Listing Advertisement'
export const ACCESS_LISTING_INVOICES = 'Access Listing Invoices'

// Offer policies
export const CREATE_OFFER = 'Create Offer'
export const ACCESS_OFFERS_LIST_PAGE = "Access Offers List Page";
export const UPDATE_OFFER = "Update Offer";
export const DELETE_OFFER = "Delete Offer";
export const SET_OFFER_STATUS = "Set Offer Status";
export const VIEW_BILLING_RULES = "View Billing Rules";
export const SET_OFFER_CHANNELS = "Set Offer Channels";
export const SYNC_OFFERS = "Sync Offers";
export const SYNDICATE_OFFER = "Syndicate Offer";
export const DELETE_SYNDICATE_OFFER = "Delete Syndicate Offer"
export const VIEW_SYNDICATED_LISTINGS = "View Syndicated Listings"

// Code Pool policies
export const ACCESS_CODE_POOL_PAGE = 'Access Code Pool List Page';
export const DELETE_CODE_POOL = 'Delete Code Pool';

// Partners policies
export const ACCESS_PARTNER_LIST_PAGE = 'Access Partner List Page';

// Events policies
export const ACCESS_EVENTS_LIST_PAGE = 'Access Events List Page';
