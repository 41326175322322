import React from 'react'
import { RouteConstants } from '../../../setup/routeConstants'
import { ACCESS_LISTING_LIST } from '../../../auth/policies'
import LazyProtectedRoute from '../../LazyProtectedRoute'

const PartnersListingsComponent = React.lazy(() => import("../../../components/Partners/Listings"))

export default {
	path: RouteConstants.partners.listings.index.path,
	element: (
		<LazyProtectedRoute 
			requiredPolicies={[ACCESS_LISTING_LIST]}
			component={<PartnersListingsComponent/>} 
		/>
	),
}