import React from 'react'
import MUITextField from '@mui/material/TextField'

const setFocus = props => {
	if (props && props.name) {
		var els = document.getElementsByName(props.name)
		if(els && els.length === 1){
			els[0].focus()
		}
	}
}

const TextArea = ({ styles, value, maxLength, label, ...custom }) => (
	<div onClick={() => setFocus(custom)}>
		<MUITextField
			multiline
			style={{
				...styles,
				fontSize: '14px',
			}}
			InputProps={{
				disableUnderline: true,
				style: {
					lineHeight: '18px',
				},
			}}
			inputProps={{	//inputProps is different from InputProps
				maxLength: maxLength,
				style: {
					resize: 'vertical',
					minHeight: label ? '37px' : '124px',
					padding: '25px 12px 25px',
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
			value={value}
			variant='filled'
			label={label}
			{...custom}
		/>
	</div>
)

export default TextArea
