import { getJson, postJson, putJson } from './baseApi'

export const login = data => {
	return postJson('/api/login', data)
		.then(response => {
			return response
		})
		.catch(err => {
			if (err.response && err.response.status === 401)
				throw { email: 'You do not have access to enter this site. Please check your credentials and try again if you believe this is an error.' }
			throw err
		})
}

export const logout = () => {
	return postJson('/api/logout', {})
		.then(response => {
			if (response.status == 200)
				return {}
		})
		.catch(err => {
			throw err
		})
}

export const fetchCurrentUser = () => {
	return getJson('/api/users/current')
}

export const fetchSettings = () => {
	return getJson('/api/settings')
}

export const fetchPolicies = policies => {
	return postJson('/api/policies/authorize', policies)
}

export const fetchPolicy = policy => {
	return fetchPolicies([policy])
}