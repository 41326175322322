import { offer } from '../../../api/schema'
import * as offersApi from '../../../api/offersApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'
import * as userApi from "../../../api/userApi";
import {CREATE_USER_FAILED, CREATE_USER_REQUEST, CREATE_USER_SUCCESS} from "../users/users";


export const FETCH_OFFERS_BY_AFFILIATEID_REQUEST = 'FETCH_OFFERS_BY_AFFILIATEID_REQUEST'
export const FETCH_OFFERS_BY_AFFILIATEID_SUCCESS = 'FETCH_OFFERS_BY_AFFILIATEID_SUCCESS'
export const FETCH_OFFERS_BY_AFFILIATEID_FAILURE = 'FETCH_OFFERS_BY_AFFILIATEID_FAILURE'

export const fetchOffersByAffiliateId = affiliateId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_OFFERS_BY_AFFILIATEID_REQUEST })
	return offersApi
		.fetchOffersByAffiliateId(affiliateId)
		.then(response => {
			const data = normalize(response, [schema.offer])
			dispatch({ type: FETCH_OFFERS_BY_AFFILIATEID_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_OFFERS_BY_AFFILIATEID_FAILURE, err })
			throw err
		})
}

export const UPDATE_PRICING_SCHEME_REQUEST = 'UPDATE_PRICING_SCHEME_REQUEST'
export const UPDATE_PRICING_SCHEME_SUCCESS = 'UPDATE_PRICING_SCHEME_SUCCESS'
export const UPDATE_PRICING_SCHEME_FAILURE = 'UPDATE_PRICING_SCHEME_FAILURE'

export const updatePricingScheme = (offerId, command) => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_PRICING_SCHEME_REQUEST })
	return offersApi.updatePricingScheme(offerId, command)
		.then(response => {
			const data = normalize(response, schema.offer)
			dispatch({ type: UPDATE_PRICING_SCHEME_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_PRICING_SCHEME_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'offers'

export const DEFAULT_STATE = {
	db: {},
}

export const mergeOffers = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.offers } }
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_OFFERS_BY_AFFILIATEID_SUCCESS:
		case UPDATE_PRICING_SCHEME_SUCCESS:
			return mergeOffers(state, action)
		default:
			return { ...state }
	}
}

export default reducer

export const selectOffersHashMap = state => state.offers.offers.db

export const selectOffers = createSelector(
	selectOffersHashMap,
	domains => Object.keys(domains).map(k => domains[k])
)

export const selectOffer = id => state => state.offers.offers.db[id]
export const selectOffersByAffiliateId = affiliateId => state => FindOffersByAffiliateId(state.offers.offers.db, affiliateId)

const FindOffersByAffiliateId = (obj, affiliateId) => {
	let offers = []
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let item = obj[key]
            if (affiliateId == item.affiliateId) {
                offers = offers.concat(item)
            }
        }
    }
    return offers
}
