import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import { hideModal, saveTicketSettings } from '../../../redux/actions'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export function LinkAdvantageModal({
  listing, ticketSettings,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())

  const {
    handleSubmit, control, getValues, watch,
  } = useForm({
    defaultValues: {
      linkType: '',
      id: '',
    },
  })

  const onSubmit = (values) => {
    const linkTypeId = getValues().linkType

    if (linkTypeId === '1') {
      return dispatch(
        saveTicketSettings(listing, { ...ticketSettings, entertainerId: values.id }),
      ).then(() => dispatch(hideModal()))
    } if (linkTypeId === '2') {
      return dispatch(
        saveTicketSettings(listing, { ...ticketSettings, venueId: values.id }),
      ).then(() => dispatch(hideModal()))
    }
  }

  return (
    <ModalContainer
      modal
      maxWidth="xs"
      fullWidth="true"
      title="Link to Advantage"
      actions={{
        primaryAction: {
          label: 'Link',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        <MUIFormSelectInput
          name="linkType"
          label="Link to"
          rules={{
            required: 'Please select a link type',
          }}
          control={control}
          items={[
            { key: '1', value: '1', label: 'Entertainer' },
            { key: '2', value: '2', label: 'Venue' },
          ]}
          includeEmptyItem
        />

        {watch('linkType') && watch('linkType') !== 'none' && (
          <Box mt="20px">
            <MUIFormTextInput
              name="id"
              label={watch('linkType') === '1' ? 'Entertainer ID' : 'Venue ID'}
              rules={{
                required: 'Please enter an ID',
              }}
              control={control}
              shrinkLabel
            />
          </Box>
        )}
      </form>
    </ModalContainer>
  )
}

LinkAdvantageModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  ticketSettings: PropTypes.shape({
    entertainerId: PropTypes.number,
    venueId: PropTypes.number,
  }).isRequired,
}

export default LinkAdvantageModal
