import { useParams, useSearchParams } from "react-router-dom"
import { useGetBillingTermsByLisstingIdQuery, useGetInvoicesByListingIdQuery, useLazyGetInvoiceDetailsByInvoiceIdQuery } from "../../../../../../redux/slices/billingSlice"
import { useEffect, useState } from "react";

const useInvoices = () => {
    const { id: partnerListingId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data: invoices,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
    } = useGetInvoicesByListingIdQuery(partnerListingId)

    const {
        data: billingTerms,
        billingTermsIsLoading,
        billingTermsIsFetching,
        billingTermsIsSuccess,
        billingTermsIsError,
        billingTermsError,
    } = useGetBillingTermsByLisstingIdQuery(partnerListingId)

    const [fetchDetails] = useLazyGetInvoiceDetailsByInvoiceIdQuery()
    const [invoiceDetails, setinvoiceDetails] = useState(null)
    const [isDetailsLoading, setIsDetailsLoading] = useState(null)
    const [detailsError, setDetailsError] = useState(null)

    const getDetails = async (invoiceId) => {
        setinvoiceDetails(null)
        const {
            data: details,
            isLoading: loading,
            isError: detailsError
        } = await fetchDetails(invoiceId);
        setIsDetailsLoading(loading)
        setinvoiceDetails(details)
        setDetailsError(detailsError)
    }

    useEffect(() => {
        if (!searchParams.get('invoiceId')) {
            setinvoiceDetails(null)
        } else {
            getDetails(searchParams.get('invoiceId'))
        }
    }, [searchParams])

    return {
        invoices,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        invoiceDetails,
        billingTerms,
        detailsError
    }
}

export default useInvoices