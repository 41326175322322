/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { ModalContainer } from '../../../Modals/ModalContainer'
import { MUIFormFileInput } from '../../../@Common/Form/FileInput/FormFileInput'

function ImportCodesModal({
  onClickSubmit,
  onClickCancel,
}) {
  const [processing, setProcessing] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      codePoolFile: null,
    },
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    try {
      setProcessing(true)
      const result = await onClickSubmit(data)
      if (result.importStatus !== 'Success') {
        setShowErrorMessage(true)
        setErrorMessage(result.importError)
      }
    } catch (error) {
      setShowErrorMessage(true)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div>
      <ModalContainer
        title="Import Codes"
        maxWidth="sm"
        fullWidth="true"
        processing={false}
        showProgressBar={processing}
        hideModal={() => { }}
        actions={{
          primaryAction: { label: 'Import', onClick: handleSubmit(onSubmit), disabled: watch('codePoolFile') === null ? watch('codePoolFile') === null : processing },
          secondaryAction: { label: 'Cancel', onClick: onClickCancel, disabled: processing },
        }}
      >
        <Typography component="div">
          <Box>
            To import codes, make sure your file adheres to the following standards:
          </Box>
          <ul style={{ listStyle: 'inside', margin: '.75em' }}>
            <li>File should be CSV format.</li>
            <li>Discount codes should be in a single column.</li>
            <li>Do not use column headers. The first code should be on row 1.</li>
          </ul>
        </Typography>
        <Box sx={{ mx: '40px', my: '20px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MUIFormFileInput
              name="codePoolFile"
              control={control}
              fileTypes=".csv"
              fileTypesDescription="CSV"
              processing={processing}
              onChange={() => { setShowErrorMessage(false); setErrorMessage(null) }}
              gxid="btnImportCodesBrowseFiles"
            />
            {showErrorMessage
              && (
                <Box>
                  <p style={{ color: '#d32f2f' }}>
                    Import failed.
                    {' '}
                    {errorMessage}
                    {' '}
                    Please try again.
                  </p>
                </Box>
              )}
          </form>
        </Box>
      </ModalContainer>
    </div>
  )
}

export default ImportCodesModal
