import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, saveTicketSettings } from '../../../redux/actions'

export function DeleteAdvantageVenueModal({
  listing,
  ticketSettings,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const deleteVenue = () => dispatch(
    saveTicketSettings(listing, { ...ticketSettings, venueId: null }),
  ).then(() => dispatch(hideModal()))

  return (
    <ModalContainer
      modal
      maxWidth="xs"
      fullWidth="true"
      title="Unlink Venue"
      actions={{
        primaryAction: {
          label: 'Unlink',
          onClick: deleteVenue,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ marginTop: '25px', color: '#222' }}>Are you sure you want to remove the link to this venue?</p>
    </ModalContainer>
  )
}

DeleteAdvantageVenueModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  ticketSettings: PropTypes.shape({
    venueId: PropTypes.number,
  }),
}

export default DeleteAdvantageVenueModal
