import * as partnerApi from '../../../api/partnerApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

export const CREATE_PARTNERS_SEARCH = 'CREATE_PARTNERS_SEARCH'
export const UPDATE_PARTNERS_SEARCH = 'UPDATE_PARTNERS_SEARCH'

export const SEARCH_PARTNERS_REQUEST = 'SEARCH_PARTNERS_REQUEST'
export const SEARCH_PARTNERS_SUCCESS = 'SEARCH_PARTNERS_SUCCESS'
export const SEARCH_PARTNERS_FAILURE = 'SEARCH_PARTNERS_FAILURE'

export const SEARCH_BYTERM_PARTNERS_REQUEST = 'SEARCH_BYTERM_PARTNERS_REQUEST'
export const SEARCH_BYTERM_PARTNERS_SUCCESS = 'SEARCH_BYTERM_PARTNERS_SUCCESS'
export const SEARCH_BYTERM_PARTNERS_FAILURE = 'SEARCH_BYTERM_PARTNERS_FAILURE'

export const createPartnersSearch = search => (dispatch, getState, { schema }) => {
    dispatch({ type: CREATE_PARTNERS_SEARCH, search })
    return new Promise(resolve => resolve(search))
}

export const updatePartnersSearch = search => (dispatch, getState, { schema }) => {
    dispatch({ type: UPDATE_PARTNERS_SEARCH, search })
    return new Promise(resolve => resolve(search))
}

export const searchPartners = searchId => (dispatch, getState, { schema }) => {
    dispatch({ type: SEARCH_PARTNERS_REQUEST })
    let search = selectPartnersSearch(searchId)(getState())

    return partnerApi.fetchPartners(search)
        .then(response => {
            const data = normalize(response.data, [schema.partner])
            dispatch({ type: SEARCH_PARTNERS_SUCCESS, data, total: response.total, search })
            return response
        })
        .catch(err => {
            dispatch({ type: SEARCH_PARTNERS_FAILURE, err })
            throw err
        })
}

export const searchPartnersByTerm = (searchByTerm) => (dispatch, { schema }) => {
    dispatch({ type: SEARCH_BYTERM_PARTNERS_REQUEST })

    return partnerApi.fetchPartnersByTerm(searchByTerm)
        .then(response => {
            const data = response.data
            dispatch({ type: SEARCH_BYTERM_PARTNERS_SUCCESS, data, total: response.total, searchByTerm })
            return response
        })
        .catch(err => {
            dispatch({ type: SEARCH_BYTERM_PARTNERS_FAILURE, err })
            throw err
        })
}

export const FETCH_PARTNER_REQUEST = 'FETCH_PARTNER_REQUEST'
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS'
export const FETCH_PARTNER_FAILED = 'FETCH_PARTNER_FAILED'

export const fetchPartner = partnerId => (dispatch, getState, { schema }) => {
    dispatch({ type: FETCH_PARTNER_REQUEST })
    return partnerApi.fetchPartner(partnerId)
        .then(response => {
            let data = normalize(response, schema.partner)
            dispatch({ type: FETCH_PARTNER_SUCCESS, data })
            return response
        })
        .catch(err => {
            dispatch({ type: FETCH_PARTNER_FAILED, err })
            throw err
        })
}

export const FETCH_PARTNER_BYLISTINGID_REQUEST = 'FETCH_PARTNER_BYLISTINGID_REQUEST'
export const FETCH_PARTNER_BYLISTINGID_SUCCESS = 'FETCH_PARTNER_BYLISTINGID_SUCCESS'
export const FETCH_PARTNER_BYLISTINGID_FAILED = 'FETCH_PARTNER_BYLISTINGID_FAILED'

export const fetchPartnerByListingId = listingId => (dispatch, getState, { schema }) => {
    dispatch({ type: FETCH_PARTNER_BYLISTINGID_REQUEST })
    return partnerApi.fetchPartnerByListingId(listingId)
        .then(response => {
            let data = normalize(response, schema.partner)
            dispatch({ type: FETCH_PARTNER_BYLISTINGID_SUCCESS, data })
            return response
        })
        .catch(err => {
            dispatch({ type: FETCH_PARTNER_BYLISTINGID_FAILED, err })
            throw err
        })
}

export const STATE_KEY = 'partners'

export const DEFAULT_STATE = {
    db: {},
    searches: {},
}

export const handleSearchChange = (state, action) => {
    const searches = { ...state.searches, [action.search.id]: action.search }
    return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
    let searches = {
        ...state.searches,
        [action.search.id]: {
            ...state.searches[action.search.id],
            total: action.total,
            result: action.data.result
        }
    }

    let db = {
        ...state.db,
        ...action.data.entities.partners
    }

    return { ...state, db, searches }
}

const handleFetchPartnerSuccess = (state, action) => {
    const db = {
        ...action.data.entities.partners
    }
    // listing count is not provided on individual patner fetch
    Object.keys(state.db).forEach(id => {
        const { listingCount } = state.db[id]
        db[id] = db[id] ? { ...db[id], listingCount } : state.db[id]
    })
    return { ...state, db }
}

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_PARTNER_SUCCESS:
        case FETCH_PARTNER_BYLISTINGID_SUCCESS:
            return handleFetchPartnerSuccess(state, action)
        case CREATE_PARTNERS_SEARCH:
        case UPDATE_PARTNERS_SEARCH:
            return handleSearchChange(state, action)
        case SEARCH_PARTNERS_SUCCESS:
            return handleSearchSuccess(state, action)
        default:
            return { ...state }
    }
}

export default reducer

export const selectPartnersHashMap = state => state.partners.partners.db
export const selectPartnersSearchesHashMap = state => state.partners.partners.searches

export const selectPartners = createSelector(selectPartnersHashMap, partners => Object.keys(partners).map(k => partners[k]))

export const selectPartnersSearches = createSelector(selectPartnersSearchesHashMap, searches =>
    Object.keys(searches).map(k => searches[k])
)

export const createFilteredPartnersSelector = id => {
    return createSelector([selectPartnersHashMap, selectPartnersSearchesHashMap], (partners, searches) => {
        if (!searches[id] || !searches[id].result) return []
        let results = searches[id].result
            .map(f => partners[f])
            .filter(d => d)
            .filter(u => !u.deleted)
        return results
    })
}

export const selectPartnersSearch = id => state => state.partners.partners.searches[id]

export const selectPartner = id => createSelector(selectPartnersHashMap, partners => partners[id.toLowerCase()])
