import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Divider, Grid } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import { ModalContainer } from '../../../../../Modals/ModalContainer'
import { MUIFormSelectInput } from '../../../../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../../../redux/actions'
import DataTable from '../../../../../@Common/DataTable'
import EligibilityIconList from '../OffersSection/EligibilityIconList'
import { MUIFormCheckboxInput } from '../../../../../@Common/Form/CheckboxInput/FormCheckboxInput'
import { SOURCE } from '../../../../../../constants/partners'
import { US_STATES_ARRAY } from '../../../../../../constants/common'

const getLinkableOffers = (listingOffers) => listingOffers.filter(({ source: { type } }) => type.toLowerCase() === SOURCE.direct)

const map = (x) => !!x ? x : null

const mapBulkEditEvents = ({
    data,
    eventIds,
    linkedOffers
}) => data == null ? null : ({
    eventIds,
    updateOffers: data.offersSelected ? {
        bulkAction: data.offersUpdateType,
        offers: linkedOffers
    } : null,
    updateLocation: data.locationSelected ? {
        name: map(data.locationName),
        address1: map(data.address),
        city: map(data.city),
        state: map(data.state),
        zip: map(data.zipCode),
    } : null,
    updateChannels: data.channelsSelected ? {
        bulkAction: data.channelsUpdateType,
        channels: Object.keys(data.channels).reduce((reduced, key) => [...reduced, ...data.channels[key] ? [key] : []], [])
    } : null,
})

function BulkEditEventsModal({
    eventIds,
    offers,
    lookups,
    onClickSubmit
}) {
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()
    const [eventLinkedOffers, setEventLinkedOffers] = useState([])

    const columns = [
        {
            field: 'name',
            headerName: 'Offer',
            flex: 1
        },
        {
            field: 'discount',
            headerName: 'Discount',
            flex: 1,
            valueGetter: ({ row: { discount: { scheme: { amount, typeName } } } }) =>
                amount ? typeName === 'PercentOff' ? `${amount}%` : `$${amount}` : null,
        },
        {
            field: 'affiliations',
            headerName: 'Eligible Affiliation',
            flex: 2,
            renderCell: ({ row }) => { return <EligibilityIconList eligibleGroups={row.verification.eligibleGroups} /> }

        }
    ]

    const bulkEditOptions = [
        { label: 'Add to existing', key: 'addToExisting', value: 'addToExisting' },
        { label: 'Remove from existing', key: 'removeFromExisting', value: 'removeFromExisting' },
        { label: 'Replace all with', key: 'replaceAll', value: 'replaceAll' },
    ]

    const getSelectedChannels = () => {
        if (lookups) {
            return lookups.data?.channels
                .filter((x) => x.defaultChecked === true)
                .reduce((carry, currentValue) => ({ ...carry, [`channels.${currentValue.value}`]: false }), {})
        }
    }

    const {
        handleSubmit, control, setValue, watch, formState: { errors }
    } = useForm({
        mode: 'all',
        defaultValues: {
            locationName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            linkedOffers: eventLinkedOffers,
            ...getSelectedChannels(),
            channelsUpdateType: 'addToExisting',
            offersUpdateType: 'addToExisting',
            offersSelected: false,
            channelsSelected: false,
            locationSelected: false
        }
    })

    const offersSelected = watch('offersSelected')
    const channelsSelected = watch('channelsSelected')
    const locationSelected = watch('locationSelected')

    const onSubmit = async (data) => {
        setProcessing(true)
        try {
            const eventObj = mapBulkEditEvents({ data, eventIds, linkedOffers: eventLinkedOffers })
            await onClickSubmit(eventObj)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        } finally {
            setProcessing(false)
        }
    }

    const selectOffers = () => {
        if (!offersSelected) {
            setValue('offersSelected', true)
        }
    }

    const selectChannels = () => {
        if (!channelsSelected) {
            setValue('channelsSelected', true)
        }
    }

    const selectLocation = () => {
        if (!locationSelected) {
            setValue('locationSelected', true)
        }
    }

    useEffect(() => {
        if (eventLinkedOffers.length > 0) {
            setValue('offersSelected', true)
        }
    }, [eventLinkedOffers])

    return (
        <ModalContainer
            title={'Edit Events'}
            maxWidth="md"
            fullWidth="true"
            processing={processing || lookups?.isLoading}
            hideModal={() => { }}
            actions={{
                primaryAction: { label: 'Save', onClick: handleSubmit(onSubmit), disabled: locationSelected || channelsSelected || offersSelected ? false : true },
                secondaryAction: { label: 'Cancel', onClick: () => { dispatch(hideModal()) } },
            }}
        >
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item>Choose the bulk action(s) you wish to perform on the selected {eventIds.length} events.</Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    <MUIFormCheckboxInput
                        key={'offersSelected'}
                        name={`offersSelected`}
                        label={'Offers'}
                        control={control}
                        gxid={`offersSelected`}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                        <MUIFormSelectInput
                            onChange={() => selectOffers()}
                            name="offersUpdateType"
                            control={control}
                            label="Action"
                            items={bulkEditOptions}
                            gxid="offersUpdateType"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            hideFooter
                            checkboxSelection
                            onRowSelectionModelChange={(data) => {
                                setEventLinkedOffers(data)
                            }}
                            rowSelectionModel={eventLinkedOffers}
                            selected
                            getRowId={(row) => row.offerId}
                            rowSelection={true}
                            loading={false}
                            rows={getLinkableOffers(offers)}
                            columns={columns}
                            disableRowSelectionOnClick
                            pagination={false}
                            disableColumnFilter
                            autoHeight
                            pageSize={5}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ marginRight: '-24px', marginLeft: '-24px', marginBottom: '24px' }} />
            <Grid container sx={{ marginY: '16px', marginBottom: '16px' }}>
                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    <MUIFormCheckboxInput
                        key={'channelsSelected'}
                        name={`channelsSelected`}
                        label={'Sales Channels'}
                        control={control}
                        gxid={`channelsSelected`}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                        <MUIFormSelectInput
                            onChange={() => selectChannels()}
                            name="channelsUpdateType"
                            control={control}
                            label="Action"
                            items={bulkEditOptions}
                            gxid="salesChannelOption"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {lookups.data.channels.map((item) => (
                            <MUIFormCheckboxInput
                                onClick={selectChannels}
                                key={item.value}
                                name={`channels.${item.value}`}
                                label={item.text}
                                control={control}
                                gxid={`channel.${item.value}`}
                                style={item.value.toLowerCase() === SOURCE.shopify ? { display: 'none' } : {}}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ marginRight: '-24px', marginLeft: '-24px', marginBottom: '24px' }} />
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                    <MUIFormCheckboxInput
                        key={'locationSelected'}
                        name={`locationSelected`}
                        label={'Location'}
                        control={control}
                        gxid={`locationSelected`}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Grid container sx={{ marginBottom: '16px' }}>
                        <Grid item xs={12}>
                            <MUIFormTextInput
                                onChange={() => selectLocation()}
                                control={control}
                                name="locationName"
                                label="Location Name"
                                gxid="locationName"
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginBottom: '16px' }}>
                        <Grid item xs={12}>
                            <MUIFormTextInput
                                onChange={() => selectLocation()}
                                control={control}
                                name="address"
                                label="Address"
                                gxid="address"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px', minHeight: '58px' }}>
                        <Grid item xs={6}>
                            <MUIFormTextInput
                                onChange={() => selectLocation()}
                                control={control}
                                name="city"
                                label="City"
                                gxid="city"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <MUIFormSelectInput
                                onChange={() => selectLocation()}
                                name="state"
                                control={control}
                                label="State"
                                items={US_STATES_ARRAY}
                                gxid="createCodePoolcodeLimitType"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MUIFormTextInput
                                onChange={() => selectLocation()}
                                control={control}
                                rules={{ pattern: { value: /^(\d{5})?$/ }, maxLength: 5 }}
                                type='number'
                                maxLength={5}
                                name="zipCode"
                                label="Zip Code"
                                gxid="eventZip"
                                hideErrorMessage={true}
                                styles={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                    "& input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                }}
                            />
                            <Box>
                                <ErrorMessage
                                    errors={errors}
                                    name={'zipCode'}
                                    render={() => (
                                        <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                                            Zip code must be a 5-digit number
                                        </p>
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ModalContainer>
    )
}

export default BulkEditEventsModal