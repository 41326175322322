import { getGovXAdminDomain, getGovXShopDomain } from './configuration'
import {
	removeUtcFromDateTime,
} from '../formatting'

export const RouteConstants = {
	dashboard: {
		index: {
			path: '/'
		}
	},
	error: {
		path: '/error'
	},
	users: {
		index: {
			path: '/users'
		},
		review: {
			path: '/users/review'
		},
		user: {
			path: '/users/:id'
		}
	},
	partners: {
		index: {
			path: '/partners'
		},
		partner: {
			path: '/partners/:id'
		},
		listings: {
			index: {
				path: '/partners/listings'
			},
			listing: {
				path: '/partners/listings/:id'
			},
		},
	},
	codePools: {
		index: {
			path: '/code-pools'
		},
		detail: {
			path: '/code-pools/:id'
		},
	},
	development: {
		path: '/development'
	},
	login: {
		path: '/login'
	},
	logout: {
		path: '/logout'
	},
	verification: {
		root: {
			path: '/verification'
		},
		review: {
			path: '/verification/review'
		},
		listings: {
			path: '/verification/listings'
		},
		listing: {
			path: '/verification/listings/:id'
		},
	},
	whitelist: {
		root: {
			path: '/whitelist'
		},
		review: {
			path: '/whitelist/review'
		}
	},
	documentRuleSets: {
		root: {
			path: '/document-rule-sets'
		},
	},
	occupations: {
		root: {
			path: '/occupations'
		},
		taxonomy: {
			path: '/occupations/taxonomy'
		}
	},
	reports: {
		dmdcStats: {
			path: '/reports/dmdc-stats'
		},
		vaStats: {
			path: '/reports/va-stats'
		},
	}
}

export const getUserPath = id => {
	return '/users/' + id
}

export const getListingPath = id => {
	return '/verification/listings/' + id
}

export const getPartnerListingPath = id =>
	RouteConstants.partners.listings.listing.path.replace(':id', id)

export const getCustomerPath = id => {
	return getGovXAdminDomain() + '/Customer/Edit/' + id
}

export const getAffiliateShopPath = (id, seoName) => {
	return getGovXShopDomain() + '/a/' + id + '/' + seoName
}

export const getEventTicketsDownloadLink = (entertainerId, listingId) => {
	return `/tickets/entertainers/${entertainerId}/event-tickets/download?listingId=${listingId}`
}

export const getVenueTicketsDownloadLink = (venueId, listingId) => {
	return `/tickets/venues/${venueId}/venue-tickets/download?listingId=${listingId}`
}

export const getVerificationRequestsDownloadLink = search => {
	let { expanded, ...timeFrame } = search.filter.timeFrame

	let query = ''
	// Timeframe
	query += 'timeFrame.type=' + timeFrame.type
	if (timeFrame.from) {
		let startDate = removeUtcFromDateTime(timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(startDate.format())
	}
	if (timeFrame.to) {
		let endDate = removeUtcFromDateTime(timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(endDate.format())
	}
	// Authorization methods
	search.filter.authorizationMethods.methods.filter(m => m.selected).forEach((m, i) => {
		query += '&authorizationMethods.methods[' + i + ']=' + m.type
	})
	// Statuses
	search.filter.verificationRequests.statuses.filter(r => r.selected).forEach((r, i) => {
		query += '&verificationRequestStatuses.statuses[' + i + ']=' + r.type
	})
	// Listing ID
	query += '&listingId=' + search.filter.listing.id
	// Occupation ID
	query += '&occupationId=' + search.filter.occupation.id
	// Statuses
	search.filter.documentTypes.types.filter(r => r.selected).forEach((r, i) => {
		query += '&documentTypes.types[' + i + ']=' + r.type
	})
	// Domain Name
	query += '&domainName=' + search.filter.searchText.text

	return `/api/verification/requests/download?${query}`
}

export const downloadListingSpecialOffersUserSubscriptionReportLink = search => {
	let { expanded, ...timeFrame } = search.filter.timeFrame

	let listingId = search.filter.listing.id
	let query = ''

	// timeframe
	query += 'timeFrame.type=' + timeFrame.type
	if (timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(timeFrame.from.format())
	if (timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(timeFrame.to.format())

	// paging
	query += '&pageSize=' + search.paging.pageSize
	// Listing ID
	query += '&listingId=' + listingId

	return `/api/listings/${listingId}/special-offers-user-subscription-report/download?${query}`
}
