import React from 'react'
import PropTypes from 'prop-types'
import TextField from './TextField'
import DateField from './DateField'
import YearField from './YearField'
import Checkbox from './Checkbox'
import { isOver18 } from '../../validation'
import GovernmentDepartmentField from './GovernmentDepartmentField'
import { pure } from 'recompose'

export const stringFieldFactory = ({field}) => (
	<TextField name={field.key} label={field.label} placeholder={field.placeholder} validate={[]} />
)

export const dateFieldFactory = ({field, postValidate}) => (
	<DateField name={field.key} label={field.label} placeholder={field.placeholder} preValidate={[]} postValidate={postValidate || []} />
)

export const yearFieldFactory =({field}) => (
	<YearField name={field.key} label={field.label} preValidate={[]} postValidate={[]} />
)

export const checkboxFieldFactory = ({field}) => (
	<Checkbox name={field.key} label={field.label} />
)

export const govtDepartmentFieldFactory = ({field, formName}) => (
	<GovernmentDepartmentField field={field} formName='verify-prequal' />
)

const DynamicFieldContent = ({
	field
}) => {
	switch(field.type) {
		case 'string':
			return stringFieldFactory({field})
		case 'date':
			if (field.key == 'dob')//we want to ensure that for > 18 on date of birth
				return dateFieldFactory({field, postValidate: [isOver18]})
			return dateFieldFactory({field})
		case 'year':
			return yearFieldFactory({field})
		case 'boolean':
			return checkboxFieldFactory({field})
		case 'govt-dept':
			return govtDepartmentFieldFactory({field})
	}

	return null
}

const DynamicField = ({field}) => (
	<div style={{marginBottom:'10px'}}>
		<DynamicFieldContent field={field}/>
	</div>
)

DynamicField.propTypes = {
	field: PropTypes.object.isRequired
}

export default pure(DynamicField)