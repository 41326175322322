import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, fetchListingOfferPageSettings, updateListingOfferPageSettings } from '../../../redux/actions'
import { selectEntertainer, selectListingSettings } from '../../../redux/selectors'

import { MUIFormTextAreaInput } from '../../@Common/Form/TextAreaInput/FormTextAreaInput'

export function EditOfferPageModal({ entertainerId, listing }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)

  const initialValues = useSelector(selectListingSettings(listing.id, 'offerPage'))
  const entertainer = useSelector(selectEntertainer)(entertainerId)

  const getListingOfferPageSettings = () => {
    dispatch(fetchListingOfferPageSettings(listing.id))
      .then(() => {
        setProcessing(false)
      })
  }
  const onCancel = () => dispatch(hideModal())

  const {
    control, handleSubmit, reset, watch,
  } = useForm()

  useEffect(() => {
    reset(initialValues)
  }, [initialValues, reset])

  useEffect(() => {
    getListingOfferPageSettings()
  }, [])

  const onSubmit = (values) => {
    const settings = {}

    settings.welcomeHeadline = values.welcomeHeadline
    settings.welcomeMessage = values.welcomeMessage

    dispatch(updateListingOfferPageSettings(listing.id, settings))
    dispatch(hideModal())
  }

  return (
    <ModalContainer
      processing={processing}
      title={`${entertainer && entertainer.name} Offer Page`}
      modal
      maxWidth="md"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          color: '#222', maxHeight: '70vh', marginTop: '10px', minWidth: 'min-content',
        }}
      >
        <div style={{ position: 'relative', margin: '30px 0px' }}>
          {/* <TextArea name="welcomeHeadline" label="Headline" autoFocus rows={3} maxLength="50" onChange={(e) => setWelcomeHeadlineCharacterCount(e.target.value.length)} /> */}
          <MUIFormTextAreaInput
            name="welcomeHeadline"
            label="Headline"
            control={control}
            autoFocus
            maxLength="50"
          />
          <Typography>
            <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
              { watch('welcomeHeadline')?.length ?? 0}
              {' '}
              of 50
            </small>
          </Typography>
        </div>
        <div style={{ position: 'relative', margin: '30px 0px' }}>
          <MUIFormTextAreaInput
            name="welcomeMessage"
            label="Message"
            control={control}
            autoFocus={false}
            maxLength="300"
          />
          <Typography>
            <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
              { watch('welcomeMessage')?.length ?? 0}
              {' '}
              of 300
            </small>
          </Typography>
        </div>
      </form>
    </ModalContainer>
  )
}

EditOfferPageModal.propTypes = {
  entertainerId: PropTypes.number.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default EditOfferPageModal
