import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const styles = {
    status: {
        display: 'inline-block',
        border: '1px solid',
        background: '#fff',
        padding: '1px 10px',
        fontWeight: '600',
    },
    off: {
        color: 'rgb(128, 128, 128)', //gray
    },
    disabled: {
        color: 'rgb(190, 70, 71)', //red
    },
    live: {
        color: 'rgb(69, 152, 232)', //blue
    },
    listed: {
        color: 'rgb(19, 135, 70)', //green
    }
}

const StatusBadge = ({
    classes,
    status,
}) => (
    status &&
    <span className={classNames(classes.status,
        status == 'Off' ? classes.off :
            status == 'Disabled' ? classes.disabled :
                status == 'Unpublished' ? classes.disabled :
                    status == 'Live' ? classes.live :
                        status == 'Listed' || status == 'Eligible' ? classes.listed :
                            classes.off)}>
        {status}
    </span>
)

export default injectSheet(styles)(StatusBadge)
