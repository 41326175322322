/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import TicketIcon from '@mui/icons-material/LocalActivity'
import Typography from '@mui/material/Typography'
import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'
import { showModal } from '../../../../../../redux/actions'
import DialogSubsectionHeadline from '../../../../../Header/DialogSubsectionHeadline'
import SectionHeadline from '../../../../../Header/SectionHeadline'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    backgroundColor: '#eee',
    borderRadius: '3px',
    textAlign: 'center',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
})
export function Unlinked({
  listing,
  ticketSettings,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const linkAdvantageEntertainer = () => dispatch(showModal('LINK_ADVANTAGE', { listing, ticketSettings }))

  return (
    <div>
      <div className={classes.container}>
        <TicketIcon style={{
          width: '80px', height: '80px', padding: '15px', backgroundColor: '#fff', border: '2px solid #222', borderRadius: '50%',
        }}
        />
        <SectionHeadline>Ticket Links</SectionHeadline>
        <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>To create ticket links, link this listing to an entertainer or venue in the Advantage system.</Typography>
        <Button label="Link to Advantage" color="primary" onClick={() => linkAdvantageEntertainer()} fullWidth={false}>Link to Advantage</Button>
      </div>
      <div className={classes.info}>
        <div className={classes.threeFourths}>
          <DialogSubsectionHeadline>Deploying ticket links</DialogSubsectionHeadline>
          <Typography variant="body2">As a GovX ticket partner, you can deploy verification links directly on your website. Our system automatically generates a unique link for each of your events. You can export and place them on your website to allow customers to initiate the verification process. If they are successfully verified, we'll automatically apply the correct discount and direct them to the right location to purchase tickets.</Typography>
          <Typography variant="body2">If you need to add more events, please contact your account manager.</Typography>
        </div>
        <div className={classes.oneFourth}>
          <DialogSubsectionHeadline>Resources</DialogSubsectionHeadline>
          <Typography><StyledLink contents="Creating and configuring your listing" external to="https://support.govxinc.com/hc/en-us/articles/360028454812-Creating-and-configuring-your-listing" newWindow /></Typography>
          <Typography><StyledLink contents="Deploying ticket links" external to="https://support.govxinc.com/hc/en-us/articles/360028454912-Deploying-verification-via-discount-links" newWindow /></Typography>
        </div>
      </div>
    </div>
  )
}

export default Unlinked
