/* eslint-disable react/prop-types */
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import RoutedMenu from '../../../../RoutedMenu/RoutedMenu'

export function MarketplaceTab() {
  const { affiliate, canUpdate, canUpdateBillingRules } = useOutletContext()

  return (
    <div style={{ margin: '30px 0 0 0' }}>
      <RoutedMenu
        items={[
          { label: 'Customize Page', to: 'customize-page' },
          { label: 'Marketing Assets', to: 'marketing-assets' },
          { label: 'Billing', to: 'billing' },
        ]}
        context={{
          affiliate,
          canUpdate,
          canUpdateBillingRules,
        }}
      />

    </div>
  )
}

export default MarketplaceTab
