import * as defaultFetchOptions from './defaultFetchOptions'
import { getAPIDomain } from '../setup/configuration'
import appInsights from '../applicationInsights'

export const DEFAULT_TIMEOUT = 20000

export const DEFAULT_OPTIONS = {
	useApiDomain: true,
	timeout: DEFAULT_TIMEOUT,
}

export const withTimeout = (fetch, timeout = DEFAULT_TIMEOUT) => {
	let timeoutPromise = new Promise((resolve, reject) => {
		setTimeout(reject, timeout, { fetch: 'timeout' })
	})

	return Promise.race([timeoutPromise, fetch])
		.then(response => response)
		.catch(err => { throw err })
}

export const checkStatus = (response) => {
	if (response.ok) {
		return response
	} else if (response.status == 404) {
		let error = new Error('Not found')
		error.response = response
		throw error
	} else if (response.status === 401) {
		let error = new Error('Unauthorized')
		error.response = response
		error.unauthorized = true
		throw error
	} else if (response.status === 409) {
		let error = new Error('Conflict')
		error.response = response
		throw error
	}

	if (response.headers.get('Content-Type').indexOf('application/json') !== -1)
		return parseJson(response).then(errBody => { throw errBody })

	let error = new Error('Unhandled fetch error')
	error.response = response
	throw error
}

export const parseJson = response => {
	if (response.status === 204)
		return {}
	return response.json()
}

export const track = (url, request, timeout) => {

	return withTimeout(fetch(url, request, timeout), timeout)
		.then(checkStatus)
		.then(response => {
			return response
		})
		.then(parseJson)
		.catch(err => {
			appInsights.ai().trackException({ exception: err })
			throw err
		})
}

export const getJson = (url, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.getJson(), options.timeout)
}

export const postJson = (url, data, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.postJson(data), options.timeout)
}

export const putJson = (url, data, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.putJson(data), options.timeout)
}

export const patchJson = (url, data, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.patchJson(data), options.timeout)
}

export const postFormData = (url, formData, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.postFormData(formData), options.timeout)
}

export const deleteJson = (url, options = DEFAULT_OPTIONS) => {
	options = { ...DEFAULT_OPTIONS, ...options }

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return track(url, defaultFetchOptions.deleteJson(), options.timeout)
}