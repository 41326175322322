import React from 'react'
import PropTypes from 'prop-types'
import MUICheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Field } from 'redux-form'
import injectSheet from 'react-jss'

const styles = {
	reducedPadding: {
		padding: '2px 12px 3px',
	},
	disabled: {
		color: 'rgba(0, 0, 0, 0.87) !important'
	}
}

export const renderCheckbox = ({ input, value, label, hintText, meta: { touched, error }, children, checked, disabled, classes, reducedPadding, ...custom }) => (
	<div>
		<FormControlLabel
			control={
				<MUICheckbox label={label} {...input} value={label} {...custom} checked={checked} disabled={disabled} aria-label={label || hintText} className={reducedPadding ? classes.reducedPadding : ''} />
			}
			label={label || hintText}
			classes={{label: classes?.disabled}}
		/>

		<div
			style={{
				position: 'relative',
				top: '5px',
				fontSize: '12px',
				lineHeight: '12px',
				color: 'rgb(244, 67, 54)',
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
			}}
		>
			{touched && error}
		</div>
	</div>
)

const Checkbox = ({ name, label, hintText, validate, checked, disabled, onChange, reducedPadding, classes }) => (
	<Field
		name={name}
		label={label}
		hintText={hintText}
		checked={checked}
		disabled={disabled}
		onChange={onChange}
		component={renderCheckbox}
		validate={validate}
		classes={classes}
		reducedPadding={reducedPadding}
	/>
)

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	hintText: PropTypes.string,
	validate: PropTypes.array,
}

export default injectSheet(styles)(Checkbox)
