import * as verificationApi from '../../../api/verificationApi'
import { createSelector } from 'reselect'

export const FETCH_DMDCSTATS_REPORT_REQUEST = 'FETCH_DMDCSTATS_REPORT_REQUEST'
export const FETCH_DMDCSTATS_REPORT_SUCCESS = 'FETCH_DMDCSTATS_REPORT_SUCCESS'
export const FETCH_DMDCSTATS_REPORT_FAILURE = 'FETCH_DMDCSTATS_REPORT_FAILURE'

export const fetchDmdcStatsReport = (id, search) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_DMDCSTATS_REPORT_REQUEST })
	return verificationApi.fetchDmdcStatsReport(search)
		.then(response => {
			dispatch({ type: FETCH_DMDCSTATS_REPORT_SUCCESS, report: response, id })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_DMDCSTATS_REPORT_FAILURE, err })
			throw err
		})
}

export const fetchDmdcStatsReports = () => (dispatch, getState, { schema }) => {
	let now = new Date()
	let hourAgo = new Date()
	hourAgo.setHours(now.getHours() - 1)
	let today = new Date()
	today.setHours(0,0,0,0)
	let thirty = new Date()
	thirty.setDate(today.getDate() - 30)

	let promises = [
		dispatch(fetchDmdcStatsReport('dmdcHour', {startDateUtc: hourAgo.toUTCString(), endDateUtc: now.toUTCString(), grouping: 10})),
		dispatch(fetchDmdcStatsReport('dmdcDay', {startDateUtc: today.toUTCString(), endDateUtc: now.toUTCString(), grouping: 60})),
		dispatch(fetchDmdcStatsReport('dmdc30', {startDateUtc: thirty.toUTCString(), endDateUtc: now.toUTCString(), grouping: 1440}))
	]

	return Promise.all(promises)
		.then(responses => {})
		.catch(err => {})
}

export const FETCH_VASTATS_REPORT_REQUEST = 'FETCH_VASTATS_REPORT_REQUEST'
export const FETCH_VASTATS_REPORT_SUCCESS = 'FETCH_VASTATS_REPORT_SUCCESS'
export const FETCH_VASTATS_REPORT_FAILURE = 'FETCH_VASTATS_REPORT_FAILURE'

export const fetchVaStatsReport = (id, search) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_VASTATS_REPORT_REQUEST })
	return verificationApi.fetchVaStatsReport(search)
		.then(response => {
			dispatch({ type: FETCH_VASTATS_REPORT_SUCCESS, report: response, id })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_VASTATS_REPORT_FAILURE, err })
			throw err
		})
}

export const fetchVaStatsReports = () => (dispatch, getState, { schema }) => {
	let now = new Date()
	let hourAgo = new Date()
	hourAgo.setHours(now.getHours() - 1)
	let today = new Date()
	today.setHours(0,0,0,0)
	let thirty = new Date()
	thirty.setDate(today.getDate() - 30)

	let promises = [
		dispatch(fetchVaStatsReport('vaHour', {startDateUtc: hourAgo.toUTCString(), endDateUtc: now.toUTCString(), grouping: 10})),
		dispatch(fetchVaStatsReport('vaDay', {startDateUtc: today.toUTCString(), endDateUtc: now.toUTCString(), grouping: 60})),
		dispatch(fetchVaStatsReport('va30', {startDateUtc: thirty.toUTCString(), endDateUtc: now.toUTCString(), grouping: 1440}))
	]

	return Promise.all(promises)
		.then(responses => {})
		.catch(err => {})
}

export const FETCH_LISTING_STATS_REPORT_REQUEST = 'FETCH_LISTING_STATS_REPORT_REQUEST'
export const FETCH_LISTING_STATS_REPORT_SUCCESS = 'FETCH_LISTING_STATS_REPORT_SUCCESS'
export const FETCH_LISTING_STATS_REPORT_FAILURE = 'FETCH_LISTING_STATS_REPORT_FAILURE'

export const fetchListingStatsReport = (id, search) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_STATS_REPORT_REQUEST })
	return verificationApi.fetchListingStatsReport(search)
		.then(response => {
			dispatch({ type: FETCH_LISTING_STATS_REPORT_SUCCESS, report: response, id })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_STATS_REPORT_FAILURE, err })
			throw err
		})
}

export const fetchListingStatsReports = listingId => (dispatch, getState, { schema }) => {
	let now = new Date()
	let hourAgo = new Date()
	hourAgo.setHours(now.getHours() - 1)
	let today = new Date()
	today.setHours(0,0,0,0)
	let thirty = new Date()
	thirty.setDate(today.getDate() - 30)

	let promises = [
		dispatch(fetchListingStatsReport('listing30', { listingId: listingId, startDateUtc: thirty.toUTCString(), endDateUtc: now.toUTCString(), grouping: 1440 }))
	]

	return Promise.all(promises)
		.then(responses => {})
		.catch(err => {})
}

export const STATE_KEY = 'reports'

export const DEFAULT_STATE = {
	db: {}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_DMDCSTATS_REPORT_SUCCESS:
		case FETCH_VASTATS_REPORT_SUCCESS:
		case FETCH_LISTING_STATS_REPORT_SUCCESS:
			var updated = []
			updated[action.id] = action.report
			return { ...state, db: { ...state.db, ...updated }}
		default:
			return { ...state }
	}
}

export default reducer

export const reportsHashMap = state => state.reports.reports.db

export const selectReport = id => {
	return createSelector(reportsHashMap, reports => reports[id])
}