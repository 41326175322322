/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'
import StyledLink from '../../../../../StyledLink'
import Button from '../../../../../Button'
import {
  fetchEntertainer, fetchEntertainerEventTickets, showModal, requireSettings,
} from '../../../../../../redux/actions'
import { selectEntertainer, selectEventTickets } from '../../../../../../redux/selectors'
import { formatDateTime } from '../../../../../../formatting'
import { getEventTicketsDownloadLink } from '../../../../../../setup/routeConstants'
import { StatusIndicator } from '../../../../../@Common/StatusIndicator/StatusIndicator'

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
  header: {
    marginBottom: '20px',
    display: 'inline-block',
  },
  logo: {
    maxWidth: '100px',
    maxHeight: '100px',
    marginRight: '15px',
    display: 'inline-block',
    float: 'left',
  },
})

function Processing() {
  return (
    <div
      style={{
        position: 'relative',
        top: '10px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <p style={{ marginBottom: '0px', textAlign: 'center' }}>
        <strong>Fetching links...</strong>
      </p>
    </div>
  )
}

export function LinkedEntertainer({
  entertainerId,
  listing,
  canEditListingDeployAdvantageLinks,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)

  const entertainer = useSelector(selectEntertainer)(entertainerId)
  const eventTickets = useSelector(selectEventTickets)(entertainerId)

  const requireSettingsFn = () => dispatch(requireSettings())
  const getEntertainer = (entertainerId) => dispatch(fetchEntertainer(entertainerId))
  const getTickets = (entertainerId) => dispatch(fetchEntertainerEventTickets(listing.id, entertainerId)).then(() => setProcessing(false))
  const viewOfferPage = () => dispatch(showModal('VIEW_OFFER_PAGE', { listing, entertainerId }))
  const editOfferPage = () => dispatch(showModal('EDIT_OFFER_PAGE', { listing, entertainerId }))
  const deleteEntertainer = () => dispatch(showModal('DELETE_ENTERTAINER', { listing, entertainerId }))
  const showInfo = (eventTicket) => dispatch(showModal('SHOW_EVENTTICKET_INFO', { eventTicket }))

  useEffect(() => {
    setProcessing(true)
    requireSettingsFn()
    getEntertainer(entertainerId)
    getTickets(entertainerId)
    setProcessing(false)
  }, [])

  if (processing) return <Processing />
  return (
    <div className={classes.wrapper}>
      <div className={classes.linksSection}>
        <Typography variant="h2" style={{ margin: '0px', padding: '15px 0px 20px', fontSize: '20px' }}>
          Ticket links
        </Typography>
        <div className={classes.header}>
          {entertainer
        && entertainer.logoPictureUrl && (
          <img src={entertainer.logoPictureUrl} className={classes.logo} alt="" />
          )}

          {entertainer && (
          <div style={{ display: 'inline-block' }}>
            <Typography style={{ margin: '0px 0px 10px' }}>
              This listing is linked to entertainer:
              {' '}
              <strong style={{ paddingRight: '20px' }}>
                {entertainer.name}
                {' '}
                (
                {entertainer.id}
                )
              </strong>
              {canEditListingDeployAdvantageLinks && (
              <StyledLink
                styles={{ color: '#f44336' }}
                contents="Delete"
                clickHandlerOnly
                onClick={deleteEntertainer}
              />
              )}
            </Typography>
            {canEditListingDeployAdvantageLinks && (
            <Typography style={{ margin: '10px 0px' }}>
              <StyledLink
                contents="View offer page"
                clickHandlerOnly
                onClick={viewOfferPage}
              />
              <StyledLink
                styles={{ paddingLeft: '10px' }}
                contents="Customize page"
                clickHandlerOnly
                onClick={editOfferPage}
              />
            </Typography>
            )}
          </div>
          )}
        </div>

        {processing ? (
          <Processing />
        ) : eventTickets.length > 0 ? (
          <Table>
            <TableHead style={{ backgroundColor: '#f1f1f1' }}>
              <TableRow style={{ borderRight: 'solid 1px #ccc' }}>
                <TableCell style={{ width: '10px', paddingRight: '0px' }} />
                <TableCell style={{
                  width: '110px', fontSize: '14px', color: '#222', fontWeight: '600',
                }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    paddingRight: '0px',
                    paddingLeft: '0px',
                    fontSize: '14px',
                    color: '#222',
                    fontWeight: '600',
                  }}
                >
                  Description
                </TableCell>
                <TableCell style={{ width: '90px', paddingLeft: '0px', paddingRight: '15px' }}>
                  <div style={{ width: '150px' }}>
                    <StyledLink
                      to={getEventTicketsDownloadLink(entertainerId, listing.id)}
                      contents={(
                        <Button
                          style={{ float: 'right' }}
                          label="Export links"
                          color="primary"
                          smallButton
                          fullWidth={false}
                        />
         )}
                      newWindow
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventTickets
                .filter(
                  (x) => x.hasDiscountedTicket
                && !x.deleted
                && (new Date(x.eventDate) >= new Date() || new Date(x.eventEndDate) >= new Date()),
                )
                .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
                .map((t, i) => (
                  <TableRow key={i}>
                    <TableCell style={{ width: '10px', paddingRight: '0px' }}>
                      <span
                        style={{
                          width: '10px',
                          height: '10px',
                          float: 'left',
                          marginRight: '10px',
                        }}
                      >
                        <StatusIndicator publishedStatus={t.published} />
                      </span>
                    </TableCell>
                    <TableCell style={{ width: '200px' }}>
                      Event ID
                      {t.eventId}
                    </TableCell>
                    <TableCell style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                      {t.eventName}
                      ,
                      {formatDateTime(t.eventDate)}
                      {' '}
                      @
                      {t.venueName}
                    </TableCell>
                    <TableCell style={{ width: '60px', paddingLeft: '0px', textAlign: 'right' }}>
                      <StyledLink contents="View" clickHandlerOnly onClick={() => showInfo(t)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No discounted tickets available for this entertainer.</Typography>
        )}
      </div>
      <div className={classes.info}>
        <div className={classes.threeFourths}>
          <Typography variant="h3" style={{ marginBottom: '10px', fontWeight: 600, fontSize: '16px' }}>
            Deploying ticket links
          </Typography>
          <Typography variant="body2">
            As a GovX ticket partner, you can deploy verification links directly on your website. Our system
            automatically generates a unique link for each of your events. You can export and place them on your
            website to allow customers to initiate the verification process. If they are successfully verified,
            we'll automatically apply the correct discount and direct them to the right location to purchase
            tickets.
          </Typography>
          <Typography variant="body2">
            If you need to add more events, please contact your account manager.
          </Typography>
        </div>
        <div className={classes.oneFourth}>
          <Typography variant="h3" style={{ marginBottom: '10px', fontWeight: 600, fontSize: '16px' }}>
            Resources
          </Typography>
          <Typography>
            <StyledLink
              contents="Creating and configuring your listing"
              external
              to="https://support.govxinc.com/hc/en-us/articles/360028454812-Creating-and-configuring-your-listing"
              newWindow
            />
          </Typography>
          <Typography>
            <StyledLink
              contents="Deploying ticket links"
              external
              to="https://support.govxinc.com/hc/en-us/articles/360028454912-Deploying-verification-via-discount-links"
              newWindow
            />
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default LinkedEntertainer
