/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Box } from '@mui/material'
import SectionHeadline from '../../../../../Header/SectionHeadline'
import MessagingFormGroup from '../../../../../Listings/Listing/Forms/MessagingForm'
import Footer from '../../../../../Listings/Listing/Tabs/Settings/Footer'

export function MessagingForm({ verificationListing }) {
  const formRef = useRef(null)

  return (
    <div style={{ position: 'relative' }}>
      <Box>
        <Box><SectionHeadline>Customize Messaging</SectionHeadline></Box>
        <Box><MessagingFormGroup innerRef={formRef} listing={verificationListing} isWizard={false} /></Box>
        <Box><Footer label="Save" onSubmit={() => formRef.current.requestSubmit()} /></Box>
      </Box>
    </div>
  )
}

export default MessagingForm
