import { LOCATION_CHANGE } from '../../setup/history'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const showModal = (modalType, modalProps) => ({
	type: SHOW_MODAL,
	modalType,
	modalProps,
})

export const hideModal = () => ({ type: HIDE_MODAL })

export const STATE_KEY = 'modal'

export const DEFAULT_STATE = {
	modalType: null,
	modalProps: {},
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case HIDE_MODAL:
			return DEFAULT_STATE
		case SHOW_MODAL:
			return {
				modalType: action.modalType,
				modalProps: action.modalProps,
			}
		case LOCATION_CHANGE:
			return DEFAULT_STATE
		default:
			return state
	}
}

export default reducer
