/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'
import List from '@mui/material/List'
import TaxonomyListItem from './TaxonomyListItem'

export const getOccupationDescendants = (parentOccupation) => {
  if (!parentOccupation || !parentOccupation.children || parentOccupation.children.length < 1) return []

  let childrenIds = parentOccupation.children.map((x) => x.id)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < parentOccupation.children.length; i++) {
    const results = getOccupationDescendants(parentOccupation.children[i])
    if (results && results.length > 0) {
      childrenIds = childrenIds.concat(results)
    }
  }
  return childrenIds
}

const getAncestorIds = (occupation, publishedOccupations) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) return []

  let ancestorIds = [occupation.parentId]
  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  ancestorIds = ancestorIds.concat(getAncestorIds(parent, publishedOccupations))

  return ancestorIds
}

const uncheckAncestors = (occupation, publishedOccupations, selectedOccupationMappings, canAccessPrivateAffiliations) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) { return selectedOccupationMappings }

  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  const selectedSiblings = parent.children.filter(
    (x) => (!!selectedOccupationMappings.find((y) => y.occupationId === x)),
  )

  const enabledPublicOccupations = selectedSiblings.filter((o) => publishedOccupations.find((po) => po.id === o && !po.private))

  if (enabledPublicOccupations.length < 1 && !canAccessPrivateAffiliations) {
    selectedOccupationMappings = selectedOccupationMappings.filter((o) => o.occupationId !== occupation.parentId)
  }
  if (selectedSiblings && selectedSiblings.length > 0) return selectedOccupationMappings

  selectedOccupationMappings = selectedOccupationMappings.filter((som) => som.occupationId !== parent.id)
  return uncheckAncestors(parent, publishedOccupations, selectedOccupationMappings, canAccessPrivateAffiliations)
}

const untoggleAncestors = (occupation, publishedOccupations, selectedOccupationMappings) => {
  if (!occupation.parentId || occupation.parentId === null || occupation.parentId === 0) { return selectedOccupationMappings }

  const parent = publishedOccupations.find((x) => x.id === occupation.parentId)
  const selectedSiblings = parent.children.filter(
    (x) => x !== occupation.id
    && (!!selectedOccupationMappings.find((y) => y.occupationId === x && y.shownInRegistration)),
  )
  if (selectedSiblings && selectedSiblings.length > 0) return selectedOccupationMappings

  const occupationMappingIndex = selectedOccupationMappings.findIndex((x) => x.occupationId === parent.id)
  const occupationMapping = selectedOccupationMappings[occupationMappingIndex]
  if (occupationMapping) {
    occupationMapping.shownInRegistration = false
    return untoggleAncestors(parent, publishedOccupations, selectedOccupationMappings)
  }

  return selectedOccupationMappings
}

function OccupationsForm({
  publishedOccupationsHierarchy,
  publishedOccupations,
  publicAndPublishedOccupationsHierarchy,
  publicAndPublishedOccupations,
  canAccessPrivateAffiliations,
  selectedOccupationMappings,
  setSelectedOccupationMappings,
}) {
  const onCheck = (occupation, checked, onToggle) => {
    const occupationId = occupation.id
    let occupationsToUpdate = [occupationId]
    const allChildrenIds = getOccupationDescendants(occupation)
    if (allChildrenIds && allChildrenIds.length > 0) { occupationsToUpdate = occupationsToUpdate.concat(allChildrenIds) }

    if (checked) {
      occupationsToUpdate = occupationsToUpdate.concat(getAncestorIds(occupation, publishedOccupations))
      const idsToAdd = occupationsToUpdate.filter(
        (x) => !(selectedOccupationMappings.find((y) => y.occupationId === x)),
      )
      const mappingsToAdd = idsToAdd.map((val) => {
        const shownInRegistration = Boolean(publishedOccupations.find((occupation) => occupation.id === val && !occupation.private))
        return { occupationId: val, shownInRegistration }
      })
      selectedOccupationMappings = selectedOccupationMappings.concat(mappingsToAdd)
      // onToggle(occupation, true)
    } else {
      // if user CAN NOT access private affiliations, remove child occupations that are NOT marked private so they cannot be unchecked
      if (!canAccessPrivateAffiliations) {
        occupationsToUpdate = occupationsToUpdate.filter((otu) => !publishedOccupations.find((o) => o.id == otu).private)
      }
      selectedOccupationMappings = selectedOccupationMappings.filter((som) => !occupationsToUpdate.includes(som.occupationId))
      selectedOccupationMappings = uncheckAncestors(
        occupation,
        publishedOccupations,
        selectedOccupationMappings,
        canAccessPrivateAffiliations,
      )
      onToggle(occupation, false)
    }
    setSelectedOccupationMappings(selectedOccupationMappings)
  }
  const onToggle = (occupation, toggled) => {
    const occupationId = occupation.id
    let occupationsToUpdate = [occupationId]
    const allChildrenIds = getOccupationDescendants(occupation)
    if (allChildrenIds && allChildrenIds.length > 0) { occupationsToUpdate = occupationsToUpdate.concat(allChildrenIds) }

    if (toggled) {
      occupationsToUpdate = occupationsToUpdate.concat(getAncestorIds(occupation, publishedOccupations))
    } else {
      selectedOccupationMappings = untoggleAncestors(
        occupation,
        publishedOccupations,
        selectedOccupationMappings,
      )
    }

    occupationsToUpdate.forEach((o) => {
      selectedOccupationMappings = selectedOccupationMappings.map((selectedOccupation) => {
        if (selectedOccupation.occupationId === o && selectedOccupation.shownInRegistration !== toggled) {
          return { ...selectedOccupation, shownInRegistration: toggled }
        }
        return selectedOccupation
      })
    })

    setSelectedOccupationMappings(selectedOccupationMappings)
  }

  const privateAndPublishedOccupations = publishedOccupations.filter((x) => x.private)
  const publicAndPublishedOccupationIds = publicAndPublishedOccupations.map((y) => y.id)

  const availableOccupationsHierarchy = canAccessPrivateAffiliations
    ? publishedOccupationsHierarchy
    : publishedOccupationsHierarchy.filter((x) => publicAndPublishedOccupationIds.includes(x.id))
  return (
    <List style={{ minWidth: 'min-content' }}>
      <div>
        {availableOccupationsHierarchy
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((o, i) => (
            <TaxonomyListItem
              key={o.id}
              index={i}
              occupation={o}
              depth={0}
              onCheck={onCheck}
              onToggle={onToggle}
              siblings={availableOccupationsHierarchy.filter((x) => x.parentId === o.parentId)}
              selectedOccupationMappings={selectedOccupationMappings}
              privateAndPublishedOccupations={privateAndPublishedOccupations}
              publicAndPublishedOccupationIds={publicAndPublishedOccupationIds}
              canAccessPrivateAffiliations={canAccessPrivateAffiliations}
            />
          ))}

      </div>
    </List>
  )
}

export default OccupationsForm
