import { getJson } from './baseApi'

const buildQuery = (search) => {
    const { paging, sorting } = search
    const groupings = { paging, sorting }
    const pairs = []
    for (let groupKey in groupings) {
        let grouping = groupings[groupKey]
        for (let key in grouping) {
            let value = grouping[key]
            if (value != null) {
                pairs.push(`${key}=${value}`)
            }
        }
    }
    return pairs.join('&')
}

export const fetchPartner = partnerId => getJson(`/api/partners/${partnerId}`)

export const fetchPartners = search => getJson(`/api/partners?${buildQuery(search)}`)

export const fetchPartnersByTerm = search => getJson(`/api/partners?SearchTerm=${search}`)

 export const fetchPartnerByListingId = listingId => getJson(`/api/partners/bylisting/${listingId}`)