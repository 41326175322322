/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react'
import EntertainerTickets from './EntertainerTickets'
import VenueTickets from './VenueTickets'
import Unlinked from './Unlinked'

export function AdvantageSection({
  listing,
  ticketSettings,
  canEditListingDeployAdvantageLinks,
}) {
  return (
    <div>
      {
    ticketSettings
      ? ticketSettings.entertainerId
        ? <EntertainerTickets listing={listing} ticketSettings={ticketSettings} entertainerId={ticketSettings.entertainerId} canEditListingDeployAdvantageLinks={canEditListingDeployAdvantageLinks} />
        : ticketSettings.venueId
          ? <VenueTickets listing={listing} ticketSettings={ticketSettings} venueId={ticketSettings.venueId} canEditListingDeployAdvantageLinks={canEditListingDeployAdvantageLinks} />
          : <Unlinked listing={listing} ticketSettings={ticketSettings} />
      : <div>Loading...</div>
  }
    </div>
  )
}

export default AdvantageSection
