import { getJson, postJson } from './baseApi'
import {
	removeUtcFromDateTime,
} from '../formatting'

export const fetchWhitelistedEmailDomains = search => {
	let { expanded, ...timeFrame } = search.filter.timeFrame

	let query = ''
	// search domain name text
	query += 'searchText=' + search.filter.searchText.text
	// timeframe
	query += '&timeFrame.type=' + timeFrame.type
	if (timeFrame.from) {
		let startDate = removeUtcFromDateTime(timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(startDate.format())
	}
	if (timeFrame.to) {
		let endDate = removeUtcFromDateTime(timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(endDate.format())
	}
	// approvalStatuses
	search.filter.approvalStatuses.statuses.filter(m => m.selected).forEach((m, i) => {
		query += '&approvalStatuses.statuses[' + i +']=' + m.type
	})
	// occupationIds
	search.filter.occupationGroups.groups.filter(m => m.selected).forEach((m, i) => {
		query += '&occupationIds.occupationIds[' + i +']=' + m.type
	})
	// paging
	query += '&pageSize=' + search.paging.pageSize

	return getJson(`/api/whitelistedEmailDomains?${query}`)
}

export const createWhitelistedEmailDomains = (domainName, occupationIds) => {
	return postJson(`/api/whitelistedEmailDomains/create-whitelistedEmailDomains`, {
		domainName: domainName,
		occupationIds: occupationIds
	})
}

export const approveWhitelistedEmailDomainById = (whitelistedEmailDomainId) => {
	return postJson(`/api/whitelistedEmailDomains/${whitelistedEmailDomainId}/approve-whitelistedEmailDomain`, {
		id: whitelistedEmailDomainId
	})
}

export const denyWhitelistedEmailDomain = (whitelistedEmailDomainId) => {
	return postJson(`/api/whitelistedEmailDomains/${whitelistedEmailDomainId}/deny-whitelistedEmailDomain`, {
		id: whitelistedEmailDomainId
	})
}

export const deleteWhitelistedEmailDomain = (whitelistedEmailDomainId) => {
	return postJson(`/api/whitelistedEmailDomains/${whitelistedEmailDomainId}/delete-whitelistedEmailDomain`, {
		id: whitelistedEmailDomainId
	})
}