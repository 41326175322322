import { createSelector } from 'reselect'
import { normalize } from 'normalizr'
import * as listingsApi from '../../../api/listingsApi'

export const STATE_KEY = 'offerUsers'

export const DEFAULT_STATE = {
	db: {},
	searches: {},
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result
		}
	}

	let db = {
		...state.db,
		...action.data.entities.offerUsers
	}

	return { ...state, db, searches }
}

export const CREATE_OFFER_USERS_SEARCH = 'CREATE_OFFER_USERS_SEARCH'
export const UPDATE_OFFER_USERS_SEARCH = 'UPDATE_OFFER_USERS_SEARCH'

export const createOfferUserSearch = search => (dispatch, _g, _s) => {
	dispatch({ type: CREATE_OFFER_USERS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const updateOfferUserSearch = search => (dispatch, _g, _s) => {
	dispatch({ type: UPDATE_OFFER_USERS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const SEARCH_OFFER_USERS_REQUEST = 'SEARCH_OFFER_USERS_REQUEST'
export const SEARCH_OFFER_USERS_SUCCESS = 'SEARCH_OFFER_USERS_SUCCESS'
export const SEARCH_OFFER_USERS_FAILURE = 'SEARCH_OFFER_USERS_FAILURE'

export const searchOfferUsers = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_OFFER_USERS_REQUEST })
	let search = selectOfferUsersSearch(searchId)(getState())
	return listingsApi.fetchListingSpecialOfferUserSubscriptionReport(search)
		.then(response => {
			let data2 = response.data.map(d=> ({...d, id: `${d.specialOfferId}-${d.userId}`}))
			const data = normalize(data2, [schema.offerUser])
			dispatch({ type: SEARCH_OFFER_USERS_SUCCESS, data, total: response.total, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_OFFER_USERS_FAILURE, err })
			throw err
		})
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case CREATE_OFFER_USERS_SEARCH:
		case UPDATE_OFFER_USERS_SEARCH:
			return handleSearchChange(state, action)
		case SEARCH_OFFER_USERS_SUCCESS:
			return handleSearchSuccess(state, action)
		default:
			return state
	}
}

export default reducer

export const selectOfferUsersHashMap = state => state.verification.offerUsers.db
export const selectOfferUsersSearchesHashMap = state => state.verification.offerUsers.searches

export const selectOfferUsers = createSelector(
	selectOfferUsersHashMap,
	offerUsers => Object.keys(offerUsers).map(k => offerUsers[k])
)

export const selectOfferUsersSearches = createSelector(
	selectOfferUsersSearchesHashMap,
	searches => Object.keys(searches).map(k => searches[k])
)

export const selectOfferUsersSearch = id => state => state.verification.offerUsers.searches[id]

export const createFilteredOfferUsersSelector = id => {
	return createSelector(
		[selectOfferUsersHashMap, selectOfferUsersSearchesHashMap],
		(userOffers, searches) => {
			if (!searches[id] || !searches[id].result)
				return []
			let results = searches[id].result.map(f => userOffers[f])
			return results
		})
}