import React from 'react'
import ModalContainer from '../../../../../Modals/ModalContainer'
import DataTable from '../../../../../@Common/DataTable';
import {Box} from '@mui/system';
import PropTypes from 'prop-types'

function OfferCommissionRateHistoryModal({onClickCancel, billingRulesHistory}) {
    return (
        <ModalContainer
            title={`Commission Rate History `}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}
            actions={{
                secondaryAction: {label: 'Close', onClick: onClickCancel},
            }}>
            <Box>
                <DataTable
                    autoPageSize
                    hideFooterRowCount
                    disableColumnFilter
                    pagination={billingRulesHistory.length > 5 ? true : false}
                    rows={billingRulesHistory || []}
                    getRowId={(row) => row.startUtc}
                    columns={[
                        {
                            field: 'startUtc',
                            headerName: 'Date',
                            type: 'string',
                            width: 250,
                            valueGetter: (params) => new Date(params.row.startUtc).toLocaleString(),
                            filterable: false
                        },
                        {
                            field: 'commissionRate',
                            type: 'string',
                            width: 250,
                            headerName: 'Commission Rate',
                            valueGetter: (params) => `${ params.row.commissionRate }%`,
                            align: 'left'
                        },
                    ]}
                />
            </Box>
        </ModalContainer>
    )
}

export default OfferCommissionRateHistoryModal

OfferCommissionRateHistoryModal.propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    billingRulesHistory: PropTypes.arrayOf(PropTypes.shape({
        commissionRate: PropTypes.number,
        endUtc: PropTypes.date,
        startUtc: PropTypes.date,
        flatRate: PropTypes.number
    })),
}
