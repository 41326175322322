export const US_STATES_ARRAY = [
    { label: 'AL', key: '1', value: 'AL' },
    { label: 'AK', key: '2', value: 'AK' },
    { label: 'AZ', key: '3', value: 'AZ' },
    { label: 'AR', key: '4', value: 'AR' },
    { label: 'CA', key: '5', value: 'CA' },
    { label: 'CO', key: '6', value: 'CO' },
    { label: 'CT', key: '7', value: 'CT' },
    { label: 'DC', key: '8', value: 'DC' },
    { label: 'DE', key: '9', value: 'DE' },
    { label: 'FL', key: '10', value: 'FL' },
    { label: 'GA', key: '11', value: 'GA' },
    { label: 'HI', key: '12', value: 'HI' },
    { label: 'ID', key: '13', value: 'ID' },
    { label: 'IL', key: '14', value: 'IL' },
    { label: 'IN', key: '15', value: 'IN' },
    { label: 'IA', key: '16', value: 'IA' },
    { label: 'KS', key: '17', value: 'KS' },
    { label: 'KY', key: '18', value: 'KY' },
    { label: 'LA', key: '19', value: 'LA' },
    { label: 'ME', key: '20', value: 'ME' },
    { label: 'MD', key: '21', value: 'MD' },
    { label: 'MA', key: '22', value: 'MA' },
    { label: 'MI', key: '23', value: 'MI' },
    { label: 'MN', key: '24', value: 'MN' },
    { label: 'MS', key: '25', value: 'MS' },
    { label: 'MO', key: '26', value: 'MO' },
    { label: 'MT', key: '27', value: 'MT' },
    { label: 'NE', key: '28', value: 'NE' },
    { label: 'NV', key: '29', value: 'NV' },
    { label: 'NH', key: '30', value: 'NH' },
    { label: 'NJ', key: '31', value: 'NJ' },
    { label: 'NM', key: '32', value: 'NM' },
    { label: 'NY', key: '33', value: 'NY' },
    { label: 'NC', key: '34', value: 'NC' },
    { label: 'ND', key: '35', value: 'ND' },
    { label: 'OH', key: '36', value: 'OH' },
    { label: 'OK', key: '37', value: 'OK' },
    { label: 'OR', key: '38', value: 'OR' },
    { label: 'PA', key: '39', value: 'PA' },
    { label: 'RI', key: '40', value: 'RI' },
    { label: 'SC', key: '41', value: 'SC' },
    { label: 'SD', key: '42', value: 'SD' },
    { label: 'TN', key: '43', value: 'TN' },
    { label: 'TX', key: '44', value: 'TX' },
    { label: 'UT', key: '45', value: 'UT' },
    { label: 'VT', key: '46', value: 'VT' },
    { label: 'VA', key: '47', value: 'VA' },
    { label: 'WA', key: '48', value: 'WA' },
    { label: 'WV', key: '49', value: 'WV' },
    { label: 'WI', key: '50', value: 'WI' },
    { label: 'WY', key: '51', value: 'WY' }
];