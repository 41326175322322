// eslint-disable
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { updateAffiliateStatus, createAffiliateWithStatus } from '../../../redux/actions'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import appInsights from '../../../applicationInsights'
import { useModal } from '../../../hooks/useModal'

export const IsPublished = (affiliate) => affiliate.status === 'Published'

export function UpdateAffiliateStatusModal({
  affiliateId,
  affiliateStatus,
  partnerListingId,
  affiliateSource = '',
}) {
  const dispatch = useDispatch()
  const { closeModal } = useModal()

  const [processing, setProcessing] = useState(false)
  const { handleSubmit, control } = useForm({
    defaultValues: {
      affiliateStatus,
    },
  })

  const onSubmit = (values) => {
    setProcessing(true)
    const newStatus = values.affiliateStatus
    if (affiliateId) {
      return dispatch(updateAffiliateStatus(affiliateId, newStatus))
        .then(() => {
          setProcessing(false)
          closeModal()
        })
        .catch((error) => {
          setProcessing(false)
          appInsights.ai().trackException({ exception: error })
        })
    }

    return dispatch(createAffiliateWithStatus(partnerListingId, newStatus))
      .then(() => {
        setProcessing(false)
        closeModal()
      })
      .catch((error) => {
        setProcessing(false)
        appInsights.ai().trackException({ exception: error })
      })
  }

  return (
    <ModalContainer
      processing={processing}
      title="Marketplace status"
      modal
      maxWidth="xs"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Confirm',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: () => closeModal(),
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        <MUIFormSelectInput
          control={control}
          name="affiliateStatus"
          label="Marketplace Status"
          items={[
            ...((affiliateId && affiliateSource !== 'shopify') || affiliateStatus === 'Off') ? [{ key: 'Off', value: 'Off', label: 'Off' }] : [],
            { key: 'Disabled', value: 'Disabled', label: 'Disabled' },
            { key: 'Live', value: 'Live', label: 'Live' },
            { key: 'Listed', value: 'Listed', label: 'Listed' },
          ]}
        />
      </form>
    </ModalContainer>
  )
}

UpdateAffiliateStatusModal.propTypes = {
  affiliateId: PropTypes.string,
  affiliateStatus: PropTypes.string,
  partnerListingId: PropTypes.string,
  affiliateSource: PropTypes.string,
}

export default UpdateAffiliateStatusModal
