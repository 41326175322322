import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import LazyProtectedRoute from '../LazyProtectedRoute'

const VerificationListingPageComponent = React.lazy(() => import('../../components/Listings/Listing'))

export default {
	path: RouteConstants.verification.listing.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[]}
			component={<VerificationListingPageComponent />} 
		/>
	),
}