/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import Footer from './Footer'
import { fetchListingEmailSettings, updateListingEmailSettings } from '../../../../../redux/actions'
import { selectListingSettings } from '../../../../../redux/selectors'
import { formatEmailSendOptionForDropdown } from '../../../../../formatting'
import DialogSubsectionHeadline from '../../../../Header/DialogSubsectionHeadline'
import { MUIFormSelectInput } from '../../../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../../../@Common/Form/TextInput/FormTextInput'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '-30px',
        bottom: '0px',
        left: '-30px',
        minHeight: '344px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export function EmailSection({ listing }) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)

  const listingSettings = useSelector(selectListingSettings(listing.id, 'email'))

  const getListingEmailSettings = () => {
    dispatch(fetchListingEmailSettings(listing.id)).then(() => setProcessing(false))
  }

  useEffect(() => {
    getListingEmailSettings()
  }, [])

  const onSubmit = (values) => {
    dispatch(updateListingEmailSettings(listing.id, values))
  }

  const {
    control, reset, handleSubmit, watch,
  } = useForm({
    defaultValues: {
      autoApprovalEmailSendOption: listingSettings?.autoApprovalEmailSendOption,
      autoApprovalEmailTemplateId: listingSettings?.autoApprovalEmailTemplateId,
      manualApprovalEmailSendOption: listingSettings?.manualApprovalEmailSendOption,
      manualApprovalEmailTemplateId: listingSettings?.manualApprovalEmailTemplateId,
      rejectionEmailSendOption: listingSettings?.rejectionEmailSendOption,
      rejectionEmailTemplateId: listingSettings?.rejectionEmailTemplateId,
      passwordResetEmailSendOption: listingSettings?.passwordResetEmailSendOption,
      passwordResetEmailTemplateId: listingSettings?.passwordResetEmailTemplateId,
      activationCodeEmailSendOption: listingSettings?.activationCodeEmailSendOption,
      activationCodeEmailTemplateId: listingSettings?.activationCodeEmailTemplateId,
    },
  })

  useEffect(() => {
    reset({
      autoApprovalEmailSendOption: listingSettings?.autoApprovalEmailSendOption,
      autoApprovalEmailTemplateId: listingSettings?.autoApprovalEmailTemplateId,
      manualApprovalEmailSendOption: listingSettings?.manualApprovalEmailSendOption,
      manualApprovalEmailTemplateId: listingSettings?.manualApprovalEmailTemplateId,
      rejectionEmailSendOption: listingSettings?.rejectionEmailSendOption,
      rejectionEmailTemplateId: listingSettings?.rejectionEmailTemplateId,
      passwordResetEmailSendOption: listingSettings?.passwordResetEmailSendOption,
      passwordResetEmailTemplateId: listingSettings?.passwordResetEmailTemplateId,
      activationCodeEmailSendOption: listingSettings?.activationCodeEmailSendOption,
      activationCodeEmailTemplateId: listingSettings?.activationCodeEmailTemplateId,
    })
  }, [listingSettings])

  return (
    <div style={{ position: 'relative' }}>
      {processing || !listingSettings ? (
        <Processing />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p style={{ marginBottom: '24px' }}>
            Throughout the verification process, we communicate with the user via email. You can turn emails on or off or trigger custom emails templates in our system.
          </p>

          <Grid container direction="column">
            <Grid item style={{ paddingBottom: '20px' }}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ minWidth: '300px', paddingBottom: '6px' }}>
                  <DialogSubsectionHeadline>Auto Approval Email</DialogSubsectionHeadline>
                </Grid>
                <Grid item style={{ minWidth: '300px', padding: '0 20px 0px 0px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="autoApprovalEmailSendOption"
                    label="Auto Approve"
                    items={[
                      {
                        key: '1', value: 'Default', label: formatEmailSendOptionForDropdown('Default'), disabled: false,
                      },
                      {
                        key: '2', value: 'None', label: formatEmailSendOptionForDropdown('None'), disabled: false,
                      },
                      {
                        key: '3', value: 'Custom', label: formatEmailSendOptionForDropdown('Custom'), disabled: false,
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  {(watch('autoApprovalEmailSendOption') === 'Custom')
                 && (
                 <Box
                   style={{ width: '250px', top: '0', verticalAlign: 'top' }}
                 >
                   <MUIFormTextInput
                     control={control}
                     name="autoApprovalEmailTemplateId"
                     label="Template ID"
                   />
                 </Box>
                 )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ minWidth: '300px', paddingBottom: '6px' }}>
                  <DialogSubsectionHeadline>Manual Approval Email</DialogSubsectionHeadline>
                </Grid>
                <Grid item style={{ minWidth: '300px', padding: '0 20px 0px 0px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="manualApprovalEmailSendOption"
                    label="Manual Approve"
                    items={[
                      {
                        key: '1', value: 'Default', label: formatEmailSendOptionForDropdown('Default'), disabled: false,
                      },
                      {
                        key: '2', value: 'None', label: formatEmailSendOptionForDropdown('None'), disabled: false,
                      },
                      {
                        key: '3', value: 'Custom', label: formatEmailSendOptionForDropdown('Custom'), disabled: false,
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  {(watch('manualApprovalEmailSendOption') === 'Custom') && (
                  <Box
                    style={{ width: '250px', top: '0', verticalAlign: 'top' }}
                  >
                    <MUIFormTextInput
                      control={control}
                      name="manualApprovalEmailTemplateId"
                      label="Template ID"
                    />
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ minWidth: '300px', paddingBottom: '6px' }}>
                  <DialogSubsectionHeadline>Rejection Email</DialogSubsectionHeadline>
                </Grid>
                <Grid item style={{ minWidth: '300px', padding: '0 20px 0px 0px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="rejectionEmailSendOption"
                    label="Rejection Email"
                    items={[
                      {
                        key: '1', value: 'Default', label: formatEmailSendOptionForDropdown('Default'), disabled: false,
                      },
                      {
                        key: '2', value: 'None', label: formatEmailSendOptionForDropdown('None'), disabled: false,
                      },
                      {
                        key: '3', value: 'Custom', label: formatEmailSendOptionForDropdown('Custom'), disabled: false,
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  {watch('rejectionEmailSendOption') && (
                  <Box
                    style={{ width: '250px', top: '0', verticalAlign: 'top' }}
                  >
                    <MUIFormTextInput
                      control={control}
                      name="rejectionEmailTemplateId"
                      label="Template ID"
                    />
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ minWidth: '300px', paddingBottom: '6px' }}>
                  <DialogSubsectionHeadline>Password Reset Email</DialogSubsectionHeadline>
                </Grid>
                <Grid item style={{ minWidth: '300px', padding: '0 20px 0px 0px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="passwordResetEmailSendOption"
                    label="Password Reset"
                    items={[
                      {
                        key: '1', value: 'Default', label: formatEmailSendOptionForDropdown('Default'), disabled: false,
                      },
                      {
                        key: '2', value: 'None', label: formatEmailSendOptionForDropdown('None'), disabled: false,
                      },
                      {
                        key: '3', value: 'Custom', label: formatEmailSendOptionForDropdown('Custom'), disabled: false,
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  {watch('passwordResetEmailSendOption') === 'Custom' && (
                  <Box
                    style={{ width: '250px', top: '0', verticalAlign: 'top' }}
                  >
                    <MUIFormTextInput
                      control={control}
                      name="passwordResetEmailTemplateId"
                      label="Template ID"
                    />
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ minWidth: '300px', paddingBottom: '6px' }}>
                  <DialogSubsectionHeadline>Activation Code Email</DialogSubsectionHeadline>
                </Grid>
                <Grid item style={{ minWidth: '300px', padding: '0 20px 0px 0px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="activationCodeEmailSendOption"
                    label="Activation Code"
                    items={[
                      {
                        key: '1', value: 'Default', label: formatEmailSendOptionForDropdown('Default'), disabled: false,
                      },
                      {
                        key: '2', value: 'None', label: formatEmailSendOptionForDropdown('None'), disabled: false,
                      },
                      {
                        key: '3', value: 'Custom', label: formatEmailSendOptionForDropdown('Custom'), disabled: false,
                      },
                    ]}
                  />
                </Grid>
                <Grid item>
                  {watch('activationCodeEmailSendOption') === 'Custom'
                 && (
                 <Box
                   style={{ width: '250px', top: '0', verticalAlign: 'top' }}
                 >
                   <MUIFormTextInput
                     control={control}
                     name="activationCodeEmailTemplateId"
                     label="Template ID"
                   />
                 </Box>
                 )}
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Footer label="Save" processing={processing} onSubmit={handleSubmit(onSubmit)} />
        </form>
      )}
    </div>
  )
}

export default EmailSection
