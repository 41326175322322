/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import PropTypes from 'prop-types'
import ModalContainer from '../ModalContainer'
import { formatDateTime } from '../../../formatting'
import { hideModal, showSuccesNotification } from '../../../redux/actions'

export function EventTicketInfoModal({ eventTicket }) {
  const dispatch = useDispatch()

  const onClose = () => dispatch(hideModal())
  const copyTicketLinkToClipboard = (link) => {
    copy(link)
    dispatch(showSuccesNotification('Link copied to clipboard'))
  }

  return (
    <ModalContainer
      title={`Verification Link: Event ID ${eventTicket.eventId}`}
      maxWidth="md"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div style={{ color: '#222' }}>
        <h4 style={{ marginTop: '25px', marginBottom: '0px' }}><strong>Description</strong></h4>
        <p style={{ marginTop: '7px' }}>
          {eventTicket.eventName}
          ,
          {' '}
          {formatDateTime(eventTicket.eventDate)}
          {' '}
          @
          {' '}
          {eventTicket.venueName}
        </p>
        <h4 style={{ marginTop: '40px', marginBottom: '0px' }}><strong>Full page link</strong></h4>
        <TextField
          name="fullPageLink"
          multiline
          rows={2}
          readOnly
          value={`${eventTicket.link}?display=full`}
          fullWidth
        />
        <div style={{ cursor: 'pointer' }} onClick={() => copyTicketLinkToClipboard(`${eventTicket.link}?display=full`)}>
          <IconButton><ContentCopyIcon /></IconButton>
          <span style={{ color: '#222', fontSize: '12px' }}>Copy to clipboard</span>
        </div>
        <h4 style={{ marginTop: '40px', marginBottom: '0px' }}><strong>Pop-up link</strong></h4>
        <TextField
          name="popupLink"
          multiline
          rows={2}
          readOnly
          value={`${eventTicket.link}?display=popup`}
          fullWidth
        />
        <div style={{ cursor: 'pointer' }} onClick={() => copyTicketLinkToClipboard(`${eventTicket.link}?display=popup`)}>
          <IconButton><ContentCopyIcon /></IconButton>
          <span style={{ color: '#222', fontSize: '12px' }}>Copy to clipboard</span>
        </div>
      </div>
    </ModalContainer>
  )
}

EventTicketInfoModal.propTypes = {
  eventTicket: PropTypes.shape({
    eventId: PropTypes.number,
    eventName: PropTypes.string,
    eventDate: PropTypes.string,
    venueName: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
}

export default EventTicketInfoModal
