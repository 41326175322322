import { getJson, postJson } from './baseApi'

export const fetchOccupationInfo = id => {
	return getJson(`/api/occupations/${id}/info`)
}

export const fetchGovernmentDepartments = () => {
	return getJson('/api/govt-departments')
}

export const fetchDmdcStatsReport = search => {
	return postJson('/api/verification/reports/dmdc-stats', search)
}

export const fetchVaStatsReport = search => {
	return postJson('/api/verification/reports/va-stats', search)
}

export const fetchListingStatsReport = search => {
	return postJson('/api/verification/reports/listing-stats', search)
}