import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'

export const reactPlugin = new ReactPlugin()
// *** Add the Click Analytics plug-in. ***
const clickPluginInstance = new ClickAnalyticsPlugin()
const clickPluginConfig = {
  autoCapture: true,
}

// eslint-disable-next-line no-underscore-dangle
let _ai

const applicationInsights = {
  init: (connectionString) => {
    if (!_ai) {
      _ai = new ApplicationInsights({
        config: {
          connectionString,
          extensions: [reactPlugin, clickPluginInstance],
          extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig,
          },
          enableAutoRouteTracking: true,
        },
      })
      _ai.loadAppInsights()
    }
  },
  ai: () => _ai,
}

export default applicationInsights
