import { occupationPrequalifier } from '../../../api/schema'
import * as occupationsApi from '../../../api/occupationsApi'
import { normalize, denormalize } from 'normalizr'
import { createSelector } from 'reselect'

export const FETCH_OCCUPATION_PREQUALIFIERS_REQUEST = 'FETCH_OCCUPATION_PREQUALIFIERS_REQUEST'
export const FETCH_OCCUPATION_PREQUALIFIERS_SUCCESS = 'FETCH_OCCUPATION_PREQUALIFIERS_SUCCESS'
export const FETCH_OCCUPATION_PREQUALIFIERS_FAILURE = 'FETCH_OCCUPATION_PREQUALIFIERS_FAILURE'

export const fetchOccupationPrequalifiers = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_OCCUPATION_PREQUALIFIERS_REQUEST })
	return occupationsApi.fetchOccupationPrequalifiers(id)
		.then(response => {
			const data = normalize(response, [occupationPrequalifier])
			dispatch({ type: FETCH_OCCUPATION_PREQUALIFIERS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_OCCUPATION_PREQUALIFIERS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'occupationPrequalifiers'

export const DEFAULT_STATE = {
	db: {}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_OCCUPATION_PREQUALIFIERS_SUCCESS:
			return { ...state, db: { ...state.db, ...action.data.entities.occupationPrequalifiers }}
		default:
			return { ...state }
	}
}

export default reducer

export const selectOccupationPrequalifiersHashMap = state => state.verification.occupationPrequalifiers.db

export const selectOccupationPrequalifiers = createSelector(
	selectOccupationPrequalifiersHashMap,
	prequal => Object.keys(prequal).map(k => prequal[k])
)

export const selectOccupationPrequalifier = id => state => state.verification.occupationPrequalifiers.db[id]

export const selectOccupationPrequalifiersForOccupation = occupationId => createSelector(selectOccupationPrequalifiers, prequal => prequal.filter(p => p.occupationId === occupationId))