let configuration = {}

export const getConfiguration = () => {
	return configuration
}

export const setConfiguration = options => {
	configuration = { ...configuration, ...options }
	return configuration
}

export const getAPIDomain = () => {
	return configuration.apiDomain
}

export const getGovXAdminDomain = () => {
	return configuration.govXAdminDomain
}

export const getGovXShopDomain = () => {
	return configuration.govXShopDomain
}

export const getListing = () => {
	return configuration.listing
}

export const getIconUrls = () => {
	return configuration.iconUrls
}

export const getFeatureFlags = () => {
	return configuration.featureFlags
}