/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ModalContainer from './ModalContainer'
import { fetchAuthorizationRecords, hideModal } from '../../redux/actions'
import { selectAuthorizationRecordsForVerificationRequest, selectVerificationRequest } from '../../redux/selectors'
import { formatDateTime, formatDateWithoutTimezone } from '../../formatting'
import { getUserPath } from '../../setup/routeConstants'
import StyledLink from '../StyledLink'

const getFailureReasonComponent = (authorizationRecords) => {
  const record = authorizationRecords && authorizationRecords.find((r) => r
    && (r.verificationAuthorizationMethod.toLowerCase() === 'manualrejection'
    || r.verificationAuthorizationMethod.toLowerCase() === 'sponsorcanceled'
    || r.verificationAuthorizationMethod.toLowerCase() === 'sponsorrevoked')
    && r.status.toLowerCase() === 'failed'
    && r.message)
  if (record && record.message) {
    return (
      <div style={{ padding: '15px 5px', color: '#222222', fontSize: '14px' }}>
        <p>
          <strong>Rejection Reason</strong>
          :
        </p>
        <div>{record.message}</div>
      </div>
    )
  }
}

const getDmdcRequestInfoComponent = (request) => {
  if (request.type === 'Dmdc') {
    return (
      <div style={{ padding: '15px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>DMDC request Info</strong>
        </div>
        {request.firstName && (
        <div>
          First name:
          {' '}
          {request.firstName}
        </div>
        )}
        {request.lastName && (
        <div>
          Last name:
          {' '}
          {request.lastName}
        </div>
        )}
        {request.dateOfBirth && (
        <div>
          Date of birth:
          {' '}
          {formatDateWithoutTimezone(request.dateOfBirth)}
        </div>
        )}
        {request.lastYearOfService && request.lastYearOfService > 1900 && (
        <div>
          Last year of service:
          {' '}
          {request.lastYearOfService}
        </div>
        )}
      </div>
    )
  }
}

const getDmdcResponseInfoComponent = (authorizationRecords) => {
  const record = authorizationRecords && authorizationRecords.find((r) => r
    && (['dmdcauto', 'dmdcretry', 'dmdcmanual', 'dmdcfraud', 'dmdcfamauto', 'dmdcfammanual', 'dmdcfamfraud', 'dmdcduplicate', 'dmdcduplicatehardrejection']
      .includes(r.verificationAuthorizationMethod.toLowerCase()))
    && r.metaData
    && (
      r.metaData['dmdc-start-date']
      || r.metaData['dmdc-end-date']
      || r.metaData['dmdc-component-code']
      || r.metaData['dmdc-component-string']
      || r.metaData['dmdc-covered-type']
    ))
  if (record) {
    return (
      <div style={{ padding: '5px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>DMDC response info</strong>
        </div>
        {record.metaData['dmdc-start-date'] && (
        <div>
          Start Date:
          {' '}
          {record.metaData['dmdc-start-date']}
        </div>
        )}
        {record.metaData['dmdc-end-date'] && (
        <div>
          End Date:
          {' '}
          {record.metaData['dmdc-end-date']}
        </div>
        )}
        {record.metaData['dmdc-component-code'] && (
        <div>
          Component Code:
          {' '}
          {record.metaData['dmdc-component-code']}
        </div>
        )}
        {record.metaData['dmdc-component-string'] && (
        <div>
          Component String:
          {' '}
          {record.metaData['dmdc-component-string']}
        </div>
        )}
        {record.metaData['dmdc-covered-type'] && (
        <div>
          Covered Type:
          {' '}
          {record.metaData['dmdc-covered-type']}
        </div>
        )}
      </div>
    )
  }
}

const getVaRequestInfoComponent = (request) => {
  if (request.type === 'Va') {
    return (
      <div style={{ padding: '15px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>VA request Info</strong>
        </div>
        {request.firstName && (
        <div>
          First name:
          {' '}
          {request.firstName}
        </div>
        )}
        {request.lastName && (
        <div>
          Last name:
          {' '}
          {request.lastName}
        </div>
        )}
        {request.dateOfBirth && (
        <div>
          Date of birth:
          {' '}
          {formatDateWithoutTimezone(request.dateOfBirth)}
        </div>
        )}
        {request.gender && (
        <div>
          Gender:
          {' '}
          {request.gender}
        </div>
        )}
        {request.addressLine1 && (
        <div>
          Address Line 1:
          {' '}
          {request.addressLine1}
        </div>
        )}
        {request.city && (
        <div>
          City:
          {' '}
          {request.city}
        </div>
        )}
        {request.state && (
        <div>
          State:
          {' '}
          {request.state}
        </div>
        )}
        {request.zipCode && (
        <div>
          Zip Code:
          {' '}
          {request.zipCode}
        </div>
        )}
      </div>
    )
  }
}

const getVaResponseInfoComponent = (authorizationRecords) => {
  const record = authorizationRecords && authorizationRecords.find((r) => r
    && (['vamanual']
      .includes(r.verificationAuthorizationMethod.toLowerCase()))
    && r.metaData
    && (
      r.metaData['va-authorization-status']
      || r.metaData['va-authorization-id']
      || r.metaData['va-not-confirmed-reason']
      || r.metaData['va-error-message']
    ))
  if (record) {
    return (
      <div style={{ padding: '5px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>VA response info</strong>
        </div>
        {record.metaData['va-authorization-status'] && (
        <div>
          Status:
          {' '}
          {record.metaData['va-authorization-status']}
        </div>
        )}
        {record.metaData['va-authorization-id'] && (
        <div>
          VA Authorization ID:
          {' '}
          {record.metaData['va-authorization-id']}
        </div>
        )}
        {record.metaData['va-not-confirmed-reason'] && (
        <div>
          Reason:
          {' '}
          {record.metaData['va-not-confirmed-reason']}
        </div>
        )}
        {record.metaData['va-error-message'] && (
        <div>
          Error Message:
          {' '}
          {record.metaData['va-error-message']}
        </div>
        )}
      </div>
    )
  }
}

const getDmdcDuplicateLinkComponent = (authorizationRecords) => {
  const record = authorizationRecords && authorizationRecords.find((r) => r
    && (r.verificationAuthorizationMethod.toLowerCase() === 'dmdcduplicate' || r.verificationAuthorizationMethod.toLowerCase() === 'dmdcduplicatehardrejection')
    && r.status.toLowerCase() === 'failed'
    && r.metaData
    && r.metaData['dmdc-duplicated-user-id'])
  if (record) {
    return (
      <div style={{ padding: '5px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>DMDC Duplicate of existing User</strong>
        </div>
        <div>
          Duplicated User ID:
          <StyledLink
            to={getUserPath(record.metaData['dmdc-duplicated-user-id'])}
            contents={(
              <span style={{ paddingLeft: '10px' }}>
                {record.metaData['dmdc-duplicated-user-id']}
              </span>
     )}
          />
        </div>
      </div>
    )
  }
}

const getEmailDuplicateLinkComponent = (authorizationRecords) => {
  const record = authorizationRecords && authorizationRecords.find((r) => r
  && (r.verificationAuthorizationMethod.toLowerCase() === 'emailduplicate' || r.verificationAuthorizationMethod.toLowerCase() === 'emailduplicatehardrejection')
    && r.status.toLowerCase() === 'failed'
    && r.metaData
    && r.metaData['email-duplicated-user-id'])
  if (record) {
    return (
      <div style={{ padding: '5px 5px 0px', color: '#222222', fontSize: '14px' }}>
        <div style={{ margin: '10px 0px 5px' }}>
          <strong>Email Duplicate of existing User</strong>
        </div>
        <div>
          Duplicated User ID:
          <StyledLink
            to={getUserPath(record.metaData['email-duplicated-user-id'])}
            contents={(
              <span style={{ paddingLeft: '10px' }}>
                {record.metaData['email-duplicated-user-id']}
              </span>
     )}
          />
        </div>
      </div>
    )
  }
}

export function VerificationRequestLogModal({ verificationRequestId }) {
  const dispatch = useDispatch()

  const verificationRequest = useSelector(selectVerificationRequest(verificationRequestId))
  const authorizationRecords = useSelector(selectAuthorizationRecordsForVerificationRequest(verificationRequestId))

  const getAuthorizationRecords = () => dispatch(fetchAuthorizationRecords(verificationRequestId))
  const onCancel = () => dispatch(hideModal())

  useEffect(() => {
    getAuthorizationRecords()
  }, [])

  return (
    <ModalContainer
      title="Verification request log"
      maxWidth="sm"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onCancel,
        },
      }}
    >
      <div style={{ marginTop: '20px' }}>
        <Table>
          <TableHead style={{ backgroundColor: '#f1f1f1' }}>
            <TableRow>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Timestamp
              </TableCell>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Log
              </TableCell>
              <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                Updated by
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{formatDateTime(verificationRequest.createdOnUtc)}</TableCell>
              <TableCell>Created</TableCell>
              <TableCell />
            </TableRow>
            {(authorizationRecords || []).map((r) => (
              <TableRow key={r.id}>
                <TableCell>{formatDateTime(r.createdOnUtc)}</TableCell>
                <TableCell>
                  {r.status}
                  {' '}
                  {r.verificationAuthorizationMethod}
                </TableCell>
                <TableCell>{r.createdByUserName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {getFailureReasonComponent(authorizationRecords)}
        {getDmdcRequestInfoComponent(verificationRequest)}
        {getDmdcResponseInfoComponent(authorizationRecords)}
        {getVaRequestInfoComponent(verificationRequest)}
        {getVaResponseInfoComponent(authorizationRecords)}
        {getDmdcDuplicateLinkComponent(authorizationRecords)}
        {getEmailDuplicateLinkComponent(authorizationRecords)}
      </div>
    </ModalContainer>
  )
}

export default VerificationRequestLogModal
