import { Box } from '@mui/material';
import React from 'react'

const DialogSubsectionHeadline = ({ marginTop = null, children }) => {

	const style = {
		fontFamily: '"Roboto Medium", Helvetica, sans-serif',
		fontSize: '.875rem',
		fontWeight: '600',
		color: 'rgba(0, 0, 0, 0.87)',
		lineHeight: '1.5',
		marginBottom: '18px',
		marginTop: marginTop ?? '18px',
	}

	return (
		<Box style={style}>{children}</Box>
	)
}

export default DialogSubsectionHeadline;
