import { createSlice } from "@reduxjs/toolkit";
import { api } from './apiSlice'

export const lookupsApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getOffersLookups: build.query({
            query: () => `/offers/lookups`,
        }),
    })
})

const initialState = [];
const lookupsSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {},
})

export const {
    useGetOffersLookupsQuery,
} = lookupsApiSlice;

export default lookupsSlice.reducer;