import { createSelector } from 'reselect'
import { normalize, denormalize } from 'normalizr'
import { listing, listingVerificationLink, listingName } from '../../../api/schema'
import * as listingsApi from '../../../api/listingsApi'
import * as picturesApi from '../../../api/picturesApi'

export const SET_LISTING = 'SET_LISTING'

export const setListing = id => (dispatch, getState, { schema }) => {
	dispatch({ type: SET_LISTING, id })
	return new Promise(resolve => resolve(id))
}

export const CREATE_LISTINGS_SEARCH = 'CREATE_LISTINGS_SEARCH'

export const createListingsSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_LISTINGS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const UPDATE_LISTINGS_SEARCH = 'UPDATE_LISTINGS_SEARCH'

export const updateListingsSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_LISTINGS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const SEARCH_LISTINGS_REQUEST = 'SEARCH_LISTINGS_REQUEST'
export const SEARCH_LISTINGS_SUCCESS = 'SEARCH_LISTINGS_SUCCESS'
export const SEARCH_LISTINGS_FAILED = 'SEARCH_LISTINGS_FAILED'

export const searchListings = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_LISTINGS_REQUEST })
	let search = selectListingsSearch(searchId)(getState())
	return listingsApi.searchListings(search)
		.then(response => {
			const data = normalize(response.data, [schema.listing])
			dispatch({ type: SEARCH_LISTINGS_SUCCESS, data, total: response.total, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_LISTINGS_FAILED, err })
			throw err
		})
}

export const requireListings = (dispatch, getState, { schema }, asset) => {
	let currentState = getState()

	if (selectListings(currentState).length !== 0)
		return new Promise(resolve => resolve())

	return fetchAllListings()(dispatch, getState, { schema })
}

export const FETCH_LISTINGS_REQUEST = 'FETCH_LISTINGS_REQUEST'
export const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS'
export const FETCH_LISTINGS_FAILURE = 'FETCH_LISTINGS_FAILURE'

export const fetchAllListings = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTINGS_REQUEST })
	return listingsApi.fetchAllListings()
		.then(response => {
			const data = normalize(response, [schema.listing])
			dispatch({ type: FETCH_LISTINGS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_NAMES_REQUEST = 'FETCH_LISTING_NAMES_REQUEST'
export const FETCH_LISTING_NAMES_SUCCESS = 'FETCH_LISTING_NAMES_SUCCESS'
export const FETCH_LISTING_NAMES_FAILURE = 'FETCH_LISTING_NAMES_FAILURE'

export const fetchListingNames = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_NAMES_REQUEST })
	return listingsApi.fetchListingNames()
		.then(response => {
			const data = normalize(response, [schema.listingName])
			dispatch({ type: FETCH_LISTING_NAMES_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_NAMES_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_REQUEST = 'FETCH_LISTING_REQUEST'
export const FETCH_LISTING_SUCCESS = 'FETCH_LISTING_SUCCESS'
export const FETCH_LISTING_FAILED = 'FETCH_LISTING_FAILED'

export const fetchListing = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_REQUEST })
	return listingsApi.fetchListing(id)
		.then(response => {
			let data = normalize(response, schema.listing)
			dispatch({ type: FETCH_LISTING_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_FAILED, err })
			throw err
		})
}

export const FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_REQUEST = 'FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_REQUEST'
export const FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_SUCCESS = 'FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_SUCCESS'
export const FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_FAILURE = 'FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_FAILURE'

export const fetchVerificationListingByPartnerListingId = partnerListingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_REQUEST })
	return listingsApi.fetchVerificationListingByPartnerListingId(partnerListingId)
		.then(response => {
			let data = normalize(response, schema.listing)
			dispatch({ type: FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_FAILURE, err })
			throw err
		})
}

export const CREATE_LISTING_REQUEST = 'CREATE_LISTING_REQUEST'
export const CREATE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS'
export const CREATE_LISTING_FAILED = 'CREATE_LISTING_FAILED'

export const createListing = listing => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_LISTING_REQUEST })

	return listingsApi.createListing(listing)
		.then(response => {
			const data = normalize(response, schema.listing)
			dispatch({ type: CREATE_LISTING_SUCCESS, data })
			return response.id
		})
		.catch(err => {
			dispatch({ type: CREATE_LISTING_FAILED, err })
			throw err
		})
}

export const UPDATE_LISTING_REQUEST = 'UPDATE_LISTING_REQUEST'
export const UPDATE_LISTING_SUCCESS = 'UPDATE_LISTING_SUCCESS'
export const UPDATE_LISTING_FAILURE = 'UPDATE_LISTING_FAILURE'

export const updateListing = listing => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_LISTING_REQUEST })
	return listingsApi.updateListing(listing)
		.then(response => {
			const data = normalize(response, schema.listing)
			dispatch({ type: UPDATE_LISTING_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_SETTINGS_REQUEST = 'UPDATE_LISTING_SETTINGS_REQUEST'
export const UPDATE_LISTING_SETTINGS_SUCCESS = 'UPDATE_LISTING_SETTINGS_SUCCESS'
export const UPDATE_LISTING_SETTINGS_FAILURE = 'UPDATE_LISTING_SETTINGS_FAILURE'

export const updateListingSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_SETTINGS_REQUEST })
	return listingsApi.updateListingSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_SETTINGS_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_TEMPLATE_SETTINGS_REQUEST = 'FETCH_LISTING_TEMPLATE_SETTINGS_REQUEST'
export const FETCH_LISTING_TEMPLATE_SETTINGS_SUCCESS = 'FETCH_LISTING_TEMPLATE_SETTINGS_SUCCESS'
export const FETCH_LISTING_TEMPLATE_SETTINGS_FAILURE = 'FETCH_LISTING_TEMPLATE_SETTINGS_FAILURE'

export const fetchListingTemplateSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_TEMPLATE_SETTINGS_REQUEST })
	return listingsApi.getListingTemplateSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_TEMPLATE_SETTINGS_SUCCESS, listingId: listingId, key: 'template', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_TEMPLATE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_TEMPLATE_SETTINGS_REQUEST = 'UPDATE_LISTING_TEMPLATE_SETTINGS_REQUEST'
export const UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS = 'UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS'
export const UPDATE_LISTING_TEMPLATE_SETTINGS_FAILURE = 'UPDATE_LISTING_TEMPLATE_SETTINGS_FAILURE'

export const updateListingTemplateSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_TEMPLATE_SETTINGS_REQUEST })
	return listingsApi.updateListingTemplateSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS, listingId: listingId, key: 'template', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_TEMPLATE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_MESSAGING_SETTINGS_REQUEST = 'FETCH_LISTING_MESSAGING_SETTINGS_REQUEST'
export const FETCH_LISTING_MESSAGING_SETTINGS_SUCCESS = 'FETCH_LISTING_MESSAGING_SETTINGS_SUCCESS'
export const FETCH_LISTING_MESSAGING_SETTINGS_FAILURE = 'FETCH_LISTING_MESSAGING_SETTINGS_FAILURE'

export const fetchListingMessagingSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_MESSAGING_SETTINGS_REQUEST })
	return listingsApi.getListingMessagingSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_MESSAGING_SETTINGS_SUCCESS, listingId: listingId, key: 'messaging', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_MESSAGING_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_MESSAGING_SETTINGS_REQUEST = 'UPDATE_LISTING_MESSAGING_SETTINGS_REQUEST'
export const UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS = 'UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS'
export const UPDATE_LISTING_MESSAGING_SETTINGS_FAILURE = 'UPDATE_LISTING_MESSAGING_SETTINGS_FAILURE'

export const updateListingMessagingSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_MESSAGING_SETTINGS_REQUEST })
	return listingsApi.updateListingMessagingSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS, listingId: listingId, key: 'messaging', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_MESSAGING_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_DATASHARING_SETTINGS_REQUEST = 'FETCH_LISTING_DATASHARING_SETTINGS_REQUEST'
export const FETCH_LISTING_DATASHARING_SETTINGS_SUCCESS = 'FETCH_LISTING_DATASHARING_SETTINGS_SUCCESS'
export const FETCH_LISTING_DATASHARING_SETTINGS_FAILURE = 'FETCH_LISTING_DATASHARING_SETTINGS_FAILURE'

export const fetchListingDataSharingSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_DATASHARING_SETTINGS_REQUEST })
	return listingsApi.getListingDataSharingSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_DATASHARING_SETTINGS_SUCCESS, listingId: listingId, key: 'dataSharing', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_DATASHARING_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_ADVERTISEMENT_SETTINGS_REQUEST = 'FETCH_LISTING_ADVERTISEMENT_SETTINGS_REQUEST'
export const FETCH_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS = 'FETCH_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS'
export const FETCH_LISTING_ADVERTISEMENT_SETTINGS_FAILURE = 'FETCH_LISTING_ADVERTISEMENT_SETTINGS_FAILURE'

export const fetchListingAdvertisementSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_ADVERTISEMENT_SETTINGS_REQUEST })
	return listingsApi.getListingAdvertisementSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS, listingId: listingId, key: 'advertisement', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_ADVERTISEMENT_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_ADVERTISEMENT_SETTINGS_REQUEST = 'UPDATE_LISTING_ADVERTISEMENT_SETTINGS_REQUEST'
export const UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS = 'UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS'
export const UPDATE_LISTING_ADVERTISEMENT_SETTINGS_FAILURE = 'UPDATE_LISTING_ADVERTISEMENT_SETTINGS_FAILURE'

export const updateListingAdvertisementSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_ADVERTISEMENT_SETTINGS_REQUEST })
	return listingsApi.updateListingAdvertisementSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS, listingId: listingId, key: 'advertisement', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_ADVERTISEMENT_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_OFFER_PAGE_SETTINGS_REQUEST = 'FETCH_LISTING_OFFER_PAGE_SETTINGS_REQUEST'
export const FETCH_LISTING_OFFER_PAGE_SETTINGS_SUCCESS = 'FETCH_LISTING_OFFER_PAGE_SETTINGS_SUCCESS'
export const FETCH_LISTING_OFFER_PAGE_SETTINGS_FAILURE = 'FETCH_LISTING_OFFER_PAGE_SETTINGS_FAILURE'

export const fetchListingOfferPageSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_OFFER_PAGE_SETTINGS_REQUEST })
	return listingsApi.getListingOfferPageSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_OFFER_PAGE_SETTINGS_SUCCESS, listingId: listingId, key: 'offerPage', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_OFFER_PAGE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_OFFER_PAGE_SETTINGS_REQUEST = 'UPDATE_LISTING_OFFER_PAGE_SETTINGS_REQUEST'
export const UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS = 'UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS'
export const UPDATE_LISTING_OFFER_PAGE_SETTINGS_FAILURE = 'UPDATE_LISTING_OFFER_PAGE_SETTINGS_FAILURE'

export const updateListingOfferPageSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_OFFER_PAGE_SETTINGS_REQUEST })

	return listingsApi.updateListingOfferPageSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS, listingId: listingId, key: 'offerPage', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_OFFER_PAGE_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_DATASHARING_SETTINGS_REQUEST = 'UPDATE_LISTING_DATASHARING_SETTINGS_REQUEST'
export const UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS = 'UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS'
export const UPDATE_LISTING_DATASHARING_SETTINGS_FAILURE = 'UPDATE_LISTING_DATASHARING_SETTINGS_FAILURE'

export const updateListingDataSharingSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_DATASHARING_SETTINGS_REQUEST })
	return listingsApi.updateListingDataSharingSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS, listingId: listingId, key: 'dataSharing', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_DATASHARING_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_EMAIL_SETTINGS_REQUEST = 'FETCH_LISTING_EMAIL_SETTINGS_REQUEST'
export const FETCH_LISTING_EMAIL_SETTINGS_SUCCESS = 'FETCH_LISTING_EMAIL_SETTINGS_SUCCESS'
export const FETCH_LISTING_EMAIL_SETTINGS_FAILURE = 'FETCH_LISTING_EMAIL_SETTINGS_FAILURE'

export const fetchListingEmailSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_EMAIL_SETTINGS_REQUEST })
	return listingsApi.fetchListingEmailSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_EMAIL_SETTINGS_SUCCESS, listingId: listingId, key: 'email', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_EMAIL_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_EMAIL_SETTINGS_REQUEST = 'UPDATE_LISTING_EMAIL_SETTINGS_REQUEST'
export const UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS = 'UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS'
export const UPDATE_LISTING_EMAIL_SETTINGS_FAILURE = 'UPDATE_LISTING_EMAIL_SETTINGS_FAILURE'

export const updateListingEmailSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_EMAIL_SETTINGS_REQUEST })
	return listingsApi.updateListingEmailSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS, listingId: listingId, key: 'email', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_EMAIL_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_LOGINS_SETTINGS_REQUEST = 'FETCH_LISTING_LOGINS_SETTINGS_REQUEST'
export const FETCH_LISTING_LOGINS_SETTINGS_SUCCESS = 'FETCH_LISTING_LOGINS_SETTINGS_SUCCESS'
export const FETCH_LISTING_LOGINS_SETTINGS_FAILURE = 'FETCH_LISTING_LOGINS_SETTINGS_FAILURE'

export const fetchListingLoginsSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_LOGINS_SETTINGS_REQUEST })
	return listingsApi.getListingLoginsSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_LOGINS_SETTINGS_SUCCESS, listingId: listingId, key: 'logins', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_LOGINS_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_LOGINS_SETTINGS_REQUEST = 'UPDATE_LISTING_LOGINS_SETTINGS_REQUEST'
export const UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS = 'UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS'
export const UPDATE_LISTING_LOGINS_SETTINGS_FAILURE = 'UPDATE_LISTING_LOGINS_SETTINGS_FAILURE'

export const updateListingLoginsSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_LOGINS_SETTINGS_REQUEST })
	return listingsApi.updateListingLoginsSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS, listingId: listingId, key: 'logins', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_LOGINS_SETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_WIZARD_SETTINGS_REQUEST = 'FETCH_LISTING_WIZARD_SETTINGS_REQUEST'
export const FETCH_LISTING_WIZARD_SETTINGS_SUCCESS = 'FETCH_LISTING_WIZARD_SETTINGS_SUCCESS'
export const FETCH_LISTING_WIZARD_SETTINGS_FAILURE = 'FETCH_LISTING_WIZARD_SETTINGS_FAILURE'

export const fetchListingWizardSettings = listingId => (dispatch, getState) => {
	dispatch({ type: FETCH_LISTING_WIZARD_SETTINGS_REQUEST })
	return listingsApi.getListingWizardSettings(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_WIZARD_SETTINGS_SUCCESS, listingId: listingId, key: 'wizard', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_WIZARD_SETTINGS_FAILURE, err })
			throw err
		})
}

export const UPDATE_LISTING_WIZARD_SETTINGS_REQUEST = 'UPDATE_LISTING_WIZARD_SETTINGS_REQUEST'
export const UPDATE_LISTING_WIZARD_SETTINGS_SUCCESS = 'UPDATE_LISTING_WIZARD_SETTINGS_SUCCESS'
export const UPDATE_LISTING_WIZARD_SETTINGS_FAILURE = 'UPDATE_LISTING_WIZARD_SETTINGS_FAILURE'

export const updateListingWizardSettings = (listingId, settings) => (dispatch, getState) => {
	dispatch({ type: UPDATE_LISTING_WIZARD_SETTINGS_REQUEST })
	return listingsApi.updateListingWizardSettings(listingId, settings)
		.then(response => {
			dispatch({ type: UPDATE_LISTING_WIZARD_SETTINGS_SUCCESS, listingId: listingId, key: 'wizard', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_LISTING_WIZARD_SETTINGS_FAILURE, err })
			throw err
		})
}

export const SET_LISTING_OCCUPATIONS_REQUEST = 'SET_LISTING_OCCUPATIONS_REQUEST'
export const SET_LISTING_OCCUPATIONS_SUCCESS = 'SET_LISTING_OCCUPATIONS_SUCCESS'
export const SET_LISTING_OCCUPATIONS_FAILURE = 'SET_LISTING_OCCUPATIONS_FAILURE'

export const setListingOccupations = (listingId, occupationMappings) => (dispatch, getState, { schema }) => {
	dispatch({ type: SET_LISTING_OCCUPATIONS_REQUEST })
	return listingsApi.setListingOccupations(listingId, occupationMappings)
		.then(response => {
			const data = response
			dispatch({ type: SET_LISTING_OCCUPATIONS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: SET_LISTING_OCCUPATIONS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_VERIFICATIONLINKS_REQUEST = 'FETCH_LISTING_VERIFICATIONLINKS_REQUEST'
export const FETCH_LISTING_VERIFICATIONLINKS_SUCCESS = 'FETCH_LISTING_VERIFICATIONLINKS_SUCCESS'
export const FETCH_LISTING_VERIFICATIONLINKS_FAILURE = 'FETCH_LISTING_VERIFICATIONLINKS_FAILURE'

export const fetchListingVerificationLinks = listingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_VERIFICATIONLINKS_REQUEST })
	return listingsApi.fetchListingVerificationLinks(listingId)
		.then(response => {
			const data = normalize(response, [schema.listingVerificationLink])
			dispatch({ type: FETCH_LISTING_VERIFICATIONLINKS_SUCCESS, listingId, links: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_VERIFICATIONLINKS_FAILURE, err })
			throw err
		})
}

export const CREATE_VERIFICATIONLINK_REQUEST = 'CREATE_VERIFICATIONLINK_REQUEST'
export const CREATE_VERIFICATIONLINK_SUCCESS = 'CREATE_VERIFICATIONLINK_SUCCESS'
export const CREATE_VERIFICATIONLINK_FAILURE = 'CREATE_VERIFICATIONLINK_FAILURE'

export const createVerificationLink = link => (dispatch, getState, { schema}) => {
	dispatch({ type: CREATE_VERIFICATIONLINK_REQUEST })
	return listingsApi.createListingVerificationLink(link)
		.then(response => {
			const data = normalize(response, schema.listingVerificationLink)
			dispatch({ type: CREATE_VERIFICATIONLINK_SUCCESS, link: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: CREATE_VERIFICATIONLINK_FAILURE, err })
			throw err
		})
}

export const UPDATE_VERIFICATIONLINK_REQUEST = 'UPDATE_VERIFICATIONLINK_REQUEST'
export const UPDATE_VERIFICATIONLINK_SUCCESS = 'UPDATE_VERIFICATIONLINK_SUCCESS'
export const UPDATE_VERIFICATIONLINK_FAILURE = 'UPDATE_VERIFICATIONLINK_FAILURE'

export const updateVerificationLink = link => (dispatch, getState, { schema}) => {
	dispatch({ type: UPDATE_VERIFICATIONLINK_REQUEST })
	return listingsApi.updateListingVerificationLink(link)
		.then(response => {
			const data = normalize(response, schema.listingVerificationLink)
			dispatch({ type: UPDATE_VERIFICATIONLINK_SUCCESS, link: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_VERIFICATIONLINK_FAILURE, err })
			throw err
		})
}

export const DELETE_VERIFICATIONLINK_REQUEST = 'DELETE_VERIFICATIONLINK_REQUEST'
export const DELETE_VERIFICATIONLINK_SUCCESS = 'DELETE_VERIFICATIONLINK_SUCCESS'
export const DELETE_VERIFICATIONLINK_FAILURE = 'DELETE_VERIFICATIONLINK_FAILURE'

export const deleteVerificationLink = link => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_VERIFICATIONLINK_REQUEST })
	return listingsApi.deleteListingVerificationLink(link)
		.then(response => {
			dispatch({ type: DELETE_VERIFICATIONLINK_SUCCESS, link })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_VERIFICATIONLINK_FAILURE, err })
			throw err
		})
}

export const SAVE_LISTING_LOGO_REQUEST = 'SAVE_LISTING_LOGO_REQUEST'
export const SAVE_LISTING_LOGO_SUCCESS = 'SAVE_LISTING_LOGO_SUCCESS'
export const SAVE_LISTING_LOGO_FAILURE = 'SAVE_LISTING_LOGO_FAILURE'

export const SAVE_LISTING_BACKGROUNDIMAGE_REQUEST = 'SAVE_LISTING_BACKGROUNDIMAGE_REQUEST'
export const SAVE_LISTING_BACKGROUNDIMAGE_SUCCESS = 'SAVE_LISTING_BACKGROUNDIMAGE_SUCCESS'
export const SAVE_LISTING_BACKGROUNDIMAGE_FAILURE = 'SAVE_LISTING_BACKGROUNDIMAGE_FAILURE'

export const saveListingPicture = (listingId, pictureType, formData) => (dispatch, getState) => {
	dispatch({ type: pictureType === 'logo' ? SAVE_LISTING_LOGO_REQUEST : SAVE_LISTING_BACKGROUNDIMAGE_REQUEST })
	return picturesApi.createPicture(formData)
		.then(response => {
			let data = response
			data.listingId = listingId
			data.pictureType = pictureType
			dispatch({ type: pictureType === 'logo' ? SAVE_LISTING_LOGO_SUCCESS : SAVE_LISTING_BACKGROUNDIMAGE_SUCCESS, data })
			return data
		})
		.catch(err => {
			dispatch({ type: pictureType === 'logo' ? SAVE_LISTING_LOGO_FAILURE : SAVE_LISTING_BACKGROUNDIMAGE_FAILURE, err })
			throw err
		})
}

export const PUBLISH_LISTING_REQUEST = 'PUBLISH_LISTING_REQUEST'
export const PUBLISH_LISTING_SUCCESS = 'PUBLISH_LISTING_SUCCESS'
export const PUBLISH_LISTING_FAILURE = 'PUBLISH_LISTING_FAILURE'

export const publishListing = id => (dispatch, getState, { schema }) => {
	dispatch({ type: PUBLISH_LISTING_REQUEST })
	return listingsApi.publishListing(id)
		.then(response => {
			const data = normalize(response, schema.listing)
			dispatch({ type: PUBLISH_LISTING_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: PUBLISH_LISTING_FAILURE, err })
			throw err
		})
}

export const UNPUBLISH_LISTING_REQUEST = 'UNPUBLISH_LISTING_REQUEST'
export const UNPUBLISH_LISTING_SUCCESS = 'UNPUBLISH_LISTING_SUCCESS'
export const UNPUBLISH_LISTING_FAILURE = 'UNPUBLISH_LISTING_FAILURE'

export const unpublishListing = id => (dispatch, getState, { schema }) => {
	dispatch({ type: UNPUBLISH_LISTING_REQUEST })
	return listingsApi.unpublishListing(id)
		.then(response => {
			const data = normalize(response, schema.listing)
			dispatch({ type: UNPUBLISH_LISTING_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UNPUBLISH_LISTING_FAILURE, err })
			throw err
		})
}

export const GENERATE_CLIENT_SECRET_REQUEST = 'GENERATE_CLIENT_SECRET_REQUEST'
export const GENERATE_CLIENT_SECRET_SUCCESS = 'GENERATE_CLIENT_SECRET_SUCCESS'
export const GENERATE_CLIENT_SECRET_FAILURE = 'GENERATE_CLIENT_SECRET_FAILURE'

export const generateClientSecret = listing => (dispatch, getState, { schema}) => {
	dispatch({ type: GENERATE_CLIENT_SECRET_REQUEST })
	return listingsApi.generateClientSecret(listing.id)
		.then(response => {
			dispatch({ type: GENERATE_CLIENT_SECRET_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: GENERATE_CLIENT_SECRET_FAILURE, err })
			throw err
		})
}

export const GENERATE_BEARER_TOKEN_REQUEST = 'GENERATE_BEARER_TOKEN_REQUEST'
export const GENERATE_BEARER_TOKEN_SUCCESS = 'GENERATE_BEARER_TOKEN_SUCCESS'
export const GENERATE_BEARER_TOKEN_FAILURE = 'GENERATE_BEARER_TOKEN_FAILURE'

export const generateBearerToken = listing => (dispatch, getState, { schema}) => {
	dispatch({ type: GENERATE_BEARER_TOKEN_REQUEST })
	return listingsApi.generateBearerToken(listing.id)
		.then(response => {
			dispatch({ type: GENERATE_BEARER_TOKEN_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: GENERATE_BEARER_TOKEN_FAILURE, err })
			throw err
		})
}

export const FETCH_TICKETSETTINGS_REQUEST = 'FETCH_TICKETSETTINGS_REQUEST'
export const FETCH_TICKETSETTINGS_SUCCESS = 'FETCH_TICKETSETTINGS_SUCCESS'
export const FETCH_TICKETSETTINGS_FAILURE = 'FETCH_TICKETSETTINGS_FAILURE'

export const fetchTicketSettings = listing => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_TICKETSETTINGS_REQUEST })
	return listingsApi.fetchTicketSettings(listing.id)
		.then(response => {
			dispatch({ type: FETCH_TICKETSETTINGS_SUCCESS, listingId: listing.id, key: 'tickets', settings: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_TICKETSETTINGS_FAILURE, err })
			throw err
		})
}

export const SAVE_TICKETSETTINGS_REQUEST = 'SAVE_TICKETSETTINGS_REQUEST'
export const SAVE_TICKETSETTINGS_SUCCESS = 'SAVE_TICKETSETTINGS_SUCCESS'
export const SAVE_TICKETSETTINGS_FAILURE = 'SAVE_TICKETSETTINGS_FAILURE'

export const saveTicketSettings = (listing, settings) => (dispatch, getState, { schema }) => {
	dispatch({ type: SAVE_TICKETSETTINGS_REQUEST })
	return listingsApi.saveTicketSettings(listing.id, settings)
		.then(response => {
			dispatch({ type: SAVE_TICKETSETTINGS_SUCCESS, listingId: listing.id, key: 'tickets', settings: settings })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_TICKETSETTINGS_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_REDIRECT_URLS_REQUEST = 'FETCH_LISTING_REDIRECT_URLS_REQUEST'
export const FETCH_LISTING_REDIRECT_URLS_SUCCESS = 'FETCH_LISTING_REDIRECT_URLS_SUCCESS'
export const FETCH_LISTING_REDIRECT_URLS_FAILURE = 'FETCH_LISTING_REDIRECT_URLS_FAILURE'

export const fetchListingRedirectUrls = listingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_LISTING_REDIRECT_URLS_REQUEST })
	return listingsApi.fetchListingRedirectUrls(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_REDIRECT_URLS_SUCCESS, listingId: listingId, urls: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_REDIRECT_URLS_FAILURE, err })
			throw err
		})
}

export const CREATE_LISTING_REDIRECT_URL_REQUEST = 'CREATE_LISTING_REDIRECT_URL_REQUEST'
export const CREATE_LISTING_REDIRECT_URL_SUCCESS = 'CREATE_LISTING_REDIRECT_URL_SUCCESS'
export const CREATE_LISTING_REDIRECT_URL_FAILURE = 'CREATE_LISTING_REDIRECT_URL_FAILURE'

export const createListingRedirectUrl = (listingId, redirectUrl) => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_LISTING_REDIRECT_URL_REQUEST })
	return listingsApi.createListingRedirectUrl(listingId, redirectUrl)
		.then(response => {
			dispatch({ type: CREATE_LISTING_REDIRECT_URL_SUCCESS, listingId: listingId, redirectUrl: redirectUrl })
			return response
		})
		.catch(err => {
			dispatch({ type: CREATE_LISTING_REDIRECT_URL_FAILURE, err })
			throw err
		})
}

export const DELETE_LISTING_REDIRECT_URL_REQUEST = 'DELETE_LISTING_REDIRECT_URL_REQUEST'
export const DELETE_LISTING_REDIRECT_URL_SUCCESS = 'DELETE_LISTING_REDIRECT_URL_SUCCESS'
export const DELETE_LISTING_REDIRECT_URL_FAILURE = 'DELETE_LISTING_REDIRECT_URL_FAILURE'

export const deleteListingRedirectUrl = (listingId, redirectUrl) => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_LISTING_REDIRECT_URL_REQUEST })
	return listingsApi.deleteListingRedirectUrl(listingId, redirectUrl)
		.then(response => {
			dispatch({ type: DELETE_LISTING_REDIRECT_URL_SUCCESS, listingId: listingId, redirectUrl: redirectUrl })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_LISTING_REDIRECT_URL_FAILURE, err })
			throw err
		})
}

export const FETCH_LISTING_SPECIAL_OFFERS_REQUEST = 'FETCH_LISTING_SPECIAL_OFFERS_REQUEST'
export const FETCH_LISTING_SPECIAL_OFFERS_SUCCESS = 'FETCH_LISTING_SPECIAL_OFFERS_SUCCESS'
export const FETCH_LISTING_SPECIAL_OFFERS_FAILURE = 'FETCH_LISTING_SPECIAL_OFFERS_FAILURE'

export const fetchListingSpecialOffers = listingId => (dispatch) => {
	dispatch({ type: FETCH_LISTING_SPECIAL_OFFERS_REQUEST })
	return listingsApi.fetchListingSpecialOffers(listingId)
		.then(response => {
			dispatch({ type: FETCH_LISTING_SPECIAL_OFFERS_SUCCESS, listingId: listingId, specialOffers: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_LISTING_SPECIAL_OFFERS_FAILURE, err })
			throw err
		})
}

export const CREATE_LISTING_SPECIAL_OFFER_REQUEST = 'CREATE_LISTING_SPECIAL_OFFER_REQUEST'
export const CREATE_LISTING_SPECIAL_OFFER_SUCCESS = 'CREATE_LISTING_SPECIAL_OFFER_SUCCESS'
export const CREATE_LISTING_SPECIAL_OFFER_FAILURE = 'CREATE_LISTING_SPECIAL_OFFER_FAILURE'

export const createListingSpecialOffer = (listingId, specialOffer) => (dispatch) => {
	dispatch({ type: CREATE_LISTING_SPECIAL_OFFER_REQUEST })
	return listingsApi.createListingSpecialOffer(listingId, specialOffer)
		.then(response => {
			dispatch({ type: CREATE_LISTING_SPECIAL_OFFER_SUCCESS, listingId: listingId, specialOffer: response })
			return response
		})
		.catch(err => {
			dispatch({ type: CREATE_LISTING_SPECIAL_OFFER_FAILURE, err })
			throw err
		})
}

export const DELETE_LISTING_SPECIAL_OFFER_REQUEST = 'DELETE_LISTING_SPECIAL_OFFER_REQUEST'
export const DELETE_LISTING_SPECIAL_OFFER_SUCCESS = 'DELETE_LISTING_SPECIAL_OFFER_SUCCESS'
export const DELETE_LISTING_SPECIAL_OFFER_FAILURE = 'DELETE_LISTING_SPECIAL_OFFER_FAILURE'

export const deleteListingSpecialOffer = (listingId, specialOffer) => (dispatch) => {
	dispatch({ type: DELETE_LISTING_SPECIAL_OFFER_REQUEST })
	return listingsApi.deleteListingSpecialOffer(listingId, specialOffer)
		.then(response => {
			dispatch({ type: DELETE_LISTING_SPECIAL_OFFER_SUCCESS, listingId: listingId, specialOffer: response })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_LISTING_SPECIAL_OFFER_FAILURE, err })
			throw err
		})
}

export const SAVE_GOVX_LOGIN_REQUEST = 'SAVE_GOVX_LOGIN_REQUEST'
export const SAVE_GOVX_LOGIN_SUCCESS = 'SAVE_GOVX_LOGIN_SUCCESS'
export const SAVE_GOVX_LOGIN_FAILURE = 'SAVE_GOVX_LOGIN_FAILURE'

export const saveGovXLogin = (listing, login) => (dispatch, getState, { schema }) => {
	dispatch({ type: SAVE_GOVX_LOGIN_REQUEST, login })
	return listingsApi.saveGovXLogin(login)
		.then(response => {
			let data = normalize({ ...response, settings: listing.settings }, schema.listing)
			dispatch({ type: SAVE_GOVX_LOGIN_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_GOVX_LOGIN_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'listings'

export const DEFAULT_STATE = {
	db: {},
	publishedDb: {},
	searches: {},
	listingNames: {},
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result
		}
	}

	let db = {
		...state.db,
		...action.data.entities.listings
	}

	return { ...state, db, searches, listingNames }
}

export const updateListingState = (state, action) => {
	var updated = { ...state.db[action.data.result], ...action.data.entities.listings[action.data.result] }
	return { ...state, db: { ...state.db, [action.data.result]: updated }}
}

export const mergeSettings = (state, listingId, settingsKey, settings) => {
	var listing = { ...state.db[listingId] }
	if (!listing.settings)
		listing.settings = {}
	listing.settings = { ...listing.settings, [settingsKey]: settings }
	return {
		...state,
		db: {
			...state.db,
			[listingId]: listing
		}
	}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case SET_LISTING:
			return { ...state, listingId: action.id }
		case FETCH_LISTING_SUCCESS:
		case FETCH_VERIFICATION_LISTING_BY_PARTNER_LISTING_ID_SUCCESS:
		case CREATE_LISTING_SUCCESS:
		case UPDATE_LISTING_SUCCESS:
		case PUBLISH_LISTING_SUCCESS:
		case UNPUBLISH_LISTING_SUCCESS:
			return updateListingState(state, action)
		case SAVE_GOVX_LOGIN_SUCCESS:{
			delete action.data.entities.listings[action.data.result].partnerListingId
			return updateListingState(state, action)
		}
		case CREATE_LISTINGS_SEARCH:
		case UPDATE_LISTINGS_SEARCH:
			return handleSearchChange(state, action)
		case SEARCH_LISTINGS_SUCCESS:
			return handleSearchSuccess(state, action)
		case FETCH_LISTINGS_SUCCESS:
			return { ...state, db: action.data.entities.listings }
		case FETCH_LISTING_NAMES_SUCCESS:
			return { ...state, listingNames: action.data.entities.listingNames }

		case FETCH_LISTING_REDIRECT_URLS_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state
			var updated = { ...listing, redirectUrls: action.urls }
			return { ...state, db: { ...state.db, [action.listingId]: updated }}
		case CREATE_LISTING_REDIRECT_URL_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing || (listing && listing.redirectUrls.includes(action.redirectUrl)))
				return state
			var updated = { ...listing, redirectUrls: [ ...listing.redirectUrls, action.redirectUrl ]}
			return { ...state, db: { ...state.db, [action.listingId]: updated }}
		case DELETE_LISTING_REDIRECT_URL_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state
			var remainingUrls = listing.redirectUrls.filter(url => url !== action.redirectUrl)
			var updated = { ...listing, redirectUrls: remainingUrls}
			return { ...state, db: { ...state.db, [action.listingId]: updated }}
		case FETCH_LISTING_VERIFICATIONLINKS_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state
			var updated = { ...listing, verificationLinks: action.data.entities.listingVerificationLinks }
			return { ...state, db: { ...state.db, [action.listingId]: updated }}
		case CREATE_VERIFICATIONLINK_SUCCESS:
		case UPDATE_VERIFICATIONLINK_SUCCESS:
			var listing = state.db[action.link.listingId]
			if (!listing)
				return state
			var updateLinks = { ...listing, verificationLinks: { ...listing.verificationLinks, ...action.data.entities.listingVerificationLinks }}
			return { ...state, db: { ...state.db, [action.link.listingId]: updateLinks }}
		case DELETE_VERIFICATIONLINK_SUCCESS:
			var listing = state.db[action.link.listingId]
			if (!listing)
				return state
			var deletedLinkId = action.link.id.toString()
			var { [deletedLinkId]: deletedLink, ...rest } = listing.verificationLinks
			var deletedLinks = { ...listing, verificationLinks: rest }
			return { ...state, db: { ...state.db, [action.link.listingId]: deletedLinks }}

		case FETCH_LISTING_SPECIAL_OFFERS_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state

			var updated = { ...listing, specialOffers: action.specialOffers }
			return { ...state, db: { ...state.db, [action.listingId]: updated }}

		case CREATE_LISTING_SPECIAL_OFFER_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state

			var created = { ...listing, specialOffers: [ ...listing.specialOffers, action.specialOffer] }
			return { ...state, db: { ...state.db, [action.listingId]: created }}

		case DELETE_LISTING_SPECIAL_OFFER_SUCCESS:
			var listing = state.db[action.listingId]
			if (!listing)
				return state

			var remainingSpecialOffers = listing.specialOffers.filter(specialOffer => specialOffer.id !== action.specialOffer.id)
			var updated = { ...listing, specialOffers: remainingSpecialOffers }
			return { ...state, db: { ...state.db, [action.listingId]: updated }}
		case FETCH_LISTING_TEMPLATE_SETTINGS_SUCCESS:
		case UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS:
		case FETCH_LISTING_MESSAGING_SETTINGS_SUCCESS:
		case UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS:
		case FETCH_LISTING_DATASHARING_SETTINGS_SUCCESS:
		case UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS:
		case FETCH_LISTING_EMAIL_SETTINGS_SUCCESS:
		case UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS:
		case FETCH_LISTING_LOGINS_SETTINGS_SUCCESS:
		case UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS:
		case FETCH_LISTING_WIZARD_SETTINGS_SUCCESS:
		case UPDATE_LISTING_WIZARD_SETTINGS_SUCCESS:
		case FETCH_TICKETSETTINGS_SUCCESS:
		case SAVE_TICKETSETTINGS_SUCCESS:
		case FETCH_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS:
		case UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS:
		case FETCH_LISTING_OFFER_PAGE_SETTINGS_SUCCESS:
		case UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS:
			return mergeSettings(state, action.listingId, action.key, action.settings)
		default:
			return state
	}
}

export default reducer

export const selectListingsHashMap = state => state.verification.listings.db
export const selectPublishedListingsHashMap = state => state.verification.listings.publishedDb
export const selectListingsSearchesHashMap = state => state.verification.listings.searches
export const selectListingNamesHashMap = state => state.verification.listings.listingNames

export const selectListings = createSelector(
	selectListingsHashMap,
	listings => Object.keys(listings).map(k => listings[k])
)

export const selectPublishedListings = createSelector(
	selectPublishedListingsHashMap,
	listings => Object.keys(listings).map(k => listings[k])
)

export const selectListingsSearches = createSelector(
	selectListingsSearchesHashMap,
	searches => Object.keys(searches).map(k => searches[k])
)

export const selectListingNames = createSelector(
	selectListingNamesHashMap,
	listingNames => Object.keys(listingNames).map(k => listingNames[k])
)

export const createFilteredListingsSelector = id => {
	return createSelector(
		[selectListingsHashMap, selectListingsSearchesHashMap],
		(listings, searches) => {
			if (!searches[id] || !searches[id].result)
				return []
			let results = searches[id].result.map(f => listings[f]).filter(d => d).filter(u => !u.deleted)
			return results
		})
}

export const selectListingsSearch = id => state => state.verification.listings.searches[id]

export const selectListing = id => {
	return createSelector(selectListingsHashMap, listings => listings[id])
}

export const selectPublishedListing = id => {
	return createSelector(selectPublishedListingsHashMap, listings => listings[id])
}

export const selectListingName = id => {
	return createSelector(selectListingNamesHashMap, listingNames => listingNames[id])
}

export const selectVerificationListingByPartnerListingId = partnerListingId => state => FindVerificationListingByPartnerListingId(state.verification.listings.db, partnerListingId)

const FindVerificationListingByPartnerListingId = (obj, partnerListingId) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let item = obj[key]
            if (partnerListingId == item.partnerListingId) {
                return item
            }
        }
    }
    return null
}

export const selectListingVerificationLinks = listingId => {
	return createSelector(
		selectListingsHashMap, listings => {
			if (!listings[listingId] || !listings[listingId].verificationLinks)
				return []
			return Object.keys(listings[listingId].verificationLinks).map(k => listings[listingId].verificationLinks[k])
		}
	)
}

export const selectListingRedirectUrls = listingId => {
	return createSelector(
		selectListingsHashMap, listings => {
			if (!listings[listingId] || !listings[listingId].redirectUrls)
				return []
			return Object.keys(listings[listingId].redirectUrls).map(k => listings[listingId].redirectUrls[k])
		}
	)
}

export const selectListingSettings = (listingId, key) => {
	return createSelector(
		selectListingsHashMap, listings => {
			if (!listings[listingId] || !listings[listingId].settings || !listings[listingId].settings.hasOwnProperty(key)) {
				return null
			}
			return listings[listingId].settings[key]
		})
}

export const selectSpecialOffersByListingId = (listingId) => {
	return createSelector(
		selectListingsHashMap, listings => {
			if (!listings[listingId] || !listings[listingId].specialOffers) {
				return null
			}
			return listings[listingId].specialOffers
		})
}

export const isListingPublished = listing => listing.status & 1