import React from 'react'
import PropTypes from 'prop-types'
import { useOutletContext, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { selectAffiliateByListingId } from '../../../../../redux/selectors'
import RoutedMenu from '../../../../RoutedMenu/RoutedMenu'
import { useGetOffersByPartnerListingIdQuery } from '../../../../../redux/slices/offersSlice'

export function OffersTab() {
  const params = useParams()
  const { verificationListing } = useOutletContext()

  const affiliate = useSelector(selectAffiliateByListingId(params.id))
  const selectActiveOffers = createSelector(
    (res) => res.data,
    (data) => data?.filter((offer) => offer.status !== 'Deleted') ?? [],
  )

  const {
    offers,
    isLoading: offersIsLoading,
    isSuccess: offersIsSuccess,
    isError: offersHasError,
  } = useGetOffersByPartnerListingIdQuery(params.id, {
    selectFromResult: (result) => ({
      ...result,
      offers: selectActiveOffers(result),
    }),
  })

  return (
    <div style={{ margin: '30px 0 0 0' }}>
      <RoutedMenu
        items={[
          { label: 'Offers', to: 'offers' },
          { label: 'Events', to: 'events' },
        ]}
        context={{
          affiliate,
          verificationListing,
          offers,
          offersIsLoading,
          offersIsSuccess,
          offersHasError,
        }}
      />

    </div>
  )
}

OffersTab.propTypes = {
  affiliate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default OffersTab
