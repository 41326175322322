import React from 'react'

const style = {
  fontFamily: '"Roboto Medium", Helvetica, sans-serif',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '1.5',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: '24px',
  marginTop: '0px',
}

const SectionHeadline = ({ children, sx = {} }) => {
  return (
    <h3 style={{ style, ...sx }}>{children}</h3>
  )
}

export default SectionHeadline;