import React, { useState } from 'react'
import { IMaskInput } from 'react-imask'

import classNames from 'classnames'
import MUITextField from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'

export function TextInput(
  {
    label,
    name,
    type = 'text',
    id,
    disabled = false,
    value = '',
    onChange,
    onBlur,
    onFocus,
    invalid = false,
    focused = false,
    prefix,
    suffix,
    inputRef,
    mask,
    normalizeMask = true,
    gxid,
  },
  ref,
) {
  const [isFocused, setIsFocused] = useState(focused)
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames(
          invalid ? 'text-red' : '',
          isFocused || value.length > 0 || prefix
            ? 'top-2 text-xs'
            : 'top-5 text-base',
          isFocused ? 'text-blue-dark' : 'text-gray-500',
          'transition-all block absolute left-3 z-10 text-base select-none pointer-events-none',
        )}
      >
        {label}
      </label>
      <div className="mt-2 relative">
        <div className="absolute text-gray-500 text-base left-3 top-6 mt-px">
          {prefix}
        </div>
        <div className="absolute text-gray-500 text-base right-5 top-6 mt-px">
          {suffix}
        </div>
        <IMaskInput
          inputRef={inputRef}
          mask={mask}
          definitions={{
            '#': /[0-9]/,
          }}
          unmask={normalizeMask}
          onAccept={
            // depending on prop above first argument is
            // `value` if `unmask=false`,
            // `unmaskedValue` if `unmask=true`,
            // `typedValue` if `unmask='typed'`
            (value, mask) => Boolean(mask) && onChange(value)
          }
          type={type}
          name={name}
          id={id}
          className={classNames(
            invalid ? 'border-b-red-600' : 'focus:border-b-blue-dark',
            disabled ? 'bg-gray-100' : 'bg-white',
            prefix ? 'pl-10' : 'pl-3',
            'block w-full text-base rounded-t border-b pt-6 pb-2 pr-10 text-left text-gray-900 border-x border-x-gray-200 border-t border-t-gray-200 focus:outline-none border-gray-600 focus:border-b-2',
          )}
          disabled={disabled}
          onChange={(e) => !mask && onChange(e.currentTarget.value)}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
            onBlur && onBlur()
          }}
          value={value}
          data-gxid={gxid}
        />
      </div>
    </div>
  )
}

const TextMaskCustom = React.forwardRef((props, ref) => {
  const {
    onChange, mask, normalizeMask, ...other
  } = props
  return (
    <IMaskInput
      {...other}
      mask={mask}
      unmask={normalizeMask}
      definitions={{
        '0': /[0-9]/,
        '9': /[0-9]/,
        'H': /[A-Fa-f0-9]/, //hexcode
      }}
      inputRef={ref}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  )
})

export function MUITextInput({
  label,
  name,
  type = 'text',
  size = 'medium',
  id,
  disabled = false,
  value = '',
  onChange,
  onBlur,
  onFocus,
  invalid = false,
  focused = false,
  prefix,
  suffix,
  inputRef,
  mask,
  autoFocus = false,
  shrinkLabel = false,
  normalizeMask = true,
  gxid,
  inputLabelProps,
  styles
}) {

  const handleKeyDown = (e) => {
    if (type === 'number') {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
        return e.preventDefault()
      }
    }
  }

  return (
    <div>
      <MUITextField
        sx={styles}
        size={size === 'small' || size === 'sm' ? 'small' : 'medium'}
        hiddenLabel={!label}
        name={name}
        id={id}
        type={type}
        disabled={disabled}
        value={value}
        variant="filled"
        fullWidth
        label={label}
        error={invalid}
        // onChange={console.log('onChange')}
        autoFocus={autoFocus}
        InputProps={{
          inputComponent: TextMaskCustom,
          inputProps: {
            'data-gxid': gxid,
            mask,
            normalizeMask,
            onChange,
          },
          startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
          endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null,
        }}
        InputLabelProps={shrinkLabel ? { ...inputLabelProps, shrink: shrinkLabel } : inputLabelProps}
        inputRef={inputRef}
        onKeyDown={(e) => handleKeyDown(e)}
      />
    </div>
  )
}
