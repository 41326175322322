import { documentRuleSet } from '../../../api/schema'
import * as documentRuleSetsApi from '../../../api/documentRuleSetsApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

export const FETCH_DOCUMENT_RULE_SETS_REQUEST = 'FETCH_DOCUMENT_RULE_SETS_REQUEST'
export const FETCH_DOCUMENT_RULE_SETS_SUCCESS = 'FETCH_DOCUMENT_RULE_SETS_SUCCESS'
export const FETCH_DOCUMENT_RULE_SETS_FAILURE = 'FETCH_DOCUMENT_RULE_SETS_FAILURE'

export const fetchDocumentRuleSets = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_DOCUMENT_RULE_SETS_REQUEST })
	return documentRuleSetsApi.fetchDocumentRuleSets()
		.then(response => {
			const data = normalize(response, [documentRuleSet])
			return dispatch({ type: FETCH_DOCUMENT_RULE_SETS_SUCCESS, data })
		})
		.catch(err => {
			return dispatch({ type: FETCH_DOCUMENT_RULE_SETS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'documentRuleSets'

export const DEFAULT_STATE = {
	db: {}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_DOCUMENT_RULE_SETS_SUCCESS:
			return { ...state, db: action.data.entities.documentRuleSets }
		default:
			return { ...state }
	}
}

export default reducer

export const selectDocumentRuleSetsHashMap = state => state.verification.documentRuleSets.db

export const selectDocumentRuleSets = createSelector(
	selectDocumentRuleSetsHashMap,
	documentRuleSets => Object.keys(documentRuleSets).map(k => documentRuleSets[k])
)

export const selectDocumentRuleSet = id => createSelector(selectDocumentRuleSetsHashMap, documentRuleSets => documentRuleSets[id])