/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import Typography from '@mui/material/Typography'
import ModalContainer from '../ModalContainer'
import Button from '../../Button'
import { generateClientSecret, hideModal } from '../../../redux/actions'

export function SecretKeyModal({ listing }) {
  const dispatch = useDispatch()

  const [secretKey, setSecretKey] = useState(null)
  const [copied, setCopied] = useState(false)

  const onClose = () => dispatch(hideModal())
  const regenerateSecretKey = () => dispatch(generateClientSecret(listing)).then((response) => {
    setSecretKey(response.key)
    return {}
  })
  const copyKey = () => {
    copy(secretKey)
    setCopied(true)
  }

  return (
    <ModalContainer
      title="Regenerate Secret Key"
      modal
      maxWidth="sm"
      fullWidth="false"
      actions={{
        secondaryAction: {
          label: 'Cancel',
          onClick: onClose,
        },
      }}
    >
      <div style={{ marginTop: '20px' }}>
        {secretKey ? (
          <div>
            <Typography style={{ marginTop: '25px', color: '#222' }}>
              Secret key generated successfully. Click the link below to copy to your clipboard.
            </Typography>
            <div style={{ cursor: 'pointer' }} onClick={() => copyKey(secretKey)}>
              <Typography style={{ marginTop: '25px', color: '#222', fontWeight: 600 }}>{secretKey}</Typography>
            </div>
            {copied && (
            <Typography style={{ margin: '0px' }}>
              <small>Secret key copied to clipboard</small>
            </Typography>
            )}
          </div>
        ) : (
          <div>
            <Typography style={{ color: '#222', marginBottom: '20px' }}>
              Are you sure you want to regenerate your secret key? This action cannot be undone.
            </Typography>
            <Button
              label="Regenerate"
              fullWidth={false}
              style={{ backgroundColor: '#f44336', color: 'white' }}
              onClick={() => regenerateSecretKey(listing)}
            >
              Regenerate
            </Button>
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

SecretKeyModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default SecretKeyModal
