/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import CircularProgress from '@mui/material/CircularProgress'
import { createUseStyles } from 'react-jss'
import Button from '../../../../Button'
import StyledLink from '../../../../StyledLink'
import { fetchListingRedirectUrls, showModal } from '../../../../../redux/actions'
import { selectListingRedirectUrls } from '../../../../../redux/selectors'

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  linkButton: {
    color: '#00a1df',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
})

function Processing() {
  return (
    <div
      style={{
        position: 'relative',
        top: '50px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <p style={{ marginBottom: '0px', textAlign: 'center' }}>
        <strong>Fetching URLs...</strong>
      </p>
    </div>
  )
}

export function RedirectsSection({ listing }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const urls = useSelector(selectListingRedirectUrls(listing.id))

  const createRedirectUrl = () => dispatch(showModal('CREATE_LISTING_REDIRECT_URL', { listingId: listing.id }))
  const deleteRedirectUrl = (url) => dispatch(showModal('DELETE_LISTING_REDIRECT_URL', { listingId: listing.id, redirectUrl: url }))

  useEffect(() => {
    dispatch(fetchListingRedirectUrls(listing.id))
  }, [])

  return (
    <div>
      <div className={classes.header} style={{ alignItems: 'center', marginBottom: '20px' }}>
        <Button
          label="Add URL"
          fullWidth={false}
          color="primary"
          onClick={createRedirectUrl}
          style={{ whiteSpace: 'nowrap' }}
        >
          Add URL
        </Button>
      </div>
      {urls ? (
        urls.length > 0 ? (
          <Table>
            <TableHead style={{ backgroundColor: '#f1f1f1' }}>
              <TableRow>
                <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                  Whitelisted redirect URL
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {urls
            && urls.length > 0
            && urls.map((u, i) => (
              <TableRow key={i}>
                <TableCell>{u}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {u !== 'https://auth.govx.com/tickets/callback' && (
                  <StyledLink contents="Delete" onClick={() => deleteRedirectUrl(u)} clickHandlerOnly />
                  )}
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        ) : (
          <p>No redirect URLs configured</p>
        )
      ) : (
        <Processing />
      )}
    </div>
  )
}

export default RedirectsSection
