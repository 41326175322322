/* eslint-disable react/prop-types */
import React from 'react'
import TemplateForm from '../../Forms/TemplateForm'
import Footer from './Footer'

export function TemplateSection({ listing }) {
  const formRef = React.useRef(null)
  return (
    <div style={{ position: 'relative' }}>
      <TemplateForm innerRef={formRef} listing={listing} isWizard={false} />
      <Footer label="Save" onSubmit={() => formRef.current.requestSubmit()} />
    </div>
  )
}

export default TemplateSection
