import { api } from '../apiSlice'

export const optimisticPublishUnpublish = (publish) => async ({ id, ...offer }, { dispatch, queryFulfilled }) => {
    const patchResult = dispatch(
      api.util.updateQueryData('getOffersByPartnerListingId', offer.listingId, (draft) => {
        const newOffersList = JSON.parse(JSON.stringify(draft)).map(o => {
            if(o.offerId === offer.offerId) {
                return { ...o,  status: publish ? 'Published' : 'Unpublished' }
            }
            return o;
        })
        Object.assign(draft, newOffersList)
      })
    )
    try {
      await queryFulfilled
    } catch {
      patchResult.undo()
    }
  }


export const optimisticDelete = () => async ({ id, ...offer }, { dispatch, queryFulfilled }) => {
    const patchResult = dispatch(
      api.util.updateQueryData('getOffersByPartnerListingId', offer.listingId, (draft) => {
        const newOffersList = JSON.parse(JSON.stringify(draft)).map(o => {
            if(o.offerId === offer.offerId) {
                return { ...o, status: 'Deleted' }
            }
            return o;
        })
        Object.assign(draft, newOffersList)
      })
    )
    try {
      await queryFulfilled
    } catch {
      patchResult.undo()
    }
  }

  export const optimisticCreate = () => async ({ id, ...offer }, { dispatch, queryFulfilled }) => {
    const patchResult = dispatch(
      api.util.updateQueryData('getOffersByPartnerListingId', offer.listingId, (draft) => {
        const newOffersList = [...JSON.parse(JSON.stringify(draft))];
        Object.assign(draft, newOffersList)
      })
    )
    try {
      await queryFulfilled
    } catch {
      patchResult.undo()
    }
  }