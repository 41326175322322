import React from 'react'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_CODE_POOL_PAGE } from '../../auth/policies'

const CodePoolDetailPage = React.lazy(() => import("../../components/CodePools/detail"))

export default {
	path: RouteConstants.codePools.detail.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_CODE_POOL_PAGE]}
			component={<CodePoolDetailPage />} 
		/>
	),
}