import {
  Box, Chip, Grid, Link, Typography,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import ProgressBar from '../../../../../../ProgressBar/ProgressBar'
import StyledLink from '../../../../../../StyledLink'
import MarketplaceStatusBadge from '../../../../MarketplaceStatusBadge'

function CodePoolOfferDetail({
  offer,
  discountTypeName,
  offerType,
  discountTypes,
  offerFont,
  onClickCommissionRateHistory,
  classes,
  channels,
  isSyndicateOffer,
  onClickViewSyndicatedListings,
  canViewSyndicatedListings,
}) {
  const stats = offer.codePool?.statistics
  const getUsageProgress = (cpStats) => (cpStats.totalActive === 0 ? 0
    : Math.floor((cpStats.totalReserved / cpStats.totalActive) * 100))
  const offerDiscountTypes = {
    none: 'None',
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box sx={{ ...offerFont }} className={classes.offerDataLine}>
          Offer Type:
          {' '}
          {offerType.name}
        </Box>
        {(offer.codePool && offer.codePool.status !== 3)
          && (
            <Box sx={offerFont} className={classes.offerDataLine}>
              Code Pool:
              {' '}
              <StyledLink to={`/code-pools/${offer.codePool.id}`} contents={offer.codePool.name} />
              {offer.codePool.published === false
                && (
                  <Box sx={{ display: 'inline', fontSize: '.75rem' }}>
                    <MarketplaceStatusBadge status={offer.codePool.published ? 'Published' : 'Unpublished'} />
                  </Box>
                )}
            </Box>
          )}
        {stats != null
          && (
            <Box sx={{ mt: '20px', maxWidth: '250px' }} className={classes.offerDataLine}>
              <ProgressBar variant="determinate" value={getUsageProgress(stats)} />
              <Typography sx={{ fontSize: '12px', textAlign: 'right', mt: '8px' }}>
                {stats.totalReserved}
                {' '}
                of
                {' '}
                {stats.totalActive}
                {' '}
                codes used
              </Typography>
            </Box>
          )}
      </Grid>
      <Grid item xs={6}>
        {discountTypeName !== offerDiscountTypes.none && (
          <Box>
            <Box sx={offerFont} className={classes.offerDataLine}>
              Discount:
              {' '}
              {offer.discount.scheme.typeName === discountTypes.fixedAmount.type && '$'}
              {offer.discount.scheme.amount}
              {offer.discount.scheme.typeName === discountTypes.percentOff.type && '%'}
            </Box>
          </Box>
        )}
        {!isSyndicateOffer
          && (
            <Box sx={offerFont} className={classes.offerDataLine}>
              Channels:
              {' '}
              {offer.channels?.length > 0 ? offer.channels.map((channel) => <Chip key={channel} sx={{ marginRight: '5px' }} label={channels?.find((x) => x.value === channel).text} size="small" variant="filled" color="default" />)
                : 'None'}
            </Box>
          )}
        {!isSyndicateOffer && (
          <Box style={{ fontSize: '0.875em' }} className={classes.offerDataLine}>
            Commission Rate:
            {' '}
            {offer.billingRules.commissionRate}
            %
            <Link // eslint-disable-line
              sx={{ marginLeft: '8px' }}
              href="#"
              underline="hover"
              onClick={() => onClickCommissionRateHistory(offer)}
            >
              View History
            </Link>
          </Box>
        )}
        {isSyndicateOffer && (
          <Box sx={offerFont} className={classes.offerDataLine}>
            Syndicated from:
            <Link // eslint-disable-line
              sx={{ marginLeft: '8px' }}
              href={`/partners/listings/${offer.listingId}/offers/offers`}
              underline="hover"
            >
              {offer.syndicateListingName}
            </Link>
          </Box>
        )}
        {canViewSyndicatedListings && offer.syndicatedListings !== null && (
          <Box sx={offerFont} className={classes.offerDataLine}>
            Syndicated:
            <Link // eslint-disable-line
              sx={{ marginLeft: '8px', cursor: 'pointer' }}
              onClick={() => onClickViewSyndicatedListings(offer.syndicatedListings)}
              underline="hover"
            >
              View Listings (
              {offer.syndicatedListings.length}
              )
            </Link>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

CodePoolOfferDetail.propTypes = {
  offer: PropTypes.shape({
    offerId: PropTypes.string,
    listingId: PropTypes.string,
    name: PropTypes.string,
    syndicateListingName: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    discountType: PropTypes.string,
    discountValue: PropTypes.number,
    channels: PropTypes.arrayOf(PropTypes.string),
    discount: PropTypes.shape({
      scheme: PropTypes.shape({
        typeName: PropTypes.string,
        amount: PropTypes.number,
      }),
      discountCode: PropTypes.shape({
        code: PropTypes.shape,
      }),
    }),
    verification: PropTypes.shape({
      eligibleOccupations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        parent: PropTypes.string,
      })),
      campaignId: PropTypes.number,
      listingId: PropTypes.number,
    }),
    source: PropTypes.shape({
      type: PropTypes.string,
    }),
    status: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    duration: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    billingRules: PropTypes.shape({
      commissionRate: PropTypes.number,
    }),
    codePool: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      published: PropTypes.bool,
      statistics: PropTypes.shape({
        totalActive: PropTypes.number,
        totalReserved: PropTypes.number,
      }),
      status: PropTypes.number,
    }),
    syndicatedListings: PropTypes.shape({
      listingId: PropTypes.string,
      listingName: PropTypes.string,
      createdOnUtc: PropTypes.string,
      length: PropTypes.number,
    }),
  }),
  discountTypeName: PropTypes.string,
  offerType: PropTypes.shape({
    name: PropTypes.string,
  }),
  discountTypes: PropTypes.shape({
    percentOff: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
    fixedAmount: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
    noScheme: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  offerFont: PropTypes.shape({
    fontSize: PropTypes.string,
  }),
  onClickCommissionRateHistory: PropTypes.func,
  onClickViewSyndicatedListings: PropTypes.func,
  classes: PropTypes.shape({
    offerDataLine: PropTypes.string,
  }),
  channels: PropTypes.arrayOf(PropTypes.shape({
    defaultChannel: PropTypes.bool,
    disable: PropTypes.bool,
    order: PropTypes.number,
    text: PropTypes.string,
    value: PropTypes.string,
  })),
  isSyndicateOffer: PropTypes.bool.isRequired,
  syndicatedListings: PropTypes.arrayOf(PropTypes.string),
  canViewSyndicatedListings: PropTypes.bool,

}

export default CodePoolOfferDetail
