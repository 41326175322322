import React from 'react'
import { useForm, useController } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import PropTypes from 'prop-types'
import { SelectField, MUISelectInput } from './ControlledSelectInput'
import { fillErrorMessages } from '../fillErrorMessages'

export function FormSelectInput({
  name,
  control,
  rules,
  label,
  disabled,
  id,
  items = [],
  includeEmptyItem = false,
  gxid,
}) {
  const { control: backupControl } = useForm({
    mode: 'onChange',
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors }, // eslint-disable-line no-unused-vars
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })
  return (
    <div>
      <SelectField
        items={
          includeEmptyItem
            ? [{ label: '', key: '__NULL__', value: '' }, ...items]
            : items
        }
        id={id}
        disabled={disabled}
        inputRef={field.ref}
        name={field.name}
        onChange={(v) => {
          field.onChange(v)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        invalid={fieldState.invalid}
        gxid={gxid}
      />
      <ErrorMessage
        errors={fillErrorMessages(errors, field.name)}
        name={field.name}
        render={({ message }) => (
          <p className="text-red text-sm mt-1">{message}</p>
        )}
      />
    </div>
  )
}

FormSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  rules: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  includeEmptyItem: PropTypes.bool,
  gxid: PropTypes.string,
}

export function MUIFormSelectInput({
  name,
  control,
  rules,
  label,
  disabled,
  id,
  items = [],
  includeEmptyItem = false,
  onChange = () => {},
  gxid,
  hideErrorMessage = false,
}) {
  const { control: backupControl } = useForm({
    mode: 'onChange',
  })
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors }, // eslint-disable-line no-unused-vars
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })
  return (
    <div>
      <MUISelectInput
        items={
          includeEmptyItem
            ? [{ label: '', key: '__NULL__', value: '' }, ...items]
            : items
        }
        id={id}
        disabled={disabled}
        inputRef={field.ref}
        name={field.name}
        onChange={(e) => {
          field.onChange(e)
          onChange(e.target.value)
        }}
        onBlur={field.onBlur}
        value={field.value}
        label={label}
        invalid={fieldState.invalid}
        gxid={gxid}
      />
      {!hideErrorMessage
        && (
          <ErrorMessage
            errors={fillErrorMessages(errors, field.name, label, rules)}
            name={field.name}
            render={({ message }) => (
              <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>{message}</p>
            )}
          />
        )}
    </div>
  )
}

MUIFormSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  rules: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  includeEmptyItem: PropTypes.bool,
  onChange: PropTypes.func,
  gxid: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
}
