import React from 'react'
import useDevelopment from './useDevelopment'
import TabWithSubNav from '../../../../TabWithSubNav'
import Billing from './Billing/Billing'
import Shopify from './Shopify'
import Specs from './Specs'
import useBilling from './Billing/useBilling'

const getItems = ({ listing, partner }) => {
  if (!partner) {
    return null // should not happen
  }
  const items = []
  if (partner.source === 'shopify') {
    items.push({ primaryText: 'Billing', component: <Billing {...useBilling()} /> })
    items.push({ primaryText: 'Shopify', component: <Shopify appId={partner.sourceKey} listingId={listing.id} /> })
  }
  items.push({ primaryText: 'Specs', component: <Specs partner={partner} listing={listing} /> })
  return items
}

function Development() {
  const { listing, partner, processing } = useDevelopment()
  const items = getItems({ listing, partner })
  return !processing && items ? (
    <div style={{ margin: '30px 0 0 0' }}>
      <TabWithSubNav items={items} />
    </div>
  ) : null
}

export default Development
