/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { hideModal, saveTicketSettings } from '../../../redux/actions'
import ModalContainer from '../ModalContainer'

export function DeleteAdvantageEntertainerModal({ listing, ticketSettings }) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const deleteEntertainer = () => dispatch(
    saveTicketSettings(listing, { ...ticketSettings, entertainerId: null }),
  ).then(() => dispatch(hideModal()))

  return (
    <ModalContainer
      modal
      maxWidth="xs"
      fullWidth="true"
      title="Unlink Entertainer"
      actions={{
        primaryAction: {
          label: 'Unlink',
          onClick: deleteEntertainer,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <Typography style={{ marginTop: '25px', color: '#222' }}>Are you sure you want to remove the link to this entertainer?</Typography>
    </ModalContainer>
  )
}

export default DeleteAdvantageEntertainerModal
