/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { ModalContainer } from '../../Modals/ModalContainer'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUITextInput } from '../../@Common/Form/TextInput/ControlledTextInput'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import { useDebounce } from '../../../hooks/UseDebounce'
import { FormAutoCompleteInput } from '../../@Common/Form/AutoComplete/FormAutoComplete'
import { ErrorMessage } from '@hookform/error-message'
import { useWatch } from 'react-hook-form';

function RenderLimitCodeOptions() {
  const ctx = useFormContext()

  const type = useWatch({ name: 'codeLimitType' })
  const codeLimit = Number(ctx.watch('codeLimit'))
  const perDaysLimit = Number(ctx.watch('perDaysLimit'))
  const codesPluralText = (Number(codeLimit) > 1 ? 'Codes' : 'Code')
  const limitDescriptionText = (type === 'PerUser' ? `${codesPluralText} per user` : (type === `PerUserPerDay` ? `${codesPluralText} per user, per` : `${codesPluralText} per user, per offer`)) //eslint-disable-line

  return (
    type !== 'None' ?
      <Box sx={{ mb: '30px' }}>
        <Box display="flex" alignItems="center" gap="1em">
          <Box sx={{ width: '75px' }}>
            <MUIFormTextInput
              key={`codeLimit-${type}`}
              hideErrorMessage={true}
              type="number"
              name="codeLimit"
              gxid="codePoolCodeLimit"
              control={ctx.control}
              size="sm"
              rules={{
                required: true,
                min: 1,
                pattern: {
                  value: /^([1-9][0-9]{0,2})$/i
                },
              }}

            />
          </Box>
          <Box>{limitDescriptionText}</Box>
          {
            type === 'PerUserPerDay' && (
              <Box display="flex" alignItems="center" gap="1em">
                <Box sx={{ width: '75px' }}>
                  <MUIFormTextInput
                    hideErrorMessage={true}
                    type="number"
                    name="perDaysLimit"
                    control={ctx.control}
                    size="sm"
                    rules={{
                      required: true,
                      min: 1,
                      max: 999,
                      pattern: {
                        value: /^([1-9][0-9]{0,2})$/i
                      },
                    }}
                  />
                </Box>
                <Box>
                  {`day${Number(perDaysLimit) > 1 ? 's' : ''}`}
                </Box>
              </Box>
            )
          }
        </Box>
        {
          (ctx.formState.errors?.perDaysLimit || ctx.formState.errors?.codeLimit) &&
          <Box>
            <ErrorMessage
              errors={ctx.formState.errors}
              name={ctx.formState.errors.perDaysLimit ? 'perDaysLimit' : ctx.formState.errors.codeLimit ? 'codeLimit' : null}
              render={() => (
                <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>Code limit must be between 1 and 999</p>
              )}
            />
          </Box>
        }
      </Box> : <></>
  )
}

function CreateEditCodePoolModal({
  codePool, lookups, getPartners, onClickSave, onClickCancel,
}) {
  const [processing, setProcessing] = useState(false)
  const { control, handleSubmit, watch, formState } = useForm({
    defaultValues: {
      name: codePool ? codePool.name : '',
      codeLimitType: codePool ? lookups.data.limitCodes.find(x => x.index === codePool.codeLimitType).value : 'None',
      partner: codePool ? { id: codePool.partnerId, name: codePool.partnerName } : null,
      minimumThreshold: codePool ? Number(codePool.minimumThreshold) : 100,
      codeLimit: codePool ? Number(codePool.codeLimit) : null,
      perDaysLimit: codePool ? Number(codePool.perDaysLimit) : null,
      status: codePool ? Number(codePool.status) : 0,
    },
  })

  const onSubmit = async (formData) => {
    try {
      setProcessing(true)
      await onClickSave({
        ...formData,
        partnerId: formData.partner.id,
        partnerName: formData.partner.name
      })
    } catch (error) {
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }

  // Partner search
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 200)

  const { partners, isPartnerFetchLoading } = getPartners(debouncedSearchTerm)
  const noOptionsText = () => {
    if (debouncedSearchTerm === '')
      return 'Search for a partner'
    else if (isPartnerFetchLoading)
      return 'Loading...'
    else
      return 'No partner found'
  }

  return (
    <ModalContainer
      title={`${codePool ? 'Edit' : 'Create'} Code Pool`}
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      hideModal={() => { }}
      actions={{
        primaryAction: { label: codePool ? 'Save' : 'Create', onClick: handleSubmit(onSubmit) },
        secondaryAction: { label: 'Cancel', onClick: onClickCancel },
      }}
    >
      <Box>
        <FormProvider control={control} watch={watch} formState={formState}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mb: '30px' }}>
              <MUIFormTextInput
                name="name"
                control={control}
                label="Code Pool Name"
                rules={{
                  required: {
                    value: true,
                    message: 'Code pool name is required',
                  },
                  pattern: {
                    value: /^[a-z\d\-_\s]+$/i,
                    message: 'Code pool name must be alphanumeric',
                  },
                  maxLength: 70,
                }}
                gxid="createCodePoolName"
              />
            </Box>
            {codePool && (
              <Box sx={{ mb: '30px' }}>
                <MUITextInput
                  name="partner"
                  label="Partner"
                  gxid="updateCodePoolPartner"
                  value={codePool.partnerName}
                  disabled={true}
                />
              </Box>
            )}
            {!codePool && (
              <Box sx={{ mb: '30px' }}>
                <FormAutoCompleteInput
                  name="partner"
                  control={control}
                  label="Partner"
                  noOptionsText={noOptionsText()}
                  options={partners}
                  onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
                  itemLabelKey='name'
                  rules={{
                    required: {
                      value: true,
                      message: 'Partner name is required',
                    },
                  }}
                  gxid="createCodePoolPartner"
                />
              </Box>
            )}
            <Box sx={{ mb: '30px' }}>
              <MUIFormSelectInput
                name="codeLimitType"
                control={control}
                label="Limit Codes"
                rules={{
                  required: {
                    value: true,
                    message: 'Limit codes is required',
                  },
                }}
                items={
                  lookups.data.limitCodes.map((item) => ({
                    label: item.text,
                    key: item.key,
                    value: item.value,
                  }))
                }
                gxid="createCodePoolcodeLimitType"
              />
            </Box>
            <RenderLimitCodeOptions />
            <Box sx={{ mb: '30px' }}>
              <MUIFormTextInput
                name="minimumThreshold"
                control={control}
                label="Minimum Threshold Alert"
                rules={{
                  required: {
                    value: true,
                    message: 'Minimum threshold alert is required',
                  },
                  min: 1
                }}
                gxid="createCodePoolMinThreshold"
              />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </ModalContainer>
  )
}

CreateEditCodePoolModal.propTypes = {
  codePool: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    partnerName: PropTypes.string,
    totalCodes: PropTypes.number,
    usedCodes: PropTypes.number,
    minimumThreshold: PropTypes.number,
    availableCodes: PropTypes.number,
    published: PropTypes.bool,
    codeLimitType: PropTypes.number,
    codeLimit: PropTypes.number,
    perDaysLimit: PropTypes.number,
    status: PropTypes.number,
  }),
  lookups: PropTypes.shape({
    data: PropTypes.shape({
      limitCodes: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string,
      })),
    }),

    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
  }),
  getPartners: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
}
export default CreateEditCodePoolModal
