import { combineReducers } from 'redux'
import users, { STATE_KEY  as USERS_STATE_KEY } from './modules/users/reducer'
import affiliates, { STATE_KEY  as AFFILIATES_STATE_KEY } from './modules/affiliates/reducer'
import verification, { STATE_KEY as VERIFICATION_STATE_KEY } from './modules/verification/reducer'
import notifications, { STATE_KEY as NOTIFICATIONS_STATE_KEY } from './modules/notifications'
import authentication, { STATE_KEY as AUTHENTICATION_STATE_KEY } from './modules/authentication/authentication'
import modal, { STATE_KEY as MODAL_STATE_KEY } from './modules/modal'
import sideSheet, { STATE_KEY as SIDE_SHEET_STATE_KEY } from './modules/sideSheet'
import drawer, { STATE_KEY as DRAWER_STATE_KEY } from './modules/drawer'
import form, { STATE_KEY as FORM_STATE_KEY } from './modules/form'
import reports, { STATE_KEY as REPORTS_STATE_KEY } from './modules/reports/reducer'
import tickets, { STATE_KEY as TICKETS_STATE_KEY } from './modules/tickets/tickets'
import partners, { STATE_KEY as PARTNERS_STATE_KEY } from './modules/partners/reducer'
import offers, { STATE_KEY as OFFERS_STATE_KEY } from './modules/offers/reducer'
import billing, { STATE_KEY as BILLING_STATE_KEY } from './modules/billing/reducer'
import offersReducer from './slices/offersSlice'
import verificationsReducer from './slices/verificationsSlice'
import { modalsReducer } from '../hooks/useModal'

const reducer = {
	[VERIFICATION_STATE_KEY]: verification,
	[NOTIFICATIONS_STATE_KEY]: notifications,
	[MODAL_STATE_KEY]: modal,
	[SIDE_SHEET_STATE_KEY]: sideSheet,
	[DRAWER_STATE_KEY]: drawer,
	[USERS_STATE_KEY]: users,
	[AFFILIATES_STATE_KEY]: affiliates,
	[FORM_STATE_KEY]: form,
	[AUTHENTICATION_STATE_KEY]: authentication,
	[REPORTS_STATE_KEY]: reports,
	[TICKETS_STATE_KEY]: tickets,
	[PARTNERS_STATE_KEY]: partners,
	[OFFERS_STATE_KEY]: offers,
	[BILLING_STATE_KEY]: billing,

	offersNew: offersReducer,
	verificationsNew: verificationsReducer,
	modals: modalsReducer,
}

export default reducer