import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, lifecycle } from 'recompose'
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form'
import CircularProgress from '@mui/material/CircularProgress'
import MarketingAssetsFormPartial from './MarketingAssetsFormPartial'
import { fetchAffiliateMarketingAssetsSettings, updateAffiliateMarketingAssetsSettings, saveAffiliatePicture } from '../../../../redux/actions'
import { selectAffiliateMarketingAssets } from '../../../../redux/selectors'
import { previewImageDimensionsValid } from '../../../../validation'
import { deleteAffiliatePicture, updatePromoSquareImage, updatePromoTileImage } from '../../../../redux/modules/affiliates/affiliates'

let promoSquareImageWidth = 500
let promoSquareImageHeight = 500
let promoTileImageWidth = 585
let promoTileImageHeight = 360

const Processing = () => (
	<div
		style={{
			position: 'absolute',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
			minHeight: '400px',
			backgroundColor: 'rgba(255,255,255,.8)',
			overflow: 'hidden',
			zIndex: '100',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}
	>
		<CircularProgress />
	</div>
)

const MarketingAssetsForm = ({
	processing,
	initialValues,
	fieldValues,
	handleSubmit,
	affiliate,
	canUpdate,
}) => (
	<div style={{ position: 'relative', minHeight: '265px' }}>
		{
			processing || !initialValues
			?	<Processing />
			:	<form onSubmit={ handleSubmit }>
					<MarketingAssetsFormPartial affiliate={ affiliate } initialValues={ initialValues } fieldValues={ fieldValues } canUpdate={canUpdate}  />
				</form>
		}
	</div>
)

export const handleSubmit = (values, dispatch, props) => {
	var promoSquareImageError = previewImageDimensionsValid(promoSquareImageWidth, promoSquareImageHeight, 'promoSquareImagePreview')
	if (promoSquareImageError) {
		throw new SubmissionError({
			promoSquareImageUrl: promoSquareImageError,
			_error: 'Wrong Promo Square Image dimensions'
		})
	}
	var promoTileImageError = previewImageDimensionsValid(promoTileImageWidth, promoTileImageHeight, 'promoTileImagePreview')
	if (promoTileImageError) {
		throw new SubmissionError({
			promoTileImageUrl: promoTileImageError,
			_error: 'Wrong Promo Tile Image dimensions'
		})
	}

	props.setProcessing(true)
	let settings = {}
	settings.affiliateId = props.affiliate.id
	settings.tagline = values.tagline

	let promises = []
	let marketingAssets = props.affiliate.marketingAssets

	if (marketingAssets.promoSquareImageUrl && typeof marketingAssets.promoSquareImageUrl !== 'string' && marketingAssets.promoSquareImageId == 0 ) {
		let logoFormData = new window.FormData()
		logoFormData.append('file', marketingAssets.promoSquareImageUrl)
		logoFormData.append('seoFilename', 'affiliatePromoSquareImage')
		logoFormData.append('targetSize', 200)
		promises.push(dispatch(saveAffiliatePicture(props.affiliate.id, 'promoSquare', logoFormData)))
	} else if(marketingAssets.promoSquareImageUrl == null){
		promises.push(dispatch(deleteAffiliatePicture(marketingAssets.promoSquareImageId)))
		settings.promoSquareImageUrl = null;
		settings.promoSquareImageId = 0;
	}
	else {
		settings.promoSquareImageUrl = marketingAssets.promoSquareImageUrl
		settings.promoSquareImageId = marketingAssets.promoSquareImageId
	}
	if (marketingAssets.promoTileImageUrl && typeof marketingAssets.promoTileImageUrl !== 'string' && marketingAssets.promoTileImageId == 0) {
		let logoFormData = new window.FormData()
		logoFormData.append('file', marketingAssets.promoTileImageUrl)
		logoFormData.append('seoFilename', 'affiliatePromoTileImage')
		logoFormData.append('targetSize', 200)
		promises.push(dispatch(saveAffiliatePicture(props.affiliate.id, 'promoTile', logoFormData)))
	} else if(marketingAssets.promoTileImageUrl == null){
		promises.push(dispatch(deleteAffiliatePicture(marketingAssets.promoTileImageId)))
		settings.promoTileImageUrl = null;
		settings.promoTileImageId = 0;
	} 
	else {
		settings.promoTileImageUrl = marketingAssets.promoTileImageUrl
		settings.promoTileImageId = marketingAssets.promoTileImageId
	}

	return promises.length > 0
	?	Promise.all(promises)
			.then(responses => {
				responses.map(r => {
					if (r.pictureType && r.pictureType === 'promoSquare') {
						dispatch(updatePromoSquareImage(r.url, r.id, props.affiliate.id));
						settings.promoSquareImageUrl = r.url
						settings.promoSquareImageId = r.id
					} else if (r.pictureType && r.pictureType === 'promoTile') {
						dispatch(updatePromoTileImage(r.url, r.id, props.affiliate.id));
						settings.promoTileImageUrl = r.url
						settings.promoTileImageId = r.id
					}
				})
				dispatch(updateAffiliateMarketingAssetsSettings(props.affiliate.id, settings))
					.then(r => {
						props.setProcessing(false)
					})
			})
			.catch(err => {})
	:	dispatch(updateAffiliateMarketingAssetsSettings(props.affiliate.id, settings))
			.then(r => props.setProcessing(false))
}

const selector = formValueSelector('marketing-assets')

const mapStateToProps = (state, ownProps) => {
	let initialValues = selectAffiliateMarketingAssets(ownProps.affiliate.id, 'marketing-assets')(state)

	let fieldValues = {
		promoSquareImageUrl: selector(state, 'promoSquareImageUrl'),
		promoSquareImageId: selector(state, 'promoSquareImageId'),
		promoTileImageUrl: selector(state, 'promoTileImageUrl'),
		promoTileImageId: selector(state, 'promoTileImageId'),
		tagline: selector(state, 'tagline'),
	}

	return {
		initialValues: initialValues,
		fieldValues: fieldValues,
		affiliate: ownProps.affiliate,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchAffiliateMarketingAssetsSettings: () => {
		dispatch(fetchAffiliateMarketingAssetsSettings(ownProps.affiliate.id))
			.then(response => ownProps.setProcessing(false))
	},
})

const enhance = compose(
	withState('processing', 'setProcessing', props => true),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({ form: 'marketing-assets', onSubmit: handleSubmit }),
	lifecycle({
		componentDidMount() {
			this.props.fetchAffiliateMarketingAssetsSettings()
		},
	})
)

export default enhance(MarketingAssetsForm)