import { IconButton, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { api } from '../../../../../../redux/slices/apiSlice'

function EventOptionsMenu({
    row,
    onClickEditEvent,
    onClickArchiveEvent,
    onClickGovXIdLink
}) {

    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleGovXIdLink = () => {
        const offer = {
            link: row.row.eventLink,
            id: row.row.id
        }
        onClickGovXIdLink(offer);
    };

    return (
        (<Box>
            <IconButton style={{ paddingTop: '9px' }} onClick={(e) => setMenuAnchor(e.currentTarget)}>
                <GridMoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => {
                    setMenuAnchor(null)
                }}
            >
                <MenuItem onClick={() => { onClickEditEvent(row.row.id); setMenuAnchor(null) }} data-gxid="editEventMenuLink">
                    Edit Event
                </MenuItem>
                <MenuItem onClick={() => { handleGovXIdLink(); setMenuAnchor(null); }} data-gxid="eventGovxLink">
                    GOVX ID Link
                </MenuItem>
                <MenuItem onClick={() => { onClickArchiveEvent([row.row.id]); setMenuAnchor(null) }} data-gxid="archiveEventLink">
                    Archive
                </MenuItem>
            </Menu>
        </Box>)
    )
}

export default EventOptionsMenu