export const required = value => value ? undefined : 'Required'

// TODO: Add tests

export const zipCode = value => {
	if (!value)
		return undefined
	let re = /^(\d{5}|\d{9})$/
	return re.test(value) ? undefined : 'Please enter a valid zip code.'
}

export const decoratedZipCode = (value) => {
	if (!value)
		return undefined
	if(value.length === 5 || value.length === 9) {
		return zipCode(value)
	}
	let re = /^((\d{5}-\d{4})|(\d{5}))$/
	return re.test(value) ? undefined : 'Please enter a valid zip code.'
}

export const email = value => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(value) ? undefined : 'Please enter a valid email address.'
}

export const phoneNumber = (value) => {
	if (!value)
		return undefined
	let re =/^(\d{10,14})$/
	return re.test(value) ? undefined : 'Please enter a valid phone number.'
}

export const decoratedPhoneNumber = (value) => {
	if (!value)
		return undefined
	if(!value.includes('(')) {
		return phoneNumber(value)
	}
	let re =/\(\d{3}\)\s\d{3}-\d{4}\s?[0-9]?[0-9]?[0-9]?[0-9]?/
	return re.test(value) ? undefined : 'Please enter a valid phone number.'
}


export const date = value => {
	if (!value)
		return undefined
	const dateMessage = 'Please enter a valid date.'
	// if (!value || value.length !== 8 || value.length !== 10)
	// 	return dateMessage

	let month = value.substr(0, 2)
	let day = value.substr(2, 2)
	let year = value.substr(4, 4)

	let date = new Date(year, month - 1, day)
	let otherDate = date.getDate()
	let otherMonth = date.getMonth()

	if(decoratedDate(value) === undefined) return undefined;
	return (otherDate == day && otherMonth == month - 1) ? undefined : dateMessage
}

export const decoratedDate = value => {
	if (!value)
		return undefined

	let re =/^((0?[1-9])|(1[0-2]))\/((0?[1-9])|((1|2)[1-9])|(3[0,1]))\/[1,2][0-9][0-9][0-9]$/
	return re.test(value) ? undefined : 'Please enter a valid formatted date.'
}

export const isNumber = value => {
	if (!value)
		return undefined
	return isNaN(value) ? 'Please enter a valid number.' : undefined
}

export const isInRange = (min, max) => value => {
	if (!value)
		return undefined
	if (isNaN(value))
		return 'Please enter a valid number.'
	let isAboveMin = isNaN(min) || value >= min
	let isBelowMax = isNaN(max) || value <= max

	return (isAboveMin && isBelowMax) ?  undefined : `Please enter a number between ${min} and ${max}.`
}

export const isOver18 = value => {
	if (!value)
		return undefined
	const over18Message = 'Must be over 18'
	let date;
	if(value.length === 8) {
		let month = value.substr(0, 2)
		let day = value.substr(2, 2)
		let year = value.substr(4, 4)
		date = new Date(year, month - 1, day)
	} else if(value.length === 10) {
		const monthDayYear = value.split('/');
		let month = monthDayYear[0]
		let day = monthDayYear[1]
		let year = monthDayYear[2]
		date = new Date(year, month - 1, day)
	} else {
		return 'Invalid date provided'
	}

	if (date && date.getFullYear() < 1900) {
		return 'Must be born after 1899'
	}

	let today = new Date()
	let age = today.getFullYear() - date.getFullYear()
	let m = today.getMonth() - date.getMonth()

	if (m < 0 || (m === 0 && today.getDate() < date.getDate()))
		age--

	return age >= 18 ? undefined : over18Message
}

export const year = value => {
	let re = /^[1,2][0-9][0-9][0-9]$/
	if (!value)
		return undefined
	return re.test(value) ? undefined : 'Please enter a valid year.'
}

export const pastExpirationDate = expirationDate => {
	return (new Date(expirationDate) < new Date())
}

export const matches = (key, errorMessage) => (value, allValues, props) => {
	return value === allValues[key]
		? undefined
		: errorMessage
}

export const previewImageDimensionsValid = (expectedWidth, expectedHeight, previewId) => {
	var previewImage = document.getElementById(previewId)
	if (previewImage && (previewImage.width != expectedWidth || previewImage.height != expectedHeight)) {
		return `Please upload an image ${expectedWidth}px wide by ${expectedHeight}px tall.`
	}
	return undefined
}

export const imageHeightValid = (minHeight, maxHeight, imageId) => {
	var image = document.getElementById(imageId)
	if (image) {
		if (minHeight && maxHeight && (image.height < minHeight || image.height > maxHeight)) {
			return `Please upload an image with height between ${minHeight}px and ${maxHeight}px.`
		}
		else if (minHeight && (image.height < minHeight)) {
			return `Please upload an image with height greater than ${minHeight}px.`
		}
		
	}
	return null
}

export const imageExtensionValid = (minHeight, maxHeight, imageId) => {
	var image = document.getElementById(imageId)
	if (image && (image.height < minHeight || image.height > maxHeight)) {
		return `Please upload an image with height between ${minHeight}px and ${maxHeight}px.`
	}
	return null
}

export const filesizeWithinLimits = size => value => {
	let sizeUnit = size < 1 ? 'KB' : 'MB'
	let sizeValue = size < 1 ? size*1000 : size
	return (value && value.size/1024/1024 > size)
		?	`The file you're uploading is too large. Maximum size is ${sizeValue}${sizeUnit}.`
		:	undefined
}

const getFileExtension = (filename) => {
	if (typeof(filename) === 'string') {
		return filename.split('.').pop()
	}
}

export const fileExtensionValid = extensions => file => {
	if (file instanceof File) {
		let extension = getFileExtension(file.name)
		let regexTest = new RegExp(extensions.join('|'), 'i')
		if (extension && !regexTest.test(extension)) {
			return `The file contains an invalid extension. Please submit a file with one of the following extensions: ${extensions.join(', ')}`
		}
	}

	return undefined
}

export const hex = value => {
	let regex = /#([a-f0-9]{3}(?:[a-f0-9]{3}){0,1})\b/gi
	return regex.test(value)
	?	undefined
	:	'Please enter a valid hex color value.'
}

export const startsWithHttp = url => {
	return (url.startsWith('http://') || url.startsWith('https://'))
	? undefined
	: 'URL must start with "http://" or "https://".'
}
