import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { compose, withState, lifecycle } from 'recompose'
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form'
import CircularProgress from '@mui/material/CircularProgress'
import CustomizePageFormPartial from './CustomizePageFormPartial'
import { fetchAffiliateMarketplacePageSettings, updateAffiliateMarketplacePageSettings, saveAffiliatePicture } from '../../../../redux/actions'
import { selectAffiliatePageInfo } from '../../../../redux/selectors'
import { previewImageDimensionsValid, imageHeightValid } from '../../../../validation'
import { deleteAffiliatePicture, updateDesktopHeroImage, updateMobileHeroImage } from '../../../../redux/modules/affiliates/affiliates'

const desktopHeroImageWidth = 1800
const desktopHeroImageHeight = 500
const mobileHeroImageWidth = 800
const mobileHeroImageHeight = 500
const minLogoHeight = 40

const Processing = () => (
	<div
		style={{
			position: 'absolute',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
			minHeight: '400px',
			backgroundColor: 'rgba(255,255,255,.8)',
			overflow: 'hidden',
			zIndex: '100',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}
	>
		<CircularProgress />
	</div>
)


const CustomizePageForm = ({
	processing,
	initialValues,
	fieldValues,
	handleSubmit,
	affiliate,
	canUpdate,
}) => (
	
	<div style={{ position: 'relative', minHeight: '265px' }}>
		{
			processing || !initialValues
			?	<Processing />
			:	<form onSubmit={ handleSubmit }>
					<CustomizePageFormPartial affiliate={ affiliate } initialValues={ initialValues } fieldValues={ fieldValues } canUpdate={canUpdate} />
				</form>
		}
	</div>
)

export const handleSubmit = (values, dispatch, props) => {
	var desktopHeroImageError = previewImageDimensionsValid(desktopHeroImageWidth, desktopHeroImageHeight, 'desktopHeroImagePreview')
	if (desktopHeroImageError) {
		throw new SubmissionError({
			desktopHeroImageUrl: desktopHeroImageError,
			_error: 'Wrong Desktop Hero Image dimensions'
		})
	}
	var mobileHeroImageError = previewImageDimensionsValid(mobileHeroImageWidth, mobileHeroImageHeight, 'mobileHeroImagePreview')
	if (mobileHeroImageError) {
		throw new SubmissionError({
			mobileHeroImageUrl: mobileHeroImageError,
			_error: 'Wrong Mobile Hero Image dimensions'
		})
	}
	var logoError = imageHeightValid(minLogoHeight, null, 'logoImagePreview')
	if (logoError) {
		throw new SubmissionError({
			logoImageUrl: logoError,
			_error: 'Wrong Logo Image dimensions'
		})
	}


	props.setProcessing(true)
	let settings = {}
	settings.affiliateId = props.affiliate.id
	settings.about = values.about
	settings.howItWorks = values.howItWorks

	let promises = []
	let pageInfo = props.affiliate.pageInfo;

	
	if (pageInfo.desktopHeroImageUrl && typeof pageInfo.desktopHeroImageUrl !== 'string' 
	&& pageInfo.desktopHeroImageId == 0) {
		let desktopHeroFormData = new window.FormData()
		desktopHeroFormData.append('file', pageInfo.desktopHeroImageUrl)
		desktopHeroFormData.append('seoFilename', 'affiliateHeroImageDesktop')
		desktopHeroFormData.append('targetSize', 200)
		promises.push(dispatch(saveAffiliatePicture(props.affiliate.id, 'desktop', desktopHeroFormData)))
	} else if(pageInfo.desktopHeroImageUrl == null){
		promises.push(dispatch(deleteAffiliatePicture(values.desktopHeroImageId)))
		settings.desktopHeroImageId = 0;
		settings.desktopHeroImageUrl = null;
	}
	else {
		settings.desktopHeroImageUrl = pageInfo.desktopHeroImageUrl
		settings.desktopHeroImageId = pageInfo.desktopHeroImageId
	}
	if (pageInfo.mobileHeroImageUrl && typeof pageInfo.mobileHeroImageUrl !== 'string' 
	&& pageInfo.mobileHeroImageId == 0) {
		let mobileHeroFormData = new window.FormData()
		mobileHeroFormData.append('file', pageInfo.mobileHeroImageUrl)
		mobileHeroFormData.append('seoFilename', 'affiliateHeroImageMobile')
		mobileHeroFormData.append('targetSize', 200)
		promises.push(dispatch(saveAffiliatePicture(props.affiliate.id, 'mobile', mobileHeroFormData)))
	} else if(pageInfo.mobileHeroImageUrl == null){
		promises.push(dispatch(deleteAffiliatePicture(pageInfo.mobileHeroImageId)))
		settings.mobileHeroImageUrl = null;
		settings.mobileHeroImageId = 0;
	} 
	else {
		settings.mobileHeroImageUrl = pageInfo.mobileHeroImageUrl
		settings.mobileHeroImageId = pageInfo.mobileHeroImageId
	}
	if (values.logoImageUrl && typeof values.logoImageUrl !== 'string') {
		let logoFormData = new window.FormData()
		logoFormData.append('file', values.logoImageUrl)
		logoFormData.append('seoFilename', 'affiliateLogoImage')
		logoFormData.append('targetSize', 200)
		promises.push(dispatch(saveAffiliatePicture(props.affiliate.id, 'logo', logoFormData)))
	} else {
		settings.logoImageUrl = values.logoImageUrl
		settings.logoImageId = values.logoImageId
	}

	return promises.length > 0
	?	Promise.all(promises)
			.then(responses => {
				responses.map(r => {
					if (r.pictureType && r.pictureType === 'desktop') {
						dispatch(updateDesktopHeroImage(r.url, r.id, props.affiliate.id));
						settings.desktopHeroImageUrl = r.url
						settings.desktopHeroImageId = r.id
					} else if (r.pictureType && r.pictureType === 'mobile') {
						dispatch(updateMobileHeroImage(r.url, r.id, props.affiliate.id));
						settings.mobileHeroImageUrl = r.url
						settings.mobileHeroImageId = r.id
					} else if (r.pictureType && r.pictureType === 'logo') {
						settings.logoImageUrl = r.url
						settings.logoImageId = r.id
					}
				})

				dispatch(updateAffiliateMarketplacePageSettings(props.affiliate.id, settings))
					.then(r => {
						props.setProcessing(false)
					})
			})
			.catch(err => {})
	:	dispatch(updateAffiliateMarketplacePageSettings(props.affiliate.id, settings))
			.then(r => props.setProcessing(false))
}

const selector = formValueSelector('customize-page')

const mapStateToProps = (state, ownProps) => {
	let initialValues = selectAffiliatePageInfo(ownProps.affiliate.id, 'customize-page')(state)
	let fieldValues = {
		desktopHeroImageUrl: selector(state, 'desktopHeroImageUrl'),
		desktopHeroImageId: selector(state, 'desktopHeroImageId'),
		mobileHeroImageUrl: selector(state, 'mobileHeroImageUrl'),
		mobileHeroImageId: selector(state, 'mobileHeroImageId'),
		logoImageUrl: selector(state, 'logoImageUrl'),
		logoImageId: selector(state, 'logoImageId'),
		about: selector(state, 'about'),
		howItWorks: selector(state, 'howItWorks'),
	}

	return {
		initialValues: initialValues,
		fieldValues: fieldValues,
		affiliate: ownProps.affiliate,
	}
}




const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchAffiliateMarketplacePageSettings: () => {
		dispatch(fetchAffiliateMarketplacePageSettings(ownProps.affiliate.id))
			.then(response => ownProps.setProcessing(false))
	},
})

const enhance = compose(
	withState('processing', 'setProcessing', props => true),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({ form: 'customize-page', onSubmit: handleSubmit }),
	lifecycle({
		componentDidMount() {
			this.props.fetchAffiliateMarketplacePageSettings()
		},
	})
)

export default enhance(CustomizePageForm)