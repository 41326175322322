import React from 'react'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_PARTNER_LIST_PAGE } from '../../auth/policies'

const PartnersPage = React.lazy(() => import("../../components/Partners"))

export default {
	path: RouteConstants.partners.index.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_PARTNER_LIST_PAGE]}
			component={<PartnersPage />}
		/>
	),
}