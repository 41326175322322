import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, setDisplayName, lifecycle } from 'recompose'
import { change } from 'redux-form'
import FormHelperText from '@mui/material/FormHelperText'
import AutoComplete from './AutoComplete'
import Select from './SelectField'
import { required } from '../../validation'
import { fetchGovernmentDepartments } from '../../redux/actions'
import { selectGovernmentDepartments } from '../../redux/selectors'

export const GovernmentDepartmentField = ({
	field,
	departments,
	department,
	jurisdiction,
	setDepartment,
	onJurisdictionChanged,
	changeDepartment,
}) => (
	<div>
		<AutoComplete
			name={field.key + '.name'}
			label={field.label}
			validate={[required]}
			changeDepartment={changeDepartment}
			dataSource={departments}
			dataSourceConfig={{ text: 'name', value: 'id' }}
			hintText="What is your department?"
			department={department}
			setDepartment={setDepartment}
		/>
		{
			department && department.name && !(department.id && department.jurisdiction) &&
			<div style={{ marginTop: '10px' }}>
				<Select
					name={field.key + '.jurisdiction'}
					label="Select the jurisdiction"
					validate={[required]}
					onChange={onJurisdictionChanged}
					items={
						[
							{key: 'none', value:null, label: ''},
							{key: 'Federal', value:'Federal', label:'Federal'},
							{key: 'StateOrLocal', value:'StateOrLocal', label:'State/Local'}
						]
					}
				/>
			</div>
		}
	</div>
)

GovernmentDepartmentField.propTypes = {
	field: PropTypes.object.isRequired,
	departments: PropTypes.array,
	findDepartment: PropTypes.func.isRequired,
	department: PropTypes.object,
	setJurisdiction: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
	field: ownProps.field,
	departments: selectGovernmentDepartments(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	changeDepartment: value => {
		dispatch(change(ownProps.formName, 'govt-dept', value))
	},
	fetchGovernmentDepartments: () => dispatch(fetchGovernmentDepartments()),
})

const enhance = compose(
	setDisplayName('GovernmentDepartmentField'),
	connect(mapStateToProps, mapDispatchToProps),
	lifecycle({
		componentDidMount() {
			this.props.fetchGovernmentDepartments()
		},
	}),
	withState('department', 'setDepartment', { id: -1, name: 'none', jurisdiction: 'none' }),
	withState('jurisdiction', 'setJurisdiction', ''),
	withHandlers({
		findDepartment: props => value => {
			let department = props.departments.find(d => d.name == value)
			props.setDepartment(department)
			if (department) props.setJurisdiction(department.jurisdiction)
			props.changeDepartment(department || { jurisdiction: props.jurisdiction })
		},
		onJurisdictionChanged: props => e => {
			props.setDepartment({ ...props.department, jurisdiction: e.target.value })
			props.changeDepartment({ ...props.department, jurisdiction: e.target.value })
		},
	})
)

export default enhance(GovernmentDepartmentField)
