/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react'
import { createUseStyles } from 'react-jss'
import TagIcon from '@mui/icons-material/LocalOffer'
import Typography from '@mui/material/Typography'
import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    backgroundColor: '#eee',
    borderRadius: '3px',
    textAlign: 'center',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
})

export function Empty({
  onCreateCampaign,
}) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.container}>
        <TagIcon style={{
          width: '80px', height: '80px', padding: '15px', backgroundColor: '#fff', border: '2px solid #222', borderRadius: '50%',
        }}
        />
        <Typography variant="h6" style={{ margin: '30px 0px 0px' }}>Campaigns</Typography>
        <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>You don't have any campaigns created for this listing.</Typography>
        <Button color="primary" label="Create a campaign" onClick={onCreateCampaign} fullWidth={false}>Create a campaign</Button>
      </div>
      <div className={classes.info}>
        <div className={classes.threeFourths}>
          <Typography variant="h3" style={{ marginBottom: '10px', fontWeight: 600 }}>Using campaigns</Typography>
          <Typography variant="body2">Campaigns are used to create multiple unique experiences under your parent listing. You may want to use campaigns if you're managing multiple online stores or websites under different brands or even just to run seasonal promotions. By using campaigns, you can customize the branding and messaging in your verification funnel.</Typography>
          <Typography variant="body2">You can easily create campaigns using the wizard in this section. Each campaign you create will be identified by a campaign_id parameter. To trigger the campaign from your website, simply append the campaign_id parameter to your verification URL. The verification URL is the authorization endpoint you're deploying on your website or application that triggers the verification flow. Our system will read the parameter and automatically apply the custom settings. If for some reason the campaign parameter is unrecognized, we will fall back to the listing settings.</Typography>
        </div>
        <div className={classes.oneFourth}>
          <Typography variant="h3"style={{ marginBottom: '10px', fontWeight: 600 }}>Resources</Typography>
          <Typography><StyledLink contents="Managing campaigns" external to="https://support.govxinc.com/hc/en-us/articles/360028454952-Managing-campaigns" newWindow /></Typography>
        </div>
      </div>
    </div>
  )
}

export default Empty
