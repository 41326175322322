import React, { useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { ModalContainer } from '../../Modals/ModalContainer'

function DialogModal(
  {
    children,
    title,
    onClickCancel = () => {},
    onClickConfirm = () => {},
    processing,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    closeModal = () => {},
  },
) {
  const [processingInternal, setProcessingInternal] = useState(false)
  const handleAction = (action) => async () => {
    try {
      setProcessingInternal(true)
      await action()
      closeModal()
    } catch (error) {
      console.error(error)
    } finally {
      setProcessingInternal(false)
    }
  }

  return (
    <ModalContainer
      title={title}
      modal
      processing={processing || processingInternal}
      actions={{
        primaryAction: { label: confirmLabel, onClick: handleAction(onClickConfirm) },
        secondaryAction: { label: cancelLabel, onClick: handleAction(onClickCancel) },
      }}
      hideModal={() => {}}
    >
      <Box>
        {children}
      </Box>
    </ModalContainer>
  )
}

DialogModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
  processing: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  closeModal: PropTypes.func,
}

export default DialogModal
