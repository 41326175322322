import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import { formatOccupationType } from '../../../formatting'

const showTaxonomyListSubheader = ({depth, index, siblings}) => depth !== 0 && (index === 0 || (siblings && siblings.length && 0 < index && index < siblings.length && siblings[index - 1].type !== siblings[index].type))

const TaxonomyListSubheader = ({ depth, index, level, siblings, occupation }) => (
	showTaxonomyListSubheader({depth, index, siblings}) ? (
		<ListSubheader
			style={{
				margin: '5px 0px',
				paddingLeft: level * 32,
				color: '#7b7e84',
				textTransform: 'uppercase',
				fontSize: '11px',
				fontWeight: '600',
				WebkitFontSmoothing: 'antialiased',
				lineHeight: '20px',
				paddingTop: '10px'
			}}
		>
			{formatOccupationType(occupation.type)}
		</ListSubheader>
	) : null
)

export default TaxonomyListSubheader
