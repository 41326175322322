/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export */
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ImageIcon from '@mui/icons-material/Photo'
import excelIcon from './fileIcons/excel.svg'
import csvIcon from './fileIcons/csv.svg'
import genericIcon from './fileIcons/generic.svg'

function extractFileType(fileName = '') {
  return fileName.split('.').pop().split('?')[0].toLowerCase()
}

function isImageFile(fileName = '') {
  if (typeof fileName !== 'string') {
    return false
  }
  const fileType = extractFileType(fileName)
  return ['jpeg', 'jpg', 'png', 'gif'].includes(fileType)
}

function getFileTypeIcon(fileName = '') {
  switch (extractFileType(fileName)) {
    case 'csv':
      return (
        <div style={{ width: '40px' }} dangerouslySetInnerHTML={{ __html: csvIcon }} />
      )
    case 'xls':
    case 'xlsx':
      return (
        <div style={{ width: '40px' }} dangerouslySetInnerHTML={{ __html: excelIcon }} />
      )
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
      return (
        <ImageIcon width="40px" />
      )
    default:
      return (
        <div style={{ width: '40px' }} dangerouslySetInnerHTML={{ __html: genericIcon }} />
      )
  }
}

function MUIControlledFileInput({
  innerRef,
  name,
  value,
  fileTypes = '*',
  fileTypesDescription,
  onChange,
  processing = false,
  gxid = '',
  showRemoveOption = true,
  // invalid = false,
  // ...inputProps
}) {
  function handleChange(event) {
    event.preventDefault()
    onChange(event.target.files[0])
  }

  function clearInput() {
    document.getElementById(`fileInput_${name}`).value = '' // remove the file from the inpuit itself
    onChange(null)
  }

  return (
    <Box sx={{
      border: '1px solid #e3e3e3',
      padding: '2rem',
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      color: '#000',
      position: 'relative',
    }}
    >
      <input
        name={name}
        ref={innerRef}
        type="file"
        hidden
        onChange={handleChange}
        id={`fileInput_${name}`}
        accept={fileTypes}
      />
      {processing && (
        <Box sx={{
          background: 'rgba(255,255,255,.75)',
          position: 'absolute',
          zIndex: 100,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {!value && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <CloudUploadIcon sx={{ fill: '#e3e3e3' }} />
          <Box sx={{ fontWeight: 'bold', mt: '.5rem' }}>Browse to add your file</Box>
          <Box sx={{ color: '#7b7e84' }}>
            Supports:
            {' '}
            {fileTypesDescription || fileTypes}
          </Box>

          <div>
            <div style={{
              position: 'relative',
              display: 'inline',
            }}
            >
              <label htmlFor={`fileInput_${name}`}>
                <Box
                  aria-label="Browse Files"
                  area-role="button"
                  data-gxid={gxid}
                  sx={{
                    border: 'solid 1px #2a2d36',
                    mt: '1rem',
                    color: '#2a2d36',
                    textTransform: 'capitalize',
                    borderRadius: '.5rem',
                    fontWeight: 'bold',
                    px: '1.5rem',
                    py: '.25rem',
                    userSelect: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#2a2d36',
                      color: '#fff',
                    },
                  }}
                >
                  Browse Files
                </Box>

              </label>
            </div>
          </div>
        </Box>
      )}

      { value && (isImageFile(value.name) || isImageFile(value)) && (
      <Box>
        <Box display="flex" justifyContent="center">
          <img
            src={typeof value === 'string' ? value : URL.createObjectURL(value)}
            alt=""
            style={{
              backgroundColor: '#d9d9d9',
              maxWidth: '100%',
            }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="16px">
          <label
            role="button"
            tabIndex={0}
            htmlFor={`fileInput_${name}`}
            style={{
              cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', display: 'block', marginRight: '1rem', textTransform: 'uppercase', fontWeight: 'bold',
            }}
          >
            Replace
          </label>
          {showRemoveOption && (
          <button
            type="button"
            onClick={() => clearInput()}
            style={{
              background: 'transparent',
              border: 'none',
              color: 'rgba(0, 0, 0, 0.87)',
              cursor: 'pointer',
              textTransform: 'uppercase',
              fontWeight: '600',
              fontSize: '0.875rem',
              fontFamily: '"Roboto Regular", "Helvetica", "Arial", sans-serif',
              lineHeight: '1.5rem',
              marginTop: '-1px',
            }}
          >
            Remove
          </button>
          )}
        </Box>
      </Box>
      ) }

      { value && !(isImageFile(value.name) || isImageFile(value)) && (
        <Box sx={{
          display: 'flex',
        }}
        >
          <Box>
            {getFileTypeIcon(value.name)}
          </Box>
          <Box sx={{
            ml: '1rem',
          }}
          >
            <Box sx={{ mt: '8px', mb: '12px' }}>{value.name}</Box>
            <Box sx={{ color: '#7b7e84' }}>
              { /* TODO: Fix accessability */}
              <label
                role="button"
                tabIndex={0}
                htmlFor={`fileInput_${name}`}
                style={{
                  cursor: 'pointer', color: '#0e5777', display: 'inline-block', marginRight: '1rem',
                }}
              >
                Replace
              </label>
              {showRemoveOption && <label role="button" tabIndex={0} onClick={() => clearInput()} style={{ cursor: 'pointer', color: '#0e5777' }}>Delete</label>}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export { MUIControlledFileInput }
