import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

const useStyles = createUseStyles({
  link: {
    color: '#0E5777',
    fontFamily: 'Roboto Regular, Helvetica, sans-serif',
    fontWeight: 400,
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover, &:focus': {
      color: '#0E5777',
      textDecoration: 'underline',
    },
  },
  linkDisabled: {
    color: '#ccc',
    textDecoration: 'none',
    cursor: 'default',
    pointerEvents: 'none',
  },
})

const buildClassName = ({ classes, isTextMuted }) => (isTextMuted ? `${classes.link} text-muted` : classes.link)

export function StyledLink({
  to,
  onClick,
  contents,
  newWindow = false,
  styles,
  external,
  clickHandlerOnly = false,
  icon,
  isTextMuted = false,
}) {
  const classes = useStyles()

  return external ? (
    <a href={to} className={buildClassName({ classes, isTextMuted })} target={newWindow ? '_blank' : ''} style={styles} rel="noreferrer">
      {contents}
      {' '}
      {icon}
    </a>
  ) : (
    <Link
      to={to || '/'}
      onClick={(e) => {
        if (onClick) onClick()
        if (clickHandlerOnly) e.preventDefault()
      }}
      className={buildClassName({ classes, isTextMuted })}
      target={newWindow ? '_blank' : ''}
      style={styles}
    >
      {contents}
      {' '}
      {icon}
    </Link>
  )
}

StyledLink.propTypes = {
  contents: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.shape({}),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  newWindow: PropTypes.bool,
  icon: PropTypes.node,
  external: PropTypes.bool,
  clickHandlerOnly: PropTypes.bool,
  isTextMuted: PropTypes.bool,
}

export default StyledLink
