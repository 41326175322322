import React, { Fragment } from 'react'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import SearchPopover from './SearchPopover'
import { compose, withState, withHandlers } from 'recompose'

export const QuickSearch = ({ quickSearchState, openMenu, onClose }) => (
	<Fragment>
		<IconButton size='small' onClick={openMenu}>
			<SearchIcon  style={{color:'white'}}/>
		</IconButton>
		{quickSearchState.isOpen && (
			<SearchPopover anchorEl={quickSearchState.anchorEl} isOpen={quickSearchState.isOpen} onClose={onClose} />
		)}
	</Fragment>
)

const enhance = compose(
	withState('quickSearchState', 'setQuickSearchState', { isOpen: false, anchorEl: null }),
	withHandlers({
		openMenu: props => event => {
			event.preventDefault()
			props.setQuickSearchState({
				...props.quickSearchState,
				isOpen: true,
				anchorEl: event.currentTarget.parentNode,
			})
		},
		onClose: props => event => {
			props.setQuickSearchState({ ...props.quickSearchState, isOpen: false })
		},
	})
)

export default enhance(QuickSearch)
