/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

function RightIconMenu({ onCopyLink, onEdit, onDelete }) {
  const [open, setOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-haspopup="true"
        onClick={(e) => {
          setAnchor(e.currentTarget)
          setOpen(!open)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={() => {
          setOpen(false)
          setAnchor(null)
        }}
      >
        <MenuItem onClick={onCopyLink}>Copy link</MenuItem>
        <MenuItem onClick={onEdit}>Edit link</MenuItem>
        <MenuItem onClick={onDelete}>Delete link</MenuItem>
      </Menu>
      {/* </ClickAwayListener> */}
    </div>
  )
}

export default RightIconMenu
