import React, { useState } from 'react'
import ModalContainer from '../../../../../Modals/ModalContainer'
import DataTable from '../../../../../@Common/DataTable';
import { Box } from '@mui/system';
import PropTypes from 'prop-types'
import StyledLink from '../../../../../StyledLink';
import { set } from 'store';
import { DialogContent } from '@mui/material';
import { offer } from '../../../../../../api/schema';
import moment from 'moment';

function SyndicatedListingsModal({ onClickClose, onClickDelete, listings, offer }) {
    const [deleteClicked, setDeleteClicked] = useState(null)
    const [listingRows, setListingRows] = useState(listings)

    const handleRevoke = (row) => {
        onClickDelete({ syndicateId: row.syndicateId, syndicateListingId: row.listingId, name: offer.name, syndicateListingName: row.listingName })
        setDeleteClicked(null)
        const remaingListings = listingRows.filter((listing) => listing.syndicateId !== row.syndicateId)
        setListingRows(remaingListings)
        if (remaingListings.length === 0) {
            onClickClose()
        }
    }

    return (
        <ModalContainer
            title={`Offer Syndication Details`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { console.log('hiding modal') }}
            actions={{
                secondaryAction: { label: 'Close', onClick: onClickClose },
            }}>
            {<>
                <DialogContent sx={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px' }}>
                    These are the listings that <b>{offer.name}</b> is syndicated to.
                </DialogContent>
                <Box>
                    <DataTable
                        slots={{
                            footer: () => <></>
                        }}
                        autoPageSize
                        hideFooterRowCount
                        disableColumnFilter
                        pagination={listings?.length > 5 ? true : false}
                        rows={listingRows || []}
                        getRowId={(row) => row.listingId}
                        columns={[
                            {
                                field: 'listingName',
                                headerName: 'Listing Name',
                                type: 'string',
                                width: 200,
                                filterable: false,
                                renderCell: ({ row }) => {
                                    return <StyledLink newWindow={true} to={`partners/listings/${row.listingId}/offers/offers`} onClick={() => onClickClose()} contents={row.listingName} />
                                }

                            },
                            {
                                field: 'syndicatedOn',
                                type: 'string',
                                width: 150,
                                headerName: 'Date Added',
                                valueGetter: (params) => (params.row.syndicatedOn
                                    ? new Date(params.row.syndicatedOn) : null),
                                renderCell: (row) => <>
                                    {moment(row.row.date).format('MM/DD/YYYY')}
                                </>
                            },
                            {
                                width: 200,
                                headerName: 'Actions',
                                field: 'actions',
                                renderCell: ({ row }) => {
                                    return deleteClicked && deleteClicked.syndicateId === row.syndicateId ?
                                        <Box>
                                            Are you sure?
                                            <Box sx={{ display: 'inline-flex', marginLeft: '3px' }}>
                                                <StyledLink to={'#'} onClick={() => handleRevoke(row)} contents="Yes" />
                                            </Box>
                                            <Box sx={{ display: 'inline-flex', paddingLeft: '2px', paddingRight: '2px' }}> / </Box>
                                            <Box sx={{ display: 'inline-flex' }}>
                                                <StyledLink to={'#'} onClick={() => setDeleteClicked(null)} contents="No" />
                                            </Box>
                                        </Box> :
                                        <StyledLink to={'#'} onClick={() => setDeleteClicked({ syndicateId: row.syndicateId })} contents="Revoke Syndication" />
                                }

                            },
                        ]}
                    />
                </Box>
            </>
            }

        </ModalContainer>
    )
}

export default SyndicatedListingsModal

SyndicatedListingsModal.propTypes = {
    onClickClose: PropTypes.func.isRequired,
    listings: PropTypes.arrayOf(PropTypes.shape({
        listingId: PropTypes.string,
        listingName: PropTypes.string,
        syndicatedOn: PropTypes.string,
        length: PropTypes.number,
    })),
}
