import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_LISTING_LIST } from '../../auth/policies'
import LazyProtectedRoute from '../LazyProtectedRoute'

const VerificationPageComponent = React.lazy(() => import('../../components/Listings'))

export default {
	path: RouteConstants.verification.listings.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_LISTING_LIST]}
			component={<VerificationPageComponent />} 
		/>
	),
}