import React from 'react'
import EventListings from './EventsList'
import useEventsList from './useEventsList'
import useEventsCreateEdit from './useEventsCreateEdit'

function EventsSection() {

    return (
        <EventListings
            {...useEventsList()}
            {...useEventsCreateEdit()}
        />

    )
}

EventsSection.propTypes = {
}

export default EventsSection
