/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, fetchPolicies, updateOccupation } from '../../../redux/actions'
import { hasPolicy } from '../../../redux/selectors'
import { EDIT_AFFILIATIONS } from '../../../auth/policies'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export function EditAffiliationNameModal({ occupation }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)
  const canEdit = useSelector(hasPolicy(EDIT_AFFILIATIONS))

  const onCancel = () => {
    dispatch(hideModal())
  }
  const getPolicies = () => dispatch(fetchPolicies([EDIT_AFFILIATIONS]))

  useEffect(() => {
    getPolicies()
  }, [])

  const { handleSubmit, control } = useForm({
    defaultValues: occupation,
  })

  const onSubmit = (values) => {
    setProcessing(true)

    return dispatch(updateOccupation({
      ...occupation,
      name: values.name,
    }))
      .then(() => {
        setProcessing(false)
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('updateOccupation error', err)
      })
  }

  return (
    <ModalContainer
      title={occupation.ancestorName.split('|').join(' / ')}
      modal
      maxWidth="sm"
      fullWidth="false"
      processing={processing}
      actions={
        canEdit
          ? {
            primaryAction: {
              label: 'Save',
              onClick: handleSubmit(onSubmit),
              disabled: !occupation,
            },
            secondaryAction: {
              label: 'Cancel',
              onClick: onCancel,
            },
          }
          : {
            primaryAction: {
              label: 'Close',
              onClick: onCancel,
            },
          }
      }
    >
      <form onSubmit={canEdit ? handleSubmit(onSubmit) : () => {}} style={{ marginTop: '10px', minWidth: '500px' }}>
        <MUIFormTextInput
          control={control}
          name="name"
          autoFocus
          label="Edit display name"
        />
      </form>
    </ModalContainer>
  )
}

export default EditAffiliationNameModal
