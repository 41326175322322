import React from 'react'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_VERIFICATION_REQUEST_LIST_PAGE } from '../../auth/policies'

const VerificationRequestsPage = React.lazy(() => import("../../components/Verification"))

export default {
	path: RouteConstants.verification.review.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_VERIFICATION_REQUEST_LIST_PAGE]}
			component={<VerificationRequestsPage />} 
		/>
	),
}