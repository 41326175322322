import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import Button from '../Button'
import PageOverlay from '../PageOverlay'
import GovXLogo from '../GovXLogo'
import { MUIFormTextInput } from '../@Common/Form/TextInput/FormTextInput'

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  logo: {
    position: 'absolute',
    top: '30px',
    left: '30px',
    width: '150px',
    height: '30px',
  },
  logoPath: {
    fill: '#222',
  },
  twoThirds: {
    backgroundImage: 'url(/images/bg-register-thankyou.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '66.7vw',
    height: '100vh',
  },
  oneThird: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.3vw',
    height: '100vh',
    padding: '0px 75px',
    backgroundColor: '#fff',
    color: '#222',
  },
  footer: {
    height: '60px',
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    left: '0px',
    margin: '0px auto',
    backgroundColor: '#323440',
    color: '#fff',
  },
  footerList: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    padding: '0px',
  },
  footerListItem: {
    display: 'inline',
    margin: '0px 40px',
  },
  footerListItemLink: {
    color: '#fff',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

function LoginPage({ onLogin, showOverlay }) {
  const classes = useStyles()
  const { control, handleSubmit } = useForm()
  const [loginError, setLoginError] = useState(null)

  const onSubmit = async (data) => {
    setLoginError(null)
    try {
      await onLogin(data)
    } catch (error) {
      setLoginError(error.message)
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.twoThirds}>
        <GovXLogo classes={{ logo: classes.logo, logoPath: classes.logoPath }} />
      </Box>
      <Box className={classes.oneThird}>
        {showOverlay && <PageOverlay />}
        <Box width="100%">
          <Typography variant="h1" style={{ marginTop: '0px', fontSize: '28px' }}>
            Log In
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '15px' }}>
            <Box>
              <MUIFormTextInput
                control={control}
                shrinkLabel
                name="email"
                label="Enter your email address"
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter your email address',
                  },
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email address',
                  },
                }}
              />
            </Box>
            <Box mt="10px" mb="30px">
              <MUIFormTextInput
                control={control}
                shrinkLabel
                name="password"
                label="Enter your password"
                type="password"
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter your password',
                  },
                }}
              />
            </Box>

            <Button
              type="submit"
              label="Log in"
              fullWidth
              color="primary"
              size="large"
              style={{ width: '100%' }}
            />
            <Box color="#bd2326" fontSize=".875rem" mt="16px">{loginError}</Box>
          </form>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <ul className={classes.footerList}>
          <li className={classes.footerListItem}>
            <a href="https://www.GovXInc.com/" className={classes.footerListItemLink} target="_blank" rel="noreferrer">
              About GovX
            </a>
          </li>
          <li className={classes.footerListItem}>
            <a href="https://support.govxinc.com/hc/en-us" className={classes.footerListItemLink} target="_blank" rel="noreferrer">
              Resources
            </a>
          </li>
          <li className={classes.footerListItem}>
            <a href="https://www.govxinc.com/privacy-policy" className={classes.footerListItemLink} target="_blank" rel="noreferrer">
              Privacy
            </a>
          </li>
          <li className={classes.footerListItem}>
            <a
              href="https://www.govxinc.com/terms-of-service/partners"
              className={classes.footerListItemLink}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </li>
        </ul>
      </Box>
    </Box>
  )
}

LoginPage.propTypes = {
  onLogin: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
}

export default LoginPage
