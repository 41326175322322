import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { routes } from './setup/routes'
import App from './containers'
import { init } from './app'

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('4fc089ce422ca71896cde22746efb12cTz0xMDUyNTcsRT0xNzY3ODIyNjEzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

init().then(({ store, history }) => {
	const container = document.querySelector('#root')
	const root = createRoot(container)

	root.render(
		<Provider store={store}>
			<BrowserRouter>
				<App routes={routes} />
			</BrowserRouter>
		</Provider>
	)
})
