import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_AFFILIATIONS_PAGE } from '../../auth/policies'
import LazyProtectedRoute from '../LazyProtectedRoute'

const OccupationTaxonomyPage = React.lazy(() => import('../../components/Occupations/Taxonomy'))

export default {
	path: RouteConstants.occupations.taxonomy.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_AFFILIATIONS_PAGE]}
			component={<OccupationTaxonomyPage />} 
		/>
	),
}