import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import ModalContainer from '../ModalContainer'
import { hideModal, unlockUser } from '../../../redux/actions'

export function UnlockUserModal({ user }) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const onConfirm = () => {
    dispatch(unlockUser(user.id))
      .then(dispatch(hideModal()))
  }

  return (
    <ModalContainer
      title="Unlock User"
      modal
      maxWidth="xs"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Confirm',
          onClick: () => onConfirm(user),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <Typography style={{ marginTop: '10px' }}>
        Are you sure you want to unlock this user?
      </Typography>
    </ModalContainer>
  )
}

UnlockUserModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default UnlockUserModal
