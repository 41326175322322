/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Tooltip from '@mui/material/Tooltip'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  active: {
    backgroundColor: '#303030 !important',
    color: '#fff !important',
    cursor: 'default',
    pointerEvents: 'none',
  },
  activeParentCollapsed: {
    backgroundColor: '#303030 !important',
    color: '#fff !important',
    cursor: 'default',
    pointerEvents: 'none',
  },
  listItemWrapper: {
    '&:hover ~ $tooltip': {
      opacity: 1,
    },
  },
  tooltip: {
    cursor: 'default',
    pointerEvents: 'none',
    opacity: 0,
  },
  '@global': {
    '.parentListItem button svg': {
      fill: '#ccc !important',
    },
    '.parentListItem + div > div > a:hover': {
      backgroundColor: 'rgba(0,0,0,.1) !important',
    },
  },
  selected: {
    color: 'white',
  },
})

function NavListItem({
  text,
  icon,
  link,
  children = [],
  drawerExpanded,
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()
  const [collapseOpen, setCollapseOpen] = useState(false)

  const onItemClick = () => {
    setCollapseOpen(!collapseOpen)
    if (!children && link) {
      navigate(link)
    }
  }

  const active = location.pathname === link

  const activeChildrenCount = children
    ? children.reduce((count, child) => count + (location.pathname === child.link ? 1 : 0), 0)
    : 0

  // eslint-disable-next-line
  if (!children.length) children = null

  return (
    <div style={{ position: 'relative' }}>

      <div>
        <Tooltip title={text} placement="right">
          <ListItemButton
            classes={{
              selected: {
                color: 'white',
              },
            }}
            className={classNames({
              [classes.active]: active,
              [classes.activeParentCollapsed]: activeChildrenCount && !drawerExpanded,
              parentListItem: !!children,
            })}
            onClick={onItemClick}
            sx={{ color: 'white', height: '55px !important' }}
          >
            {/* eslint-disable-next-line */}
            <ListItemIcon children={icon} sx={{margin: drawerExpanded ? '0 15px 0 7px' : '0 auto', height: '28px', minWidth: '28px'}} />
            <ListItemText
              sx={{ display: drawerExpanded ? 'flex' : 'none' }}
              primaryTypographyProps={{
                style: { color: 'white' },
              }}
            >
              {drawerExpanded ? text : ''}
            </ListItemText>
            {children && (
              <div>
                {collapseOpen || activeChildrenCount > 0
                  ? <ExpandLess /> : <ExpandMore />}
              </div>
            )}
          </ListItemButton>
        </Tooltip>
        {children && (
          <Collapse in={collapseOpen || activeChildrenCount > 0} timeout="auto" unmountOnExit>
            <List disablePadding>
              {children.map((item, i) => (
                // eslint-disable-next-line
                <Tooltip title={item.text} placement="right" key={i}>
                  <ListItemButton
                    className={item.active ? classes.active : ''}
                    onClick={() => navigate(item.link)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: { color: 'white', marginLeft: '25%', textAlign: 'left' },
                      }}
                    >
                      {item.text}
                    </ListItemText>
                  </ListItemButton>
                </Tooltip>
              ))}
            </List>
          </Collapse>
        )}
      </div>
    </div>
  )
}

export default NavListItem
