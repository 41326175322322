import { createSlice } from '@reduxjs/toolkit';
import { api } from './apiSlice'

export const billingApiSlice = api.injectEndpoints({
    endpoints: build => ({
        getInvoicesByListingId: build.query({
            query: (listingId) => `marketplace/billing/invoices/listing/${listingId}`,
            providesTags: ['InvoicesByListing'],
            method: 'GET',
        }),
        getInvoiceDetailsByInvoiceId: build.query({
            query: (invoiceId) => `marketplace/billing/invoice/details/${invoiceId}`,
            providesTags: ['InvoiceDetails'],
            method: 'GET',
        }),
        getBillingTermsByLisstingId: build.query({
            query: (listingId) => `marketplace/billing/terms/listing/${listingId}`,
            providesTags: ['BillingTerms'],
            method: 'GET',
        }),
        getBillableSubscriptionByListingId: build.query({
            query: (listingId) => `marketplace/billing/subscription/${listingId}/billable`,
            providesTags: ['BillableSubscription'],
            method: 'GET',
        }),
        createBillableEvent: build.mutation({
            query: billableEvent => ({
                url: `/marketplace/billing/event`,
                method: 'POST',
                body: billableEvent
            }),
            invalidatesTags: ['BillableEvents'],
        }),
    })
})

const initialState = [];
const billingSlice = createSlice({
    name: 'billingNew',
    initialState,
    reducers: {},
})

export const {
    useGetInvoicesByListingIdQuery,
    useGetInvoiceDetailsByInvoiceIdQuery,
    useLazyGetInvoiceDetailsByInvoiceIdQuery,
    useGetBillingTermsByLisstingIdQuery,
    useGetBillableSubscriptionByListingIdQuery,
    useCreateBillableEventMutation
} = billingApiSlice;

export default billingSlice.reducer;
