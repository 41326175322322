import React from 'react'
import { getIconUrls } from '../../setup/configuration'

export const getIconUrl = iconType => {
	let iconUrls = getIconUrls()
	switch (iconType) {
		case '1':
			return iconUrls.FirstResponder
		case '2':
			return iconUrls.Government
		case '3':
			return iconUrls.Military
		case '4':
			return iconUrls.Police
		case '5':
			return iconUrls.GovXFlag
		case '6':
			return iconUrls.Medical
		case '7':
			return iconUrls.Education
		case '0':
		default:
			return null
	}
}

export const OccupationIcon = ({ iconType, size, styles }) => {
	let iconUrl = getIconUrl(String(iconType))
	return (
		<div style={{ height: '100%' }}>
		{
			iconUrl
			? <img src={ iconUrl } style={{ ...styles, width: size, height: size }} />
			: <div style={{ ...styles, width: size - 2, height: size - 2, border: '1px dashed #ddd' }}></div>
		}
		</div>
	)
}

export default OccupationIcon