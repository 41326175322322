/* eslint-disable no-console */

import { useDispatch } from "react-redux"
import { hideModal, showErrorNotification, showModal, showSuccesNotification, showSuccessNotification } from "../../../../../../redux/actions"
import { useArchiveEventMutation, useBulkArchiveEventsMutation, useGetEventsByPartnerListingIdQuery, useBulkUpdateEventsMutation, useImportEventsMutation, useLazyGetEventImportByIdQuery, useLazyGetEventsByPartnerListingIdQuery } from "../../../../../../redux/slices/eventsSlice"
import { useOutletContext, useParams } from "react-router-dom"
import { useGetOffersLookupsQuery } from "../../../../../../redux/slices/lookupsSlice"
import { useEffect, useRef, useState } from "react"
import { updateModal } from "../../../../../../redux/modules/modal"
import ImportEventsModal from "./ImportEventsModal"
import { useModal } from "../../../../../../hooks/useModal"
import { set } from "store"

const useEventsList = () => {
    const dispatch = useDispatch()
    const [importErrors, setImportErrors] = useState([])
    const [importProcessing, setImportProcessing] = useState(false)
    const { id: partnerListingId } = useParams()
    const {
        offers
    } = useOutletContext()
    const lookups = useGetOffersLookupsQuery()
    const interval = useRef(null)
    const importCounter = useRef(0)

    const {
        data: events,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
    } = useGetEventsByPartnerListingIdQuery(partnerListingId)

    const [archiveEvent] = useArchiveEventMutation()
    const [bulkArchiveEvents] = useBulkArchiveEventsMutation()
    const [bulkUpdateEvents] = useBulkUpdateEventsMutation()
    const [importEvents] = useImportEventsMutation()
    const [getEventImport] = useLazyGetEventImportByIdQuery()
    const [getEvents] = useLazyGetEventsByPartnerListingIdQuery()

    const { openModal, renderModal, updateModalProps, closeModal } = useModal({
        IMPORT_EVENTS: {
            component: ImportEventsModal,
        },
    })

    const openImportModal = (props) => {
        openModal('IMPORT_EVENTS', props)
    }

    const openArchiveEventModal = (props) => dispatch(showModal('ARCHIVE_EVENT_MODAL', props))
    const openGovXIdLinkModal = (offer) => dispatch(showModal('VIEW_GOVX_LINK', {
        offer,
        title: 'GOVX ID Event Link',
        message: `Use this link to advertise the event on your website. Customers that
          click the link will be asked to log in or verify before accessing the
          discount.` }))
    const openBulkEditEventsModal = (props) => dispatch(showModal('BULK_EDIT_EVENTS_MODAL', props))

    const showErrorMessage = () => {
        clearInterval(interval.current)
        setImportProcessing(false)
        dispatch(showErrorNotification('There was an error while attempting to import the events.'))
    }

    const onClickImportEvents = () => {
        openImportModal({
            importErrors,
            importProcessing,
            onFileChange: () => {
                setImportErrors([])
                setImportProcessing(false)
            },
            onClickSubmit: async ({ eventsFile }) => {
                try {
                    setImportProcessing(true)
                    const res = await importEvents({ id: partnerListingId, body: eventsFile })
                    if (res && res.data?.success) {
                        interval.current = setInterval(async () => {
                            if (importCounter.current < 31) {
                                importCounter.current += 2
                                try {
                                    const importRes = await getEventImport(res.data.importId).unwrap()
                                    if (importRes) {
                                        if (importRes.status === 2) {
                                            clearInterval(interval.current)
                                            setImportProcessing(false)
                                            if (importRes.errors?.length === 0) {
                                                closeModal()
                                                dispatch(showSuccesNotification('Imported events successfully.'))
                                                clearInterval(interval.current)
                                                await getEvents(partnerListingId)
                                            } else {
                                                setImportErrors(importRes.errors)
                                            }
                                        }
                                        if (importRes.status === 3) {
                                            showErrorMessage()
                                        }
                                    } else {
                                        showErrorMessage()
                                    }
                                } catch (err) {
                                    showErrorMessage()
                                }
                            } else {
                                showErrorMessage()
                            }
                        }, 2000)
                    }
                    else if (res && !res.data.success && res.data.errorMessage != null) {
                        setImportProcessing(false)
                        dispatch(showErrorNotification(res.data.errorMessage))
                    }
                    else if (res && !res.data.success) {
                        showErrorMessage()
                    }
                    return res.data
                } catch (err) {
                    showErrorMessage()
                    throw err
                }
            },
            onClickClose: () => {
                setImportProcessing(false)
                clearInterval(interval.current)
                setImportErrors([])
                closeModal()
            },
        })
    }

    const onClickBulkEditEvents = async (eventIds) => {
        openBulkEditEventsModal({
            eventIds,
            offers,
            lookups,
            onClickSubmit: async (data) => {
                try {
                    await bulkUpdateEvents(data).unwrap()
                } catch (error) {
                    console.error(err)
                    dispatch(showErrorNotification(`Something went wrong, we weren't able to save the events changes.`))
                    dispatch(hideModal())

                } finally {
                    dispatch(showSuccesNotification(`${eventIds?.length} event${eventIds?.length > 1 ? 's' : ''} successfully updated.`))
                    dispatch(hideModal())
                }
            },
        })
    }

    const onClickArchiveEvents = (eventIds) => {
        openArchiveEventModal({
            eventIds,
            onClickSubmit: async (eventIds) => {
                try {
                    if (eventIds?.length === 1) {
                        await archiveEvent(eventIds[0]).unwrap()
                    } else[
                        await bulkArchiveEvents(eventIds).unwrap()
                    ]
                } catch (error) {
                    dispatch(showErrorNotification(`Something went wrong, we weren't able to archive the event${eventIds?.length > 1 ? 's' : ''}.`))
                    dispatch(hideModal())
                } finally {
                    dispatch(showSuccesNotification(`${eventIds?.length > 1 ? eventIds?.length + ' e' : 'E'}vent${eventIds?.length > 1 ? 's' : ''} successfully archived.`))
                    dispatch(hideModal())
                }
            },
        })
    }

    useEffect(() => {
        updateModalProps('IMPORT_EVENTS', {
            importErrors: importErrors
        })
    }, [importErrors])

    useEffect(() => {
        updateModalProps('IMPORT_EVENTS', {
            importProcessing: importProcessing
        })
    }, [importProcessing])

    useEffect(() => {
        if (interval.current !== null) {
            return () => clearInterval(interval.current) && setImportErrors([])
        }
    }, [])

    return {
        renderModal,
        events,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        importErrors,
        onClickImportEvents,
        onClickArchiveEvents,
        openGovXIdLinkModal,
        onClickBulkEditEvents
    }
}

export default useEventsList
