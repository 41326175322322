/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { submit, reset } from 'redux-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import ModalContainer from '../ModalContainer'
import OccupationSelector from '../../Occupations/OccupationSelector'
import PrequalForm from '../../Occupations/PrequalForm'
import { fetchOccupationPrequalifiers, hideModal } from '../../../redux/actions'

export function AddAffiliationModal({ userId }) {
  const dispatch = useDispatch()
  const [occupation, setOccupation] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [listingName, setListingName] = useState('govx')

  const onSave = () => {
    dispatch(submit('verify-prequal'))
  }
  const onCancel = () => {
    reset('verify-prequal')
    dispatch(hideModal())
  }
  const getOccupationPrequalifiers = (occupationId) => dispatch(fetchOccupationPrequalifiers(occupationId))

  const onAffiliationChange = (newOccupation) => {
    setOccupation(newOccupation)
    if (newOccupation) getOccupationPrequalifiers(newOccupation.id)
  }

  return (
    <ModalContainer
      title="Add Affiliation"
      modal
      maxWidth="sm"
      fullWidth="false"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: onSave,
          disabled: !occupation || !listingName,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <div style={{ marginTop: '10px', minWidth: '420px' }}>
        <OccupationSelector onChange={onAffiliationChange} />
        <div style={{ marginTop: '8px' }}>
          <PrequalForm
            occupationId={occupation ? occupation.id : null}
            userId={userId}
            onRequestCreated={onCancel}
            listingName={listingName}
            setProcessing={setProcessing}
          />
        </div>

        <Typography style={{ margin: '20px 0px 5px', fontSize: '16px', opacity: 0.6 }}>Parent listing</Typography>
        <RadioGroup>
          <FormControlLabel
            value="govx"
            control={(
              <Radio
                value="govx"
                checked={listingName === 'govx'}
                label="GovX"
                onClick={() => setListingName('govx')}
              />
    )}
            label="GovX"
          />
          <FormControlLabel
            value="any"
            control={(
              <Radio
                value="vcs"
                checked={listingName === 'vcs'}
                label="VCS"
                onClick={() => setListingName('vcs')}
              />
    )}
            label="VCS"
          />
        </RadioGroup>
      </div>
    </ModalContainer>
  )
}

export default AddAffiliationModal
