import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const style = { fontFamily: 'monospace' }

function Specs({ listing, partner }) {
  return (
    <Grid container>
      <Grid item xs={2}>Listing Id</Grid>
      <Grid item xs={10}><span style={style}>{listing.id}</span></Grid>
      <Grid item xs={2}>Partner Id</Grid>
      <Grid item xs={10}><span style={style}>{partner.id}</span></Grid>
      <Grid item xs={2}>Partner Name</Grid>
      <Grid item xs={10}><span style={style}>{partner.name}</span></Grid>
      <Grid item xs={2}>Source</Grid>
      <Grid item xs={10}><span style={style}>{partner.source}</span></Grid>
      <Grid item xs={2}>Source Key</Grid>
      <Grid item xs={10}><span style={style}>{partner.sourceKey}</span></Grid>
    </Grid>
  )
}

Specs.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  partner: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    source: PropTypes.string,
    sourceKey: PropTypes.string,
  }).isRequired,
}

export default Specs
