import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../../../redux/actions'
import ModalContainer from '../../../../../Modals/ModalContainer'
import { Box } from '@mui/system'

function ArchiveEventModal({
    eventIds,
    onClickSubmit,
}) {
    const dispatch = useDispatch()
    const [processing, setProcessing] = useState(false)

    const getModalText = () => {
        return `You are about to archive ${eventIds.length === 1 ? 'an event' : eventIds.length + ' events'}. This action cannot be undone. Please confirm below.`
    }

    return (
        <ModalContainer
            title={`Are you sure?`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}
            processing={processing}
            actions={{
                primaryAction: {
                    label: 'Archive',
                    onClick: async () => {
                        setProcessing(true)

                        try {
                            await onClickSubmit(eventIds)
                        } finally {
                            setProcessing(false)
                        }
                    },
                },
                secondaryAction: { label: 'Cancel', onClick: () => dispatch(hideModal()) },
            }}
        >
            <Box>{getModalText()}</Box>
        </ModalContainer>
    )

}

export default ArchiveEventModal    