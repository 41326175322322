import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState, withHandlers, connect } from 'recompose'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Autosuggest from 'react-autosuggest'
import { withStyles } from '@mui/styles'
import { Field } from 'redux-form'

const styles = (theme) => ({
	root: {
		height: 250,
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 2,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
		paddingTop: '7px',
	},
	suggestionsList: {
		margin: 0,
		padding: '10px',
		listStyleType: 'none',
	},
	input: {},
	divider: {
		height: theme.spacing.unit * 2,
	},
})
function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.name, query)
	const parts = parse(suggestion.name, matches)

	return (
		<MenuItem selected={isHighlighted} component="div" style={{ height: '15px', background: 'white' }}>
			<div style={{ minWidth: '200px' }}>
				{parts &&
					parts.map((part, index) => {
						return part.highlight ? (
							<span key={index} style={{ fontWeight: 500 }}>
								<strong>{part.text}</strong>
							</span>
						) : (
							<span key={index} style={{ fontWeight: 'normal' }}>
								{part.text}
							</span>
						)
					})}
			</div>
		</MenuItem>
	)
}
function renderInputComponent(inputProps) {
	const { classes, inputRef = () => {}, meta: { touched, error }, ref, ...other } = inputProps

	return (
		<TextField
			fullWidth
			helperText={touched && error}
			variant='filled'
			error={touched && !!error}
			InputProps={{
				inputRef: node => {
					ref(node)
					inputRef(node)
				},
				classes: {},
			}}
			InputLabelProps={{
				error:false
			}}
			{...other}
		/>
	)
}

const getSuggestionValue = suggestion => {
	let value = suggestion && suggestion.name
	if (value) {
		return value
	}
	return ''
}

const autosuggestProps = {
	renderInputComponent,
	renderSuggestion,
}

export const renderAutoComplete = ({
	label,
	suggestions,
	onSuggestionsFetchRequested,
	onSuggestionsClearRequested,
	onSuggestionSelected,
	single,
	setSingle,
	classes,
	handleBlur,
	shouldRenderSuggestions,
	meta,
}) => (
	<Autosuggest
		shouldRenderSuggestions={shouldRenderSuggestions}
		alwaysRenderSuggestions={false}
		renderInputComponent={renderInputComponent}
		suggestions={suggestions}
		onSuggestionsFetchRequested={onSuggestionsFetchRequested}
		onSuggestionsClearRequested={onSuggestionsClearRequested}
		onSuggestionSelected={onSuggestionSelected}
		getSuggestionValue={getSuggestionValue}
		renderSuggestion={renderSuggestion}
		renderSuggestionsContainer={options => (
			<Paper {...options.containerProps} square>
				{options.children}
			</Paper>
		)}
		theme={{
			container: classes.container,
			suggestionsContainerOpen: classes.suggestionsContainerOpen,
			suggestionsList: classes.suggestionsList,
			suggestion: classes.suggestion,
		}}
		inputProps={{
			value: single,
			onChange: e => setSingle(e.target.value),
			onBlur: e => handleBlur(e),
			label: label,
			meta: meta,
		}}
	/>
)

const AutoComplete = ({
	name,
	label,
	hintText,
	classes,
	dataSource,
	dataSourceConfig,
	validate,
	onBlur,
	handleBlur,
	suggestions,
	handleSuggestionsFetchRequested,
	handleSuggestionsClearRequested,
	handleDepartmentSelected,
	single,
	setSingle,
	changeDepartment,
	shouldRenderSuggestions,
}) => (
	<Field
		name={name}
		label={label}
		hintText={hintText}
		component={renderAutoComplete}
		dataSource={dataSource}
		dataSourceConfig={dataSourceConfig}
		validate={validate}
		onBlur={onBlur}
		suggestions={suggestions}
		onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
		onSuggestionsClearRequested={handleSuggestionsClearRequested}
		onSuggestionSelected={handleDepartmentSelected}
		single={single}
		setSingle={setSingle}
		classes={classes}
		handleBlur={handleBlur}
		shouldRenderSuggestions={shouldRenderSuggestions}
	/>
)

AutoComplete.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	hintText: PropTypes.string,
	dataSource: PropTypes.array,
	dataSourceConfig: PropTypes.object,
	validate: PropTypes.array,
	onBlur: PropTypes.func,
	onSuggestionsFetchRequested: PropTypes.func,
}

const handleSuggestionsFetch = async (value, props) => {
	props.setSuggestions([])
    let search = value.value

	if(props.onSuggestionsFetchRequested) {
		let suggestions = await props.onSuggestionsFetchRequested(search)
		props.setSuggestions(suggestions)
	} else {
		let suggestions = props.dataSource.filter(d => d.name && d.name.indexOf(search) > -1)
		let s = suggestions || props.departments

		props.setSuggestions(s.length > 5 ? s.slice(0, 5) : s)
	}      
}

const enhance = compose(
	withState('single', 'setSingle', props => ''),
	withState('suggestions', 'setSuggestions', props => []),
	withState('searchValue', 'setSearchValue', props => ''),
	withState('timerId', 'setTimerId', props => ''),
	withHandlers({
		shouldRenderSuggestions: props => value => {
			return value && value.trim().length > 2
		},

		handleSuggestionsFetchRequested: props => value => handleSuggestionsFetch(value, props),
		handleSuggestionsClearRequested: props => e => {},
		handleBlur: props => e => {
			if (props.department && e.target.value.trim() !== props.department.name) {
				props.setDepartment({ name: e.target.value.trim(), jurisdiction: null, id: null })
				props.changeDepartment({ name: e.target.value.trim(), jurisdiction: null, id: null })
				props.setSingle(e.target.value.trim())
			}
		},
		handleDepartmentSelected: props => (event, data) => {
			if (data.suggestion.name) {
				props.setSearchValue(data.suggestion.name)
				var department = props.dataSource.find(d => d.name == data.suggestion.name)
				if (department) {
					props.setDepartment(department)
					props.changeDepartment(department)
				}
			}
			props.setSingle(data.suggestion.name)
		},
	}),
	//connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(AutoComplete)
