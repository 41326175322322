import React from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useController, useForm } from "react-hook-form";

function MuiTimePicker({
    label,
    gxId,
    name,
    rules,
    control
}) {
    const { control: backupControl } = useForm();
    const {
        field,
        fieldState: { error },
        formState: { touchedFields, dirtyFields, errors },
    } = useController({
        name: name,
        control: control ?? backupControl,
        rules
    });
    return (
        <DesktopTimePicker
            label={label}
            value={field.value ? moment(field.value, 'LT') : undefined}
            gxid={gxId}
            onChange={(date) => { field.onChange(date) }}
            sx={{ width: '100%' }}
            slotProps={{
                textField: { variant: 'filled' },
                inputProps: {
                    'data-gxid': gxId,
                },
                popper: {
                    sx: {
                        '.MuiMultiSectionDigitalClockSection-root': { width: '70px', overflowY: 'scroll', scrollbarWidth: 'none' },
                        '.MuiMenuItem-root': { width: 'auto' },
                    }
                }
            }}
        />
    )
}

export default MuiTimePicker;