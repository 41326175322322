/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl'
import Select2 from '../Inputs/Select'
import { formatOccupationType } from '../../formatting'
import { selectPublishedOccupationsHierarchy } from '../../redux/selectors'
import { fetchPublishedOccupations } from '../../redux/actions'

export const recursiveOccupationSelectors = (siblings, selectedOccupations, onSelectOccupation, depth) => {
  let selectors = []
  const currentOccupation = selectedOccupations[depth]

  selectors.push(
    <div style={{ marginBottom: '10px' }} key={Math.floor(Math.random() * (+1 - +10000)) + +1}>
      <FormControl fullWidth>
        <Select2
          label={formatOccupationType(siblings[0].type)}
          onChange={onSelectOccupation}
          value={currentOccupation !== undefined ? currentOccupation.id : undefined}
          items={[
            { key: '0', value: '', label: '' },
            ...(siblings.filter((s) => !s.path.toLowerCase().includes('unknown')).map((o) => (
              { key: o.id, value: o.id, label: o.name }
            ))),
          ]}
        />
      </FormControl>
    </div>,
  )

  if (currentOccupation && currentOccupation.children && currentOccupation.children.length > 0) {
    selectors = selectors.concat(
      recursiveOccupationSelectors(currentOccupation.children, selectedOccupations, onSelectOccupation, depth + 1),
    )
  }

  return selectors
}

const findNode = (id, currentNode) => {
  let i
  let currentChild
  let result

  if (id == currentNode.id) return currentNode

  for (i = 0; i < currentNode.children.length; i += 1) {
    currentChild = currentNode.children[i]

    result = findNode(id, currentChild)

    // Return the result if the node has been found
    if (result !== false) {
      return result
    }
  }
  return false
}

const findNodeInTree = (id, nodes) => {
  for (let i = 0; i < nodes.length; i++) {
    const node = findNode(id, nodes[i])
    if (node !== false) {
      return node
    }
  }
  return false
}

export function OccupationSelector({ onChange }) {
  const dispatch = useDispatch()
  const [selectedOccupations, setSelectedOccupations] = useState([])
  const occupations = useSelector(selectPublishedOccupationsHierarchy)

  const getPublishedOccupations = () => {
    dispatch(fetchPublishedOccupations())
  }

  const onSelectOccupation = (e, occupationId) => {
    let occupation
    if (occupationId != null) {
      occupation = findNodeInTree(occupationId, occupations)
    }

    let currentSelections = selectedOccupations
    if (currentSelections.length != 0) {
      const foundIndex = currentSelections.findIndex((x) => x.parentId == occupation.parentId)
      if (foundIndex >= 0) {
        currentSelections = currentSelections.slice(0, foundIndex)
      }
    }
    currentSelections.push(occupation)
    setSelectedOccupations([...currentSelections])

    if (occupation.children?.length == 0) { onChange(occupation) } else { onChange(null) }
  }

  useEffect(() => {
    getPublishedOccupations()
  }, [])

  return (
    <div>
      {occupations
      && occupations.length > 0
      && recursiveOccupationSelectors(occupations, selectedOccupations, onSelectOccupation, 0)}
    </div>
  )
}

export default OccupationSelector
