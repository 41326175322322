import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@mui/material/Button'

export const Button = ({
	label,
	type,
	color,
	secondary,
	onClick,
	fullWidth = true,
	icon,
	containerElement,
	largeButton = false,
	smallButton = false,
	tinyButton = false,
	style,
	size,
	buttonStyles,
	successDisabled,
	gxid = '',
	...custom
}) => (
	<MuiButton
		startIcon={icon}
		type={type ? type : 'submit'}
		label={label}
		color={color}
		variant='contained'
		{...fullWidth}
		size={size}
		onClick={onClick}
		data-gxid={gxid}
		{...custom} style={style}>{label}
	</MuiButton>
)

Button.propTypes = {
	onClick: PropTypes.func,
	type: PropTypes.string,
	secondary: PropTypes.bool,
}

export default Button