import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, deleteListingRedirectUrl } from '../../../redux/actions'

export function DeleteListingRedirectUrlModal({
  listingId,
  redirectUrl,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const takeAction = () => dispatch(deleteListingRedirectUrl(listingId, redirectUrl))
    .then(() => dispatch(hideModal()))
    .catch((err) => {
      console.log('deleteListingRedirectUrl error', err)
      dispatch(hideModal())
      throw err
    })

  return (
    <ModalContainer
      modal
      maxWidth="sm"
      fullWidth="true"
      title="Delete Whitelisted URL"
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: () => takeAction(listingId, redirectUrl),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ color: '#222', marginTop: '20px' }}>Are you sure you want to delete this url? This action may break your integration and cannot be undone.</p>
    </ModalContainer>
  )
}

DeleteListingRedirectUrlModal.propTypes = {
  listingId: PropTypes.number.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}

export default DeleteListingRedirectUrlModal
