import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, showSuccesNotification } from '../../../redux/actions'

export function ConfirmPublishModal({
  action,
  publish,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const takeAction = () => action()
    .then(dispatch(showSuccesNotification(publish ? 'Listing published' : 'Listing unpublished')))
    .then(dispatch(hideModal()))

  return (
    <ModalContainer
      modal
      maxWidth="xs"
      fullWidth="true"
      title={publish ? 'Publish listing' : 'Unpublish listing'}
      actions={{
        primaryAction: {
          label: publish ? 'Publish' : 'Unpublish',
          onClick: takeAction,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      {
    publish
      ? <p style={{ color: '#222' }}>Publishing this listing means users will be able to verify and log in. Do you wish to continue?</p>
      : <p style={{ color: '#222' }}>Unpublishing this listing means users will not be able to verify or log in. Do you wish to continue?</p>
  }
    </ModalContainer>
  )
}

ConfirmPublishModal.propTypes = {
  publish: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
}

export default ConfirmPublishModal
