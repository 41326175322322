/* eslint-disable max-len */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import { getListingPath } from '../../../setup/routeConstants'
import { hideModal, createListing } from '../../../redux/actions'
import { LOCATION_CHANGE } from '../../../setup/history'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export function CreateListingModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, control } = useForm()

  const onSubmit = (values) => {
    setProcessing(true)
    const createListingModel = { listingName: values.name, defaultOfflineRedirectUrl: values.defaultOfflineRedirectUrl }

    return dispatch(createListing(createListingModel))
      .then((id) => {
        setProcessing(false)
        navigate(getListingPath(id))
        dispatch({ type: LOCATION_CHANGE })
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('createListing error', err)
      })
  }

  return (
    <ModalContainer
      title="Create your listing"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Create listing',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '15px' }}>
        <MUIFormTextInput
          name="name"
          label="Name"
          autoFocus
          control={control}
          rules={{ required: true }}
        />
        <p className="no-margin">
          <small style={{
            display: 'block', lineHeight: '16px', color: '#acacac', marginTop: '5px',
          }}
          >
            Typically this is the name of your organization. Keep in mind, the listing name will be visible to your customers. You can always change this later.
          </small>
        </p>
        <Box mt="10px">
          <MUIFormTextInput
            name="defaultOfflineRedirectUrl"
            label="Your website URL"
            control={control}
            rules={{ required: true, pattern: { value: /^https?:\/\//i } }}
          />
        </Box>
        <p className="no-margin">
          <small style={{
            display: 'block', lineHeight: '16px', color: '#acacac', marginTop: '5px',
          }}
          >
            Please enter the URL of the website where you intend to deploy GovX verification.
          </small>
        </p>
      </form>
    </ModalContainer>
  )
}

export default CreateListingModal
