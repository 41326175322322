/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import Grid from '@mui/material/Grid'
import AdvertisingForm from '../../Forms/AdvertisingForm'
import Footer from './Footer'

export function AdvertisementSection({ listing }) {
  const formRef = useRef()

  return (
    <div style={{ position: 'relative' }}>
      <Grid container direction="column">
        <Grid item><AdvertisingForm innerRef={formRef} listing={listing} isWizard={false} /></Grid>
        <Grid item><Footer label="Save" onSubmit={() => formRef.current.requestSubmit()} /></Grid>
      </Grid>
    </div>
  )
}

export default AdvertisementSection
