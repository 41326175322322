import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { ACCESS_DOCUMENT_RULE_SETS_PAGE } from '../../auth/policies'

const DocumentRuleSetsPage = React.lazy(() => import('../../components/DocumentRuleSets'))

export default {
	path: RouteConstants.documentRuleSets.root.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_DOCUMENT_RULE_SETS_PAGE]}
			component={<DocumentRuleSetsPage />} 
		/>
	),
}