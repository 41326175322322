import React from "react";
import { useForm, useController } from "react-hook-form";
import { CheckboxInput, MUICheckboxInput } from "./ControlledCheckboxInput";

export const FormCheckboxInput = ({
  id,
  control,
  label,
  name,
  disabled,
  labelPosition = "right",
  gxid,
}) => {
  const { control: backupControl } = useForm();
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name: name,
    control: control ?? backupControl,
  });
  return (
    <CheckboxInput
      id={id}
      disabled={disabled}
      name={field.name}
      on={field.value}
      onChange={(v) => {
        field.onChange(v);
      }}
      label={label}
      labelPosition={labelPosition}
      gxid={gxid}
    />
  );
};

export const MUIFormCheckboxInput = ({
  id,
  control,
  label,
  name,
  rules,
  disabled,
  labelPosition = "right",
  reducedPadding = false,
  gxid,
  shouldUnregister = false,
  style = {},
  size = "medium",
  onClick = () => { },
  onChange = () => { }
}) => {
  const { control: backupControl } = useForm();
  const {
    field,
    fieldState,
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name: name,
    control: control ?? backupControl,
    shouldUnregister,
    rules,
  });
  return (
    <MUICheckboxInput
      onClick={onClick}
      id={id}
      disabled={disabled}
      style={style}
      size={size}
      name={field.name}
      on={field.value}
      onChange={(e) => {
        field.onChange(e.target.checked)
        onChange(e.target.checked)
      }}
      checked={field.value}
      label={label}
      labelPosition={labelPosition}
      reducedPadding={reducedPadding}
      gxid={gxid}
    />
  );
};