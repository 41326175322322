import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { withStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
const styles = (theme) => ({
	label: {
		color: 'rgba(0,0,0,.54)',
		fontFamily: '"Roboto Regular", Helvetica, sans-serif',
		fontSize: '16px',
		position:'absolute',
		transformOrigin:'top left',
		transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
		left: '0px',
		top: '-3px',
	},
	select: {
		backgroundColor: 'transparent',
		height: '56px',
		width: '100%',
		fontSize: '16px',
		position: 'relative',
		padding: '20px 0px 6px 14px',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
		'&:focus': {
			outline: 1
		},
		borderStyle: 'none',
		borderRadius: '0px',
		cursor: 'pointer',
	},
	errorLabel: {
		color: theme.colors.red,
		fontSize: '12px',
		margin: '40px 12px'
	},

	outerWrapper: {
		position: 'relative',
		border: 'solid 1px #e3e3e3',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	fieldset: {
		zIndex: '5',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		margin: '0',
		padding: '8px',
		position: 'absolute',
		borderWidth:'0px',
		backgroundColor:'rgba(0,0,0,.09)',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
        pointerEvents: 'none',

	},
	legend: {
        width: '0px',
        display: 'none'
	},
	arrow: {
		position: 'absolute',
		right:'5px',
		top: '20px',
		pointerEvents: 'none'
	},
	wrapper: {

		position: 'relative',

		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
		'&::after': {
			content: '""',
			position:'absolute',
			left:'0px',
			right:'0px',
			bottom:'0px',
			display:'block',
			borderBottom: '2px solid #0e5777',
			transform:'scaleX(0)',
			transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
		},

		'&::before': {
			content: '"\\00a0"',
			position:'absolute',
			left:'0px',
			right:'0px',
			bottom:'-1px',
			display:'block',
			transform:'scaleX(1)',
			borderBottom: '1px solid rgba(0,0,0,.42)',
			transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

		},
		'&:hover': {
			'&:not(.active)': {
				'&:not(.error)': {
					'&::before': {
						borderBottom:'solid 1px #000'
					}
				}
			},
			backgroundColor: 'rgba(0, 0, 0, 0.09)',
		},
		'&.hasValue': {
			'& $label': {
				transform: 'translate(12px, 8px) scale(.75)'
			}
		},
		'&:not(.hasValue)': {
			'& $label': {
				transform: 'translate(12px, 22px) scale(1)'
			}
		},
		'&.active': {
			'&::after': {
				transform:'scaleX(1)',
			},
			'&::before': {
				transform:'scaleX(0)'
			},
			'& $label': {
				color: '#0e5777'
			},
			'&:not(.hasValue) $label': {
				transform: 'translate(12px, 8px) scale(.75)'
		},
			'&.error $label': {
				color: theme.colors.red,

            },
		},
		'&.error': {
			'& $label': {
				color: theme.colors.red,
			},
			'&::after': {
				borderBottom:'solid 2px ' + theme.colors.red,
				transform:'scale(1)',
			}
		}
	}
})

const stateClasses = {
	active: 'active',
	hasValue: 'hasValue',
	error: 'error'
}

class Select extends Component {
	constructor(props) {
		super(props)
        this.selectRef = React.createRef()
	}

	render() {
		const { classes, label, items, selectValue, name } = this.props
		return (
			<div className={classes.outerWrapper}>
				<div className={classes.wrapper} ref={this.selectRef}>
					<label className={classes.label} htmlFor={label} >{label}</label>

					<select id={label} name={name} value={selectValue} {...this.props.input} onChange={e=>this.handleChange(e)}  className={classes.select} onBlur={() => this.handleBlur()} onFocus={() => this.handleFocus()}>
						{items.map( (s, i) => (
							<option value={s.value} key={s.key}>
								{s.label}
							</option>
						))}
					</select>
					<ArrowDropDownIcon className={classes.arrow}/>
				</div>
			</div>
		)
	}

	handleChange(e) {
		this.props.setSelectValue(e.target.value)
		if(this.props.onChange)
		this.props.onChange(e, e.target.value)
	}

	componentDidMount() {
		this.props.setSelectValue(this.props.value!==null ? this.props.value : '')
        let elem = this.selectRef.current
        let label = elem.querySelector('label')
		if (this.props.value) {
			let classes = elem.className.split(' ')
			if (classes.indexOf(stateClasses.hasValue) === -1)
				elem.className += ' ' + stateClasses.hasValue
        }
        else {
        }
	}

	componentDidUpdate(prevProps) {
		let elem = this.selectRef.current

		if(prevProps.value!==this.props.value) {
			this.props.setSelectValue(this.props.value!==null ? this.props.value : '')
		}

		//revisit in the future, right now its hard to resize the legend if the label changes
        if(prevProps.label!== this.props.label) {
			let label = elem.querySelector('label')
            if (this.props.value) {
			}
			else {
			}
		}
    }

	handleBlur() {
		let elem = this.selectRef.current
		let classes = elem.className.split(' ')

		if (classes.indexOf('active') !== -1) {
			elem.className = elem.className.replace(/\s+\active\b/g, '')
		}

		if (!this.props.selectValue) {
			if (classes.indexOf(stateClasses.hasValue) !== -1) {
				elem.className = elem.className.replace(/\s+\hasValue\b/g, '')
            }
		} else {
			if (classes.indexOf(stateClasses.hasValue) === -1) {
                elem.className = elem.className += ' ' + stateClasses.hasValue

            }
		}
	}

	handleFocus() {
		let elem = this.selectRef.current
        let classes = elem.className.split(' ')

		if (classes.indexOf(stateClasses.active) === -1) elem.className += ' ' + stateClasses.active
	}
}

Select.propTypes = {
	label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.any
}

const enhance = compose(
	withStyles(styles),
    withState('selectValue', 'setSelectValue', -1)
)

export default enhance(Select)
