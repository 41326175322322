import { getJson, postJson, putJson, deleteJson, postFormData } from './baseApi'
import {
	removeUtcFromDateTime,
} from '../formatting'

export const fetchUsers = search => {
	let { ...timeFrame } = search.filter.timeFrame
	let { ...userData } = search.filter.userData

	let query = ''

	// timeframe
	query += 'timeFrame.type=' + timeFrame.type
	if (timeFrame.from) {
		let startDate = removeUtcFromDateTime(timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(startDate.format())
	}
	if (timeFrame.to) {
		let endDate = removeUtcFromDateTime(timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(endDate.format())
	}

	// user
	query += '&userData.firstName=' + search.filter.userData.firstName
	query += '&userData.lastName=' + search.filter.userData.lastName

	// paging
	query += '&pageSize=' + search.paging.pageSize

	return getJson(`/api/users?${query}`)
}

export const fetchUsersByName = (firstName, lastName, caseSensitive) => {
	const query = []
	if (firstName) {
		query.push('firstName=' + firstName)
	}
	if (lastName) {
		query.push('lastName=' + lastName)
	}
	if (caseSensitive) {
		query.push('caseSensitive=True')
	}
	return getJson(`/api/users/by-name?${query.join('&')}`, { timeout: 30000 })
}

export const fetchUsersByDuplicateUserId = (duplicateUserId) => {
	return getJson(`/api/users/by-duplicate-user-id?duplicateUserId=${duplicateUserId}`)
}

export const fetchUsersByClaim = (claim) => {
	let query = 'type=' + claim.type + '&value=' + claim.value
	return getJson(`/api/users/by-claim?${query}`)
}

export const fetchUser = (userId) => {
	return getJson(`/api/users/${userId}`)
}

export const fetchUserByEmail = (email) => {
	return getJson(`/api/users/by-email?email=${encodeURIComponent(email)}`)
}

export const createUser = user => {
	return postJson('/api/users/create', user)
}

export const updateUser = user => {
	return putJson('/api/users/update', user)
}

export const lockOutUser = userId => {
	return putJson(`/api/users/${userId}/lock-out`)
}

export const unlockUser = userId => {
	return putJson(`/api/users/${userId}/unlock`)
}

export const fetchUserRoles = userId => {
	return getJson(`/api/users/${userId}/roles`)
}

export const updateUserRoles = (userId, roles) => {
	return putJson(`/api/users/${userId}/roles`, roles)
}

export const addUserRole = (userId, roleName) => {
	return postJson(`/api/users/${userId}/role/${roleName}`)
}

export const deleteUserRole = (userId, roleName) => {
	return deleteJson(`/api/users/${userId}/role/${roleName}`)
}

export const forgotPassword = userId => {
	return postJson(`/api/users/${userId}/forgot-password`, {})
}

export const synchronizeUser = userId => {
	return postJson(`/api/users/${userId}/synchronize-user`)
}

export const deleteUser = userId => {
	return deleteJson(`/api/users/${userId}`)
}

export const addUserClaim = (userId, claim) => {
	let query = 'type=' + claim.type + '&value=' + claim.value
	return postJson(`/api/users/${userId}/claim?${query}`)
}

export const deleteUserClaim = (userId, claim) => {
	let query = 'type=' + claim.type + '&value=' + claim.value
	return deleteJson(`/api/users/${userId}/claim?${query}`)
}

export const bulkImportUsers = formData => {
	return postFormData(`/api/bulkimport`, formData, { timeout: 60000 })
}