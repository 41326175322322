/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import LinkIcon from '@mui/icons-material/Link'
import Typography from '@mui/material/Typography'
import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'
import DialogSubsectionHeadline from '../../../../../Header/DialogSubsectionHeadline'
import SectionHeadline from '../../../../../Header/SectionHeadline'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    backgroundColor: '#eee',
    borderRadius: '3px',
    textAlign: 'center',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
})

export function Empty({ createVerificationLink }) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.container}>
        <LinkIcon
          style={{
            width: '80px',
            height: '80px',
            padding: '15px',
            backgroundColor: '#fff',
            border: '2px solid #222',
            borderRadius: '50%',
          }}
        />
        <SectionHeadline>OAuth Verification Links</SectionHeadline>
        <Typography>You don't have any verification links created for this listing.</Typography>
        <Button
          color="primary"
          label="Create a verification link"
          onClick={createVerificationLink}
          style={{ marginTop: '10px' }}
        >
          Create a verification link
        </Button>
      </div>
      <div className={classes.info}>
        <div className={classes.threeFourths}>
          <DialogSubsectionHeadline>Deploying verification</DialogSubsectionHeadline>
          <Typography variant="body2">
            Our OAuth verification solution allows you to verify a user's group affiliation in real-time. It can
            be used to identify and target specific user groups for special benefits and offers. The most common
            implementation utilizes our OAuth server-side flow for verification. GovX uses the OAuth 2.0
            protocol to provide access to our API. You will use our API to retrieve a user’s group affiliation
            data.
          </Typography>
          <Typography variant="body2">
            In order to initiate the verification process, you must direct the user to the GovX authorization
            endpoint via a verification link. You can create properly formatted verification links using the
            link builder tool in this section. Simply
            {' '}
            <StyledLink contents="create a verification link" clickHandlerOnly onClick={createVerificationLink} />
            {' '}
            to get
            started.
          </Typography>
        </div>
        <div className={classes.oneFourth}>
          <DialogSubsectionHeadline>Resources</DialogSubsectionHeadline>
          <Typography>
            <StyledLink
              contents="Creating and configuring your listing"
              external
              to="https://support.govxinc.com/hc/en-us/articles/360028454812-Creating-and-configuring-your-listing"
              newWindow
            />
          </Typography>
          <Typography>
            <StyledLink
              contents="Deploying verification via OAuth"
              external
              to="https://support.govxinc.com/hc/en-us/articles/360028760851-Deploying-verification-via-OAuth-Explicit-flow"
              newWindow
            />
          </Typography>
        </div>
      </div>
    </div>
  )
}

Empty.propTypes = {
  createVerificationLink: PropTypes.func.isRequired,
}

export default Empty
