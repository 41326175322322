import React from 'react'
import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'
import TabWithSubNav from '../../../../TabWithSubNav'
// import IntegrationsSection from './Integrations/IntegrationsSection'
import UserPermissions from './Permissions/UserPermissions'

export function SettingsTab() {
  const { verificationListing } = useOutletContext()

  return (
    verificationListing && (
      <div style={{ margin: '30px 0 0 0' }}>
        <TabWithSubNav
          items={[
            // { primaryText: 'Integrations', component: <IntegrationsSection affiliate={ verificationListing } /> },
            { primaryText: 'Permissions', component: <UserPermissions listing={verificationListing} /> },
          ]}
        />
      </div>
    )
  )
}

SettingsTab.propTypes = {
  verificationListing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
}

export default SettingsTab
