import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, createListingSpecialOffer } from '../../../redux/actions'
import { MUIFormTextAreaInput } from '../../@Common/Form/TextAreaInput/FormTextAreaInput'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'

export const DisplayOptions = ['OptIn', 'OptOut']

const ConvertOption = (option) => option.replace(/[A-Z]/g, (match, index) => (index > 0 ? `-${match.toLowerCase()}` : match))

export function CreateListingSpecialOfferModal({ listingId }) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      displayOption: 'OptIn',
    },
  })

  const onSubmit = (values) => {
    setProcessing(true)

    const specialOffer = {
      listingId,
      message: values.message,
      displayOption: values.displayOption || DisplayOptions[0],
    }

    return dispatch(createListingSpecialOffer(listingId, specialOffer))
      .then(() => {
        setProcessing(false)
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('createListingSpecialOffer error', err)
      })
  }

  return (
    <ModalContainer
      title="Special Offer"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        <div style={{ position: 'relative' }}>
          <MUIFormTextAreaInput
            name="message"
            control={control}
            rules={{ required: true }}
            label="Message"
            maxLength="300"
            autoFocus
          />
          <Typography>
            <small style={{ position: 'absolute', bottom: '2px', right: '20px' }}>
              { watch('message')?.length ?? 0 }
              {' '}
              of 300
            </small>
          </Typography>
        </div>

        <Box sx={{ width: '120px', mt: '30px' }}>
          <MUIFormSelectInput
            name="displayOption"
            control={control}
            rules={{ required: true }}
            label="Display as"
            items={DisplayOptions.map((o, i) => ({ key: i, value: o, label: ConvertOption(o) }))}
          />
        </Box>

      </form>
    </ModalContainer>
  )
}

CreateListingSpecialOfferModal.propTypes = {
  listingId: PropTypes.string.isRequired,
}

export default CreateListingSpecialOfferModal
