/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import ArrowDownIcon from '@mui/icons-material/ExpandMore'
import ArrowUpIcon from '@mui/icons-material/ExpandLess'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import ModalContainer from '../ModalContainer'
import OccupationIcon from '../../Occupations/OccupationIcon'
import { hideModal, fetchPolicies, updateOccupationInfoAndTypes } from '../../../redux/actions'
import {
  hasPolicy, selectAllowedVerificationTypeGroup, selectOccupationInfoModel, selectDocumentRuleSets,
} from '../../../redux/selectors'
import { formatVerificationType, formatVerificationDocumentType } from '../../../formatting'
import { allowedHtmlTagsForMessages } from '../../../setup/utilities'
import { EDIT_AFFILIATIONS } from '../../../auth/policies'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'
import { MUIFormTextAreaInput } from '../../@Common/Form/TextAreaInput/FormTextAreaInput'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

const useStyles = createUseStyles({
  verificationTypesList: {
    marginTop: '7px',
    paddingLeft: '0px',
    listStyle: 'none',

    '& > li': {
      borderStyle: 'solid',
      borderWidth: '1px 1px 0px',
      borderColor: 'rgb(126, 126, 126)',
      padding: '5px 15px',

      '&:last-of-type': {
        borderBottom: '1px solid rgb(126, 126, 126)',
        marginBottom: '-15px',
      },
    },
  },
  eligibleDocTypesList: {
    listStyle: 'none',
    width: 'auto',
    paddingLeft: '0px',

    '& > li': {
      borderStyle: 'solid',
      borderWidth: '1px 1px 0px',
      borderColor: 'rgb(126, 126, 126)',
      padding: '3px 15px',

      '&:last-of-type': {
        borderBottom: '1px solid rgb(126, 126, 126)',
      },
    },
  },
})

const compareTypes = (a, b) => {
  if (b.type.toLowerCase() === 'other') return -2
  if (a.type.toLowerCase() === 'other') return 2
  if (a.type < b.type) return -1
  if (a.type > b.type) return 1
  return 0
}

const compareNames = (a, b) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export const buildOccupationVerificationDocumentTypeObject = (typeName, occupationVerificationDocumentTypes, verificationRuleSets) => {
  const type = occupationVerificationDocumentTypes.find((x) => x.verificationDocumentType === typeName)
  const selected = typeof type !== 'undefined'
  const uploadInstructions = selected ? type.uploadInstructions : ''
  const ruleSet = verificationRuleSets.find((x) => x.verificationDocumentType === typeName)
  const ruleSetId = ruleSet ? ruleSet.ruleSetId : '0'
  return {
    type: typeName, selected, uploadInstructions, ruleSetId,
  }
}

export const occupationVerificationDocumentTypesDefault = (occupationVerificationDocumentTypes, verificationRuleSets) => ({
  types: [
    buildOccupationVerificationDocumentTypeObject('Dd214', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('PayStub', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('DepartmentId', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('MilitaryId', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Vhic', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Vid', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Vapsl', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('SdlwvetDesignation', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Nremt', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Dd256', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Dd257', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Ngbform22', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Ngbform55', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('LeaveAndEarningsStatement', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Iaff', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Sf50', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('HospitalBadgeIdCard', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('CertificationForm', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Pcsorders', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('EmtstateLicense', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('NurseStateLicense', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('SchoolId', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('StudentId', occupationVerificationDocumentTypes, verificationRuleSets),
    buildOccupationVerificationDocumentTypeObject('Other', occupationVerificationDocumentTypes, verificationRuleSets),
  ],
})

export const isDocTypeExpanded = (docTypeName, expandedEligibleDocTypes) => {
  const match = expandedEligibleDocTypes.find((x) => x === docTypeName)
  return typeof match !== 'undefined'
}

export const mapChecked = (allowedVerificationType, checkedType, checked) => ({
  ...allowedVerificationType,
  selected: allowedVerificationType.type === checkedType ? checked : allowedVerificationType.selected,
})

export const changeVerificationDocumentTypeCheckbox = (
  checked,
  docType,
  occupationVerificationDocumentTypes,
  setOccupationVerificationDocumentTypes,
  expandedEligibleDocTypes,
  setExpandedEligibleDocTypes,
) => {
  setOccupationVerificationDocumentTypes({
    ...occupationVerificationDocumentTypes,
    types: occupationVerificationDocumentTypes.types.sort(compareTypes).map((m) => mapChecked(m, docType, checked)),
  })
  if (checked && !expandedEligibleDocTypes.find((x) => x === docType)) {
    setExpandedEligibleDocTypes([...expandedEligibleDocTypes, docType])
  }
}

export const isInAllowedVerificationTypeGroup = (verificationTypeName, allowedVerificationTypeGroup) => {
  if (!allowedVerificationTypeGroup) return false
  return !!allowedVerificationTypeGroup.allowedVerificationTypes.find(
    (x) => x.verificationTypeName === verificationTypeName,
  )
}

export const allowedVerificationTypesFromGroup = (allowedVerificationTypeGroup) => ({
  types: [
    { type: 'Dmdc', selected: isInAllowedVerificationTypeGroup('Dmdc', allowedVerificationTypeGroup) },
    { type: 'DmdcFamily', selected: isInAllowedVerificationTypeGroup('DmdcFamily', allowedVerificationTypeGroup) },
    { type: 'Email', selected: isInAllowedVerificationTypeGroup('Email', allowedVerificationTypeGroup) },
    { type: 'Doc', selected: isInAllowedVerificationTypeGroup('Doc', allowedVerificationTypeGroup) },
    { type: 'Phone', selected: isInAllowedVerificationTypeGroup('Phone', allowedVerificationTypeGroup) },
    { type: 'Family', selected: isInAllowedVerificationTypeGroup('Family', allowedVerificationTypeGroup) },
    { type: 'Auto', selected: isInAllowedVerificationTypeGroup('Auto', allowedVerificationTypeGroup) },
    { type: 'Va', selected: isInAllowedVerificationTypeGroup('Va', allowedVerificationTypeGroup) },
  ],
})

export const isVerificationTypeChecked = (type, allowedVerificationTypes) => {
  const allowedVerificationType = allowedVerificationTypes.types.find((x) => x.type === type)
  if (allowedVerificationType) return allowedVerificationType.selected
  return false
}

export function EditAffiliationModal({
  occupation,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)
  const [expandedEligibleDocTypes, setExpandedEligibleDocTypes] = useState([])

  const canEdit = useSelector(hasPolicy(EDIT_AFFILIATIONS))
  const allowedVerificationTypeGroup = useSelector(selectAllowedVerificationTypeGroup(occupation.id))
  const [allowedVerificationTypes] = useState(allowedVerificationTypesFromGroup(allowedVerificationTypeGroup))
  const occupationInfoModel = useSelector(selectOccupationInfoModel(occupation.id))
  const [
    occupationVerificationDocumentTypes,
  ] = useState(
    occupationVerificationDocumentTypesDefault(occupationInfoModel.occupationVerificationDocumentTypes, occupationInfoModel.verificationRuleSets),
  )
  const documentRuleSets = useSelector(selectDocumentRuleSets)

  console.log('occupationVerificationDocumentTypes', occupationVerificationDocumentTypes.types.map((ovdt) => ({
    ...ovdt,
    occupationId: occupation.id,
    verificationDocumentType: ovdt.type,
  })).reduce((acc, curr) => ({ ...acc, [`${curr.type}`]: curr }), {}))
  const {
    handleSubmit, control, watch, reset,
  } = useForm()

  useEffect(() => {
    reset({
      ...occupation,
      isPrivate: occupation.private,
      occupationInfoModel: { ...occupationInfoModel },
      allowedVerificationTypes: allowedVerificationTypes.types
        .reduce((acc, curr) => ({ ...acc, [`${curr.type}`]: curr.selected }), {}),
      ovdt: occupationVerificationDocumentTypes.types.map((ovdt) => ({
        ...ovdt,
        occupationId: occupation.id,
        verificationDocumentType: ovdt.type,
      })).reduce((acc, curr) => ({ ...acc, [`${curr.type}`]: curr }), {}),
    })
  }, [occupationInfoModel])

  const onSubmit = (values) => {
    setProcessing(true)

    const occupationValue = { ...values.occupation }
    occupationValue.name = values.name
    occupationValue.published = values.published
    occupationValue.private = values.isPrivate
    occupationValue.iconType = values.iconType
    occupationValue.canSponsor = values.canSponsor

    const occupationInfoAndTypesRequestModel = {
      occupation: {
        ...occupation,
        ...occupationValue,
      },
      occupationInfoModel: {
        ...occupationInfoModel,
        ...values.occupationInfoModel,
        occupationVerificationDocumentTypes: Object.keys(values.ovdt).map((key) => ({
          ...values.ovdt[key],
          uploadInstructions: sanitizeHtml(values.ovdt[key].uploadInstructions, allowedHtmlTagsForMessages),
        })).filter((x) => x.selected === true),
        verificationRuleSets: Object.keys(values.ovdt).map((key) => ({
          ...values.ovdt[key],
        }))
          .filter((x) => x.ruleSetId && Number(x.ruleSetId) > 0)
          .map((o) => ({ occupationId: occupation.id, verificationDocumentType: o.type, ruleSetId: Number(o.ruleSetId) })),
        verifyEmailMessage: sanitizeHtml(values.occupationInfoModel.verifyEmailMessage, allowedHtmlTagsForMessages),
        verifyEmailHelpText: values.occupationInfoModel.verifyEmailHelpText,
        verifyDocMessage: sanitizeHtml(values.occupationInfoModel.verifyDocMessage, allowedHtmlTagsForMessages),
      },
      allowedVerificationTypes: Object.keys(values.allowedVerificationTypes).map((key) => ({
        verificationTypeName: key,
        selected: values.allowedVerificationTypes[key],
      })).filter((x) => x.selected === true).map((o) => (o.verificationTypeName)),

    }

    console.log('occupationInfoAndTypesRequestModel', occupationInfoAndTypesRequestModel)

    return dispatch(updateOccupationInfoAndTypes(occupationInfoAndTypesRequestModel))
      .then(() => {
        setProcessing(false)
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('updateOccupationInfoAndTypes error', err)
      })
  }

  const onCancel = () => {
    dispatch(hideModal())
  }
  const getPolicies = () => dispatch(fetchPolicies([EDIT_AFFILIATIONS]))

  useEffect(() => {
    getPolicies()
  }, [])

  return (
    <ModalContainer
      title={occupation.ancestorName.split('|').join(' / ')}
      modal
      maxWidth="md"
      fullWidth="false"
      processing={processing}
      actions={
    canEdit
      ? {
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
          disabled: !occupation,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }
      : {
        primaryAction: {
          label: 'Close',
          onClick: onCancel,
        },
      }
  }
    >
      <form onSubmit={canEdit ? handleSubmit : () => {}}>
        <Grid container alignItems="flex-end" style={{ marginTop: '5px', width: '768px' }}>
          <Grid item>
            <OccupationIcon iconType={watch('iconType')} size={54} />
          </Grid>

          <Grid item style={{ width: '215px', padding: '0px 15px' }}>
            <MUIFormSelectInput
              control={control}
              name="iconType"
              label="Select an icon"
              items={[
                { key: '0', value: '0', label: 'None' },
                { key: '1', value: '1', label: 'First Responder' },
                { key: '2', value: '2', label: 'Government' },
                { key: '3', value: '3', label: 'Military' },
                { key: '4', value: '4', label: 'Police' },
                { key: '6', value: '6', label: 'Medical' },
                { key: '7', value: '7', label: 'Education' },
                { key: '5', value: '5', label: 'GovX' },
              ]}
            />
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <MUIFormTextInput
              control={control}
              name="name"
              label="Display name"
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end" justify="flex-end" style={{ marginTop: '5px', width: '768px' }}>
          <Grid item>
            <MUIFormCheckboxInput
              control={control}
              name="published"
              label="Published"
            />
          </Grid>
          <Grid item>
            <MUIFormCheckboxInput
              control={control}
              name="isPrivate"
              label="Private"
            />
          </Grid>
          <Grid item>
            <MUIFormCheckboxInput
              control={control}
              name="canSponsor"
              label="Can Sponsor"
            />
          </Grid>
        </Grid>

        <MUIFormTextAreaInput
          control={control}
          name="occupationInfoModel.prequalifierMessage"
          label="Pre-qualification message"
        />

        <div style={{ margin: '20px 0px' }}>
          <div style={{
            margin: '0px 0px 5px',
            color: '#7b7e84',
            textTransform: 'uppercase',
            fontSize: '11px',
            fontWeight: '600',
            WebkitFontSmoothing: 'antialiased',
            lineHeight: '20px',
            paddingTop: '20px',
          }}
          >
            Verification types
          </div>
          <ul className={classes.verificationTypesList} style={{ marginTop: '7px', paddingLeft: '0px', listStyle: 'none' }}>
            <li>
              <MUIFormCheckboxInput
                key="Dmdc"
                control={control}
                name="allowedVerificationTypes.Dmdc"
                label={formatVerificationType('Dmdc')}
              />

            </li>
            <li>
              <MUIFormCheckboxInput
                key="DmdcFamily"
                control={control}
                name="allowedVerificationTypes.DmdcFamily"
                label={formatVerificationType('DmdcFamily')}
              />
              {watch('allowedVerificationTypes.DmdcFamily') && (
              <div style={{ padding: '0px 0px 20px 40px' }}>
                <MUIFormTextAreaInput
                  control={control}
                  name="occupationInfoModel.familyDocumentUploadInstructions"
                  label="Document instructions"
                />
                <div style={{ marginTop: '15px' }}>
                  <MUIFormSelectInput
                    control={control}
                    name="occupationInfoModel.familyVerificationRuleSetId"
                    label="Rule-set"
                    items={
                    [
                      { key: '0', value: '0', label: 'None' },
                      ...(documentRuleSets.sort(compareNames).map((r) => (
                        { key: r.id, value: r.id, label: r.name }
                      ))),
                    ]
                  }
                  />
                </div>
              </div>
              )}
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Phone"
                control={control}
                name="allowedVerificationTypes.Phone"
                label={formatVerificationType('Phone')}
              />
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Doc"
                control={control}
                name="allowedVerificationTypes.Doc"
                label={formatVerificationType('Doc')}
              />
              {watch('allowedVerificationTypes.Doc') && (
              <div style={{ padding: '0px 0px 20px 40px' }}>
                <MUIFormTextAreaInput
                  control={control}
                  name="occupationInfoModel.verifyDocMessage"
                  label="Document verification message"
                />
                <div style={{
                  margin: '0px 0px 5px',
                  color: '#7b7e84',
                  textTransform: 'uppercase',
                  fontSize: '11px',
                  fontWeight: '600',
                  WebkitFontSmoothing: 'antialiased',
                  lineHeight: '20px',
                  paddingTop: '20px',
                }}
                >
                  Eligible document types
                </div>
                <ul className={classes.eligibleDocTypesList}>
                  {occupationVerificationDocumentTypes.types.sort(compareTypes).map((ovdt) => (
                    <li key={ovdt.type}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <MUIFormCheckboxInput
                            control={control}
                            name={`ovdt.${ovdt.type}.selected`}
                            label={formatVerificationDocumentType(ovdt.type)}
                          />
                          <input type="hidden" name={`ovdt.${ovdt.type}.type`} value={ovdt.type} />
                          <input type="hidden" name={`ovdt.${ovdt.type}.ruleSetId`} value={ovdt.ruleSetId} />
                          <input type="hidden" name={`ovdt.${ovdt.type}.occupationId`} value={ovdt.occupationId} />
                        </div>
                        <div style={{ margin: '0 0 0 auto' }}>
                          {isDocTypeExpanded(ovdt.type, expandedEligibleDocTypes) && (
                          <ArrowUpIcon
                            style={{
                              fill: '#9b9b9b', cursor: 'pointer', height: '30px', width: '30px',
                            }}
                            onClick={() => setExpandedEligibleDocTypes(expandedEligibleDocTypes.filter((x) => x !== ovdt.type))}
                          />
                          )}
                          {!isDocTypeExpanded(ovdt.type, expandedEligibleDocTypes) && (
                          <ArrowDownIcon
                            style={{
                              fill: '#9b9b9b', cursor: 'pointer', height: '30px', width: '30px',
                            }}
                            onClick={() => setExpandedEligibleDocTypes([...expandedEligibleDocTypes, ovdt.type])}
                          />
                          )}
                        </div>
                      </div>
                      {isDocTypeExpanded(ovdt.type, expandedEligibleDocTypes)
                    && (
                    <div style={{ padding: '0px 0px 20px 40px' }}>
                      <MUIFormTextAreaInput
                        control={control}
                        name={`ovdt.${ovdt.type}.uploadInstructions`}
                        label="Document Instructions"
                      />
                      <div style={{ marginTop: '15px' }}>
                        <MUIFormSelectInput
                          control={control}
                          name={`ovdt.${ovdt.type}.ruleSetId`}
                          label="Rule-set"
                          items={
                            [
                              { key: '0', value: '0', label: 'None' },
                              ...(documentRuleSets.sort(compareNames).map((r) => (
                                { key: r.id, value: r.id, label: r.name }
                              ))),
                            ]
                          }
                        />
                      </div>
                    </div>
                    )}
                    </li>
                  ))}
                </ul>
              </div>
              )}
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Email"
                control={control}
                name="allowedVerificationTypes.Email"
                label={formatVerificationType('Email')}
              />
              {isVerificationTypeChecked('Email', allowedVerificationTypes) && (
              <Collapse
                in
                timeout="auto"
                unmountOnExit
                style={{ marginLeft: '40px', paddingBottom: '25px' }}
              >
                <MUIFormTextAreaInput
                  control={control}
                  name="occupationInfoModel.verifyEmailMessage"
                  label="Email verification message"
                />
                <MUIFormTextInput
                  control={control}
                  name="occupationInfoModel.verifyEmailHelpText"
                  label="Input help text"
                />
              </Collapse>
              )}
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Family"
                control={control}
                name="allowedVerificationTypes.Family"
                label={formatVerificationType('Family')}
              />
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Auto"
                control={control}
                name="allowedVerificationTypes.Auto"
                label={formatVerificationType('Auto')}
              />
            </li>
            <li>
              <MUIFormCheckboxInput
                key="Va"
                control={control}
                name="allowedVerificationTypes.Va"
                label={formatVerificationType('Va')}
              />
            </li>
          </ul>
        </div>
      </form>
    </ModalContainer>
  )
}

export default EditAffiliationModal
