/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'
import Empty from './Empty'
import {
  showModal,
  fetchCampaignsByListingId,
  fetchCampaignTemplateSettings,
  fetchCampaignMessagingSettings,
  fetchPublishedOccupations,
  fetchPublicAndPublishedOccupations,
} from '../../../../../../redux/actions'
import { selectCampaignsByListingId } from '../../../../../../redux/selectors'
import DialogSubsectionHeadline from '../../../../../Header/DialogSubsectionHeadline'
import SectionHeadline from '../../../../../Header/SectionHeadline'

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
})

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export function CampaignsSection({ listing }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const campaigns = useSelector(selectCampaignsByListingId(listing.id))

  const onCreateCampaign = () => dispatch(showModal('CAMPAIGN', { listing }))
  const onEditCampaign = (campaign) => {
    const p1 = dispatch(fetchCampaignTemplateSettings(campaign.id))
    const p2 = dispatch(fetchCampaignMessagingSettings(campaign.id))

    return Promise.all([p1, p2])
      .then((responses) => {
        const settings = Object.assign(responses[0], responses[1])
        dispatch(
          showModal('CAMPAIGN', { listing, campaign, campaignSettings: settings }),
        )
      })
      .catch((err) => {
        console.log('fetchCampaignSettings error', err)
        throw err
      })
  }
  const onDeleteCampaign = (campaign) => dispatch(showModal('CONFIRM_CAMPAIGN_DELETE', { campaign }))

  useEffect(() => {
    dispatch(fetchCampaignsByListingId(listing.id))
    dispatch(fetchPublishedOccupations())
    dispatch(fetchPublicAndPublishedOccupations())
  }, [])

  return campaigns ? (
    campaigns.length > 0 ? (
      <div className={classes.wrapper}>
        <div className={classes.linksSection}>
          <div className={classes.header}>
            <SectionHeadline>Campaigns</SectionHeadline>
            <Button
              label="Create Campaign"
              fullWidth={false}
              onClick={onCreateCampaign}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Create Campaign
            </Button>
          </div>
          <Table>
            <TableHead style={{ backgroundColor: '#f1f1f1' }}>
              <TableRow>
                <TableCell style={{ fontSize: '14px', color: '#222', fontWeight: '600' }}>
                  Campaign
                </TableCell>
                <TableCell
                  style={{
                    width: '180px', fontSize: '14px', color: '#222', fontWeight: '600',
                  }}
                >
                  Query string parameter
                </TableCell>
                <TableCell
                  style={{
                    width: '100px',
                    fontSize: '14px',
                    color: '#222',
                    fontWeight: '600',
                    cursor: 'default',
                  }}
                >
                &nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((c) => (
                <TableRow key={c.id}>
                  <TableCell style={{ position: 'relative' }}>{c.name}</TableCell>
                  <TableCell style={{ width: '180px', position: 'relative' }}>
                    &campaign_id=
                    {c.id}
                  </TableCell>
                  <TableCell
                    style={{ width: '128px', position: 'relative', textAlign: 'right' }}
                  >
                    <StyledLink
                      key="edit"
                      clickHandlerOnly
                      onClick={() => onEditCampaign(c)}
                      contents="Edit"
                      styles={{ marginRight: '27px' }}
                    />
                    <StyledLink
                      key="delete"
                      clickHandlerOnly
                      onClick={() => onDeleteCampaign(c)}
                      contents="Delete"
                      styles={{ color: '#f44336' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className={classes.info}>
          <div className={classes.threeFourths}>
            <DialogSubsectionHeadline>Using campaigns</DialogSubsectionHeadline>
            <Typography variant="body2">
              Campaigns are used to create multiple unique experiences under your parent listing. You may want
              to use campaigns if you're managing multiple online stores or websites under different brands or
              even just to run seasonal promotions. By using campaigns, you can customize the branding and
              messaging in your verification funnel.
            </Typography>
            <Typography variant="body2">
              You can easily create campaigns using the wizard in this section. Each campaign you create will
              be identified by a campaign_id parameter. To trigger the campaign from your website, simply
              append the campaign_id parameter to your verification URL. The verification URL is the
              authorization endpoint you're deploying on your website or application that triggers the
              verification flow. Our system will read the parameter and automatically apply the custom
              settings. If for some reason the campaign parameter is unrecognized, we will fall back to the
              listing settings.
            </Typography>
          </div>
          <div className={classes.oneFourth}>
            <DialogSubsectionHeadline>Resources</DialogSubsectionHeadline>
            <Typography>
              <StyledLink
                contents="Managing campaigns"
                external
                to="https://support.govxinc.com/hc/en-us/articles/360028454952-Managing-campaigns"
                newWindow
              />
            </Typography>
          </div>
        </div>
      </div>
    ) : (
      <Empty onCreateCampaign={onCreateCampaign} />
    )
  ) : (
    <Processing />
  )
}

export default CampaignsSection
