import { showSuccessNotification, showErrorNotification } from '../../../redux/actions'

export const copyAction = ({
    dispatch,
    text,
    label
}) => {
    if (!navigator.clipboard) {
        // eslint-disable-next-line no-console
        console.warn('Unable to access clipboard. Clipboard may be restricted due to running over http.')
        dispatch(showErrorNotification(`Failed to copy ${label} to clipboard`))
        return
    }

    try {
        navigator.clipboard.writeText(text)
        dispatch(showSuccessNotification(`${label} copied to clipboard`))
    } catch (err) {
        console.error('Failed to copy: ', err)
        dispatch(showErrorNotification(`Failed to copy ${label} to clipboard`))
    }
}
