import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import LazyProtectedRoute from '../LazyProtectedRoute'
import { ACCESS_DMDC_STATUS_PAGE } from '../../auth/policies'

const DmdcStatsReportPage = React.lazy(() => import('../../components/Reports/Verification/DmdcStatsReport'))

export default {
	path: RouteConstants.reports.dmdcStats.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_DMDC_STATUS_PAGE]}
			component={<DmdcStatsReportPage />} 
		/>
	),
}