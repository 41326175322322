import { normalize } from 'normalizr'
import { createSelector } from 'reselect'
import { authorizationRecord } from '../../../api/schema'
import * as verificationRequestsApi from '../../../api/verificationRequestsApi'

export const FETCH_AUTHORIZATION_RECORDS_REQUEST = 'FETCH_AUTHORIZATION_RECORDS_REQUEST'
export const FETCH_AUTHORIZATION_RECORDS_SUCCESS = 'FETCH_AUTHORIZATION_RECORDS_SUCCESS'
export const FETCH_AUTHORIZATION_RECORDS_FAILURE = 'FETCH_AUTHORIZATION_RECORDS_FAILURE'

export const fetchAuthorizationRecords = verificationRequestId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_AUTHORIZATION_RECORDS_REQUEST })
	return verificationRequestsApi.fetchAuthorizationRecords(verificationRequestId)
		.then(response => {
			const data = normalize(response, [schema.authorizationRecord])
			dispatch({ type: FETCH_AUTHORIZATION_RECORDS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_AUTHORIZATION_RECORDS_FAILURE, err })
			throw err
		})
}

export const DEFAULT_STATE = {
	db: {}
}

export const mergeDb = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.authorizationRecords }}
}

export const STATE_KEY = 'authorizationRecords'

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_AUTHORIZATION_RECORDS_SUCCESS:
			return mergeDb(state, action)
		default:
			return {...state}
	}
}

export default reducer

export const selectAuthorizationRecordsHashMap = state => state.verification.authorizationRecords.db

export const selectAuthorizationRecords = createSelector(
	selectAuthorizationRecordsHashMap,
	auth => Object.keys(auth).map(k => auth[k])
)

export const selectAuthorizationRecordsForVerificationRequest = (verificationRequestId) => createSelector(
	selectAuthorizationRecords, 
	(_, ownProps) => verificationRequestId,
	(records, id) => {
		return records.filter(r => r.verificationRequestId === id)
	}
)