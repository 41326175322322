import React, { Component } from 'react'
import { compose, withState } from 'recompose'
import { withStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const styles = (theme) => ({
	label: {
		color: 'rgba(0,0,0,.54)',
		fontFamily: '"Roboto Regular", Helvetica, sans-serif',
		fontSize: '16px',
		position:'absolute',
		transformOrigin:'top left',
		transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
		left: '0px',
		top: '-3px',
	},
	select: {
		height:'56px',
		width: '100%',
		fontSize: '16px',
		position: 'relative',
		backgroundColor: 'transparent',
		padding: '20px 0px 6px 14px',
		'&:focus': {
			outline: 1
		},
		borderStyle: 'none',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',

	},
	errorLabel: {
		color: theme.colors.red,
		fontSize: '12px',
		margin: '5px 12px'
	},

	outerWrapper: {
		position: 'relative',
		border: 'solid 1px #e3e3e3',
		borderTopLeftRadius:'4px',
		borderTopRightRadius:'4px',

	},
	arrow: {
		position: 'absolute',
		right:'5px',
		top: '15px',
		pointerEvents: 'none'
	},
	wrapper: {
		position: 'relative',
		height: '56px',
		'&::after': {
			content: '""',
			position:'absolute',
			left:'0px',
			right:'0px',
			bottom:'0px',
			display:'block',
			borderBottom: '2px solid ' + theme.colors.lightBlue,
			transform:'scaleX(0)',
			transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
		},

		'&::before': {
			content: '"\\00a0"',
			position:'absolute',
			left:'0px',
			right:'0px',
			bottom:'-1px',
			display:'block',
			transform:'scaleX(1)',
			borderBottom: '1px solid rgba(0,0,0,.42)',
			transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

		},
		'&:hover': {
			'&:not(.active)': {
				'&:not(.error)': {
					'&::before': {
						borderBottom:'solid 1px #000'
					}
				}
			}
		},
		'&.hasValue': {
			'& $label': {
				transform: 'translate(12px, 8px) scale(.75)'
			}
		},
		'&:not(.hasValue)': {
			'& $label': {
				transform: 'translate(12px, 22px) scale(1)'
			}
		},
		'&.active': {
			'&::after': {
				transform:'scaleX(1)',
			},
			'&::before': {
				transform:'scaleX(0)'
			},
			'& $label': {
				color: theme.colors.lightBlue
			},
			'&:not(.hasValue) $label': {
				transform: 'translate(12px, 8px) scale(.75)'
		},
			'&.error $label': {
				color: theme.colors.red,

            },
		},
		'&.error': {
			'& $label': {
				color: theme.colors.red,
			},
			'&::after': {
				borderBottom:'solid 2px ' + theme.colors.red,
				transform:'scale(1)',
			}
		}
	}
})

const stateClasses = {
	active: 'active',
	hasValue: 'hasValue',
	error: 'error'
}

class ReduxFormSelect extends Component {
	constructor(props) {
		super(props)
        this.selectRef = React.createRef()
		this.visited = false
	}

	render() {
		const { classes, label, items, name, meta, visited, notchWidth } = this.props
		return (
			<>
			<div className={classes.outerWrapper} onClick={()=>this.handleFocus()}>
				<div className={classes.wrapper} ref={this.selectRef}>
					<label className={classes.label}>{label}</label>

					<select {...this.props.input} className={classes.select} onBlur={() => this.handleBlur()} onFocus={() => this.handleFocus()} disabled={this.props.disabled}>
						{items && items.map(s => (
							<option value={s.value} key={s.key} hidden={s.value === null}>
								{s.label}
							</option>
						))}
					</select>
					{!this.props.disabled && <ArrowDropDownIcon className={classes.arrow}/>}
				</div>
			</div>
			{meta && !meta.valid && visited && <label className={classes.errorLabel}>{meta.error}</label>}
			</>
		)
	}

	componentDidMount() {
		let elem = this.selectRef.current
		let hasValue = this.props.input.value || (this.props.items && this.props.items[0] && this.props.items[0].value)
		if (hasValue) {
			let classes = elem.className.split(' ')
			if (classes.indexOf(stateClasses.hasValue) === -1) {
				elem.className += ' ' + stateClasses.hasValue
            }

        }
	}

	componentDidUpdate(prevProps) {
		let elem = this.selectRef.current
		let classes = elem.className.split(' ')
		let meta = this.props.meta
		if (!meta.valid && this.props.visited) {
			if (classes.indexOf(stateClasses.error) === -1) elem.className += ' ' + stateClasses.error
		}
		if (meta.valid) {
			if (classes.indexOf(stateClasses.error) !== -1) elem.className = elem.className.replace(/\s+\error\b/g, '')
		}
	}


	handleBlur() {
		let elem = this.selectRef.current
		let classes = elem.className.split(' ')
       let meta = this.props.meta
		this.props.setVisited(true)
		if (classes.indexOf('active') !== -1) {
			elem.className = elem.className.replace(/\s+\active\b/g, '')
		}

		if (!meta.valid || !this.props.input.value) {
			if (classes.indexOf(stateClasses.error) === -1) {
				elem.className += ' ' + stateClasses.error
			}

			if (classes.indexOf(stateClasses.hasValue) !== -1) {
				elem.className = elem.className.replace(/\s+\hasValue\b/g, '')
            }
		} else {
			if (classes.indexOf(stateClasses.hasValue) === -1) {
                elem.className = elem.className += ' ' + stateClasses.hasValue

            }
		}
	}

	handleFocus() {
		let elem = this.selectRef.current
        let classes = elem.className.split(' ')

		if (classes.indexOf(stateClasses.active) === -1 && !this.props.disabled) elem.className += ' ' + stateClasses.active
	}
}

ReduxFormSelect.propTypes = {
}

const enhance = compose(
	withStyles(styles),
    withState('visited', 'setVisited', false)
)

export default enhance(ReduxFormSelect)
