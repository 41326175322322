import React from 'react'

export const StatusIndicator = function ({
    publishedStatus,
}) {
    return (
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle
                fill={publishedStatus ? '#2ecc71' : '#f44336'}
                cx="5"
                cy="5"
                r="5"
            />
        </svg>
    )
}
