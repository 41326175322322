import { LOCATION_CHANGE } from '../../setup/history'

export const SHOW_SIDE_SHEET = 'SHOW_SIDE_SHEET'
export const HIDE_SIDE_SHEET = 'HIDE_SIDE_SHEET'

export const showSideSheet = (sideSheetType, sideSheetProps) => ({
	type: SHOW_SIDE_SHEET,
	sideSheetType,
	sideSheetProps,
})

export const hideSideSheet = () => ({ type: HIDE_SIDE_SHEET })

export const STATE_KEY = 'sideSheet'

export const DEFAULT_STATE = {
	sideSheetType: null,
	sideSheetProps: {},
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case HIDE_SIDE_SHEET:
			return DEFAULT_STATE
		case SHOW_SIDE_SHEET:
			return {
				sideSheetType: action.sideSheetType,
				sideSheetProps: action.sideSheetProps,
			}
		case LOCATION_CHANGE:
			return DEFAULT_STATE
		default:
			return state
	}
}

export default reducer
