import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import StyledLink from '../../StyledLink'
import {
  hideModal, searchUsersByClaim, fetchUserByEmail, addUserClaim, addUserRole, showSuccesNotification,
} from '../../../redux/actions'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'

export function AddListingUserModal({ claim }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)
  const [user, setUser] = useState(null)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      assignPartnerRole: true,
    },
  })

  const onCancel = () => {
    dispatch(hideModal())
  }

  const onSubmit = (values) => {
    setProcessing(true)

    // IF no user is selected, fetch user by email
    if (!user) {
      return dispatch(fetchUserByEmail(values.email))
        .then((response) => {
          setProcessing(false)
          setUser(response)
        })
        .catch((err) => {
          setProcessing(false)
          console.log('fetchUserByEmail error', err)
        })
    }

    // ELSE IF user is already selected, add user listing claim
    return dispatch(addUserClaim(user.id, claim))
      .then(() => {
        setProcessing(false)

        if (values.assignPartnerRole) { dispatch(addUserRole(user.id, 'Partner')) }

        dispatch(searchUsersByClaim(claim, 'users-listing'))
        dispatch(showSuccesNotification('User added successfully'))
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('addUserClaim error', err)
      })
  }

  return (
    <ModalContainer
      title="Add User"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: user ? 'Save' : 'Search',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        {
      user
        ? (
          <div>
            <div style={{ marginTop: '25px', color: '#222' }}>
              User:
              {' '}
              {user.firstName}
              {' '}
              {user.lastName}
              {' '}
              (
              {user.email}
              )
              {' '}
              <StyledLink contents="Remove" clickHandlerOnly onClick={() => setUser(null)} styles={{ marginLeft: '15px' }} />
            </div>
            <Box mt="15px">
              <MUIFormCheckboxInput
                name="assignPartnerRole"
                label="Assign Partner role"
                control={control}
              />
            </Box>
          </div>
        )
        : (
          <div>
            <MUIFormTextInput
              name="email"
              label="Search by email address"
              control={control}
              autoFocus
              rules={{ required: !!user }}
            />
          </div>
        )
    }
      </form>
    </ModalContainer>
  )
}

AddListingUserModal.propTypes = {
  claim: PropTypes.string.isRequired,
}

export default AddListingUserModal
