import { useForm } from 'react-hook-form'
import ModalContainer from '../../../../../Modals/ModalContainer'

function SyndicateOfferRemoveModal({
    onClickCancel,
    onClickSubmit,
    syndicateOffer
}) {

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
    })

    const onSubmit = () => {
        onClickSubmit(syndicateOffer)
    }

    return (
        <ModalContainer
            title={`Syndicated Offer Removal`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}
            actions={{
                primaryAction: { label: "Yes", onClick: handleSubmit(onSubmit) },
                secondaryAction: { label: "Cancel", onClick: () => onClickCancel() },
            }}
        >
            <div>
                <p>
                    Are you sure you would like to remove the syndicated offer?
                </p>
            </div>
        </ModalContainer>
    )
}

export default SyndicateOfferRemoveModal
