import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, deleteCampaign } from '../../../redux/actions'

export function ConfirmCampaignDeleteModal({ campaign }) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const takeAction = () => dispatch(deleteCampaign(campaign))
    .then(() => dispatch(hideModal()))
    .catch((err) => {
      console.log('deleteCampaign error', err)
      dispatch(hideModal())
      throw err
    })

  return (
    <ModalContainer
      modal
      maxWidth="sm"
      fullWidth="true"
      title="Delete campaign"
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: () => takeAction(campaign),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ color: '#222', marginTop: '20px' }}>Are you sure you want to delete this campaign? Any links using this campaign parameter will default to the listing settings instead.</p>
      <p style={{ color: '#222' }}>This action cannot be undone.</p>
    </ModalContainer>
  )
}

ConfirmCampaignDeleteModal.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default ConfirmCampaignDeleteModal
