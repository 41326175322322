/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { useForm } from 'react-hook-form'
import MessagingFormPartial from './MessagingFormPartial'
import { fetchListingMessagingSettings, updateListingMessagingSettings, updateListingWizardSettings } from '../../../../redux/actions'
import { selectListingSettings } from '../../../../redux/selectors'

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        minHeight: '400px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function MessagingForm({
  listing,
  isWizard = false,
  currentStep,
  setCurrentStep,
  wizardStepBit,
  innerRef,
}) {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, reset, ...form } = useForm({
    defaultValues: useSelector(selectListingSettings(listing.id, 'messaging')),
  })

  const onSubmit = (values) => {
    const settings = {}
    settings.prequalifierMessage = values.prequalifierMessage
    settings.privacyPolicyLink = values.privacyPolicyLink
    settings.showSocialProof = values.showSocialProof
    settings.termsOfServiceLink = values.termsOfServiceLink
    settings.welcomeMessage = values.welcomeMessage
    settings.welcomeHeadline = values.welcomeHeadline
    settings.welcomeFooter = values.welcomeFooter
    settings.registerEmailHint = values.registerEmailHint

    dispatch(updateListingMessagingSettings(listing.id, settings))

    if (isWizard) {
      dispatch(updateListingWizardSettings(listing.id, { stepsCompleted: wizardStepBit }))
      setCurrentStep(currentStep + 1)
    }
  }

  useEffect(() => {
    const fn = async () => {
      const res = await dispatch(fetchListingMessagingSettings(listing.id))
      setProcessing(false)
      reset(res)
    }
    fn()
  }, [])

  return (
    <div style={{ position: 'relative', width: 'calc(100% - 2px)', minHeight: '400px' }}>
      {
    processing
      ? <Processing />
      : (
        <form ref={innerRef} onSubmit={handleSubmit(onSubmit)}>
          <MessagingFormPartial form={form} />
        </form>
      )
  }
    </div>
  )
}

export default MessagingForm
