import { billingTerms } from '../../../api/schema'
import * as billingApi from '../../../api/billingApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'


export const FETCH_BILLING_TERMS_BY_LISTINGID_REQUEST = 'FETCH_BILLING_TERMS_BY_LISTINGID_REQUEST'
export const FETCH_BILLING_TERMS_BY_LISTINGID_SUCCESS = 'FETCH_BILLING_TERMS_BY_LISTINGID_SUCCESS'
export const FETCH_BILLING_TERMS_BY_LISTINGID_FAILURE = 'FETCH_BILLING_TERMS_BY_LISTINGID_FAILURE'

export const fetchBillingTermsByListingId = listingId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_BILLING_TERMS_BY_LISTINGID_REQUEST })
	return billingApi
		.fetchBillingTermsByListingId(listingId)
		.then(response => {
			const data = normalize(response, schema.billingTerms)
			dispatch({ type: FETCH_BILLING_TERMS_BY_LISTINGID_SUCCESS, data: response, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_BILLING_TERMS_BY_LISTINGID_FAILURE, err })
			throw err
		})
}

export const UPDATE_BILLING_TERMS_REQUEST = 'UPDATE_BILLING_TERMS_REQUEST'
export const UPDATE_BILLING_TERMS_SUCCESS = 'UPDATE_BILLING_TERMS_SUCCESS'
export const UPDATE_BILLING_TERMS_FAILURE = 'UPDATE_BILLING_TERMS_FAILURE'

export const updateBillingTerms = (subscriptionId, command) => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_BILLING_TERMS_REQUEST })
	return billingApi.updateBillingTerms(subscriptionId, command)
		.then(response => {
			const data = normalize(response, schema.billingTerms)
			dispatch({ type: UPDATE_BILLING_TERMS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_BILLING_TERMS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'billingTerms'

export const DEFAULT_STATE = {
	db: {},
}

export const updateBillingTermsState = (state, action) => {
	var updated = { ...state.db[action.data.result], ...action.data.entities.billingTerms[action.data.result] }
	return { ...state, db: { ...state.db, [action.data.result]: updated }}
}

export const mergeBillingTerms = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.billingTerms } }
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_BILLING_TERMS_BY_LISTINGID_SUCCESS:
		case UPDATE_BILLING_TERMS_SUCCESS:
			return updateBillingTermsState(state, action)
		default:
			return { ...state }
	}
}

export default reducer

export const selectBillingTermsHashMap = state => state.billing.billingTerms.db

export const selectBillingTerms = id => createSelector(selectBillingTermsHashMap, billingTerms => billingTerms[id])

export const selectAllBillingTerms = createSelector(
	selectBillingTermsHashMap,
	billingTerms => Object.keys(billingTerms).map(k => billingTerms[k])
)

export const selectAllBillingTermsByListingId = listingId => createSelector(selectAllBillingTerms, billingTerms => billingTerms.filter(r => r.listingId === listingId))