import React, { useState } from 'react'
import { Box, Button as MuiButton, ButtonGroup, Chip, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import PropTypes from 'prop-types'
import { ArrowDropDown } from '@mui/icons-material'
import NoOffersAvailable from './NoOffersAvailable'
import OfferListItem from './OfferCard/OfferListItem'
import OffersLoadingSkeleton from './OffersLoadingSkeleton'
import { Button } from '../../../../../Button'

function OffersListing({
  // affiliate,
  processing,
  canAddOffers,
  canViewOffersList,
  canDeleteOffer,
  canSyncOffer,
  canSetStatus,
  canSyndicateOffer,
  canDeleteSyndicateOffer,
  partnerListing,
  canViewSyndicatedListings,
  offers,
  offersIsLoading,
  offersIsSuccess,
  offersHasError,
  syndicateOffers,
  syndicateOffersIsLoading,
  syndicateOffersIsSuccess,
  syndicateOffersHasError,
  onClickCreateOffer,
  onClickEditOffer,
  onClickSyncOffer,
  onClickSyndicateOffer,
  onClickCommissionRateHistoryModal,
  onClickSyndicatedListingsModal,
  onClickSyndicateOfferCreateModal,
  openGovXIdLinkModal,
  openPublishModal,
  onChangeStatus,
  openDeleteModal,
  onDelete,
  prefetchOfferDetails,
  channels,
  onClickRemoveSyndicateOffer
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const options = ['Create Offer', 'Add a Syndicate Offer'];

  const handlePublish = (offer) => {
    if (offer.codePool) {
      if (offer.codePool.status !== 2) {
        openPublishModal(offer, true, onChangeStatus)
      } else {
        onClickEditOffer(offer.offerId, true)
      }
    } else {
      openPublishModal(offer, true, onChangeStatus)
    }
  }

  const handleClick = () => {
    onClickCreateOffer(partnerListing)

    console.info(`You clicked ${options[selectedIndex]}`);
  }

  const handleMenuItemClick = (event, index) => {
    if (options[index] === 'Create Offer') {
      onClickCreateOffer(partnerListing)

    } else {
      onClickSyndicateOfferCreateModal()
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (offer) => {
    if (anchorRef.current && anchorRef.current.contains(offer.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <div>
      {canViewOffersList && (
        <div style={{ position: 'relative' }}>
          {canSyndicateOffer && (
            <Box display="flex" justifyContent="right" sx={{ mb: '32px' }}>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <MuiButton onClick={handleClick} variant='contained' color='primary'>{options[selectedIndex]}</MuiButton>
                <MuiButton
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  variant='contained' color='primary'
                >
                  <ArrowDropDown />
                </MuiButton>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option + index}
                              disabled={index === 2}
                              selected={0}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          )}
          {!canSyndicateOffer && (
            <Box display="flex" justifyContent="right" sx={{ mb: '32px' }}>
              <Button size="small" label="Create Offer" onClick={handleClick} />
            </Box>
          )}
          {offersIsLoading && syndicateOffersIsLoading && (<OffersLoadingSkeleton />)}
          {offersIsSuccess && offers.length < 1 && syndicateOffersIsSuccess && syndicateOffers.length < 1 && (
            <NoOffersAvailable
              canAddOffers={canAddOffers}
              onClickAddOffer={() => onClickCreateOffer(partnerListing)}
            />
          )}
          {offersIsSuccess && offers.length > 0 && offers.map((offer) => (
            <OfferListItem
              key={offer.offerId}
              offer={offer}
              onClickCommissionRateHistory={() => onClickCommissionRateHistoryModal(offer)}
              onClickViewSyndicatedListings={() => onClickSyndicatedListingsModal(offer)}
              onClickEdit={() => { onClickEditOffer(offer.offerId, false) }}
              onClickGovXIdLink={() => openGovXIdLinkModal(offer)}
              canDeleteOffer={canDeleteOffer}
              canDeleteSyndicateOffer={canDeleteSyndicateOffer}
              canSyncOffer={canSyncOffer}
              canSyndicateOffer={canSyndicateOffer}
              canViewSyndicatedListings={canViewSyndicatedListings}
              onClickPublish={() => handlePublish(offer, true, onChangeStatus)}
              onClickUnpublish={() => openPublishModal(offer, false, onChangeStatus)}
              onClickDelete={() => openDeleteModal(offer, onDelete)}
              onClickSyncOffer={() => onClickSyncOffer(offer.source.id)}
              onClickSyndicateOffer={() => onClickSyndicateOffer(offer.offerId)}
              canSetStatus={canSetStatus}
              onMenuHover={() => prefetchOfferDetails(offer)}
              channels={channels}
              isSyndicateOffer={false}
              onClickRemoveSyndicate={() => { }}
            />
          ))}
          {syndicateOffersIsSuccess && syndicateOffers.length > 0 && syndicateOffers.map((syndicateOffer) => (
            <OfferListItem
              key={syndicateOffer.offerId}
              offer={syndicateOffer}
              onClickCommissionRateHistory={() => onClickCommissionRateHistoryModal(syndicateOffer)}
              onClickViewSyndicatedListings={() => onClickSyndicatedListingsModal(offer)}
              onClickEdit={() => { }}
              onClickGovXIdLink={() => { }}
              canDeleteOffer={canDeleteOffer}
              canSyncOffer={canSyncOffer}
              canSyndicateOffer={canSyndicateOffer}
              canDeleteSyndicateOffer={canDeleteSyndicateOffer}
              canViewSyndicatedListings={canViewSyndicatedListings}
              onClickPublish={() => { }}
              onClickUnpublish={() => { }}
              onClickDelete={() => { }}
              onClickSyncOffer={() => { }}
              onClickSyndicateOffer={() => { }}
              canSetStatus={canSetStatus}
              onMenuHover={() => prefetchOfferDetails(syndicateOffer)}
              channels={channels}
              isSyndicateOffer={true}
              onClickRemoveSyndicate={() => onClickRemoveSyndicateOffer(syndicateOffer)}
            />
          ))}
        </div>
      )}
      {!processing && !canViewOffersList && (
        <p>You are not authorized to view this data.</p>
      )}
      {(offersHasError || syndicateOffersHasError) && (
        <div style={{ color: '#bd2326' }}>There was an error that prevented this data from loading. Please try again.</div>
      )}
    </div>
  )
}

OffersListing.propTypes = {
  affiliate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }),
  processing: PropTypes.bool.isRequired,
  canAddOffers: PropTypes.bool.isRequired,
  canViewOffersList: PropTypes.bool.isRequired,
  canDeleteOffer: PropTypes.bool.isRequired,
  canSyncOffer: PropTypes.bool.isRequired,
  canSetStatus: PropTypes.bool.isRequired,
  canSyndicateOffer: PropTypes.bool.isRequired,
  canDeleteSyndicateOffer: PropTypes.bool.isRequired,
  partnerListing: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  offers: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.string.isRequired,
  })).isRequired,
  offersIsLoading: PropTypes.bool.isRequired,
  offersIsSuccess: PropTypes.bool.isRequired,
  offersHasError: PropTypes.bool.isRequired,
  syndicateOffers: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.string.isRequired,
  })).isRequired,
  syndicateOffersIsLoading: PropTypes.bool.isRequired,
  syndicateOffersIsSuccessIsSuccess: PropTypes.bool.isRequired,
  syndicateOffersHasError: PropTypes.bool.isRequired,
  onClickCreateOffer: PropTypes.func.isRequired,
  onClickEditOffer: PropTypes.func.isRequired,
  onClickSyncOffer: PropTypes.func.isRequired,
  onClickSyndicateOffer: PropTypes.func.isRequired,
  onClickCommissionRateHistoryModal: PropTypes.func.isRequired,
  onClickSyndicatedListingsModal: PropTypes.func.isRequired,
  onClickRemoveSyndicateOffer: PropTypes.func.isRequired,
  openGovXIdLinkModal: PropTypes.func.isRequired,
  openPublishModal: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefetchOfferDetails: PropTypes.func.isRequired,
  onClickSyndicateOfferCreateModal: PropTypes.func.isRequired,
  canViewSyndicatedListings: PropTypes.bool
}

export default OffersListing
