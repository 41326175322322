/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import Grid from '@mui/material/Grid'
import { useDispatch } from 'react-redux'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import { Button } from '../../../../../Button'
import { showErrorNotification } from '../../../../../../redux/actions'

function Billing({
  billableSubscription,
  billableSubscriptionIsError,
  submitBillableEvent,
}) {
  const styleInputRow = { margin: '20px 0', width: '50%' }

  const dispatch = useDispatch()
  if (billableSubscriptionIsError) dispatch(showErrorNotification('Failed to load billable subscription. Please Sync Affiliate and try again later.'))

  const defaultDiscountCode = 'GX-TEST'
  const defaultSubTotal = 10
  const defaultCommissionRate = 0.1
  const defaultCommissionValue = 1

  const {
    handleSubmit, control, formState, setValue, formState: { isSubmitSuccessful }, reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      uniquenessToken: uuidv4(),
      orderDateUTC: new Date().toISOString(),
      orderNumber: uuidv4(),
      orderSubTotal: defaultSubTotal,
      discountCode: defaultDiscountCode,
      commissionRate: defaultCommissionRate,
      commissionValue: defaultCommissionValue,
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        uniquenessToken: uuidv4(),
        orderNumber: uuidv4(),
        orderDateUTC: new Date().toISOString(),
        partnerListingId: billableSubscription?.partnerListingId,
        eventStreamId: billableSubscription?.eventStreamId,
        subscriptionId: billableSubscription?.subscriptionId,
      })
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    setValue('partnerListingId', billableSubscription?.partnerListingId)
    setValue('eventStreamId', billableSubscription?.eventStreamId)
    setValue('subscriptionId', billableSubscription?.subscriptionId)
  }, [billableSubscription])

  const onSubmit = async (data) => {
    if (data) {
      await submitBillableEvent(
        {
          subscriptionId: data.subscriptionId,
          eventStreamId: data.eventStreamId,
          uniquenessToken: data.uniquenessToken,
          partnerListingId: data.partnerListingId,
          eventTimeUTC: data.orderDateUTC,
          payload: {
            orderNumber: data.orderNumber,
            orderDateUTC: data.orderDateUTC,
            orderSubTotal: data.orderSubTotal,
            discountCode: data.discountCode,
            commissionRate: data.commissionRate,
            commissionValue: data.commissionValue,
          },
        },
      )
    }
  }

  return (
    <div style={{ borderBottom: '1px dashed #ccc' }}>
      <div style={styleInputRow}>
        <h4>Billable Events</h4>
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          size="small"
          disabled
          control={control}
          name="eventStreamId"
          label="Event Stream ID"
          rules={{ required: true }}
          gxid="billingEventStreamId"
        />
      </div>
      <div style={styleInputRow}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <MUIFormTextInput
              control={control}
              name="uniquenessToken"
              label="Uniqueness Token"
              rules={{ required: true }}
              gxid="billingUniquenessToken"
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              label="Generate new token"
              variant="contained"
              color="primary"
              onClick={() => setValue('uniquenessToken', uuidv4())}
            />
          </Grid>
        </Grid>
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          disabled
          control={control}
          name="partnerListingId"
          label="Partner Listing ID"
          rules={{ required: true }}
          gxid="billingPartnerListingId"
        />
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          control={control}
          name="orderDateUTC"
          label="Order Date UTC"
          rules={{ required: true }}
          gxid="billingOrderDateUTC"
        />
      </div>
      <div style={styleInputRow}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <MUIFormTextInput
              control={control}
              name="orderNumber"
              label="Order Number"
              rules={{ required: true }}
              gxid="billingOrderNumber"
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              label="Generate new order number"
              variant="contained"
              color="primary"
              onClick={() => setValue('orderNumber', uuidv4())}
            />
          </Grid>
        </Grid>
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          control={control}
          name="orderSubTotal"
          label="Order Sub Total (use money format without $ sign)"
          type="number"
          rules={{ required: true, min: 0 }}
          gxid="billingOrderSubTotal"
        />
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          control={control}
          name="discountCode"
          label="Order Discount Code"
          rules={{ required: true }}
          gxid="billingDiscountCode"
        />
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          control={control}
          name="commissionRate"
          label="Commission Rate (use decimal value)"
          type="number"
          rules={{ required: true, min: 0 }}
          gxid="billingCommissionRate"
        />
      </div>
      <div style={styleInputRow}>
        <MUIFormTextInput
          control={control}
          name="commissionValue"
          label="Commission Value (use money format without $ sign)"
          type="number"
          rules={{ required: true, min: 0 }}
          gxid="billingCommissionValue"
        />
      </div>
      <div style={styleInputRow}>
        <Button
          label="Create Billable Event"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Create Billable Event
        </Button>
      </div>
    </div>
  )
}

Billing.propTypes = {
  submitBillableEvent: PropTypes.func.isRequired,
}

export default Billing
