/* eslint-disable react/prop-types */
import React from 'react'
import SecretKeySection from './SecretKeySection'
import ApiSection from './ApiSection'

export function CredentialsSection({ listing }) {
  return (
    <div>
      <SecretKeySection listing={listing} />
      <ApiSection listing={listing} />
    </div>
  )
}

export default CredentialsSection
