import { api } from '../../../redux/slices/apiSlice'

export const partnerListingsApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getPartnerListings: build.query({
      query: () => '/partners/listings?page=0&pageSize=99999&created.type=any&updated.type=any&sortby=createdonutc&sortdirection=desc',
      transformResponse: (response) => ({
        rows: response.data,
        pagination: {
          total: response.total,
        },
      }),
      providesTags: ['PartnerListings'],
    }),
    createPartnerListing: build.mutation({
      query: (data) => ({
        url: '/partners/listings',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PartnerListings'],
    }),
    createPicture: build.mutation({
      query: (data) => ({
        url: '/pictures',
        method: 'POST',
        body: data,
      }),
      extraOptions: {
        timeout: 30000,
      },
    }),
    getPartnerListing: build.query({
      query: (partnerListingId) => ({
        url: `/partners/listing/${partnerListingId}`
      })

    }),
    getPartnerListingsByTerm: build.query({
      query: (search) => `/partners?SearchTerm=${search}`,
      transformResponse: (response, meta, query) => {
        const { data } = response

        return data.map((d) => {
          const partnerName = d.name.toLowerCase()
          const search = query.toLowerCase()

          // first results, matches beginning of partner name
          if (partnerName.startsWith(search)) {
            return { priority: 1, ...d }
          }

          // second results, matches full word in partner name
          const searchTermFirstWord = search.split(' ')[0]
          if (partnerName.split(' ').some((word) => searchTermFirstWord === word)) {
            return { priority: 2, ...d }
          }

          // everything else
          return { priority: 3, ...d }
        }).sort((optA, optB) => optA.priority - optB.priority).slice(0, 25)
      },
    }),
    getDefaultBillingRules: build.query({
      query: () => '/resources/billing-rules',
    }),
  }),
})

export const {
  useGetPartnerListingsQuery,
  useLazyGetPartnerListingsByTermQuery,
  useCreatePartnerListingMutation,
  useCreatePictureMutation,
  useLazyGetPartnerListingQuery,
  useGetDefaultBillingRulesQuery,
} = partnerListingsApiSlice
