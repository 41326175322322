import { useSearchParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { buildSearchParams, getFilters } from './filters'

const useFilterParams = ({
  columns,
  defaultFilters,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const [filters, setFilters] = useState({
    items: [],
    logicOperator: 'and',
  })
  const [sortParams, setSortParams] = useState([])
  const [paginationParams, setPaginationParams] = useState({
    pageSize: 25,
    page: 0,
  })
  const filterFields = columns.map((column) => column.field)

  useEffect(() => {
    const currentFilters = getFilters(searchParams, defaultFilters, pathname, filterFields, paginationParams)
    setSearchParams(buildSearchParams(currentFilters, pathname, [{
      sort: currentFilters.sort.sort,
      field: currentFilters.sort.field,
    }], currentFilters.pagination))
    setFilters(currentFilters)
    setSortParams([currentFilters.sort])
    setPaginationParams(currentFilters.pagination)
  }, [])

  return {
    filters,
    setFilters,
    setSearchParams,
    sortParams,
    setSortParams,
    paginationParams,
    setPaginationParams,
  }
}

export default useFilterParams
