/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable react/prop-types */
/* eslint-disable no-extend-native */
import React, { useState } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { formatOccupationType } from '../../../../formatting'

String.prototype.hashCode = function () {
  let hash = 0; let i; let
    chr
  if (this.length === 0) return hash
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function OccupationListItem({
  occupation,
  level,
  index,
  onSelectedStateChange,
  // eslint-disable-next-line no-unused-vars
  hasPrivateAccess,
  canAccessPrivate,
  onShownInRegistrationChange,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      {index === 0
    && level !== 0 && (
      <Typography style={{
        margin: '5px 0px',
        marginLeft: `${52 * level}px`,
        color: '#7b7e84',
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: '600',
        WebkitFontSmoothing: 'antialiased',
        lineHeight: '20px',
        paddingTop: '20px',
      }}
      >
        {formatOccupationType(occupation.type)}
      </Typography>
      )}
      <ListItem
        style={{
          marginLeft: `${52 * level}px`,
          maxWidth: `calc(100% - ${52 * level}px)`,
          border: 'solid 1px #CFCFCF',
          padding: '0px 15px',
        }}
      >
        <ListItemText style={{ paddingRight: '0px' }}>
          <Grid container style={{ flexWrap: 'nowrap' }}>
            <Grid item style={{ flex: 1 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    label={occupation.name}
                    disabled={occupation.private && !canAccessPrivate}
                    aria-label={occupation.name}
                    onChange={(e) => onSelectedStateChange(occupation.id, e.target.checked)}
                    checked={occupation.selected}
                  />
      )}
                label={occupation.name}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={(
                  <Switch
                    disableRipple
                    value="show"
                    checked={occupation.shownInRegistration}
                    disabled={!occupation.selected}
                    onChange={(e) => onShownInRegistrationChange(occupation.id, e.target.checked)}
                  />
      )}
                label="Show in registration"
                labelPlacement="start"
              />
            </Grid>
            <Grid item>
              {occupation
            && occupation.children
            && occupation.children.length > 0 && (
              <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              )}
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      {occupation
    && occupation.children
    && occupation.children.length > 0 && (
      <div>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit style={{ marginBottom: '30px' }}>
          {occupation
            && occupation.children
            && occupation.children.length > 0
            && occupation.children
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((o, i) => <OccupationListItem key={o.name} index={i} occupation={o} level={level + 1} onShownInRegistrationChange={onShownInRegistrationChange} onSelectedStateChange={onSelectedStateChange} />)}
        </Collapse>
      </div>
      )}
    </>
  )
}

export default OccupationListItem
