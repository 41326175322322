/* eslint-disable react/prop-types */
import React from 'react'
import ModalContainer from './ModalContainer'

export function DescriptionModal({
  description,
}) {
  return (
    <ModalContainer
      maxWidth="xs"
      fullWidth="true"
    >
      <p style={{ color: '#222' }}>{ description }</p>
    </ModalContainer>
  )
}

export default DescriptionModal
