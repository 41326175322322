import { combineReducers } from 'redux'
import partners, { STATE_KEY as PARTNERS_STATE_KEY } from './partners'
import listings, { STATE_KEY as PARTNERS_LISTINGS_STATE_KEY } from './listings'

export const STATE_KEY = 'partners'

const reducer = combineReducers({
	[PARTNERS_STATE_KEY]: partners,
	[PARTNERS_LISTINGS_STATE_KEY]: listings,
})

export default reducer
