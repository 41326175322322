import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectPartnerListing } from '../../../../../redux/modules/partners/listings'
import { fetchPartner, selectPartner } from '../../../../../redux/modules/partners/partners'

const useDevelopment = () => {
  const dispatch = useDispatch()
  const { id: listingId } = useParams()

  const [processing, setProcessing] = useState(false)

  // selectors
  const listing = useSelector(selectPartnerListing(listingId))
  const partner = useSelector(selectPartner(listing.partnerId))

  // init
  useEffect(() => {
    const runDispatches = async () => {
      setProcessing(true)
      const promises = []
      if (!(partner && partner.source)) {
        promises.push(dispatch(fetchPartner(listing.partnerId)))
      }
      await Promise.all(promises).then(() => setProcessing(false))
    }
    runDispatches()
  }, [])

  return {
    listing,
    partner,
    processing,
  }
}

export default useDevelopment
