/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Launch } from '@mui/icons-material'
import ModalContainer from '../../Modals/ModalContainer'
import DataTable from '../../@Common/DataTable'
import StyledLink from '../../StyledLink'

function CodePoolDetailsModal({
  onClickConfirm,
  onClickCancel,
  linkedOffers,
  titleText,
  confirmLabel,
  action,
  dialogText,
}) {
  const [processing, setProcessing] = useState(false)

  const onSubmit = async () => {
    try {
      setProcessing(true)
      await onClickConfirm()
    } catch (error) {
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }

  const offerStatus = {
    deleted: 'Deleted',
    published: 'Published',
  }

  const checkLinkedOffers = () => {
    const offers = linkedOffers.filter((offer) => offer.status !== offerStatus.deleted)
    return offers.length > 0
  }

  return (
    <ModalContainer
      processing={processing}
      title={checkLinkedOffers() ? titleText : 'Are you sure?'}
      maxWidth="sm"
      fullWidth="true"
      hideModal={() => { }}
      actions={checkLinkedOffers() ? {
        secondaryAction: { label: 'Cancel', onClick: onClickCancel },
      } : {
        primaryAction: { label: confirmLabel, onClick: onSubmit },
        secondaryAction: { label: 'Cancel', onClick: onClickCancel },
      }}
    >
      {
        checkLinkedOffers() && action !== 'publish'
          ? (
            <Box>
              <Typography>
                This code pool is being used by
                {' '}
                {linkedOffers.length}
                {' '}
                offer
                {linkedOffers.length === 1 ? '' : 's'}
                . Please replace the code pool on the listings below and then try again.
              </Typography>
              <DataTable
                autoPageSize
                hideFooterRowCount
                disableColumnFilter
                pagination={linkedOffers.length > 5}
                rows={linkedOffers || []}
                getRowId={(row) => row.offerId}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Offer Name',
                    type: 'string',
                    width: 250,
                    filterable: false,
                  },
                  {
                    field: 'listingId',
                    headerName: '',
                    type: 'string',
                    width: 250,
                    align: 'left',
                    renderCell: (row) => (
                      <Box alignItems="center" display="flex" flexWrap="wrap">
                        <Box display="inline"><Launch style={{ color: '#0E5777' }} /></Box>
                        <Box display="inline" style={{ marginLeft: '5px' }}>
                          <StyledLink
                            onClick={() => onClickCancel()}
                            to={`/partners/listings/${row.row.listingId}`}
                            contents="View Linked Offer"
                          />
                        </Box>

                      </Box>
                    ),
                  },
                ]}
              />
            </Box>
          )
          : (
            <Typography component="div">
              <Box>
                {dialogText}
              </Box>
            </Typography>
          )
      }
    </ModalContainer>
  )
}

export default CodePoolDetailsModal
