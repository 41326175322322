import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, CircularProgress, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { ModalContainer } from '../../../../../Modals/ModalContainer'
import { MUIFormSelectInput } from '../../../../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import { MUIFormTextAreaInput } from '../../../../../@Common/Form/TextAreaInput/FormTextAreaInput'
import OccupationsForm from '../../../../../Listings/Listing/Forms/OccupationsForm'
import { MUIFormCheckboxInput } from '../../../../../@Common/Form/CheckboxInput/FormCheckboxInput'
import { ErrorMessage } from '@hookform/error-message'

const DiscountInstructions = ({ control }) => <Box sx={{ marginBottom: '16px' }}>
  <MUIFormTextAreaInput
    name="instructions"
    label="Discount Instructions"
    rules={{
      maxLength: {
        value: 500,
        message: 'Instructions cannot exceed 500 characters',
      },
    }}
    control={control}
    gxid="offerDescription"
  />
</Box>

function OfferCreateEditModal({
  offer,
  partnerListing,
  verificationListing,
  lookups,
  canSetOfferChannels,
  onClickSubmit,
  onClickCancel,

  publishedOccupationsHierarchy,
  publishedOccupations,
  publicAndPublishedOccupationsHierarchy,
  publicAndPublishedOccupations,
  canAccessPrivateAffiliations,
  canViewBillingRules,
  codePools,
  isPublishMode
}) {
  const Shopify = 'shopify'
  const [processing, setProcessing] = useState(false)
  const [
    selectedOccupationMappings,
    setSelectedOccupationMappings,
  ] = useState(offer ? offer.verification.eligibleOccupations
    : publicAndPublishedOccupations?.map((item) => ({ occupationId: item.id, shownInRegistration: true })))
  const isShopifyOffer = offer?.source.type.toLowerCase() === Shopify

  const getSelectedChannels = () => {
    if (offer) {
      return lookups.data?.channels.reduce((carry, currentValue) => ({
        ...carry,
        [`channels.${currentValue.value}`]: Boolean(offer.channels
          .find((channel) => channel === currentValue.value)),
      }), {})
    }
    if (!canSetOfferChannels && !offer) return lookups.data?.channels.reduce((carry, currentValue) => ({ ...carry, [`channels.${currentValue.value}`]: false }), {})
    return lookups.data?.channels.filter((x) => x.defaultChecked === true).reduce((carry, currentValue) => ({ ...carry, [`channels.${currentValue.value}`]: true }), {})
  }

  function getInitialValues(data) {
    if (!data) return null

    let offerObj = {}
    if (data?.discount.protectedLink) {
      offerObj = {
        offerType: 'ProtectedLink',
        offerLink: data.discount.protectedLink.link,
      }
    }
    else if (data?.discount.codePool) {
      offerObj = {
        offerType: 'CodePool',
        codePoolId: codePools.some(x => x.key === data.discount.codePool.id) ? data.discount.codePool.id : '',
        passCodeInUrl: data.discount.codePool.passCodeInUrl,
        urlParameterName: data.discount.codePool.urlParameterName,
        offerLink: data.discount.codePool.link,
        instructions: data.discount.codePool.instructions,
        hideCodeInDisplay: !data.discount.codePool.showCodeInDisplay
      }
    }
    else if (data?.discount.discountCode) {
      offerObj = {
        offerType: 'DiscountCode',
        discountCode: data.discount.discountCode.code,
        passCodeInUrl: data.discount.discountCode.passCodeInUrl,
        urlParameterName: data.discount.discountCode.urlParameterName,
        offerLink: data.discount.discountCode.link,
        instructions: data.discount.discountCode.instructions,
        hideCodeInDisplay: !data.discount.discountCode.showCodeInDisplay
      }
    }

    return {
      commissionRate: offer.billingRules?.commissionRate,
      offerName: data ? data.name : '',
      offerDescription: data?.description,
      discountType: data?.discount.scheme.typeName,
      discountValue: data?.discount.scheme.amount,
      passCodeInUrl: true,
      ...offerObj,
      ...getSelectedChannels(),
    }
  }

  const {
    handleSubmit, watch, control, setValue, setError, reset, formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: !offer ? {
      offerType: 'ProtectedLink',
      discountType: 'PercentOff',
      passCodeInUrl: true,
      ...getSelectedChannels(),
      commissionRate: partnerListing.billingRules.commissionRate
    } : getInitialValues(offer),
  })

  useEffect(() => {
    reset()
  }, [offer, lookups, reset])

  useEffect(() => {
    if (isPublishMode) {
      setError("codePoolId", {
        type: "manual",
      })
    }
  }, [setError])

  const getDiscount = (offerForm) => {
    let discount = {
      typeName: offerForm.offerType,
      scheme: {
        typeName: offerForm.discountType,
        amount: offerForm.discountType !== 'NoScheme' ? Number(offerForm.discountValue) : null,
      }
    }

    switch (offerForm.offerType) {
      case 'ProtectedLink':
        discount.protectedLink = { link: offerForm.offerLink }
        break;
      case 'CodePool':
        discount.codePool = {
          id: offerForm.codePoolId,
          passCodeInUrl: offerForm.passCodeInUrl,
          urlParameterName: offerForm.urlParameterName,
          link: offerForm.offerLink,
          instructions: offerForm.instructions,
          showCodeInDisplay: !offerForm.hideCodeInDisplay
        }
      case 'DiscountCode':
        discount.discountCode = {
          code: offerForm.discountCode,
          passCodeInUrl: offerForm.passCodeInUrl,
          urlParameterName: offerForm.urlParameterName,
          link: offerForm.offerLink,
          instructions: offerForm.instructions,
          showCodeInDisplay: !offerForm.hideCodeInDisplay
        }
        break;
    }

    return discount;
  }

  const onSubmit = async (data) => {
    try {
      setProcessing(true)
      const channels = Object.keys(data.channels)
        .reduce((reduced, key) => [...reduced, ...data.channels[key] ? [key] : []], [])
      const offerObj = {
        offerId: offer?.offerId,
        status: offer?.status,
        channels,
        name: data.offerName,
        description: data.offerDescription,
        link: offer?.link ?? 'https://offers.govx.com/verify/offer/',
        tags: offer?.tags,
        listingId: partnerListing.id,
        verification: {
          campaignId: offer?.verification?.campaignId,
          eligibleOccupations: selectedOccupationMappings,
          listingId: offer?.verification.listingId ?? verificationListing?.id,
        },
        discount: getDiscount(data),
        duration: {
          startDate: offer?.duration.startDate,
          endDate: offer?.duration.endDate,
        },
        billingRules: {
          commissionRate: data.commissionRate
        }
      }
      await onClickSubmit(offerObj)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      setProcessing(false)
    }
  }

  const supportedOfferCreateTypes = ['ProtectedLink', 'CodePool', 'DiscountCode']

  return (
    <ModalContainer
      title={`${offer ? 'Edit' : 'Create'} Offer`}
      maxWidth="md"
      fullWidth="true"
      drawer
      processing={processing || lookups.isLoading}
      hideModal={() => { }}
      actions={{
        primaryAction: { label: isPublishMode ? 'Save and Publish' : 'Save', onClick: handleSubmit(onSubmit) },
        secondaryAction: { label: 'Cancel', onClick: onClickCancel },
      }}
    >
      <Box sx={{ marginBottom: '16px' }}>
        <MUIFormTextInput
          control={control}
          name="offerName"
          label="Offer Name"
          rules={{ required: true }}
          gxid="offerName"
        />
      </Box>
      {
        isShopifyOffer && (
          <Box display="flex" sx={{ mb: '36px', mt: '16px' }}>
            <Box width="36px">
              <InfoOutlined sx={{ color: '#0E5777', mt: '-1px' }} width="16px" />
            </Box>
            <Box>
              This offer is controlled via Shopify API. Editing is limited.
            </Box>
          </Box>
        )
      }
      {(lookups.data) && (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '16px', marginBottom: '16px' }}>
          {
            !isShopifyOffer && (
              <Box>
                <Box sx={{ marginBottom: '16px' }}>
                  <MUIFormSelectInput
                    name="offerType"
                    label="Offer Type"
                    control={control}
                    items={lookups.data.discountType.map((lu) => ({
                      value: lu.value, key: lu.value, label: lu.text,
                    })).filter((lu) => supportedOfferCreateTypes.includes(lu.value))}
                    disabled={offer != null}
                    gxid="offerType"
                  />
                </Box>
                {
                  watch('offerType') === 'DiscountCode' && (
                    <>
                      <Box sx={{ marginBottom: '16px' }}>
                        <MUIFormTextInput
                          name="discountCode"
                          label="Discount Code"
                          control={control}
                          rules={{ required: true }}
                          gxid="discountCode"
                        />
                      </Box>
                      <Box display="flex" sx={{ minHeight: '58px' }}>
                        <Box>
                          <MUIFormCheckboxInput
                            name="passCodeInUrl"
                            label={"Pass Code in URL"}
                            control={control}
                            gxid={`passCodeInUrl`}
                            onChange={(e) => {
                              if (!e) {
                                setValue('hideCodeInDisplay', false)
                              }
                            }}
                          />
                        </Box>
                        {
                          watch('passCodeInUrl') &&
                          <Box>
                            <MUIFormCheckboxInput
                              name="hideCodeInDisplay"
                              label={"Hide code on offer screen"}
                              control={control}
                              gxid={`hideCodeInDisplay`}
                            />
                          </Box>
                        }
                      </Box>
                      {
                        watch('passCodeInUrl') && (
                          <Box sx={{ marginBottom: '16px' }}>
                            <MUIFormTextInput
                              name="urlParameterName"
                              label="Parameter Name"
                              control={control}
                              rules={{ required: true }}
                              gxid="urlParameterName"
                            />
                          </Box>
                        )
                      }
                      <DiscountInstructions control={control} />
                    </>
                  )
                }
                {
                  watch('offerType') === 'CodePool' && (
                    <>
                      <Box sx={{ marginBottom: isPublishMode ? '0px' : '16px' }}>
                        <MUIFormSelectInput
                          hideErrorMessage={isPublishMode ? true : false}
                          name="codePoolId"
                          label="Discount Code Pool"
                          control={control}
                          items={codePools}
                          rules={{
                            required: true,
                          }}
                          gxid="codePoolId"
                        />
                      </Box>
                      {isPublishMode &&
                        <Box>
                          <ErrorMessage
                            errors={errors}
                            name={'codePoolId'}
                            render={() => (
                              <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                                Please select a published code pool
                              </p>
                            )}
                          />
                        </Box>
                      }
                      <Box display="flex" sx={{ minHeight: '58px' }}>
                        <Box>
                          <MUIFormCheckboxInput
                            name="passCodeInUrl"
                            label={"Pass Code in URL"}
                            control={control}
                            gxid={`passCodeInUrl`}
                            onChange={(e) => {
                              if (!e) {
                                setValue('hideCodeInDisplay', false)
                              }
                            }}
                          />
                        </Box>
                        {
                          watch('passCodeInUrl') &&
                          <Box>
                            <MUIFormCheckboxInput
                              name="hideCodeInDisplay"
                              label={"Hide code on offer screen"}
                              control={control}
                              gxid={`hideCodeInDisplay`}
                            />
                          </Box>
                        }
                      </Box>
                      {
                        watch('passCodeInUrl') && (
                          <Box sx={{ marginBottom: '16px' }}>
                            <MUIFormTextInput
                              name="urlParameterName"
                              label="Parameter Name"
                              control={control}
                              rules={{ required: true }}
                              gxid="urlParameterName"
                            />
                          </Box>
                        )
                      }
                      <DiscountInstructions control={control} />
                    </>
                  )
                }
                <Box sx={{ marginBottom: '16px' }}>
                  <MUIFormTextInput
                    name="offerLink"
                    label="Offer Link"
                    control={control}
                    rules={{ required: true, pattern: { value: /^https?:\/\// } }}
                    gxid="offerLink"
                  />
                </Box>
                <Box display="flex" sx={{ marginBottom: '16px', minHeight: '58px' }}>
                  <Box width="50%" sx={{ marginRight: '16px' }}>
                    <MUIFormSelectInput
                      name="discountType"
                      label="Discount Type"
                      control={control}
                      items={lookups.data.schemeType.map((lu) => ({
                        value: lu.value, label: lu.text, key: lu.value,
                      }))}
                      onChange={() => setValue('discountValue', '')}
                      gxid="discountType"
                    />
                  </Box>
                  {
                    watch('discountType') !== 'NoScheme' && (
                      <Box width="50%" key={watch('discountType')}>
                        <MUIFormTextInput
                          name="discountValue"
                          label="Discount Value"
                          control={control}
                          rules={{
                            required: true,
                            pattern: {
                              value: watch('discountType') === 'PercentOff' ? /^([0-9]|[1-9][0-9]|100)$/ : /^([0-9]|[1-9][0-9]*)$/,
                            },
                          }}
                          shouldUnregister
                          suffix={watch('discountType') === 'PercentOff' ? '%' : null}
                          prefix={watch('discountType') === 'FixedAmount' ? '$' : null}
                          gxid="discountValue"
                        />
                      </Box>
                    )
                  }
                </Box>
              </Box>
            )
          }
          <Box sx={{ marginBottom: '16px' }}>
            <MUIFormTextAreaInput
              name="offerDescription"
              label="Description (Optional)"
              rules={{
                maxLength: {
                  value: 500,
                  message: 'Description cannot exceed 500 characters',
                },
              }}
              control={control}
              gxid="offerDescription"
            />
          </Box>
          {!isShopifyOffer && (
            <Box>
              <Typography sx={{ marginBottom: '16px' }} style={{ margin: '0px' }}>
                <strong>Eligible Affiliations</strong>
              </Typography>

              {
                (publishedOccupations
                  && publishedOccupationsHierarchy
                  && publicAndPublishedOccupations
                  && publicAndPublishedOccupationsHierarchy) ? (
                  <OccupationsForm
                    publishedOccupationsHierarchy={publishedOccupationsHierarchy}
                    publishedOccupations={publishedOccupations}
                    publicAndPublishedOccupationsHierarchy={publicAndPublishedOccupationsHierarchy}
                    publicAndPublishedOccupations={publicAndPublishedOccupations}
                    canAccessPrivateAffiliations={canAccessPrivateAffiliations}
                    selectedOccupationMappings={selectedOccupationMappings}
                    setSelectedOccupationMappings={setSelectedOccupationMappings}
                  />
                ) : (<Box height="150px" display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box>)
              }
            </Box>
          )}
          {canViewBillingRules &&
            <Box margin={{ marginTop: '22px', marginBottom: '16px' }}>
              <Box display={'flex'} sx={{ justifyContent: 'space-between' }}>
                <Box style={{ fontSize: '.875rem', marginTop: '5px' }}>
                  <Typography style={{ margin: '0px' }}>
                    <strong>Billing Rules</strong>
                  </Typography>
                  <Box>
                    Defines the commission rate on a sale
                  </Box>
                </Box>
                <Box display={'flex'} sx={{ alignItems: 'center', width: '50%' }}>
                  {/* <Box width={'45%'}>
                                        <TextField
                                            name={'billingFlatRate'}
                                            label='Flat Rate'
                                            validate={[required, isNumber]}
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">$</InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                    <Box width={'10%'} style={{ fontWeight: 'bold', textAlign: 'center' }}>+</Box> */}
                  <Box width={'30%'}>
                    <MUIFormTextInput
                      control={control}
                      type="number"
                      name={'commissionRate'}
                      label='Commission'
                      rules={{
                        required: true,
                        min: 0,
                        max: 100,
                        pattern: {
                          value: /^\s*-?[0-9]\d*(\.\d{1,2})?\s*$/,
                        },
                      }}
                      suffix={'%'}
                      inputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Box>
              </Box>
              {/* <Box style={{ paddingLeft: '50%' }}>
                                {formInfo.billingFlatRate && formInfo.billingFlatRate.touched && formErrors.billingFlatRate && <FormHelperText error={true}>Billing Flate Rate is Required</FormHelperText>}
                            </Box> */}
            </Box>
          }

          <Box display="flex" sx={{ marginY: '16px' }}>
            <Box width="50%" sx={{ paddingRight: '100px' }}>
              <Typography style={{ margin: '0px' }}>
                <strong>Sales Channels</strong>
              </Typography>
              <p>
                Control where the offer will show up by configuring the display channels.
              </p>
            </Box>
            <Box width="50%">
              {lookups.data.channels.map((item) => (
                <MUIFormCheckboxInput
                  key={item.value}
                  name={`channels.${item.value}`}
                  label={item.text}
                  control={control}
                  gxid={`channel.${item.value}`}
                  disabled={!canSetOfferChannels || item.disabled}
                  style={!isShopifyOffer && item.value.toLowerCase() === Shopify ? { display: 'none' } : {}}
                />
              ))}
            </Box>
          </Box>
        </form>
      )}

    </ModalContainer>
  )
}

OfferCreateEditModal.defaultProps = {
  offer: undefined,
  partnerListing: undefined,
  verificationListing: undefined,
}

OfferCreateEditModal.propTypes = {
  offer: PropTypes.shape({
    offerId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    discountType: PropTypes.string,
    discountValue: PropTypes.number,
    channels: PropTypes.arrayOf(PropTypes.string),
    verification: PropTypes.shape({
      eligibleOccupations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        parent: PropTypes.string,
      })),
      campaignId: PropTypes.number,
      listingId: PropTypes.number,
    }),
    source: PropTypes.shape({
      type: PropTypes.string,
    }),
    status: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    duration: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
  lookups: PropTypes.shape({
    data: PropTypes.shape({
      discountType: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string,
      })),
      schemeType: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string,
      })),
      channels: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string,
      })),
    }),
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  canSetOfferChannels: PropTypes.bool.isRequired,
  canAccessPrivateAffiliations: PropTypes.bool.isRequired,
  publicAndPublishedOccupations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    parent: PropTypes.string,
    isPublic: PropTypes.bool,
    isPublished: PropTypes.bool,
  })).isRequired,
  publicAndPublishedOccupationsHierarchy: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    parent: PropTypes.string,
    isPublic: PropTypes.bool,
    isPublished: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      parent: PropTypes.string,
      isPublic: PropTypes.bool,
      isPublished: PropTypes.bool,
    })),
  })).isRequired,
  publishedOccupations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    parent: PropTypes.string,
    isPublic: PropTypes.bool,
    isPublished: PropTypes.bool,
  })).isRequired,
  publishedOccupationsHierarchy: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    parent: PropTypes.string,
    isPublic: PropTypes.bool,
    isPublished: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      parent: PropTypes.string,
      isPublic: PropTypes.bool,
      isPublished: PropTypes.bool,
    })),
  })).isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  partnerListing: PropTypes.shape({
    id: PropTypes.string,
  }),
  verificationListing: PropTypes.shape({
    id: PropTypes.number,
  }),
}

export default OfferCreateEditModal
