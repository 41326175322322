/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import Typography from '@mui/material/Typography'
import ModalContainer from '../ModalContainer'
import Button from '../../Button'
import { generateBearerToken, hideModal } from '../../../redux/actions'

export function BearerModal({ listing }) {
  const dispatch = useDispatch()

  const [bearerToken, setBearerToken] = useState(null)
  const [copied, setCopied] = useState(false)

  const onClose = () => dispatch(hideModal())
  const createNewBearer = () => dispatch(generateBearerToken(listing)).then((response) => {
    setBearerToken(response.token)
    return {}
  })
  const copyToken = () => {
    copy(bearerToken)
    setCopied(true)
  }

  return (
    <ModalContainer
      title="Create New Bearer Token"
      modal
      maxWidth="sm"
      fullWidth="false"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div style={{ marginTop: '10px' }}>
        {bearerToken ? (
          <div>
            <Typography style={{ color: '#222' }}>
              New bearer token generated successfully. This token expires in one year. Click the link below to copy to your clipboard.
            </Typography>
            <div style={{ cursor: 'pointer', justifyContent: 'center' }} onClick={() => copyToken(bearerToken)}>
              <Typography style={{
                marginTop: '25px', padding: '8px', border: 'solid #e3e3e3 1px', borderRadius: '3px', background: '#f7f7f7', color: '#2a2d36', fontFamily: 'monospace', alignItems: 'center', whiteSpace: 'nowrap', overflowX: 'scroll',
              }}
              >
                {bearerToken}
              </Typography>
            </div>
            {copied && (
            <Typography style={{ margin: '0px' }}>
              <small>Token copied to clipboard</small>
            </Typography>
            )}
          </div>
        ) : (
          <div>
            <Typography style={{ color: '#222', marginBottom: '20px' }}>
              Are you sure you want to create a new bearer token that replaces your previous token? This action cannot be undone.
            </Typography>
            <Button
              label="Create"
              fullWidth={false}
              style={{ backgroundColor: '#f44336', color: 'white' }}
              onClick={() => createNewBearer(listing)}
            >
              Create
            </Button>
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

BearerModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default BearerModal
