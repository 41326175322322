import { Box, styled } from '@mui/material'

const HelperText = styled(Box)({
  fontSize: '12px',
  display: 'block',
  lineHeight: '16px',
  color: '#acacac',
  margin: '5px 0 0 0 !important',
})

export default HelperText
