import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, deleteVerificationLink, showSuccesNotification } from '../../../redux/actions'

export function DeleteVerificationLinkModal({
  verificationLink,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const onDeleteVerificationLink = (link) => dispatch(deleteVerificationLink(link))
    .then(dispatch(showSuccesNotification('Verification link deleted')))
    .then(dispatch(hideModal()))

  return (
    <ModalContainer
      title="Delete verification link"
      modal
      maxWidth="sm"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: () => onDeleteVerificationLink(verificationLink),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ marginTop: '25px', color: '#222' }}>
        You are about to delete a verification link. Users accessing this link will no longer be able to verify.
        <strong>This action cannot be undone</strong>
        .
      </p>
    </ModalContainer>
  )
}

DeleteVerificationLinkModal.propTypes = {
  verificationLink: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    redirectUri: PropTypes.string.isRequired,
  }).isRequired,
}

export default DeleteVerificationLinkModal
