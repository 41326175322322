import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../../../Button'
import { useSyncShopifyOfferMutation } from '../../../../../../../redux/slices/offersSlice'
import { showSuccesNotification, showErrorNotification } from '../../../../../../../redux/actions'

const styleInputRow = { margin: '20px 0' }

function DevelopmentOffers({
  appId,
}) {
  const dispatch = useDispatch()
  const [syncShopifyOffer] = useSyncShopifyOfferMutation()

  const onSync = async (listingId) => {
    try {
      const response = await syncShopifyOffer(listingId).unwrap()
      dispatch(showSuccesNotification('Successfully initiated shopify sync'))
      return response
    } catch (err) {
      dispatch(showErrorNotification('There was an error while attempting sync the shopify offer'))
      return Promise.reject(err)
    }
  }

  return (
    <>
      <div style={styleInputRow}>
        <h4>Offers</h4>
      </div>
      <div style={styleInputRow}>
        <Button
          label="Sync Offer"
          variant="contained"
          color="primary"
          onClick={() => onSync(appId)}
        >
          Sync Offer
        </Button>
      </div>
    </>
  )
}

DevelopmentOffers.propTypes = {
  appId: PropTypes.string.isRequired,
}

export default DevelopmentOffers
