import { governmentDepartment } from '../../../api/schema'
import * as governmentDepartmentsApi from '../../../api/governmentDepartmentsApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

export const FETCH_GOVERNMENTDEPARTMENTS_REQUEST = 'FETCH_GOVERNMENTDEPARTMENTS_REQUEST'
export const FETCH_GOVERNMENTDEPARTMENTS_SUCCESS = 'FETCH_GOVERNMENTDEPARTMENTS_SUCCESS'
export const FETCH_GOVERNMENTDEPARTMENTS_FAILURE = 'FETCH_GOVERNMENTDEPARTMENTS_FAILURE'

export const fetchGovernmentDepartments = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_GOVERNMENTDEPARTMENTS_REQUEST })

	return governmentDepartmentsApi.fetchGovernmentDepartments()
		.then(response => {
			const data = normalize(response, [governmentDepartment])
			return dispatch({ type: FETCH_GOVERNMENTDEPARTMENTS_SUCCESS, data })
		})
		.catch(err => {
			return dispatch({ type: FETCH_GOVERNMENTDEPARTMENTS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'governmentDepartments'

export const DEFAULT_STATE = {
	db: {}	
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_GOVERNMENTDEPARTMENTS_SUCCESS:
			return { ...state, db: action.data.entities.governmentDepartments }
		default:
			return { ...state }
	}
}

export default reducer

export const selectGovernmentDepartmentsHashMap = state => state.verification.governmentDepartments.db

export const selectGovernmentDepartments = createSelector(
	selectGovernmentDepartmentsHashMap,
	governmentDepartments => Object.keys(governmentDepartments).map(k => governmentDepartments[k])
)

export const selectGovernmentDepartment = id => createSelector(selectGovernmentDepartmentsHashMap, governmentDepartments => governmentDepartments[id])