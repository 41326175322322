/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box, styled } from '@mui/material'
import { useForm } from 'react-hook-form'
import TemplateFormPartial from '../../Listings/Listing/Forms/TemplateFormPartial'
import MessagingFormPartial from '../../Listings/Listing/Forms/MessagingFormPartial'
import OccupationsFormPartial from '../../Listings/Listing/Forms/OccupationsFormPartial'
import ModalContainer from '../ModalContainer'
import {
  hideModal,
  createCampaign,
  updateCampaign,
  saveCampaignTemplateSettings,
  saveCampaignMessagingSettings,
  fetchListingTemplateSettings,
  fetchListingMessagingSettings,
  saveCampaignPicture,
  setCampaignOccupations,
  fetchCampaign,
} from '../../../redux/actions'
import {
  selectListingSettings,
  selectPublishedOccupations,
  selectPublishedOccupationsHierarchy,
  selectPublicAndPublishedOccupations,
  selectPublicAndPublishedOccupationsHierarchy,
  hasPolicy,
} from '../../../redux/selectors'
import { ACCESS_PRIVATE_AFFILIATIONS, EDIT_LISTING_COBRAND_TEMPLATE } from '../../../auth/policies'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import PageOverlay from '../../PageOverlay'

const CampaignTabs = styled(Tabs)({
  backgroundColor: '#222',

  '& .MuiButtonBase-root': {
    color: '#fff',
  },
})

export function CampaignModal({ campaign, campaignSettings, listing }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(true)
  const [currentTab, setCurrentTab] = useState(0)
  const [campaignOccupations, setOccupations] = useState([])
  const [selectedOccupationMappings, setSelectedOccupationMappings] = useState(
    campaign ? campaign.occupationMappings
      : listing ? listing.occupationMappings : [],
  )

  const publishedOccupationsHierarchy = useSelector(selectPublishedOccupationsHierarchy)
  const publishedOccupations = useSelector(selectPublishedOccupations)
  const publicAndPublishedOccupationsHierarchy = useSelector(selectPublicAndPublishedOccupationsHierarchy)
  const publicAndPublishedOccupations = useSelector(selectPublicAndPublishedOccupations)
  const canAccessPrivateAffiliations = useSelector(hasPolicy(ACCESS_PRIVATE_AFFILIATIONS))

  const onCancel = () => dispatch(hideModal())
  const getListingTemplateSettings = () => dispatch(fetchListingTemplateSettings(listing.id))
  const getListingMessagingSettings = () => dispatch(fetchListingMessagingSettings(listing.id))
  const getCampaign = () => dispatch(fetchCampaign(campaign.id))

  const canEditListingCobrandTemplate = useSelector(hasPolicy(EDIT_LISTING_COBRAND_TEMPLATE))

  const getInitialDefaultOfflineRedirect = () => {
    if (campaign && campaign.defaultOfflineRedirectUrl) return campaign.defaultOfflineRedirectUrl
    if (listing && listing.defaultOfflineRedirectUrl) return listing.defaultOfflineRedirectUrl
    return ''
  }

  const getInitialValues = () => {
    let init = null
    if (campaignSettings) {
      init = campaignSettings
      init.name = campaign ? campaign.name : ''
      init.campaignId = campaign ? campaign.id : null
      init.listingId = listing ? listing.id : null
      init.defaultOfflineRedirectUrl = getInitialDefaultOfflineRedirect(listing, campaign)
      return init
    }

    const initialTemplateSettings = useSelector(selectListingSettings(listing.id, 'template'))
    const initialMessagingSettings = useSelector(selectListingSettings(listing.id, 'messaging'))

    if (initialTemplateSettings && initialMessagingSettings) {
      init = Object.assign(initialTemplateSettings, initialMessagingSettings)
      init.campaignId = campaign ? campaign.id : null
      init.name = campaign ? campaign.name : ''
      init.listingId = listing.id
      init.defaultOfflineRedirectUrl = getInitialDefaultOfflineRedirect(listing, campaign)
    }

    return init || null
  }

  const initialValues = getInitialValues()

  const { handleSubmit, reset, ...form } = useForm({
    defaultValues: initialValues,
  })

  const onSubmit = (values) => {
    console.log('values', values)
    const campaignObj = {
      ...campaign, name: values.name, listingId: listing.id, defaultOfflineRedirectUrl: values.defaultOfflineRedirectUrl,
    }
    const campaignPromise = campaignObj && campaignObj.id ? dispatch(updateCampaign(campaignObj)) : dispatch(createCampaign(campaignObj))
    const templateSettings = {}
    const messagingSettings = {}
    const imagePromises = []

    templateSettings.type = values.type
    templateSettings.headerBackgroundColor = values.headerBackgroundColor
    templateSettings.headerMobileBackgroundColor = values.headerMobileBackgroundColor
    templateSettings.headerBackgroundType = values.headerBackgroundType
    templateSettings.headerLogoAlignment = values.headerLogoAlignment
    templateSettings.headerLogoHeight = values.headerLogoHeight > 150 ? 150 : values.headerLogoHeight < 30 ? 30 : values.headerLogoHeight
    templateSettings.headerLogoOrientation = initialValues.headerLogoOrientation
    templateSettings.bodyBackgroundColor = values.bodyBackgroundColor
    templateSettings.bodyBackgroundType = values.bodyBackgroundType
    templateSettings.footerBackgroundType = values.footerBackgroundType
    templateSettings.footerBackgroundColor = values.footerBackgroundColor
    templateSettings.footerGovXLogoColor = values.footerGovXLogoColor
    templateSettings.footerLinkColor = values.footerLinkColor
    templateSettings.footerShowFooterLinks = !!values.footerShowFooterLinks
    templateSettings.footerShowGovXLogo = !!values.footerShowGovXLogo
    templateSettings.headerLogoImageUrl = values.headerLogoImageUrl
    templateSettings.bodyBackgroundImageUrl = values.bodyBackgroundImageUrl

    messagingSettings.welcomeHeadline = values.welcomeHeadline
    messagingSettings.welcomeMessage = values.welcomeMessage
    messagingSettings.registerEmailHint = values.registerEmailHint
    messagingSettings.prequalifierMessage = values.prequalifierMessage
    messagingSettings.showSocialProof = values.showSocialProof
    messagingSettings.termsOfServiceLink = values.termsOfServiceLink
    messagingSettings.privacyPolicyLink = values.privacyPolicyLink

    campaignPromise
      .then((c) => {
        if (typeof templateSettings.headerLogoImageUrl !== 'string') {
          const logoFormData = new window.FormData()
          logoFormData.append('file', templateSettings.headerLogoImageUrl)
          logoFormData.append('targetSize', 200)
          logoFormData.append('seoFilename', 'campaignHeaderLogo')
          imagePromises.push(dispatch(saveCampaignPicture(c.id, 'logo', logoFormData)))
        }
        if (
          typeof templateSettings.bodyBackgroundImageUrl !== 'string'
        && templateSettings.bodyBackgroundImageUrl
        ) {
          const backgroundImageFormData = new window.FormData()
          backgroundImageFormData.append('file', templateSettings.bodyBackgroundImageUrl)
          backgroundImageFormData.append('targetSize', 2000)
          backgroundImageFormData.append('seoFilename', 'campaignBackgroundImage')
          imagePromises.push(dispatch(saveCampaignPicture(c.id, 'background', backgroundImageFormData)))
        } else {
          templateSettings.bodyBackgroundImageUrl = templateSettings.bodyBackgroundImageUrl || ''
        }

        if (imagePromises.length > 0) {
          Promise.all(imagePromises)
            .then((responses) => {
              responses.forEach((r) => {
                if (r.pictureType && r.pictureType === 'logo') {
                  templateSettings.headerLogoImageUrl = r.url
                  templateSettings.headerLogoOrientation = r.orientation
                } else if (r.pictureType && r.pictureType === 'background') {
                  templateSettings.bodyBackgroundImageUrl = r.url
                }
              })
              const p1 = dispatch(saveCampaignTemplateSettings(c.id, templateSettings))
              const p2 = dispatch(saveCampaignMessagingSettings(c.id, messagingSettings))
              const p3 = dispatch(setCampaignOccupations(c.id, campaignOccupations))

              Promise.all([p1, p2, p3])
                .then(() => dispatch(hideModal()))
                .catch(() => dispatch(hideModal()))
            })
            .catch(() => console.log('error saving campaign template images'))
        } else {
          const p1 = dispatch(saveCampaignTemplateSettings(c.id, templateSettings))
          const p2 = dispatch(saveCampaignMessagingSettings(c.id, messagingSettings))
          const p3 = dispatch(setCampaignOccupations(c.id, campaignOccupations))

          Promise.all([p1, p2, p3])
            .then(() => dispatch(hideModal()))
            .catch(() => dispatch(hideModal()))
        }
        dispatch(hideModal())
      })
      .catch(() => dispatch(hideModal()))
  }

  useEffect(() => {
    const getData = async () => {
      if (campaign) {
        await getCampaign(campaign.id)
      }
      if (!campaignSettings) {
        await getListingTemplateSettings()
        await getListingMessagingSettings()
      }
    }
    getData()
    setProcessing(false)
  }, [])

  useEffect(() => {
    reset(initialValues)
  }, [initialValues])

  return (
    <ModalContainer
      processing={processing && initialValues}
      title={campaign ? 'Edit campaign' : 'Create campaign'}
      modal
      maxWidth="md"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
      showScrollAlways
    >
      {!processing && initialValues ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            color: '#222', maxHeight: '70vh', marginTop: '10px', minWidth: 'min-content',
          }}
        >
          <MUIFormTextInput
            control={form.control}
            name="name"
            label="Campaign name"
            autoFocus
            rules={{ required: true }}
          />
          <Box mt="20px">
            <MUIFormTextInput
              control={form.control}
              name="defaultOfflineRedirectUrl"
              label="Your website URL"
              autoFocus={false}
              rules={{ required: true, pattern: { value: /^https?:\/\// } }}
            />
          </Box>
          <div style={{ margin: '30px -24px 0px' }}>
            <CampaignTabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
              <Tab label="Affiliations" />
              <Tab label="Customize display" />
              <Tab label="Customize messaging" />
            </CampaignTabs>
            <div style={{ padding: '30px' }}>
              {currentTab === 0 && (
              <OccupationsFormPartial
                publishedOccupationsHierarchy={publishedOccupationsHierarchy}
                publishedOccupations={publishedOccupations}
                publicAndPublishedOccupationsHierarchy={publicAndPublishedOccupationsHierarchy}
                publicAndPublishedOccupations={publicAndPublishedOccupations}
                canAccessPrivateAffiliations={canAccessPrivateAffiliations}
                selectedOccupationMappings={selectedOccupationMappings}
                setSelectedOccupationMappings={setSelectedOccupationMappings}
                setCampaignOccupations={setOccupations}
              />
              )}
              {currentTab === 1 && (
              <div>
                <TemplateFormPartial
                  canEditListingCobrandTemplate={canEditListingCobrandTemplate}
                  form={form}
                  initialValues={initialValues}
                />
              </div>
              )}
              {currentTab === 2 && (
              <div style={{ marginBottom: '30px' }}>
                <MessagingFormPartial
                  form={form}
                  canEditListingCobrandTemplate={canEditListingCobrandTemplate}
                />
              </div>
              )}
            </div>
          </div>
        </form>
      ) : <PageOverlay />}
    </ModalContainer>
  )
}

export default CampaignModal
