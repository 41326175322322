import { getJson, postJson, deleteJson } from './baseApi'
import {
	removeUtcFromDateTime,
} from '../formatting'

export const fetchVerificationRequests = search => {

	let { expanded, ...timeFrame } = search.filter.timeFrame

	let query = ''
	// Timeframe
	query += 'timeFrame.type=' + timeFrame.type
	if (timeFrame.from) {
		let startDate = removeUtcFromDateTime(timeFrame.from)
		query += '&timeFrame.from=' + encodeURIComponent(startDate.format())
	}
	if (timeFrame.to) {
		let endDate = removeUtcFromDateTime(timeFrame.to)
		query += '&timeFrame.to=' + encodeURIComponent(endDate.format())
	}
	// Authorization methods
	search.filter.authorizationMethods.methods.filter(m => m.selected).forEach((m, i) => {
		query += '&authorizationMethods.methods[' + i +']=' + m.type
	})
	// Statuses
	search.filter.verificationRequests.statuses.filter(r => r.selected).forEach((r, i) => {
		query += '&verificationRequestStatuses.statuses[' + i +']=' + r.type
	})
	// Paging
	query += '&pageSize=' + search.paging.pageSize
	// Listing ID
	query += '&listingId=' + search.filter.listing.id
	// Occupation ID
	query += '&occupationId=' + search.filter.occupation.id
	// Statuses
	search.filter.documentTypes.types.filter(r => r.selected).forEach((r, i) => {
		query += '&documentTypes.types[' + i +']=' + r.type
	})
	// Domain Name
	query += '&domainName=' + search.filter.searchText.text

	return getJson(`/api/verification/requests?${query}`)
}

export const approveVerificationRequest = (verificationRequest, authMethod) => {
	return postJson(`/api/verification/requests/${verificationRequest.id}/approve`, {
		verificationRequestId: verificationRequest.id,
		authMethod
	})
}

export const rejectVerificationRequest = (verificationRequest, message, sendEmail) => {
	return postJson(`/api/verification/requests/${verificationRequest.id}/reject`, {
		verificationRequestId: verificationRequest.id,
		message,
		sendEmail
	})
}

export const retryDMDC = verificationRequest => {
	return postJson(`/api/verification/requests/${verificationRequest.id}/retry-dmdc`, {})
}

export const sendNewActivationCode = verificationRequest => {
	return postJson(`/api/verification/requests/${verificationRequest.id}/sendNewActivationCode`, {})
}

export const createVerificationRequest = (request, approve) => {
	return postJson(`/api/verification/requests?approve=${approve}`, request)
}

export const deleteVerificationRequest = id => {
	return deleteJson(`/api/verification/requests/${id}`)
}

export const fetchUserVerificationRequests = userId => {
	return getJson(`/api/users/${userId}/verificationRequests`)
}

export const fetchDuplicateUserVerificationRequests = userId => {
	return getJson(`/api/users/${userId}/duplicateVerificationRequests`)
}

export const fetchAuthorizationRecords = verificationRequestId => {
	return getJson(`/api/verification/requests/${verificationRequestId}/authorizationrecords`)
}

export const fetchDocScanResults = verificationRequestId => {
	return getJson(`/api/verification/docscan?verificationRequestId=${ verificationRequestId }`)
}