export function fillErrorMessages(errors, fieldName, label, rules) {
  function translateTypeToMessage(type) {
    switch (type) {
      case 'required':
        return `${label || 'This field'} is required`
      case 'maxLength':
        return `The length of ${label || 'this field'} cannot exceed ${rules.maxLength} characters`
      case 'minLength':
        return `The length of ${label || 'this field'} cannot be less than ${rules.minLength} characters`
      case 'max':
        return `${label || 'This field'} cannot be greater than ${rules.max}`
      case 'min':
        return `${label || 'This field'} cannot be less than ${rules.min}`
      default:
        return 'This field is invalid'
    }
  }
  if (errors[fieldName]) {
    const error = errors[fieldName]
    return {
      [fieldName]: {
        ref: error?.ref,
        type: error?.type,
        message:
            error?.message?.length > 0
              ? error.message
              : translateTypeToMessage(error?.type, label),
      },
    }
  }
  return errors
}
