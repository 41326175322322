import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { withStyles } from '@mui/styles'
import Switch from '@mui/material/Switch'
import Button from '../../Button'

const styles = {
    root: {
        fontFamily: 'Roboto Regular,Helvetica,sans-serif',
    },
    actionsTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
    actionSectionContainer: {
        border: '1px solid #cfcfcf',
        padding: '15px',
        margin: '10px 0',
        display: 'flex',
    },
    actionSectionText: {
        width: '70%',
        display: 'inline-block',
    },
    actionSectionTitle: {
        fontWeight: '600',
    },
    actionSectionDescription: {
        marginTop: '10px',
    },
    actionSectionButtonContainer: {
        minWidth: '120px',
        width: '30%',
        display: 'inline-block',
        textAlign: 'right',
        position: 'relative',
    },
    actionSectionButton: {
        minWidth: '120px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0',
    },
    actionSectionButtonWithOptions: {
        minWidth: '120px',
    },
    actionSectionOption: {
        minWidth: '120px',
        position: 'absolute',
        top: '50%',
        xxtransform: 'translateY(-50%)',
        right: '0',
    },
}

const getArgs = (options) => {
    let args = {}
    options.forEach(x => args[x.key] = x.value)
    return args
}

export const ActionSection = ({
    buttonId,
    buttonLabel,
    description,
    classes,
    onClick,
    setSwitchOptions,
    switchOptions,
    title
}) => (

    <div className={classes.actionSectionContainer}>
        <div className={classes.actionSectionText}>
            <div className={classes.actionSectionTitle}>{title}</div>
            <div className={classes.actionSectionDescription}>{description}</div>
        </div>
        <div className={classes.actionSectionButtonContainer}>
            <Button
                className={switchOptions.length ? classes.actionSectionButtonWithOptions : classes.actionSectionButton}
                label={buttonLabel}
                id={buttonId}
                color="primary"
                disabled={false}
                onClick={() => onClick(getArgs(switchOptions))} />
                <br />
            {switchOptions.map(({ key, value, text }) => (
                <div key={key} style={{ display: 'inline-block' }}>
                    <strong>{text}</strong>
                    <Switch
                        value={value}
                        checked={value}
                        onChange={e => {
                            let i = switchOptions.findIndex(x => x.key === key)
                            switchOptions[i] = { ...switchOptions[i], value: e.target.checked }
                            setSwitchOptions([...switchOptions])
                        }}
                    />
                </div>
            ))}
        </div>
    </div>
)

ActionSection.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

const enhance = compose(
    withState('switchOptions', 'setSwitchOptions', ({ options }) => options ? options.filter(x => x.type === 'boolean') : []),
    withStyles(styles),
)

export default enhance(ActionSection)
