import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { generateDelegateAccessToken } from '../../../../../../../redux/modules/partners/listings'
import { Button } from '../../../../../../Button'

const RESULT_RESET = ''
const RESULT_ERROR = 'Could not generate delegate access token.'

const styleInputRow = { margin: '20px 0' }

function DelegateAccessToken({ appId, listingId }) {
  const dispatch = useDispatch()
  const [myState, setMyState] = useState({ result: RESULT_RESET, disabled: false })

  const createToken = () => {
    setMyState({ result: RESULT_RESET, disabled: true })
    const request = ({ listingId })
    dispatch(generateDelegateAccessToken(request)).then((response) => {
      const result = response && response.accessToken
        ? response
        : ({ errors: RESULT_ERROR })
      setMyState({ result: JSON.stringify(result, null, 4), disabled: false })
      return {}
    })
  }
  const { disabled, result } = myState
  return (
    <>
      <div style={styleInputRow}>
        <h4>Delegate Access Token</h4>
      </div>
      <div style={styleInputRow}>
        <TextField variant="outlined" label="App Id" value={appId} disabled />
      </div>
      <div style={styleInputRow}>
        <Button
          label="Create Token"
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={createToken}
        >
          Create Token
        </Button>
      </div>
      <div style={styleInputRow}>
        <pre
          style={{
            borderRadius: '5px',
            background: 'black',
            color: 'yellow',
            padding: '10px',
          }}
        >
          {result}
        </pre>
      </div>
    </>
  )
}

DelegateAccessToken.propTypes = {
  appId: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
}

export default DelegateAccessToken
