import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { bulkImportUsers, hideModal, showSuccesNotification } from '../../../redux/actions'

export function BulkImportUsersModal({ listingId }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)
  const [file, setFile] = useState(null)

  const bulkImport = (formData) => {
    setProcessing(true)
    dispatch(bulkImportUsers(formData)).then((x) => {
      setProcessing(false)
      dispatch(showSuccesNotification(`${x.imported} user(s) imported.`))
      dispatch(hideModal())
    })
      .catch((err) => {
        setProcessing(false)
        console.error('bulk import users failed', err)
      })
  }
  const onCancel = () => dispatch(hideModal())

  const onFileChange = (e) => {
    setFile(e.target.files[0])
  }
  const onImport = () => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('listingId', listingId)

    bulkImport(formData)
  }

  return (
    <ModalContainer
      title="Import Users"
      modal
      maxWidth="xs"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Import',
          onClick: onImport,
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <div style={{ marginTop: 24 }}>
        <input type="file" name="usersImportFile" onChange={onFileChange} />
      </div>
    </ModalContainer>
  )
}

BulkImportUsersModal.propTypes = {
  listingId: PropTypes.number.isRequired,
}

export default BulkImportUsersModal
