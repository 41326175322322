/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Switch from '@mui/material/Switch'
import ListItemText from '@mui/material/ListItemText'
import ArrowDownIcon from '@mui/icons-material/ExpandMore'
import ArrowUpIcon from '@mui/icons-material/ExpandLess'
import { createUseStyles } from 'react-jss'
import TaxonomyListSubheader from '../../../Occupations/Taxonomy/TaxonomyListSubheader'

const isToggled = (selectedOccupationMappings, occupationId) => {
  if (!selectedOccupationMappings || !occupationId) return false

  const matchingOccupationMapping = selectedOccupationMappings.find((x) => x.occupationId === occupationId)

  return matchingOccupationMapping ? matchingOccupationMapping.shownInRegistration : false
}

export const getOccupationDescendants = (parentOccupation) => {
  if (!parentOccupation || !parentOccupation.children || parentOccupation.children.length < 1) return []

  let childrenIds = parentOccupation.children.map((x) => x.id)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < parentOccupation.children.length; i++) {
    const results = getOccupationDescendants(parentOccupation.children[i])
    if (results && results.length > 0) {
      childrenIds = childrenIds.concat(results)
    }
  }
  return childrenIds
}

const useStyles = createUseStyles({
  switchRoot: {
    display: 'inline-block',

    width: 'auto',
    marginTop: '-3px',

  },
  formLabel: {
    width: 'auto',
    fontSize: '14px',
    fontWeight: '700',
    color: 'rgb(171, 171, 171)',

  },
})

export function TaxonomyListItem({
  index,
  occupation,
  depth,
  onCheck,
  onToggle,
  siblings,
  selectedOccupationMappings,
  privateAndPublishedOccupations,
  publicAndPublishedOccupationIds,
  canAccessPrivateAffiliations,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const level = depth
  const hasChildren = occupation.children && occupation.children.length > 0
  const availableChildOccupations = canAccessPrivateAffiliations || !hasChildren
    ? occupation.children
    : occupation.children.filter((x) => publicAndPublishedOccupationIds.includes(x.id))

  let isDisabled = false
  if (!canAccessPrivateAffiliations) {
    if (occupation.private) {
      isDisabled = true
    }
  }
  return (
    <div>
      <TaxonomyListSubheader
        depth={depth}
        index={index}
        level={level}
        occupation={occupation}
        siblings={siblings}
      />
      <ListItem
        key={occupation.id}
        disableGutters
        className={classes.listItem}
        style={{
          marginTop: '-1px',
          width: `calc(100% - ${level * 32}px)`,
          marginLeft: `${level * 32}px`,
          backgroundColor: '#fbfbfb',
          border: '1px solid #cfcfcf',
          cursor: 'default',
          padding: '0',
          minWidth: 'min-content',
        }}
      >
        <ListItemText style={{ width: '100%' }}>
          <Grid container style={{ width: '100%', flexWrap: 'nowrap' }}>
            <Grid item style={{ alignSelf: 'center' }}>
              <Checkbox
                key={occupation.id}
                name={occupation.name}
                checked={
                selectedOccupationMappings
                && (!!selectedOccupationMappings.find((x) => x.occupationId === occupation.id))
              }
                onChange={(e, checked) => onCheck(occupation, checked, onToggle)}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item style={{ alignSelf: 'center' }}>
              <div style={{ display: 'inline-block', fontSize: '0.875rem' }}>{occupation.name}</div>
            </Grid>
            <Grid item style={{ flex: 1 }} />
            <Grid item style={{ marginTop: '2px' }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={isToggled(selectedOccupationMappings, occupation.id)}
                    onChange={(e, toggled) => onToggle(occupation, toggled)}
                    value={isToggled(selectedOccupationMappings, occupation.id)}
                    disabled={
                    !selectedOccupationMappings
                    || !selectedOccupationMappings.find((x) => x.occupationId === occupation.id)
                  }
                  />
       )}
                slotProps={{
                  typography: {
                    sx: { fontSize: '0.875rem' },
                  },
                }}
                label="Show in registration"
                labelPlacement="start"
              />
            </Grid>
            {hasChildren ? (
              <Grid
                item
                style={{
                  alignSelf: 'center', justifySelf: 'flex-end', marginTop: '7px', marginRight: '20px',
                }}
              >
                {expanded && (
                <ArrowUpIcon
                  style={{ fill: '#9b9b9b', marginLeft: '30px', cursor: 'pointer' }}
                  onClick={() => setExpanded(false)}
                />
                )}
                {!expanded && (
                <ArrowDownIcon
                  style={{ fill: '#9b9b9b', marginLeft: '30px', cursor: 'pointer' }}
                  onClick={() => setExpanded(true)}
                />
                )}
              </Grid>
            ) : (
              <Grid item style={{ alignSelf: 'center', justifySelf: 'flex-end' }}>
                <div style={{ width: '44px', marginLeft: '30px' }} />
              </Grid>
            )}
          </Grid>
        </ListItemText>
      </ListItem>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        style={{ width: '100%' }}
      >
        <List component="div" disablePadding style={{ marginBottom: '28px' }}>
          {hasChildren
            ? availableChildOccupations
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((o, i) => (
                <TaxonomyListItem
                  key={o.id}
                  index={i}
                  occupation={o}
                  depth={level + 1}
                  classes={classes}
                  onCheck={onCheck}
                  onToggle={onToggle}
                  siblings={availableChildOccupations}
                  selectedOccupationMappings={selectedOccupationMappings}
                  privateAndPublishedOccupations={privateAndPublishedOccupations}
                  publicAndPublishedOccupationIds={publicAndPublishedOccupationIds}
                  canAccessPrivateAffiliations={canAccessPrivateAffiliations}
                />
              ))
            : []}
        </List>
      </Collapse>
    </div>
  )
}

export default TaxonomyListItem
