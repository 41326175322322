import { normalize } from 'normalizr'
import {createSelector} from 'reselect'
import { verificationRequest, authorizationRecord } from '../../../api/schema'
import * as verificationRequestsApi from '../../../api/verificationRequestsApi'

export const SEARCH_VERIFICATIONREQUESTS_REQUEST = 'SEARCH_VERIFICATIONREQUESTS_REQUEST'
export const SEARCH_VERIFICATIONREQUESTS_SUCCESS = 'SEARCH_VERIFICATIONREQUESTS_SUCCESS'
export const SEARCH_VERIFICATIONREQUESTS_FAILURE = 'SEARCH_VERIFICATIONREQUESTS_FAILURE'

export const CREATE_VERIFICATIONREQUESTS_SEARCH = 'CREATE_VERIFICATIONREQUESTS_SEARCH'
export const UPDATE_VERIFICATIONREQUESTS_SEARCH = 'UPDATE_VERIFICATIONREQUESTS_SEARCH'

export const REJECT_VERIFICATIONREQUEST_REQUEST = 'REJECT_VERIFICATIONREQUEST_REQUEST'
export const REJECT_VERIFICATIONREQUEST_SUCCESS = 'REJECT_VERIFICATIONREQUEST_SUCCESS'
export const REJECT_VERIFICATIONREQUEST_FAILURE = 'REJECT_VERIFICATIONREQUEST_FAILURE'

export const APPROVE_VERIFICATIONREQUEST_REQUEST = 'APPROVE_VERIFICATIONREQUEST_REQUEST'
export const APPROVE_VERIFICATIONREQUEST_SUCCESS = 'APPROVE_VERIFICATIONREQUEST_SUCCESS'
export const APPROVE_VERIFICATIONREQUEST_FAILURE = 'APPROVE_VERIFICATIONREQUEST_FAILURE'

export const FETCH_USER_VERIFICATION_REQUESTS_REQUEST = 'FETCH_USER_VERIFICATION_REQUESTS_REQUEST'
export const FETCH_USER_VERIFICATION_REQUESTS_SUCCESS = 'FETCH_USER_VERIFICATION_REQUESTS_SUCCESS'
export const FETCH_USER_VERIFICATION_REQUESTS_FAILURE = 'FETCH_USER_VERIFICATION_REQUESTS_FAILURE'

export const FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_REQUEST = 'FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_REQUEST'
export const FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_SUCCESS = 'FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_SUCCESS'
export const FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_FAILURE = 'FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_FAILURE'

export const CREATE_OFFLINEVERIFICATIONREQUEST_REQUEST = 'CREATE_OFFLINEVERIFICATIONREQUEST_REQUEST'
export const CREATE_OFFLINEVERIFICATIONREQUEST_SUCCESS = 'CREATE_OFFLINEVERIFICATIONREQUEST_SUCCESS'
export const CREATE_OFFLINEVERIFICATIONREQUEST_FAILURE = 'CREATE_OFFLINEVERIFICATIONREQUEST_FAILURE'

export const DELETE_VERIFICATIONREQUEST_REQUEST = 'DELETE_VERIFICATIONREQUEST_REQUEST'
export const DELETE_VERIFICATIONREQUEST_SUCCESS = 'DELETE_VERIFICATIONREQUEST_SUCCESS'
export const DELETE_VERIFICATIONREQUEST_FAILURE = 'DELETE_VERIFICATIONREQUEST_FAILURE'

export const RETRY_DMDC_REQUEST = 'RETRY_DMDC_REQUEST'
export const RETRY_DMDC_SUCCESS = 'RETRY_DMDC_SUCCESS'
export const RETRY_DMDC_FAILURE = 'RETRY_DMDC_FAILURE'

export const SEND_NEW_ACTIVATION_CODE_REQUEST = 'SEND_NEW_ACTIVATION_CODE_REQUEST'
export const SEND_NEW_ACTIVATION_CODE_SUCCESS = 'SEND_NEW_ACTIVATION_CODE_SUCCESS'
export const SEND_NEW_ACTIVATION_CODE_FAILURE = 'SEND_NEW_ACTIVATION_CODE_FAILURE'

export const FETCH_SCANRESULTS_REQUEST = 'FETCH_SCANRESULTS_REQUEST'
export const FETCH_SCANRESULTS_SUCCESS = 'FETCH_SCANRESULTS_SUCCESS'
export const FETCH_SCANRESULTS_FAILURE = 'FETCH_SCANRESULTS_FAILURE'

const verificationRequestResponseSchema = { verificationRequest, authorizationRecord }

export const fetchUserVerificationRequests = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_USER_VERIFICATION_REQUESTS_REQUEST })
	return verificationRequestsApi.fetchUserVerificationRequests(userId)
		.then(response => {
			const data = normalize(response, [schema.verificationRequest])
			dispatch({ type: FETCH_USER_VERIFICATION_REQUESTS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_USER_VERIFICATION_REQUESTS_FAILURE, err })
			throw err
		})
}

export const fetchDuplicateUserVerificationRequests = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_REQUEST })
	return verificationRequestsApi.fetchDuplicateUserVerificationRequests(userId)
		.then(response => {
			const data = normalize(response, [schema.verificationRequest])
			dispatch({ type: FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_FAILURE, err })
			throw err
		})
}

export const createVerificationRequestsSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_VERIFICATIONREQUESTS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const updateVerificationRequestsSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_VERIFICATIONREQUESTS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const searchVerificationRequests = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_VERIFICATIONREQUESTS_REQUEST })
	let search = selectVerificationRequestSearch(searchId)(getState())
	return verificationRequestsApi.fetchVerificationRequests(search)
		.then(response => {
			const data = normalize(response.data, [schema.verificationRequest])
			dispatch({ type: SEARCH_VERIFICATIONREQUESTS_SUCCESS, data, total: response.total, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_VERIFICATIONREQUESTS_FAILURE, err })
			throw err
		})
}

export const approveVerificationRequest  = (verificationRequest, authMethod) => (dispatch, getState, { schema }) => {
	dispatch({ type: APPROVE_VERIFICATIONREQUEST_REQUEST })
	return verificationRequestsApi.approveVerificationRequest(verificationRequest, authMethod)
		.then(response => {
			const data = normalize(response, verificationRequestResponseSchema)
			dispatch({ type: APPROVE_VERIFICATIONREQUEST_SUCCESS, data })
			return dispatch(fetchUserVerificationRequests(verificationRequest.userId))
				.then(fetchResponse => {
					return response
				})
		})
		.catch(err => {
			dispatch({ type: APPROVE_VERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const rejectVerificationRequest = (verificationRequest, message, sendEmail) => (dispatch, getState, { schema }) => {
	dispatch({ type: REJECT_VERIFICATIONREQUEST_REQUEST })
	return verificationRequestsApi.rejectVerificationRequest(verificationRequest, message, sendEmail)
		.then(response => {
			const data = normalize(response, verificationRequestResponseSchema)
			dispatch({ type: REJECT_VERIFICATIONREQUEST_SUCCESS, data, message, sendEmail })
			return response
		})
		.catch(err => {
			dispatch({ type: REJECT_VERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const deleteVerificationRequest = id => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_VERIFICATIONREQUEST_REQUEST })
	return verificationRequestsApi.deleteVerificationRequest(id)
		.then(response => {
			const data = normalize(response, verificationRequestResponseSchema)
			dispatch({ type: DELETE_VERIFICATIONREQUEST_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_VERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const createOfflineVerificationRequest = (occupationId, user, metadata, listingName) => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_OFFLINEVERIFICATIONREQUEST_REQUEST })

	return verificationRequestsApi.createVerificationRequest({
		occupationId,
		userId: user.id,
		firstName: metadata.find(m => m.key === 'first-name') ? metadata.find(m => m.key === 'first-name').value : user.firstName,
		lastName: metadata.find(m => m.key === 'last-name') ? metadata.find(m => m.key === 'last-name').value : user.firstName,
		email: user.email,
		metadata,
		type: 'Offline',
		listingId: !listingName || listingName == 'govx' ? 1 : 55
	}, true)
	.then(response => {
		const data = normalize(response, verificationRequestResponseSchema)
		dispatch({ type: CREATE_OFFLINEVERIFICATIONREQUEST_SUCCESS, data })
		return dispatch(fetchUserVerificationRequests(user.id))
			.then(fetchResponse => {
				return response
			})
	})
	.catch(err => {
		dispatch({ type: CREATE_OFFLINEVERIFICATIONREQUEST_FAILURE, err })
		throw err
	})
}

export const retryDMDC = verificationRequest => (dispatch, getState, { schema }) => {
	dispatch({ type: RETRY_DMDC_REQUEST })
	return verificationRequestsApi.retryDMDC(verificationRequest)
		.then(response => {
			const data = normalize(response, verificationRequestResponseSchema)
			dispatch({ type:RETRY_DMDC_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: RETRY_DMDC_FAILURE, err })
			throw err
		})
}

export const sendNewActivationCode = verificationRequest => (dispatch, getState, { schema }) => {
	dispatch({ type: SEND_NEW_ACTIVATION_CODE_REQUEST })
	return verificationRequestsApi.sendNewActivationCode(verificationRequest)
		.then(response => {
			const data = response
			dispatch({ type:SEND_NEW_ACTIVATION_CODE_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: SEND_NEW_ACTIVATION_CODE_FAILURE, err })
			throw err
		})
}

export const fetchScanResults = verificationRequestId => dispatch => {
	dispatch({ type: FETCH_SCANRESULTS_REQUEST, verificationRequestId })
	return verificationRequestsApi.fetchDocScanResults(verificationRequestId)
		.then(response => {
			dispatch({ type: FETCH_SCANRESULTS_FAILURE, response })
			return response
		})
		.catch(err => {
			dispatch({ type:  FETCH_SCANRESULTS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'verificationRequests'

export const DEFAULT_STATE = {
	db: {},
	searches: {}
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result
		}
	}

	let db = {
		...state.db,
		...action.data.entities.verificationRequests
	}

	return { ...state, db, searches }
}

export const handleVerificationRequestUpdate = (state, action) => {
	let updated = action.data.entities.verificationRequests[action.data.result.verificationRequest]

	let db = {
		...state.db,
		[updated.id]: updated
	}

	return { ...state, db }
}

export const handleRemove = (state, action) => {}

export const mergeVerificationRequests = (state, action) => {
	let db = {
		...state.db,
		...action.data.entities.verificationRequests
	}

	return { ...state, db }
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type){
		case CREATE_VERIFICATIONREQUESTS_SEARCH:
		case UPDATE_VERIFICATIONREQUESTS_SEARCH:
			return handleSearchChange(state, action)
		case SEARCH_VERIFICATIONREQUESTS_SUCCESS:
			return handleSearchSuccess(state, action)
		case REJECT_VERIFICATIONREQUEST_SUCCESS:
		case APPROVE_VERIFICATIONREQUEST_SUCCESS:
		case CREATE_OFFLINEVERIFICATIONREQUEST_SUCCESS:
		case DELETE_VERIFICATIONREQUEST_SUCCESS:
		case RETRY_DMDC_SUCCESS:
			return handleVerificationRequestUpdate(state, action)
		case FETCH_USER_VERIFICATION_REQUESTS_SUCCESS:
		case FETCH_DUPLICATE_USER_VERIFICATION_REQUESTS_SUCCESS:
			return mergeVerificationRequests(state, action)
		case SEND_NEW_ACTIVATION_CODE_SUCCESS:
		default:
			return { ...state }
	}
}

export default reducer

export const selectVerificationRequestsHashMap = state => state.verification.verificationRequests.db
export const selectVerificationRequestSearchesHashMap = state => state.verification.verificationRequests.searches

export const selectVerificationRequests = createSelector(
	selectVerificationRequestsHashMap,
	requests => Object.keys(requests).map(k => requests[k])
)

export const selectVerificationRequestSearches = createSelector(
	state => state.verification.verificationRequests.searches,
	searches => Object.keys(searches).map(k => searches[k])
)

export const createFilteredVerificationRequestsSelector = id => {
	return createSelector(
		[selectVerificationRequestsHashMap, selectVerificationRequestSearchesHashMap],
		(requests, searches) => {
			if (!searches[id] || !searches[id].result)
				return []
			let results = searches[id].result.map(f => requests[f]).filter(r => r)
			return results
		})
}

export const selectVerificationRequestSearch = id => state => state.verification.verificationRequests.searches[id]
export const selectVerificationRequest = id => state => state.verification.verificationRequests.db[id]
