import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import {
  hideModal, createVerificationLink, showSuccesNotification, updateVerificationLink,
} from '../../../redux/actions'
import DialogSubsectionHeadline from '../../Header/DialogSubsectionHeadline'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUIFormTextAreaInput } from '../../@Common/Form/TextAreaInput/FormTextAreaInput'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'
import MUIFormRadioGroupInput from '../../@Common/Form/RadioGroupInput/FormRadioGroupInput'

export function VerificationLinkModal({
  listing,
  verificationLink,
}) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)

  const getInitialValues = () => {
    const init = { ...verificationLink }

    if (verificationLink) {
      init.scopeDemographics = verificationLink.scope.includes('user_demographics')
      init.scopeVerification = verificationLink.scope.includes('verification')
      init.display = verificationLink.display
      init.goTo = verificationLink.goTo
      init.grantType = verificationLink.grantType
    } else {
      init.grantType = 'token'
      init.display = 'full'
      init.goTo = 'register'
      init.scope_userprofile = true
    }

    return init
  }

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, reset, control } = useForm()

  const [intitialValues] = useState(getInitialValues())

  useEffect(() => {
    reset(intitialValues)
  }, [intitialValues])

  const onSubmit = (values) => {
    setProcessing(true)

    const verificationLinkObj = {
      name: values.name,
      description: values.description,
      redirectUri: values.redirectUri,
      display: values.display,
      goTo: values.goTo,
      grantType: values.grantType,
      scope: 'user_profile',
      listingId: listing.id,
    }

    if (values.scopeDemographics) verificationLinkObj.scope += ' user_demographics'
    if (values.scopeVerification) verificationLinkObj.scope += ' verification'

    if (verificationLink) {
      dispatch(updateVerificationLink({ ...verificationLink, ...verificationLinkObj }))
        .then(() => {
          dispatch(showSuccesNotification('Link updated successfully'))
          setProcessing(false)
          dispatch(hideModal())
        })
        .catch(() => setProcessing(false))
    } else {
      dispatch(createVerificationLink(verificationLinkObj))
        .then(() => {
          dispatch(showSuccesNotification('Link created successfully'))
          setProcessing(false)
          dispatch(hideModal())
        })
        .catch(() => setProcessing(false))
    }
  }

  return (
    <ModalContainer
      title={verificationLink ? 'Edit verification link' : 'Create verification link'}
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '10px' }}>
        <MUIFormTextInput
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          label="Name"
        />
        <div style={{ padding: '30px 0px 20px' }}>
          <MUIFormTextAreaInput
            name="description"
            control={control}
            rules={{
              required: true,
            }}
            label="Description"
            maxLength="100"
            rows={2}
          />
        </div>
        <MUIFormTextInput
          name="redirectUri"
          control={control}
          rules={{
            required: true,
          }}
          label="Redirect destination"
        />

        <MUIFormRadioGroupInput
          name="display"
          control={control}
          rules={{
            required: true,
          }}
          label="How should the verification form display?"
          items={[
            { value: 'full', key: 'full', label: 'Full page' },
            { value: 'popup', key: 'popup', label: 'Pop-up' },
          ]}
        />

        <MUIFormRadioGroupInput
          name="goTo"
          control={control}
          rules={{
            required: true,
          }}
          label="What should the default start page be?"
          items={[
            { value: 'register', key: 'register', label: 'Register (for new users)' },
            { value: 'login', key: 'login', label: 'Login (for returning users)' },
          ]}
        />

        <Box sx={{ mt: '25px' }}>
          <DialogSubsectionHeadline>What data should the partner be able to access?</DialogSubsectionHeadline>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MUIFormCheckboxInput
            name="scope_userprofile"
            label="User profile (REQUIRED)"
            control={control}
            disabled
            reducedPadding
          />
          <Typography style={{ margin: '0px' }}>
            <small>Includes first name, last name, and email</small>
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MUIFormCheckboxInput
            name="scopeDemographics"
            label="Demographics"
            control={control}
            reducedPadding
          />
          <Typography style={{ margin: '0px' }}>
            <small>Typically includes gender, date of birth, and zip</small>
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MUIFormCheckboxInput
            name="scopeVerification"
            label="Affiliations"
            control={control}
            reducedPadding
          />
          <Typography style={{ margin: '0px' }}>
            <small>Includes the customer's affiliation, for example Military / Army / Veteran</small>
          </Typography>
        </div>

        <MUIFormRadioGroupInput
          name="grantType"
          control={control}
          rules={{
            required: true,
          }}
          label="What is the grant type?"
          items={[
            {
              value: 'code', key: 'code', label: 'Code', helperText: 'An authorization code is a short-lived token representing the user\'s access grant, created by the authorization server and passed to the client application via the browser. The client application sends the authorization code to the authorization server to obtain an access token.',
            },
            {
              value: 'token', key: 'token', label: 'Token', helperText: 'The access token is used by the client to make authenticated requests on behalf of the end user. It has a longer lifetime than the authorization code, typically on the order of minutes or hours. When the access token expires, attempts to use it will fail.',
            },
          ]}
        />
      </form>
    </ModalContainer>
  )
}

VerificationLinkModal.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  verificationLink: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    redirectUri: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    goTo: PropTypes.string.isRequired,
    grantType: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
  }),
}

export default VerificationLinkModal
