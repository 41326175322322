import React, { useState } from 'react'
import classNames from 'classnames'
import MUITextField from '@mui/material/TextField'

export function TextAreaInput({
  label,
  name,
  id,
  disabled = false,
  value = '',
  onChange,
  invalid = false,
  focused = false,
  rows = 4,
  gxid,
}) {
  const [isFocused, setIsFocused] = useState(focused)
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames(
          invalid ? 'text-red' : '',
          isFocused || value.length > 0 ? 'top-2 text-xs' : 'top-4 text-base',
          isFocused ? 'text-blue-dark' : 'text-gray-500',
          'transition-all block absolute left-3 z-10 text-base',
        )}
      >
        {label}
      </label>
      <div className="mt-2">
        <textarea
          rows={rows}
          name={name}
          id={id}
          className={classNames(
            invalid ? 'border-b-red-600' : 'focus:border-b-blue-dark',
            disabled ? 'bg-gray-100' : 'bg-white',
            'block w-full text-base rounded-t border-b pt-6 pb-2 pl-3 pr-10 text-left text-gray-900 border-x border-x-gray-200 border-t border-t-gray-200 focus:outline-none border-gray-600 focus:border-b-2',
          )}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.currentTarget.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          gxid={gxid}
        />
      </div>
    </div>
  )
}

export function MUITextAreaInput({
  label,
  name,
  id,
  disabled = false,
  value = '',
  onChange,
  invalid = false,
  focused = false,
  rows = 4,
  maxLength,
  shrinkLabel = false,
  inputLabelProps,
  gxid,
}) {
  return (
    <div>
      <MUITextField
        variant='filled'
        size='medium'
        style={{ fontSize: '14px' }}
        name={name}
        id={id}
        label={label}
        hiddenLabel={!label}
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => onChange(e.currentTarget.value)}
        error={invalid}
        // focused={focused}
        fullWidth
        inputProps={{
          'data-gxid': gxid,
          maxLength: maxLength,
          style: {
            resize: 'vertical',
            minHeight: label ? '37px' : '124px',
            padding: '25px 12px 25px',
          },
        }}
        InputLabelProps={shrinkLabel ? { ...inputLabelProps, shrink: shrinkLabel } : inputLabelProps}
        InputProps={{
          disableUnderline: true,
          style: {
            lineHeight: '18px',
          },
        }}
        multiline
        rows={rows}
      />
    </div>
  )
}
