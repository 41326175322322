import createStore from './redux/index'
import { setConfiguration } from './setup/configuration'
import { loadFontFaceObserver } from './setup'
import './css/tailwind.css'
import './css/base.css'
import applicationInsights from './applicationInsights'

const config = setConfiguration(window.__GOVX_CONFIGURATION__)
delete window.__GOVX_CONFIGURATION__

applicationInsights.init(config.appInsights.ConnectionString)

;(function() {
	loadFontFaceObserver()
})()

let store = createStore()

export const init = () => {
	return new Promise((resolve, reject) => {
		// do some async task
		resolve({ store })
	})
}

export const refresh = () => {
	return new Promise((resolve, reject) => {
		// do some async task
		resolve({ store })
	})
}
