import React from 'react'
import { RouteConstants } from '../../setup/routeConstants'
import { ACCESS_USER_LIST_PAGE } from '../../auth/policies'
import LazyProtectedRoute from '../LazyProtectedRoute'

const UsersPage = React.lazy(() => import('../../components/Users'))

export default {
	path: RouteConstants.users.index.path,
	element: (
		<LazyProtectedRoute
			requiredPolicies={[ACCESS_USER_LIST_PAGE]}
			component={<UsersPage />} 
		/>
	),
}