import { Box } from '@mui/material'
import React from 'react'
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import Button from '../../Button'

function RowSelectionFooter({
  rows,
  onClickSubmit,
  onClickSubmitCustom,
  onClickCancel,
  submitText,
  customButtonText = null,
}) {
  return (
    <GridFooterContainer sx={{ alignItems: 'right' }}>
      {rows.length > 0
        && (
          <Box sx={{ display: 'flex', backgroundColor: 'rgba(0,0,0,0.04)' }} justifyContent="space-between">
            <Box
              sx={{
                padding: '20px', display: 'flex', alignItems: 'center', fontWeight: 'bold',
              }}
              justifyContent="center"
            >
              {rows.length}
              {' '}
              rows selected
            </Box>
            <Box sx={{ padding: '20px' }}>
              <Button
                onClick={() => onClickCancel()}
                sx={{ margin: 'auto', marginRight: '10px' }}
                label="Cancel"
                variant="text"
                gxid="btnFooterCancel"
              />
              <Button
                onClick={() => onClickSubmit(rows)}
                label={submitText}
                variant="contained"
                color="primary"
                gxid="btnFooterSubmit"
              />
              {
                customButtonText
                && (
                  <Button
                    onClick={() => onClickSubmitCustom(rows)}
                    sx={{ marginLeft: '10px' }}
                    label={customButtonText}
                    variant="contained"
                    color="secondary"
                    gxid="btnFooterCustom"
                  />
                )
              }
            </Box>
          </Box>
        )}
      <Box>
        <GridFooter sx={{
          border: 'none',
        }}
        />
      </Box>
    </GridFooterContainer>
  )
}

RowSelectionFooter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.string),
  onClickSubmit: PropTypes.func,
  onClickSubmitCustom: PropTypes.func,
  onClickCancel: PropTypes.func,
  submitText: PropTypes.string,
  customButtonText: PropTypes.string,
}

export default RowSelectionFooter
