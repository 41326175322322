import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, createWhitelistedEmailDomains, searchWhitelistEmailDomains } from '../../../redux/actions'
import { selectPublishedOccupationsHierarchy } from '../../../redux/selectors'
import DialogSubsectionHeadline from '../../Header/DialogSubsectionHeadline'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'
import { MUIFormCheckboxInput } from '../../@Common/Form/CheckboxInput/FormCheckboxInput'

export function AddWhitelistedDomainModal() {
  const dispatch = useDispatch()

  const occupations = useSelector(selectPublishedOccupationsHierarchy)

  const onSave = (values) => {
    dispatch(createWhitelistedEmailDomains(
      values.domainName.toLowerCase().trim(),
      values.selectedOccupations
        .map((itemEnabled, i) => (itemEnabled ? occupations[i].id : null))
        .filter((item) => item !== null),
    ))
      .then(() => {
        dispatch(searchWhitelistEmailDomains('whitelist-queue'))
        dispatch(hideModal())
      })
  }
  const onCancel = () => dispatch(hideModal())

  const {
    handleSubmit, control, reset, getValues,
  } = useForm({
    defaultValues: {
      domainName: '',
      selectedOccupations: occupations.map(() => false),
    },
  })

  useEffect(() => {
    reset({
      domainName: '',
      selectedOccupations: occupations.map(() => false),
    })
  }, [occupations, reset])

  const validateOccupations = () => {
    const values = getValues(occupations.map((o, i) => `selectedOccupations.${i}`))
    const isValid = values.some((v) => v)
    return isValid || 'Please select at least one occupation.'
  }

  return (
    <ModalContainer
      title="Add Domain"
      modal
      maxWidth="sm"
      fullWidth="false"
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSave),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <MUIFormTextInput
        control={control}
        name="domainName"
        label="Enter domain name"
        rules={{
          required: 'Please enter a domain name.',
          pattern: {
            value: /^((?!www|http|https)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
            message: 'Please enter a valid domain name.',
          },
        }}
        autoFocus
      />
      <p className="text-muted" style={{ marginTop: '3px', lineHeight: '14px' }}>
        <small>
          Only enter the domain name in the field above.
          <br />
          Example: govx.com or google.com &mdash; not www,
          https://, http://, etc.
        </small>
      </p>
      <div><DialogSubsectionHeadline>Approves which affiliations?</DialogSubsectionHeadline></div>
      <Grid container direction="column">
        {occupations.map((o, i) => (
          <Grid item key={o.name}>
            <MUIFormCheckboxInput
              key={o.name}
              control={control}
              name={`selectedOccupations.${i}`}
              label={o.name}
              rules={{
                validate: validateOccupations,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </ModalContainer>
  )
}

export default AddWhitelistedDomainModal
