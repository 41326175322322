import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-extraneous-dependencies
import { api } from '../../redux/slices/apiSlice'

export const codePoolsApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getCodePoolLookups: build.query({
      query: () => '/offers/codepools/lookups',
    }),
    getCodePools: build.query({
      query: () => ({
        url: 'offers/codepools',
        method: 'GET',
      }),
      providesTags: ['CodePools'],
      transformResponse: (response) => ({
        ...response,
        paging: {
          page: Number(response.paging.page),
          pageSize: Number(response.paging.pageSize),
        },
      }),
    }),
    createCodePool: build.mutation({
      query: (body) => ({
        url: 'offers/codepool',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CodePools'],
    }),
    updateCodePool: build.mutation({
      query: ({ id, body }) => ({
        url: `offers/codepool/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CodePools'],
    }),
    updateCodePoolPublishedStatus: build.mutation({
      query: ({ id, body }) => ({
        url: `offers/codepool/${id}/status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CodePools'],
    }),
    deleteCodePool: build.mutation({
      query: (id) => ({
        url: `offers/codepool/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CodePools'],
    }),
    deleteCodes: build.mutation({
      query: ({ body }) => ({
        url: 'offers/codepool/codes/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CodePools'],
    }),
    importCodes: build.mutation({
      query: ({ id, body }) => {
        const formBody = new FormData()
        formBody.append('importFile', body)
        formBody.append('codePoolId', id)
        return {
          url: 'offers/codepools/import-codes',
          method: 'POST',
          body: formBody,
          formData: true,
        }
      },
      invalidatesTags: ['CodePools'],
    }),
    getImportStatus: build.query({
      query: (codePoolId) => ({
        url: `offers/codepools/${codePoolId}/imports-requests`,
        method: 'GET',
      }),
    }),
    exportCodes: build.mutation({
      query: (codePoolId) => ({
        url: `offers/codepool/${codePoolId}/export`,
        method: 'POST',
      }),
    }),
    getExportStatus: build.query({
      query: (codePoolId) => ({
        url: `offers/codepool/${codePoolId}/export-requests`,
        method: 'GET',
      }),
    }),
    getExportDownloadLink: build.query({
      query: (exportRequestId) => ({
        url: `offers/codepool/export/${exportRequestId}/download-link`,
        method: 'GET',
      }),
    }),
    searchCodes: build.mutation({
      query: (body) => ({
        url: 'offers/codepools/codesearch',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => {
        const codesData = {
          continuationToken: response.continuationToken,
          total: response.total,
          data: response.data.map((x) => ({
            id: x.id,
            code: x.value,
            createdOnUtc: x.createdOnUtc,
            status: x.status,
            claimedOnUtc: x.reservation ? x.reservation.reservedOnUtc : null,
            user: x.reservation ? x.reservation.userId : null,
          })),
        }
        return codesData
      },
    }),
    getCodePoolById: build.query({
      query: (id) => `offers/codepool/${id}`,
      providesTags: ['CodePools'],
      method: 'GET',
    }),
    getCodePoolsByPartnerId: build.query({
      query: (partnerId) => `offers/codepools/${partnerId}`,
      providesTags: ['CodePoolsByPartner'],
      method: 'GET',
    }),
  }),
})

const initialState = []
const codePoolsSlice = createSlice({
  name: 'codePools',
  initialState,
  reducers: {
  },
})

export const {
  useGetCodePoolLookupsQuery,
  useGetCodePoolsQuery,
  useGetCodePoolByIdQuery,
  useCreateCodePoolMutation,
  useUpdateCodePoolMutation,
  useUpdateCodePoolPublishedStatusMutation,
  useImportCodesMutation,
  useSearchCodesMutation,
  useLazyGetCodePoolsByPartnerIdQuery,
  useDeleteCodePoolMutation,
  useDeleteCodesMutation,
  useExportCodesMutation,
  useLazyGetExportStatusQuery,
  useLazyGetImportStatusQuery,
  useLazyGetExportDownloadLinkQuery,
} = codePoolsApiSlice

export default codePoolsSlice.reducer
