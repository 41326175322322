import { createTheme } from '@mui/material/styles'

const placeholder = {
	color: '#222',
}
export default createTheme({
	fontFamily: '"Roboto Regular", Helvetica, sans-serif',
	typography: {
		useNextVariants: true,
		fontFamily: ['Roboto Regular', 'Helvetica', 'sans-serif'].join(','),
		fontSize: 14,
		h3: {
			fontSize: '16px',
			marginTop: '20px',
		},
		body2: {
			margin: '10px 0',
		},
	},
	colors: {
		lightBlue: '#00a1df',
		red: '#bd2326'
	},
	palette: {
		primary: {
			main: '#0E5777',
		},
		accent1Color: '#cf5928',
		background: {
			default: '#fff',
		},
	},
	components: {
		MuiAlert: {
			styleOverrides: {
				standard: {
					color: '#FFF',
					fontFamily: "'Roboto Regular', Helvetica, sans-serif;",
					fontWeight: 400,
					fontSize: '0.875rem',
					'& .MuiAlert-icon': { color: '#FFF' }
				},
				standardSuccess: {
					backgroundColor: '#1F8623',
				},
				standardError: {
					backgroundColor: '#BD2326',
				},
			}
		},
		MuiPickersToolbar: {
			styleOverrides: {
				toolbar: {
					backgroundColor: '#f3f3f3',
					color: 'black',
				},
				button: {
					backgroundColor: '#f3f3f3',
					color: 'black',
				},
			}
		},
		MuiPickersCalendarHeader: {
			styleOverrides: {
				switchHeader: {
					// backgroundColor: lightBlue.A200,
					color: 'black',
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					'&.Mui-active': {
						color: '#00a1df',
					},
					'&.Mui-completed': {
						color: '#00a1df',
					},
				},
				text: {
					fill: 'white',
				},
			},
		},
		MuiSnackbar: {},
		MuiSwitch: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						transform: 'translateX(14px)',
						'& + $bar': {
							backgroundColor: '#0E5777',
							opacity: 1.0,
						},
					},
					'& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
						backgroundColor: '#0E5777',
						opacity: 1.0,
					},
					'& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
						color: '#FFF'
					},
					'& span.MuiSwitch-switchBase.Mui-checked.Mui-disabled + span.MuiSwitch-track': {
						backgroundColor: '#DDD'
					}
				},
				iconChecked: {
					backgroundColor: '#0E5777',
				},
				colorPrimary: {
					'&.Mui-checked': {
						color: '#0E5777',
						'& + $bar': {
							backgroundColor: '#0E5777',
						},
					},
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: '#0E5777',
						'& + $bar': {
							backgroundColor: '#0E5777',
							opacity: 1.0,
						},
					},
				},
				bar: {
					backgroundColor: 'black',
					opacity: 0.12,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'&.Mui-disabled.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
						backgroundColor: 'white'
					},
				},
				select: {
					borderBottom: 'none',
					'&:focus': {
						background: 'transparent',
						borderBottom: '1px solid #9B9B9B',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					lineHeight: 1.5,
					letterSpacing: '0.2px'
				},
				textPrimary: {
					color: 'rgba(0, 0, 0, 0.87)',
					padding: '8px 16px',
					fontWeight: '600',
					minHeight: '36px',
					minWidth: '64px',
					borderRadius: '4px',
					'&:hover': {
						backgroundColor: '#DEDEDE',
						transition: 'opacity .25s ease-in, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
						textDecoration: 'none',
						opacity: 1,
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
					},
					'&active': {
						backgroundColor: '#A5A5A5',
					}
				},
				containedPrimary: {
					backgroundColor: '#0E5777',
					fontFamily: 'Roboto Medium, Helvetica, sans-serif',
					fontWeight: 600,
					fontSize: '0.875rem',
					letterSpacing: '.2px',
					color: '#FFFFFF',
					boxShadow: `0px 1px 5px 0px rgb(0 0 0 / 20%), 
						0px 2px 2px 0px rgb(0 0 0 / 14%), 
						0px 3px 1px -2px rgb(0 0 0 / 12%)`,
					minHeight: '36px',
					minWidth: '64px',
					padding: '8px 16px',
					borderRadius: '4px',
					transition: 'opacity .25s ease-in, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					'&.Mui-disabled': {
						backgroundColor: '#E0E0E0',
						boxShadow: 'none',
						color: '#FFFFFF',
					},
					'&:hover': {
						backgroundColor: '#0E5777',
						transition: 'opacity .15s ease-in',
						opacity: 0.5,
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
					},
					'&active': {
						backgroundColor: '#ABC4D0',
						boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 
							0px 8px 10px 1px rgb(0 0 0 / 14%), 
							0px 3px 14px 2px rgb(0 0 0 / 12%`,
					},
				},
				containedSecondary: {
					minHeight: '36px',
					minWidth: '64px',
					padding: '8px 16px',
					backgroundColor: '#CF5944',
					fontWeight: 600,
					fontSize: '0.875rem',
					color: 'white',
					'&:hover': {
						backgroundColor: '#CF5944',
						transition: 'opacity .15s ease-in',
						opacity: 0.5,
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
					},
				},
				outlinedPrimary: {
					border: '1px solid #0E5777',
					color: '#0E5777',
					'&:hover': {
						backgroundColor: '#0E5777',
						color: '#FFF',
						transition: 'opacity .25s ease-in, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
						textDecoration: 'none',
						opacity: 1,
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
						border: '1px solid #0E5777',
					},
					'&active': {
						backgroundColor: '#A5A5A5',
					}
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: '#9B9B9B',
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					border: 'solid 1px #e3e3e3',
					backgroundColor: '#fff',
				},
				"&.Mui-focused": {
					backgroundColor: '#fff !important'
				},
				underline: {
					'&:before': {
						bottom: '-1px',
					},
					'&:after': {
						bottom: '-1px',
						borderBottomColor: '#0e5777'
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					border: 'solid 1px #e3e3e3',
					backgroundColor: '#fff'
				},
				input: {
					'&::-webkit-input-placeholder': placeholder,
					'&::-moz-placeholder': placeholder, // Firefox 19+
					'&:-ms-input-placeholder': placeholder, // IE 11
					'&::-ms-input-placeholder': placeholder,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					lineHeight: 'normal',
					border: 'solid 1px #e3e3e3'
				},
				input: {
					paddingLeft: '5px'

				},
				underline: {
					'&:after': {
						backgroundColor: '#214292',
					},
				},
				multiline: {
					padding: '6px 0 3px 0 !important'
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				"&.Mui-disabled": {
					color: '#222',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						color: '#0e5777',
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {},
				"&.Mui-focused": {
					color: '#00A1DF',
				},
				"&.Mui-disabled": {
					color: '#C1C1C1',
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					padding: '2px 12px 3px',

					'&.Mui-checked': {
						color: '#0E5777',
					},
					'&.Mui-disabled': {
						color: 'rgba(0, 0, 0, 0.26)',
					},
				},

				colorPrimary: {
					'&.Mui-checked': {
						color: '#0E5777',
					},
					'&.Mui-disabled': {
						color: 'rgba(0, 0, 0, 0.26)',
					},
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: '#0E5777',
					},
					'&.Mui-disabled': {
						color: 'rgba(0, 0, 0, 0.26)',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: '#0E5777',
					},
					'&.Mui-checked.Mui-disabled': {
						color: 'rgba(0, 0, 0, 0.26)'
					}
				},

				colorPrimary: {
					'&.Mui-checked': {
						color: '#0E5777',
					},
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: '#0E5777',
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: 'black',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: 'inherit',
					opacity: '0.7',
					padding: '0 36px',
					minWidth: '160px',
					fontWeight: '400',
					fontSize: '13px',
					lineHeight: '1.25',
					color: '#808080',

					'&.Mui-selected': {
						color: 'rgba(0, 0, 0, 0.87)',
						opacity: '1',
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					minHeight: '25px',
				},

				label: {
					margin: '0px',
				},
			},
		},

		MuiCircularProgress: {
			styleOverrides: {
				colorPrimary: {
					color: '#00a1df',
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					border: '1px solid rgb(204, 204, 204)',
					borderRadius: '2px',
					borderCollapse: 'inherit',
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:last-child > td': {
						/*MuiTableCell: {
							root: {
								borderBottom: 'none',
							},
						},*/
						borderBottom: 'none',
					},
					'&.Mui-selected&:hover': {
						backgroundColor: '#ABC4D0',
						boxShadow: `px 5px 5px -3px rgb(0 0 0 / 20%),
						0px 8px 10px 1px rgb(0 0 0 / 14%), 
						0px 3px 14px 2px rgb(0 0 0 / 12%)`
					},
					'&.Mui-selected': {
						backgroundColor: '#ABC4D0',
						boxShadow: `px 5px 5px -3px rgb(0 0 0 / 20%),
						0px 8px 10px 1px rgb(0 0 0 / 14%), 
						0px 3px 14px 2px rgb(0 0 0 / 12%)`
					}
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				actions: {
					color: 'rgba(0, 0, 0, 0.87)',
				},
				caption: {
					color: 'rgba(0, 0, 0, 0.87)',
					fontSize: '0.8125rem',
					fontWeight: '400',
					marginLeft: '25px',
					marginRight: '5px',
				},
				input: {
					border: '0px',
				},
				select: {
					color: 'rgba(0, 0, 0, 0.87)',
					fontSize: '0.8125rem',
					fontWeight: '400',
					border: '0px',
				},
				selectIcon: {
					color: 'rgba(0, 0, 0, 0.87)',
					paddingLeft: '5px',
				},
				selectRoot: {
					marginLeft: 0,
					marginRight: 0,
				}
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					//padding: '4px 15px',
					fontSize: '0.875rem',

					'&:last-child': {
						paddingRight: '24px',
					},

					'&:first-of-type': {
						paddingLeft: '24px',
					},
				},
			},
		},
	},
})
