import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer'
import { hideModal, deleteListingSpecialOffer } from '../../../redux/actions'

export function DeleteListingSpecialOfferModal({
  listingId,
  specialOffer,
}) {
  const dispatch = useDispatch()

  const onCancel = () => dispatch(hideModal())
  const takeAction = () => dispatch(deleteListingSpecialOffer(listingId, specialOffer))
    .then(() => dispatch(hideModal()))
    .catch((err) => {
      console.log('deleteListingSpecialOffer error', err)
      dispatch(hideModal())
      throw err
    })

  return (
    <ModalContainer
      modal
      maxWidth="sm"
      fullWidth="true"
      title="Are you sure you want to delete this offer?"
      actions={{
        primaryAction: {
          label: 'Delete',
          onClick: () => takeAction(listingId, specialOffer),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <p style={{ color: '#222', marginTop: '20px' }}>This action cannot be undone, however, existing offer data will continue to be available.</p>
    </ModalContainer>
  )
}

DeleteListingSpecialOfferModal.propTypes = {
  listingId: PropTypes.number.isRequired,
  specialOffer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default DeleteListingSpecialOfferModal
