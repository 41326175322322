import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
    hideModal,
    showModal,
    showSuccessNotification,
    showErrorNotification
} from "../../../../../../redux/actions"
import {
    selectPartnerListing,
} from "../../../../../../redux/selectors"
import { useOutletContext } from "react-router-dom"
import { useGetOffersLookupsQuery } from "../../../../../../redux/slices/lookupsSlice"
import {
    useCreateEventMutation,
    useUpdateEventMutation,
    useLazyGetEventByIdQuery
} from "../../../../../../redux/slices/eventsSlice"

const useEventsCreateEdit = () => {
    const { id: partnerListingId } = useParams()
    const dispatch = useDispatch()
    const {
        offers
    } = useOutletContext()

    // selectors
    const partnerListing = useSelector(selectPartnerListing(partnerListingId))

    // RTK Query Hooks
    const lookups = useGetOffersLookupsQuery()
    const [getEventById] = useLazyGetEventByIdQuery()
    const [createEvent] = useCreateEventMutation()
    const [updateEvent] = useUpdateEventMutation()

    // Callback actions
    const openCreateModal = (props) => dispatch(showModal('CREATE_EVENT_MODAL', props))

    const onClickSubmit = async ({ data, isEdit }) => {
        try {
            if (isEdit) {
                await updateEvent(data).unwrap()
            }
            else {
                await createEvent(data).unwrap()
            }
            dispatch(showSuccessNotification(`Event successfully ${isEdit ? 'updated' : 'created'}.`))
            dispatch(hideModal())
        } catch (err) {
            console.error(err)
            dispatch(showErrorNotification(`There was an error ${isEdit ? 'updating' : 'creating'} this event. Please try again.`))
        }
    }

    const onClickCreateEvent = () => {
        openCreateModal({
            offers,
            lookups,
            onClickSubmit,
            partnerListing
        })
    }

    const onClickEditEvent = async (eventId) => {
        const event = await getEventById(eventId).unwrap()
        openCreateModal({
            event,
            offers,
            lookups,
            onClickSubmit,
            partnerListing
        })
    }

    return {
        onClickCreateEvent,
        onClickEditEvent,
        lookups
    }
}

export default useEventsCreateEdit
