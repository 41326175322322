import React from 'react'
import { useOutletContext } from 'react-router-dom'
import BillingTerms from './Billing/BillingTerms'
import OffersTable from '../../Tables/OffersTable'
import SectionHeadline from '../../../../Header/SectionHeadline'

export function BillingSection() {
  const { affiliate, canUpdateBillingRules } = useOutletContext()
  return (
    <div style={{ position: 'relative', paddingBottom: '30px', minHeight: '800px' }}>
      <SectionHeadline>Billing</SectionHeadline>
      <BillingTerms affiliate={affiliate} canUpdate={canUpdateBillingRules} />
      <OffersTable affiliate={affiliate} canUpdate={canUpdateBillingRules} />
    </div>
  )
}

BillingSection.propTypes = {
}

export default BillingSection
