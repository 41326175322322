/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import ModalContainer from '../ModalContainer'
import { hideModal, showSuccesNotification } from '../../../redux/actions'

export function VenueTicketInfoModal({ venueTicket }) {
  const dispatch = useDispatch()

  const onClose = () => dispatch(hideModal())
  const copyTicketLinkToClipboard = (link) => {
    copy(link)
    dispatch(showSuccesNotification('Link copied to clipboard'))
  }

  return (
    <ModalContainer
      title={`Verification Link: Ticket ID ${venueTicket.ticketId}`}
      maxWidth="md"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div style={{ color: '#222' }}>
        <h4 style={{ marginTop: '25px', marginBottom: '0px' }}><strong>Description</strong></h4>
        <p style={{ marginTop: '7px' }}>{venueTicket.ticketName}</p>
        <h4 style={{ marginTop: '40px', marginBottom: '0px' }}><strong>Full page link</strong></h4>
        <TextField
          name="fullPageLink"
          multiline
          rows={2}
          readOnly
          value={`${venueTicket.link}?display=full`}
          fullWidth
        />
        <div style={{ cursor: 'pointer' }} onClick={() => copyTicketLinkToClipboard(`${venueTicket.link}?display=full`)}>
          <IconButton><ContentCopyIcon /></IconButton>
          <span style={{ color: '#222', fontSize: '12px' }}>Copy to clipboard</span>
        </div>
        <h4 style={{ marginTop: '40px', marginBottom: '0px' }}><strong>Pop-up link</strong></h4>
        <TextField
          name="popupLink"
          multiline
          rows={2}
          readOnly
          value={`${venueTicket.link}?display=popup`}
          fullWidth
        />
        <div style={{ cursor: 'pointer' }} onClick={() => copyTicketLinkToClipboard(`${venueTicket.link}?display=full`)}>
          <IconButton><ContentCopyIcon /></IconButton>
          <span style={{ color: '#222', fontSize: '12px' }}>Copy to clipboard</span>
        </div>
      </div>
    </ModalContainer>
  )
}

VenueTicketInfoModal.propTypes = {
  venueTicket: PropTypes.shape({
    ticketId: PropTypes.number.isRequired,
    ticketName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default VenueTicketInfoModal
