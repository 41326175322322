import {
	APPROVE_VERIFICATIONREQUEST_SUCCESS,
	REJECT_VERIFICATIONREQUEST_SUCCESS,
	RETRY_DMDC_SUCCESS,
	SEND_NEW_ACTIVATION_CODE_SUCCESS,
} from './verification/verificationRequests'

import {
	FETCH_USER_FAILED,
	SEARCH_USERS_FAILED,
	SEARCH_USERS_SUCCESS_NORESULTS,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILED,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILED,
	LOCK_OUT_USER_SUCCESS,
	LOCK_OUT_USER_FAILED,
	UNLOCK_USER_SUCCESS,
	UNLOCK_USER_FAILED,
	SAVE_USER_ROLES_SUCCESS,
	FORGOT_PASSWORD_SUCCESS,
	DELETE_USER_SUCCESS,
	SYNCHRONIZE_USER_SUCCESS,
	BULK_IMPORT_USERS_FAILED,
} from './users/users'

import {
	UPDATE_AFFILIATE_STATUS_SUCCESS,
	UPDATE_AFFILIATE_STATUS_FAILED,
	UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS,
	UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_FAILED,
	UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS,
	UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE,
	FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE,
	SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_FAILURE,
	SAVE_AFFILIATE_HERO_IMAGE_MOBILE_FAILURE,
	SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_FAILURE,
	SAVE_AFFILIATE_PROMO_TILE_IMAGE_FAILURE,
} from './affiliates/affiliates'

import {
	UPDATE_PRICING_SCHEME_SUCCESS,
	UPDATE_PRICING_SCHEME_FAILURE,
} from './offers/offers'

import {
	UPDATE_BILLING_TERMS_SUCCESS,
	UPDATE_BILLING_TERMS_FAILURE,
} from './billing/billingTerms'

import {
	SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS,
	SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS,
	CREATE_CAMPAIGN_FAILURE,
	DELETE_CAMPAIGN_SUCCESS,
	DELETE_CAMPAIGN_FAILURE,
	UPDATE_CAMPAIGN_FAILURE,
	CREATE_CAMPAIGN_FAILURE_DUPLICATE,
	UPDATE_CAMPAIGN_FAILURE_DUPLICATE,
	SAVE_CAMPAIGN_LOGO_FAILURE,
	SAVE_CAMPAIGN_BACKGROUNDIMAGE_FAILURE,
} from './verification/campaigns'

import {
	FETCH_LISTING_FAILED,
	SAVE_LISTING_LOGO_FAILURE,
	SAVE_LISTING_BACKGROUNDIMAGE_FAILURE,
	UPDATE_LISTING_SUCCESS,
	SET_LISTING_OCCUPATIONS_SUCCESS,
	UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS,
	UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS,
	UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS,
	UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS,
	UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS,
	UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS,
	CREATE_LISTING_REDIRECT_URL_SUCCESS,
	DELETE_LISTING_REDIRECT_URL_SUCCESS,
	CREATE_LISTING_REDIRECT_URL_FAILURE,
	DELETE_LISTING_REDIRECT_URL_FAILURE,
	FETCH_LISTING_OFFER_PAGE_SETTINGS_FAILURE,
	UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS,
	UPDATE_LISTING_OFFER_PAGE_SETTINGS_FAILURE,		
	SAVE_GOVX_LOGIN_SUCCESS
} from './verification/listings'

import {
	UPDATE_PARTNERS_LISTING_SUCCESS,
	UPDATE_PARTNERS_LISTING_FAILED,
	UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_SUCCESS,
	UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_FAILURE,
} from './partners/listings'

import {
	APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS,
	APPROVE_WHITELISTEDEMAILDOMAIN_FAILURE,
	DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS,
} from './verification/whiteListedEmailDomains'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const showErrorNotification = message => ({type: SHOW_NOTIFICATION, message: message, severity: 'error'})
export const showSuccesNotification = message => ({type: SHOW_NOTIFICATION, message: message, severity: 'success'})
export const showSuccessNotification = showSuccesNotification // alias to fix typo
export const clearNotification = severity => ({type: 'CLEAR_NOTIFICATION', severity: severity})

export const STATE_KEY = 'notifications'

export const DEFAULT_STATE = {
	message: null,
	showing: false,
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case CLEAR_NOTIFICATION:
			return {
				...state,
				message: null,
				showing: false,
				severity: action.severity
			}
		case SHOW_NOTIFICATION:
			return {
				...state,
				message: action.message,
				showing: true,
				severity: action.severity
			}

		case FETCH_USER_FAILED:
			return {
				...state,
				message: 'No user matching that email address was found',
				showing: true,
				severity: 'error'
			}
		case SEARCH_USERS_SUCCESS_NORESULTS:
			return {
				...state,
				message: 'No matching records found',
				showing: true,
				severity: 'error'
			}

		case UPDATE_AFFILIATE_STATUS_SUCCESS:
			return {
				...state,
				message: 'Affiliate Status updated',
				showing: true,
				severity: 'success'

			}

		case UPDATE_AFFILIATE_STATUS_FAILED:
			return {
				...state,
				message: 'Affiliate Status failed to update',
				showing: true,
				severity: 'error'
			}

		case UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_SUCCESS:
			return {
				...state,
				message: 'Enable Product Pulls updated',
				showing: true,
				severity: 'success'
			}

		case UPDATE_AFFILIATE_ALLOW_PRODUCT_PULLS_FAILED:
			return {
				...state,
				message: 'Enable Product Pulls failed to update',
				showing: true,
				severity: 'error'
			}

		case UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_SUCCESS:
			return {
				...state,
				message: 'Marketplace settings saved successfully',
				showing: true,
				severity: 'success'
			}

		case UPDATE_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE:
			return {
				...state,
				message: 'Error saving Marketplace settings',
				showing: true,
				severity: 'error'
			}

		case FETCH_AFFILIATE_MARKETPLACE_PAGE_SETTINGS_FAILURE:
			return {
				...state,
				message: 'Failed to load Marketplace settings',
				showing: true,
				severity: 'error'
			}

		case UPDATE_PRICING_SCHEME_SUCCESS:
			return {
				...state,
				message: 'Offer pricing scheme updated',
				showing: true,
				severity: 'success'
			}

		case UPDATE_PRICING_SCHEME_FAILURE:
			return {
				...state,
				message: 'Offer pricing scheme failed to update',
				showing: true,
				severity: 'error'
			}

		case UPDATE_BILLING_TERMS_SUCCESS:
			return {
				...state,
				message: 'Billing terms updated',
				showing: true,
				severity: 'success'
			}

		case UPDATE_BILLING_TERMS_FAILURE:
			return {
				...state,
				message: 'Billing terms failed to update',
				showing: true,
				severity: 'error'
			}

		case CREATE_USER_SUCCESS:
			return {
				...state,
				message: 'User created',
				showing: true,
				severity: 'success'
			}

		case CREATE_USER_FAILED:
			return {
				...state,
				message: action.err?.message ?? 'Error creating user',
				showing: true,
				severity: 'error'
			}

		case UPDATE_USER_SUCCESS:
			return {
				...state,
				message: 'User updated',
				showing: true,
				severity: 'success'
			}
		case UPDATE_USER_FAILED:
			return {
				...state,
				message: 'Error updating user',
				showing: true,
				severity: 'success'
			}

		case LOCK_OUT_USER_SUCCESS:
			return {
				...state,
				message: 'User locked out',
				showing: true,
				severity: 'success'
			}
		case LOCK_OUT_USER_FAILED:
			return {
				...state,
				message: 'Error locking out user',
				showing: true,
				severity: 'error'
			}

		case UNLOCK_USER_SUCCESS:
			return {
				...state,
				message: 'User unlocked',
				showing: true,
				severity: 'success'
			}
		case UNLOCK_USER_FAILED:
			return {
				...state,
				message: 'Error unlocking user',
				showing: true,
				severity: 'error'
			}

		case FETCH_LISTING_FAILED:
			return {
				...state,
				message: 'Listing ID not recognized',
				showing: true,
				severity: 'error'
			}

		case SAVE_LISTING_LOGO_FAILURE:
		case SAVE_LISTING_BACKGROUNDIMAGE_FAILURE:
		case SAVE_CAMPAIGN_LOGO_FAILURE:
		case SAVE_CAMPAIGN_BACKGROUNDIMAGE_FAILURE:
		case SAVE_AFFILIATE_HERO_IMAGE_DESKTOP_FAILURE:
		case SAVE_AFFILIATE_HERO_IMAGE_MOBILE_FAILURE:
		case SAVE_AFFILIATE_PROMO_SQUARE_IMAGE_FAILURE:
		case SAVE_AFFILIATE_PROMO_TILE_IMAGE_FAILURE:
			return {
				...state,
				message: 'Error saving image',
				showing: true,
				severity: 'error'
			}
		case UPDATE_LISTING_SUCCESS:
			return {
				...state,
				message: 'Listing updated successfully',
				showing: true,
				severity: 'success'
			}

		case SET_LISTING_OCCUPATIONS_SUCCESS:
		case UPDATE_LISTING_TEMPLATE_SETTINGS_SUCCESS:
		case UPDATE_LISTING_MESSAGING_SETTINGS_SUCCESS:
		case UPDATE_LISTING_DATASHARING_SETTINGS_SUCCESS:
		case UPDATE_LISTING_ADVERTISEMENT_SETTINGS_SUCCESS:
		case UPDATE_LISTING_EMAIL_SETTINGS_SUCCESS:
		case UPDATE_LISTING_LOGINS_SETTINGS_SUCCESS:
		case UPDATE_LISTING_OFFER_PAGE_SETTINGS_SUCCESS:
			return {
				...state,
				message: 'Listing settings saved successfully',
				showing: true,
				severity: 'success'
			}

		case APPROVE_VERIFICATIONREQUEST_SUCCESS:
			return {
				...state,
				message: 'Request approved',
				showing: true,
				severity: 'success'
			}
		case REJECT_VERIFICATIONREQUEST_SUCCESS:
			return {
				...state,
				message: 'Request rejected',
				showing: true,
				severity: 'error'
			}
		case RETRY_DMDC_SUCCESS:
			const auth = action.data.entities.authorizationRecords[action.data.result.authorizationRecord]
			const message =
				auth.metaData && auth.metaData['dmdc-authorization-status'] === 'Unavailable'
					? 'The DMDC is currently unavailable'
					: `Request statuses set to ${ auth.status }`
			return {
				...state,
				message,
				showing: true,
				severity: 'error'
			}
		case SEND_NEW_ACTIVATION_CODE_SUCCESS:
			return {
				...state,
				message: 'Verification email sent',
				showing: true,
				severity: 'success'
			}

		case APPROVE_WHITELISTEDEMAILDOMAIN_SUCCESS:
			const whiteListedDomain = action.data.entities.whitelistedEmailDomains[action.data.result.whitelistedEmailDomain]
			return {
				...state,
				message: `${ whiteListedDomain.name } approved`,
				showing: true,
				severity: 'success'
			}
		case DELETE_WHITELISTEDEMAILDOMAIN_SUCCESS:
			return {
				...state,
				message: 'Domain successfully removed from whitelist',
				showing: true,
				severity: 'success'
			}
		case SAVE_USER_ROLES_SUCCESS:
			return {
				...state,
				message: 'User roles saved successfully',
				showing: true,
				severity: 'success'
			}
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				message: 'Password reset email has been sent',
				showing: true,
				severity: 'success'
			}
		case DELETE_USER_SUCCESS:
			return {
				...state,
				message: 'User deleted successfully',
				showing: true,
				severity: 'success'
			}
		case SYNCHRONIZE_USER_SUCCESS:
			return {
				...state,
				message: 'Synchronization request has been sent',
				showing: true,
				severity: 'success'

			}
		case SAVE_CAMPAIGN_TEMPLATESETTINGS_SUCCESS:
		case SAVE_CAMPAIGN_MESSAGINGSETTINGS_SUCCESS:
			return {
				...state,
				message: 'Campaign saved successfully',
				showing: true,
				severity: 'success'
			}
		case DELETE_CAMPAIGN_SUCCESS:
			return {
				...state,
				message: 'Campaign deleted successfully',
				showing: true,
				severity: 'success'
			}
		case CREATE_CAMPAIGN_FAILURE:
			return {
				...state,
				message: 'Failed to create campaign',
				showing: true,
				severity: 'error'
			}
		case DELETE_CAMPAIGN_FAILURE:
			return {
				...state,
				message: 'Failed to delete campaign',
				showing: true,
				severity: 'error'
			}
		case CREATE_CAMPAIGN_FAILURE_DUPLICATE:
		case UPDATE_CAMPAIGN_FAILURE_DUPLICATE:
			return {
				...state,
				message: 'Campaign name already exists',
				showing: true,
				severity: 'error'
			}
		case CREATE_LISTING_REDIRECT_URL_SUCCESS:
			return {
				...state,
				message: 'Whitelisted URL saved successfully',
				showing: true,
				severity: 'success'
			}
		case DELETE_LISTING_REDIRECT_URL_SUCCESS:
			return {
				...state,
				message: 'Whitelisted URL deleted successfully',
				showing: true,
				severity: 'success'
			}
		case CREATE_LISTING_REDIRECT_URL_FAILURE:
			return {
				...state,
				message: 'Failed to create whitelisted URL',
				showing: true,
				severity: 'error'
			}
		case DELETE_LISTING_REDIRECT_URL_FAILURE:
			return {
				...state,
				message: 'Failed to delete whitelisted URL',
				showing: true,
				severity: 'error'
			}
		case BULK_IMPORT_USERS_FAILED:
			return {
				...state,
				message: 'Failed to import users',
				showing: true,
				severity: 'error'
			}
		case UPDATE_PARTNERS_LISTING_SUCCESS:
			return {
				...state,
				message: 'Partner listing updated successfully',
				showing: true,
				severity: 'success'
			}
		case UPDATE_PARTNERS_LISTING_FAILED:
			return {
				...state,
				message: 'Failed to update partner listing',
				showing: true,
				severity: 'error'
			}
		case UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_SUCCESS:
			return {
				...state,
				message: 'GOVX Marketplace eligibility updated successfully',
				showing: true,
				severity: 'success'
			}
		case UPDATE_LISTING_ELIGIBLE_FOR_GX_MARKETPLACE_FAILURE:
			return {
				...state,
				message: 'Failed to update partner listing govx marketplace eligibility',
				showing: true,
				severity: 'error'
			}
		case FETCH_LISTING_OFFER_PAGE_SETTINGS_FAILURE:
			return {
				...state,
				message: 'Failed to fetch partner listing offer page settings',
				showing: true,
				severity: 'error'
			}			
		case UPDATE_LISTING_OFFER_PAGE_SETTINGS_FAILURE:
			return {
				...state,
				message: 'Failed to update partner listing offer page settings',
				showing: true,
				severity: 'error'
			}
		default:
			return state
	}
}

export default reducer

export const selectNotification = state => state.notifications
