import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { Link, Outlet, useMatch } from 'react-router-dom'
import { Box, MenuItem, MenuList } from '@mui/material'

const useStyles = createUseStyles({
  menuItem: {
    '&:hover': {
      backgroundColor: '#f1f1f1 !important',
      borderRadius: '2px !important',
    },
  },
})

function RoutedMenuItem({
  children, isActive, to, onClick,
}) {
  const classes = useStyles()
  return (
    <MenuItem
      component={Link}
      to={to}
      onClick={onClick}
      classes={{
        root: classes.menuItem,
      }}
      sx={{
        textTransform: 'uppercase',
        fontSize: '13px',
        borderRadius: isActive ? '2px' : 'inherit',
        borderLeft: isActive ? '2px solid #000' : 'inherit',
        backgroundColor: isActive ? '#f1f1f1' : 'inherit',
        color: isActive ? 'rgba(0, 0, 0, 0.87)' : '#000',
        height: '50px',
        paddingY: '6px',
      }}
    >
      {children}

    </MenuItem>
  )
}

function RoutedMenu({ items, context }) {
  const match = useMatch('/partners/listings/:id/:tab/:subtab')
  const [tab, setTab] = useState(match?.params?.subtab || 'customize-page')

  return (
    <Box display="flex">
      <Box width="210px" pr="10px" borderRight="1px solid rgb(207, 207, 207)">
        <MenuList>
          {items.map((item, i) => (
            <RoutedMenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={() => setTab(item.to)}
              isActive={tab === item.to}
              to={item.to}
            >
              {item.label}
            </RoutedMenuItem>
          ))}
        </MenuList>
      </Box>
      <Box
        pl="20px"
        flex="1 1 0%;"
      >
        <Outlet context={context} />
      </Box>
    </Box>
  )
}

RoutedMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  })).isRequired,
  context: PropTypes.shape({}),
}

RoutedMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default RoutedMenu
