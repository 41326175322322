import { user } from '../../../api/schema'
import * as userApi from '../../../api/userApi'
import { normalize } from 'normalizr'
import { createSelector } from 'reselect'
import { RouteConstants, getUserPath } from '../../../setup/routeConstants'
import { history, LOCATION_CHANGE } from '../../../setup/history'

export const CREATE_USERS_SEARCH = 'CREATE_USERS_SEARCH'
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH'

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_SUCCESS_NORESULTS = 'SEARCH_USERS_SUCCESS_NORESULTS'
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const LOCK_OUT_USER_REQUEST = 'LOCK_OUT_USER_REQUEST'
export const LOCK_OUT_USER_SUCCESS = 'LOCK_OUT_USER_SUCCESS'
export const LOCK_OUT_USER_FAILED = 'LOCK_OUT_USER_FAILED'

export const UNLOCK_USER_REQUEST = 'UNLOCK_USER_REQUEST'
export const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS'
export const UNLOCK_USER_FAILED = 'UNLOCK_USER_FAILED'

export const FETCH_USER_ROLES_REQUEST = 'FETCH_USER_ROLES_REQUEST'
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS'
export const FETCH_USER_ROLES_FAILED = 'FETCH_USER_ROLES_FAILED'

export const SAVE_USER_ROLE_REQUEST = 'SAVE_USER_ROLE_REQUEST'
export const SAVE_USER_ROLE_SUCCESS = 'SAVE_USER_ROLE_SUCCESS'
export const SAVE_USER_ROLE_FAILED = 'SAVE_USER_ROLE_FAILED'

export const SAVE_USER_ROLES_REQUEST = 'SAVE_USER_ROLES_REQUEST'
export const SAVE_USER_ROLES_SUCCESS = 'SAVE_USER_ROLES_SUCCESS'
export const SAVE_USER_ROLES_FAILED = 'SAVE_USER_ROLES_FAILED'

export const SET_USER = 'SET_USER'

export const createUsersSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_USERS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const updateUsersSearch = search => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_USERS_SEARCH, search })
	return new Promise(resolve => resolve(search))
}

export const searchUsers = searchId => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_USERS_REQUEST })
	let search = selectUsersSearch(searchId)(getState())
	return userApi
		.fetchUsers(search)
		.then(response => {
			const data = normalize(response.data, [schema.user])
			dispatch({
				type: SEARCH_USERS_SUCCESS,
				data,
				total: response.total,
				search,
			})
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_USERS_FAILED, err })
			throw err
		})
}

export const searchUsersByName = (firstName, lastName, searchId) => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_USERS_REQUEST })
	firstName = firstName ? firstName : ''
	lastName = lastName ? lastName : ''
	const caseSensitive = true

	return userApi.fetchUsersByName(firstName, lastName, caseSensitive)
		.then(response => {
			const data = normalize(response, [schema.user])
			let search = selectUsersSearch(searchId)(getState())
			if (!search) {
				search = {
					id: 'users-queue',
					filter: {
						timeFrame: { type: 'any', from: null, to: null, expanded: false },
						userData: { firstName: firstName, lastName: lastName, expanded: false },
					},
					paging: {
						pageSize: 25,
					},
				}
				dispatch({ type: CREATE_USERS_SEARCH, search: search })
			} else {
				let newSearch = {
					...search,
					filter: {
						...search.filter,
						timeFrame: { ...search.filter.timeFrame, type: 'any', from: null, to: null, expanded: false },
						userData: { ...search.filter.userData, firstName: firstName, lastName: lastName, expanded: false },
					},
				}

				dispatch({ type: UPDATE_USERS_SEARCH, search: newSearch })
			}
			if (response.length == undefined || response.length == 0) {
				return dispatch({ type: SEARCH_USERS_SUCCESS_NORESULTS, data })
			}
			if (response.length == 1) {
				dispatch({ type: LOCATION_CHANGE })
				return response
			}
			dispatch({
				type: SEARCH_USERS_SUCCESS,
				data,
				total: response ? response.filter(x => !x.deleted).length : 0,
				search,
			})
			dispatch({ type: LOCATION_CHANGE })

			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_USERS_FAILED, err })
			throw err
		})
}

export const searchUsersByDuplicateUserId = (duplicateUserId, searchId) => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_USERS_REQUEST })
	let search = selectUsersSearch(searchId)(getState())
	return userApi
		.fetchUsersByDuplicateUserId(duplicateUserId)
		.then(response => {
			const data = normalize(response, [schema.user])

			dispatch({ type: SEARCH_USERS_SUCCESS, data, total: response.length || 0, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_USERS_FAILED, err })
			throw err
		})
}

export const searchUsersByClaim = (claim, searchId) => (dispatch, getState, { schema }) => {
	dispatch({ type: SEARCH_USERS_REQUEST })
	let search = selectUsersSearch(searchId)(getState())
	return userApi
		.fetchUsersByClaim(claim)
		.then(response => {
			const data = normalize(response, [schema.user])

			dispatch({ type: SEARCH_USERS_SUCCESS, data, total: response.length || 0, search })
			return response
		})
		.catch(err => {
			dispatch({ type: SEARCH_USERS_FAILED, err })
			throw err
		})
}

export const fetchUser = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_USER_REQUEST })
	return userApi
		.fetchUser(id)
		.then(response => {
			let data = normalize(response, schema.user)
			dispatch({ type: FETCH_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_USER_FAILED, err })
			throw err
		})
}

export const fetchUserByEmail = (email, redirect) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_USER_REQUEST })
	return userApi
		.fetchUserByEmail(email)
		.then(response => {
			const data = normalize(response, schema.user)
			dispatch({ type: FETCH_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_USER_FAILED, err })
			throw err
		})
}

export const setUser = id => (dispatch, getState, { schema }) => {
	dispatch({ type: SET_USER, id })
	return new Promise(resolve => resolve(id))
}

export const createUser = user => (dispatch, getState, { schema }) => {
	dispatch({ type: CREATE_USER_REQUEST })

	return userApi
		.createUser(user)
		.then(response => {
			const data = normalize(response, schema.user)
			dispatch({ type: CREATE_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			if (err.email) err.message = err.email
			dispatch({ type: CREATE_USER_FAILED, err })
			throw err
		})
}

export const updateUser = user => (dispatch, getState, { schema }) => {
	dispatch({ type: UPDATE_USER_REQUEST })
	return userApi
		.updateUser(user)
		.then(response => {
			const data = normalize(response, schema.user)
			dispatch({ type: UPDATE_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UPDATE_USER_FAILED, err })
			throw err
		})
}

export const lockOutUser = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: LOCK_OUT_USER_REQUEST })
	return userApi
		.lockOutUser(userId)
		.then(response => {
			const data = normalize(response, schema.user)
			dispatch({ type: LOCK_OUT_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: LOCK_OUT_USER_FAILED, err })
			throw err
		})
}

export const unlockUser = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: UNLOCK_USER_REQUEST })
	return userApi
		.unlockUser(userId)
		.then(response => {
			const data = normalize(response, schema.user)
			dispatch({ type: UNLOCK_USER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: UNLOCK_USER_FAILED, err })
			throw err
		})
}

export const fetchUserRoles = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_USER_ROLES_REQUEST })
	return userApi
		.fetchUserRoles(userId)
		.then(response => {
			dispatch({ type: FETCH_USER_ROLES_SUCCESS, data: { roles: response, userId } })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_USER_ROLES_FAILED, err })
			throw err
		})
}

export const saveUserRoles = (userId, roles) => (dispatch, getState, { schema }) => {
	dispatch({ type: SAVE_USER_ROLES_REQUEST })
	return userApi
		.updateUserRoles(userId, roles)
		.then(response => {
			dispatch({ type: SAVE_USER_ROLES_SUCCESS, data: { roles: response, userId } })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_USER_ROLES_FAILED, err })
			throw err
		})
}

export const addUserRole = (userId, roleName) => (dispatch, getState, { schema }) => {
	dispatch({ type: SAVE_USER_ROLE_REQUEST })
	return userApi
		.addUserRole(userId, roleName)
		.then(response => {
			dispatch({ type: SAVE_USER_ROLE_SUCCESS, data: { roles: response, userId } })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_USER_ROLE_FAILED, err })
			throw err
		})
}

export const deleteUserRole = (userId, roleName) => (dispatch, getState, { schema }) => {
	dispatch({ type: SAVE_USER_ROLE_REQUEST })
	return userApi
		.deleteUserRole(userId, roleName)
		.then(response => {
			dispatch({ type: SAVE_USER_ROLE_SUCCESS, data: { roles: response, userId } })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_USER_ROLE_FAILED, err })
			throw err
		})
}

export const SYNCHRONIZE_USER_REQUEST = 'SYNCHRONIZE_USER_REQUEST'
export const SYNCHRONIZE_USER_SUCCESS = 'SYNCHRONIZE_USER_SUCCESS'
export const SYNCHRONIZE_USER_FAILED = 'SYNCHRONIZE_USER_FAILED'

export const synchronizeUser = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: SYNCHRONIZE_USER_REQUEST })
	return userApi
		.synchronizeUser(userId)
		.then(response => {
			dispatch({ type: SYNCHRONIZE_USER_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: SYNCHRONIZE_USER_FAILED, err })
			throw err
		})
}


export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'

export const forgotPassword = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: FORGOT_PASSWORD_REQUEST })
	return userApi
		.forgotPassword(userId)
		.then(response => {
			dispatch({ type: FORGOT_PASSWORD_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: FORGOT_PASSWORD_FAILED, err })
			throw err
		})
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const deleteUser = userId => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_USER_REQUEST })
	return userApi
		.deleteUser(userId)
		.then(response => {
			dispatch({ type: DELETE_USER_SUCCESS, userId })
			return response
		})
		.catch(err => {
			dispatch({ type: DELETE_USER_FAILED, err })
			throw err
		})
}

export const ADD_USER_CLAIM_REQUEST = 'ADD_USER_CLAIM_REQUEST'
export const ADD_USER_CLAIM_SUCCESS = 'ADD_USER_CLAIM_SUCCESS'
export const ADD_USER_CLAIM_FAILED = 'ADD_USER_CLAIM_FAILED'

export const addUserClaim = (userId, claim) => (dispatch, getState, { schema }) => {
	dispatch({ type: ADD_USER_CLAIM_REQUEST })
	return userApi
		.addUserClaim(userId, claim)
		.then(() => {
			dispatch({ type: ADD_USER_CLAIM_SUCCESS, data: { userId, claim } })
			return
		})
		.catch(err => {
			dispatch({ type: ADD_USER_CLAIM_FAILED, err })
			throw err
		})
}

export const DELETE_USER_CLAIM_REQUEST = 'DELETE_USER_CLAIM_REQUEST'
export const DELETE_USER_CLAIM_SUCCESS = 'DELETE_USER_CLAIM_SUCCESS'
export const DELETE_USER_CLAIM_FAILED = 'DELETE_USER_CLAIM_FAILED'

export const deleteUserClaim = (userId, claim) => (dispatch, getState, { schema }) => {
	dispatch({ type: DELETE_USER_CLAIM_REQUEST })
	return userApi
		.deleteUserClaim(userId, claim)
		.then(() => {
			dispatch({ type: DELETE_USER_CLAIM_SUCCESS, data: { userId, claim } })
			return
		})
		.catch(err => {
			dispatch({ type: DELETE_USER_CLAIM_FAILED, err })
			throw err
		})
}

export const BULK_IMPORT_USERS_REQUEST = 'BULK_IMPORT_USERS_REQUEST'
export const BULK_IMPORT_USERS_SUCCESS = 'BULK_IMPORT_USERS_SUCCESS'
export const BULK_IMPORT_USERS_FAILED = 'BULK_IMPORT_USERS_FAILED'

export const bulkImportUsers = form => dispatch => {
	dispatch({ type: BULK_IMPORT_USERS_REQUEST })
	return userApi.bulkImportUsers(form)
		.then(response => {
			dispatch({ type: BULK_IMPORT_USERS_SUCCESS, response })
			return response
		})
		.catch(err => {
			dispatch({ type: BULK_IMPORT_USERS_FAILED, err })
			throw err
		})
}

export const STATE_KEY = 'users'

export const DEFAULT_STATE = {
	db: {},
	searches: {},
}

export const handleSearchChange = (state, action) => {
	const searches = { ...state.searches, [action.search.id]: action.search }
	return { ...state, searches }
}

export const handleSearchSuccess = (state, action) => {
	let searches = {
		...state.searches,
		[action.search.id]: {
			...state.searches[action.search.id],
			total: action.total,
			result: action.data.result,
		},
	}

	let db = {
		...state.db,
		...action.data.entities.users,
	}

	return { ...state, db, searches }
}

export const mergeUsers = (state, action) => {
	return { ...state, db: { ...state.db, ...action.data.entities.users } }
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case SET_USER:
			return { ...state, userId: action.id }
		case FETCH_USER_SUCCESS:
		case CREATE_USER_SUCCESS:
		case UPDATE_USER_SUCCESS:
		case LOCK_OUT_USER_SUCCESS:
		case UNLOCK_USER_SUCCESS:
			return mergeUsers(state, action)
		case CREATE_USERS_SEARCH:
		case UPDATE_USERS_SEARCH:
			return handleSearchChange(state, action)
		case SEARCH_USERS_SUCCESS:
			return handleSearchSuccess(state, action)
		case FETCH_USER_ROLES_SUCCESS:
		case SAVE_USER_ROLE_SUCCESS:
		case SAVE_USER_ROLES_SUCCESS:
			let user = { ...state.db[action.data.userId], roles: action.data.roles }
			let update = []
			update[action.data.userId] = user
			return {
				...state,
				db: {
					...state.db,
					...update,
				},
			}
		case DELETE_USER_SUCCESS:
			let deletedUser = { ...state.db[action.userId], hasRequestsToDelete: true }
			let deleted = []
			deleted[action.userId] = deletedUser
			return {
				...state,
				db: {
					...state.db,
					...deleted,
				},
			}
		default:
			return { ...state }
	}
}

export default reducer

export const selectUsersHashMap = state => state.users.users.db
export const selectUsersSearchesHashMap = state => state.users.users.searches

export const selectUsers = createSelector(selectUsersHashMap, users => Object.keys(users).map(k => users[k]))

export const selectUsersSearches = createSelector(selectUsersSearchesHashMap, searches =>
	Object.keys(searches).map(k => searches[k])
)

export const createFilteredUsersSelector = id => {
	return createSelector([selectUsersHashMap, selectUsersSearchesHashMap], (users, searches) => {
		if (!searches[id] || !searches[id].result) return []
		let results = searches[id].result
			.map(f => users[f])
			.filter(d => d)
			.filter(u => !u.deleted)
		return results
	})
}

export const selectUsersSearch = id => state => state.users.users.searches[id]

export const selectUser = id => {
	return createSelector(selectUsersHashMap, users => users[id.toLowerCase()])
}
