/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import copy from 'copy-to-clipboard'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiButton from '@mui/material/Button'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'

import Button from '../../../../../Button'
import StyledLink from '../../../../../StyledLink'
import Empty from './Empty'
import { showModal, showSuccesNotification, fetchListingVerificationLinks } from '../../../../../../redux/actions'
import { selectListingVerificationLinks } from '../../../../../../redux/selectors'
import RightIconMenu from './RightIconMenu'
import SectionHeadline from '../../../../../Header/SectionHeadline'

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  info: {
    display: 'flex',
  },
  threeFourths: {
    width: '75%',
    padding: '15px',
  },
  oneFourth: {
    width: '25%',
    padding: '15px',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  linkButton: {
    color: '#0E5777',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
})

function Processing() {
  return (
    <div
      style={{
        position: 'relative',
        top: '10px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <p style={{ marginBottom: '0px', textAlign: 'center' }}>
        <strong>Fetching links...</strong>
      </p>
    </div>
  )
}

export function OAuthLinks({
  listing,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const verificationLinks = useSelector(selectListingVerificationLinks(listing.id))

  const createVerificationLink = () => dispatch(showModal('VERIFICATION_LINK', { listing }))
  const showVerificationLink = (verificationLink) => dispatch(showModal('OAUTH_LINK_DISPLAY', { verificationLink }))
  const copyVerificationLinkToClipboard = (link) => {
    copy(link)
    dispatch(showSuccesNotification('Link copied to clipboard'))
  }
  const updateVerificationLink = (link) => dispatch(showModal('VERIFICATION_LINK', { listing, verificationLink: link }))
  const deleteVerificationLink = (link) => dispatch(showModal('DELETE_VERIFICATION_LINK', { listing, verificationLink: link }))

  useEffect(() => {
    dispatch(fetchListingVerificationLinks(listing.id))
  }, [])

  return verificationLinks ? (
    verificationLinks.length > 0 ? (
      <div className={classes.wrapper}>
        <div className={classes.linksSection}>
          <div className={classes.header}>
            <SectionHeadline>OAuth Verification Links</SectionHeadline>
            <Button
              label="Create a verification link"
              fullWidth={false}
              color="primary"
              onClick={createVerificationLink}
              style={{ whiteSpace: 'nowrap' }}
            >
              Create a verification link
            </Button>
          </div>

          <Table>
            <TableHead style={{ backgroundColor: '#f1f1f1' }}>
              <TableRow>
                <TableCell
                  style={{
                    paddingRight: '0px',
                    color: '#222',
                    fontWeight: '600',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                  // width: '60px',
                    color: '#222',
                    fontWeight: '600',
                    paddingRight: '10px',
                  }}
                >
                  Display
                </TableCell>
                <TableCell
                  style={{
                  // width: '60px',
                    color: '#222',
                    fontWeight: '600',
                    paddingRight: '30px',
                    minWidth: '84px',
                  }}
                >
                  Go-To
                </TableCell>
                <TableCell
                  style={{
                    paddingRight: '0px',
                    paddingLeft: '0px',
                    color: '#222',
                    fontWeight: '600',
                  }}
                >
                  Scope
                </TableCell>
                <TableCell
                  style={{
                    color: '#222',
                    fontWeight: '600',
                    paddingRight: '30px',
                  }}
                >
                  Grant Type
                </TableCell>
                <TableCell
                  style={{
                    minWidth: '70px',
                    paddingRight: '0px',
                    paddingLeft: '0px',
                    color: '#222',
                    fontWeight: '600',
                  }}
                >
                  Actions
                </TableCell>
                <TableCell
                  style={{
                    width: '50px',
                    paddingRight: '3px',
                    paddingLeft: '5px',
                    color: '#222',
                    fontWeight: '600',
                    cursor: 'default',
                  }}
                >
                &nbsp;
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {verificationLinks.map((v) => (
                <TableRow key={v.id}>
                  <TableCell
                    style={{
                      paddingRight: '0px',
                    }}
                  >
                    {v.name}
                  </TableCell>
                  <TableCell>
                    {v.display}
                  </TableCell>
                  <TableCell>
                    {v.goTo}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingRight: '0px',
                      paddingLeft: '0px',
                    }}
                  >
                    {v.scope.split(' ').join(', ')}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingRight: '30px',
                    }}
                  >
                    {v.grantType}
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '70px',
                      paddingRight: '0px',
                      paddingLeft: '0px',
                    }}
                  >
                    <MuiButton
                      variant="text"
                      color="primary"
                      onClick={() => showVerificationLink(v)}
                      classes={{
                        root: classes.linkButton,
                      }}
                      style={{
                        textTransform: 'none',
                        fontSize: '0.875rem',
                        paddingRight: '0px',
                        paddingLeft: '0px',
                      }}
                    >
                      View details
                    </MuiButton>
                  </TableCell>
                  <TableCell
                    style={{
                      width: '50px',
                      paddingRight: '3px',
                      paddingLeft: '5px',
                      textAlign: 'right',
                    }}
                  >
                    {!v.isLocked && (
                    <RightIconMenu
                      onCopyLink={() => copyVerificationLinkToClipboard(v.link)}
                      onDelete={() => deleteVerificationLink(v)}
                      onEdit={() => updateVerificationLink(v)}
                    />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </div>
        <div className={classes.info}>
          <div className={classes.threeFourths}>
            <Typography variant="h3" style={{ marginBottom: '10px', fontWeight: 600 }}>
              Deploying verification
            </Typography>
            <Typography variant="body2">
              Our OAuth verification solution allows you to verify a user's group affiliation in
              real-time. It can be used to identify and target specific user groups for special benefits
              and offers. The most common implementation utilizes our OAuth server-side flow for
              verification. GovX uses the OAuth 2.0 protocol to provide access to our API. You will use
              our API to retrieve a user’s group affiliation data.
            </Typography>
            <Typography variant="body2">
              In order to initiate the verification process, you must direct the user to the GovX
              authorization endpoint via a verification link. You can create properly formatted
              verification links using the link builder tool in this section. Simply
              {' '}
              <StyledLink
                contents="create a verification link"
                clickHandlerOnly
                onClick={createVerificationLink}
              />
              {' '}
              to get started.
            </Typography>
          </div>
          <div className={classes.oneFourth}>
            <Typography variant="h3" style={{ marginBottom: '10px', fontWeight: 600 }}>
              Resources
            </Typography>
            <Typography>
              <StyledLink
                contents="Creating and configuring your listing"
                external
                to="https://support.govxinc.com/hc/en-us/articles/360028454812-Creating-and-configuring-your-listing"
                newWindow
              />
            </Typography>
            <Typography>
              <StyledLink
                contents="Deploying verification via OAuth"
                external
                to="https://support.govxinc.com/hc/en-us/articles/360028760851-Deploying-verification-via-OAuth-Explicit-flow"
                newWindow
              />
            </Typography>
          </div>
        </div>
      </div>
    ) : (
      <Empty createVerificationLink={createVerificationLink} />
    )
  ) : (
    <Processing />
  )
}

export default OAuthLinks
