/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import copy from 'copy-to-clipboard'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/FileCopy'
import ModalContainer from '../ModalContainer'
import { hideModal, showSuccesNotification } from '../../../redux/actions'
import { selectEntertainer, getSettings } from '../../../redux/selectors'

const getTextFieldName = (text) => text
  .toLowerCase()
  .replace(/ /g, '-')
  .split('-')
  .map((x, i) => (i > 0 ? `${x[0].toUpperCase()}${x.substr(1)}` : x))
  .join('')

function LinkSection({
  link, title, display, copyLinkToClipboard,
}) {
  const linkWithDisplay = `${link}${link.indexOf('?') > -1 ? '&' : '?'}display=${display}`
  return (
    <>
      <h4 style={{ marginTop: '40px', marginBottom: '0px' }}>
        <strong>{title}</strong>
      </h4>
      <TextField name={getTextFieldName(title)} multiline rows={2} readOnly value={linkWithDisplay} fullWidth />
      <div style={{ cursor: 'pointer' }} onClick={() => copyLinkToClipboard(linkWithDisplay)}>
        <IconButton>
          <ContentCopyIcon />
        </IconButton>
        <span style={{ color: '#222', fontSize: '12px' }}>Copy to clipboard</span>
      </div>
    </>
  )
}

LinkSection.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  copyLinkToClipboard: PropTypes.func.isRequired,
}

export function ViewOfferPageModal({ entertainerId, listing }) {
  const dispatch = useDispatch()

  const entertainer = useSelector(selectEntertainer)(entertainerId)
  const link = `${useSelector(getSettings).authDomain}/tickets/entertainer-events/${listing.clientId}/`

  const onClose = () => dispatch(hideModal())
  const copyLinkToClipboard = () => {
    copy(link)
    dispatch(showSuccesNotification('Link copied to clipboard'))
  }

  return (
    <ModalContainer
      title={`${entertainer && entertainer.name} Offer Page`}
      maxWidth="md"
      fullWidth="true"
      actions={{
        secondaryAction: {
          label: 'Close',
          onClick: onClose,
        },
      }}
    >
      <div style={{ color: '#222' }}>
        <LinkSection link={link} copyLinkToClipboard={copyLinkToClipboard} title="Full page link" display="full" />
        <LinkSection link={link} copyLinkToClipboard={copyLinkToClipboard} title="Pop-up link" display="popup" />
      </div>
    </ModalContainer>
  )
}

ViewOfferPageModal.propTypes = {
  listing: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  entertainerId: PropTypes.number.isRequired,
}

export default ViewOfferPageModal
