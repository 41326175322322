/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Box, Typography } from '@mui/material'
import DialogSubsectionHeadline from '../../../../Header/DialogSubsectionHeadline'
import { MUIFormSelectInput } from '../../../../@Common/Form/SelectInput/FormSelectInput'
import MUIFormHexInput from '../../../../@Common/Form/TextInput/FormHexInput'
import { MUIFormCheckboxInput } from '../../../../@Common/Form/CheckboxInput/FormCheckboxInput'

export function FooterSettings({
  form,
  isGovX,
  canEditListingCobrandTemplate,
  classes,
  showFooterLinks,
  showGovXLogo,
  setShowFooterLinks,
  setShowGovXLogo,
}) {
  return (
    <>
      {(form.watch('type') !== 'CoBranded' || isGovX) && (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" style={{ marginTop: isGovX ? '20px' : '40px' }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12">
            <Box gridColumn="span 4">
              <DialogSubsectionHeadline>Footer</DialogSubsectionHeadline>
              <Typography className={classes.sectionSubHeader}>
                Color inputs must be in hex code format
              </Typography>
            </Box>
            <Box gridColumn="span 3">
              <MUIFormSelectInput
                control={form.control}
                name="footerBackgroundType"
                label="Background"
                items={[
                  { value: 'color', label: 'Solid Color', disabled: isGovX },
                  { value: 'transparent', label: 'Transparent', disabled: isGovX },
                ]}
              />
            </Box>
            {form.watch('footerBackgroundType') === 'color' && (
              <Box gridColumn="span 2" sx={{ pl: '10px' }}>
                <MUIFormHexInput
                  control={form.control}
                  name="footerBackgroundColor"
                  label="Background Color"
                  rules={{ required: true }}
                />
              </Box>
            )}
          </Box>

          {form.watch('type') === 'PrivateLabel' && !isGovX && (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12" style={{ marginTop: '20px' }}>
              <Box gridColumn="5/7">
                <MUIFormCheckboxInput
                  control={form.control}
                  name="footerShowFooterLinks"
                  label="Show footer links"
                  onChange={(e) => setShowFooterLinks(e.currentTarget.checked)}
                />
              </Box>
              <Box gridColumn="7/11" style={{ marginLeft: '10px' }}>
                <MUIFormCheckboxInput
                  control={form.control}
                  name="footerShowGovXLogo"
                  label="Show &quot;GOVX ID&quot; logo"
                  onChange={(e) => setShowGovXLogo(e.currentTarget.checked)}
                />
              </Box>
            </Box>
          )}
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridColumn="span 12" style={{ marginTop: '20px' }}>
            <Box gridColumn="5/8">
              {(form.watch('type') !== 'CoBranded' || isGovX) && (
                ((form.watch('type') === 'PrivateLabel' && form.watch('footerShowFooterLinks') === true) || isGovX) && (
                  <MUIFormSelectInput
                    control={form.control}
                    name="footerLinkColor"
                    label="Link Color"
                    items={[
                      { value: '#fff', label: 'White', disabled: isGovX },
                      { value: '#2a2d36', label: 'Dark', disabled: isGovX },
                    ]}
                  />
                )
              )}
            </Box>
            <Box gridColumn="8/11" style={{ paddingLeft: '10px' }}>
              {form.watch('footerShowGovXLogo') === true && form.watch('type') === 'PrivateLabel' && !isGovX && (
                <MUIFormSelectInput
                  control={form.control}
                  name="footerGovXLogoColor"
                  label="Logo Color"
                  items={[
                    { value: '#fff', label: 'White', disabled: false },
                    { value: '#222', label: 'Dark', disabled: false },
                  ]}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default FooterSettings
