import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import ModalContainer from '../../../../../Modals/ModalContainer'

function SyndicateOfferCreateModal({
    onClickCancel,
    onClickSubmit,
    listingId
}) {

    const offerId = useRef(null)

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
    })

    const onSubmit = offerId => {
        onClickSubmit(offerId, listingId)
    }

    return (
        <ModalContainer
            title={`Syndicate Offer`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}
            actions={{
                primaryAction: { label: "Add", onClick: handleSubmit(onSubmit) },
                secondaryAction: { label: "Cancel", onClick: () => onClickCancel() },
            }}
        >
            <div>
                <p>
                    Syndicating an offer allows you to show offers from others listings on this listings page on GOVX.com. It does not change ownership of the offer.
                </p>
            </div>
            <div style={{ marginTop: "40px", marginBottom: "30px" }}>
                <MUIFormTextInput
                    name="offerId"
                    label="Offer ID"
                    control={control}
                    rules={{
                        required: true,
                        validate: (value) => value !== null || value !== '',
                        message: "Offer ID is required"
                    }}
                    gxid="createSyndicateOfferOriginalOfferId"
                />
            </div>
        </ModalContainer>
    )
}

export default SyndicateOfferCreateModal
