import { combineReducers } from 'redux'
import occupations, { STATE_KEY as OCCUPATIONS_STATE_KEY } from './occupations'
import verificationRequests, { STATE_KEY as VERIFICATIONREQUESTS_STATE_KEY } from './verificationRequests'
import governmentDepartments, { STATE_KEY as GOVERNMENT_DEPARTMENTS_STATE_KEY } from './governmentDepartments'
import occupationPrequalifiers, { STATE_KEY as OCCUPATION_PREQUALIFIERS_STATE_KEY } from './occupationPrequalifiers'
import occupationInfoModels, { STATE_KEY as OCCUPATION_INFOMODELS_STATE_KEY } from './occupationInfoModels'
import whitelistedEmailDomains, { STATE_KEY as WHITELISTEDEMAILDOMAINS_STATE_KEY } from './whiteListedEmailDomains'
import documentRuleSets, { STATE_KEY as DOCUMENTRULESETS_STATE_KEY } from './documentRuleSets'
import authorizationRecords, { STATE_KEY as AUTHORIZATIONRECORDS_STATE_KEY } from './authorizationRecords'
import allowedVerificationTypeGroups, { STATE_KEY as ALLOWED_VERIFICATIONTYPE_GROUPS_STATE_KEY } from './allowedVerificationTypeGroups'
import listings, { STATE_KEY as LISTINGS_STATE_KEY } from './listings'
import campaigns, { STATE_KEY as CAMPAIGNS_STATE_KEY } from './campaigns'
import offerUsers, { STATE_KEY as OFFER_USERS_STATE_KEY } from './offerUsers'

export const STATE_KEY = 'verification'

const reducer = combineReducers({
	[OCCUPATIONS_STATE_KEY]: occupations,
	[VERIFICATIONREQUESTS_STATE_KEY]: verificationRequests,
	[GOVERNMENT_DEPARTMENTS_STATE_KEY]: governmentDepartments,
	[OCCUPATION_PREQUALIFIERS_STATE_KEY]: occupationPrequalifiers,
	[OCCUPATION_INFOMODELS_STATE_KEY]: occupationInfoModels,
	[WHITELISTEDEMAILDOMAINS_STATE_KEY]: whitelistedEmailDomains,
	[DOCUMENTRULESETS_STATE_KEY]: documentRuleSets,
	[AUTHORIZATIONRECORDS_STATE_KEY]: authorizationRecords,
	[ALLOWED_VERIFICATIONTYPE_GROUPS_STATE_KEY]: allowedVerificationTypeGroups,
	[LISTINGS_STATE_KEY]: listings,
	[CAMPAIGNS_STATE_KEY]: campaigns,
	[OFFER_USERS_STATE_KEY]: offerUsers,
})

export default reducer
