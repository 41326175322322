import * as ticketsApi from '../../../api/ticketsApi'
import { normalize } from 'normalizr'
import { entertainer, eventTicket, venue, venueTicket } from '../../../api/schema'

export const FETCH_ENTERTAINER_REQUEST = 'FETCH_ENTERTAINER_REQUEST'
export const FETCH_ENTERTAINER_SUCCESS = 'FETCH_ENTERTAINER_SUCCESS'
export const FETCH_ENTERTAINER_FAILURE = 'FETCH_ENTERTAINER_FAILURE'

export const fetchEntertainer = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_ENTERTAINER_REQUEST })
	return ticketsApi.fetchEntertainer(id)
		.then(response => {
			let data = normalize(response, schema.entertainer)
			dispatch({ type: FETCH_ENTERTAINER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_ENTERTAINER_FAILURE, err })
			throw err
		})
}

export const FETCH_ENTERTAINER_EVENTTICKETS_REQUEST = 'FETCH_ENTERTAINER_EVENTTICKETS_REQUEST'
export const FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS = 'FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS'
export const FETCH_ENTERTAINER_EVENTTICKETS_FAILURE = 'FETCH_ENTERTAINER_EVENTTICKETS_FAILURE'

export const fetchEntertainerEventTickets = (listingId, entertainerId) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_REQUEST })
	return ticketsApi.fetchEntertainerEventTickets(listingId, entertainerId)
		.then(response => {
			let data = normalize(response, [schema.eventTicket])
			dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS, data, entertainerId })
			return response
		}).catch(err =>{
			dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_FAILURE, err })
			throw err
		})
}

export const FETCH_VENUE_REQUEST = 'FETCH_VENUE_REQUEST'
export const FETCH_VENUE_SUCCESS = 'FETCH_VENUE_SUCCESS'
export const FETCH_VENUE_FAILURE = 'FETCH_VENUE_FAILURE'

export const fetchVenue = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_VENUE_REQUEST })
	return ticketsApi.fetchVenue(id)
		.then(response => {
			let data = normalize(response, schema.venue)
			dispatch({ type: FETCH_VENUE_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_VENUE_FAILURE, err })
			throw err
		})
}

export const FETCH_VENUETICKETS_REQUEST = 'FETCH_VENUETICKETS_REQUEST'
export const FETCH_VENUETICKETS_SUCCESS = 'FETCH_VENUETICKETS_SUCCESS'
export const FETCH_VENUETICKETS_FAILURE = 'FETCH_VENUETICKETS_FAILURE'

export const fetchVenueTickets = (listingId, venueId) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_VENUETICKETS_REQUEST })
	return ticketsApi.fetchVenueTickets(listingId, venueId)
		.then(response => {
			let data = normalize(response, [schema.venueTicket])
			dispatch({ type: FETCH_VENUETICKETS_SUCCESS, data, venueId })
			return response
		}).catch(err =>{
			dispatch({ type: FETCH_VENUETICKETS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'tickets'

export const DEFAULT_STATE = {
	entertainers: {},
	eventTickets: {},
	venues: {},
	venueTickets: {}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_ENTERTAINER_SUCCESS:
			return { ...state, entertainers: { ...state.entertainers, ...action.data.entities.entertainers }}
		case FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS:
			return { ...state, eventTickets: { ...state.eventTickets, [action.entertainerId]: action.data.entities.eventTickets }}
		case FETCH_VENUE_SUCCESS:
			return { ...state, venues: { ...state.venues, ...action.data.entities.venues }}
		case FETCH_VENUETICKETS_SUCCESS:
			return { ...state, venueTickets: { ...state.venueTickets, [action.venueId]: action.data.entities.venueTickets }}
		default:
			return state
	}
}

export default reducer

export const selectEntertainer = state => id => state.tickets.entertainers[id]

export const selectEventTickets = state => entertainerId => {
	if (!state.tickets.eventTickets[entertainerId])
		return []
	return Object.keys(state.tickets.eventTickets[entertainerId]).map(k => state.tickets.eventTickets[entertainerId][k])
}

export const selectVenue = state => id => state.tickets.venues[id]

export const selectVenueTickets = state => venueId => {
	if (!state.tickets.venueTickets[venueId])
		return []
	return Object.keys(state.tickets.venueTickets[venueId]).map(k => state.tickets.venueTickets[venueId][k])
}