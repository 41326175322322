/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { createUseStyles } from 'react-jss'
import { useForm } from 'react-hook-form'
import Button from '../../../Button'
import { saveGovXLogin, showSuccesNotification } from '../../../../redux/actions'
import { MUIFormCheckboxInput } from '../../../@Common/Form/CheckboxInput/FormCheckboxInput'
import { MUIFormTextInput } from '../../../@Common/Form/TextInput/FormTextInput'
import { useGetVerificationListingByPartnerListingIdQuery } from '../../../../redux/slices/verificationsSlice'

const useStyles = createUseStyles({
  footer: {
    height: 'auto',
    backgroundColor: '#f1f1f1',
    padding: '10px 30px',
    marginTop: '30px',
    display: 'flex',
    marginLeft: '-20px',
    '&>Button': { marginLeft: 'auto' },
  },
})

function Processing() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '-30px',
        bottom: '0px',
        left: '-30px',
        minHeight: '344px',
        backgroundColor: 'rgba(255,255,255,.8)',
        overflow: 'hidden',
        zIndex: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

function GovXLoginForm({ listing }) {
  console.log('GovXLoginForm', listing)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { refetch } = useGetVerificationListingByPartnerListingIdQuery(listing.partnerListingId)

  const [processing, setProcessing] = useState(false)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      enabled: listing.loginEnabled,
      origins: listing.origins
        ? listing.origins.join()
        : null,
    },
  })

  const onSubmit = (values) => {
    setProcessing(true)

    const login = {
      listingId: listing.id,
      origins: values.origins.split(','),
      enabled: values.enabled,
    }

    return dispatch(saveGovXLogin(listing, login)).then(() => {
      setProcessing(false)
      dispatch(showSuccesNotification('GovX Login updated'))
      refetch()
    })
  }

  return (
    <div>
      { processing
        ? <Processing />
        : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2">
              By enabling GOVX Login, GOVX will be able to track an individual user's login across multiple sessions for your application.
              This allows your application to retrieve auth tokens and user information without the user having to re-authenticate per request.
              By providing a comma-separated list of origins (in the form of scheme + domain (example: https://www.govx.com)), you are identifying which origins may use this feature.
            </Typography>
            <div style={{ margin: '0px 0px 20px' }}>
              <MUIFormCheckboxInput
                control={control}
                name="enabled"
                label="Enable GOVX Login"
              />
            </div>
            <div>
              <MUIFormTextInput
                control={control}
                name="origins"
                label="Origins (comma seperated)"
              />
            </div>
            <div className={classes.footer}>
              <Button label="Save" color="primary" disabled={processing} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </form>
        )}
    </div>
  )
}

export default GovXLoginForm
