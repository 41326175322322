import { Box } from '@mui/material'
import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { useForm, useController } from 'react-hook-form'
import MUIRadioGroupInput from './ControlledRadioGroupInput'

function MUIFormRadioGroupInput({   
  name,
  control,
  rules = { required: true },
  label,
  disabled,
  id,
  items = [],
  onChange = () => {},
}) {

  const { control: backupControl } = useForm({
    mode: 'onChange',
  })
  const {
    field,
    fieldState: { error },
    formState, // eslint-disable-line no-unused-vars
  } = useController({
    name,
    control: control ?? backupControl,
    rules,
  })

  return (
    <Box>
      <MUIRadioGroupInput
        label={label}
        items={items}
        value={field.value}
        disabled={disabled}
        id={id}
        onChange={(e, v) => {
          field.onChange(e)
          onChange(v)
        }}
        onBlur={field.onBlur}
      />
      {error && <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>{label || 'This field'} is required</p>}
    </Box>
  )
}

export default MUIFormRadioGroupInput
