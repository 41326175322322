import { Box } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import OccupationIcon from '../../../../../Occupations/OccupationIcon'

export default function EligibilityIconList({ eligibleGroups }) {
  return (
    <Box display="flex" sx={{ gap: '.25rem' }}>
      {eligibleGroups.includes('Military') && <OccupationIcon size={24} iconType={3} />}
      {eligibleGroups.includes('FirstResponder') && <OccupationIcon size={24} iconType={1} />}
      {eligibleGroups.includes('Police') && <OccupationIcon size={24} iconType={4} />}
      {eligibleGroups.includes('Medical') && <OccupationIcon size={24} iconType={6} />}
      {eligibleGroups.includes('Government') && <OccupationIcon size={24} iconType={2} />}
      {eligibleGroups.includes('Education') && <OccupationIcon size={24} iconType={7} />}
      {eligibleGroups.includes('GovXFlag') && <OccupationIcon size={24} iconType={5} />}
    </Box>
  )
}

EligibilityIconList.propTypes = {
  eligibleGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
}
