import { putJson, getJson } from './baseApi'

export const fetchOccupations = () => {
	return getJson('/api/verification/occupations')
}

export const fetchPublishedOccupations = () => {
	return getJson('/api/verification/occupations/published')
}

export const fetchPublicOccupations = () => {
	return getJson('/api/verification/occupations/public')
}

export const fetchPublicAndPublishedOccupations = () => {
	return getJson('/api/verification/occupations/publicAndPublished')
}

export const fetchOccupationPrequalifiers = id => {
	return getJson(`/api/verification/occupations/${id}/prequalifiers`)
}

export const fetchOccupationInfoModel = id => {
	return getJson( `/api/verification/occupations/${id}/info`)
}

export const updateOccupation = occupation => {
	return putJson('/api/verification/occupations/update', occupation)
}

export const updateOccupationDisplayOrders = occupationDisplayOrders => {
	return putJson('/api/verification/occupations/displayOrder', { occupationDisplayOrders: occupationDisplayOrders })
}

export const updateOccupationInfoAndTypes = occupationInfoAndTypes => {
	return putJson('/api/verification/occupations/infoandtypes', occupationInfoAndTypes)
}
