import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  IconButton, Snackbar, Skeleton, Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import DataTable from '../../../../../../@Common/DataTable'
import { getWebhookSettings, setWebhookSettings } from '../../../../../../../redux/modules/partners/listings'

const DISPLAY_STATE = {
  INIT: 0,
  LOAD: 1,
  DATA: 2,
}

function WebhookSettings({ appId }) {
  const dispatch = useDispatch()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [display, setDisplay] = useState(DISPLAY_STATE.INIT)
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true // eslint-disable-line
    }
  }
  const handleCloseSuccess = () => setShowSuccess(false)
  const handleCloseFailure = () => setShowFailure(false)
  const handleEditClick = (id) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  const handleSaveClick = (id) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const saveWebhookSetting = (webhookSettings) => dispatch(
    setWebhookSettings({ appId, webhookSettings }),
  ).then((response) => (response ? setShowSuccess : setShowFailure)(true))

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    saveWebhookSetting(updatedRows.map(({ topic, callbackUrl, on }) => ({ topic, callbackUrl, delete: !on })))
    setRows(updatedRows)
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => setRowModesModel(newRowModesModel)

  const columns = [
    {
      field: 'on',
      type: 'boolean',
      numeric: false,
      sortable: true,
      headerName: 'On',
      editable: true,
    },
    {
      field: 'topic',
      numeric: false,
      sortable: true,
      headerName: 'Topic',
      width: 250,
    },
    {
      flex: 1,
      field: 'callbackUrl',
      numeric: false,
      sortable: true,
      headerName: 'Callback URL',
      width: 500,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Tooltip title="Save"><SaveIcon /></Tooltip>}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Tooltip title="Cancel"><CancelIcon /></Tooltip>}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }
        return [
          <GridActionsCellItem
            icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  const viewWebhookSettings = async () => {
    setDisplay(DISPLAY_STATE.LOAD)
    dispatch(getWebhookSettings(appId)).then((response) => {
      setDisplay(DISPLAY_STATE.DATA)
      setRows(response.map((x) => ({ ...x, id: x.topic, on: !x.delete })))
    })
  }

  return (
    <div style={{ borderTop: '1px dashed #ccc' }}>
      <div style={{ display: 'flex' }}>
        <h4>Webhook Settings</h4>
        <Tooltip title="View Webhook Settings">
          <IconButton
            label="View"
            variant="contained"
            color="primary"
            onClick={viewWebhookSettings}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </div>
      {
        display === DISPLAY_STATE.LOAD && (
          <Skeleton animation="wave" height={400} />
        )
      }
      {
        display === DISPLAY_STATE.DATA && (
          <DataTable
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        )
      }
      <Snackbar open={showSuccess} autoHideDuration={5000} onClose={handleCloseSuccess} message="Webhook setting saved" />
      <Snackbar open={showFailure} autoHideDuration={5000} onClose={handleCloseFailure} message="Could not save webhook setting, please check error logs" />
    </div>
  )
}

WebhookSettings.propTypes = {
  appId: PropTypes.string.isRequired,
}

export default WebhookSettings
