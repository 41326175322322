import React, { Fragment, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import UserIcon from '@mui/icons-material/Person'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { isLoggedIn } from '../../redux/selectors'
import { logout } from '../../redux/actions'
import { RouteConstants } from '../../setup/routeConstants'
import Button from '../Button'
import { compose, withState } from 'recompose'
import { LOCATION_CHANGE } from '../../setup/history'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'

const AccountMenu = () => {
	const [anchorElement, setAnchorElement] = useState(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const goToLogout = () => {
		dispatch(logout()).then(() => {
			navigate(RouteConstants.login.path)
			dispatch({ type: LOCATION_CHANGE })
		})
	}

	const goToLogin = () => {
		navigate(RouteConstants.login.path)
		dispatch({ type: LOCATION_CHANGE })
	}

	const loggedIn = useSelector(isLoggedIn)

	return loggedIn ? (
		<Fragment>
			<IconButton
				size='small'
				aria-label="More"
				label=""
				aria-haspopup="true"
				onClick={event => {
					setAnchorElement(event.target)
				}}
			>
				<UserIcon  style={{color:'white'}}/>
			</IconButton>
			<Menu anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={() => setAnchorElement(null)}>
				<MenuItem onClick={goToLogout}>Logout</MenuItem>
			</Menu>
		</Fragment>
	) : (
		<div style={{paddingLeft:'10px'}}>
			<Button label="Log in" fullWidth={false} onClick={goToLogin}>
				Log in
			</Button>
		</div>
	)
}

export default AccountMenu
