import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import {fetchUserByEmail, searchUsersByName, showErrorNotification} from '../../redux/actions'
import {getUserPath, RouteConstants} from '../../setup/routeConstants'

const SearchButtonContent = ({processing}) => processing
    ? (
        <>
            <Box sx={{width: '100%'}}>
                <LinearProgress />
            </Box>
            &nbsp;
        </>
    )
    : <>Search</>

function SearchPopover({
    anchorEl,
    isOpen,
    onClose,
    handleSubmit}) {
    const [processing, setProcessing] = useState(false);
    const [email, setEmail] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onSearchClick = () => {
        setProcessing(true)
        if (email) {
            dispatch(fetchUserByEmail(email, true)).then(response => {
                setProcessing(false)
                if (response) {
                    onClose()
                    navigate(getUserPath(response.id))
                }
            })
            .catch(err => {
                setProcessing(false)
            })
        } else {
            dispatch(searchUsersByName(firstName, lastName, 'users-queue'))
                .then(response => {
                    setProcessing(false)
                    if (response) {
                        if (response.length === 1) {
                            navigate(getUserPath(response[0].id))
                            onClose()
                            return
                        }
                        navigate(RouteConstants.users.index.path)
                        onClose()
                    }
                })
                .catch(err => {
                    setProcessing(false)
                    console.log('searchUsersByName error', err)
                    showErrorNotification('Something went wrong. Please try that again.')
                })
        }
    }

    return (
        <Popover
            anchorEl={anchorEl}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            open={isOpen}
            onClose={onClose}
            style={{padding: '1em 2em'}}
        >
            <div style={{width: '300px', padding: '1em 2em'}}>
                <Grid container direction="column">

                    <Grid item>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} direction="column">
                                <Grid item >
                                    <TextField
                                        variant='filled'
                                        type="email"
                                        name="email"
                                        fullWidth
                                        label="Email"
                                        onChange={event => setEmail(event.target.value)}
                                        onKeyPress={ev => {
                                            if (ev.key === 'Enter') {
                                                onSearchClick()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        name="firstName"
                                        style={{marginRight: '8px'}}
                                        variant='filled'
                                        label="First name"
                                        fullWidth
                                        onChange={event => setFirstName(event.target.value)}
                                        onKeyPress={ev => {
                                            if (ev.key === 'Enter') {
                                                onSearchClick()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        name="lastName"
                                        label="Last name"
                                        fullWidth
                                        variant='filled'
                                        onChange={event => setLastName(event.target.value)}
                                        onKeyPress={ev => {
                                            if (ev.key === 'Enter') {
                                                onSearchClick()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        label="Search"
                                        color="primary"
                                        fullWidth
                                        variant="contained"
                                        disabled={processing || !(email || firstName || lastName)}
                                        onClick={onSearchClick}
                                        style={{margin: '0 auto 5px'}}
                                    >
                                        <SearchButtonContent processing={processing} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>

                <br />
            </div>
        </Popover>
    )
}

SearchPopover.prototypes = {
    anchorEl: PropTypes.element.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default SearchPopover