import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Grid, Typography } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import PropTypes from 'prop-types'
import { ModalContainer } from '../../../../../Modals/ModalContainer'
import { MUIFormSelectInput } from '../../../../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import { MUIFormTextAreaInput } from '../../../../../@Common/Form/TextAreaInput/FormTextAreaInput'
import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../../../redux/actions'
import DataTable from '../../../../../@Common/DataTable'
import EligibilityIconList from '../OffersSection/EligibilityIconList'
import { MUIFormCheckboxInput } from '../../../../../@Common/Form/CheckboxInput/FormCheckboxInput'
import { SOURCE } from '../../../../../../constants/partners'
import moment from 'moment'
import { US_STATES_ARRAY } from '../../../../../../constants/common'
import MuiDatePicker from '../../../../../@Common/Form/DateTimePicker/MuiDatePicker'
import MuiTimePicker from '../../../../../@Common/Form/DateTimePicker/MuiTimePicker'

const map = (x) => !!x ? x : null

const mapMoment = (x, f) => (!!x ? moment.isMoment(x) ? moment(x).format(f) : x : null)

const mapEvent = ({
    data,
    eventId,
    listingId,
    linkedOffers
}) => data == null ? null : ({
    eventId,
    listingId,
    name: map(data.name),
    description: map(data.details),
    claimSettings: {
        offerUrl: map(data.link)
    },
    eventLocation: {
        name: map(data.locationName),
        description: null,
        address1: map(data.address),
        address2: null,
        city: map(data.city),
        state: map(data.state),
        zip: map(data.zipCode),
        countryCode: null,
    },
    eventTime: {
        dateTimeParts: {
            eventStartDate: mapMoment(data.startDate, 'YYYY-MM-DD'),
            eventStartTime: mapMoment(data.startTime, 'HH:mm:ss'),
            eventEndDate: mapMoment(data.endDate, 'YYYY-MM-DD'),
            eventEndTime: null
        }
    },
    channels: Object.keys(data.channels).reduce((reduced, key) => [...reduced, ...data.channels[key] ? [key] : []], []),
    linkedOffers,
})

const getLinkableOffers = (listingOffers) => listingOffers.filter(({ source: { type } }) => type.toLowerCase() === SOURCE.direct)

function EventCreateEditModal({
    event,
    offers,
    lookups,
    onClickSubmit,
    partnerListing,
}) {
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()
    const [eventLinkedOffers, setEventLinkedOffers] = useState(event ? event.linkedOffers : [])
    const isEdit = !!event

    const columns = [
        {
            field: 'name',
            headerName: 'Offer',
            flex: 2
        },
        {
            field: 'discount',
            headerName: 'Discount',
            flex: 1,
            valueGetter: ({ row: { discount: { scheme: { amount, typeName } } } }) =>
                amount ? typeName === 'PercentOff' ? `${amount}%` : `$${amount}` : null,
        },
        {
            field: 'affiliations',
            headerName: 'Eligible Affiliation',
            flex: 2,
            renderCell: ({ row }) => { return <EligibilityIconList eligibleGroups={row.verification.eligibleGroups} /> }

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,

        }
    ]

    const getSelectedChannels = () => {
        if (isEdit) {
            return lookups.data?.channels.reduce((carry, currentValue) => ({
                ...carry,
                [`channels.${currentValue.value}`]: Boolean(event.channels
                    .find((channel) => channel === currentValue.value)),
            }), {})
        }
        return lookups.data?.channels
            .filter((x) => x.defaultChecked === true)
            .reduce((carry, currentValue) => ({ ...carry, [`channels.${currentValue.value}`]: true }), {})
    }

    const {
        handleSubmit, watch, control, setValue, setError, reset, formState: { errors }
    } = useForm({
        mode: 'all',
        defaultValues: !isEdit ? {
            name: '',
            link: '',
            startDate: '',
            startTime: '',
            endDate: '',
            locationName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            details: '',
            linkedOffers: [],
            ...getSelectedChannels()
        } : {
            name: event.name,
            link: event.claimSettings.offerUrl,
            startDate: event.eventTime.dateTimeParts.eventStartDate,
            startTime: event.eventTime.dateTimeParts.eventStartTime,
            endDate: event.eventTime.dateTimeParts.eventEndDate,
            locationName: event.eventLocation.name,
            address: event.eventLocation.address1,
            city: event.eventLocation.city,
            state: event.eventLocation.state,
            zipCode: event.eventLocation.zip || '',
            details: event.description,
            linkedOffers: event.linkedOffers,
            ...getSelectedChannels()
        }
    })

    const currentStartDate = moment(watch('startDate'))

    const onSubmit = async (data) => {
        setProcessing(true)
        try {
            const eventObj = mapEvent({
                data,
                eventId: (event ? event.eventId : null),
                listingId: partnerListing.id,
                linkedOffers: eventLinkedOffers,
            })
            await onClickSubmit({ data: eventObj, isEdit })
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        } finally {
            setProcessing(false)
        }
    }

    return (
        <ModalContainer
            title={`${isEdit ? 'Edit' : 'Create'} Event`}
            maxWidth="md"
            fullWidth="true"
            drawer
            processing={processing || lookups.isLoading}
            hideModal={() => { }}
            actions={{
                primaryAction: { label: 'Save', onClick: handleSubmit(onSubmit) },
                secondaryAction: { label: 'Cancel', onClick: () => { dispatch(hideModal()) } },
            }}
        >
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={12}>
                    <MUIFormTextInput
                        control={control}
                        name="name"
                        label="Event Name"
                        rules={{ required: true }}
                        gxid="name"
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={12}>
                    <MUIFormTextInput
                        control={control}
                        rules={{ required: true, pattern: { value: /^https?:\/\// } }}
                        name="link"
                        label="Event URL"
                        gxid="link"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: '16px', minHeight: '58px' }}>
                <Grid item xs={6}>
                    <MuiDatePicker
                        label={'Event Date'}
                        name={'startDate'}
                        control={control}
                        gxId={'eventStartDate'}
                        disablePast={!isEdit ? true : false}
                        minDate={!isEdit ? moment().add(1, 'days') : null}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiTimePicker
                        control={control}
                        name="startTime"
                        label="Event Start Time"
                        gxId={'eventStartTime'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: '16px', minHeight: '58px' }}>
                <Grid item xs={6}>
                    <MuiDatePicker
                        name="endDate"
                        label={'End Date'}
                        control={control}
                        gxId={'eventEndDate'}
                        minDate={currentStartDate ? currentStartDate : moment()}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={12}>
                    <MUIFormTextInput
                        control={control}
                        name="locationName"
                        label="Location Name"
                        gxid="locationName"
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '16px' }}>
                <Grid item xs={12}>
                    <MUIFormTextInput
                        control={control}
                        rules={{ pattern: { value: /^(?!.*[.,' -]{2})(?!.*[,' -]$)(?!^[,' -])(?!.*\s{2,})[a-zA-Z0-9][a-zA-Z0-9 .,\'-]*[a-zA-Z0-9.]$/ } }}
                        hideErrorMessage
                        name="address"
                        label="Address"
                        gxid="address"
                    />
                    <Box>
                        <ErrorMessage
                            errors={errors}
                            name={'address'}
                            render={() => (
                                <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                                    This field contains invalid characters.
                                </p>
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: '16px', minHeight: '58px' }}>
                <Grid item xs={6}>
                    <MUIFormTextInput
                        control={control}
                        rules={{ pattern: { value: /^(?!.*[.,' -]{2})(?!.*[,' -]$)(?!^[,' -])(?!^\s)(?!.*\s$)[a-zA-Z0-9][a-zA-Z0-9 .,\'-]*[a-zA-Z0-9]$/ } }}
                        hideErrorMessage
                        name="city"
                        label="City"
                        gxid="city"
                    />
                    <Box>
                        <ErrorMessage
                            errors={errors}
                            name={'city'}
                            render={() => (
                                <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                                    This field contains invalid characters.
                                </p>
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <MUIFormSelectInput
                        name="state"
                        control={control}
                        label="State"
                        items={US_STATES_ARRAY}
                        gxid="createCodePoolcodeLimitType"
                    />
                </Grid>
                <Grid item xs={4}>
                    <MUIFormTextInput
                        control={control}
                        rules={{ pattern: { value: /^(\d{5})?$/ }, maxLength: 5 }}
                        type='number'
                        maxLength={5}
                        name="zipCode"
                        label="Zip Code"
                        gxid="eventZip"
                        hideErrorMessage={true}
                        styles={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                            "& input[type=number]": {
                                MozAppearance: "textfield",
                            },
                        }}
                    />
                    <Box>
                        <ErrorMessage
                            errors={errors}
                            name={'zipCode'}
                            render={() => (
                                <p style={{ color: '#bd2326', fontSize: '.875rem', marginTop: '0.25rem' }}>
                                    Zip code must be a 5-digit number
                                </p>
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '16px', minHeight: '58px' }}>
                <Grid item xs={12}>
                    <MUIFormTextAreaInput
                        control={control}
                        name="details"
                        label="Event Information"
                        gxid="details"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>Linked Offers</Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        checkboxSelection
                        onRowSelectionModelChange={(data) => {
                            setEventLinkedOffers(data)
                        }}
                        rowSelectionModel={eventLinkedOffers}
                        selected
                        getRowId={(row) => row.offerId}
                        rowSelection={true}
                        loading={false}
                        rows={getLinkableOffers(offers)}
                        columns={columns}
                        disableRowSelectionOnClick
                        pagination={false}
                        disableColumnFilter
                        hideFooterRowCount
                        hideFooter
                        hideFooterSelectedRowCount
                        autoHeight
                        pageSize={5}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ marginY: '16px' }}>
                <Grid item xs={6} sx={{ paddingRight: '100px' }}>
                    <Typography style={{ margin: '0px' }}>
                        <strong>Sales Channels</strong>
                    </Typography>
                    <p>
                        Control where the offer will show up by configuring the display channels.
                    </p>
                </Grid>
                <Grid item xs={6}>
                    {lookups.data.channels.map((item) => (
                        <MUIFormCheckboxInput
                            key={item.value}
                            name={`channels.${item.value}`}
                            label={item.text}
                            control={control}
                            gxid={`channel.${item.value}`}
                            style={item.value.toLowerCase() === SOURCE.shopify ? { display: 'none' } : {}}
                        />
                    ))}
                </Grid>
            </Grid>

        </ModalContainer>
    )
}
/**/
EventCreateEditModal.defaultProps = {
    partnerListing: undefined,
}

EventCreateEditModal.propTypes = {
    partnerListing: PropTypes.shape({
        id: PropTypes.string,
    }),
}

export default EventCreateEditModal
