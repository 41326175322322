import React from 'react'
import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'
import useOffersListing from './useOffersListing'
import useOfferCreateEdit from './useOfferCreateEdit'
import OffersListing from './OffersListing'

function OffersSection({
  affiliate,
  verificationListing,
}) {
  const {
    offers,
    offersIsLoading,
    offersIsSuccess,
    offersHasError,
  } = useOutletContext()

  return (
    <OffersListing
      affiliate={affiliate}
      verificationListing={verificationListing}
      offers={offers}
      offersIsLoading={offersIsLoading}
      offersIsSuccess={offersIsSuccess}
      offersHasError={offersHasError}
      {...useOfferCreateEdit()}
      {...useOffersListing()}
    />
  )
}

OffersSection.propTypes = {
  affiliate: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  verificationListing: PropTypes.shape({

  }),
  offers: PropTypes.arrayOf(PropTypes.shape({
    offerId: PropTypes.string.isRequired,
  })),
  offersIsLoading: PropTypes.bool,
  offersIsSuccess: PropTypes.bool,
  offersHasError: PropTypes.bool,
}

OffersSection.defaultProps = {
  verificationListing: null,
}

export default OffersSection
