import React, { useState } from 'react'
import DataTable from '../../../../../@Common/DataTable'
import NoRows from '../../../../../@Common/DataTable/NoRows'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import InvoiceDetails from './InvoiceDetails'

const InvoiceOptionMenu = () => {

    const [menuAnchor, setMenuAnchor] = useState(null)

    return (
        <Box>
            <IconButton style={{ paddingTop: '9px' }} onClick={(e) => setMenuAnchor(e.currentTarget)}>
                <GridMoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => {
                    setMenuAnchor(null)
                }}
            >
                <MenuItem onClick={() => { }} data-gxid="deleteInvoice">
                    View Invoice
                </MenuItem>
            </Menu>
        </Box>
    )
}

function Invoices({
    invoices,
    isLoading,
    isFetching,
    invoiceDetails,
    getDetails,
    billingTerms,
    detailsError }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const columns = [
        {
            field: 'id',
            headerName: 'Invoice Id',
            flex: 1
        },
        {
            field: 'currentPeriodStart',
            headerName: 'Invoice Period',
            flex: 2,
            valueGetter: (params) => (`${moment(params.row.currentPeriodStart).format('MM/DD/YYYY')}-${moment(params.row.currentPeriodEnd).format('MM/DD/YYYY')}`)

        },
        {
            field: 'amountDue',
            headerName: 'Total',
            valueGetter: (params) => (`$${params.row.amountDue.toFixed(2)}`),
            flex: 1,
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 1,
            valueGetter: (params) => params.row.dueDate ? new Date(moment(params.row.dueDate).format('MM/DD/YYYY')) : null,
            renderCell: (params) => (`${moment(params.row.dueDate).format('M/DD/YYYY')}`)
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1
        },
    ]

    return (
        searchParams.get('invoiceId') ?
            <InvoiceDetails
                isError={detailsError}
                invoiceId={searchParams.get('invoiceId')}
                billingTerms={billingTerms}
                invoiceDetails={invoiceDetails} getDetails={getDetails} /> :
            <DataTable
                slots={{
                    noRowsOverlay: () => <NoRows message={'No invoices found'} />,
                }}
                pagination={true}
                hideFooterSelectedRowCount
                rowSelection
                loading={isLoading || isFetching}
                rowCount={invoices ? invoices.length : 0}
                rows={invoices ? invoices : []} dfns
                pageSizeOptions={[25, 50, 100, 500]}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100, 500]}
                disableRowSelectionOnClick
                onRowClick={(row) => setSearchParams({ invoiceId: row.row.id })}
            />
    )
}

export default Invoices