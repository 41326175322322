import { putJson, postJson, getJson, deleteJson } from './baseApi'

export const getCampaignsByListingId = listingId => {
	return getJson(`/api/listings/${listingId}/campaigns`)
}

export const getCampaign = campaignId => {
	return getJson(`/api/verification/campaigns/${campaignId}`)
}

export const createCampaign = campaign => {
	return postJson('/api/verification/campaigns', campaign)
}

export const deleteCampaign = campaign => {
	return deleteJson(`/api/verification/campaigns/${campaign.id}`)
}

export const updateCampaign = campaign => {
	return putJson(`/api/verification/campaigns/${campaign.id}`, campaign)
}

export const saveCampaignTemplateSettings = (campaignId, settings) => {
	return putJson(`/api/verification/campaigns/${campaignId}/settings/template`, settings)
}

export const saveCampaignMessagingSettings = (campaignId, settings) => {
	return putJson(`/api/verification/campaigns/${campaignId}/settings/messaging`, settings)
}

export const getCampaignTemplateSettings = id => {
	return getJson(`/api/verification/campaigns/${id}/settings/template`)
}

export const getCampaignMessagingSettings = id => {
	return getJson(`/api/verification/campaigns/${id}/settings/messaging`)
}

export const getCampaignOccupations = campaignId => {
	return getJson(`/api/verification/campaigns/${campaignId}/occupations`)
}

export const setCampaignOccupations = (campaignId, occupationMappings) => {
	return putJson(`/api/verification/campaigns/${campaignId}/occupations`, { campaignId: campaignId, occupationMappings: occupationMappings })
}