/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import ModalContainer from '../ModalContainer'
import { hideModal, updateBillingTerms } from '../../../redux/actions'
import { pluralize } from '../../../formatting'
import { MUIFormSelectInput } from '../../@Common/Form/SelectInput/FormSelectInput'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export const snapDayRange = () => [...Array(28).keys()]

export const isShopify = (paymentInfoType) => paymentInfoType === 'Shopify'

export function UpdateBillingTermsModal({
  billingTerms,
  onModalClose,
}) {
  const dispatch = useDispatch()

  const {
    handleSubmit, control, setValue, watch,
  } = useForm({
    defaultValues: {
      billingTerms,
      billingTermsSnapDay: billingTerms.snapDay,
      netTerms: billingTerms.netTerms,
      paymentInfoType: billingTerms.paymentInfo.type,
    },
  })

  const [processing, setProcessing] = useState(false)

  const onCancel = () => {
    onModalClose()
    dispatch(hideModal())
  }

  const onSave = (values) => {
    setProcessing(true)
    const updatedBillingTerms = { ...billingTerms }
    updatedBillingTerms.paymentInfo.type = values.paymentInfoType
    updatedBillingTerms.snapDay = values.billingTermsSnapDay
    updatedBillingTerms.netTerms = values.netTerms

    const command = {}
    command.billingTerms = updatedBillingTerms

    return dispatch(updateBillingTerms(updatedBillingTerms.id, command))
      .then(() => {
        setProcessing(false)
        onModalClose()
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('updateBillingTerms error', err)
      })
  }

  const onPaymentInfoTypeChanged = (value) => {
    if (value === 'Shopify') {
      setValue('netTerms', '1')
    } else {
      setValue('netTerms', '30')
    }
    setValue('billingTermsSnapDay', '28')
  }

  return (
    <ModalContainer
      title="Edit Billing Terms"
      processing={processing}
      modal
      maxWidth="sm"
      fullWidth="true"
      actions={{
        primaryAction: {
          label: 'Confirm',
          onClick: handleSubmit(onSave),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSave)} name="updateBillingTerms" style={{ marginTop: '10px', padding: '0px 40px' }}>
        <div>
          <MUIFormSelectInput
            control={control}
            name="billingTermsSnapDay"
            label="Monthly Billing Date"
            disabled
            items={[
              ...(snapDayRange().map((i) => ({ key: i + 1, value: i + 1, label: (i + 1).toString() }))),
              { key: 0, value: 0, label: 'Last day of month' },
            ]}
          />
        </div>
        <Box mt="10px">
          <MUIFormTextInput
            control={control}
            name="netTerms"
            label="Payment Terms"
            rules={{
              required: 'Required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Must be a number',
              },
            }}
            disabled
            suffix={pluralize(Number(watch('netTerms')), 'day', 'days')}
          />
        </Box>
        <Box mt="10px">
          <MUIFormSelectInput
            control={control}
            name="paymentInfoType"
            label="Payment Method"
            items={[
              { key: 'Shopify', value: 'Shopify', label: 'Shopify' },
              { key: 'Manual', value: 'Manual', label: 'Manual' },
            ]}
            onChange={onPaymentInfoTypeChanged}
          />
        </Box>
      </form>
    </ModalContainer>
  )
}

UpdateBillingTermsModal.propTypes = {
  onModalClose: PropTypes.func,
  billingTerms: PropTypes.shape({
    id: PropTypes.number,
    snapDay: PropTypes.number,
    netTerms: PropTypes.number,
    paymentInfo: PropTypes.shape({
      type: PropTypes.string,
      shopify: PropTypes.shape({
        appSubscriptionIdNumber: PropTypes.number,
      }),
    }),
  }),
}

export default UpdateBillingTermsModal
