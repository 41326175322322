/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createSlice } from '@reduxjs/toolkit'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { hideModal } from '../redux/actions'

window.modalChildren = {}

function constructModalObject({ inUrl = false, ...modal }, key) {
  const m = { ...modal, inUrl, key }
  if (m.props?.children) {
    window.modalChildren[key] = { ...m.props.children }
    delete m.props.children
  }
  return m
}

const initialState = {
  modals: {},
  activeModal: null,
  currentModalKey: null,
}
const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    appendModals(state, action) {
      if (!action.payload) return
      const modals = Object.keys(action.payload).reduce((acc, key) => {
        acc[key] = constructModalObject(action.payload[key], key)
        return acc
      }, {})

      state.modals = { ...state.modals, ...modals }
    },
    openModal(state, action) {
      const { modalKey, props } = action.payload
      state.activeModal = state.modals[modalKey]
      state.currentModalKey = modalKey
      state.activeModal.props = { ...state.activeModal?.props || {}, ...props }
    },
    closeModal(state) {
      state.activeModal = null
      state.currentModalKey = null
    },
    updateModalProps(state, action) {
      const { modalKey, props } = action.payload
      state.modals[modalKey] = constructModalObject(
        { ...state.modals[modalKey], props: { ...state.activeModal?.props || {}, ...props } },
        modalKey,
      )
      if (modalKey === state.currentModalKey) {
        state.activeModal.props = state.modals[modalKey].props
      }
    },
  },
})

export const { openModal, closeModal, updateModalProps } = modalsSlice.actions
export const modalsReducer = modalsSlice.reducer


/**
 *  @typedef {{component: React.ComponentType, inUrl: boolean, props: Object}} Modal
 */
/**
 * @param {Object.<string, Modal>} initialModals
 */
export function useModal(initialModals, portalElement = document.body) {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(modalsSlice.actions.appendModals(initialModals))
    window.addEventListener("popstate", (e) => dispatch(hideModal(), closeModal(false)))
    return () => window.removeEventListener("popstate", (e) => dispatch(hideModal(), closeModal(false)))
  }, [])

  const modals = useSelector((state) => state.modals.modals)
  const activeModal = useSelector((state) => state.modals.activeModal)

  const modalExists = (modalKey) => modals[modalKey]

  const openModal = (modalKey, props = {}) => {
    if (!modalExists(modalKey)) {
      console.error(`Modal ${modalKey} is not defined`)
      return
    }
    if (modals[modalKey].inUrl) setSearchParams({ modal: modalKey })
    dispatch(modalsSlice.actions.openModal({ modalKey, props }))
  }

  const closeModal = (setParams = true) => {
    searchParams.delete('modal')
    if (setParams) {
      setSearchParams(searchParams)
    }
    dispatch(modalsSlice.actions.closeModal())
  }

  const renderModal = () => {
    if (!activeModal) return null
    return createPortal(
      React.createElement(
        activeModal.component,
        {
          ...activeModal.props,
          closeModal,
        },
        window.modalChildren[activeModal.key],
      ),
      portalElement,
    )
  }

  const updateModalProps = (modalKey, props = {}) => {
    dispatch(modalsSlice.actions.updateModalProps({ modalKey, props }))
  }

  useEffect(() => {
    if (searchParams.has('modal') && modals[searchParams.get('modal')] && activeModal === null) {
      openModal(searchParams.get('modal'), modals[searchParams.get('modal')].props)
    }
  }, [searchParams, modals])

  return {
    openModal,
    closeModal,
    renderModal,
    updateModalProps,
  }
}
