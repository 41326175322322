import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducer'
import loggerMiddleware from './middleware/LoggerMiddleware'
import pageTitleMiddleware from './middleware/PageTitleMiddleware'
import monitorReducerEnhancer from './enhancer/monitorReducer'
import * as schema from '../api/schema'
import { api } from './slices/apiSlice'
import './slices/lookupsSlice'

const isProduction = process.env.NODE_ENV === 'production'

// used for tests only right now
export const setupTestStore = (preloadedState) => configureStore({
  reducer: {
    ...reducer,
    [api.reducerPath]: api.reducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk: {
        extraArgument: { schema },
      },
      // legacy redux modules don't abide by new redux rules, so disable checks
      immutableCheck: false,
      serializableCheck: false,
    }).concat(pageTitleMiddleware, api.middleware)
    return middleware
  },
})

const store = () => configureStore({
  reducer: {
    ...reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk: {
        extraArgument: { schema },
      },
      // legacy redux modules don't abide by new redux rules, so disable checks
      immutableCheck: false,
      serializableCheck: false,
    }).concat(pageTitleMiddleware, api.middleware)
    if (!isProduction) {
      middleware.push(loggerMiddleware, pageTitleMiddleware)
    }
    return middleware
  },
  enhancers: [...isProduction ? [] : [monitorReducerEnhancer]],
  devTools: !isProduction,
})

export default store
