import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import ModalContainer from '../ModalContainer'
import { hideModal, createListingRedirectUrl } from '../../../redux/actions'
import { MUIFormTextInput } from '../../@Common/Form/TextInput/FormTextInput'

export function CreateListingRedirectUrlModal({ listingId }) {
  const dispatch = useDispatch()

  const [processing, setProcessing] = useState(false)

  const onCancel = () => dispatch(hideModal())

  const { handleSubmit, control } = useForm()

  const onSubmit = (values) => {
    setProcessing(true)

    return dispatch(createListingRedirectUrl(listingId, values.redirectUrl))
      .then(() => {
        setProcessing(false)
        dispatch(hideModal())
      })
      .catch((err) => {
        setProcessing(false)
        console.log('createListingRedirectUrl error', err)
      })
  }

  return (
    <ModalContainer
      title="Add Whitelisted URL"
      modal
      maxWidth="sm"
      fullWidth="true"
      processing={processing}
      actions={{
        primaryAction: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
        },
        secondaryAction: {
          label: 'Cancel',
          onClick: onCancel,
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '15px' }}>
        <MUIFormTextInput
          name="redirectUrl"
          label="Whitelisted URL"
          autoFocus
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^https?:\/\//,
              message: 'URL must start with http:// or https://',
            },
          }}
        />
      </form>
    </ModalContainer>
  )
}

CreateListingRedirectUrlModal.propTypes = {
  listingId: PropTypes.number.isRequired,
}

export default CreateListingRedirectUrlModal
