export * from './modules/verification/selectors'
export * from './modules/users/selectors'
export * from './modules/affiliates/selectors'
export * from './modules/authentication/selectors'
export * from './modules/reports/selectors'
export * from './modules/tickets/selectors'
export * from './modules/partners/selectors'
export * from './modules/offers/selectors'
export * from './modules/billing/selectors'
export { selectNotification } from './modules/notifications'
export { selectDrawerStatus } from './modules/drawer'

//begin specialized selectors

import { createSelector } from 'reselect'
import { selectVerificationRequests } from './modules/verification/selectors'

/*
* - users.users[id]
* - verification.verificationRequests
*/
export const selectUserVerificationRequests = userId => {
	return createSelector(selectVerificationRequests, requests => requests.filter(r => r.userId.toLowerCase() === userId.toLowerCase() && (r.status === 'Approved' || r.status === 'Pending' || r.status === 'Failed' || r.status === 'Partial' || r.status === 'Expired' || (r.status === 'Deleted' && r.type === 'Offline'))))
}

export const selectDuplicateUserVerificationRequests = userId => {
	return createSelector(selectVerificationRequests, requests => requests.filter(r => r.userId.toLowerCase() === userId.toLowerCase() && r.duplicatedUserId && r.duplicatedUserId != null))
}
