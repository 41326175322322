import React, { useState } from 'react'
import DataTable from '../../../../../@Common/DataTable'
import { Box, Button, ButtonGroup, Chip, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, gridSortedRowIdsSelector } from '@mui/x-data-grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ArrowDropDown } from '@mui/icons-material'
import NoRows from '../../../../../@Common/DataTable/NoRows'
import PropTypes from 'prop-types'
import moment from 'moment'
import EventOptionsMenu from './EventOptionsMenu'
import RowSelectionFooter from '../../../../../@Common/DataTable/RowSelectionFooter';

const formatDate = (date) => date ? moment(date.length > 10 ? date : `${date}T00:00:00Z`).format('MM/DD/YYYY') : ''
const formatTime = (time) => time ? moment(time, ['HH:mm']).format('hh:mm a') : ''

const getCsvColumns = (events) => {
    const csvColumns = []
    csvColumns.push({ field: 'Event ID', valueGetter: ({ row }) => row.id })
    csvColumns.push({ field: 'Event Name', valueGetter: ({ row }) => row.name })
    csvColumns.push({ field: 'Event URL', valueGetter: ({ row }) => row.offerUrl })
    csvColumns.push({ field: 'Event Date', valueGetter: ({ row }) => formatDate(row.date) })
    csvColumns.push({ field: 'Start Time', valueGetter: ({ row }) => formatTime(row.startTime) })
    csvColumns.push({ field: 'End Date', valueGetter: ({ row }) => formatDate(row.endDate) })
    csvColumns.push({ field: 'City', valueGetter: ({ row }) => row.city })
    csvColumns.push({ field: 'State', valueGetter: ({ row }) => row.state })
    csvColumns.push({ field: 'Zip', valueGetter: ({ row }) => row.zip })
    csvColumns.push({ field: 'Event Info', valueGetter: ({ row }) => row.description })

    const maxOffers = events ? events.reduce((max, x) => (x && x.linkedOffers && x.linkedOffers.length > max ? x.linkedOffers.length : max), 0) : 0
    for (let offerIndex = 0; offerIndex < maxOffers; offerIndex++) {
        csvColumns.push({
            field: `Offer_ID ${offerIndex + 1}`,
            valueGetter: ({ row }) => row.linkedOffers[offerIndex]
        })
    }

    csvColumns.push({ field: 'GOVX Sales Channel', valueGetter: ({ row }) => row.channels.findIndex(x => x === 'GovXMarketplace') > -1 })
    csvColumns.push({ field: 'Offer Page Sales Channel', valueGetter: ({ row }) => row.channels.findIndex(x => x === 'GovXMarketplaceOfferPage') > -1 })
    csvColumns.push({ field: 'GOVX ID link', valueGetter: ({ row }) => row.eventLink })
    return csvColumns
}

function CustomToolbar({ exportFields }) {
    return (
        <GridToolbarContainer sx={{ padding: '4px 4px 4px' }}>
            <GridToolbarFilterButton />
            <GridToolbarExport
                csvOptions={{
                    fields: exportFields,
                    getRowsToExport: ({ apiRef }) => gridSortedRowIdsSelector(apiRef)
                }}
                slotprops={{
                    tooltip: { title: 'Export data' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    );
}

function EventsList({
    events,
    onClickCreateEvent,
    onClickEditEvent,
    onClickImportEvents,
    lookups,
    onClickArchiveEvents,
    openGovXIdLinkModal,
    isLoading,
    isFetching,
    onClickBulkEditEvents
}) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [eventsSelected, setEventsSelected] = useState([])

    const [filters, setFilters] = useState({
        items: [{
            id: 'eventDate',
            field: 'date',
            operator: 'onOrAfter',
            value: moment().format('YYYY-MM-DD'),
        },
        ],
        logicOperator: 'and',
    })
    const options = ['Create Event', 'Import Events'];

    const csvColumns = getCsvColumns(events)

    const columns = [
        {
            field: 'name',
            headerName: 'Event',
            width: 200
        },
        {
            field: 'date',
            headerName: 'Date',
            type: 'date',
            width: 200,

            valueGetter: (params) => (params.row.date
                ? new Date(params.row.date) : null),
            renderCell: (row) => <>
                {moment(row.row.date).format('MM/DD/YYYY')}
                {row.row.startTime !== null ?
                    ' at ' + moment(row.row.startTime, ['HH:mm']).format('hh:mm a') :
                    ''}
            </>
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 200,
            valueGetter: (params) => (params.row.city && params.row.state ? `${params.row.city}, ${params.row.state}` : '')
        },
        {
            field: 'linkedOffers',
            headerName: 'Linked Offers',
            width: 200,
            valueGetter: (params) => (params.row.linkedOffers.length)
        },
        {
            field: 'channels',
            headerName: 'Sales Channels',
            width: 200,
            type: 'singleSelect',
            valueOptions: ['All Channels', 'GOVX', 'Offer Page', 'No Channels'],
            valueGetter: (params) => {
                const channels = params.row.channels.map(x => lookups.data?.channels.find(channel => channel.value === x)?.text)
                if (channels.length > 1) {
                    return 'All Channels'
                } else if (channels.length === 0) {
                    return 'No Channels'
                } else {
                    return channels[0]
                }
            },
            renderCell: (row) => <>{row.row.channels.map((x, i) => <Chip key={i} sx={{ marginLeft: '5px' }} label={lookups.data?.channels.find(channel => channel.value === x)?.text} />)}</>
        },
        {
            field: '',
            headerName: '',
            width: 20,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (row) => <EventOptionsMenu row={row} onClickEditEvent={onClickEditEvent} onClickArchiveEvent={onClickArchiveEvents} onClickGovXIdLink={openGovXIdLinkModal} />
        },
    ]

    const columnVisibilityModel = {}
    const exportFields = []

    csvColumns.forEach(x => columns.push(x))
    csvColumns.forEach(x => columnVisibilityModel[x.field] = false)
    csvColumns.forEach(x => exportFields.push(x.field))

    const handleClick = () => {
        onClickCreateEvent()

        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        if (options[index] === 'Create Event') {
            onClickCreateEvent()

        } else {
            onClickImportEvents()
        }
        setOpen(false)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleOnClickCancel = () => {
        setEventsSelected([])
    }

    const handleOnClickArchiveEvents = () => {
        onClickArchiveEvents(eventsSelected)
    }

    const handleOnClickBulkEditEvents = () => {
        onClickBulkEditEvents(eventsSelected)
    }

    return (
        <Box>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                    >
                        <Button onClick={handleClick} variant='contained' color='primary'>{options[selectedIndex]}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant='contained' color='primary'
                        >
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option + index}
                                                    disabled={index === 2}
                                                    selected={0}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
            <Box sx={{ minHeight: '800px' }}>
                <DataTable
                    columnVisibilityModel={columnVisibilityModel}
                    slots={{
                        noRowsOverlay: () => <NoRows importOptions={{ onClick: () => onClickImportEvents(), buttonText: 'Bulk Create Events' }} message={'No events have been added'} />,
                        toolbar: () => <CustomToolbar exportFields={exportFields} />,
                        exportIcon: FileDownloadIcon,
                        footer: () => <RowSelectionFooter rows={eventsSelected} onClickSubmit={handleOnClickBulkEditEvents} onClickSubmitCustom={() => handleOnClickArchiveEvents()} onClickCancel={() => handleOnClickCancel()} submitText="Edit" customButtonText='Archive' />
                    }}
                    rowSelectionModel={eventsSelected}
                    pagination={!(eventsSelected.length > 0)}
                    hideFooterRowCount={eventsSelected.length > 0}
                    onRowSelectionModelChange={(data) => {
                        setEventsSelected(data)
                    }}
                    hideFooterSelectedRowCount
                    rowSelection
                    checkboxSelection
                    loading={isLoading || isFetching}
                    rowCount={events ? events.length : 0}
                    rows={events ? events : []}
                    pageSizeOptions={[25, 50, 100, 500]}
                    filterModel={{
                        items: filters.items,
                        logicOperator: filters.logicOperator,
                    }}
                    onFilterModelChange={(model) => {
                        setFilters({
                            items: model.items,
                            logicOperator: model.logicOperator,
                        })
                    }}

                    columns={columns}
                    rowsPerPageOptions={[25, 50, 100, 500]}
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    )
}

EventsList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.date,
        offers: PropTypes.number,
        linkedOffers: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
    })),
    onClickCreateEvent: PropTypes.func.isRequired,
    onClickArchiveEvent: PropTypes.func,
}

export default EventsList
