import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { MUIFormTextInput } from '../../../../../@Common/Form/TextInput/FormTextInput'
import ModalContainer from '../../../../../Modals/ModalContainer'

function SyndicateOfferToListingCreateModal({
    onClickCancel,
    onClickSubmit,
    offerId
}) {

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
    })

    const onSubmit = listingId => {
        onClickSubmit(listingId, offerId)
    }

    return (
        <ModalContainer
            title={`Syndicate Offer`}
            maxWidth="sm"
            fullWidth="true"
            hideModal={() => { }}
            actions={{
                primaryAction: { label: "Add", onClick: handleSubmit(onSubmit) },
                secondaryAction: { label: "Cancel", onClick: () => onClickCancel() },
            }}
        >
            <div>
                <p>
                    Syndicating this offer will display it on another listing page on GOVX.com. To remove the offer, you'll have to delete it from that listing.
                </p>
            </div>
            <div style={{ marginTop: "40px", marginBottom: "30px" }}>
                <MUIFormTextInput
                    name="listingId"
                    label="Listing ID"
                    control={control}
                    rules={{
                        required: true,
                        validate: (value) => value != null,
                        message: "Listing ID is required"
                    }}
                    gxid="SyndicateOfferToListingCreateModal"
                />
            </div>
        </ModalContainer>
    )
}

export default SyndicateOfferToListingCreateModal
