import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '@mui/material/Drawer'
import { Box, LinearProgress, Button } from '@mui/material'
import { hideModal } from '../../../redux/actions'

function ProcessingIcon() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.83)',
      }}
    >
      <CircularProgress />
    </div>
  )
}

const style = {
  // this style should be integrated into to the Button component
  cancelButton: {
    fontFamily: 'Roboto Medium, Helvetica, sans-serif',
    fontWeight: '600',
    fontSize: '0.875rem',
    letterSpacing: '.2px',
    lineHeight: '1.5',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    minHeight: '36px',
    minWidth: '64px',
    outline: '0px',
    border: '0px',
    padding: '8px 16px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    verticalAlign: 'middle',
    cursor: 'pointer',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  title: {
    borderBottom: '1px solid #e0e0e0',
    fontWeight: '600',
    lineHeight: '1.6',
    fontSize: '1.25rem',
    fontFamily: 'Roboto Medium, Helvetica, sans-serif',
    padding: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  content: {
    maxHeight: '70vh',
    fontFamily: '"Roboto Regular", Helvetica, sans-serif',
    fontSize: '1rem',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '1.5',
    padding: '24px',
  },
}

export function ModalContainer({
  title,
  children,
  actions,
  maxWidth,
  fullWidth,
  processing,
  // eslint-disable-next-line no-unused-vars
  disabled,
  showScrollAlways = false,
  modal = false,
  drawer = false,
  showProgressBar = false,
}) {
  const dispatch = useDispatch()

  const hideModalFn = (event, reason) => {
    if (!modal || reason !== 'backdropClick') dispatch(hideModal())
  }

  if (drawer) {
    return (
      <Drawer
        open
        anchor="right"
        onClose={hideModal}
        sx={{
          width: '900px',
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: '900px', boxSizing: 'border-box' },
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            borderBottom: '1px solid #e0e0e0', fontWeight: '600', lineHeight: '1.6', fontSize: '1.25rem', fontFamily: 'Roboto Medium, Helvetica, sans-serif', padding: '24px',
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ overflowY: showScrollAlways ? 'scroll' : 'auto', paddingTop: '24px' }}>
          {children}
        </DialogContent>
        <DialogActions style={{ padding: '12px 24px', margin: '0px', backgroundColor: '#f3f3f3' }}>
          {actions
            && actions.secondaryAction && (
              <Button
                variant="text"
                onClick={actions.secondaryAction.onClick}
                disabled={actions.secondaryAction.disabled}
                label={actions.secondaryAction.label}
                style={style.cancelButton}
              >
                {actions.secondaryAction.label}
              </Button>
          )}
          {actions
            && actions.primaryAction && (
              <Button
                variant="contained"
                color="primary"
                onClick={actions.primaryAction.onClick}
                disabled={actions.primaryAction.disabled}
                label={actions.primaryAction.label}
                style={{
                  minHeight: '36px',
                  minWidth: '64px',
                }}
              >
                {actions.primaryAction.label}
              </Button>
          )}
        </DialogActions>

        {processing ? <ProcessingIcon /> : null}
      </Drawer>
    )
  }

  // if not drawer, return regular modal
  return (
    <Dialog
      open
      onClose={hideModalFn}
      scroll="paper"
      maxWidth={maxWidth || 'sm'}
      // eslint-disable-next-line eqeqeq
      fullWidth={fullWidth != 'false'}
    >
      <DialogTitle id="form-dialog-title" style={style.title}>
        {title}
      </DialogTitle>
      <DialogContent style={{ overflowY: showScrollAlways ? 'scroll' : 'auto', ...style.content }}>
        {children}
      </DialogContent>
      <DialogActions style={{ padding: '12px 24px', margin: '0px', backgroundColor: '#f3f3f3' }}>
        {actions
          && actions.secondaryAction && (
            <Button
              variant="text"
              onClick={actions.secondaryAction.onClick}
              disabled={actions.secondaryAction.disabled}
              label={actions.secondaryAction.label}
            >
              {actions.secondaryAction.label}
            </Button>
        )}
        {actions
          && actions.primaryAction

          && (
            <Button
              variant="contained"
              color="primary"
              onClick={actions.primaryAction.onClick}
              disabled={actions.primaryAction.disabled}
              label={actions.primaryAction.label}
            >
              <Box sx={{ width: '100%' }}>
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                {showProgressBar ? <LinearProgress /> : <>{actions.primaryAction.label}</>}
              </Box>
            </Button>
          )}

      </DialogActions>

      {processing ? <ProcessingIcon /> : null}
    </Dialog>
  )
}

ModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.shape({
    primaryAction: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
    }),
    secondaryAction: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.string,
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
  showScrollAlways: PropTypes.bool,
  modal: PropTypes.bool,
  drawer: PropTypes.bool,
  showProgressBar: PropTypes.bool,
}

export default ModalContainer
