import React from 'react'
import PropTypes from 'prop-types'
import BulkOperations from './BulkOperations'
import DelegateAccessToken from './DelegateAccessToken'
import DevelopmentOffers from './Offers'
import WebhookSettings from './WebhookSettings'

function Shopify({ appId, listingId }) {
  return (
    <>
      <BulkOperations appId={appId} listingId={listingId} />
      <DelegateAccessToken appId={appId} listingId={listingId} />
      <DevelopmentOffers appId={appId} />
      <WebhookSettings appId={appId} />
    </>
  )
}

Shopify.propTypes = {
  appId: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
}

export default Shopify
